import React, { ReactNode, useCallback, ElementType } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Chip, Stack, SxProps } from '@mui/material';
import KeyresultChip from '../KeyresultChip';
import Button from 'Components/Library/BaseComponents/Button';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { openAddKeyResultPanel } from 'config/ModalProvider/helpers';
import { getKeyResultCompletionPct, getLastModifiedString } from 'config/helpers';
import { KR_OUTDATED_TRESHOLD } from 'config/constants';
import { IObjectiveData } from 'state/ducks/objectives/types';
import NestedDndContext from 'Components/Library/DragAndDrop/NestedDndContext';
import { useDispatch } from 'react-redux';
import { objectivesActions } from 'state/ducks/objectives';
import { get } from 'lodash';
import { sortKeyresults } from 'state/ducks/objectives/helpers';

const isOutdated = (keyresult, objectiveData) =>
  keyresult.lastModifiedDays >= KR_OUTDATED_TRESHOLD &&
  getKeyResultCompletionPct(keyresult) < 100 &&
  objectiveData?.data?.stage === 'LIVE';

interface KeyresultsListProps {
  canEditObjective?: boolean;
  objectiveData: IObjectiveData;
  uniqueContextIdentifier: string;
  sx?: SxProps;
  keyresultChipProps?: { [key: string]: any };
  stackProps?: { [key: string]: any };
  enableAddButton?: boolean;
  showLastModified?: boolean;
  emptyStateNode?: ReactNode;
  component?: ElementType<any>;
}

function KeyresultsList(props: KeyresultsListProps) {
  const {
    canEditObjective,
    objectiveData,
    uniqueContextIdentifier,
    sx = {},
    enableAddButton = false,
    showLastModified = false,
    keyresultChipProps = {},
    stackProps = {},
    emptyStateNode = null,
    component = 'div',
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const openAddKeyResult = (event, objectiveID) => {
    event.preventDefault();
    event.stopPropagation();
    openAddKeyResultPanel(objectiveID, navigate, location);
  };

  const keyresults = sortKeyresults(get(objectiveData, ['data', 'keyresults'], []));

  const dispatch = useDispatch();

  const onDragEnd = useCallback(
    result => {
      const { draggableId, destination, source } = result;
      if (!destination || !source) {
        return;
      }
      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;

      const keyresultID: string = draggableId.replace(
        `okr-table-draggable-${uniqueContextIdentifier}-${objectiveData.data.objectiveID}-`,
        '',
      );

      const order: Array<string> = keyresults.map(krData => krData.keyresultID);
      order.splice(sourceIndex, 1);
      order.splice(destinationIndex, 0, keyresultID);

      const pl = {
        objectiveID: objectiveData.data.objectiveID,
        updates: [],
      };

      order.forEach((keyresultID, index) => {
        pl.updates.push({
          keyresultID,
          position: index,
        });
      });

      dispatch(objectivesActions.sortKeyresults(pl));
    },
    [dispatch, objectiveData, uniqueContextIdentifier],
  );

  const appliedSx = React.useMemo<SxProps>(
    () => ({
      flexDirection: 'column',
      alignItems: 'space-between',
      ...sx,
    }),
    [sx],
  );

  if (!objectiveData || !objectiveData.ok) {
    return null;
  }

  return (
    <NestedDndContext
      onDragEnd={onDragEnd}
      droppableId={`okr-table-droppable-${uniqueContextIdentifier}-${objectiveData.data.objectiveID}`}
    >
      <Box sx={appliedSx} component={component}>
        <Droppable
          droppableId={`okr-table-droppable-${uniqueContextIdentifier}-${objectiveData.data.objectiveID}`}
          type={`okr-table-droptype-kr-${uniqueContextIdentifier}-${objectiveData.data.objectiveID}`}
        >
          {(provided, snapshot) => (
            <Stack {...stackProps} {...provided.droppableProps} ref={provided.innerRef}>
              {objectiveData?.data?.keyresults?.length === 0 && emptyStateNode}
              {keyresults.map((krData, index) => (
                <Draggable
                  draggableId={`okr-table-draggable-${uniqueContextIdentifier}-${objectiveData.data.objectiveID}-${krData.keyresultID}`}
                  index={index}
                  key={`okr-table-draggable-${uniqueContextIdentifier}-${objectiveData.data.objectiveID}-${krData.keyresultID}`}
                  isDragDisabled={!canEditObjective}
                  disableInteractiveElementBlocking
                >
                  {(prov, snap) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: snap.isDragging ? 5 : 'none',
                        backgroundColor: snap.isDragging ? 'background.paper' : 'transparent',
                        borderRadius: snap.isDragging ? 1 : 0,
                      }}
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                      style={prov.draggableProps.style}
                    >
                      <KeyresultChip
                        keyresult={krData}
                        color="transparent"
                        {...keyresultChipProps}
                      />
                      {showLastModified && (
                        <Box
                          sx={{
                            width: '100px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            flexShrink: 0,
                            flexGrow: 0,
                          }}
                        >
                          <Chip
                            color={isOutdated(krData, objectiveData) ? 'error' : 'default'}
                            label={getLastModifiedString(krData.lastModifiedDays, true)}
                            data-tg-name={
                              isOutdated(krData, objectiveData) ? 'red-okr-chip' : 'grey-okr-chip'
                            }
                            size="medium"
                            sx={{ m: '4px' }}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {canEditObjective && enableAddButton && (
                <Button
                  color="secondary"
                  name="okr-table-add-kr-button"
                  size="medium"
                  variant="text"
                  onClick={evt => openAddKeyResult(evt, objectiveData.data.objectiveID)}
                  sx={{ alignSelf: 'flex-start' }}
                >
                  {`+ ${t('objectives.addkeyresultcta')}`}
                </Button>
              )}
            </Stack>
          )}
        </Droppable>
      </Box>
    </NestedDndContext>
  );
}

export default KeyresultsList;
