import { createLogic } from 'redux-logic';
import axios from 'axios';
import { shouldFetch } from 'state/helpers';
import { INTEGRATIONS_API_BASE_URL } from 'state/constants/api';
import * as actions from './actions';
import * as types from './types';
import * as selectors from './selectors';

const SLICE_NAME = 'integrations';

// eslint-disable-next-line import/prefer-default-export
export const getIntegrationsLogic = createLogic({
  type: types.GET_INTEGRATIONS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectIntegrations(state.main[SLICE_NAME]),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action;
    axios
      .get(`${INTEGRATIONS_API_BASE_URL}/${getState().auth.tenantID}/getintegrations`, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.integrationFetchSuccess(res.data.result));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(payload));
      })
      .then(() => done());
  },
});
