import reducer from './reducers';

import * as insightsLogic from './logic';
import * as insightsTypes from './types';
import * as insightsActions from './actions';
import * as insightsSelectors from './selectors';

export { insightsLogic, insightsTypes, insightsActions, insightsSelectors };

export default reducer;
