import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import FeatureFlag from 'Components/Library/FeatureFlag/index';
import { useSelector, useDispatch } from 'react-redux';
import { tagsActions, tagsSelectors } from 'state/ducks/tags/index';
import { Tag } from 'Components/Library/Tags/Details/Tag';
import LinkTag from 'Components/Library/Tags/Details/LinkTag';
import { IState } from 'state/ducks/types';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ITagsData } from 'state/ducks/tags/types';

export interface ITagsComponentProps {
  instanceID: string;
  canEdit: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function TagsComponent(props: ITagsComponentProps) {
  const { canEdit, instanceID } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tagsActions.getInstanceTags({ id: instanceID, force: true }));
  }, [dispatch, instanceID]);

  const tagsData: ITagsData = useSelector((state: IState) =>
    tagsSelectors.selectInstanceTags(state?.main?.tags, instanceID),
  );

  const isLoading = tagsData?.loading && !tagsData.ok;
  const loadingSkeleton = (
    <Stack direction="column" spacing={1}>
      <Skeleton height={20} width="10vw" variant="rectangular" sx={{ mt: 1 }} />
      <Skeleton height={20} width="15vw" variant="rectangular" sx={{ mt: 1 }} />
    </Stack>
  );

  const hasTags = !!tagsData?.data?.nodes?.length;

  return (
    <FeatureFlag flag="TAGS">
      {isLoading ? (
        loadingSkeleton
      ) : (
        <>
          <Stack spacing={1}>
            {hasTags &&
              tagsData.data.nodes.map(tag => (
                <Tag
                  key={tag.id}
                  tagColor={tag.color}
                  tagID={tag.id}
                  tagText={tag.name}
                  canEdit={canEdit}
                  instanceID={instanceID}
                />
              ))}
          </Stack>
          <LinkTag
            existingTags={tagsData?.data?.nodes}
            instanceID={props?.instanceID}
            canEdit={canEdit}
          />
        </>
      )}
    </FeatureFlag>
  );
}

TagsComponent.propTypes = {
  instanceID: PropTypes.string,
};
