import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import { objectivesActions } from 'state/ducks/objectives';
import { KR_DESCRIPTION_MAX_LENGTH, KR_NAME_MAX_LENGTH } from 'config/constants';
import Container from 'Components/Library/BaseComponents/Container';

const schema = {
  type: 'object',
  properties: {
    keyresult: { type: 'string', minLength: 1, maxLength: KR_NAME_MAX_LENGTH },
    description: { type: 'string', maxLength: KR_DESCRIPTION_MAX_LENGTH },
  },
  required: ['keyresult'],
  additionalProperties: true,
};

function KeyresultHeadlineContainer(props) {
  const { keyresultData, canEdit } = props;
  const { t } = useTranslation();
  return (
    <Form
      name="panel-view-kr-headline"
      debouncedAutoSubmit={950}
      submitActionCreator={objectivesActions.editKeyresult}
      stateSlice="main.objectives"
      allowRefreshData
      refreshedDataSafeDeltaSeconds={10}
      initialValues={
        !!keyresultData && {
          keyresult: keyresultData.keyresult,
          description: keyresultData.description,
        }
      }
      additionalProperties={{
        keyresultID: keyresultData.keyresultID,
      }}
      schema={schema}
    >
      <LinearIndicator positioning="absolute" />
      <Container spacing={3} m={0}>
        <FormTextField
          fieldName="keyresult"
          render={fieldProps => (
            <InlineEditInput
              id="keyresultNameEditField"
              fullWidth
              multiline
              variant="h4"
              disabled={!canEdit}
              label={t('objectives.createNewKrNameInputLabel')}
              {...fieldProps}
            />
          )}
        />
        <FormTextField
          fieldName="description"
          render={fieldProps => (
            <InlineEditInput
              id="keyresultDescriptionField"
              fullWidth
              multiline
              variant="body1"
              disabled={!canEdit}
              label={canEdit ? t('objectives.createNewKrDescriptionInputLabel') : null}
              {...fieldProps}
            />
          )}
        />
      </Container>
    </Form>
  );
}

KeyresultHeadlineContainer.propTypes = {
  objectiveData: PropTypes.object,
  keyresultData: PropTypes.object,
  objectiveID: PropTypes.string,
  canEdit: PropTypes.bool,
};

export default KeyresultHeadlineContainer;
