import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function GamePlanIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C2.44772 3 2 3.44772 2 4V8.5C2 9.05228 2.44772 9.5 3 9.5H7C7.55228 9.5 8 9.05228 8 8.5V4C8 3.44772 7.55228 3 7 3H3ZM3.5 4C3.22386 4 3 4.22386 3 4.5V7.5C3 7.77614 3.22386 8 3.5 8H6.5C6.77614 8 7 7.77614 7 7.5V4.5C7 4.22386 6.77614 4 6.5 4H3.5Z"
        fill="black"
        fillOpacity="0.26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10C2.44772 10 2 10.4477 2 11V20C2 20.5523 2.44772 21 3 21H7C7.55228 21 8 20.5523 8 20V11C8 10.4477 7.55228 10 7 10H3ZM3.5 11C3.22386 11 3 11.2239 3 11.5V19C3 19.2761 3.22386 19.5 3.5 19.5H6.5C6.77614 19.5 7 19.2761 7 19V11.5C7 11.2239 6.77614 11 6.5 11H3.5Z"
        fill="black"
        fillOpacity="0.26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3C16.4477 3 16 3.44772 16 4V10.5C16 11.0523 16.4477 11.5 17 11.5H21C21.5523 11.5 22 11.0523 22 10.5V4C22 3.44772 21.5523 3 21 3H17ZM17.5 4C17.2239 4 17 4.22386 17 4.5V9.5C17 9.77614 17.2239 10 17.5 10H20.5C20.7761 10 21 9.77614 21 9.5V4.5C21 4.22386 20.7761 4 20.5 4H17.5Z"
        fill="black"
        fillOpacity="0.26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3C9.44772 3 9 3.44772 9 4V11.5C9 12.0523 9.44772 12.5 10 12.5H14C14.5523 12.5 15 12.0523 15 11.5V4C15 3.44772 14.5523 3 14 3H10ZM10.5 4C10.2239 4 10 4.22386 10 4.5V10.5C10 10.7761 10.2239 11 10.5 11H13.5C13.7761 11 14 10.7761 14 10.5V4.5C14 4.22386 13.7761 4 13.5 4H10.5Z"
        fill="black"
        fillOpacity="0.26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12.5C16.4477 12.5 16 12.9477 16 13.5V20C16 20.5523 16.4477 21 17 21H21C21.5523 21 22 20.5523 22 20V13.5C22 12.9477 21.5523 12.5 21 12.5H17ZM17.5 13.5C17.2239 13.5 17 13.7239 17 14V19C17 19.2761 17.2239 19.5 17.5 19.5H20.5C20.7761 19.5 21 19.2761 21 19V14C21 13.7239 20.7761 13.5 20.5 13.5H17.5Z"
        fill="black"
        fillOpacity="0.26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 13.5C9.44772 13.5 9 13.9477 9 14.5V20C9 20.5523 9.44772 21 10 21H14C14.5523 21 15 20.5523 15 20V14.5C15 13.9477 14.5523 13.5 14 13.5H10ZM10.5 14.5C10.2239 14.5 10 14.7239 10 15V19C10 19.2761 10.2239 19.5 10.5 19.5H13.5C13.7761 19.5 14 19.2761 14 19V15C14 14.7239 13.7761 14.5 13.5 14.5H10.5Z"
        fill="black"
        fillOpacity="0.26"
      />
    </SvgIcon>
  );
}

export default GamePlanIcon;
