// this function is used by React so this is ok
/* eslint-disable react/react-in-jsx-scope */

export const teamGradientPairs = [
  ['#55C016', '#F6C944'],
  ['#55C016', '#FFEEBD'],
  ['#64B6F7', '#8BF351'],
  ['#55C016', '#0092FF'],
  ['#0A8F00', '#64B6F7'],
  ['#4090F7', '#FF5A63'],
  ['#FF5A63', '#4090F7'],
  ['#4090F7', '#F88078'],
  ['#4090F7', '#F6C944'],
  ['#F6C944', '#64B6F7'],
  ['#4090F7', '#FFEEBD'],
  ['#FF5A63', '#F6C944'],
  ['#F6C944', '#FFEEBD'],
  ['#FFB400', '#D89B26'],
  ['#FFB400', '#F88078'],
  ['#F88078', '#FFEEBD'],
];

export const employeeGradientPairs = [
  // ['#9E9E9E', '#212121'],
  ['#BDBDBD', '#616161'],
  // ['#E0E0E0', '#757575'],
];

// chooses the gradient color pairs based on users sub
export function getGradientCodeFromId(personaId, type = 'employee', svg = false, id = 'Gradient') {
  // choose color pair
  let colors;
  if (type === 'employee') {
    colors = employeeGradientPairs[0];
  } else if (type === 'team' && personaId) {
    colors = teamGradientPairs[parseInt(personaId.slice(-1), 16)];
  }

  // make sure default user colors are selected in any case
  if (!colors) {
    if (type === 'employee') {
      colors = employeeGradientPairs[0];
    } else {
      colors = teamGradientPairs[0];
    }
  }

  // choose which corner
  let quarterHex = 0;
  if (personaId) {
    const secondLastHexChar = parseInt(personaId.slice(-2, -1), 16);
    if (secondLastHexChar < 2) {
      quarterHex = 0;
    } else {
      quarterHex = Math.round(secondLastHexChar / 4);
    }
  }

  let x;
  let y;
  switch (quarterHex) {
    case 0:
      x = '2.5%';
      y = '2.5%';
      break;
    case 1:
      x = '97.5%';
      y = '2.5%';
      break;
    case 2:
      x = '2.5%';
      y = '97.5%';
      break;
    case 3:
      x = '97.5%';
      y = '97.5%';
      break;
    default:
      x = '2.5%';
      y = '2.5%';
  }

  // choose which color is first, for teams only
  if (type === 'team' && personaId) {
    const thirdLastHexChar = parseInt(personaId.slice(-3, -2), 16);
    if (thirdLastHexChar < 8) {
      colors = colors.reverse();
    }
  }

  if (svg) {
    return (
      <radialGradient id={id} cx={x} cy={y} fx={x} fy={y} r="83.75%" data-testid={`gradient-${id}`}>
        <stop offset="0%" stopColor={colors[0]} />
        <stop offset="100%" stopColor={colors[1]} />
      </radialGradient>
    );
  }
  return `radial-gradient(83.75% 83.75% at ${x} ${y}, ${colors[0]} 0%, ${colors[1]} 100%)`;
}

export function getInitials(sub, state) {
  let initials = '';
  if (state.auth && state.auth.userID && sub === state.auth.userID) {
    if (!!state.auth.firstName) {
      initials = state.auth.firstName.charAt(0);
    }
    if (!!state.auth.lastName) {
      initials += state.auth.lastName.charAt(0);
    }
  } else if (
    !!state.main &&
    !!state.main.people &&
    !!state.main.people.subs[sub] &&
    state.main.people.subs[sub].ok
  ) {
    if (!!state.main.people.subs[sub].firstName) {
      initials = state.main.people.subs[sub].firstName.charAt(0);
    }
    if (!!state.main.people.subs[sub].lastName) {
      initials += state.main.people.subs[sub].lastName.charAt(0);
    }
  }
  return initials;
}

export default getInitials;
