import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import OfflineIcon from '@mui/icons-material/CloudOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.grey[900],
    ...theme.shape,
    color: theme.palette.primary[400],
  },
});

class OfflineText extends Component {
  render() {
    const { t, isOnline, classes } = this.props;
    if (!!isOnline) {
      return null;
    }
    return (
      <Box
        className={classes.root}
        display="flex"
        flexDirection="row"
        p={1}
        alignItems="center"
        mb={5}
      >
        <Box mr={2} ml={1}>
          <OfflineIcon id="offline-icon" />
        </Box>
        <Typography variant="body2">{t('general.offline')}</Typography>
      </Box>
    );
  }
}

OfflineText.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  isOnline: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(OfflineText));
