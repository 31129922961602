import axios from 'axios';
import { APIGW_URL } from 'state/constants/api';
import { getStore } from 'store';

/* Handle the add element logic outside of redux.

   Elements in game plan widgets need to have a unique and immutable ID for the
   drag and drop to work, so elements need to be added using an API call.

   If this would be handled in the redux context, it would cause issues
   with the logic of preventing inputs in forms from updating when recently
   edited - which tries to resolve potential conflicts with two people touching
   the same objects simultaneously.

   Using this helper, we can use the API to add a new element and update the form
   state with the new element - when that element is then edited, updates to the
   widget state will happen with the normal Forms <-> Redux <-> backend integration.

*/
// eslint-disable-next-line import/prefer-default-export
export const addWidgetElement = async (widgetId, index, elementType) => {
  const state = getStore().getState();

  const { tenantID } = state.auth;
  const accessToken = state.auth.tokens.access_token;

  const payload = {
    widget_id: widgetId,
    element_type: elementType,
    index,
  };

  const headers = { headers: { Authorization: `Bearer ${accessToken}` } };

  const response = await axios.post(
    `${APIGW_URL}/gameplans/${tenantID}/addwidgetelement`,
    payload,
    headers,
  );

  return response.data.result;
};
