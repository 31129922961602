/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  listItem: {
    listStyle: 'none',
    position: 'relative',
  },
  label: {
    position: 'absolute',
    left: -26,
    width: 24,
    top: -1,
    display: 'inline-block',
    textAlign: 'center',
  },
  checkbox: {
    filter: 'grayscale(1)',
  },
});

function TaskListItem(props) {
  const { classes, node, children } = props;

  return (
    <li
      // the classnames and data attributes are required for copy pasting
      // back to the editor to correctly parse the content
      className={clsx('remirror-list-item-with-custom-mark', classes.listItem)}
      data-task-list-item
      data-checked={node.attrs.checked ? '' : undefined}
    >
      <label className={clsx('remirror-list-item-marker-container', classes.label)}>
        <input
          type="checkbox"
          className={clsx('remirror-list-item-checkbox', classes.checkbox)}
          contentEditable="false"
          checked={node.attrs.checked}
          readOnly
        />
      </label>
      <div>{children}</div>
    </li>
  );
}

TaskListItem.propTypes = {
  node: PropTypes.object,
  classes: PropTypes.shape({
    label: PropTypes.string,
    listItem: PropTypes.string,
    checkbox: PropTypes.string,
  }),
};

export default withStyles(styles)(TaskListItem);
