import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';

const styles = theme => ({
  card: {
    borderRadius: 15,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.box,
    '&:hover': {
      boxShadow: theme.shadows[2],
    },
    '&:focus': {
      boxShadow: theme.shadows[2],
    },
  },
});

class InteractableCard extends Component {
  render() {
    const {
      className,
      classes,
      children,
      name,
      id,
      disabled,
      onClick,
      footer,
      header,
      fixedHeight,
    } = this.props;
    return (
      <ButtonBase
        component="div"
        className={classes.card}
        name={name}
        id={id}
        disabled={disabled}
        onClick={onClick}
      >
        {header && (
          <Box
            sx={{
              margin: 2,
              marginTop: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {header}
          </Box>
        )}
        <Box
          className={className}
          sx={{
            bgcolor: 'background.paper',
            overflow: 'hidden',
            margin: 2,
            borderRadius: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            minHeight: fixedHeight ? 240 : 0,
          }}
        >
          {children}
        </Box>
        {footer && (
          <Box
            sx={{
              margin: 2,
              marginTop: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {footer}
          </Box>
        )}
      </ButtonBase>
    );
  }
}

InteractableCard.propTypes = {
  classes: PropTypes.exact({
    card: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  footer: PropTypes.node,
  header: PropTypes.node,
  fixedHeight: PropTypes.bool,
};

InteractableCard.defaultProps = {
  fixedHeight: false,
};

export default withStyles(styles, { withTheme: true })(InteractableCard);
