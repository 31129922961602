import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { orderBy } from 'lodash';

import { getConfidenceString } from 'state/ducks/objectives/helpers/common';

const EventChart = lazy(() => import('Components/Features/Objectives/EventChart'));

class KeyresultChart extends React.PureComponent {
  render() {
    const { eventData, status, confidence } = this.props;
    const enrichedEventData = {
      historical: orderBy(eventData, ['timestamp'], ['asc']).map(evt => ({
        ...evt,
        // timestamp: moment(evt.timestamp, 'YYYY-MM-DD'),
        y: evt.status,
        confidenceStr: getConfidenceString(evt.confidence),
      })),
      today: {
        y: status,
        timestamp: moment(),
        confidenceStr: getConfidenceString(confidence),
        confidence,
      },
    };
    enrichedEventData.historical.push(enrichedEventData.today);
    return (
      <Suspense fallback={null}>
        <EventChart objectiveData={this.props.objectiveData} eventData={enrichedEventData} />
      </Suspense>
    );
  }
}

KeyresultChart.propTypes = {
  eventData: PropTypes.array,
  status: PropTypes.number,
  confidence: PropTypes.number,
  objectiveData: PropTypes.object,
};

export default KeyresultChart;
