import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import FormStringList from 'Components/Library/Forms/Elements/FormStringList';
import StandardInput from 'Components/Library/StandardInput';

const styles = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
  icon: {
    fontSize: 16,
  },
  iconButton: {
    width: 36,
    height: 36,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  lefticon: {
    marginRight: 8,
  },
});

class MultipleChoiceEditor extends React.Component {
  render() {
    const { t, classes } = this.props;
    return (
      <div className={this.props.className}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          {t('insights.multiChoiceEditorTitle')}
        </Typography>
        <FormStringList
          fieldName="choiceOptions"
          render={fieldProps => (
            <>
              {fieldProps.error && !!fieldProps.helperText && (
                <FormHelperText error>{fieldProps.helperText}</FormHelperText>
              )}
              {fieldProps.entryProps.map(({ onDelete, ...entryProps }, i) => (
                <div className={classes.row} key={i}>
                  <StandardInput
                    variant="standard"
                    fullWidth
                    name={`multiple-choice-editor-standard-input-${i}`}
                    placeholder={t('insights.multiChoiceOptionPlaceholder')}
                    label={`${i + 1}. ${t('insights.multiChoiceOptionPlaceholder')}`}
                    {...entryProps}
                  />
                  <IconButton
                    className={classes.iconButton}
                    size="large"
                    onClick={onDelete}
                    name={`multiple-choice-editor-delete-button-${i}`}
                    aria-label={t('general.delete')}
                  >
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </div>
              ))}
              <Button
                variant="text"
                color="secondary"
                className={classes.button}
                {...fieldProps.addButtonProps}
                name="multiple-choice-editor-add-option"
              >
                {`+ ${t('general.add')}`}
              </Button>
            </>
          )}
        />
      </div>
    );
  }
}

MultipleChoiceEditor.propTypes = {
  classes: PropTypes.exact({
    row: PropTypes.string,
    icon: PropTypes.string,
    iconButton: PropTypes.string,
    button: PropTypes.string,
    lefticon: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(MultipleChoiceEditor));
