import * as types from './types';

export const programsReceived = payload => ({
  type: types.RECEIVED_PROGRAMS,
  payload,
});

export const getProgramUsers = payload => ({
  type: types.GET_PROGRAM_USERS,
  payload,
});

export const programUsersReceived = payload => ({
  type: types.RECEIVED_PROGRAM_USERS,
  payload,
});

export const programUsersFailed = payload => ({
  type: types.FAILED_PROGRAM_USERS,
  payload,
});

export const createProgram = payload => ({
  type: types.CREATE_PROGRAM,
  payload,
});

export const programCreated = payload => ({
  type: types.PROGRAM_CREATED,
  payload,
});

export const editProgram = payload => ({
  type: types.EDIT_PROGRAM,
  payload,
});

export const programEdited = payload => ({
  type: types.PROGRAM_EDITED,
  payload,
});

export const editProgramRoot = payload => ({
  type: types.EDIT_PROGRAM_ROOT,
  payload,
});

export const programRootEdited = payload => ({
  type: types.PROGRAM_ROOT_EDITED,
  payload,
});

export const disableProgram = payload => ({
  type: types.DISABLE_PROGRAM,
  payload,
});

export const programDisabled = payload => ({
  type: types.PROGRAM_DISABLED,
  payload,
});

export const enableProgram = payload => ({
  type: types.ENABLE_PROGRAM,
  payload,
});

export const programEnabled = payload => ({
  type: types.PROGRAM_ENABLED,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
