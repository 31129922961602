import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import StandardDialog from 'Components/Library/StandardDialog';

import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import DataObjectChip from 'Components/Library/DataObjectChip';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import StandardInput from 'Components/Library/StandardInput';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import TrafficLightIcon from '../TrafficLightIcon';

const WARN_AFTER = 3; // Warn if trying to add more than 4 questions
const MAX_QUESTIONS = 8; // Maximum number of questions

const styles = theme => ({
  title: {
    height: 44,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 20,
    paddingRight: 12,
  },
  h6: {
    marginBottom: theme.spacing(3),
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  answersTitle: {
    marginTop: 40,
  },
  savingIndicator: {
    marginTop: -1 * 32,
    marginRight: -1 * 32,
    marginLeft: -1 * 32,
    marginBottom: 32 - 6,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20 * -1 - 6,
      marginBottom: 20 + 2,
    },
  },
  addButton: {
    color: theme.palette.secondary.main,
    padding: 8 /* padding instead of margin to increase touch area */,
    paddingLeft: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    ...theme.shape,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#ffffff',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    marginBottom: 8,
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: 56,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  deleteIcon: {
    flexGrow: 0,
    flexShrink: 0,
    flex: 0,
    alignSelf: 'flex-start',
  },
  settingsContainer: {
    marginTop: theme.spacing(2),
  },
});

class PanelCreateInterpretation extends Component {
  state = {
    // hasChanged: used for computing whether a question field should get focus
    // - if false, default to false - focus should be on the name field
    hasChanged: false,
  };

  addQuestion = () => {
    if (this.props.questions.length < MAX_QUESTIONS) {
      this.props.addQuestion();
    }
    const newState = { hasChanged: true };
    if (this.props.questions.length >= WARN_AFTER) {
      newState.shouldWarn = true;
    }
    if (!!newState) {
      this.setState(newState);
    }
  };

  render() {
    const {
      classes,
      submitted,
      submitStatus,
      submitDisabled,
      questions,
      name,
      trafficLights,
      trafficLightOptions,
      trafficLightQuestion,
      onClose,
      t,
      open,
      zIndexModifier,
    } = this.props;
    const { hasChanged } = this.state;
    return (
      <StandardDialog
        size="large"
        open={open}
        onBackdropClick={onClose}
        zIndexModifier={zIndexModifier}
      >
        <div className={classes.title}>
          <DataObjectChip text={t('csi.viewmodaloverline')} variant="normal" />
          <IconButton onClick={onClose} size="medium" aria-label={t('general.close')}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={clsx(classes.content, 'scrollable')}>
          <SavingIndicator
            submitted={submitted}
            submitStatus={submitStatus}
            className={classes.savingIndicator}
          />
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t('csi.startSessionTitle')}
          </Typography>
          <EmphasizedSection sx={{ mb: 3 }}>
            <Typography variant="h6" className={classes.h6}>
              {t('csi.startSessionSubtitle')}
            </Typography>
            <StandardInput
              id="panel-create-interpretation-edit-field-name"
              autoFocus
              placeholder={t('csi.startSessionNamePlaceholder')}
              maxLength="256"
              fullWidth
              value={name}
              onChange={this.props.onNameChange}
            />
          </EmphasizedSection>

          <EmphasizedSection sx={{ mb: 3 }}>
            <Typography variant="h6" className={classes.h6}>
              {t('csi.startSessionQuestionsSubtitle')}
            </Typography>
            {questions &&
              questions.map((q, i) => (
                <div key={`panel-create-interpretation-q-${i}`} className={classes.row}>
                  <StandardInput
                    id={`panel-create-interpretation-edit-field-${i}`}
                    autoFocus={!!(q.length === 0 && hasChanged)}
                    multiline
                    maxLength="256"
                    fullWidth
                    value={q}
                    onChange={evt => this.props.onQuestionTextChange(i, evt)}
                  />
                  <IconButton
                    id={`panel-create-interpretation-delete-button-${i}`}
                    className={classes.deleteIcon}
                    onClick={() => this.props.deleteQuestion(i)}
                    size="large"
                    aria-label={t('general.delete')}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            {questions.length === 0 && (
              <Typography variant="body2" color="textSecondary">
                {t('csi.startSessionNoQuestionsHint')}
              </Typography>
            )}

            <br />
            {questions.length < MAX_QUESTIONS ? (
              <Typography
                id="panel-create-interpretation-add-one"
                variant="caption"
                className={classes.addButton}
                onClick={this.addQuestion}
              >
                {t('csi.startSessionAddQuestionCta')}
              </Typography>
            ) : (
              <Typography id="panel-create-interpretation-max-reached" variant="caption">
                {t('csi.startmodalmaxquestionshint')}
              </Typography>
            )}
          </EmphasizedSection>
          <EmphasizedSection>
            <Typography variant="h6" className={classes.h6}>
              {t('csi.startmodalsubtitlesettings')}
            </Typography>
            <div>
              <div className={classes.inline}>
                <div>
                  <Typography variant="subtitle2">
                    {t('csi.startmodaltoggletrafficlightslabel')}
                  </Typography>
                  <Typography variant="caption">
                    {t('csi.startmodaltoggletrafficlightsinfotext')}
                  </Typography>
                </div>
                <Switch
                  id="panel-create-interpretation-enable-field"
                  onChange={this.props.toggleTrafficLights}
                  checked={trafficLights}
                />
              </div>
            </div>
            {trafficLights && (
              <React.Fragment>
                <br />
                <InlineEditInput
                  id="panel-create-interpretation-edit-trafficquestion-field"
                  maxLength="256"
                  fullWidth
                  variant="subtitle1"
                  value={trafficLightQuestion}
                  onChange={evt => this.props.onTrafficLightQuestionChange(evt)}
                />
                <Paper className={classes.settingsContainer}>
                  <div className={classes.inline}>
                    <TrafficLightIcon color="green" />
                    <InlineEditInput
                      id="panel-create-interpretation-edit-traffic-field-green"
                      multiline
                      variant="body1"
                      maxLength="256"
                      fullWidth
                      value={trafficLightOptions.green}
                      onChange={evt => this.props.onTrafficLightOptionChange('green', evt)}
                      sx={{ mr: 1 }}
                    />
                  </div>
                  <div className={classes.inline}>
                    <TrafficLightIcon color="yellow" />
                    <InlineEditInput
                      id="panel-create-interpretation-edit-traffic-field-yellow"
                      multiline
                      variant="body1"
                      maxLength="256"
                      fullWidth
                      value={trafficLightOptions.yellow}
                      onChange={evt => this.props.onTrafficLightOptionChange('yellow', evt)}
                      sx={{ mr: 1 }}
                    />
                  </div>
                  <div className={classes.inline}>
                    <TrafficLightIcon color="red" />
                    <InlineEditInput
                      id="panel-create-interpretation-edit-traffic-field-red"
                      multiline
                      variant="body1"
                      maxLength="256"
                      fullWidth
                      value={trafficLightOptions.red}
                      onChange={evt => this.props.onTrafficLightOptionChange('red', evt)}
                      sx={{ mr: 1 }}
                    />
                  </div>
                </Paper>
              </React.Fragment>
            )}
          </EmphasizedSection>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} id="panel-create-interpretation-cancel">
            {t('general.cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            id="panel-create-interpretation-start"
            disabled={submitDisabled}
            onClick={this.props.onSubmit}
          >
            {t('general.start')}
          </Button>
        </DialogActions>
      </StandardDialog>
    );
  }
}

PanelCreateInterpretation.propTypes = {
  classes: PropTypes.exact({
    title: PropTypes.string,
    h6: PropTypes.string,
    content: PropTypes.string,
    questionName: PropTypes.string,
    answersTitle: PropTypes.string,
    savingIndicator: PropTypes.string,
    addButton: PropTypes.string,
    row: PropTypes.string,
    inline: PropTypes.string,
    deleteIcon: PropTypes.string,
    settingsContainer: PropTypes.string,
  }),
  questions: PropTypes.array,
  addQuestion: PropTypes.func,
  submitted: PropTypes.bool,
  submitStatus: PropTypes.number,
  submitDisabled: PropTypes.bool,
  name: PropTypes.string,
  trafficLights: PropTypes.bool,
  trafficLightOptions: PropTypes.object,
  trafficLightQuestion: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNameChange: PropTypes.func,
  onQuestionTextChange: PropTypes.func,
  deleteQuestion: PropTypes.func,
  toggleTrafficLights: PropTypes.func,
  onTrafficLightQuestionChange: PropTypes.func,
  onTrafficLightOptionChange: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(withStyles(styles)(connect()(PanelCreateInterpretation)));
