import React from 'react';
import Ajv from 'ajv';
import { get, set, isEmpty, debounce, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import { formsActions, formsSelectors } from 'state/ducks/forms';
import { filterMentions } from './helpers';
import FormContext from './FormContext';

const ajv = new Ajv({ allErrors: true, strict: false });

ajv.addKeyword({
  keyword: 'richtextschema',
  code(cxt) {
    const { data } = cxt;
    return !!data && data.type === 'doc';
  },
});

export class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      isValid: false,
      isInitialized: false,
      fieldErrors: {},
      dirtyFields: {},
      touchedFields: {},
      submitting: false,
      submitSuccess: false,
      submitResult: {},
      apiError: false,
      apiErrorMessage: null,
      showErrors: false,
      requestID: null,
      submittedData: {},
    };
    this.validator = ajv.compile(props.schema);

    this.richtextRefs = {};
    if (Boolean(props.debouncedAutoSubmit)) {
      this.debouncedSubmit = debounce(this.submit, props.debouncedAutoSubmit);
    }
  }

  componentDidMount() {
    const {
      initialValues,
      persistedData,
      persistedDataBlacklist = [],
      debouncedAutoSubmit,
      preventSaveIncomplete,
    } = this.props;
    if (!!initialValues) {
      let { values } = this.state;
      let { dirtyFields } = this.state;
      if (!!persistedData && !debouncedAutoSubmit && !preventSaveIncomplete) {
        const filteredPeristedData = {};
        filteredPeristedData.values = pickBy(
          persistedData.values,
          (value, key) => !persistedDataBlacklist.includes(key),
        );
        filteredPeristedData.dirtyFields = pickBy(
          persistedData.dirtyFields,
          (value, key) => !persistedDataBlacklist.includes(key),
        );
        values = { ...values, ...filteredPeristedData.values };
        dirtyFields = { ...dirtyFields, ...filteredPeristedData.dirtyFields };
      }
      const [isValid, fieldErrors] = this.validate(this.getValuesToSubmit(values));
      this.setState({
        isValid,
        fieldErrors,
        values,
        dirtyFields,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};
    if (!!nextProps.initialValues) {
      const now = Date.now();
      const newValues = {};
      let updated = false;
      for (const fieldName in nextProps.initialValues) {
        /*
        Receiving potentially new values, check if we should update the values in state.
        Update if:
          - we did not yet have a value for this field
          - we had a value, but:
            - allowRefreshData is true
            - AND the field is not dirty (edited, not yet submitted)
            - AND if refreshedDataSafeDeltaSeconds is set (non-zero), atleast that amount of
              seconds have elapsed since the last edit to that field (used for "autosubmit scenarios")
        */
        if (
          !(fieldName in prevState.values) ||
          (nextProps.allowRefreshData &&
            !get(prevState.dirtyFields, fieldName, false) &&
            (nextProps.refreshedDataSafeDeltaSeconds === 0 ||
              get(prevState.touchedFields, fieldName, 0) +
                nextProps.refreshedDataSafeDeltaSeconds * 1000 <
                now))
        ) {
          newValues[fieldName] = nextProps.initialValues[fieldName];
          if (newValues[fieldName] !== prevState.values[fieldName]) {
            updated = true;
          }
        }
      }
      nextState.values = { ...prevState.values, ...newValues };
      nextState.updated = updated;
    }
    if (prevState.submitting) {
      if (prevState.requestID in nextProps.actionlog) {
        nextState.submitting = false;
        nextState.submitResult = nextProps.actionlog[prevState.requestID];
        if (nextProps.actionlog[prevState.requestID].result === 'ok') {
          nextState.submitSuccess = true;
        } else {
          nextState.apiError = true;
          nextState.apiErrorMessage = nextProps.actionlog[prevState.requestID].message;
        }
      }
    }
    if (!!nextState) {
      return nextState;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    /* Ensure form validation happens if new values are received
     */
    const newState = {};
    if (!!this.state.updated && !prevState.updated) {
      const [isValid, fieldErrors] = this.validate(this.getValuesToSubmit(this.state.values));
      newState.isValid = isValid;
      newState.fieldErrors = fieldErrors;
      newState.updated = false;
    }

    /* Handle onSubmitSuccess callback & clean dirty fields */
    if (this.state.submitSuccess && !prevState.submitSuccess) {
      const cleanedDirtyFields = { ...this.state.dirtyFields };
      Object.keys(this.state.submittedData).forEach(submittedKey => {
        cleanedDirtyFields[submittedKey] = false;
      });
      newState.dirtyFields = cleanedDirtyFields;

      if (!!this.props.onSubmitSuccess) {
        this.props.onSubmitSuccess(this.state.submitResult, this.state.submittedData);
      }
    }
    if (Object.entries(newState).length > 0) {
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    const { dispatch, formIdentifier, name, debouncedAutoSubmit, preventSaveIncomplete } =
      this.props;
    const { values, dirtyFields, submitSuccess } = this.state;
    if (!debouncedAutoSubmit && !isEmpty(dirtyFields) && !preventSaveIncomplete) {
      // Save the form state into redux state
      if (!!submitSuccess) {
        // clear the stored form state
        dispatch(
          formsActions.clearForm({
            formName: name,
            formIdentifier,
          }),
        );
      } else {
        dispatch(
          formsActions.saveForm({
            formName: name,
            formState: { values, dirtyFields },
            formIdentifier,
          }),
        );
      }
    }
    clearTimeout(this.showErrorTimeout);
  }

  onFieldChange = (fieldName, value) => {
    const { initialValues } = this.props;
    let { values } = this.state;
    const touchedFields = { ...this.state.touchedFields };
    const dirtyFields = { ...this.state.dirtyFields };
    set(values, fieldName, value);

    const rootField = fieldName.split('.')[0];
    // Mark field as edited?
    touchedFields[rootField] = Date.now();
    dirtyFields[rootField] = value !== initialValues[rootField];
    if (!!this.props.onValueChange) {
      values = this.props.onValueChange(rootField, values);
    }
    const [isValid, fieldErrors] = this.validate(this.getValuesToSubmit(values));

    const newState = { values, isValid, fieldErrors, touchedFields, dirtyFields };

    // hide errors if the user now fixed them
    if (!!this.state.showErrors && isValid) {
      newState.showErrors = false;
    }

    this.setState(newState);
    if (Boolean(this.props.debouncedAutoSubmit)) {
      this.debouncedSubmit();
    }
  };

  registerRef = (fieldName, r) => {
    this.richtextRefs[fieldName] = r;
  };

  getValuesToSubmit = values => {
    const { submitOnlyDirty } = this.props;
    if (!submitOnlyDirty) {
      return values;
    }
    return Object.keys(values)
      .filter(key => key in this.state.dirtyFields)
      .reduce((obj, key) => {
        obj[key] = values[key];
        return obj;
      }, {});
  };

  castValues = values => {
    // Perform any necessary casting of values before dispatch
    // Currently used to filter out PII from mentions before submitting
    // to the API, doing this in the rich text editor would have a heavy
    // performance impact
    for (const fieldName in values) {
      const fieldSchema = this.props.schema.properties[fieldName];
      if (fieldSchema?.richtextschema) {
        values[fieldName] = filterMentions(values[fieldName]);
      }
    }
    return values;
  };

  validate = values => {
    let isValid;
    if (!!this.props.preValidationTransform) {
      isValid = this.validator(this.props.preValidationTransform(values));
    } else {
      isValid = this.validator(values);
    }

    const fieldErrors = {};
    if (!isValid) {
      this.validator.errors.forEach(error => {
        let fieldName = error.instancePath.substring(1);
        if (fieldName.includes('/')) {
          // nested field, we need to manipulate the path format
          // ajv returns paths in the format of array[0].key
          // but what we need is array.0.key
          fieldName = fieldName.replace(/\//g, '.');
        }
        if (!(fieldName in fieldErrors)) {
          fieldErrors[fieldName] = [];
        }
        fieldErrors[fieldName].push(error.message);
      });
    }

    // Custom validation for fields that are not directly compatible with ajv
    // json-schema validation
    // eg. the only sane way to validate rich text content in any way is to
    // use methods of the remirror library
    for (const fieldName in this.richtextRefs) {
      const ref = this.richtextRefs[fieldName];
      const fieldSchema = get(this.props.schema.properties, fieldName, {});
      if (ref.current) {
        const errors = ref.current.validate(fieldSchema);

        if (errors.length > 0) {
          isValid = false;
          fieldErrors[fieldName] = errors;
        }
      }
    }

    return [isValid, fieldErrors];
  };

  submit = event => {
    const {
      dispatch,
      onSubmitDispatched,
      submitActionCreator,
      customDispatchFunc,
      allowDispatchInvalid,
      additionalProperties,
      debouncedAutoSubmit,
    } = this.props;
    const valuesToSubmit = this.castValues(this.getValuesToSubmit({ ...this.state.values }));
    const [isValid, fieldErrors] = this.validate(valuesToSubmit);
    if (isValid || !!allowDispatchInvalid) {
      const requestID = nanoid(10);
      const params = {
        ...valuesToSubmit,
        ...additionalProperties,
        requestID,
      };
      if (!Boolean(customDispatchFunc)) {
        dispatch(submitActionCreator(params));
        if (Boolean(onSubmitDispatched)) {
          onSubmitDispatched(params, event);
        }
        this.setState({
          submitSuccess: false,
          submitting: true,
          submitResult: {},
          requestID,
          apiError: false,
          apiErrorMessage: null,
          submittedData: valuesToSubmit,
        });
      } else {
        customDispatchFunc(submitActionCreator(params));
        this.setState({
          submitSuccess: true,
          submitting: false,
          submitResult: {},
          apiError: false,
          apiErrorMessage: null,
          submittedData: valuesToSubmit,
        });
      }
    } else {
      const newState = { isValid, fieldErrors };

      if (!Boolean(debouncedAutoSubmit)) {
        // The source of the action was user interaction
        // Show the user the error instantly
        newState.showErrors = true;
      } else {
        // Let's give the user a bit of time before we show
        // what's wrong, they might've just erased a field
        // and want to think for a bit!
        clearTimeout(this.showErrorTimeout);
        this.showErrorTimeout = setTimeout(() => {
          this.setState({ showErrors: true });
        }, debouncedAutoSubmit * 3);
      }
      this.setState(newState);
    }
  };

  enableShowErrors = () => {
    this.setState({ showErrors: true });
  };

  render() {
    const { debouncedAutoSubmit, name, schema, children, forwardRef, key } = this.props;
    const {
      values,
      isValid,
      isInitialized,
      showErrors,
      fieldErrors,
      submitting,
      submitSuccess,
      apiError,
      apiErrorMessage,
      dirtyFields,
    } = this.state;
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div name={name} ref={forwardRef} key={key}>
        <FormContext.Provider
          // TODO: performance optimization
          // Right now, everytime Form renders a new value object is created
          // And all consumers of this context will re-render
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          value={{
            onFieldChange: this.onFieldChange,
            formName: name,
            onSubmit: this.submit,
            register: this.onFieldRegister,
            schema,
            values,
            isValid,
            showErrors,
            enableShowErrors: this.enableShowErrors,
            isInitialized,
            fieldErrors,
            submitting,
            apiError,
            apiErrorMessage,
            submitSuccess,
            isAutoSubmit: Boolean(debouncedAutoSubmit),
            dirtyFields,
            registerRef: this.registerRef,
          }}
        >
          {children}
        </FormContext.Provider>
      </div>
    );
  }
}

FormComponent.propTypes = {
  name: PropTypes.string.isRequired,
  formIdentifier: PropTypes.string,
  submitActionCreator: PropTypes.func.isRequired,
  // stateSlice is used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  stateSlice: PropTypes.string,
  debouncedAutoSubmit: PropTypes.number,
  onSubmitDispatched: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  // custom dispatch func - this form is not connected to the API
  customDispatchFunc: PropTypes.func,
  // allow dispatch even if the validation is not successful?
  allowDispatchInvalid: PropTypes.bool,
  // Callback fn called after a value has changed but before validation is performed
  // Receives the entire values object as a parameter, and must return a new
  // values object.
  onValueChange: PropTypes.func,

  // fn called to transform values object before validating
  // Receives the entire values object as a parameter, and must return a new
  // values object.
  preValidationTransform: PropTypes.func,

  // Prevent persisting of an incomplete form when it is closed without submitting
  preventSaveIncomplete: PropTypes.bool,

  // Blacklisted fields for rehydrating a persisted incomplete form
  persistedDataBlacklist: PropTypes.array,

  submitOnlyDirty: PropTypes.bool,
  allowRefreshData: PropTypes.bool,
  refreshedDataSafeDeltaSeconds: PropTypes.number,
  initialValues: PropTypes.object,
  actionlog: PropTypes.object,
  dispatch: PropTypes.func,
  additionalProperties: PropTypes.object,
  schema: PropTypes.object.isRequired,
  persistedData: PropTypes.object,
  forwardRef: PropTypes.object,
  key: PropTypes.string,
};

FormComponent.defaultProps = {
  refreshedDataSafeDeltaSeconds: 0,
  allowRefreshData: false,
  submitOnlyDirty: false,
  additionalProperties: {},
  allowDispatchInvalid: false,
  preventSaveIncomplete: false,
};

const mapStateToProps = (state, ownProps) => ({
  actionlog: get(state, `${ownProps.stateSlice}.actionlog`, {}),
  persistedData: formsSelectors.selectFormState(
    state.main.forms,
    ownProps.name,
    ownProps.formIdentifier,
  ),
});

export default connect(mapStateToProps)(FormComponent);
