/* eslint-disable no-console */
// This component must be able to log for debugging purposes
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import { get } from 'lodash';
import { channel } from 'config/swbus';
import { authActions } from 'state/ducks/auth';

class ServiceWorkerTokenHandler extends React.Component {
  state = {
    unsupportedSw: false,
    snackbarShown: false,
  };

  componentDidMount() {
    console.log('ServiceWorkerTokenHandler: Mounting, posting initial token');
    this.postToken();
    channel.addEventListener('message', this.onSwMessage);
    if (!('serviceWorker' in navigator)) {
      console.log('ServiceWorkerTokenHandler: Service Workers are not supported');
      this.setState({ unsupportedSw: true, snackbarShown: false });
      // Service Workers are not supported or disabled, show warning
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      console.log('ServiceWorkerTokenHandler: Updating token');
      this.postToken();
    }
  }

  hideSnackbar = () => {
    this.setState({
      snackbarShown: true,
    });
  };

  onSwMessage = event => {
    if (event.title === 'TOKEN_REQUEST') {
      console.log('ServiceWorkerTokenHandler: Request for token received');
      this.postToken();
    }
    if (event.title === 'TOKEN_CONFIRMED') {
      console.log('ServiceWorkerTokenHandler: SW token confirmed');
      this.props.dispatch(authActions.swTokenConfirmed());
    }
  };

  postToken = () => {
    const now = new Date();
    if (parseInt(this.props.expires_in, 10) >= now.getTime()) {
      console.log('ServiceWorkerTokenHandler: token is valid, posting');
      channel.postMessage({ title: 'TOKEN_UPDATE', token: this.props.token });
    } else {
      console.log('ServiceWorkerTokenHandler: skip posting token - current token is expired');
    }
  };

  render() {
    const { t } = this.props;
    const { unsupportedSw, snackbarShown } = this.state;
    return (
      <Snackbar
        open={!!unsupportedSw && !snackbarShown}
        message={t('swTokenHandler.swNotSupportedErrorText')}
        autoHideDuration={8000}
        onClose={this.hideSnackbar}
      />
    );
  }
}

ServiceWorkerTokenHandler.propTypes = {
  token: PropTypes.string,
  expires_in: PropTypes.number,
  dispatch: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = state => ({
  token: get(state, 'auth.tokens.access_token'),
  expires_in: get(state, 'auth.tokens.expires_in'),
});

export default withTranslation()(connect(mapStateToProps)(ServiceWorkerTokenHandler));
