import * as actions from 'state/ducks/interlocks/actions';
import { sendAnalyticsData } from 'config/analytics';

// eslint-disable-next-line import/prefer-default-export
export const onInterlockCreated = (res, requestID, dispatch) => {
  const { result } = res.data;
  result.requestID = requestID;
  sendAnalyticsData('created interlock');
  dispatch(actions.addedInterlock(result));
};

export const onInterlockUpdated = (res, requestID, refetchContributors, isOwner, dispatch) => {
  const { result } = res.data;
  result.requestID = requestID;
  if (isOwner) {
    sendAnalyticsData('updated interlock by owner');
  } else {
    sendAnalyticsData('updated interlock by participant');
  }
  if (result.status === 'RESOLVED') {
    sendAnalyticsData('resolved interlock');
  }
  refetchContributors();
  dispatch(actions.updatedInterlock(result));
};

export const onContributorStatusUpdated = (res, requestID, id, dispatch) => {
  const { result } = res.data;
  result.requestID = requestID;
  result.id = id;
  sendAnalyticsData('updated interlock status');
  dispatch(actions.updatedContributorsStatus(result));
  dispatch(actions.fetchContributorsChart({ id, force: true }));
};
