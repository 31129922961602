import { enrich } from 'state/helpers';
import { IStateTags } from 'state/ducks/tags/reducers';

// eslint-disable-next-line import/prefer-default-export
export function selectAllTags(slice: IStateTags) {
  if (!!slice?.tags?.all && !!slice.tags.all.fetchStatus) {
    return slice.tags.all;
  }
  return enrich();
}

export function selectInstanceTags(slice: IStateTags, id: string) {
  if (!!slice?.tags[id] && !!slice.tags[id].fetchStatus) {
    return slice.tags[id];
  }
  return enrich();
}

export function selectLinkedToTag(slice: IStateTags, id: string) {
  if (!!slice?.linkedtagsv2 && !!slice?.linkedtagsv2[id]) {
    return slice.linkedtagsv2[id];
  }
  return enrich();
}
