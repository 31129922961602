import * as constants from './constants/api';

export function copyState(state) {
  const newstate = { ...state }; // shallow copy
  if (Object.prototype.hasOwnProperty.call(newstate, 'actionlog')) {
    // Shallow copy the actionlog
    newstate.actionlog = { ...state.actionlog };
  }
  return newstate;
}

export function validatePersistedState(state, initialState) {
  if (!!initialState.VERSION) {
    if (initialState.VERSION !== state.VERSION) {
      return JSON.parse(JSON.stringify(initialState));
    }
  }
  return state;
}

export function shouldFetch(dataObj, connection, force = false, nextToken = null) {
  if (connection && !connection.online) {
    // reject fetch actions when offline
    return false;
  }
  if ((force || (nextToken && dataObj?.next_token === nextToken)) && !dataObj?.loading) {
    return true;
  }
  if (dataObj?.fetchStatus === constants.OK && !!dataObj?.lastFetched) {
    const timeDelta = Date.now() - dataObj.lastFetched;
    const maxAge = !!dataObj.maxAge ? dataObj.maxAge : 60000; // milliseconds
    if (timeDelta > maxAge) {
      return true;
    }
  }

  if (
    [constants.REFRESHING, constants.FETCHING].includes(dataObj?.fetchStatus) &&
    !!dataObj?.fetchingTS
  ) {
    const timeDelta = Date.now() - dataObj.fetchingTS;
    const maxAge = !!dataObj.maxAge ? dataObj.maxAge : 60000; // milliseconds
    if (timeDelta > maxAge) {
      return true;
    }
  }

  if (Object.prototype.hasOwnProperty.call(dataObj, 'canFetch')) {
    return !!dataObj?.canFetch;
  }

  return true;
}

export function enrich(obj) {
  if (!!obj) {
    if (obj.fetchStatus === constants.OK) {
      return {
        ...obj,
        ok: true,
        loading: false,
        error: false,
        canFetch: false,
      };
    }
    if (obj.fetchStatus === constants.REFRESHING) {
      return {
        ...obj,
        ok: true,
        loading: true,
        error: false,
        canFetch: false,
      };
    }
    if (obj.fetchStatus === constants.ERROR) {
      return {
        ...obj,
        ok: false,
        loading: false,
        error: true,
        canFetch: false,
      };
    }
    if (obj.fetchStatus === constants.FETCHING) {
      return {
        ...obj,
        ok: false,
        loading: true,
        error: false,
        canFetch: false,
      };
    }
    if (obj.fetchStatus === constants.RETRY) {
      return {
        ...obj,
        ok: false,
        loading: false,
        error: false,
        canFetch: obj.errorCount ? obj.errorCount < 10 : true,
      };
    }
    if (obj.fetchStatus === constants.PARTIAL) {
      return {
        ...obj,
        ok: false,
        loading: false,
        error: false,
        canFetch: true,
      };
    }
    if (obj.fetchStatus === constants.DELETED) {
      return {
        ...obj,
        ok: false,
        loading: false,
        error: false,
        canFetch: false,
      };
    }
    if (obj.fetchStatus === constants.DOES_NOT_EXIST) {
      return {
        ...obj,
        ok: false,
        loading: false,
        error: false,
        canFetch: false,
      };
    }
  }
  return {
    ok: false,
    loading: false,
    error: false,
    canFetch: true,
  };
}

export function apiError(obj, doNotRetry) {
  const retVal = { ...obj };

  if (!Object.prototype.hasOwnProperty.call(retVal, 'errorCount')) {
    retVal.errorCount = 0;
  }
  retVal.errorCount += 1;
  if (!doNotRetry && retVal.errorCount < 5) {
    retVal.fetchStatus = constants.RETRY;
  } else {
    retVal.fetchStatus = constants.ERROR;
  }
  retVal.lastAttempt = Date.now();
  return enrich(retVal);
}

export function fetching(previousObject) {
  const retVal = { ...previousObject, fetchingTS: Date.now() };
  if (!!retVal.fetchStatus && retVal.fetchStatus === constants.OK) {
    retVal.fetchStatus = constants.REFRESHING;
  } else {
    retVal.fetchStatus = constants.FETCHING;
  }
  return enrich(retVal);
}
