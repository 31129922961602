import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Popover from '@mui/material/Popover';

import TeamChip from 'Components/Library/TeamChip';
import DataObjectChip from 'Components/Library/DataObjectChip';
import SearchOverlay from './SearchOverlay';

const styles = () => ({
  searchPopoverPaper: {
    marginTop: -2, // Align the search input text vertically with the chip text
  },
  searchRoot: {
    height: 'auto !important',
  },
});

function DomainSelector(props) {
  const {
    classes,
    value,
    onSelect,
    color,
    filterFunc,
    disabled,
    showManaged,
    showRecent,
    name,
    statusComponent,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);

  const openSearch = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const onSelectHandler = selectedSub => {
    closeSearch();
    onSelect(selectedSub);
  };

  let chip = null;
  if (value.type === 'special') {
    chip = (
      <DataObjectChip
        color={color}
        text={t(`objectives.create${value.id}`)}
        actionable={!disabled}
        onClick={openSearch}
        statusComponent={statusComponent}
        actOnDownArrow
        name={name}
      />
    );
  } else {
    chip = (
      <TeamChip
        color={color}
        teamId={value.id}
        actionable={!disabled}
        onClick={openSearch}
        statusComponent={statusComponent}
        actOnDownArrow
        name={name}
      />
    );
  }

  return (
    <>
      {chip}
      <Popover
        anchorEl={anchorEl}
        open={open}
        disableAutoFocus
        onClose={closeSearch}
        classes={{
          paper: classes.searchPopoverPaper,
        }}
      >
        <SearchOverlay
          onSelect={onSelectHandler}
          filterFunc={filterFunc}
          showManaged={showManaged}
          showRecent={showRecent}
          name={name}
        />
      </Popover>
    </>
  );
}

DomainSelector.propTypes = {
  classes: PropTypes.exact({
    searchPopoverPaper: PropTypes.string,
    searchRoot: PropTypes.string,
  }),
  value: PropTypes.object,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  filterFunc: PropTypes.func,
  showManaged: PropTypes.bool,
  showRecent: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  statusComponent: PropTypes.node,
};

export default withStyles(styles)(DomainSelector);
