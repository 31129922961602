import * as types from './types';

export const initialState = {
  VERSION: 1.02,
  online: navigator.onLine,
};

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case types.SET_STATUS: {
      const newState = { ...state };
      newState.online = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
