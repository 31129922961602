import * as actions from 'state/ducks/facilitation/actions';
import { sendAnalyticsData } from 'config/analytics';

// eslint-disable-next-line import/prefer-default-export
export const onFacilitationStarted = (res, dispatch) => {
  const { result } = res.data;
  sendAnalyticsData('started facilitation');
  dispatch(actions.startedSession(result));
};

export const onFacilitationConcluded = (res, state, action, dispatch) => {
  const { result } = res.data;
  result.sub = state.auth.userID;
  result.sessionID = action.payload.sessionID;
  sendAnalyticsData('concluded facilitation');
  dispatch(actions.summarySubmitted(result));
};
