import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import NotificationsHandler from 'Components/Common/NotificationsHandler';
import ConnectionHandler from 'Components/Common/ConnectionHandler';
import ServiceWorkerUpdateHandler from 'Components/Common/ServiceWorkerUpdateHandler';
import ServiceWorkerTokenHandler from 'Components/Common/ServiceWorkerTokenHandler';

import { peopleActions } from 'state/ducks/people';
import { objectivesActions } from 'state/ducks/objectives';
import { inboxActions } from 'state/ducks/inbox';

import ModalContainer from 'config/ModalProvider/ModalContainer';
import NotFound from './NotFound';
import TabObjectivesFetcher from './TabObjectivesFetcher';

class Tabs extends Component {
  componentDidMount() {
    this.props.dispatch(inboxActions.getInbox());
    this.props.dispatch(peopleActions.getMatrices());
    this.props.dispatch(objectivesActions.getPeriods({ force: true }));
    this.props.dispatch(peopleActions.getHierarchy());
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    newProps.dispatch(objectivesActions.getPeriods());
    newProps.dispatch(peopleActions.getHierarchy());
  }

  render() {
    const { auth } = this.props;
    if (!auth.isProvisioned) {
      return <Navigate to="/missinguserinfo" />;
    }

    return (
      <Box sx={{ width: 1, height: 1 }} id="content">
        <Suspense fallback={null}>
          <Routes>
            <Route path="/objectives/team/:teamID" element={<TabObjectivesFetcher />} />
            <Route path="/objectives/user/:sub" element={<TabObjectivesFetcher />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <ConnectionHandler />
          <NotificationsHandler />
          <ModalContainer />
          <ServiceWorkerUpdateHandler />
          <ServiceWorkerTokenHandler />
        </Suspense>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  // Injecting the whole auth state object to force a re-render when tokens are refreshed:
  auth: state.auth,
});

Tabs.propTypes = {
  dispatch: PropTypes.func,
  auth: PropTypes.object,
};

export default connect(mapStateToProps)(Tabs);
