import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';

import { withLocation, withNavigation } from 'withRouter';
import NotFoundPanelContent from 'Components/Common/NotFoundPanelContent';
import MediaQueryWrapper from 'Components/Common/MediaQueryWrapper';
import StandardSurveyDialog from 'Components/Library/StandardSurveyDialog';
import StandardInput from 'Components/Library/StandardInput';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import Form from 'Components/Library/Forms/Form';
import FormStringList from 'Components/Library/Forms/Elements/FormStringList';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import TeamWidget from 'Components/Library/TeamWidget';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import DataObjectChip from 'Components/Library/DataObjectChip';

import DfacIcon from 'Components/Features/DigitalFacilitation/DfacIcon';
import { openViewLiveFacilitationPanel } from 'config/ModalProvider/helpers';
import { facilitationActions } from 'state/ducks/facilitation';
import { peopleSelectors } from 'state/ducks/people';

import {
  DFAC_MAX_QUESTIONS_IN_SESS,
  DFAC_MAX_DESCRIPTION_STR_LENGTH,
  DFAC_MAX_TITLE_STR_LENGTH,
  DFAC_MAX_QUESTION_STR_LENGTH,
} from 'config/constants';

const schema = {
  type: 'object',
  properties: {
    topic: { type: 'string', minLength: 1, maxLength: DFAC_MAX_TITLE_STR_LENGTH },
    description: { type: 'string', maxLength: DFAC_MAX_DESCRIPTION_STR_LENGTH },
    questions: {
      type: 'array',
      minItems: 1,
      maxItems: DFAC_MAX_QUESTIONS_IN_SESS,
      items: {
        type: 'string',
        minLength: 1,
        maxLength: DFAC_MAX_QUESTION_STR_LENGTH,
      },
    },
  },
  required: ['topic', 'questions'],
};

class PanelStartFacilitation extends Component {
  onSessionStarted = apiResult => {
    openViewLiveFacilitationPanel(
      this.props.teamID,
      apiResult.data.session_id,
      true,
      this.props.navigate,
      this.props.location,
    );
  };

  render() {
    const { teamID, teamMembers, managerSub, isMember, onClose, t, zIndexModifier } = this.props;

    let content = <NotFoundPanelContent />;

    const memberList = [];

    if (!!managerSub) {
      memberList.push(managerSub);
    }

    memberList.push(...get(teamMembers, 'members', []).filter(sub => sub !== managerSub));

    if (!!isMember) {
      content = (
        <Box
          sx={{
            overflowY: 'auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 1 }}>
            {t('dfac.createheadline')}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {t('dfac.createbody')}
          </Typography>
          <div>
            <div>
              <Box
                sx={{
                  backgroundColor: theme => theme.palette.background.box,
                  borderRadius: 1,
                  p: 3,
                  mt: 4,
                  mb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'stretch',
                }}
              >
                <Typography variant="h6" component="span" display="block">
                  {t('dfac.subtitleSessionDetails')}
                </Typography>
                <FormTextField
                  fieldName="topic"
                  render={fieldProps => (
                    <StandardInput
                      id="start-dfac-panel-edit-topic-field"
                      label={t('dfac.createtitlelabel')}
                      multiline
                      margin="dense"
                      autoFocus
                      // align right end with those of the question inputs:
                      sx={{ mr: '38px' }}
                      {...fieldProps}
                    />
                  )}
                />
                <br />
                <FormTextField
                  fieldName="description"
                  render={fieldProps => (
                    <StandardInput
                      id="start-dfac-panel-edit-description-field"
                      label={t('dfac.createdescriptionlabel')}
                      multiline
                      margin="dense"
                      rows="4"
                      // align right end with those of the question inputs:
                      sx={{ mr: '38px' }}
                      {...fieldProps}
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  backgroundColor: theme => theme.palette.background.box,
                  borderRadius: 1,
                  p: 3,
                  mt: 4,
                  mb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'stretch',
                }}
              >
                <Typography variant="h6" component="span" display="block">
                  {t('dfac.subtitleAddQuestions')}
                </Typography>
                <FormStringList
                  fieldName="questions"
                  render={fieldProps => (
                    <>
                      {fieldProps.entryProps.map(({ onDelete, ...entryProps }, i) => (
                        <Box
                          key={`start-dfac-panel-question-${i}`}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'stretch',
                            alignItems: 'flex-start',
                          }}
                        >
                          <StandardInput
                            id={`start-dfac-panel-edit-question-field-${i}`}
                            label={t('dfac.createaddquestionlabel')}
                            fullWidth
                            multiline
                            margin="dense"
                            autoFocus={i > 0}
                            {...entryProps}
                          />
                          <IconButton
                            sx={{ mt: 2, ml: 0.5 }}
                            id={`start-dfac-panel-delete-button-${i}`}
                            size="small"
                            onClick={onDelete}
                            aria-label={t('general.delete')}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      ))}
                      <Button
                        color="secondary"
                        variant="text"
                        id="start-dfac-panel-add-question-button"
                        sx={{
                          alignSelf: 'flex-start',
                          mt: 1,
                        }}
                        {...fieldProps.addButtonProps}
                      >
                        {`+ ${t('dfac.createaddquestion')}`}
                      </Button>
                    </>
                  )}
                />
              </Box>
            </div>
          </div>
        </Box>
      );
    }

    return (
      <Form
        schema={schema}
        initialValues={{
          topic: '',
          description: '',
          questions: [''],
        }}
        submitActionCreator={facilitationActions.startSession}
        additionalProperties={{ teamID }}
        stateSlice="main.facilitation"
        onSubmitSuccess={this.onSessionStarted}
        name="form-start-dfac"
      >
        <StandardSurveyDialog
          size="large"
          open
          name="dfac-start"
          onBackdropClick={onClose}
          onCloseClick={onClose}
          closeButtonProps={{
            id: 'start-dfac-panel-cancel-btn',
          }}
          menuItems={null}
          title={<DataObjectChip Icon={DfacIcon} text={t('dfac.featureName')} variant="normal" />}
          zIndexModifier={zIndexModifier}
          sidebarContent={
            <Box>
              <Typography
                variant="subtitle2"
                component="span"
                display="block"
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                {t('dfac.subtitleCreateDialogMemberList', {
                  numPeople: memberList.length,
                })}
              </Typography>

              <TeamWidget teamId={teamID} />
            </Box>
          }
          actionbarContent={
            !!isMember ? (
              <DialogActions sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }}>
                <FormButton
                  render={buttonProps => (
                    <Button
                      id="start-dfac-panel-submit-btn"
                      variant="contained"
                      color="secondary"
                      {...buttonProps}
                    >
                      {t('general.start')}
                    </Button>
                  )}
                />
              </DialogActions>
            ) : null
          }
        >
          <LinearIndicator positioning="absolute" />
          {content}
        </StandardSurveyDialog>
      </Form>
    );
  }
}

PanelStartFacilitation.propTypes = {
  isQuestionsEmpty: PropTypes.bool,
  onQuestionAdded: PropTypes.func,
  onQuestionDeleted: PropTypes.func,
  onQuestionTextChange: PropTypes.func,
  submitStatus: PropTypes.number,
  submitDisabled: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  topic: PropTypes.string,
  description: PropTypes.string,
  onTopicChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  isMember: PropTypes.bool,
  teamID: PropTypes.string,
  t: PropTypes.func,
  canAdd: PropTypes.bool,
  wideScreen: PropTypes.bool,
  navigate: PropTypes.func,
  location: PropTypes.object,
  teamMembers: PropTypes.object,
  managerSub: PropTypes.string,
  zIndexModifier: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => ({
  isMember: peopleSelectors.isMember(
    state.main.people,
    ownProps.teamID,
    state.auth.userID,
    state.auth.teamID,
  ),
  teamMembers: peopleSelectors.selectTeamMembers(state.main.people, ownProps.teamID),
  managerSub: peopleSelectors.selectTeamManager(state.main.people, ownProps.teamID),
});

export default withLocation(
  withNavigation(
    connect(mapStateToProps)(MediaQueryWrapper()(withTranslation()(PanelStartFacilitation))),
  ),
);
