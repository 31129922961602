import React, { forwardRef, useEffect, useImperativeHandle, useContext } from 'react';
import PropTypes from 'prop-types';
import { RootDndContext } from './RootDndContext';

const InnerNestedDndContext = forwardRef(({ onDragEnd, children }, ref) => {
  // Expose the onDragEnd prop given to this context
  useImperativeHandle(ref, () => ({ onDragEnd }));

  return children;
});

InnerNestedDndContext.propTypes = {
  onDragEnd: PropTypes.func,
};

function NestedDndContext({ droppableId, onDragEnd, children }) {
  const { registerChild, unregisterChild } = useContext(RootDndContext);

  // Unregister this context from the root context
  useEffect(
    () => () => {
      // "componentWillUnMount"
      unregisterChild(droppableId);
    },
    [],
  );

  return (
    <InnerNestedDndContext
      ref={r => registerChild(droppableId, r)}
      droppableId={droppableId}
      onDragEnd={onDragEnd}
    >
      {children}
    </InnerNestedDndContext>
  );
}

NestedDndContext.propTypes = {
  droppableId: PropTypes.string,
  onDragEnd: PropTypes.func,
};

export default NestedDndContext;
