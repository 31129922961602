import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { programsSelectors, programsActions } from 'state/ducks/programs';
import { peopleSelectors } from 'state/ducks/people';
import { insightsActions, insightsSelectors } from 'state/ducks/insights';

import PanelCreateSurvey from './PanelCreateSurvey';

class PanelCreateSurveyContainer extends Component {
  componentDidMount() {
    const { dispatch, audienceID, audienceType, topicID } = this.props;
    if (audienceType === 'PRG') {
      dispatch(programsActions.getProgramUsers({ cpID: audienceID }));
    }
    dispatch(insightsActions.getSingleTopic({ topicID }));
  }

  render() {
    const {
      audienceID,
      audienceName,
      audienceType,
      numPeople,
      onClose,
      open,
      program,
      sub,
      teamList,
      topic,
      zIndexModifier,
    } = this.props;
    if (audienceType === 'PRG' && !program) {
      return null;
    }
    return (
      <PanelCreateSurvey
        open={open}
        onClose={onClose}
        onChange={this.onChange}
        topic={topic.data}
        sub={sub}
        numPeople={numPeople}
        audienceName={audienceName}
        audienceType={audienceType}
        audienceID={audienceID}
        teamList={teamList}
        zIndexModifier={zIndexModifier}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const topic = insightsSelectors.selectTopic(state.main.insights, ownProps.topicID);
  const audienceID = topic.ok ? topic.data.audienceID : null;
  const audienceType = topic.ok ? topic.data.audienceType : null;
  let numPeople;
  let people;
  let audienceName;
  let teamList = [];

  let program;
  if (audienceType === 'PRG') {
    people = programsSelectors.selectProgramUsers(state.main.programs, audienceID);
    numPeople = people.ok ? people.data.length : undefined;
    program = programsSelectors.selectProgramById(state.main.programs, audienceID);
    audienceName = !!program && program.name;
    // TODO: this should be memoized, it's pretty expensive to compute!
    const rootTeamId =
      program && program.type !== 'strategy'
        ? program.rootTeamId
        : peopleSelectors.selectRootTeam(state.main.people);

    if (rootTeamId) {
      teamList = peopleSelectors.flattenTree(
        peopleSelectors.selectHierarchyFromTeam(state.main.people, rootTeamId),
      );
    }
  } else {
    people = peopleSelectors.selectTeamMembers(state.main.people, audienceID);
    numPeople = people.ok ? people.members.length : undefined;
    audienceName = peopleSelectors.selectTeamName(state.main.people, audienceID);
  }

  return {
    isChangeManager: state.auth.isChangeManager,
    audienceID,
    audienceName,
    audienceType,
    program,
    teamList,
    topic,
    sub: state.auth.userID,
    numPeople,
  };
};

PanelCreateSurveyContainer.propTypes = {
  audienceID: PropTypes.string,
  audienceType: PropTypes.string,
  audienceName: PropTypes.string,
  topicID: PropTypes.string,
  program: PropTypes.object,
  sub: PropTypes.string,
  dispatch: PropTypes.func,
  topic: PropTypes.object,
  open: PropTypes.bool,
  numPeople: PropTypes.number,
  onClose: PropTypes.func,
  teamList: PropTypes.array,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(PanelCreateSurveyContainer);
