import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import CommitmentsIcon from './CommitmentsIcon';

const DEFAULT_STYLES = {
  borderRadius: '8px',
  backgroundColor: theme => `${theme.palette?.featureCommitments?.main}33`,
  height: 24,
  width: 24,
  fontSize: '18px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function FeatureIcon({ sx }) {
  sx = { ...DEFAULT_STYLES, ...sx };
  return (
    <Box sx={sx}>
      <CommitmentsIcon color="featureCommitments" fontSize="inherit" />
    </Box>
  );
}

FeatureIcon.propTypes = {
  sx: PropTypes.object,
};

export default withStyles(null, { withTheme: true })(FeatureIcon);
