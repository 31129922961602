import React from 'react';
import { Task } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

function CommitmentIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
      <Task height={15} width={15} fill="black" fillOpacity="0.6" x={5} y={4} />
      <defs>
        <clipPath id="clip0_985_6588">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default CommitmentIcon;
