import { nanoid } from 'nanoid';
import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
// TODO: need solve the cycle-problem
// eslint-disable-next-line import/no-cycle
import { deleteToken } from 'firebase-cfg';
import { API_BASE_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

// logics must be exported as separate named functions
// eslint-disable-next-line import/prefer-default-export
export const registerPushEndpoint = createLogic({
  type: types.REGISTER_PUSH_ENDPOINT,
  process: async ({ getState, action }, dispatch, done) => {
    const requestID = nanoid(10);
    const params = {
      requestID,
      endpoint_type: 'fcm',
      token: action.payload,
    };
    const cfg = selectors.selectCurrentDeviceCfg(getState().main.notificationconfig);
    if (!!cfg.hashed_arn) {
      params.endpoint_hash = cfg.hashed_arn;
    }

    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/registerpushtoken`, params, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.registeredPushEndpoint(res.data.result));
      })
      .catch(e => {
        if (e.response) {
          if (e.response.status === 400) {
            if (!!e.response.data && !!e.response.data.error) {
              const errorPayload = { ...action.payload };
              errorPayload.errormsg = e.response.data.error;
              dispatch(actions.errorTryAgainLater(errorPayload));
              if (e.response.data.error.includes('cannot be claimed')) {
                // Deleting the existing token, a new one will be requested eventually:
                deleteToken(params.token);
              }
            }
          }
        }
      })
      .then(() => done());
  },
});
