import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getCanvasImageCoordinates } from './helpers';

const styles = () => ({
  reactCrop: {
    display: 'block',
  },
});

const initialCropConfig = {
  unit: '%',
  height: 100,
  width: 100,
  x: 0,
  y: 0,
};

const getCroppedImg = async (image, crop) => {
  const canvas = document.createElement('canvas');

  const { sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight } =
    getCanvasImageCoordinates(image, crop);

  canvas.width = destWidth;
  canvas.height = destHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    image,
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    destX,
    destY,
    destWidth,
    destHeight,
  );

  return new Promise(resolve => {
    resolve(canvas.toDataURL('image/jpeg'));
  });
};

class ImageEditor extends Component {
  state = {
    crop: { ...initialCropConfig },
  };

  save = async () => {
    const src = await getCroppedImg(this.state.imageRef, this.state.crop);
    this.props.onSave(src);
  };

  onImageLoaded = imageRef => {
    this.setState({
      imageRef,
    });
  };

  onCropChange = (_, crop) => {
    this.setState({ crop });
  };

  render() {
    const { classes, menuState, onClose, t } = this.props;
    const { imageEditMenuOpen, b64img } = menuState;
    const { crop } = this.state;

    return (
      <Dialog open={Boolean(imageEditMenuOpen)}>
        <DialogContent>
          <ReactCrop
            className={classes.reactCrop}
            disabled={false}
            src={b64img}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropChange}
            onChange={this.onCropChange}
            keepSelection
          />
        </DialogContent>
        <DialogActions>
          <Button id="card-image-cancel" onClick={onClose}>
            {t('general.cancel')}
          </Button>
          <Button id="card-image-save" color="secondary" variant="contained" onClick={this.save}>
            {t('general.save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ImageEditor.propTypes = {
  classes: PropTypes.exact({
    reactCrop: PropTypes.string,
  }),
  menuState: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(ImageEditor));
