import React from 'react';
import PropTypes from 'prop-types';
import LabelIcon from '@mui/icons-material/Label';
import DataObjectChip from 'Components/Library/DataObjectChip/index';

interface ITagProps {
  tagText?: string;
  tagColor?: string;
}

// eslint-disable-next-line import/prefer-default-export
export function SearchItemTag({ tagText, tagColor }: ITagProps) {
  return (
    <DataObjectChip
      text={tagText}
      icon={<LabelIcon fontSize="small" sx={{ marginTop: '5px', color: tagColor }} />}
      variant="normal"
      color="white"
      actionable
    />
  );
}

SearchItemTag.propTypes = {
  tagText: PropTypes.string,
};
