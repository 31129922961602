import React from 'react';
import { Trans } from 'react-i18next';
import HelperText from 'Components/Common/HelperText';
import i18n from 'config/i18n';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = theme => ({
  helperText: {
    backgroundColor: theme.palette.background.app,
    padding: theme.spacing(2),
    borderRadius: '12px',
  },
});

// @TODO: unit tests
const getDueDateString = (value, locale) => {
  if (!locale || locale === 'en') {
    locale = 'en-US';
  }
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    /* Getting error with this, so adding time manualy */
    // timeStyle: 'short',
  };
  const date = new Date(Date.parse(value));
  const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);
  return `${formattedDate} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

export const getRelativeTime = value => {
  const timestampMoment = moment(value);
  const todayMoment = moment();

  const difference = moment.duration(timestampMoment.diff(todayMoment));

  // Get Days
  const days = Math.floor(difference.asDays());
  const daysFormatted = days ? `${days}d ` : '';

  // Get Hours
  const hours = difference.hours();
  const hoursFormatted = hours ? `${hours}h ` : '';

  // Get Minutes
  const minutes = difference.minutes();
  const minutesFormatted = minutes ? `${minutes}m` : '';

  // Use Intl.DurationFormat once the browser support is there.
  return [daysFormatted, hoursFormatted, minutesFormatted].join('');
};

function DateHint({ classes, value }) {
  const currentLang = i18n.language.substring(0, 2);
  return (
    <HelperText variant="hint" className={classes.helperText}>
      <Trans
        i18nKey="insights.dueDateHelperText"
        values={{
          dueDate: getDueDateString(value, currentLang),
          timeLeft: getRelativeTime(value, currentLang),
        }}
        components={[<b>placeholder</b>, <b>placeholder</b>]}
      />
    </HelperText>
  );
}

DateHint.propTypes = {
  classes: PropTypes.exact({
    helperText: PropTypes.string,
  }),
  value: PropTypes.string,
};

export default withStyles(styles)(DateHint);
