import React from 'react';
import Typography from '@mui/material/Typography';

function PopoverHeader({ children }) {
  return (
    <Typography
      variant="body2"
      color="text.disabled"
      sx={{
        pl: 2,
        height: '39px',
        lineHeight: '41px', // Align to the same position as the text in search inputs
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
    >
      {children}
    </Typography>
  );
}

export default PopoverHeader;
