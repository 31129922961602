export const tableHeaderStyles = theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    pageBreakInside: 'avoid',
    ...theme.shape,
    backgroundColor: theme.palette.background.paper,
    minHeight: 32,
    padding: theme.spacing(),
  },
  interactive: {
    boxShadow: theme.shadows[1],
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  tableContainer: {
    overflow: 'clip',
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('lg')]: {
      overflowX: 'auto',
      '-webkit-transform': 'translateZ(0)',
    },
  },
  minimizedStatusText: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.subtitle1,
    },
  },
  statusText: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h4,
    },
  },
  header: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
  },
  domainRow: {
    maxWidth: `calc(100% - 32px)`,
    overflow: 'hidden',
    marginBottom: theme.spacing(),
  },
  collapser: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    flexWrap: 'wrap',
  },
  chartContainer: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  chipContainer: {
    flexShrink: 0,
  },
  statusContainer: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  chip: {
    height: 22,
    ...theme.shape,
    display: 'flex',
    flexShrink: 20,
    minWidth: '1px',
    overflow: 'hidden',
    alignItems: 'center',
    padding: '0px 10px',
    color: theme.palette.text.secondary,
    border: '1px solid',
    '& span:first-of-type': {
      width: 20,
      textAlign: 'center',
    },
    '& span:last-child': {
      marginLeft: 2.2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    marginLeft: 4,
  },
  outdated: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '& .MuiTypography-root': {
      color: theme.palette.error.main,
    },
  },
  red: {
    color: theme.palette.confidence.red,
    borderColor: theme.palette.confidence.red,
    '& .MuiTypography-root': {
      color: theme.palette.confidence.red,
    },
  },
  green: {
    color: theme.palette.confidence.green,
    borderColor: theme.palette.confidence.green,
    '& .MuiTypography-root': {
      color: theme.palette.confidence.green,
    },
  },
  amber: {
    color: theme.palette.confidence.amber,
    borderColor: theme.palette.confidence.amber,
    '& .MuiTypography-root': {
      color: theme.palette.confidence.amber,
    },
  },
  greentext: {
    color: `${theme.palette.confidence.green} !important`,
  },
  redtext: {
    color: `${theme.palette.confidence.red} !important`,
  },
  xsTable: {
    flexGrow: 2,
  },
  modalRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 10, // Fill screen vertically
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      margin: 0,
      padding: 0,
      borderRadius: 0,
      top: 0,
      left: 0,
    },
  },
  flexGrow: {
    flexGrow: 10,
    flexShrink: 5,
  },
  scrollable: {
    overflowY: 'auto',
  },
});
