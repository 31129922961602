import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Popover from '@mui/material/Popover';

import UserChip from 'Components/Library/UserChip';
import SearchOverlay from './SearchOverlay';

const styles = () => ({
  searchPopoverPaper: {
    marginTop: -2, // Align the search input text vertically with the chip text
  },
});

function UserSelector(props) {
  const { classes, value, onSelect, color, filterFunc, disabled, showRecent = true, name } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openSearch = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const onSelectHandler = selectedSub => {
    closeSearch();
    onSelect(selectedSub);
  };

  return (
    <>
      <UserChip
        color={color}
        sub={value}
        actionable={!disabled}
        onClick={openSearch}
        name={name}
        actOnDownArrow
      />
      <Popover
        anchorEl={anchorEl}
        open={open}
        disableAutoFocus
        onClose={closeSearch}
        classes={{
          paper: classes.searchPopoverPaper,
        }}
      >
        <SearchOverlay
          name={name}
          onSelect={onSelectHandler}
          filterFunc={filterFunc}
          showRecent={showRecent}
        />
      </Popover>
    </>
  );
}

UserSelector.propTypes = {
  classes: PropTypes.exact({
    searchPopoverPaper: PropTypes.string,
  }),
  value: PropTypes.string,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  filterFunc: PropTypes.func,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  showRecent: PropTypes.bool,
  name: PropTypes.string,
};

export default withStyles(styles)(UserSelector);
