import * as types from './types';

export const fetchCommitment = payload => ({
  type: types.FETCH_COMMITMENT,
  payload,
});

export const fetchContributors = payload => ({
  type: types.FETCH_CONTRIBUTORS,
  payload,
});

export const fetchedContributors = payload => ({
  type: types.RECEIVED_CONTRIBUTORS,
  payload,
});

export const failedContributors = payload => ({
  type: types.FAILED_CONTRIBUTORS,
  payload,
});

export const editContributors = payload => ({
  type: types.EDIT_CONTRIBUTORS,
  payload,
});

export const updatedContributors = payload => ({
  type: types.CONTRIBUTORS_UPDATED,
  payload,
});

export const fetchUserCommitments = payload => ({
  type: types.FETCH_USER_COMMITMENTS,
  payload,
});

export const fetchCompletedUserCommitments = payload => ({
  type: types.FETCH_COMPLETED_USER_COMMITMENTS,
  payload,
});

export const fetchTeamCommitments = payload => ({
  type: types.FETCH_TEAM_COMMITMENTS,
  payload,
});

export const fetchCompletedTeamCommitments = payload => ({
  type: types.FETCH_COMPLETED_TEAM_COMMITMENTS,
  payload,
});

export const fetchedCommitment = payload => ({
  type: types.RECEIVED_COMMITMENT,
  payload,
});

export const failedCommitment = payload => ({
  type: types.FAILED_COMMITMENT,
  payload,
});

export const addCommitment = payload => ({
  type: types.ADD_COMMITMENT,
  payload,
});

export const addedCommitment = payload => ({
  type: types.ADDED_COMMITMENT,
  payload,
});

export const editCommitment = payload => ({
  type: types.EDIT_COMMITMENT,
  payload,
});

export const updatedCommitment = payload => ({
  type: types.COMMITMENT_UPDATED,
  payload,
});

export const deleteCommitment = payload => ({
  type: types.DELETE_COMMITMENT,
  payload,
});

export const commitmentDeleted = payload => ({
  type: types.COMMITMENT_DELETED,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
