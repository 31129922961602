import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ObjectivesIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
      <g clipPath="url(#clip0_985_6588)">
        <path
          d="M17.3025 6.6975L16.245 7.755C17.325 8.8425 18 10.3425 18 12C18 15.315 15.315 18 12 18C8.685 18 6 15.315 6 12C6 8.94 8.2875 6.42 11.25 6.0525V7.5675C9.12 7.9275 7.5 9.7725 7.5 12C7.5 14.4825 9.5175 16.5 12 16.5C14.4825 16.5 16.5 14.4825 16.5 12C16.5 10.755 15.9975 9.63 15.18 8.82L14.1225 9.8775C14.6625 10.425 15 11.175 15 12C15 13.6575 13.6575 15 12 15C10.3425 15 9 13.6575 9 12C9 10.605 9.96 9.4425 11.25 9.105V10.71C10.8 10.9725 10.5 11.445 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.445 13.2 10.965 12.75 10.71V4.5H12C7.86 4.5 4.5 7.86 4.5 12C4.5 16.14 7.86 19.5 12 19.5C16.14 19.5 19.5 16.14 19.5 12C19.5 9.93 18.66 8.055 17.3025 6.6975Z"
          fill="black"
          fillOpacity="0.6"
        />
      </g>
      <defs>
        <clipPath id="clip0_985_6588">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default ObjectivesIcon;
