import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

import ReactCrop from 'react-image-crop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { nanoid } from 'nanoid';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';

import { peopleActions, peopleSelectors } from 'state/ducks/people';
import 'react-image-crop/dist/ReactCrop.css';

const styles = theme => ({
  reactCrop: {
    position: 'relative',
    maxWidth: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
});

const getCroppedImg = (image, pixelCrop) => {
  /* create cropped b64 image data string, downscale to 512*512 if larger */
  const canvas = document.createElement('canvas');
  const ratio = image.width / image.naturalWidth;
  const w = Math.min(512, pixelCrop.width / ratio);
  const h = Math.min(512, pixelCrop.height / ratio);

  canvas.width = w;
  canvas.height = h;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x / ratio,
    pixelCrop.y / ratio,
    pixelCrop.width / ratio,
    pixelCrop.height / ratio,
    0,
    0,
    w,
    h,
  );

  return new Promise(resolve => {
    resolve(canvas.toDataURL('image/jpeg'));
  });
};

class ProfileTab extends Component {
  state = {
    submitted: false,
    submitStatus: 0,
    crop: {
      aspect: 1,
      width: 50,
      x: 0,
      y: 0,
    },
    pixelCrop: false,
    src: '',
  };

  componentDidMount() {
    if (this.props.userDetails.avatar) {
      this.setState({
        src: this.props.userDetails.avatar,
        crop: {
          aspect: 1,
          width: 100,
          x: 0,
          y: 0,
        },
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitted === true) {
      if (this.state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[this.state.requestID].result === 'ok') {
          this.setState({ submitStatus: 1, submitted: false });
        } else {
          const errorMessage =
            nextProps.actionlog[this.state.requestID].message ||
            this.props.t('general.errorOccured');
          this.setState({ submitStatus: -1, submitted: false, lastError: errorMessage });
        }
      }
    }
  }

  isValid = () =>
    !!this.state.src && !this.state.imageError && !this.state.src.includes('data:image/svg');

  getValidatedState = newState => {
    newState.imageError = null;
    if (!this.state.src) {
      newState.imageError = true;
    }
    return newState;
  };

  handleSubmit = () => {
    const newState = this.getValidatedState({ ...this.state });
    if (!newState.imageError) {
      const requestID = nanoid(10);
      this.setState({ submitted: true, submitStatus: 0, requestID });

      this.generatePreview().then(imagedata => {
        const payload = {
          requestID,
          b64str: imagedata,
          sub: this.props.sub,
        };
        this.props.dispatch(peopleActions.setAvatar(payload));
      });
    } else {
      this.setState(newState);
    }
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const context = this;
      reader.addEventListener('load', () => {
        context.setState({ src: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
    this.setState({ imageError: false });
  };

  onCropChange = pixelCrop => {
    this.setState({ crop: pixelCrop, imageError: false });
  };

  onImageError = () => {
    this.setState({ imageError: true });
  };

  generatePreview = async () => {
    const { crop } = this.state;
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageData = await getCroppedImg(this.imageRef, crop);
      return croppedImageData;
    }
    return null;
  };

  render() {
    const { classes, t, userDetails, email, username } = this.props;
    const { lastError, submitStatus, submitted, src, crop } = this.state;
    const { height, width } = crop;
    const notValid = !this.isValid();

    return (
      <>
        <SavingIndicator
          className={classes.savingIndicator}
          submitted={submitted}
          submitStatus={submitStatus}
          errorText={lastError}
        />
        <Typography variant="body1" gutterBottom>
          {t('usersettings.profile.subtitlePersonalInformation')}
        </Typography>
        <Typography variant="caption">{t('general.fullname')}</Typography>
        <Typography variant="subtitle2" gutterBottom>
          {userDetails.fullName}
        </Typography>
        <Typography variant="caption">{t('general.email')}</Typography>
        <Typography variant="subtitle2" gutterBottom>
          {email}
        </Typography>
        {email !== username && (
          <>
            <Typography variant="caption">{t('general.username')}</Typography>
            <Typography variant="subtitle2">{username}</Typography>
          </>
        )}
        <Divider className={classes.divider} />
        <Typography variant="body1" gutterBottom>
          {t('usersettings.profile.subtitleProfilePicture')}
        </Typography>
        <br />
        {src && (
          <div id="user-profile-dialog-image-div">
            <ReactCrop
              className={classes.reactCrop}
              disabled={submitted}
              src={src}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropChange}
              onChange={this.onCropChange}
              onImageError={this.onImageError}
            />
          </div>
        )}
        {notValid && (
          <div id="user-settings-error">{this.props.t('contextv2.cards.badfileerror')}</div>
        )}
        <input
          accept="image/bmp, image/jpeg, image/png, image/webp, image/avif, image/apng, image/gif"
          type="file"
          style={{ display: 'none' }}
          id="raised-button-file"
          onChange={this.onSelectFile}
        />
        <label htmlFor="raised-button-file">
          <Button component="span" id="user-profile-dialog-upload-btn">
            {t('general.uploadFile')}
          </Button>
        </label>
        <Button
          id="user-profile-dialog-save-button"
          disabled={submitted || !!!width || !!!height || notValid}
          color="secondary"
          onClick={this.handleSubmit}
          variant="contained"
        >
          {t('general.save')}
        </Button>
      </>
    );
  }
}

const mapStateToProps = state => ({
  actionlog: state.main.people.actionlog,
  userDetails: peopleSelectors.selectUser(state.main.people, state.auth.userID),
  sub: state.auth.userID,
  email: state.auth.email,
  username: state.auth.username,
});

ProfileTab.propTypes = {
  classes: PropTypes.exact({
    reactCrop: PropTypes.string,
    error: PropTypes.string,
    savingIndicator: PropTypes.string,
    divider: PropTypes.string,
  }),
  actionlog: PropTypes.object,
  userDetails: PropTypes.object,
  sub: PropTypes.string,
  fullName: PropTypes.string,
  email: PropTypes.string,
  username: PropTypes.string,
  dispatch: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(connect(mapStateToProps)(withStyles(styles)(ProfileTab)));
