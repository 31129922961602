import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import TgProgress from 'Components/Common/TgProgress';

const styles = theme => ({
  root: {
    minHeight: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },
  spinner: {
    marginBottom: theme.spacing(2),
  },
});

class LoadingCard extends React.Component {
  render() {
    const { classes, className, label = this.props.t('general.loading') } = this.props;
    return (
      <Card className={clsx([classes.root, className])}>
        <TgProgress color="secondary" className={classes.spinner} />
        <Typography variant="subtitle1">{label}</Typography>
      </Card>
    );
  }
}

LoadingCard.propTypes = {
  label: PropTypes.number,
  classes: PropTypes.exact({
    root: PropTypes.string,
    spinner: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(LoadingCard));
