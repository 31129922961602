import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import queryString from 'query-string';
import TgProgress from 'Components/Common/TgProgress';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import { INTEGRATIONS_API_BASE_URL } from 'state/constants/api';
import { instance as axios } from 'config/axios';
import { withLocation } from 'withRouter';

const styles = theme => ({
  content: {
    padding: 64,
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  paper: {
    overflowX: 'auto',
    margin: '0 auto',
    padding: '5%',
  },
  subtitle: {
    margin: '0 auto',
    width: 'max-content',
  },
  errorsubtitle: {
    margin: '0 auto',
    width: 'max-content',
  },
  button: {
    width: 100,
    left: 'calc(50% - 50px)',
    marginTop: '5%',
  },
  progress: {
    margin: '5% calc(50% - 22px)',
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
});

class IntegrationsAuthorizationView extends Component {
  constructor(props) {
    super(props);
    const parsedQs = queryString.parse(this.props.location.search);

    const hasParams = !!parsedQs.serviceName && !!parsedQs.code && !!parsedQs.state;
    this.state = {
      status: 'pending',
      hasParams,
      parsedQs,
      submitted: false,
      submitStatus: 0,
      serverErrorText: '',
    };
  }

  componentDidMount() {
    const { accessToken, tenantID } = this.props;
    const { parsedQs, hasParams } = this.state;

    if (hasParams) {
      this.setState({ submitted: true, submitStatus: 1 });
      const requestID = nanoid(10);
      const redirectUri = `${window.location.origin}${
        window.location.pathname
      }?${queryString.stringify({
        serviceName: parsedQs.serviceName,
      })}`;
      axios
        .post(
          `${INTEGRATIONS_API_BASE_URL}/${tenantID}/imidentity`,
          {
            code: parsedQs.code,
            state: parsedQs.state,
            redirect_uri: redirectUri,
            serviceName: parsedQs.serviceName,
            requestID,
          },
          { headers: { Authorization: `Bearer ${accessToken}` } },
        )
        .then(response => {
          if (!!response.data && response.data.result) {
            this.setState({
              status: 'done',
              submitted: false,
              submitStatus: 0,
            });
          } else {
            this.setState({
              status: 'failed',
              submitted: false,
              submitStatus: 0,
            });
          }
        })
        .catch(e => {
          this.setState({
            status: 'error',
            submitted: false,
            submitStatus: -1,
          });
          if (!!e.response && !!e.response.data && !!e.response.data.message) {
            this.setState({
              serverErrorText: e.response.data.message,
            });
          }
        });
    }
  }

  render() {
    const { t, classes } = this.props;
    const { hasParams, status, serverErrorText, submitted, submitStatus } = this.state;

    return (
      <div className={classes.main}>
        <SavingIndicator
          className={classes.savingIndicator}
          submitted={submitted}
          submitStatus={submitStatus}
          errorText={serverErrorText}
        />
        <div id="content" className={classes.content}>
          <Paper className={classes.paper}>
            {hasParams ? (
              <React.Fragment>
                {status === 'pending' && (
                  <React.Fragment>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                      {t('binding.pending')}
                    </Typography>
                    <TgProgress color="secondary" className={classes.progress} />
                  </React.Fragment>
                )}

                {status === 'done' && (
                  <React.Fragment>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                      {`${t('general.done')}! 🎉`}
                    </Typography>
                    <Button
                      component={Link}
                      to="/home"
                      name="integrations-return-home"
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                    >
                      {t('general.continue')}
                    </Button>
                  </React.Fragment>
                )}

                {status === 'failed' && (
                  <React.Fragment>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                      {t('admin.integrations.failed')}
                    </Typography>
                    <Button
                      component={Link}
                      to="/home"
                      name="integrations-return-home"
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                    >
                      {t('general.continue')}
                    </Button>
                  </React.Fragment>
                )}

                {status === 'error' && (
                  <React.Fragment>
                    <Typography variant="subtitle2" className={classes.errorsubtitle}>
                      {t('general.oops')}
                    </Typography>
                    <Button
                      component={Link}
                      to="/home"
                      name="integrations-return-home"
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                    >
                      {t('general.continue')}
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="subtitle2" className={classes.errorsubtitle}>
                  {t('admin.integrations.errorCouldNotStartIntegration')}
                </Typography>
                <br />
                <Button
                  component={Link}
                  to="/home"
                  name="integrations-return-home"
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                >
                  {t('general.goHomeCta')}
                </Button>
              </React.Fragment>
            )}
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tenantID: state.auth.tenantID,
  accessToken: state.auth.tokens.access_token,
});

IntegrationsAuthorizationView.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    main: PropTypes.string,
    paper: PropTypes.string,
    subtitle: PropTypes.string,
    errorsubtitle: PropTypes.string,
    button: PropTypes.string,
    progress: PropTypes.string,
    savingIndicator: PropTypes.string,
  }),
  t: PropTypes.func,
  accessToken: PropTypes.string,
  tenantID: PropTypes.string,
  location: PropTypes.object,
};

export default withLocation(
  connect(mapStateToProps)(withTranslation()(withStyles(styles)(IntegrationsAuthorizationView))),
);
