// eslint-disable-next-line import/prefer-default-export
export const styles = theme => ({
  dialogPaper: {
    position: 'absolute',
    top: '50%',
    width: 400,
    maxWidth: '100%',
    transform: 'translateY(-150px)',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      // fullscreen on mobile:
      transform: 'none',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      maxHeight: '100%',
      borderRadius: 0,
    },
  },
  searchRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  searchContainer: {
    flexGrow: 100,
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
  container: {
    flexGrow: 1,
    maxHeight: 300,
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      height: '100%',
    },
    overflow: 'hidden',
  },
  containerOpen: {
    overflowY: 'auto',
    maxHeight: 300 - 61,
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      height: '100%',
    },
  },
  suggestion: {
    display: 'block',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(),
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  input: {
    paddingRight: 0,
    height: 61,
  },
});
