import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import BrokenImage from './broken-image.svg';

class ProtectedImage extends Component {
  render() {
    const {
      t,
      filepath,
      tenantId,
      width,
      className,
      swTokenConfirmed,
      style,
      alt = '',
    } = this.props;
    if (!('serviceWorker' in navigator)) {
      return (
        <Tooltip title={t('general.protectedMediaNoSw')}>
          <img
            style={{ width: '25%', opacity: '30%', margin: 'auto', display: 'block' }}
            src={BrokenImage}
            alt={t('general.protectedMediaNoSw')}
          />
        </Tooltip>
      );
    }
    if (!swTokenConfirmed) {
      return null;
    }
    return (
      <img
        src={`${process.env.REACT_APP_MEDIA_URL}/${tenantId}/${filepath}`}
        alt={alt}
        crossOrigin="use-credentials"
        className={className}
        width={width}
        style={style}
      />
    );
  }
}

ProtectedImage.propTypes = {
  alt: PropTypes.string,
  filepath: PropTypes.string,
  tenantId: PropTypes.string,
  width: PropTypes.string,
  swTokenConfirmed: PropTypes.bool,
  t: PropTypes.func,
  style: PropTypes.object,
};

const mapStateToProps = state => ({
  tenantId: state.auth.tenantID,
  swTokenConfirmed: state.auth.swTokenConfirmed,
});

export default withTranslation()(connect(mapStateToProps)(ProtectedImage));
