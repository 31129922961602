import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { withLocation } from 'withRouter';
import { peopleSelectors } from 'state/ducks/people';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import LinkItem from './LinkItem';
import ExpandButton from './ExpandButton';

const generateNavLink = (location, teamID) => {
  if (location.pathname.includes('/home/teams/')) {
    // replace the teamID
    const pathParts = location.pathname.split('/');
    pathParts[pathParts.indexOf('teams') + 1] = teamID;
    return pathParts.join('/');
  }
  return `/home/teams/${teamID}`;
};

class TeamSelector extends Component {
  state = {
    expanded: true,
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { t, classes, location, selectTeamName, managedTeams, matrixTeams, ownTeamId } =
      this.props;

    const { expanded } = this.state;

    const teams = [];
    if (!!ownTeamId && !managedTeams.includes(ownTeamId)) {
      teams.push({
        to: generateNavLink(location, ownTeamId),
        linkIcon: <PersonasAvatar size="xtiny" sub={ownTeamId} type="team" />,
        linkText: selectTeamName(ownTeamId),
        id: `mainnav-teamselector-${ownTeamId}`,
        key: `mainnav-teamselector-${ownTeamId}`,
      });
    }

    if (!!matrixTeams) {
      matrixTeams.forEach(teamObject => {
        if (!managedTeams.includes(teamObject.id) && teamObject.id !== ownTeamId) {
          teams.push({
            to: generateNavLink(location, teamObject.id),
            linkIcon: <PersonasAvatar size="xtiny" sub={teamObject.id} type="team" />,
            linkText: selectTeamName(teamObject.id),
            id: `mainnav-teamselector-${teamObject.id}`,
            key: `mainnav-teamselector-${teamObject.id}`,
          });
        }
      });
    }

    if (teams.length === 0) {
      return null;
    }

    return (
      <div className={classes.selectorRoot}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: 24,
            overflow: 'visible',
          }}
        >
          <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
            {t('mainNavigation.sectionTitleTeams')}
          </Typography>
          <ExpandButton
            expanded={expanded}
            onToggle={this.toggleExpanded}
            name="main-nav-toggle-expand-my-teams"
          />
        </Box>
        <List dense component="div">
          {teams.map(teamProps => {
            if (expanded || location.pathname.includes(teamProps.to)) {
              return <LinkItem {...teamProps} name="linkitem-my-teams" />;
            }
            return null;
          })}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const lineTeamId = peopleSelectors.isCeo(state.main.people, state.auth.userID)
    ? peopleSelectors.selectRootTeam(state.main.people)
    : state.auth.teamID;

  return {
    ownTeamId: lineTeamId,
    matrixTeams: peopleSelectors.selectUsersMatrixTeams(state.main.people, state.auth.userID),
    managedTeams: peopleSelectors.selectTeamsManagedBy(state.main.people, state.auth.userID),
    selectTeamName: id => peopleSelectors.selectTeamName(state.main.people, id),
  };
};

TeamSelector.propTypes = {
  ownTeamId: PropTypes.string,
  matrixTeams: PropTypes.array,
  managedTeams: PropTypes.array,
  selectTeamName: PropTypes.func,
  classes: PropTypes.shape({
    selectorRoot: PropTypes.string,
  }),
  location: PropTypes.object,
  t: PropTypes.func,
};

export default withLocation(connect(mapStateToProps)(withTranslation()(TeamSelector)));
