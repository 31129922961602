import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import { SectionLabel } from 'Components/Library/Shared/ChipAutocomplete';
import TeamChip from 'Components/Library/TeamChip';
import LinkItem from 'Components/Library/MainNav/LinkItem';
import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import KeyresultChip from 'Components/Features/Objectives/KeyresultChip';
import NoResults from 'Components/Library/GlobalSearch/NoResults';
import { styles } from 'Components/Library/GlobalSearch/styles';
import CommitmentChip from 'Components/Features/Commitments/CommitmentChip';
import InterlockChip from 'Components/Features/Interlocks/InterlockChip';
import UserChip from 'Components/Library/UserChip';

const getSectionSuggestions = section => {
  if (section) {
    return section.suggestions;
  }
  return [];
};

const renderSectionTitle = section => (
  <SectionLabel
    section={{
      title: section.title.toLowerCase(),
      suggestions: section.suggestions,
    }}
  />
);

const getSuggestionValue = suggestion => {
  switch (suggestion.type) {
    case 'team':
      return suggestion.text;
    case 'KEY_RESULT':
    case 'OBJECTIVE':
      return suggestion.full_text;
    case 'COMMITMENT':
      return suggestion.full_text;
    case 'INTERLOCK':
      return suggestion.full_text;
    case 'USER':
      return suggestion.text;
    default:
      return null;
  }
};

const renderSuggestion = (suggestion, { isHighlighted }) => {
  switch (suggestion.type) {
    case 'team':
      return (
        <TeamChip
          id={`org-search-team-result-${suggestion.id}`}
          name="org-search-team-result"
          teamId={suggestion.id}
          color={isHighlighted ? 'grey' : 'transparent'}
          actionable
        />
      );
    case 'KEY_RESULT':
      return (
        <KeyresultChip
          keyresultID={suggestion.node_id}
          placeholder={suggestion.full_text}
          color={isHighlighted ? 'grey' : 'transparent'}
          allowNavigate={false}
          actionable
        />
      );
    case 'OBJECTIVE':
      return (
        <ObjectiveChip
          objectiveID={suggestion.node_id}
          placeholder={suggestion.full_text}
          color={isHighlighted ? 'grey' : 'transparent'}
          allowNavigate={false}
          actionable
        />
      );
    case 'COMMITMENT':
      return (
        <CommitmentChip
          ContextIcon={null}
          key={`commitments-widget-${suggestion.node_id}`}
          graphId={suggestion.node_id}
          color={isHighlighted ? 'grey' : 'transparent'}
          allowNavigate={false}
          actionable
        />
      );
    case 'INTERLOCK':
      return (
        <InterlockChip
          ContextIcon={null}
          key={`interlocks-widget-${suggestion.node_id}`}
          graphId={suggestion.node_id}
          color={isHighlighted ? 'grey' : 'transparent'}
          allowNavigate={false}
          actionable
        />
      );
    case 'USER':
      return (
        <UserChip sub={suggestion.id} color={isHighlighted ? 'grey' : 'transparent'} actionable />
      );
    default:
      return null;
  }
};

class GlobalSearch extends React.Component {
  state = {
    dialogOpened: this.props.searchDialogOpened,
    searchString: '',
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDownHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDownHandler);
  }

  onKeyDownHandler = e => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
      e.preventDefault();
      this.setState({ dialogOpened: !this.state.dialogOpened });
    }
  };

  handleSuggestionSelected = (event, data) => {
    this.setState({ dialogOpened: false, searchString: '' });
    if (data) {
      const { suggestion } = data;
      this.props.onSelect(suggestion);
    }
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.props.onFetchRequested(value);
  };

  closeSearch = () => {
    this.setState({ dialogOpened: false, searchString: '' });
  };

  renderInput = inputProps => {
    const { classes, fetching } = this.props;
    const { inputClasses, ref, variant, label, placeholder, ...other } = inputProps;

    return (
      <div className={classes.searchRow}>
        {fetching ? (
          <CircularProgress name="CircularProgress" size={20} sx={{ ml: '2px', mr: '2px' }} />
        ) : (
          <SearchIcon color="action" />
        )}
        <div className={classes.searchContainer}>
          <InlineEditInput
            name="global-search-input"
            variant="h6"
            fullWidth
            type="text"
            value={other.value}
            onChange={other.onChange}
            onBlur={other.onBlur}
            onFocus={other.onFocus}
            onKeyDown={other.onKeyDown}
            autoComplete="off"
            autoFocus
            className={classes.input}
            placeholder={!!label ? label : placeholder}
          />
        </div>
        <IconButton onClick={this.closeSearch} size="large">
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;
    const { searchString } = this.state;
    const { suggestions, fetching } = this.props;
    const hasResults = suggestions.some(
      suggestionsBlock => suggestionsBlock?.suggestions?.length > 0,
    );
    const hasUserInput = searchString.length >= 2;

    return (
      <div
        key={containerProps.key}
        ref={containerProps.ref}
        role={containerProps.role}
        style={containerProps.style}
        className={containerProps.className}
        id="global-search-suggestions-container"
        aria-label={this.props.t('objectives.globalSearchPlaceholder')}
      >
        {children}
        {!hasResults && hasUserInput && !fetching && <NoResults />}
      </div>
    );
  };

  render() {
    const { t, classes, autoFocus, error, className, label, fetching, id, suggestions } =
      this.props;
    const { dialogOpened, searchString } = this.state;

    return (
      <>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={dialogOpened}
          onClose={this.closeSearch}
        >
          <Autosuggest
            theme={{
              container: clsx([classes.container, className]),
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
              suggestionsContainer: classes.containerOpen,
            }}
            renderInputComponent={this.renderInput}
            suggestions={suggestions}
            multiSection
            alwaysRenderSuggestions
            focusInputOnSuggestionClick={false}
            getSectionItems={section => section.suggestions}
            getSectionSuggestions={getSectionSuggestions}
            renderSectionTitle={renderSectionTitle}
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            // this will prevent clearing up search result on using keyboard
            onSuggestionsClearRequested={() => {}}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            onSuggestionSelected={this.handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={(options, item) => renderSuggestion(options, item)}
            id={id}
            inputProps={{
              inputClasses: classes,
              error,
              value: searchString,
              onChange: (event, { newValue }) => this.setState({ searchString: newValue }),
              autoFocus,
              placeholder: label,
              fetching,
              id: `react-autowhatever-${id}`, // this id is needed for the aria to work properly
            }}
          />
        </Dialog>
        <LinkItem
          linkIcon={<SearchIcon fontSize="small" />}
          linkText={t('general.searchLabel')}
          variant="large"
          name="global-search-main-nav-link"
          onClick={() => this.setState({ dialogOpened: true })}
        />
      </>
    );
  }
}

GlobalSearch.propTypes = {
  suggestions: PropTypes.array,
  onFetchRequested: PropTypes.func,
  fetching: PropTypes.bool,
  classes: PropTypes.exact({
    container: PropTypes.string,
    searchRow: PropTypes.string,
    input: PropTypes.string,
    searchContainer: PropTypes.string,
    containerOpen: PropTypes.string,
    dialogPaper: PropTypes.string,
    suggestion: PropTypes.string,
    suggestionsList: PropTypes.string,
  }),
  t: PropTypes.func,
  autoFocus: PropTypes.func,
  error: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  onSelect: PropTypes.func,
  searchDialogOpened: PropTypes.bool,
};

GlobalSearch.defaultProps = {
  searchDialogOpened: false,
  suggestions: [],
};

export default withTranslation()(withStyles(styles)(GlobalSearch));
