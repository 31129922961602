import React from 'react';
import { useTranslation } from 'react-i18next';
import DarkModeToggle from 'Components/Common/DialogUserSettings2/DarkModeToggle';

function ThemeTab() {
  const { t } = useTranslation();

  return (
    <>
      <p>{t('usersettings.theme.content')}</p>
      <DarkModeToggle />
    </>
  );
}

export default ThemeTab;
