import { useSelector } from 'react-redux';
import { peopleSelectors } from 'state/ducks/people';

function useCanCreateForDomain(domain) {
  // We're going to perform a lot of checks, let's just get the whole state
  const state: any = useSelector(state => state);

  const canCreate =
    state.auth.isChangeManager ||
    (domain.t === 'team' &&
      peopleSelectors.isMember(
        state.main.people,
        domain.d,
        state.auth.userID,
        state.auth.teamID,
      )) ||
    (domain.t === 'team' &&
      peopleSelectors
        .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
        .includes(domain.t));

  return canCreate;
}

export default useCanCreateForDomain;
