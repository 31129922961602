import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingCard from 'Components/Common/LoadingCard';
import EmptyState from 'Components/Common/EmptyState';
import InsightsEmptyStateIcon from './empty_state-icon-insights.svg';
import InsightsCard from '../InsightsCard';

class InsightsOverviewWidget extends Component {
  render() {
    const {
      surveys,
      surveyIDs,
      audienceType,
      isMember,
      isManager,
      isManagerOfManager,
      isChangeManager,
      minimized = false,
    } = this.props;
    if (audienceType === 'TEAM') {
      if (!isMember && !isManager && !isManagerOfManager && !isChangeManager) {
        return (
          <Box m={6} alignContent="center">
            <EmptyState icon={InsightsEmptyStateIcon} translationgroup="insights" noaccess />
          </Box>
        );
      }
    }
    return (
      <>
        {surveys && surveys.length > 0 && (
          <Grid
            item
            container
            spacing={3}
            md={12}
            sm={12}
            xs={12}
            alignItems="stretch"
            alignContent="stretch"
          >
            {surveys.map(survey => (
              <Grid
                item
                key={`insights-overview-card-${survey.surveyID}`}
                xs={12}
                sm={6}
                md={minimized ? false : 4}
              >
                <InsightsCard surveyID={survey.surveyID} />
              </Grid>
            ))}
          </Grid>
        )}
        {!!surveys && !!surveyIDs && surveyIDs.ok && surveys.length === 0 && (
          <Box justifyContent="center" flexDirection="row">
            <EmptyState icon={InsightsEmptyStateIcon} translationgroup="insights" />
          </Box>
        )}
        {(!surveyIDs || !surveyIDs.ok) && (
          <Grid
            item
            container
            spacing={1}
            md={12}
            sm={12}
            xs={12}
            alignItems="stretch"
            alignContent="stretch"
          >
            <Grid item xs={12} sm={6} md={minimized ? false : 4} lg={minimized ? false : 3}>
              <LoadingCard />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

InsightsOverviewWidget.propTypes = {
  surveys: PropTypes.array,
  surveyIDs: PropTypes.object,
  minimized: PropTypes.string,
  audienceType: PropTypes.string,
  isMember: PropTypes.bool,
  isManager: PropTypes.bool,
  isManagerOfManager: PropTypes.bool,
  isChangeManager: PropTypes.bool,
};

export default InsightsOverviewWidget;
