import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CanEditKeyResultWrapper from 'Components/Features/Objectives/CanEditKeyResultWrapper';

import { objectivesSelectors, objectivesHelpers, objectivesActions } from 'state/ducks/objectives';
import PanelViewKeyresult from './PanelViewKeyresult';

class PanelViewKeyresultContainer extends React.Component {
  componentDidUpdate() {
    const { deleted, objectiveID, objectiveData, dispatch } = this.props;
    if (!!deleted) {
      // objectiveData was fetched but this KR was not found, assume it has been deleted and silently close the panel
      this.onClose();
    }
    if (objectiveID && (!objectiveData || !objectiveData.ok)) {
      dispatch(objectivesActions.getObjective({ objectiveIDs: [objectiveID] }));
    }
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <CanEditKeyResultWrapper
        render={canEdit => (
          <PanelViewKeyresult
            deleted={this.props.deleted}
            dispatch={this.props.dispatch}
            keyresult={this.props.keyresult}
            keyresultID={this.props.keyresultID}
            objectiveData={this.props.objectiveData}
            onClose={this.onClose}
            open={this.props.open}
            period={this.props.period}
            canEdit={canEdit}
            zIndexModifier={this.props.zIndexModifier}
          />
        )}
        keyresult={this.props.keyresult}
        keyresultID={this.props.keyresultID}
        objectiveData={this.props.objectiveData}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const objectiveID = objectivesHelpers.parseObjectiveIDfromKeyresultID(ownProps.keyresultID);
  const objectiveData = objectivesSelectors.selectObjective(state.main.objectives, objectiveID);
  let krData;
  if (!!objectiveData && objectiveData.ok) {
    krData = objectivesSelectors.selectKeyresult(state.main.objectives, ownProps.keyresultID);
  }
  // TODO: how to handle link to deleted KR, it should handle gracefully, basic oh noes should be enough

  return {
    objectiveData,
    objectiveID,
    keyresult: krData,
    deleted: objectiveData.ok && !krData,
    period:
      ownProps.period === '-'
        ? objectivesSelectors.selectBestStPeriodForObjective(
            state.main.objectives,
            ownProps.objectiveID,
          )
        : ownProps.period,
  };
};

PanelViewKeyresultContainer.propTypes = {
  deleted: PropTypes.bool,
  dispatch: PropTypes.func,
  keyresult: PropTypes.object,
  keyresultID: PropTypes.string,
  objectiveID: PropTypes.string,
  period: PropTypes.string,
  objectiveData: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(PanelViewKeyresultContainer);
