/* eslint-disable import/prefer-default-export */
export const START_FACILITATION_SESSION = 'START_FACILITATION_SESSION';
export const STARTED_FACILITATION_SESSION = 'STARTED_FACILITATION_SESSION';
export const DELETE_FACILITATION_SESSION = 'DELETE_FACILITATION_SESSION';
export const FACILITATION_SESSION_DELETED = 'FACILITATION_SESSION_DELETED';
export const FETCH_FACILITATION_SESSION = 'FETCH_FACILITATION_SESSION';
export const FETCHED_FACILITATION_SESSION = 'FETCHED_FACILITATION_SESSION';
export const FETCH_FACILITATION_INPUTS = 'FETCH_FACILITATION_INPUTS';
export const FETCHED_FACILITATION_INPUTS = 'FETCHED_FACILITATION_INPUTS';
export const FETCH_FACILITATION_VOTES = 'FETCH_FACILITATION_VOTES';
export const FETCHED_FACILITATION_VOTES = 'FETCHED_FACILITATION_VOTES';
export const FETCH_FACILITATION_SUMMARY = 'FETCH_FACILITATION_SUMMARY';
export const FETCHED_FACILITATION_SUMMARY = 'FETCHED_FACILITATION_SUMMARY';
export const LIST_FACILITATION_SESSIONS = 'LIST_FACILITATION_SESSIONS';
export const FETCHED_FACILITATION_SESSION_LIST = 'FETCHED_FACILITATION_SESSION_LIST';
export const SUBMIT_INPUT = 'SUBMIT_FACILITATION_INPUT';
export const SUBMITTED_INPUT = 'SUBMITTED_FACILITATION_INPUT';
export const SUBMIT_SUMMARY = 'SUBMIT_FACILITATION_SUMMARY';
export const SUBMITTED_SUMMARY = 'SUBMITTED_FACILITATION_SUMMARY';
export const SUBMIT_VOTES = 'SUBMIT_FACILITATION_VOTES';
export const SUBMITTED_VOTES = 'SUBMITTED_FACILITATION_VOTES';
export const SEND_HEARTBEAT = 'SEND_FACILITATION_HEARTBEAT';
export const RECEIVED_HEARTBEAT = 'RECEIVED_FACILITATION_HEARTBEAT';
export const SEND_PROGRESS_STATE = 'SEND_PROGRESS_STATE';
export const STATE_PROGRESSED = 'STATE_PROGRESSED';
export const ERROR_RECEIVED_FROM_API = 'FACILITATION_API_ERROR_RECEIVED';
export const ERROR_FAILED_TO_FETCH_SESSION = 'ERROR_FAILED_TO_FETCH_SESSION';
