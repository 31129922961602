import React, { MouseEventHandler } from 'react';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ExpandIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import DataObjectChip from 'Components/Library/DataObjectChip';
import TeamChip from 'Components/Library/TeamChip';
import UserChip from 'Components/Library/UserChip';
import { useSelector } from 'react-redux';
import { peopleSelectors } from 'state/ducks/people';
import { useTranslation, Trans } from 'react-i18next';
import TgProgress from 'Components/Common/TgProgress';
import MultipleObjectiveChart from './MultipleObjectiveChart';
import clsx from 'clsx';
import { objectivesSelectors } from 'state/ducks/objectives';
import moment from 'moment';
import { DomainOkrMetadata } from './helpers';
import { IDomainData, NodeDomain } from 'state/ducks/objectives/types';
import { tableHeaderStyles as styles } from './styles';

interface TableHeaderProps {
  domain: NodeDomain;
  okrData: IDomainData;
  showDomainName: boolean;
  period: string;
  canToggle: boolean;
  metadata: DomainOkrMetadata;
  showControls: boolean;
  onExpand: MouseEventHandler<HTMLButtonElement>;
  onClose: MouseEventHandler<HTMLButtonElement>;
  setIsMinimized: Function;
  isMinimized: boolean;
  // lazy cop-out for now:
  classes: { [key: string]: any };
}

function TableHeader(props: TableHeaderProps) {
  const {
    canToggle,
    domain,
    metadata,
    showControls = false,
    showDomainName = true,
    period,
    onExpand,
    onClose,
    classes,
    setIsMinimized,
    isMinimized,
  } = props;
  const { t } = useTranslation();

  const headerProps = !canToggle
    ? {}
    : { onClick: () => setIsMinimized(!isMinimized), sx: { cursor: 'pointer' } };
  let domainWidget;

  // ts-ignores here to avoid having to define types for the global
  // redux state objects, as we'll be moving away from these soon...
  // @ts-ignore
  const rootTeamSub = useSelector(state => peopleSelectors.selectRootTeam(state.main.people));
  const periodcfg = useSelector(state =>
    // @ts-ignore
    objectivesSelectors.selectStPeriodCfg(state.main.objectives, period),
  );

  if (domain.t === 'team') {
    domainWidget = <TeamChip teamId={domain.d} variant="normal" color="transparent" />;
  } else if (domain.t === 'personal') {
    domainWidget = <UserChip sub={domain.d} variant="normal" color="transparent" />;
  } else if (domain.t === 'company') {
    domainWidget = (
      <DataObjectChip
        icon={<PersonasAvatar sub={rootTeamSub} type="team" size="xtiny" />}
        variant="normal"
        color="transparent"
        text={t('objectives.companyobjectives')}
      />
    );
  }

  return (
    // @ts-ignore
    <Box {...headerProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 32,
            boxSizing: 'border-box',
            flexWrap: { xs: 'wrap', sm: 'nowrap' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexGrow: 100,
              minWidth: { xs: '100%', sm: '200px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                minWidth: '1px',
              }}
            >
              {metadata.loading && !metadata.refreshing && <TgProgress color="primary" size={32} />}
              {showDomainName && domainWidget}
            </Box>
            <Box sx={{ flexGrow: 100 }} />
            {isMinimized && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mr: { xs: 1, lg: 2 },
                }}
              >
                <Box
                  sx={{
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    minWidth: {
                      xs: 'auto',
                      sm: '144px',
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    component="span"
                    className={classes.minimizedStatusText}
                  >{`${metadata.statusPercent}%`}</Typography>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    display="inline"
                    sx={{ mt: 0.5, ml: 0.5 }}
                    className={clsx(classes[metadata.weeklyDelta >= 0 ? 'greentext' : 'redtext'])}
                  >
                    {`${metadata.weeklyDelta >= 0 ? '+' : ''}${metadata.weeklyDelta}%`}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: {
                  xs: '192px', // enough room for all chips with atleast emoji and number
                },
                width: { xs: 'auto', sm: '45%', lg: '45%', xl: 'auto' },
                flexShrink: {
                  xs: 100,
                  sm: 0, // For larger screens, we want the chips to occupy the same amount of space
                },
              }}
            >
              <div
                data-tg-name="okr-header-good-krs"
                className={clsx(
                  classes.chip,
                  metadata.numGoodKrs !== 0 ? classes.green : classes.grey,
                )}
              >
                <Typography variant="body2" component="span">
                  👌
                </Typography>
                <Typography variant="body2" component="span">
                  {metadata.numGoodKrs}
                </Typography>
                <Typography variant="body2" component="span">
                  {t('objectives.goodkrs')}
                </Typography>
              </div>
              <div
                data-tg-name="okr-header-amber-krs"
                className={clsx(
                  classes.chip,
                  metadata.numAmberConfidenceKrs !== 0 ? classes.amber : classes.grey,
                )}
              >
                <Typography variant="body2" component="span">
                  🤞
                </Typography>
                <Typography variant="body2" component="span">
                  {metadata.numAmberConfidenceKrs}
                </Typography>
                <Typography variant="body2" component="span">
                  {t('objectives.amberkrs')}
                </Typography>
              </div>
              <div
                data-tg-name="okr-header-red-krs"
                className={clsx(
                  classes.chip,
                  metadata.numRedConfidenceKrs !== 0 ? classes.red : classes.grey,
                )}
              >
                <Typography variant="body2" component="span">
                  👋
                </Typography>
                <Typography variant="body2" component="span">
                  {metadata.numRedConfidenceKrs}
                </Typography>
                <Typography variant="body2" component="span">
                  {t('objectives.redkrs')}
                </Typography>
              </div>
              {moment().isBefore(moment(periodcfg.periodEnd)) && (
                <div
                  data-tg-name="okr-header-outdated-krs"
                  className={clsx(
                    classes.chip,
                    metadata.numOutdatedKrs !== 0 ? classes.outdated : classes.grey,
                  )}
                >
                  <Typography variant="body2" component="span">
                    📅
                  </Typography>
                  <Typography variant="body2" component="span">
                    {metadata.numOutdatedKrs}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {t('objectives.outdatedkrs')}
                  </Typography>
                </div>
              )}
            </Box>
          </Box>
          {showControls && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <IconButton
                name="expand"
                onClick={onExpand}
                size="small"
                aria-label={t('general.expand')}
              >
                <ExpandIcon />
              </IconButton>
              <IconButton
                name="okr-header-close-button"
                onClick={onClose}
                size="small"
                aria-label={t('general.close')}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {!isMinimized && (
          <Box sx={{ p: 1 }}>
            <MultipleObjectiveChart
              loading={metadata.loading}
              events={metadata.events}
              period={periodcfg.periodStart}
              periodcfg={periodcfg}
              status={metadata.statusPercent}
              amberConfidenceKrs={metadata.numAmberConfidenceKrs}
              redConfidenceKrs={metadata.numRedConfidenceKrs}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              {!!metadata.loading ? (
                <Typography variant="h6">{t('general.loading')}</Typography>
              ) : (
                <div className={classes.statusContainer}>
                  <Typography
                    variant="h3"
                    className={classes.statusText}
                  >{`${metadata.statusPercent}%`}</Typography>
                  <Box sx={{ ml: 1 }}>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      display="inline"
                      className={clsx(classes[metadata.weeklyDelta >= 0 ? 'greentext' : 'redtext'])}
                    >
                      {`${metadata.weeklyDelta >= 0 ? '+' : ''}${metadata.weeklyDelta}%`}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      display="inline"
                      sx={{ display: { xs: 'none', sm: 'inline' } }}
                    >
                      <Trans i18nKey="objectives.tabledeltalastweek" />
                    </Typography>
                  </Box>
                </div>
              )}

              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography variant="subtitle1" component="span">
                    {t('objectives.genericDataObjectNameObjectives')}
                    &nbsp;
                    <span data-tg-name="okr-header-owned-objectives">
                      {metadata.numOwnedObjectives}
                    </span>
                    {`, ${t('objectives.genericDataObjectNameKeyresults')}`}
                    &nbsp;
                    <span data-tg-name="okr-header-owned-krs">{metadata.numOwnedKrs}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

// Convert styling to more modern MUI solution instead
// of going through the pain of defining proper types...
// @ts-ignore
export default withStyles(styles)(TableHeader);
