import React from 'react';
import { useSelector } from 'react-redux';
import { settingsSelectors } from 'state/ducks/usersettings';
import logodark from './media/logo_dark.png';
import logolight from './media/logo_light.png';

function TgLogo() {
  const darkmode = useSelector(state =>
    settingsSelectors.selectAttribute(state.usersettings, 'darkmode'),
  );

  const logoFile = darkmode ? logodark : logolight;
  return <img src={logoFile} alt="TG LOGO" width="246px" height="32px" />;
}

export default TgLogo;
