import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MAX_DAYS_BETWEEN_BEFORE_DATE_DISPLAY } from 'config/constants';

function DueDateChip(props) {
  const { dueDate, size, name } = props;
  let { color } = props;
  const { t } = useTranslation();

  if (!dueDate) {
    return null;
  }

  const momentDueDate = moment.unix(dueDate);
  const momentNow = moment();
  const daysBetween = momentDueDate.diff(momentNow, 'days');

  let label = '';
  if (daysBetween === 0) {
    label = t('general.today');
    color = 'error';
  } else if (daysBetween > MAX_DAYS_BETWEEN_BEFORE_DATE_DISPLAY) {
    label = momentDueDate.format('DD.MM.YYYY');
  } else if (daysBetween < MAX_DAYS_BETWEEN_BEFORE_DATE_DISPLAY + 1 && daysBetween > 0) {
    label = t('general.ndays', { days: daysBetween });
  } else if (daysBetween < 1 && daysBetween > -MAX_DAYS_BETWEEN_BEFORE_DATE_DISPLAY - 1) {
    label = t('general.ndaysago', { days: daysBetween * -1 });
    color = 'error';
  } else if (daysBetween < -MAX_DAYS_BETWEEN_BEFORE_DATE_DISPLAY) {
    label = momentDueDate.format('DD.MM.YYYY');
    color = 'error';
  }

  return <Chip color={color} label={label} name={name} size={size} />;
}

DueDateChip.propTypes = {
  dueDate: PropTypes.number,
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default DueDateChip;
