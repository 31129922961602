import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StandardDialog from 'Components/Library/StandardDialog';
import Form from 'Components/Library/Forms/';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';

import { objectivesActions } from 'state/ducks/objectives';

const schema = {
  type: 'object',
  additionalProperties: true,
};

class DialogConfirmDelete extends Component {
  render() {
    const { open, onCancel, objective, t } = this.props;

    if (!objective) {
      return null;
    }

    return (
      <Form
        name="confirm-delete-objective-form"
        schema={schema}
        initialValues={{}}
        stateSlice="main.objectives"
        submitActionCreator={objectivesActions.deleteObjective}
        additionalProperties={{ objectiveID: objective.objectiveID }}
        onSubmitSuccess={this.onObjectiveDeleted}
      >
        <StandardDialog open={open} onBackdropClick={onCancel} size="xs">
          <DialogTitle id="simple-dialog-title">
            {t('objectives.deleteobjectivedialogTitle')}
          </DialogTitle>
          <DialogContent>
            {!!objective && (
              <Typography variant="body1" sx={{ mb: 2 }}>
                <Trans
                  i18nKey="objectives.confirmobjectivedelete"
                  values={{ objective: objective.objective }}
                />
              </Typography>
            )}
            <LinearIndicator positioning="absolute" />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} id="delete-objective-form-cancel-button">
              {t('general.cancel')}
            </Button>
            <FormButton
              render={buttonProps => (
                <Button
                  id="delete-objective-form-confirm-button"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('general.delete')}
                </Button>
              )}
            />
          </DialogActions>
        </StandardDialog>
      </Form>
    );
  }
}

DialogConfirmDelete.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  objective: PropTypes.object,
  dispatch: PropTypes.func,
  open: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(DialogConfirmDelete);
