import React from 'react';

import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import TagsSearchOverlay from 'Components/Library/Tags/Details/TagsSearchOverlay';
import { tagsActions } from 'state/ducks/tags/index';
import { ITag } from 'state/ducks/tags/types';

interface IAddTagProps {
  existingTags: ITag[];
  instanceID: string;
  canEdit: boolean;
}

export default function LinkTag({ existingTags, instanceID, canEdit }: IAddTagProps) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openSearch = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const onSelectHandler = (tag: ITag) => {
    dispatch(tagsActions.linkTag({ id: tag.id, target_id: instanceID }));
    closeSearch();
  };

  if (!canEdit) return null;

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        sx={{
          justifyContent: 'start',
          alignSelf: 'flex-start',
        }}
        onClick={openSearch}
      >
        {t('tags.add')}
      </Button>
      <Popover anchorEl={anchorEl} open={open} disableAutoFocus onClose={closeSearch}>
        <TagsSearchOverlay onSelect={data => onSelectHandler(data)} existingTags={existingTags} />
      </Popover>
    </>
  );
}
