import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import StandardInput from 'Components/Library/StandardInput';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    ...theme.shape,
    backgroundColor: theme.palette.background.box,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
});

class FormSubmitSummary extends Component {
  render() {
    const { classes, summaryString, onSummaryChange, t, disabled } = this.props;
    return (
      <div className={classes.root} name="live-dfac-panel-summary-form">
        <Typography variant="h6" color="text.strong" sx={{ mb: 2 }}>
          {t('dfac.flowmanagersummaryinputlabel')}
        </Typography>
        <StandardInput
          id="live-dfac-panel-edit-summary-field"
          autoFocus
          inputProps={{ maxLength: '1024' }}
          label={t('dfac.summary')}
          rows={3}
          variant="standard"
          fullWidth
          multiline
          value={summaryString}
          onChange={onSummaryChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

FormSubmitSummary.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  summaryString: PropTypes.string,
  onSummaryChange: PropTypes.func,
  t: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(FormSubmitSummary));
