import reducer from './reducers';

import * as interlocksLogic from './logic';
import * as interlocksTypes from './types';
import * as interlocksActions from './actions';
import * as interlocksSelectors from './selectors';

export { interlocksLogic, interlocksTypes, interlocksActions, interlocksSelectors };

export default reducer;
