import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { peopleActions, peopleSelectors } from 'state/ducks/people';
import { getGradientCodeFromId, getInitials } from 'Components/Library/Visualizations/helpers';

const styles = theme => ({
  root: {
    flexGrow: 0,
  },
  square: {
    borderRadius: 4,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 8,
  },
  menupaper: {
    paddingBottom: 0,
  },
  disabledImg: {
    filter: 'grayscale(100%)',
  },
  xlarge: {
    width: 64,
    height: 64,
    fontSize: 22,
  },
  large: {
    width: 48,
    height: 48,
    fontSize: 20,
  },
  medium: {
    width: 40,
    height: 40,
    fontSize: 18,
  },
  small: {
    width: 32,
    height: 32,
    fontSize: 14,
  },
  tiny: {
    width: 24,
    height: 24,
    fontSize: 12,
  },
  xtiny: {
    width: 16,
    height: 16,
    fontSize: 10,
  },
  menuName: {
    padding: theme.spacing(2),
    paddingTop: 8,
    fontSize: '1rem',
    color: 'rgba(0,0,0,0.6)',
  },
  menuItem: {
    fontSize: 14,
  },
});

class PersonasAvatar extends Component {
  state = {
    menuOpen: false,
  };

  componentDidMount() {
    this.requestUserInfo();
  }

  componentDidUpdate() {
    this.requestUserInfo();
  }

  requestUserInfo() {
    const { sub, users, dispatch } = this.props;
    if (!!sub && (!users[sub] || users[sub].canFetch)) {
      dispatch(peopleActions.getPersons({ subs: [sub] }));
    }
  }

  renderContent() {
    const { userObj, sub, initials, ownSub, avatar, size = 'large' } = this.props;
    if (!!avatar) {
      return null;
    }
    if (sub === ownSub || userObj.ok) {
      return initials.toUpperCase();
    }
    if (!!userObj.loading) {
      return (
        <CircularProgress
          name="CircularProgress"
          color="secondary"
          size={size === 'large' ? 40 : size === 'small' ? 32 : 24}
        />
      );
    }
    return '?';
  }

  render() {
    const {
      classes,
      className,
      fullName,
      id,
      sub,
      size = 'large',
      disabled,
      enableTooltip = false,
      userObj,
      shape = 'square',
      t,
      originalId,
      type = 'employee',
    } = this.props;
    const { menuOpen } = this.state;
    const avatarUrl = userObj.ok ? userObj.avatar : '';
    const content = (
      <span className={clsx([classes.root, classes[shape], classes[size], className])}>
        <span
          data-tg-name="avatar-wrapper"
          aria-owns={menuOpen ? 'avatar-toggle-menu' : null}
          aria-haspopup={!enableTooltip ? 'true' : false}
          onMouseEnter={this.handleTooltipOpen}
          onMouseLeave={this.handleTooltipClose}
        >
          <Avatar
            id={!!id ? `avatar-${id}` : null}
            name="avatar-avatar"
            data-tg-name={`avatar-${originalId}`}
            sx={{
              background: getGradientCodeFromId(sub, type),
              borderRadius: 4,
            }}
            className={clsx([classes[shape], classes[size]])}
            src={avatarUrl}
            component="span"
            classes={{
              img: disabled && classes.disabledImg,
            }}
            alt={t('general.avatar')}
          >
            {!!!avatarUrl && type === 'employee' && this.renderContent()}
          </Avatar>
        </span>
      </span>
    );
    if (!enableTooltip) {
      return content;
    }
    return <Tooltip title={fullName}>{content}</Tooltip>;
  }
}

const mapStateToProps = (state, ownProps) => {
  let userObj;
  let fullName;
  let managerSub;
  if (ownProps.type === 'team') {
    fullName = peopleSelectors.selectTeamName(state.main.people, ownProps.sub);
    managerSub = peopleSelectors.selectTeamManager(state.main.people, ownProps.sub);
    userObj = peopleSelectors.selectUser(state.main.people, managerSub);
  } else {
    userObj = peopleSelectors.selectUser(state.main.people, ownProps.sub);
    fullName =
      ownProps.ownerId === state.auth.userID
        ? `${state.auth.firstName} ${state.auth.lastName}`
        : userObj && userObj.fullName && userObj.fullName;
  }

  return {
    users: state.main.people.subs,
    avatars: state.main.avatars,
    ownSub: state.auth.userID,
    initials: getInitials(ownProps.sub, state),
    fullName,
    userObj,
    // Hiding the team avatar gradients by forcing the type to team and
    // the sub to the managerSub if type === team, the gradient avatars
    // need some more design work
    sub: ownProps.type === 'team' ? managerSub : ownProps.sub,
    originalId: ownProps.sub,
    type: 'employee',
  };
};

PersonasAvatar.propTypes = {
  enableTooltip: PropTypes.bool,
  classes: PropTypes.exact({
    root: PropTypes.string,
    square: PropTypes.string,
    popover: PropTypes.string,
    paper: PropTypes.string,
    menupaper: PropTypes.string,
    disabledImg: PropTypes.string,
    small: PropTypes.string,
    large: PropTypes.string,
    medium: PropTypes.string,
    xlarge: PropTypes.string,
    tiny: PropTypes.string,
    xtiny: PropTypes.string,
    menuName: PropTypes.string,
    menuItem: PropTypes.string,
  }),
  ok: PropTypes.bool,
  dispatch: PropTypes.func,
  sub: PropTypes.string,
  originalId: PropTypes.string,
  canFetch: PropTypes.func,
  initials: PropTypes.string,
  userObj: PropTypes.object,
  ownSub: PropTypes.string,
  avatar: PropTypes.object,
  size: PropTypes.oneOf(['xtiny', 'tiny', 'small', 'medium', 'large', 'xlarge']),
  users: PropTypes.object,
  fullName: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  shape: PropTypes.string,
  t: PropTypes.func,
  type: PropTypes.string,
};

export default withTranslation()(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(PersonasAvatar)),
);
