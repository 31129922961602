import React from 'react';
import { SvgIcon } from '@mui/material';

function TgIcon(props) {
  return (
    <SvgIcon viewBox="-2 -2 16 17" {...props}>
      <path
        d="M4.28571 5.93909C4.28571 5.61108 4.54155 5.34518 4.85714 5.34518H7.11111C7.4267 5.34518 7.68254 5.61108 7.68254 5.93909V12.4061C7.68254 12.7341 7.4267 13 7.11111 13H4.85714C4.54155 13 4.28571 12.7341 4.28571 12.4061V5.93909Z"
        fill="white"
      />
      <path
        d="M8.60317 0.593909C8.60317 0.265902 8.85901 0 9.1746 0H11.4286C11.7442 0 12 0.265902 12 0.593909V12.4061C12 12.7341 11.7442 13 11.4286 13H9.1746C8.85901 13 8.60317 12.7341 8.60317 12.4061V0.593909Z"
        fill="white"
      />
      <path
        d="M0 9.43655C0 9.10854 0.255837 8.84264 0.571429 8.84264H2.8254C3.14099 8.84264 3.39683 9.10854 3.39683 9.43655V12.4061C3.39683 12.7341 3.14099 13 2.8254 13H0.571428C0.255837 13 0 12.7341 0 12.4061V9.43655Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default TgIcon;
