import { PURGE } from 'redux-persist';
import { get } from 'lodash';
import { validatePersistedState, enrich, copyState } from 'state/helpers';
import {
  addApiErrorToState,
  onDeleteSuccess,
  onFetchFailed,
  onFetchStarted,
  onListFetchSuccess,
  onSingleFetchSuccess,
} from 'state/defaultLogic';
import * as selectors from 'state/ducks/interlocks/selectors';
import { CONTRIBUTOR_TYPES } from 'config/constants';
import { getChartContributors, getPreparedChartData } from 'state/ducks/interlocks/utils';
import * as types from './types';
import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.04,
  interlocks: {},
  userInterlocks: {},
  teamInterlocks: {},
  contributors: {},
  userResolvedInterlocks: {},
  teamResolvedInterlocks: {},
  contributorsChart: {},
  actionlog: {},
};

const addInterlockTransformFunc = (state, payload) => {
  // function run on the state after adding an interlock,
  // adds to interlock ID to the lists for the creator
  // and owner if provided in the payload

  // it's somewhat questionable if this is required, opening
  // the freshly created interlock will trigger a fetch for
  // the contributors that will also handle the same.
  for (const userId of [payload.creator, payload.owner]) {
    if (userId) {
      if (!state.userInterlocks[userId]?.data?.length) {
        state.userInterlocks[userId] = enrich({
          // this makes sure that the logics know what to do when
          // they encounter this object
          fetchStatus: constants.PARTIAL,
          data: [],
        });
      }

      // adding fresh commitment id on top of already existing ones
      const userHasInterlock = state.userInterlocks[userId]?.data?.includes(payload.id);
      if (!!state.userInterlocks[userId]?.data && !userHasInterlock) {
        state.userInterlocks[userId]?.data.unshift(payload.id);
        state.userInterlocks[userId] = enrich(state.userInterlocks[userId]);
      }
    }
  }
};

const queryCompletedTransformFunc = (state, payload) => {
  // function run on the state after a list of commitments,
  // adds all the commitments to the "commitments" objects,
  // the lists under the contributors contain just IDs

  payload.nodes.forEach(node =>
    onSingleFetchSuccess({
      state,
      payload: node,
      key: 'interlocks',
      transformItemFn: data => ({
        ...data,
        ts: data.timestamp,
        description: JSON.parse(data.description),
      }),
      copyState: false,
      postProcessTransform: addInterlockTransformFunc,
    }),
  );
};

function addUpdatedInterlockToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  const { id, status } = payload;

  const oldStatus = newState.interlocks[id]?.data?.status;

  newState.interlocks[id] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    data: {
      ...payload,
      description: payload.description ? JSON.parse(payload.description) : null,
    },
  });

  if (oldStatus !== status && (oldStatus === 'RESOLVED' || status === 'RESOLVED')) {
    // This interlock should be moved in the state objects from the unresolved interlocks
    // list to the resolved interlocks list for all contributors
    const contributors = selectors.selectContributors(newState, id);
    if (contributors.ok) {
      for (const node of contributors.data) {
        const keyPrefix = node.type === CONTRIBUTOR_TYPES.TEAM ? 'team' : 'user';
        let removeFrom;
        let addTo;
        if (oldStatus === 'RESOLVED') {
          removeFrom = get(newState, `${keyPrefix}ResolvedInterlocks.${node.id}`, {});
          addTo = get(newState, `${keyPrefix}Interlocks.${node.id}`, {});
        } else {
          removeFrom = get(newState, `${keyPrefix}Interlocks.${node.id}`, {});
          addTo = get(newState, `${keyPrefix}ResolvedInterlocks.${node.id}`, {});
        }
        if (removeFrom.ok) {
          removeFrom.data = removeFrom.data.filter(cid => cid !== id);
        }
        if (addTo.ok && !addTo.data.includes(id)) {
          addTo.data.unshift(id);
        }
      }
    }
  }

  return newState;
}

const contributorsQueryCompletedTransformFunc = (state, payload) => {
  // function run on the state after receiving the contributors for a commitment,
  // adds the interlock ID to the interlock lists of the received contributors

  const interlockData = selectors.selectInterlock(state, payload.id);
  if (interlockData.ok) {
    for (const node of payload.nodes) {
      const keyPrefix = node.type === CONTRIBUTOR_TYPES.TEAM ? 'team' : 'user';
      const key = `${keyPrefix}Interlocks`;

      const domainData = get(state, `${key}.${node.id}`, {});
      if (domainData.ok && !domainData.data.includes(payload.id)) {
        state[key][node.id].data.unshift(payload.id);
        state[key][node.id] = enrich(state[key][node.id]);
      }
    }
  }
};

function addFetchedContributorsChartDataToState(state, payload) {
  const { nodes, id } = payload;
  const newState = copyState(state);
  const interlockData = state.interlocks[id].data;
  const chartContributors = getChartContributors(nodes);
  const temp = getPreparedChartData(chartContributors, nodes, interlockData);

  newState.contributorsChart[id] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    data: {
      contributors: chartContributors,
      contributorChartData: temp,
    },
  });

  return newState;
}

const onRefreshContributorsCache = (state, payload) => {
  const { id } = payload;
  const newState = copyState(state);

  newState.contributors[id] = enrich({
    data: [],
    fetchStatus: constants.PARTIAL,
    lastFetched: 1,
  });

  return newState;
};

// The params need to be in this order, that's what redux gives :)
// eslint-disable-next-line default-param-last
const reducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);

  switch (action.type) {
    case types.ADDED_INTERLOCK:
      return onSingleFetchSuccess({
        state,
        payload: action.payload,
        key: 'interlocks',
        transformItemFn: data => ({
          ...data,
          ts: data.timestamp,
          description: JSON.parse(data.description),
        }),
        postProcessTransform: (s, payload) => {
          addInterlockTransformFunc(s, payload);

          if ('requestID' in payload) {
            s.actionlog[payload.requestID] = { result: 'ok', data: { ...payload } };
          }
        },
      });
    case types.FETCH_USER_INTERLOCKS:
      return onFetchStarted({ state, payload: action.payload, key: 'userInterlocks' });
    case types.FETCH_TEAM_INTERLOCKS:
      return onFetchStarted({ state, payload: action.payload, key: 'teamInterlocks' });
    case types.FETCH_INTERLOCK:
      return onFetchStarted({ state, payload: action.payload, key: 'interlocks' });
    case types.FETCH_CONTRIBUTORS:
      return onFetchStarted({ state, payload: action.payload, key: 'contributors' });
    case types.FETCH_RESOLVED_USER_INTERLOCKS:
      return onFetchStarted({ state, payload: action.payload, key: 'userResolvedInterlocks' });
    case types.FETCH_RESOLVED_TEAM_INTERLOCKS:
      return onFetchStarted({ state, payload: action.payload, key: 'teamResolvedInterlocks' });
    case types.FETCH_CONTRIBUTOR_CHART_INTERLOCKS:
      return onFetchStarted({ state, payload: action.payload, key: 'contributorsChart' });
    case types.RECEIVED_USER_INTERLOCKS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'userInterlocks',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_TEAM_INTERLOCKS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'teamInterlocks',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_INTERLOCK:
      return onSingleFetchSuccess({
        state,
        payload: action.payload,
        key: 'interlocks',
        transformItemFn: data => ({
          ...data,
          ts: data.timestamp,
          description: action.payload.description ? JSON.parse(action.payload.description) : null,
        }),
        postProcessTransform: addInterlockTransformFunc,
      });
    case types.RECEIVED_CONTRIBUTORS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'contributors',
        postProcessTransform: contributorsQueryCompletedTransformFunc,
      });
    case types.RECEIVED_RESOLVED_USER_INTERLOCKS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'userResolvedInterlocks',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_RESOLVED_TEAM_INTERLOCKS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'teamResolvedInterlocks',
        mapFn: node => node.id,
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.RECEIVED_CONTRIBUTOR_CHART_INTERLOCKS:
      return addFetchedContributorsChartDataToState(state, action.payload);
    case types.CONTRIBUTORS_UPDATED:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'contributors',
        postProcessTransform: contributorsQueryCompletedTransformFunc,
      });
    case types.CONTRIBUTORS_STATUS_UPDATED:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'contributors',
        postProcessTransform: contributorsQueryCompletedTransformFunc,
      });
    case types.INTERLOCK_UPDATED:
      return addUpdatedInterlockToState(state, action.payload);
    case types.INTERLOCK_DELETED:
      return onDeleteSuccess({ state, payload: action.payload, key: 'interlocks' });
    case types.FAILED_INTERLOCK:
      return onFetchFailed({ state, payload: action.payload, key: 'interlocks' });
    case types.FAILED_USER_INTERLOCKS:
      return onFetchFailed({ state, payload: action.payload, key: 'userInterlocks' });
    case types.FAILED_TEAM_INTERLOCKS:
      return onFetchFailed({ state, payload: action.payload, key: 'teamInterlocks' });
    case types.FAILED_CONTRIBUTORS:
      return onFetchFailed({ state, payload: action.payload, key: 'contributors' });
    case types.FAILED_RESOLVED_USER_INTERLOCKS:
      return onFetchFailed({ state, payload: action.payload, key: 'userResolvedInterlocks' });
    case types.FAILED_RESOLVED_TEAM_INTERLOCKS:
      return onFetchFailed({ state, payload: action.payload, key: 'teamResolvedInterlocks' });
    case types.FAILED_CONTRIBUTOR_CHART_INTERLOCKS:
      return onFetchFailed({
        state,
        payload: action.payload,
        key: 'contributorsChart',
      });
    case types.REFRESH_CONTRIBUTORS_CACHE:
      return onRefreshContributorsCache(state, action.payload);
    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action.payload);

    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};

export default reducer;
