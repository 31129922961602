/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PaginatedDataGrid from 'Components/Library/PaginatedDataGrid';
import StatusSelector from 'Components/Library/StatusSelector';
import PersonaSelector from 'Components/Library/PersonaSelector';
import { commitmentsActions, commitmentsSelectors } from 'state/ducks/commitments/index';
import { NODE_TYPE_USER, NODE_TYPE_TEAM, COM_STATUS_COLORS } from 'config/constants';
import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import CommitmentRow from './CommitmentRow';
import CommitmentsTableHeader from './CommitmentsTableHeader';
import { Column } from 'Components/Library/Graph/types';

const FETCH_ACTION_MAP = {
  [NODE_TYPE_TEAM]: {
    ACTIVE: commitmentsActions.fetchTeamCommitments,
    DONE: commitmentsActions.fetchCompletedTeamCommitments,
  },
  [NODE_TYPE_USER]: {
    ACTIVE: commitmentsActions.fetchUserCommitments,
    DONE: commitmentsActions.fetchCompletedUserCommitments,
  },
};

const SELECTOR_MAP = {
  [NODE_TYPE_TEAM]: {
    ACTIVE: commitmentsSelectors.selectTeamCommitments,
    DONE: commitmentsSelectors.selectCompletedTeamCommitments,
  },
  [NODE_TYPE_USER]: {
    ACTIVE: commitmentsSelectors.selectUserCommitments,
    DONE: commitmentsSelectors.selectCompletedUserCommitments,
  },
};

export const fetchActionCreator = (domain, previousData, statusFilter, force) => {
  if (!domain) {
    return null;
  }
  const fetchTypeKey = statusFilter === 'COMPLETED' ? 'DONE' : 'ACTIVE';
  const payload = { force };
  if (previousData?.next_token && !force) {
    // if force is true, this is the initial mount of the component,
    // let's refresh
    payload.next_token = previousData.next_token;
  }
  payload.id = domain.id;

  return FETCH_ACTION_MAP[domain.type][fetchTypeKey](payload);
};

function CommitmentsGrid({ initialDomain, allowDomainChange }) {
  const { t } = useTranslation();
  const sub = useSelector(state => state.auth.userID);
  const [domain, setDomain] = useState(initialDomain || { type: NODE_TYPE_USER, id: sub });
  const [statusFilter, setStatusFilter] = useState('ALL_ACTIVE');
  const [sortColumn, setSortBy] = useState('default');
  // latest first
  const [sortOrder, setSortOrder] = useState(SORTING_ORDERS.desc);

  const selector = state => {
    const selectorStateKey = statusFilter === 'COMPLETED' ? 'DONE' : 'ACTIVE';
    return SELECTOR_MAP[domain.type][selectorStateKey](state.main.commitments, domain.id, {
      sortColumn,
      sortOrder,
    });
  };

  const statusOptions = [
    { option: 'ALL_ACTIVE', text: t('commitments.statusFilterAllActive'), color: 'primary' },
    ...Object.keys(COM_STATUS_COLORS).map(status => ({
      text: t(`commitments.statusValueText.${status}`),
      option: status,
      color: COM_STATUS_COLORS[status],
    })),
  ];

  return (
    <PaginatedDataGrid
      domain={domain}
      statusFilterNode={
        <StatusSelector
          variant="select"
          value={statusFilter}
          onSelect={setStatusFilter}
          options={statusOptions}
          name="status-selector"
        />
      }
      statusFilterValue={
        statusFilter === 'ALL_ACTIVE' ? ['PENDING', 'IN_PROGRESS', 'HELP_NEEDED'] : [statusFilter]
      }
      domainFilterNode={
        allowDomainChange ? (
          <PersonaSelector
            showRecent
            color="white"
            value={domain}
            onSelect={setDomain}
            name="paginated-data-grid-domain-selector"
            sx={{
              height: '24px !important',
              minHeight: '24px !important',
              borderRadius: '8px !important',
              maxWidth: '192px !important',
            }}
          />
        ) : null
      }
      fetchActionCreator={(previousData, force) =>
        fetchActionCreator(domain, previousData, statusFilter, force)
      }
      selector={state => selector(state)}
      RowElement={CommitmentRow}
      columns={[
        Column.StatusSmall,
        Column.Name,
        Column.Domains,
        Column.DueDate,
        Column.LastUpdated,
      ]}
      TableHeaderElement={CommitmentsTableHeader}
      translationNameSpace="commitments"
      sortColumn={sortColumn}
      sortOrder={sortOrder}
      setSortBy={setSortBy}
      setSortOrder={setSortOrder}
    />
  );
}

CommitmentsGrid.propTypes = {
  initialDomain: PropTypes.object,
  allowDomainChange: PropTypes.bool,
};

CommitmentsGrid.defaultProps = {
  allowDomainChange: true,
};

export default CommitmentsGrid;
