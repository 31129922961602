import { PURGE } from 'redux-persist';
import { validatePersistedState } from 'state/helpers';
import * as types from './types';

export const initialState = {
  VERSION: 1.02,
  status: {
    optOutDate: null,
  },
};

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.PWA_OPT_OUT:
      return { ...state, status: { optOutDate: action.payload } };
    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
