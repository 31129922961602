import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { settingsActions, settingsSelectors } from 'state/ducks/usersettings/index';
import FeatureFlag from 'Components/Library/FeatureFlag';
import LightModeBg from './light_mode.svg';
import DarkModeBg from './dark_mode.svg';

function DarkModeToggle() {
  const darkmode = useSelector(state =>
    settingsSelectors.selectAttribute(state.usersettings, 'darkmode'),
  );

  const [darkModeOption, setdarkModeOption] = useState(darkmode ? 'dark' : 'light');
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <FeatureFlag flag="DARKMODE">
      <div>
        <RadioGroup onChange={e => setdarkModeOption(e.target.value)} value={darkModeOption} row>
          <FormControlLabel
            key="isLightModeSelected"
            value="light"
            control={<Radio color="primary" />}
            label={t(`usersettings.theme.light`)}
            sx={{
              marginLeft: '20px',
              height: 160,
              width: 120,
              boxSizing: 'border-box',
              paddingTop: '124px',
              backgroundImage: `url(${LightModeBg})`,
              backgroundSize: '110px',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <FormControlLabel
            key="isDarkModeSelected"
            value="dark"
            control={<Radio color="primary" />}
            label={t(`usersettings.theme.dark`)}
            sx={{
              marginLeft: '20px',
              height: 160,
              width: 120,
              boxSizing: 'border-box',
              paddingTop: '124px',
              backgroundImage: `url(${DarkModeBg})`,
              backgroundSize: '110px',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </RadioGroup>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mt: 3 }}
          onClick={() =>
            dispatch(
              settingsActions.setAttribute({ name: 'darkmode', value: darkModeOption === 'dark' }),
            )
          }
        >
          {t('general.save')}
        </Button>
      </div>
    </FeatureFlag>
  );
}

export default DarkModeToggle;
