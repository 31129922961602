import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import {
  Chip,
  Popover,
  Box,
  Button,
  Stack,
  RadioGroup,
  Radio,
  Typography,
  Divider,
} from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { isEnabled } from 'config/flags';
import { appstatusSelectors } from 'state/ducks/appstatus';
import DataObjectChip from 'Components/Library/DataObjectChip';
import AiLoadingIndicator from '../AiLoadingIndicator';
import AiError from '../AiError';

function AiPopover({ dataGetter, onReceive, result = {}, onClose, onApprove, SuggestionIcon }) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [selectedSuggestion, handleSuggestionSelect] = useState(null);

  const open = Boolean(anchorEl);
  const hasResult = !isEmpty(result);

  const appstatus = useSelector(state => appstatusSelectors.selectStatus(state.main.appstatus));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLoading(true);
    setError(false);
    onClose();
  };

  const dispatch = () => {
    setLoading(true);
    setError(false);
    dataGetter({ language: i18n.language.split('-')[0] })
      .then(res => {
        onReceive(res.data.result);
        if (get(res.data.result, 'suggestions', []).length === 1) {
          handleSuggestionSelect(0);
        }
        setLoading(false);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log('caught: ', e);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      dispatch();
    }
  }, [open]);

  if (!isEnabled('AI', appstatus)) {
    return null;
  }

  return (
    <>
      <Chip
        variant="outlined"
        onClick={handleClick}
        color="secondary"
        icon={<AutoFixHigh color="inherit" />}
        label={t('ai.askAiChip')}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            // Needed to force reflow after loading is done
            // and we know the length of the content:
            minHeight: isLoading ? 16 : 80,
            maxWidth: 'calc(100% - 32px)',
          },
        }}
      >
        <Box
          sx={{
            width: isLoading ? 256 : 400,
            maxWidth: 'calc(100% - 24px)',
            m: 3,
          }}
        >
          {isLoading && <AiLoadingIndicator title={t('ai.generatingRecommendations')} />}
          {!isLoading && hasResult && (
            <Stack spacing={1}>
              <Typography variant="h6">
                <AutoFixHigh sx={{ fontSize: '16px', mr: 1 }} />
                {t('ai.recommendationsTitle')}
              </Typography>
              <Box
                sx={{
                  borderRadius: 1,
                  p: 2,
                  pt: 1.5,
                  pb: 1.5,
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: 'action.hover',
                }}
              >
                <ReportProblemOutlinedIcon fontSize="small" color="disabled" sx={{ mr: 2 }} />
                <Typography variant="body2" color="text.disabled">
                  {t('ai.betaDisclaimer')}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: 1,
                  p: 2,
                  pt: 1.5,
                  pb: 1.5,
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: 'background.box',
                }}
              >
                <Typography component="section" variant="body2" color="text.primary">
                  {result.comment &&
                    result.comment
                      .split(/\r?\n/)
                      .map((line, lineNo) => <p key={`ai-answer-line-${lineNo}`}>{line}</p>)}
                </Typography>
              </Box>
              {result.suggestions && result.suggestions.length > 0 && (
                <>
                  <Typography variant="subtitle1" sx={{ pt: 1.5, pb: 0.5 }}>
                    {result.suggestions.length === 1
                      ? t('ai.suggestionsTitleSingle')
                      : t('ai.suggestionsTitleMultiple')}
                  </Typography>
                  <RadioGroup
                    onChange={e => handleSuggestionSelect(e.target.value)}
                    value={selectedSuggestion}
                    sx={{ alignItems: 'stretch' }}
                  >
                    {result.suggestions.map((s, index) => (
                      <Radio
                        size="small"
                        disableRipple
                        sx={{
                          p: 0,
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          '&:hover .MuiBox-root': {
                            boxShadow: theme =>
                              `inset 0px 0px 0px 100px ${theme.palette.action.hover}`,
                          },
                        }}
                        key={`ai-answer-suggestion-${s.suggestion}`}
                        checkedIcon={
                          <DataObjectChip
                            Icon={SuggestionIcon}
                            sx={{ flexGrow: 1 }}
                            text={s.suggestion}
                            color="grey"
                            wrapText
                          />
                        }
                        icon={
                          <DataObjectChip
                            Icon={SuggestionIcon}
                            sx={{ flexGrow: 1 }}
                            text={s.suggestion}
                            color="transparent"
                            wrapText
                          />
                        }
                        value={index}
                        data-testid="ai-suggestion-radio-button"
                      />
                    ))}
                  </RadioGroup>
                </>
              )}
            </Stack>
          )}
          {hasError && <AiError onRetry={dispatch} />}
          {!isLoading && hasResult && (
            <Stack spacing={1} sx={{ mt: 2 }}>
              <Divider />
              <Button
                size="small"
                variant="text"
                onClick={handleClose}
                data-testid="close-popover-button"
              >
                {t('ai.closePopoverButton')}
              </Button>
              {result.suggestions.length > 0 && (
                <Button
                  disabled={selectedSuggestion === null}
                  onClick={() => {
                    onApprove(result.suggestions[selectedSuggestion].suggestion);
                    handleClose();
                  }}
                  size="small"
                  color="secondary"
                  variant="contained"
                  data-testid="use-suggestion-button"
                >
                  {t('ai.useSuggestionButton')}
                </Button>
              )}
            </Stack>
          )}
        </Box>
      </Popover>
    </>
  );
}

AiPopover.propTypes = {
  dataGetter: PropTypes.func.isRequired,
  onReceive: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onApprove: PropTypes.func,
  result: PropTypes.object,
  SuggestionIcon: PropTypes.elementType,
};

export default AiPopover;
