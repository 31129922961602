import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { connectionSelectors } from 'state/ducks/connection';
import { NEEDS_REFRESH, NEEDS_LOGIN, NEEDS_LOGOUT } from 'state/ducks/auth/selectors';
import { authSelectors, authActions } from 'state/ducks/auth';
import { logout } from 'config/helpers';

function UnwrappedAuthGate() {
  const dispatch = useDispatch();
  const location = useLocation();

  const auth = useSelector(state => state.auth);
  const authStatus = useSelector(state => authSelectors.isAuthValid(state));

  const isOnline = useSelector(state =>
    connectionSelectors.selectOnlineStatus(state.main.connection),
  );

  const refreshTokens = () => {
    if (authStatus === NEEDS_REFRESH) {
      dispatch(authActions.refreshTokens({ force: true }));
    }
  };

  useEffect(() => {
    refreshTokens();
  });

  if (isOnline && authStatus === NEEDS_LOGOUT) {
    // The refresh token did not work, let's clear all local state
    logout(auth, {
      nextTenant: auth?.tenantID,
      nextPath: window.location.pathname + window.location.hash,
    });
    return null;
  }

  if (isOnline && authStatus === NEEDS_LOGIN) {
    // The user is simply not logged in at all yet
    return (
      <Navigate
        to="/login"
        state={{ from: location.pathname + location.hash, search: location.search }}
      />
    );
  }

  return <Outlet />;
}

export default UnwrappedAuthGate;
