import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import StandardDialog from '../StandardDialog';

class StandardConfirmationDialog extends Component {
  render() {
    const {
      open,
      children,
      name,
      title,
      secondaryAction,
      tertiaryAction,
      primaryAction,
      onBackdropClick,
      id,
    } = this.props;

    return (
      <StandardDialog id={id} name={name} size="xs" open={open} onBackdropClick={onBackdropClick}>
        {!!title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {!!secondaryAction && (
            <Button
              name={`${name}-secondary-action`}
              color="primary"
              disabled={secondaryAction.disabled}
              onClick={secondaryAction.onClick}
            >
              {secondaryAction.label}
            </Button>
          )}
          {!!tertiaryAction && (
            <Button
              name={`${name}-tertiary-action`}
              color="primary"
              disabled={tertiaryAction.disabled}
              onClick={tertiaryAction.onClick}
              variant="outlined"
            >
              {tertiaryAction.label}
            </Button>
          )}
          {!!primaryAction && (
            <Button
              name={`${name}-primary-action`}
              color="secondary"
              variant="contained"
              disabled={primaryAction.disabled}
              onClick={primaryAction.onClick}
            >
              {primaryAction.label}
            </Button>
          )}
        </DialogActions>
      </StandardDialog>
    );
  }
}

StandardConfirmationDialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string,
  onBackdropClick: PropTypes.func,
  title: PropTypes.string,
  primaryAction: PropTypes.object,
  secondaryAction: PropTypes.object,
  tertiaryAction: PropTypes.object,
};

export default StandardConfirmationDialog;
