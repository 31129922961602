// eslint-disable-next-line import/prefer-default-export
export const generateTeamNavLink = (location, teamID) => {
  if (location.pathname.includes('/home/teams/')) {
    // replace the teamID
    const pathParts = location.pathname.split('/');
    pathParts[pathParts.indexOf('teams') + 1] = teamID;
    return pathParts.join('/');
  }
  return `/home/teams/${teamID}`;
};

export const getRecentTeams = (searchFor = [], recentTeams = [], teamNames = []) => {
  const recent = [];
  if (searchFor.includes('teams')) {
    for (const recentTeamId of recentTeams) {
      if (!!recentTeamId && !!teamNames[recentTeamId]) {
        recent.push({
          type: 'team',
          id: recentTeamId,
          text: teamNames[recentTeamId],
        });
      }
    }
  }
  return recent;
};
