import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';

function CommittedIcon(props) {
  const { t, fontSize, color } = props;
  return (
    <Tooltip title={t('objectives.committedKrTooltip')}>
      <VerifiedIcon name="committed-kr-icon" sx={{ fontSize }} fontSize={fontSize} color={color} />
    </Tooltip>
  );
}

CommittedIcon.propTypes = {
  t: PropTypes.func,
  fontSize: PropTypes.string,
  color: PropTypes.string,
};

export default withTranslation()(CommittedIcon);
