import { createLogic } from 'redux-logic';
import axios from 'axios';
import { TENANT_API_BASE_URL } from '../../constants/api';
import * as actions from './actions';
import * as types from './types';

const getTenantFromApi = function getTenantFromApiFunction(payload) {
  return axios.get(`${TENANT_API_BASE_URL}`, { params: payload });
};

const getTenantLogic = createLogic({
  type: types.GET_TENANT,
  latest: true,

  process: async ({ action }, dispatch, done) => {
    getTenantFromApi(action.payload)
      .then(res => {
        dispatch(actions.tenantFetchSuccess(res.data.result));
      })
      .catch(() => {
        dispatch(actions.tenantFetchFailed({ msg: 'ERROR' }));
      })
      .then(() => done());
  },
});

// eslint-disable-next-line
export { getTenantLogic };
