import React from 'react';

function ListItem(props) {
  const { children } = props;
  return <li>{children}</li>;
}

ListItem.propTypes = {};

export default ListItem;
