import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { nanoid } from 'nanoid';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { peopleActions, peopleSelectors } from 'state/ducks/people';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';

const styles = theme => ({
  buttonRed: {
    color: theme.palette.confidence.red,
  },
  buttonGreen: {
    color: theme.palette.confidence.green,
  },
  buttonAmber: {
    color: theme.palette.confidence.amber,
    pointerEvents: 'none',
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

class JoinTeamButton extends Component {
  state = {
    requestID: null,
    submitted: false,
    submitStatus: null,
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.submitted === true) {
      if (state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[state.requestID].result === 'ok') {
          return {
            submitStatus: 1,
            submitted: false,
            errorText: '',
          };
        }
        return {
          submitStatus: -1,
          submitted: false,
          errorText: nextProps.actionlog[state.requestID].message,
        };
      }
    }
    return null;
  }

  submitJoinRequest = () => {
    const { dispatch, teamId } = this.props;
    const { submitted } = this.state;
    if (submitted) {
      return;
    }
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    dispatch(peopleActions.requestToJoinTeam({ teamID: teamId, requestID }));
  };

  submitLeaveRequest = () => {
    const { dispatch, teamId } = this.props;
    const { submitted } = this.state;
    if (submitted) {
      return;
    }
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    dispatch(peopleActions.leaveTeam({ teamID: teamId, requestID }));
  };

  render() {
    const { classes, t, isManager, isMember, teamId, teamIsInvitable, myJoinRequests } = this.props;
    const { submitStatus, submitted } = this.state;
    const indicator = (
      <SavingIndicator
        submitStatus={submitStatus}
        submitted={submitted}
        className={classes.savingIndicator}
      />
    );

    if (!!isManager) {
      return null;
    }

    if (!!teamIsInvitable) {
      if (!!isMember && !isManager) {
        return (
          <>
            {indicator}
            <div className={classes.buttonRed}>
              <Button
                onClick={this.submitLeaveRequest}
                variant="outlined"
                color="inherit"
                fullWidth
                name="team-info-leave-team-btn"
              >
                {t('teamHeaderInformation.leaveTeamCta')}
              </Button>
            </div>
          </>
        );
      }
      if (!!myJoinRequests.ok) {
        if (myJoinRequests.data.includes(teamId)) {
          return (
            <>
              {indicator}
              <div className={classes.buttonAmber}>
                <Button
                  variant="outlined"
                  color="inherit"
                  fullWidth
                  name="team-info-leave-pending-approval-btn"
                >
                  {t('teamHeaderInformation.pendingApproval')}
                </Button>
              </div>
            </>
          );
        }
        return (
          <>
            {indicator}
            <div className={classes.buttonGreen}>
              <Button
                onClick={this.submitJoinRequest}
                variant="outlined"
                color="inherit"
                fullWidth
                name="team-info-request-invite-btn"
              >
                {t('teamHeaderInformation.requestInviteCta')}
              </Button>
            </div>
          </>
        );
      }
    }
    return null;
  }
}

JoinTeamButton.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.exact({
    buttonRed: PropTypes.string,
    buttonGreen: PropTypes.string,
    buttonAmber: PropTypes.string,
    savingIndicator: PropTypes.string,
  }),
  myJoinRequests: PropTypes.object,
  teamIsInvitable: PropTypes.bool,
  isMember: PropTypes.bool,
  isManager: PropTypes.bool,
  dispatch: PropTypes.func,
  teamId: PropTypes.string,
  actionlog: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  actionlog: state.main.people.actionlog,
  teamIsInvitable: peopleSelectors.canRequestToJoin(state.main.people, ownProps.teamId),
  isManager:
    peopleSelectors.selectTeamManager(state.main.people, ownProps.teamId) === state.auth.userID,
  isMember: peopleSelectors.isMember(
    state.main.people,
    ownProps.teamId,
    state.auth.userID,
    state.auth.teamID,
  ),
  myJoinRequests: peopleSelectors.selectMyJoinRequests(state.main.people),
});

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(JoinTeamButton)));
