import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import DueDateChip from 'Components/Library/DueDateChip';
import StatusSelector from 'Components/Library/StatusSelector';
import Form from 'Components/Library/Forms/';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import { getLastModifiedStringFromTimestamp } from 'config/helpers';
import { commitmentsSelectors, commitmentsActions } from 'state/ducks/commitments';
import { peopleSelectors } from 'state/ducks/people';
import { openViewCommitmentPanel } from 'config/ModalProvider/helpers';
import { COM_STATUS_COLORS, CONTRIBUTOR_TYPES } from 'config/constants';
import { editSchema } from 'Components/Features/Commitments/schema';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PersonasAvatar from 'Components/Common/PersonasAvatar';

const styles = theme => ({
  commitmentRow: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: 1,
    paddingBottom: 1,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover h6': {
      color: theme.palette.text.primary,
    },
    // cannot apply border radius to a row, but we need
    // rounded corners for the hover effect:
    '& > td:first-of-type': {
      paddingLeft: theme.spacing(),
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '& > td:last-of-type': {
      paddingLeft: theme.spacing(),
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
  },
  commitmentCell: {
    paddingLeft: 0,
    verticalAlign: 'middle',
    minHeight: '32px',
    color: theme.palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0,
    '& h6': {
      // ensure the rows conform to the grid:
      lineHeight: '1.25rem',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
});

const handleRowClick = (graphId, navigate, location) => {
  openViewCommitmentPanel(graphId, false, navigate, location);
};

const renderContributor = contributor => {
  if (contributor.type !== CONTRIBUTOR_TYPES.TEAM && contributor.type !== CONTRIBUTOR_TYPES.USER) {
    return null;
  }
  return (
    <Box
      key={`commitment-grid-contributor-${contributor.id}`}
      sx={{
        display: 'inline-flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '24px',
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          minHeight: '24px',
          minWidth: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PersonasAvatar
          sub={contributor.id}
          type={contributor.type === CONTRIBUTOR_TYPES.USER ? 'employee' : 'team'}
          size="xtiny"
          enableTooltip
        />
      </Box>
    </Box>
  );
};

function CommitmentRow(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes, graphId, statusFilter } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // TODO: this should be optimized... most likely the information should be cached
  // on the nodes
  useEffect(() => {
    dispatch(commitmentsActions.fetchContributors({ id: graphId, force: false }));
  }, []);

  const commitment = useSelector(
    state => commitmentsSelectors.selectCommitment(state.main.commitments, graphId),
    // { stabilityCheck: 'never' },
  ).data;

  const managedTeamList = useSelector(
    state => peopleSelectors.selectTeamsManagedBy(state.main.people, state.auth.userID),
    // { stabilityCheck: 'never' },
  );

  const canEdit = useSelector(
    state =>
      commitmentsSelectors.canEditCommitment({
        slice: state.main.commitments,
        commitmentID: graphId,
        userId: state.auth.userID,
        managedTeamIds: managedTeamList,
        isChangeManager: state.auth.isChangeManager,
      }),
    // { stabilityCheck: 'never' },
  );

  const contributors = useSelector(
    state => commitmentsSelectors.selectContributors(state.main.commitments, graphId),
    // { stabilityCheck: 'never' },
  ).data;

  const hasContributors = !!contributors?.length;

  if (!commitment) {
    return null;
  }
  if (statusFilter && !statusFilter.includes(commitment?.status)) {
    return null;
  }

  let initialValues = {};
  if (commitment) {
    initialValues = {
      status: commitment.status,
    };
  }

  const contributorsElements =
    hasContributors &&
    contributors.map(contributor => renderContributor(contributor)).filter(Boolean);

  return (
    <TableRow
      className={classes.commitmentRow}
      key={`commitment-grid-row-${commitment.id}`}
      onClick={() => handleRowClick(commitment.id, navigate, location)}
      name="commitments-row"
    >
      <TableCell className={classes.commitmentCell}>
        <Form
          name="commitment-row-status-select-form"
          debouncedAutoSubmit={950}
          schema={editSchema}
          initialValues={initialValues}
          stateSlice="main.commitments"
          allowRefreshData
          submitActionCreator={commitmentsActions.editCommitment}
          additionalProperties={{
            id: graphId,
          }}
        >
          <FormSelectorField
            fieldName="status"
            render={fieldProps => (
              <StatusSelector
                {...fieldProps}
                disabled={!canEdit}
                options={Object.keys(COM_STATUS_COLORS).map(status => ({
                  text: t(`commitments.statusValueText.${status}`),
                  option: status,
                  color: COM_STATUS_COLORS[status],
                }))}
                color="transparent"
                variant="icon"
              />
            )}
          />
        </Form>
      </TableCell>
      <TableCell className={classes.commitmentCell} sx={{ minWidth: '400px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ flexShrink: 0, mr: 1 }}>
            <PersonasAvatar sub={commitment.owner} enableTooltip size="xtiny" />
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              maxWidth: '85%',
            }}
            color="text.secondary"
          >
            {commitment.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={classes.commitmentCell} sx={{ width: '88px' }}>
        {hasContributors ? (
          <Stack direction="row" useFlexGap flexWrap="wrap" sx={{ mt: 0.5, mb: 0.5 }}>
            {contributorsElements}
          </Stack>
        ) : (
          ' '
        )}
      </TableCell>
      <TableCell className={classes.commitmentCell}>
        {!!commitment.due_date ? (
          <DueDateChip
            name="grey-commitment-chip"
            dueDate={commitment.due_date}
            color="default"
            size="medium"
          />
        ) : (
          ' '
        )}
      </TableCell>
      <TableCell className={classes.commitmentCell}>
        <Chip
          label={getLastModifiedStringFromTimestamp(commitment.last_modified)}
          size="medium"
          color="default"
        />
      </TableCell>
    </TableRow>
  );
}

CommitmentRow.propTypes = {
  classes: PropTypes.exact({
    commitmentRow: PropTypes.string,
    commitmentCell: PropTypes.string,
  }),
  graphId: PropTypes.string.isRequired,
  statusFilter: PropTypes.array,
};

export default withStyles(styles)(CommitmentRow);
