import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ObjectivesIcon from '../ObjectivesIcon';
import KeyresultsIcon from '../KeyresultsIcon';
import Icon from 'Components/Library/BaseComponents/Icon';

interface TablecolumnNamesProps {
  showLastModified?: boolean;
}

function TableColumnNames(props: TablecolumnNamesProps) {
  const { showLastModified } = props;
  const { t } = useTranslation();
  return (
    <thead>
      <Box
        sx={{
          '& .MuiSvgIcon-root': {
            color: theme => theme.palette.primary[500],
            marginRight: '7px',
          },
          '& td': {
            borderTop: '1px solid',
            borderColor: theme => theme.palette.divider,
            paddingTop: 1,
          },
          display: 'table-row',
        }}
        component="tr"
      >
        <Box
          component="td"
          sx={{
            display: 'table-cell',
            pl: 1,
            width: { xs: '100%', sm: '33%' },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Icon size="small">
              <ObjectivesIcon />
            </Icon>
            <Typography color="text.secondary" variant="caption">
              {t('objectives.genericDataObjectNameObjectives')}
            </Typography>
          </Box>
        </Box>
        <Box
          component="td"
          sx={{
            display: { xs: 'none', sm: 'table-cell' },
            pl: 1,
            width: { xs: '0%', sm: '67%' },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Icon size="small">
                <KeyresultsIcon />
              </Icon>
              <Typography color="text.secondary" variant="caption">
                {t('objectives.genericDataObjectNameKeyresults')}
              </Typography>
            </Box>
            {showLastModified && (
              <Box sx={{ display: 'flex', flexDirection: 'row', mr: 1 }}>
                <Typography color="text.secondary" variant="caption">
                  {t('general.lastUpdatedTitle')}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </thead>
  );
}

export default TableColumnNames;
