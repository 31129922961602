export const GET_PERSON = 'GET_PERSON';
export const RECEIVED_PERSON = 'PERSON_RECEIVED';
export const FAILED_PERSON = 'PERSON_FAILED';

export const SEARCH_PEOPLE = 'SEARCH_PEOPLE';
export const RECEIVED_SEARCHED_PEOPLE = 'RECEIVED_SEARCHED_PEOPLE';

export const GET_ORG_HIERARCHY = 'GET_ORG_HIERARCHY';
export const RECEIVED_ORG_HIERARCHY = 'RECEIVED_ORG_HIERARCHY';

export const GET_TEAM = 'GET_TEAM';
export const RECEIVED_TEAM = 'TEAM_RECEIVED';
export const FAILED_TEAM = 'TEAM_FAILED';

export const ADD_RECENT_TEAM = 'ADD_RECENT_TEAM';
export const ADD_RECENT_USER = 'ADD_RECENT_USER';

// Admin
export const GET_USER_LIST = 'GET_USER_LIST';
export const RECEIVED_USER_LIST = 'RECEIVED_USER_LIST';
export const FAILED_USER_LIST = 'FAILED_USER_LIST';

export const EDIT_PERSON_ATTRIBUTES = 'EDIT_PERSON_ATTRIBUTES';
export const EDITED_PERSON_ATTRIBUTES = 'EDITED_PERSON_ATTRIBUTES';

export const CREATE_PERSON = 'CREATE_PERSON';
export const CREATED_PERSON = 'CREATED_PERSON';

export const SET_AVATAR = 'SET_AVATAR';
export const AVATAR_SET = 'AVATAR_SET';

export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATED_TEAM = 'CREATED_TEAM';

export const EDIT_USERS_TEAM = 'EDIT_USERS_TEAM';
export const USERS_TEAM_EDITED = 'USERS_TEAM_EDITED';

export const EDIT_USERS_MATRIX_TEAM = 'EDIT_USERS_MATRIX_TEAM';
export const USERS_MATRIX_TEAM_EDITED = 'USERS_MATRIX_TEAM_EDITED';

export const DETACH_USER_MATRIX_TEAM = 'DETACH_USER_MATRIX_TEAM';
export const DETACHED_USER_MATRIX_TEAM = 'DETACHED_USER_MATRIX_TEAM';

export const ERROR_RECEIVED_FROM_API = 'PEOPLE_API_ERROR_RECEIVED';

export const EDIT_TEAM_MANAGER = 'EDIT_TEAM_MANAGER';
export const EDITED_TEAM_MANAGER = 'EDITED_TEAM_MANAGER';

export const EDIT_CEO = 'EDIT_CEO';
export const EDITED_CEO = 'EDITED_CEO';

export const EDIT_TEAM_PARENT = 'EDIT_TEAM_PARENT';
export const EDITED_TEAM_PARENT = 'EDITED_TEAM_PARENT';

export const EDIT_TEAM_NAME = 'EDIT_TEAM_NAME';
export const EDITED_TEAM_NAME = 'EDITED_TEAM_NAME';

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETED_TEAM = 'DELETED_TEAM';

export const DELETE_USER = 'DELETE_USER';
export const DELETED_USER = 'DELETED_USER';

export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const RECEIVED_USER_PERMISSIONS = 'USER_PERMISSIONS_RECEIVED';
export const FAILED_USER_PERMISSIONS = 'USER_PERMISSIONS_FAILED';
export const EDIT_USER_PERMISSIONS = 'EDIT_USER_PERMISSIONS';
export const EDITED_USER_PERMISSIONS = 'EDITED_USER_PERMISSIONS';

export const GET_MATRICES = 'GET_MATRICES';
export const MATRICES_RECEIVED = 'MATRICES_RECEIVED';
export const MATRICES_FAILED = 'MATRICES_FAILED';

export const GET_MATRIX_HIERARCHY = 'GET_MATRIX_HIERARCHY';
export const MATRIX_HIERARCHY_RECEIVED = 'MATRIX_HIERARCHY_RECEIVED';
export const MATRIX_HIERARCHY_FAILED = 'MATRIX_HIERARCHY_FAILED';

export const GET_FOLLOWED_TEAMS = 'GET_FOLLOWED_TEAMS';
export const FOLLOWED_TEAMS_RECEIVED = 'FOLLOWED_TEAMS_RECEIVED';
export const FOLLOWED_TEAMS_FAILED = 'FOLLOWED_TEAMS_FAILED';

export const FOLLOW_TEAM = 'FOLLOW_TEAM';
export const FOLLOWED_TEAM = 'FOLLOWED_TEAM';
export const UNFOLLOW_TEAM = 'UNFOLLOW_TEAM';
export const UNFOLLOWED_TEAM = 'UNFOLLOWED_TEAM';

export const GET_MY_JOIN_REQUESTS = 'GET_MY_JOIN_REQUESTS';
export const MY_JOIN_REQUESTS_RECEIVED = 'MY_JOIN_REQUESTS_RECEIVED';
export const MY_JOIN_REQUESTS_FAILED = 'MY_JOIN_REQUESTS_FAILED';

export const GET_TEAM_JOIN_REQUESTS = 'GET_TEAM_JOIN_REQUESTS';
export const TEAM_JOIN_REQUESTS_RECEIVED = 'TEAM_JOIN_REQUESTS_RECEIVED';
export const TEAM_JOIN_REQUESTS_FAILED = 'TEAM_JOIN_REQUESTS_FAILED';

export const REQUEST_TO_JOIN_TEAM = 'REQUEST_TO_JOIN_TEAM';
export const REQUESTED_TO_JOIN_TEAM = 'REQUESTED_TO_JOIN_TEAM';

export const APPROVE_JOIN_REQUEST = 'APPROVE_JOIN_REQUEST';
export const APPROVED_JOIN_REQUEST = 'APPROVED_JOIN_REQUEST';

export const DENY_JOIN_REQUEST = 'DENY_JOIN_REQUEST';
export const DENIED_JOIN_REQUEST = 'DENIED_JOIN_REQUEST';

export const LEAVE_TEAM = 'LEAVE_TEAM';
export const LEFT_TEAM = 'LEFT_TEAM';
