import * as types from './types';

export const getTokensWithCode = payload => ({
  type: types.GET_TOKENS_WITH_CODE,
  payload,
});

export const refreshTokens = payload => ({
  type: types.REFRESH_TOKENS,
  payload,
});

export const tokenFetchSuccess = payload => ({
  type: types.TOKEN_FETCH_SUCCESS,
  payload,
});

export const tokenFetchFailed = payload => ({
  type: types.TOKEN_FETCH_FAILURE,
  payload,
});

export const revokeTokens = payload => ({
  type: types.REVOKE_TOKENS,
  payload,
});

export const tokenRevokeSuccess = payload => ({
  type: types.TOKEN_REVOKE_SUCCESS,
  payload,
});

export const tokenRevokeFailed = payload => ({
  type: types.TOKEN_REVOKE_FAILURE,
  payload,
});

export const getMyTeams = function getMyTeams() {
  return { type: types.GET_MY_TEAMS };
};

export const myTeamsReceived = payload => ({
  type: types.MY_TEAMS_RECEIVED,
  payload,
});

export const myTeamsFailed = payload => ({
  type: types.MY_TEAMS_FAILED,
  payload,
});

export const tokenRefreshSuccess = payload => ({
  type: types.TOKEN_REFRESH_SUCCESS,
  payload,
});

export const tokenRefreshFailure = () => ({
  type: types.TOKEN_REFRESH_FAILURE,
});

export const startRefreshTimer = payload => ({
  type: types.START_REFRESH_TIMER,
  payload,
});

export const swTokenConfirmed = () => ({
  type: types.SW_TOKEN_CONFIRMED,
});
