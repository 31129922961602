import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DoneIcon from '@mui/icons-material/Done';
import { withLocation, withNavigation } from 'withRouter';
import StandardDialog from 'Components/Library/StandardDialog';
import { openTopicViewSurveyPanel } from 'config/ModalProvider/helpers';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import { withFormStatus } from 'Components/Library/Forms/FormContext';
import StyledPanelReplyTopicHeader from './StyledPanelReplyTopicHeader';
import FormMultiChoiceReply from './FormMultiChoiceReply';
import FormCsatReply from './FormCsatReply';
import FormQuadReply from './FormQuadReply';
import FormNpsReply from './FormNpsReply';
import FormSliderReply from './FormSliderReply';
import FormFreeText from './FormFreeText';

const styles = theme => ({
  content: {
    paddingLeft: 14,
    paddingRight: 14,
  },
  card: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  centered: {
    margin: 'auto',
    textAlign: 'center',
  },
  ownerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ownerDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
});

class PanelReplyTopic extends Component {
  viewResults = () => {
    openTopicViewSurveyPanel(
      this.props.message.surveyID,
      true,
      undefined,
      false,
      this.props.navigate,
      this.props.location,
    );
  };

  render() {
    const { t, classes, message, onClose, open, submitSuccess, zIndexModifier } = this.props;
    if (!message) {
      return null;
    }

    let content;

    if (!submitSuccess && message.secondsLeft > 0) {
      if (message.topicType === 'multiplechoice') {
        content = <FormMultiChoiceReply values={message} />;
      } else if (message.topicType === 'quad') {
        content = <FormQuadReply values={message} />;
      } else if (message.topicType === 'csat') {
        content = <FormCsatReply values={message} />;
      } else if (message.topicType === 'nps') {
        content = <FormNpsReply values={message} />;
      } else if (message.topicType === 'opentext') {
        content = <FormFreeText audienceType={message.audienceType} />;
      } else if (message.topicType === 'sliders') {
        content = <FormSliderReply values={message} />;
      }
    }

    return (
      <StandardDialog
        open={open}
        onBackdropClick={onClose}
        size="large"
        zIndexModifier={zIndexModifier}
      >
        <DialogContent className={clsx(classes.content, 'scrollable')}>
          <LinearIndicator positioning="absolute" />
          <StyledPanelReplyTopicHeader {...this.props.message} />
          {content}
          {!submitSuccess && message.secondsLeft < 0 && message.status !== 'parsed' && (
            <div>
              <Typography variant="h6" className={classes.centered}>
                {t('insights.surveyhasendedtitle')}
              </Typography>
              <br />
              <Typography variant="h6" className={classes.centered}>
                {t('insights.noresultssubtitle')}
              </Typography>
            </div>
          )}
          {!submitSuccess && message.secondsLeft < 0 && message.status === 'parsed' && (
            <div className={classes.centered}>
              <Typography variant="h6" className={classes.centered}>
                {t('insights.surveyhasendedtitle')}
              </Typography>
              <br />
              <Button
                variant="contained"
                color="secondary"
                onClick={this.viewResults}
                name="reply-view-results"
              >
                {t('insights.viewresultscta')}
              </Button>
            </div>
          )}
          {!!submitSuccess && (
            <div className={classes.centered} id="panel-reply-survey-thank-you-box">
              <div className={classes.card}>
                <br />
                <DoneIcon fontSize="large" color="secondary" />
                <Typography variant="h6" className={classes.centered} paragraph>
                  {t('insights.thankyousubtitle')}
                </Typography>
                <br />
                <br />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text" id="panel-reply-survey-close-btn">
            {t('general.close')}
          </Button>
          {!submitSuccess && message.secondsLeft > 0 && (
            <FormButton
              render={buttonProps => (
                <Button
                  variant="contained"
                  color="secondary"
                  id="panel-reply-survey-submit-btn"
                  {...buttonProps}
                >
                  {t('general.submit')}
                </Button>
              )}
            />
          )}
        </DialogActions>
      </StandardDialog>
    );
  }
}

PanelReplyTopic.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    card: PropTypes.string,
    centered: PropTypes.string,
    ownerRow: PropTypes.string,
    ownerDetailsContainer: PropTypes.string,
    savingIndicator: PropTypes.string,
  }),
  message: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  done: PropTypes.bool,
  t: PropTypes.func,
  location: PropTypes.object,
  navigate: PropTypes.func,
  submitSuccess: PropTypes.bool,
  zIndexModifier: PropTypes.number,
};

export default withLocation(
  withNavigation(withTranslation()(withStyles(styles)(withFormStatus(PanelReplyTopic)))),
);
