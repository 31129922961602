import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChipAutocomplete from 'Components/Library/Shared/ChipAutocomplete';
import useSearch from 'hooks/useSearch';
import { useTranslation } from 'react-i18next';
import GraphElementChip from 'Components/Library/GraphElementResolver/GraphElementChip';
import Box from '@mui/material/Box';

const renderSuggestion = (suggestion, { isHighlighted }) => {
  if (suggestion?.node_id) {
    return (
      <GraphElementChip
        graphId={suggestion.node_id}
        id={`node-selector-result-${suggestion.node_id}`}
        name="node-selector-result"
        color={isHighlighted ? 'grey' : 'transparent'}
        actionable
        allowNavigate={false}
      />
    );
  }

  // placeHolderOptions options:
  if (suggestion?.type === 'placeholder') {
    const { title, type, onSelect, ...rest } = suggestion;
    return (
      <Box
        {...rest}
        sx={{
          borderRadius: 1,
          backgroundColor: isHighlighted ? 'background.box' : 'transparent',
          cursor: 'pointer',
        }}
      >
        {title}
      </Box>
    );
  }
  return null;
};

function SearchOverlay(props) {
  const { name, selected, onSelect, id, placeHolderOptions } = props;
  const [searchString, setSearchString] = useState('');
  const [results, isLoading] = useSearch(searchString, ['nodes'], false);
  const { t } = useTranslation();

  const setString = s => {
    setSearchString(s || '');
  };

  return (
    <ChipAutocomplete
      name={name}
      selected={selected}
      suggestions={searchString.length > 0 ? results : placeHolderOptions}
      onSelect={onSelect}
      multiSection={false}
      isLoading={isLoading}
      alwaysRenderSuggestions
      renderSuggestion={(suggestion, { isHighlighted }) =>
        renderSuggestion(suggestion, { isHighlighted })
      }
      renderSearchIcon
      onClearRequested={() => setSearchString('')}
      onFetchRequested={setString}
      id={id}
      placeholder={t('general.searchLabel')}
    />
  );
}

SearchOverlay.propTypes = {
  selected: PropTypes.object,
  onSelect: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  placeHolderOptions: PropTypes.array,
};

SearchOverlay.defaultProps = {
  placeHolderOptions: [],
};

export default SearchOverlay;
