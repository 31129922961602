export const GET_INSTANCE_TAGS = 'GET_INSTANCE_TAGS';
export const INSTANCE_TAGS_FETCH_SUCCESS = 'INSTANCE_TAGS_FETCH_SUCCESS';
export const FAILED_INSTANCE_TAGS = 'FAILED_INSTANCE_TAGS';

export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const ALL_TAGS_FETCH_SUCCESS = 'ALL_TAGS_FETCH_SUCCESS';
export const FAILED_ALL_TAGS = 'FAILED_ALL_TAGS';

export const ADD_TAG = 'ADD_TAG';
export const ADDED_TAG = 'ADDED_TAG';

export const DELETE_TAG = 'DELETE_TAG';
export const DELETED_TAG = 'DELETED_TAG';

export const EDIT_TAG = 'EDIT_TAG';
export const EDITED_TAG = 'EDITED_TAG';

export const LINK_TAG = 'LINK_TAG';
export const LINKED_TAG = 'LINKED_TAG';

export const UNLINK_TAG = 'UNLINK_TAG';
export const UNLINKED_TAG = 'UNLINKED_TAG';

export const GET_LINKED_TAGS = 'GET_LINKED_TAGS';
export const LINKED_TAGS_FETCH_SUCCESS = 'LINKED_TAGS_FETCH_SUCCESS';
export const FAILED_LINKED_TAGS = 'FAILED_LINKED_TAGS';

export const ERROR_RECEIVED_FROM_API_TAGS = 'ERROR_RECEIVED_FROM_API_TAGS';

export interface IGetInstanceTagsPayload {
  id: string;
  force?: boolean;
}

export interface IAddTagPayload {
  name: string;
  color: string;
}

export interface IDeleteTagPayload {
  id: string;
  requestID?: string;
}

export interface IEditTagPayload {
  id: string;
  name: string;
  color: string;
}

export interface ILinkTagPayload {
  id: string;
  target_id: string;
}

export interface ITag {
  color: string;
  id: string;
  name: string;
}

export interface ITagNodesData {
  nodes: Array<ITag>;
  next_token?: string;
}

export interface ITagsData {
  canFetch: boolean;
  data: ITagNodesData;
  error: boolean;
  fetchStatus: string;
  lastFetched: number;
  loading: boolean;
  ok: boolean;
}

export interface ILinkedTagNodesData {
  nodes: Array<ITag>;
  next_token?: string;
  id: string;
}

export interface ITagsError {
  error: string;
  requestID: string;
}
