import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import EmphasizedSection from 'Components/Library/EmphasizedSection/index';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Collapse } from '@mui/material';
import { isEnabled } from 'config/flags';

const styles = theme => ({
  content: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1.5),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    width: '100%',
    '&:hover': {
      backgroundColor: 'action.hover',
      cursor: 'pointer',
    },
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  childrenContainer: {
    height: 'auto',
  },
});

const CollapsibleDialogSection = React.forwardRef(
  ({ iconNode, title, children, classes, additionalControl }, ref) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleClick = () => {
      if (isEnabled('COLLAPSIBLE')) {
        setIsCollapsed(!isCollapsed);
      }
    };
    const shouldChildrenBeShown = !isCollapsed && !!children;

    return (
      <EmphasizedSection className={classes.content} onClick={handleClick} ref={ref}>
        {(!!iconNode || !!title || !!additionalControl) && (
          <Box className={classes.titleContainer}>
            {iconNode}
            <Typography variant="subtitle2" component="span" className={classes.title}>
              {title}
            </Typography>
            {!!additionalControl && additionalControl}
          </Box>
        )}
        <Collapse in={shouldChildrenBeShown} timeout={50} onClick={e => e.stopPropagation()}>
          <Box className={classes.childrenContainer}>{children}</Box>
        </Collapse>
      </EmphasizedSection>
    );
  },
);

CollapsibleDialogSection.propTypes = {
  iconNode: PropTypes.node,
  title: PropTypes.string,
  additionalControl: PropTypes.node,
  children: PropTypes.node,
  classes: PropTypes.exact({
    content: PropTypes.string,
    titleContainer: PropTypes.string,
    title: PropTypes.string,
    childrenContainer: PropTypes.string,
  }),
};

export default withStyles(styles, { withTheme: true })(CollapsibleDialogSection);
