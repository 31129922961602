import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { insightsActions, insightsSelectors } from 'state/ducks/insights';
import { SRV_COMMENT_MAX_LENGTH } from 'config/constants';
import Form from 'Components/Library/Forms/Form';
import PanelReplyTopic from './PanelReplyTopic';

export const baseSchema = {
  type: 'object',
  properties: {
    text: { type: 'string', maxLength: SRV_COMMENT_MAX_LENGTH },
  },
  required: [],
  additionalProperties: true,
};

export const singleValueSurveySchema = {
  properties: {
    score: { type: 'number' },
  },
  required: ['score'],
};

const quadSurveySchema = {
  properties: {
    scorex: { type: 'number', minimum: 0, maximum: 100 },
    scorey: { type: 'number', minimum: 0, maximum: 100 },
  },
};

const sliderSurveySchema = {
  properties: {
    sliders: { type: 'object', propertyNames: { pattern: '^[0-9]+$' } },
  },
};

const fetchData = props => {
  if (!!props.open) {
    if (!props.survey || !props.survey.ok) {
      props.dispatch(insightsActions.getSingleSurvey({ surveyID: props.messageID }));
    }
  }
};

class PanelReplyTopicContainer extends React.Component {
  componentDidMount() {
    fetchData(this.props);
  }

  componentDidUpdate() {
    fetchData(this.props);
  }

  render() {
    const { survey, messageID, zIndexModifier } = this.props;
    const appliedSchema = { ...baseSchema };
    const initialValues = {
      text: get(survey, 'data.surveyReply.text', ''),
      surveyID: messageID,
    };
    switch (get(survey, 'data.topicType')) {
      case 'multiplechoice':
      case 'nps':
      case 'csat':
        appliedSchema.properties = {
          ...baseSchema.properties,
          ...singleValueSurveySchema.properties,
        };
        appliedSchema.required = singleValueSurveySchema.required;
        initialValues.score = get(survey, 'data.surveyReply.score', null);
        break;
      case 'quad':
        appliedSchema.properties = {
          ...baseSchema.properties,
          ...quadSurveySchema.properties,
        };
        // Use the already stored answer as initial value if available, default to 50
        initialValues.scorex = get(survey, 'data.surveyReply.score.x', 50);
        initialValues.scorey = get(survey, 'data.surveyReply.score.y', 50);
        break;
      case 'sliders':
        appliedSchema.properties = {
          ...baseSchema.properties,
          ...sliderSurveySchema.properties,
        };
        initialValues.sliders = [];
        break;
      default:
        break;
    }
    return (
      <Form
        name="dialog-reply-topic"
        schema={appliedSchema}
        initialValues={initialValues}
        submitActionCreator={insightsActions.replyToSurvey}
        stateSlice="main.insights"
        preventSaveIncomplete
      >
        <PanelReplyTopic
          open={this.props.open}
          onClose={this.props.onClose}
          message={this.props.survey && this.props.survey.data}
          zIndexModifier={zIndexModifier}
        />
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  survey: insightsSelectors.selectSurvey(state.main.insights, ownProps.messageID),
});

PanelReplyTopicContainer.propTypes = {
  survey: PropTypes.object,
  messageID: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(PanelReplyTopicContainer);
