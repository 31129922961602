import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function withNavigation(Component) {
  return function Navigation(props) {
    return <Component {...props} navigate={useNavigate()} />;
  };
}

export function withLocation(Component) {
  return function Location(props) {
    return <Component {...props} location={useLocation()} />;
  };
}

export function withParams(Component) {
  return function Params(props) {
    return <Component {...props} params={useParams()} />;
  };
}
