import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import DueDateChip from 'Components/Library/DueDateChip';
import StatusSelector from 'Components/Library/StatusSelector';
import { interlocksSelectors, interlocksActions } from 'state/ducks/interlocks/index';
import { INTERLOCK_STATUS_COLORS, CONTRIBUTOR_TYPES } from 'config/constants';
import { getLastModifiedStringFromTimestamp } from 'config/helpers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PersonasAvatar from 'Components/Common/PersonasAvatar';

const styles = theme => ({
  interlockRow: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: 1,
    paddingBottom: 1,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover h6': {
      color: theme.palette.text.primary,
    },
    // cannot apply border radius to a row, but we need
    // rounded corners for the hover effect:
    '& > td:first-of-type': {
      paddingLeft: theme.spacing(),
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '& > td:last-of-type': {
      paddingLeft: theme.spacing(),
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
  },
  interlockCell: {
    paddingLeft: 0,
    verticalAlign: 'middle',
    minHeight: '32px',
    color: theme.palette.text.secondary,
    paddingTop: 0,
    paddingBottom: 0,
    '& h6': {
      // ensure the rows conform to the grid:
      lineHeight: '1.25rem',
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
});

const handleRowClick = (graphId, navigate) => {
  navigate(`#modal/interlocks/view/${graphId}`, { replace: true });
};

const renderContributor = contributor => {
  if (contributor.type !== CONTRIBUTOR_TYPES.TEAM && contributor.type !== CONTRIBUTOR_TYPES.USER) {
    return null;
  }
  return (
    <Box
      key={`interlock-grid-contributor-${contributor.id}`}
      sx={{
        display: 'inline-flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '24px',
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          minHeight: '24px',
          minWidth: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PersonasAvatar
          sub={contributor.id}
          type={contributor.type === CONTRIBUTOR_TYPES.USER ? 'employee' : 'team'}
          size="xtiny"
          enableTooltip
        />
      </Box>
    </Box>
  );
};

function InterlockRow(props) {
  const navigate = useNavigate();
  const { classes, graphId, statusFilter } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // TODO: this should be optimized... most likely the information should be cached
  // on the nodes
  useEffect(() => {
    dispatch(interlocksActions.fetchContributors({ id: graphId, force: false }));
  }, []);

  const interlock = useSelector(
    state => interlocksSelectors.selectInterlock(state.main.interlocks, graphId).data,
    // { stabilityCheck: 'never' },
  );

  const contributors = useSelector(
    state => interlocksSelectors.selectContributors(state.main.interlocks, graphId),
    // { stabilityCheck: 'never' },
  ).data;

  const hasContributors = !!contributors?.length;

  if (!interlock) {
    return null;
  }

  if (statusFilter && !statusFilter.includes(interlock.status)) {
    return null;
  }

  const contributorsElements =
    hasContributors &&
    contributors
      .map(contributor => {
        if (interlock.owner !== contributor.id) {
          return renderContributor(contributor);
        }
        return null;
      })
      .filter(Boolean);

  return (
    <TableRow
      className={classes.interlockRow}
      key={`interlock-grid-row-${interlock.id}`}
      onClick={() => handleRowClick(interlock.id, navigate)}
    >
      <TableCell className={classes.interlockCell}>
        <StatusSelector
          disabled
          options={Object.keys(INTERLOCK_STATUS_COLORS).map(status => ({
            text: t(`interlocks.statusValueText.${status}`),
            option: status,
            color: INTERLOCK_STATUS_COLORS[status],
          }))}
          color="transparent"
          value={interlock.status}
          name={`interlock-statechip-${interlock.id}`}
          variant="icon"
        />
      </TableCell>
      <TableCell className={classes.interlockCell} sx={{ minWidth: '400px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ flexShrink: 0, mr: 1 }}>
            <PersonasAvatar sub={interlock.owner} enableTooltip size="xtiny" />
          </Box>
          <Typography
            variant="subtitle2"
            sx={{
              maxWidth: '85%',
              overflowWrap: 'anywhere',
              '&:hover': {
                color: theme => theme.palette.text.primary,
              },
            }}
            color="text.secondary"
          >
            {interlock.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={classes.interlockCell} sx={{ width: '88px' }}>
        {hasContributors ? (
          <Stack direction="row" useFlexGap flexWrap="wrap" sx={{ mt: 0.5, mb: 0.5 }}>
            {contributorsElements}
          </Stack>
        ) : (
          ' '
        )}
      </TableCell>
      <TableCell className={classes.interlockCell}>
        {!!interlock.due_date ? (
          <DueDateChip
            name="grey-interlock-chip"
            dueDate={interlock.due_date}
            color="default"
            size="medium"
          />
        ) : (
          ' '
        )}
      </TableCell>
      <TableCell className={classes.interlockCell}>
        <Chip label={getLastModifiedStringFromTimestamp(interlock.last_modified)} size="medium" />
      </TableCell>
    </TableRow>
  );
}

InterlockRow.propTypes = {
  classes: PropTypes.exact({
    interlockRow: PropTypes.string,
    interlockCell: PropTypes.string,
  }),
  graphId: PropTypes.string.isRequired,
  statusFilter: PropTypes.arrayOf(PropTypes.string),
};

export default withStyles(styles)(InterlockRow);
