import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { withTranslation } from 'react-i18next';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import { withNavigation } from 'withRouter';

import { peopleSelectors } from 'state/ducks/people';
import { getNodeModalPath } from 'Components/Library/GraphElementResolver/helpers';
import GraphElementName from 'Components/Library/GraphElementResolver/GraphElementName';
import CommonNotification from './CommonNotification';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: 8,
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[300],
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
});

class NewCommentOnOwnedNodeNotification extends React.Component {
  handleClick = () => {
    const { navigate, message } = this.props;
    navigate(getNodeModalPath(message.commentedNodeId));
  };

  render() {
    const { classes, t, message, authorName, key } = this.props;
    if (!!message) {
      return (
        <CommonNotification
          key={key}
          message={message}
          className={classes.root}
          onClick={this.handleClick}
        >
          <PersonasAvatar sub={message.from} className={classes.avatar} />
          <Box>
            <Typography variant="subtitle2" sx={{ flexShrink: 0 }}>
              {`${authorName} ${t('general.comments.commentedOn')} `}{' '}
              <GraphElementName graphId={message.commentedNodeId} />
            </Typography>
            <Typography variant="caption">
              {t('general.ndaysago', {
                days: message.age,
              })}
            </Typography>
          </Box>
        </CommonNotification>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  inbox: state.main.inbox && state.main.inbox.inbox,
  authorName: peopleSelectors.selectFullName(state.main.people, ownProps.message.from),
});

NewCommentOnOwnedNodeNotification.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    avatar: PropTypes.string,
  }),
  key: PropTypes.string,
  t: PropTypes.func,
  message: PropTypes.object,
  authorName: PropTypes.string,
  navigate: PropTypes.func,
};

export default withNavigation(
  withTranslation()(
    withStyles(styles)(connect(mapStateToProps)(NewCommentOnOwnedNodeNotification)),
  ),
);
