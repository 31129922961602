import { isEmpty } from 'lodash';
import { EVENT_TYPE_STATE_SNAPSHOT, EVENT_TYPE_RELATIONS_CHANGESET } from 'config/constants';
/*
This is mostly a copy-paste of the similar code for key results - 
the structure of the events is different enough to make trying
to generalize it too complex, and KR events will eventually be
phased out.

TODO: ENG-1653
*/

// Fields that should be ignored
const IGNORE_KEYS = ['contributors', 'okrs', 'creator', 'last_modified'];

// eslint-disable-next-line import/prefer-default-export
export const processActionLogEvents = (nodeId, eventData) => {
  const { nodes } = eventData;
  if (!nodes) {
    return [];
  }
  let previousSnapshot;
  for (let i = nodes.length - 1; i >= 0; i--) {
    const event = nodes[i];
    event.id = event.graph_id;
    event.ts = event.timestamp;
    event.nodeId = nodeId;
    event.ignore = false;

    // Compute changes from previous snapshot:
    if (!!previousSnapshot) {
      if (event.type === EVENT_TYPE_STATE_SNAPSHOT) {
        const changes = {};
        for (const key of Object.keys(event.data)) {
          if (previousSnapshot.data[key] !== event.data[key] && !IGNORE_KEYS.includes(key)) {
            changes[key] = previousSnapshot.data[key];
          }
        }
        if (!isEmpty(changes)) {
          event.changes = changes;
        }
      }
      if (event.type === EVENT_TYPE_RELATIONS_CHANGESET) {
        event.data.changes.forEach(change => {
          if (
            change.extra.contributor_type === 'OWNER' ||
            (change.action === 'UPDATE' && !change.extra.status)
          ) {
            event.ignore = true;
          }
        });
        event.changes = event.data.changes;
        // Don't store a reference to this as the previousSnapshot
        // eslint-disable-next-line no-continue
        continue;
      }
    } else {
      event.isCreate = true;
    }
    previousSnapshot = event;
  }
  return nodes;
};
