import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OptionsIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UserChip from 'Components/Library/UserChip';
import InteractableCard from 'Components/Library/InteractableCard';
import { getLastModifiedStringFromTimestamp } from 'config/helpers';

const styles = theme => ({
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  stage: {
    marginRight: theme.spacing(3),
  },
  topic: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(),
    display: 'box',
    lineClamp: 3,
    boxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  description: {
    marginBottom: theme.spacing(),
    display: 'box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  optionsIcon: {
    flexShrink: 0,
    flexGrow: 0,
  },
});

class FacilitationSessionCard extends Component {
  state = {
    optionsOpen: false,
  };

  openOptions = e => {
    this.setState({ optionsOpen: e.currentTarget });
    e.preventDefault();
    e.stopPropagation();
  };

  closeOptions = () => {
    this.setState({ optionsOpen: null });
  };

  requestDelete = e => {
    e.preventDefault();
    e.stopPropagation();
    this.closeOptions();
    this.props.openDelete();
  };

  render() {
    const { classes, session, onClick, userSub, t, isManager } = this.props;
    const { optionsOpen } = this.state;

    if (!session.ok) {
      return null;
    }

    const timestring =
      !!session && !!session.id ? getLastModifiedStringFromTimestamp(session.id.split('_')[0]) : '';
    return (
      <InteractableCard
        fixedHeight
        onClick={onClick}
        tabIndex={0}
        footer={
          <>
            <UserChip variant="normal" color="grey" sub={session.owner} />
            <Typography variant="body2" color="text.secondary" display="block">
              {timestring}
            </Typography>
          </>
        }
      >
        <CardContent>
          <div className={classes.topRow}>
            <Typography
              color={session.status < 5 ? 'secondary' : 'textSecondary'}
              variant="subtitle2"
              component="span"
              className={classes.stage}
            >
              {session.status < 4 && t('dfac.stageInProgress')}
              {session.status === 4 && t('dfac.stageUnconcluded')}
              {session.status === 5 && t('dfac.stageFinished')}
            </Typography>
            {(isManager || session.owner === userSub) && (
              <IconButton
                onClick={this.openOptions}
                className={classes.optionsIcon}
                name="facilitation-session-card-toggle-options-button"
                id={`facilitation-session-card-toggle-options-button-${session.id}`}
                aria-label={t('dfac.ariaLabelForCardOptions')}
              >
                <OptionsIcon />
              </IconButton>
            )}
          </div>
          <Typography variant="h4" className={classes.topic} name="facilitation-session-card-topic">
            {session.topic}
          </Typography>
          <Typography variant="caption" color="textSecondary" className={classes.description}>
            {session.description}
          </Typography>
        </CardContent>
        {Boolean(optionsOpen) && (
          <Menu anchorEl={optionsOpen} open={Boolean(optionsOpen)} onClose={this.closeOptions}>
            <MenuItem name="facilitation-session-card-options-remove" onClick={this.requestDelete}>
              {t('general.remove')}
            </MenuItem>
          </Menu>
        )}
      </InteractableCard>
    );
  }
}

FacilitationSessionCard.propTypes = {
  session: PropTypes.object,
  classes: PropTypes.exact({
    topRow: PropTypes.string,
    stage: PropTypes.string,
    topic: PropTypes.string,
    description: PropTypes.string,
    optionsIcon: PropTypes.string,
  }),
  userSub: PropTypes.string,
  onClick: PropTypes.func,
  openDelete: PropTypes.func,
  t: PropTypes.func,
  isManager: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(FacilitationSessionCard));
