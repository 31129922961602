import { enrich } from '../../helpers';

export function selectProgramById(slice, id) {
  if (slice.programs.ok) {
    return slice.programs.data[id];
  }
  return null;
}

export function selectStrategy(slice) {
  if (!!slice.programs.strategy) {
    return selectProgramById(slice, slice.programs.strategy);
  }
  return null;
}

export function selectPrograms(slice) {
  if (!!slice.programs) {
    return slice.programs;
  }
  return enrich();
}

export function selectProgramsData(slice) {
  if (slice.programs.ok) {
    return slice.programs.data;
  }
  return {};
}

export function selectActivePrograms(slice) {
  const activePrograms = [];
  const programs = selectProgramsData(slice);
  for (const programId in programs) {
    const prg = programs[programId];
    if (prg.type === 'program' && prg.status === 'ENABLED') {
      activePrograms.push(prg);
    }
  }
  return activePrograms;
}

export function selectProgramUsers(slice, cpID) {
  if (!!slice.programusers[cpID]) {
    return slice.programusers[cpID];
  }
  return enrich();
}
