import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import Form from 'Components/Library/Forms/';
import FormButton from 'Components/Library/Forms/Elements/FormButton';

import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import StandardDialog from 'Components/Library/StandardDialog';

const schema = {
  type: 'object',
  additionalProperties: true,
};

function ConfirmDeleteDialog(props) {
  const { onCancel, name, actionProperties, instanceName, submitActionCreator } = props;
  const { t } = useTranslation();

  return (
    <StandardDialog open onBackdropClick={onCancel} size="xs">
      <Form
        name={`confirm-delete-${instanceName}-form`}
        schema={schema}
        initialValues={{}}
        stateSlice={`main.${instanceName}`}
        submitActionCreator={submitActionCreator}
        additionalProperties={actionProperties}
      >
        <DialogTitle id="simple-dialog-title">
          {t(`${instanceName}.delete${capitalize(instanceName)}DialogTitle`)}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <Trans
              i18nKey={`${instanceName}.confirm${capitalize(instanceName)}Delete`}
              values={{ [instanceName]: name }}
            />
          </Typography>
          <LinearIndicator positioning="absolute" />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} id={`delete-${instanceName}-form-cancel-button`}>
            {t('general.cancel')}
          </Button>
          <FormButton
            render={buttonProps => (
              <Button
                id={`delete-${instanceName}-form-confirm-button`}
                variant="contained"
                color="secondary"
                {...buttonProps}
              >
                {t('general.delete')}
              </Button>
            )}
          />
        </DialogActions>
      </Form>
    </StandardDialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  actionProperties: PropTypes.object.isRequired,
  instanceName: PropTypes.string.isRequired,
  submitActionCreator: PropTypes.func.isRequired,
};

export default ConfirmDeleteDialog;
