import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';

const styles = theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 1, // Needed to fix a ton of potential issues
    maxWidth: '100%',
    boxSizing: 'border-box',
    display: 'flex',
  },
  selected: {
    // Empty class needs to exist for selectors below
  },
  raised: {
    boxShadow: theme.shadows[1],
    '&$actionable': {
      '&:hover': {
        boxShadow: `${theme.shadows[1]}, inset 0px 0px 0px 100px ${theme.palette.action.hover}`,
      },
      '&:focus-within': {
        boxShadow: `${theme.shadows[1]}, inset 0px 0px 0px 100px ${theme.palette.action.focus}`,
      },
      '&$selected': {
        boxShadow: `${theme.shadows[1]}, inset 0px 0px 0px 100px ${theme.palette.action.selected}`,
      },
    },
  },
  secondaryAction: {
    opacity: 0,
    transition: 'all .125s ease-out',
    transform: 'scale(0.75)',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `${theme.shadows[1]}, inset 0px 0px 0px 100px ${theme.palette.action.hover}`,
      color: theme.palette.text.primary,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `${theme.shadows[1]}, inset 0px 0px 0px 100px ${theme.palette.action.hover}`,
      color: theme.palette.text.primary,
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    position: 'absolute',
  },
  actionable: {
    cursor: 'pointer',
    border: 'none',
    maxWidth: '100%',
    '&:hover': {
      boxShadow: `inset 0px 0px 0px 100px ${theme.palette.action.hover}`,
      '& $secondaryAction': {
        opacity: 1,
        transform: 'scale(.999)', // 1 causes a jitter
      },
    },
    '&:focus-within': {
      boxShadow: `inset 0px 0px 0px 100px ${theme.palette.action.focus}`,
      '& $secondaryAction': {
        opacity: 1,
        transform: 'scale(.999)', // 1 causes a jitter
      },
    },
    '&$selected': {
      boxShadow: `inset 0px 0px 0px 100px ${theme.palette.action.selected}`,
    },
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: 4,
  },
  text: {
    flexGrow: 10,
    flexBasis: 'content',
    flexShrink: 10,
    minWidth: 0, // Fix text ellipsis
    textAlign: 'left',
  },
  overlineContainer: {
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
    marginBottom: '8px',
  },
  wrapped: {
    overflowWrap: 'break-word',
  },
  statusElement: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'inherit',
    alignItems: 'center',
    '& >*': {
      marginLeft: 4,
    },
    '& button': {
      maxHeight: 30,
      maxWidth: 30,
    },
    '& .MuiIconButton-root': {
      marginLeft: -8,
      marginRight: -4,
      '& .MuiSvgIcon-root': {
        width: 18,
        height: 18,
      },
    },
  },
  // all these below are needed as options even if not picked up by eslint
  grey: {
    backgroundColor: theme.palette.background.box,
  },
  darkgrey: {
    backgroundColor: theme.palette.action.selected,
  },
  white: {
    backgroundColor: theme.palette.background.paper,
  },
  transparent: {
    backgroundColor: 'rgba(255,255,255,0)',
  },
  withOverline: {},
  small: {
    minHeight: 24,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 6,
    '& $text': {
      marginLeft: 2,
      marginRight: 6,
      marginTop: 1, // fine tuning pixels
      '& > span': {
        fontSize: '0.6875rem',
      },
    },
    '&$withOverline': {
      minHeight: 40,
    },
  },
  normal: {
    minHeight: 24,
    paddingLeft: 8,
    paddingRight: 6,
    borderRadius: 8,
    '& $text': {
      marginLeft: 2,
      marginRight: 8,
    },
    '&$withOverline': {
      minHeight: 48,
    },
  },
  large: {
    minHeight: 32,
    paddingLeft: 8,
    paddingRight: 6,
    borderRadius: 10,
    '& $text': {
      marginLeft: 2,
      marginRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
    },
    '&$withOverline': {
      minHeight: 56,
    },
  },
  xl: {
    minHeight: 40,
    paddingLeft: 8,
    paddingRight: 6,
    borderRadius: 10,
    '& $text': {
      marginLeft: 2,
      marginRight: 8,
      '& > span': {
        fontSize: '1rem',
      },
    },
    '&$withOverline': {
      minHeight: 56,
    },
  },
  xxl: {
    minHeight: 40,
    borderRadius: 10,
    '& $text': {
      marginLeft: 2,
      marginRight: 8,
      '& > span': {
        fontSize: '20px',
      },
    },
    '&$withOverline': {
      minHeight: 56,
    },
  },
});

class DataObjectChip extends Component {
  onKeyDown = e => {
    const { actOnDownArrow, onClick } = this.props;
    if (!!actOnDownArrow) {
      if (e.key === 'ArrowDown') {
        onClick(e);
        return false;
      }
    }
    return true;
  };

  renderContent() {
    const {
      classes,
      Icon,
      icon,
      statusComponent = null,
      text,
      wrapText = false,
      contextIconProps,
      overline,
      iconWithOverline,
      tooltipText,
      secondaryActions,
    } = this.props;

    const contentEl = (
      <>
        {!!Icon && (
          <Icon
            className={classes.icon}
            fontSize="18px"
            sx={{ color: theme => theme.palette.primary[500] }}
            {...contextIconProps}
          />
        )}

        {!!icon && !iconWithOverline && <div className={classes.icon}>{icon}</div>}

        {!!text && (
          <div className={classes.text}>
            <div className={classes.overlineContainer}>
              {!!icon && !!iconWithOverline && <div className={classes.iconContainer}>{icon}</div>}
              {!!overline && (
                <Typography variant="caption" color="text.disabled">
                  {overline}
                </Typography>
              )}
            </div>
            <Typography
              variant="subtitle2"
              component="span"
              noWrap={!wrapText}
              display="block"
              name="dataobjectchip-text"
              className={wrapText ? classes.wrapped : undefined}
            >
              {text}
            </Typography>
          </div>
        )}

        {!!statusComponent && (
          <div name="dataobjectchip-statuscomponent" className={classes.statusElement}>
            {statusComponent}
          </div>
        )}
        {secondaryActions &&
          secondaryActions.map((actionDef, actionIndex) => (
            <IconButton
              tabIndex={0}
              key={`dataobjectchip-secondary-action-${actionDef.name}`}
              onClick={e => {
                actionDef.onClick(e);
                e.preventDefault();
                e.stopPropagation();
              }}
              className={classes.secondaryAction}
              component="div"
              size="small"
              name={actionDef.name}
              aria-label={actionDef.label}
              disableRipple
              sx={{
                right: `${8 + actionIndex * 26}px`,
              }}
            >
              <actionDef.Icon
                sx={{
                  width: 15,
                  height: 15,
                }}
              />
            </IconButton>
          ))}
      </>
    );

    return tooltipText ? (
      <Tooltip title={tooltipText} placement="bottom">
        <div>{contentEl}</div>
      </Tooltip>
    ) : (
      contentEl
    );
  }

  render() {
    const {
      classes,
      variant = 'large',
      color = 'grey',
      overline,
      actionable = false,
      selected = false,
      onClick,
      className,
      name,
      id,
      dataChipType,
      sx,
      title,
      raised,
    } = this.props;

    if (!!actionable) {
      return (
        <ButtonBase
          type="button"
          title={title}
          className={clsx(
            classes.root,
            classes[color],
            classes[variant],
            overline && classes.withOverline,
            classes.actionable,
            selected && classes.selected,
            raised && classes.raised,
            className,
          )}
          onClick={e => {
            if (!!onClick) {
              onClick(e);
            }
          }}
          onKeyDown={this.onKeyDown}
          name={name}
          id={id}
          data-chip-type={dataChipType}
          sx={{
            ...sx,
          }}
        >
          {this.renderContent()}
        </ButtonBase>
      );
    }

    return (
      <Box
        className={clsx(
          classes.root,
          classes[color],
          classes[variant],
          !!actionable && classes.actionable,
          selected && classes.selected,
          className,
        )}
        name={name}
        id={id}
        data-chip-type={dataChipType}
        sx={{
          ...sx,
        }}
        tabIndex={actionable ? 0 : -1}
      >
        {this.renderContent()}
      </Box>
    );
  }
}

DataObjectChip.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    actionable: PropTypes.string,
    selected: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    statusElement: PropTypes.string,
    transparent: PropTypes.string, // This and the ones below are needed as options, see variant and color below
    white: PropTypes.string,
    grey: PropTypes.string,
    darkgrey: PropTypes.string,
    small: PropTypes.string,
    normal: PropTypes.string,
    large: PropTypes.string,
    xl: PropTypes.string,
    xxl: PropTypes.string,
    withOverline: PropTypes.string,
    wrapped: PropTypes.string,
    overlineContainer: PropTypes.string,
    iconContainer: PropTypes.string,
    raised: PropTypes.string,
    secondaryAction: PropTypes.string,
  }),
  variant: PropTypes.oneOf(['small', 'normal', 'large', 'xl', 'xxl']),
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  actionable: PropTypes.bool,
  overline: PropTypes.node,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  Icon: PropTypes.elementType,
  contextIconProps: PropTypes.object,
  icon: PropTypes.node,
  statusComponent: PropTypes.node,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  actOnDownArrow: PropTypes.bool,
  className: PropTypes.string,
  wrapText: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  selected: PropTypes.bool,
  dataChipType: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.string,
  iconWithOverline: PropTypes.bool,
  tooltipText: PropTypes.string,
  raised: PropTypes.bool,
  secondaryActions: PropTypes.array,
};

DataObjectChip.defaultProps = {
  contextIconProps: {},
  iconWithOverline: false,
  raised: false,
};

export default withStyles(styles)(DataObjectChip);
