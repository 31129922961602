import { isEmpty } from 'lodash';
import { EVENT_TYPE_KREVENT } from 'config/constants';
import { getConfidenceString } from './common';

const EVENT_VALUE_KEYS = [
  'baseline',
  'confidenceStr',
  'status',
  'target',
  'unit',
  'weight',
  'keyresult',
  'description',
  'committed',
  'krowner',
];

// eslint-disable-next-line import/prefer-default-export
export const processKrEvents = keyresult => {
  const { keyresultID, events } = keyresult;
  if (!events) {
    return;
  }
  let previous;
  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i];
    // Add data needed to optimize the rendering of the events:
    event.confidenceStr = getConfidenceString(event.confidence);
    event.keyresultID = keyresultID;
    event.id = `${event.owner}-${keyresultID}`;
    event.type = EVENT_TYPE_KREVENT;

    // Compute changes from previous event:
    if (!!previous) {
      const changes = {};
      for (const key of EVENT_VALUE_KEYS) {
        if (previous[key] !== event[key]) {
          changes[key] = previous[key];
        }
      }
      if (!isEmpty(changes)) {
        event.changes = changes;
      }
    } else {
      event.isCreate = true;
    }
    previous = event;
  }
};
