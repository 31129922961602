import reducer from './reducers';

import * as peopleLogic from './logic';
import * as peopleTypes from './types';
import * as peopleActions from './actions';
import * as peopleSelectors from './selectors';

export { peopleLogic, peopleTypes, peopleActions, peopleSelectors };

export default reducer;
