import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connectionSelectors } from 'state/ducks/connection';
import { commitmentsSelectors } from 'state/ducks/commitments/index';
import { peopleSelectors } from 'state/ducks/people/index';
import { interlocksSelectors } from 'state/ducks/interlocks/index';
import { objectivesSelectors } from 'state/ducks/objectives/index';

class CanEditNodeWrapper extends React.Component {
  render() {
    const { render, canEdit, isOnline } = this.props;
    return render(canEdit && isOnline);
  }
}

export const getInstanceType = graphID => {
  if (graphID && graphID.length > 0) {
    const prefix = graphID.split('_')[0];
    switch (prefix) {
      case 'OBJ':
        if (graphID.indexOf('KR_') > -1) {
          return 'KR';
        }
        return 'OBJ';
      case 'COM':
        return 'COM';
      case 'INTERLOCK':
        return 'INTERLOCK';
      default:
        return null;
    }
  }
  return null;
};

export const checkIfCanEditInstance = (state, instanceType, graphID) => {
  switch (instanceType) {
    case 'KR':
      // eslint-disable-next-line no-case-declarations
      const otherObjectiveData = objectivesSelectors.selectObjective(
        state.main.objectives,
        graphID,
      );
      // eslint-disable-next-line no-case-declarations
      let krData;
      if (!!otherObjectiveData && otherObjectiveData.ok) {
        krData = objectivesSelectors.selectKeyresult(state.main.objectives, graphID);
      }
      return (
        otherObjectiveData &&
        otherObjectiveData.ok &&
        (state.auth.isChangeManager || // change managers currently have wide rights
          otherObjectiveData.data.owner === state.auth.userID || // Can always edit objectives that I own
          (!!krData && krData.owner === state.auth.userID) || // Can always edit krs that I own
          // can edit team objectives of the teams I lead or to which I belong to
          (otherObjectiveData.data.type === 'team' &&
            (peopleSelectors.isMember(
              state.main.people,
              otherObjectiveData.data.teamID,
              state.auth.userID,
              state.auth.teamID,
            ) ||
              peopleSelectors
                .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
                .includes(otherObjectiveData.data.teamID))) ||
          // Edit stuff owned by my subordinates
          peopleSelectors.isManagerOf(
            state.main.people,
            state.auth.userID,
            otherObjectiveData.data.owner,
          ) ||
          (!!krData &&
            peopleSelectors.isManagerOf(state.main.people, state.auth.userID, krData.owner)))
      );
    case 'OBJ':
      // eslint-disable-next-line no-case-declarations
      const objectiveData = objectivesSelectors.selectObjective(state.main.objectives, graphID);
      // eslint-disable-next-line no-case-declarations
      return (
        objectiveData &&
        objectiveData.ok &&
        (state.auth.isChangeManager || // change managers currently have wide rights
          objectiveData.data.owner === state.auth.userID || // Can always edit objectives that I own
          // can edit team objectives of the teams I lead or to which I belong to
          (objectiveData.data.type === 'team' &&
            (peopleSelectors.isMember(
              state.main.people,
              objectiveData.data.teamID,
              state.auth.userID,
              state.auth.teamID,
            ) ||
              peopleSelectors
                .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
                .includes(objectiveData.data.teamID))) ||
          // Edit stuff owned by my subordinates
          peopleSelectors.isManagerOf(
            state.main.people,
            state.auth.userID,
            objectiveData.data.owner,
          ))
      );
    case 'COM':
      // eslint-disable-next-line no-case-declarations
      const managedTeamList = peopleSelectors.selectTeamsManagedBy(
        state.main.people,
        state.main.people,
        state.auth.userID,
        state.auth.userID,
      );
      return commitmentsSelectors.canEditCommitment({
        slice: state.main.commitments,
        commitmentID: graphID,
        userId: state.auth.userID,
        managedTeamIds: managedTeamList,
        isChangeManager: state.auth.isChangeManager,
      });
    case 'INTERLOCK':
      return interlocksSelectors.canEditInterlock({
        slice: state.main.interlocks,
        interlockID: graphID,
        userId: state.auth.userID,
        isChangeManager: state.auth.isChangeManager,
      });
    default:
      return false;
  }
};

const mapStateToProps = (state, ownProps) => {
  const { graphID } = ownProps;
  const instanceType = getInstanceType(graphID);
  const canEdit = checkIfCanEditInstance(state, instanceType, graphID);

  return {
    isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
    canEdit,
  };
};

CanEditNodeWrapper.propTypes = {
  render: PropTypes.func,
  canEdit: PropTypes.bool,
  isOnline: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  graphID: PropTypes.string,
};

export default connect(mapStateToProps)(CanEditNodeWrapper);
