import { createLogic, createDefaultListQueryLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { APIGW_URL } from '../../constants/api';
import { idRegex } from 'state/ducks/objectives/helpers';
import * as types from './types';
import * as selectors from './selectors';

const SLICE_NAME = 'graph';

export const getGraphRelations = createDefaultListQueryLogic({
  endpoint: { api: `${APIGW_URL}/graph`, method: 'queryrelations' },
  actionTypes: {
    fetch: types.FETCH_RELATIONS,
    success: types.RECEIVED_RELATIONS,
    fail: types.FAILED_RELATIONS,
  },
  selector: selectors.selectRelations,
  sliceName: SLICE_NAME,
});

export const editGraphRelationsLogic = createLogic({
  type: types.EDIT_RELATIONS,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action;

    axios
      .post(`${APIGW_URL}/graph/${getState().auth.tenantID}/editrelations`, payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        result.requestID = payload.requestID;
        dispatch({ type: types.EDITED_RELATIONS, payload: result });
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = payload.requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch({ type: types.ERROR_RECEIVED_FROM_API, payload });
      })
      .then(() => done());
  },
});

export const addLinkLogic = createLogic({
  type: types.ADD_LINK,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action;
    const { sourceNode, url, displayName } = payload;

    // Backwards compatibility of legacy KR IDs
    const dispatchedNodeId = idRegex.test(sourceNode) ? sourceNode.split('-')[1] : sourceNode;

    const linkNodeBody = {
      node_type: 'RELEXTLINK',
      url: url,
    };

    if (displayName) {
      linkNodeBody.display_name = displayName;
    }

    const apiPayload = {
      requests: [
        {
          method: 'PUT',
          url: '/node',
          body: linkNodeBody,
          def: 'target_node',
        },
        {
          method: 'PUT',
          url: '/edge',
          body: { from: dispatchedNodeId, to: 'ref:target_node', edge_type: 'GENREL' },
        },
      ],
    };

    axios
      .post(`${APIGW_URL}/graph/${getState().auth.tenantID}/batch`, apiPayload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        // Find added link node in the response
        // to get the ID
        const linkNode = result.added.find(item => item.id.startsWith('RELEXTLINK_'));

        dispatch({
          type: types.EDITED_RELATIONS,
          payload: {
            changeset: [
              {
                action: 'ADD',
                from: sourceNode,
                to: linkNode.id,
                type: 'GENREL',
              },
            ],
          },
        });
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = payload.requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch({ type: types.ERROR_RECEIVED_FROM_API, payload });
      })
      .then(() => done());
  },
});
