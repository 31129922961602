import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import ValueIcon from '@mui/icons-material/MilitaryTech';
import PrincipleIcon from '@mui/icons-material/Tour';
import TradeOffIcon from '@mui/icons-material/LinearScale';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@mui/material';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import { gameplansActions } from 'state/ducks/gameplans';
import FormContext from 'Components/Library/Forms/FormContext';
import FormSlider from 'Components/Library/Forms/Elements/FormSlider';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import RichTextEditor from 'Components/Library/RichTextEditor';
import Form from 'Components/Library/Forms/Form';
import TgDeltaSlider from 'Components/Common/TgDeltaSlider';
import { SCHEMA_COMMENT } from 'Components/Library/RichTextEditor/schemas';
import RichTextRenderer from 'Components/Library/RichTextRenderer';
import DraggableElement from './Lib/DraggableElement';
import DragContainer from './Lib/DragContainer';
import WidgetFooter from './Lib/WidgetFooter';
import { addWidgetElement } from './Lib/api';

export const ITEMTYPE_VALUE = 'VALUE';
export const ITEMTYPE_PRINCIPLE = 'PRINCIPLE';
export const ITEMTYPE_TRADEOFF = 'TRADEOFF';

const schema = {
  type: 'object',
  properties: {
    elements: {
      type: 'array',
      items: {
        anyOf: [
          {
            type: 'object',
            properties: {
              element_type: { const: ITEMTYPE_VALUE },
              title: { type: 'string' },
              content: { richtextschema: SCHEMA_COMMENT, minLength: 1, maxLength: 3000 },
            },
          },
          {
            type: 'object',
            properties: {
              element_type: { const: ITEMTYPE_PRINCIPLE },
              title: { type: 'string' },
            },
          },
          {
            type: 'object',
            properties: {
              element_type: { const: ITEMTYPE_TRADEOFF },
              slider: {
                type: 'object',
                properties: {
                  left_label: { type: 'string' },
                  right_label: { type: 'string' },
                  values: {
                    type: 'array',
                    minLength: 2,
                    maxLength: 2,
                    items: { type: 'number', minimum: 0, maximum: 100 },
                  },
                },
              },
            },
          },
        ],
      },
    },
  },
};

const elementStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  alignItems: 'flex-start',
  p: 0.5,
  pl: 1,
};

const addItemAt = async (widgetId, formContext, index, itemType) => {
  const elements = [...formContext.values.elements];
  const item = await addWidgetElement(widgetId, index, itemType);
  elements.splice(index + 1, 0, item);
  formContext.onFieldChange('elements', elements);
};

const removeItemAt = (formContext, index) => {
  const elements = [...formContext.values.elements];
  elements.splice(index, 1);
  formContext.onFieldChange('elements', elements);
};

function ValuesWidget({
  variant,
  teamId,
  gameplanId,
  data,
  dragHandleProps,
  requestRemove,
  canEdit,
  name,
}) {
  const [addMenuState, setAddMenuState] = React.useState({});

  const { t } = useTranslation();

  const isInteractive = variant === 'card' && canEdit;

  return (
    <>
      <Box
        sx={{
          mt: 0,
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'stretch',
          height: '24px',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Typography variant="subtitle2">{t('gameplans.valueWidget.title')}</Typography>
          {isInteractive && (
            <Stack direction="row" spacing={1} className="gp-widget-actions">
              <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                <DeleteIcon />
              </ContainedIconButton>
            </Stack>
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          ml: variant === 'card' ? 2 : 0,
          mr: variant === 'card' ? 2 : 0,
          mb: 1.5,
          pb: 1,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
        className="widget-content"
      >
        <Form
          name="gameplan-values"
          schema={schema}
          preventSaveIncomplete
          stateSlice="main.gameplans"
          submitActionCreator={gameplansActions.editGamePlanWidget}
          debouncedAutoSubmit={950}
          additionalProperties={{ domain_id: teamId, widget_id: data.id, gameplan_id: gameplanId }}
          initialValues={{
            elements: data.elements || [],
          }}
        >
          <FormContext.Consumer>
            {formContext => (
              <>
                <DragContainer
                  fieldName="elements"
                  type="GAMEPLAN_WIDGET_VALUES"
                  widgetId={data.id}
                  elements={formContext.values.elements}
                  onFieldChange={formContext.onFieldChange}
                  disabled={!isInteractive}
                >
                  <div>
                    {(!get(formContext, 'values.elements') ||
                      get(formContext, 'values.elements', []).length === 0) && (
                      <ButtonBase
                        sx={{
                          p: 2,
                          pt: 1.5,
                          width: '100%',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          setAddMenuState({
                            anchorEl: event.currentTarget,
                            index: 0,
                          });
                        }}
                      >
                        <Typography variant="body2" color="action.disabled">
                          {t('gameplans.valueWidget.emptyStateCta')}
                        </Typography>
                      </ButtonBase>
                    )}
                    {formContext.values?.elements?.map((elementsItem, index) => (
                      <DraggableElement
                        key={`values-widget-element-${elementsItem.id}`}
                        index={index}
                        draggableId={elementsItem.id}
                        onAddClick={event =>
                          setAddMenuState({ anchorEl: event.currentTarget, index })
                        }
                        onDelete={() => removeItemAt(formContext, index)}
                        isActive={addMenuState.index === index}
                        disabled={!isInteractive}
                      >
                        {elementsItem.element_type === ITEMTYPE_VALUE && (
                          <Box sx={elementStyle}>
                            <ValueIcon
                              sx={{
                                mt: variant === 'card' ? 1.25 : 1.75,
                                mr: variant === 'card' ? 0.5 : 1,
                              }}
                              fontSize={variant === 'card' ? 'small' : 'medium'}
                              color="action"
                            />
                            <Box sx={{ flexGrow: 10, p: 0.5, mb: -0.5 }}>
                              <FormTextField
                                fieldName={`elements.${index}.title`}
                                render={fieldProps => (
                                  <InlineEditInput
                                    {...fieldProps}
                                    disabled={!isInteractive}
                                    variant={variant === 'card' ? 'subtitle1' : 'h4'}
                                    fullWidth
                                    multiline
                                    label={t('gameplans.valueWidget.valueElementTitleLabel')}
                                  />
                                )}
                              />
                              {isInteractive ? (
                                <FormRichTextField
                                  fieldName={`elements.${index}.content`}
                                  render={fieldProps => (
                                    <RichTextEditor
                                      {...fieldProps}
                                      label={t(
                                        'gameplans.valueWidget.valueElementDescriptionLabel',
                                      )}
                                      placeholder={t(
                                        'gameplans.valueWidget.valueElementDescriptionLabel',
                                      )}
                                      toolbarVariant="floating"
                                      variant="inline"
                                      schema={SCHEMA_COMMENT}
                                      minRows={1}
                                    />
                                  )}
                                />
                              ) : (
                                <RichTextRenderer
                                  variant="inline"
                                  richTextContent={elementsItem.content}
                                  schema={SCHEMA_COMMENT}
                                  fontVariantOverrides={
                                    variant === 'card'
                                      ? {}
                                      : {
                                          p: 'bodylarge',
                                        }
                                  }
                                />
                              )}
                            </Box>
                          </Box>
                        )}
                        {elementsItem.element_type === ITEMTYPE_PRINCIPLE && (
                          <Box sx={elementStyle}>
                            <PrincipleIcon
                              sx={{
                                mt: variant === 'card' ? 1.25 : 1.75,
                                mr: variant === 'card' ? 0.5 : 1,
                              }}
                              fontSize={variant === 'card' ? 'small' : 'medium'}
                              color="action"
                            />
                            <Box sx={{ flexGrow: 10, p: 0.5 }}>
                              <FormTextField
                                fieldName={`elements.${index}.title`}
                                render={fieldProps => (
                                  <InlineEditInput
                                    {...fieldProps}
                                    disabled={!isInteractive}
                                    variant={variant === 'card' ? 'subtitle1' : 'h4'}
                                    fullWidth
                                    multiline
                                    label={t('gameplans.valueWidget.principleElementTitleLabel')}
                                  />
                                )}
                              />
                            </Box>
                          </Box>
                        )}
                        {elementsItem.element_type === ITEMTYPE_TRADEOFF && (
                          <Box sx={{ flexGrow: 10 }}>
                            <Box
                              sx={{
                                p: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <FormTextField
                                fieldName={`elements.${index}.slider.left_label`}
                                render={fieldProps => (
                                  <InlineEditInput
                                    {...fieldProps}
                                    disabled={!isInteractive}
                                    variant={variant === 'card' ? 'caption' : 'subtitle1'}
                                    fullWidth
                                    multiline
                                    placeholder="..."
                                    label={t('gameplans.valueWidget.tradeOffElementLeftLabel')}
                                  />
                                )}
                              />
                              <FormTextField
                                fieldName={`elements.${index}.slider.right_label`}
                                render={fieldProps => (
                                  <InlineEditInput
                                    {...fieldProps}
                                    variant={variant === 'card' ? 'caption' : 'subtitle1'}
                                    disabled={!isInteractive}
                                    fullWidth
                                    multiline
                                    placeholder="..."
                                    label={t('gameplans.valueWidget.tradeOffElementRightLabel')}
                                    inputProps={{ style: { textAlign: 'right' } }}
                                  />
                                )}
                              />
                            </Box>
                            <FormSlider
                              fieldName={`elements.${index}.slider.values`}
                              render={fieldProps => (
                                <TgDeltaSlider
                                  color="secondary"
                                  {...fieldProps}
                                  disabled={!isInteractive}
                                  valueLabelDisplay="off"
                                  size={variant === 'card' ? 'small' : 'medium'}
                                  getAriaLabel={i =>
                                    [
                                      t('gameplans.valueWidget.tradeOffElementSliderCurrentLabel'),
                                      t('gameplans.valueWidget.tradeOffElementSliderTargetLabel'),
                                    ][i]
                                  }
                                />
                              )}
                            />
                          </Box>
                        )}
                      </DraggableElement>
                    ))}
                  </div>
                </DragContainer>

                <Menu
                  id="gameplan-add-element-menu"
                  anchorEl={addMenuState.anchorEl}
                  open={Boolean(addMenuState.anchorEl)}
                  onClose={e => {
                    e.stopPropagation();
                    setAddMenuState({});
                  }}
                  keepMounted
                  variant="menu"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      addItemAt(data.id, formContext, addMenuState.index, ITEMTYPE_VALUE);
                      setAddMenuState({});
                    }}
                  >
                    <ListItemIcon name="listitem-value">
                      <ValueIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('gameplans.valueWidget.valueElementName')}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      addItemAt(data.id, formContext, addMenuState.index, ITEMTYPE_PRINCIPLE);
                      setAddMenuState({});
                    }}
                  >
                    <ListItemIcon name="listitem-principle">
                      <PrincipleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('gameplans.valueWidget.principleElementName')}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      addItemAt(data.id, formContext, addMenuState.index, ITEMTYPE_TRADEOFF);
                      setAddMenuState({});
                    }}
                  >
                    <ListItemIcon name="listitem-tradeoff">
                      <TradeOffIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('gameplans.valueWidget.tradeOffElementName')}</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </FormContext.Consumer>
        </Form>
      </Box>
      {variant === 'card' && <WidgetFooter data={data} dragHandleProps={dragHandleProps} />}
    </>
  );
}

ValuesWidget.propTypes = {
  teamId: PropTypes.string,
  data: PropTypes.object,
  dragHandleProps: PropTypes.object,
  gameplanId: PropTypes.string,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  name: PropTypes.string,
};

ValuesWidget.defaultProps = {
  variant: 'card',
};

export default ValuesWidget;
