import React from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { DateTimePickerProps } from './DateTimePicker.types';
/*
  Typical use:
   - Low level construct, wrapper around flexbox
   - Used to position things, provides padding and spacing between children
*/

function DateTimePicker(props: DateTimePickerProps) {
  return <MuiDateTimePicker {...props} />;
}
export default DateTimePicker;
