import moment from 'moment';
import { commitmentsActions } from 'state/ducks/commitments';

// chooses which date to show when opening calendar
export const calendarValue = commitmentData => {
  if (commitmentData?.data?.due_date) {
    // if there is a due date we start looking at that
    return moment.unix(commitmentData.data.due_date);
  }
  // if due date is null we show today in the calendar
  return moment().endOf('day');
};

export const isPastDate = commitmentData => {
  if (commitmentData?.data?.due_date) {
    return moment.unix(commitmentData.data.due_date) < moment();
  }
  return false;
};

// a custom submitActionCreator to handle changing of the owner as it needs both DELETE and ADD -operations in one
export const createReduxAction = (params, commitmentData) => {
  const { ...rest } = params;
  const actionCreator = commitmentsActions.editCommitment;
  rest.oldOwner = commitmentData?.data?.owner;
  return actionCreator(rest);
};
