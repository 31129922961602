import React from 'react';
import PropTypes from 'prop-types';
import { withLocation } from 'withRouter';
import StandardDialog from 'Components/Library/StandardDialog';
import TeamPage from 'Components/Features/Teams/TeamPage';

class DialogTeamPage extends React.Component {
  render() {
    const { open, teamId, page, subpage, onClose, zIndexModifier } = this.props;

    return (
      <StandardDialog
        open={open}
        onBackdropClick={onClose}
        fixedHeight
        name="dialog-team-view"
        size="large"
        zIndexModifier={zIndexModifier}
      >
        <TeamPage teamId={teamId} page={page} subpage={subpage} isDialog onClose={onClose} />
      </StandardDialog>
    );
  }
}

DialogTeamPage.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.exact({
    content: PropTypes.string,
  }),
  onClose: PropTypes.func,
  teamId: PropTypes.string,
  page: PropTypes.string,
  subpage: PropTypes.string,
  zIndexModifier: PropTypes.number,
};

export default withLocation(DialogTeamPage);
