import reducer from './reducers';

import * as commentsLogic from './logic';
import * as commentsTypes from './types';
import * as commentsActions from './actions';
import * as commentsSelectors from './selectors';

export { commentsLogic, commentsTypes, commentsActions, commentsSelectors };

export default reducer;
