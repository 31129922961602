import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Typography, Box, Checkbox, FormControlLabel } from '@mui/material';

import CheckedIcon from '@mui/icons-material/CheckCircle';
import UnCheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function MenuOption({
  Icon,
  title,
  description,
  onClick,
  mode,
  name,
  onChange,
  checked,
  contextIconProps,
}) {
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 100,
      }}
    >
      <Icon sx={{ flexGrow: 0, flexShrink: 0, mr: 2 }} {...contextIconProps} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexGrow: 10,
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body2" color="text.disabled">
          {description}
        </Typography>
      </Box>
    </Box>
  );

  const ContainerElement = mode === 'checkbox' ? FormControlLabel : ButtonBase;

  const containerProps =
    mode === 'checkbox'
      ? {
          control: (
            <Checkbox
              size="small"
              checkedIcon={<CheckedIcon color="secondary" />}
              icon={<UnCheckedIcon color="disabled" />}
              onChange={onChange}
              checked={checked}
              name={name}
            />
          ),
          label: content,
          labelPlacement: 'start',
        }
      : {};

  return (
    <ContainerElement
      sx={{
        display: 'flex',
        p: 0.5,
        borderRadius: 1,
        textAlign: 'left',
        mt: 0.5,
        mb: 0.5,
        ml: 0,
        mr: 0,
        flexGrow: 100,
        '& .MuiFormControlLabel-label': {
          flexGrow: 100,
        },
        '&:hover': {
          backgroundColor: theme => theme.palette.action.selected,
          boxShadow: theme => `inset 0px 0px 0px 100px ${theme.palette.action.hover}`,
          borderRadius: '10px',
        },
      }}
      onClick={onClick}
      {...containerProps}
      name={`${name}-menuoption`}
    >
      {mode === 'button' && content}
    </ContainerElement>
  );
}

MenuOption.propTypes = {
  Icon: PropTypes.elementType,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  mode: PropTypes.oneOf(['button', 'checkbox']),
  name: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  contextIconProps: PropTypes.object,
};

MenuOption.defaultProps = {
  mode: 'button',
};

export default MenuOption;
