import React from 'react';
import { SvgIcon } from '@mui/material';

function ToggleNavIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-1 -3 18 18">
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
      <path d="M1.33337 11.3334C1.05837 11.3334 0.822957 11.2355 0.627124 11.0397C0.431291 10.8438 0.333374 10.6084 0.333374 10.3334V1.66675C0.333374 1.39175 0.431291 1.15633 0.627124 0.960498C0.822957 0.764665 1.05837 0.666748 1.33337 0.666748H12.6667C12.9417 0.666748 13.1771 0.764665 13.373 0.960498C13.5688 1.15633 13.6667 1.39175 13.6667 1.66675V10.3334C13.6667 10.6084 13.5688 10.8438 13.373 11.0397C13.1771 11.2355 12.9417 11.3334 12.6667 11.3334H1.33337ZM5.66671 10.3334H12.6667V1.66675H5.66671V10.3334ZM4.66671 10.3334V1.66675H1.33337V10.3334H4.66671Z" />
    </SvgIcon>
  );
}

export default ToggleNavIcon;
