import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    padding: theme.spacing(6),
    paddingLeft: `calc(${theme.spacing(6)} - 10px)`,
    paddingRight: `calc(${theme.spacing(6)} - 10px)`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      paddingLeft: `calc(${theme.spacing(3)} - 10px)`,
      paddingRight: `calc(${theme.spacing(3)} - 10px)`,
      width: 'auto',
    },
  },

  scrollable: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
});

class ContentPage extends Component {
  render() {
    const { classes, children, scrollable } = this.props;
    return (
      <div className={clsx(classes.root, scrollable && classes.scrollable)} id="print">
        {children}
      </div>
    );
  }
}

ContentPage.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    scrollable: PropTypes.string,
  }),
  scrollable: PropTypes.bool,
};

export default withStyles(styles)(ContentPage);
