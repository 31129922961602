import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { peopleSelectors } from 'state/ducks/people';

import ObjectiveReflections from './ObjectiveReflections';

class ObjectiveReflectionsContainer extends React.Component {
  render() {
    const { objectiveID, objectiveData, canEdit, selectFullName } = this.props;
    if (
      !!objectiveData &&
      objectiveData.ok &&
      (objectiveData.data.stage === 'HISTORY' ||
        (objectiveData.data.stage === 'LIVE' && objectiveData.data.daysLeft < 15))
    ) {
      return (
        <ObjectiveReflections
          canEdit={canEdit}
          objectiveData={objectiveData}
          objectiveID={objectiveID}
          selectFullName={selectFullName}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
});

ObjectiveReflectionsContainer.propTypes = {
  objectiveID: PropTypes.string,
  objectiveData: PropTypes.object,
  canEdit: PropTypes.bool,
  selectFullName: PropTypes.func,
};

export default connect(mapStateToProps)(ObjectiveReflectionsContainer);
