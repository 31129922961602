import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import StandardInput from 'Components/Library/StandardInput';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';

const styles = theme => ({
  minmaxrow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    width: '100%',
  },
  smallinput: {
    width: 140,
  },
  cornerinput: {
    width: 220,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(),
  },
});

const createLabel = (text, parText) => (
  <span>
    <span>{text}</span>
    <Hidden smDown>{parText}</Hidden>
  </span>
);

class QuadEditor extends React.Component {
  render() {
    const { t, classes } = this.props;
    return (
      <div className={this.props.className}>
        <div className={classes.card}>
          <Typography variant="subtitle1" className={classes.label}>
            {t('insights.quadeditorxaxisquestionsubtitle')}
          </Typography>
          <FormTextField
            fieldName="xquestion"
            render={fieldProps => (
              <StandardInput
                className={this.props.classes.input}
                label={t('insights.quadeditorquestionInputLabel')}
                margin="dense"
                {...fieldProps}
              />
            )}
          />
          <br />
          <br />
          <div className={classes.minmaxrow}>
            <FormTextField
              fieldName="xmin"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.smallinput}
                  label={t('insights.quadeditormininputlabel')}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
            <FormTextField
              fieldName="xmax"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.smallinput}
                  label={t('insights.quadeditormaxinputlabel')}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
          </div>
          <br />
        </div>
        <div className={classes.card}>
          <Typography variant="subtitle1" className={classes.label}>
            {t('insights.quadeditoryaxisquestionsubtitle')}
          </Typography>
          <FormTextField
            fieldName="yquestion"
            render={fieldProps => (
              <StandardInput
                className={this.props.classes.input}
                label={t('insights.quadeditorquestionInputLabel')}
                margin="dense"
                {...fieldProps}
              />
            )}
          />
          <br />
          <br />
          <div className={classes.minmaxrow}>
            <FormTextField
              fieldName="ymin"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.smallinput}
                  label={t('insights.quadeditormininputlabel')}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
            <FormTextField
              fieldName="ymax"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.smallinput}
                  label={t('insights.quadeditormaxinputlabel')}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
          </div>
          <br />
        </div>
        <div className={classes.card}>
          <Typography variant="subtitle1" className={classes.label}>
            {t('insights.quadeditorcornerlabelssubtitle')}
          </Typography>
          <div className={classes.minmaxrow}>
            <FormTextField
              fieldName="xminymax"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.cornerinput}
                  label={createLabel(t('insights.quadeditortopleftinputlabel'), '(xmin & ymax)')}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
            <FormTextField
              fieldName="xmaxymax"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.cornerinput}
                  label={createLabel(t('insights.quadeditortoprightinputlabel'), '(xmax & ymax)')}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
          </div>
          <div className={classes.minmaxrow}>
            <FormTextField
              fieldName="xminymin"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.cornerinput}
                  label={createLabel(t('insights.quadeditorbottomleftinputlabel'), '(xmin & ymin)')}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
            <FormTextField
              fieldName="xmaxymin"
              render={fieldProps => (
                <StandardInput
                  className={this.props.classes.cornerinput}
                  label={createLabel(
                    t('insights.quadeditorbottomrightinputlabel'),
                    '(xmax & ymin)',
                  )}
                  margin="dense"
                  {...fieldProps}
                />
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

QuadEditor.propTypes = {
  classes: PropTypes.exact({
    minmaxrow: PropTypes.string,
    input: PropTypes.string,
    smallinput: PropTypes.string,
    cornerinput: PropTypes.string,
    card: PropTypes.string,
    label: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(QuadEditor));
