import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import {
  useCurrentSelection,
  useAttrs,
  useChainedCommands,
  useExtensionEvent,
  useSelectedText,
  usePositioner,
  useHelpers,
} from '@remirror/react';
import { LinkExtension, ShortcutHandlerProps } from 'remirror/extensions';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Input from 'Components/Library/BaseComponents/Input';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

function useLinkShortcut() {
  const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();

  useExtensionEvent(
    LinkExtension,
    'onShortcut',
    useCallback(props => setLinkShortcut(props), []),
  );

  return { linkShortcut };
}

export function useFloatingLinkState() {
  const { linkShortcut } = useLinkShortcut();
  const selectedText = useSelectedText();
  const chain = useChainedCommands();
  const selection = useCurrentSelection();
  const { to, empty, from } = selection;

  const url = (useAttrs().link()?.href as string) ?? '';
  const [href, setHref] = useState<string>(url);

  useEffect(() => {
    setHref(url);
  }, [url]);

  const submitHref = useCallback(
    linkText => {
      let range: { from: number; to: number } = linkShortcut ?? undefined;

      if (from === to) {
        range = { from, to: from + linkText.length };
      }
      chain.replaceText({ content: linkText, selection: { from, to } });

      if (href === '') {
        chain.removeLink();
      } else {
        chain.updateLink({ href, auto: false }, range);
      }

      chain.focus(range?.to ?? to).run();
      setHref('');
    },
    [linkShortcut, chain, href, to],
  );

  const clickEdit = useCallback(() => {
    if (empty) {
      chain.selectLink();
    }
  }, [chain, empty]);

  return useMemo(
    () => ({
      href,
      setHref,
      linkShortcut,
      clickEdit,
      submitHref,
      selectedText,
    }),
    [href, linkShortcut, clickEdit, submitHref, selectedText],
  );
}

// eslint-disable-next-line import/prefer-default-export
export function EditLinkDialog({ isEditingLink, setIsEditingLink, isFloating }) {
  const { submitHref, href, setHref, selectedText } = useFloatingLinkState();
  const { t } = useTranslation();
  const [linkText, setLinkText] = useState('');

  const positioner = usePositioner('selection');
  const container = useHelpers().getPositionerWidget();

  useEffect(() => {
    setLinkText(selectedText);
  }, [selectedText]);

  if (!isEditingLink) {
    return null;
  }

  const popOverProps: PopoverProps = { open: false };
  if (isEmpty(positioner.data)) {
    popOverProps.anchorEl = container;
  } else {
    popOverProps.anchorReference = 'anchorPosition';
    popOverProps.anchorPosition = {
      top: positioner.data.from.bottom,
      left: positioner.data.from.left,
    };
  }

  const closeDialog = () => {
    setIsEditingLink(false);
    setLinkText('');
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const { code } = event;

    if (code === 'Enter') {
      if (!!linkText && !!href) {
        submitHref(linkText);
        closeDialog();
        if (!isFloating) {
          event.preventDefault();
        }
      }
    }

    if (code === 'Escape') {
      closeDialog();
    }
  };

  return (
    <Popover
      {...popOverProps}
      open={isEditingLink}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{ zIndex: 99999 }}
      onClose={closeDialog}
    >
      <Box sx={{ p: 2, width: 300 }}>
        <Stack sx={{ mb: 2 }} spacing={2}>
          <Typography variant="subtitle2">{t('general.richText.editLink')}</Typography>
          <Input
            placeholder={t('general.richText.linkText')}
            size="medium"
            fullWidth
            onChange={(event: ChangeEvent<HTMLInputElement>) => setLinkText(event.target.value)}
            value={linkText}
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
            sx={{ mb: 2 }}
          />
          <Input
            placeholder={t('general.richText.url')}
            size="medium"
            fullWidth
            onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
            value={href}
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
            sx={{ mb: 2 }}
            autoFocus
          />
        </Stack>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button onClick={closeDialog}>{t('general.cancel')}</Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={!linkText || !href}
            onClick={() => {
              submitHref(linkText);
              closeDialog();
            }}
          >
            {t('general.save')}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

EditLinkDialog.propTypes = {
  isEditingLink: PropTypes.bool,
  setIsEditingLink: PropTypes.func,
  isFloating: PropTypes.bool,
};
