import firebase from 'firebase/app';
import 'firebase/messaging';
import { notificationconfigActions } from 'state/ducks/notificationconfig';
import { inboxActions } from 'state/ducks/inbox';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  vapid: process.env.REACT_APP_FIREBASE_VAPID,
};

// Client side flows:
// UC 1:
//  - Register for push notifications for the first time, we dont have a hashed arn yet.
//  Triggered by: the user opting in for notifications, after the permission have been given

// UC 2:
//  - We have a hashed arn and token, onTokenRefresh() fires and we need to register the new token for the existing arn
// Triggered by the listener, the user has previously opted in for notifications

// UC 3:
//  - This library gets loaded, there is already permissions for notifications but we do not have notificationconfig in the state
//  Triggered by loading the app after clearing the storage
//  Note: for this to work, we must ensure that we only check for this use case _after_ the state has been rehydrated

// All of the above need to have the possibility to delete the token from firebase and request a new one if the API returns a ValidationException ("The provided token cannot be claimed")
let messaging;

const getToken = async () => {
  // Avoid race conditions:
  // do not ever try this until the service worker is ready
  // and firebase is initialized
  // for example on FF browsing in private mode does not allow service worker so adding check here
  if (!messaging || !navigator.serviceWorker) {
    return null;
  }
  await navigator.serviceWorker.ready;
  const token = await messaging.getToken();
  return token;
};

const getAndRegisterToken = async dispatch => {
  const token = await getToken();
  dispatch(notificationconfigActions.registerPushEndpoint(token));
  // Add listener for token refresh
  messaging.onTokenRefresh(() => {
    // UC 2
    messaging
      .getToken()
      .then(refreshedToken => {
        dispatch(notificationconfigActions.registerPushEndpoint(refreshedToken));
      })
      .catch();
  });
};

export const askForPermissioToReceiveNotifications = async dispatch => {
  // UC 1
  try {
    await messaging.requestPermission();
    await getAndRegisterToken(dispatch);
  } catch (error) {
    // console.error(error);
  }
};

export const deleteToken = async oldToken => {
  try {
    if (!!oldToken) {
      await messaging.deleteToken(oldToken);
    }
  } catch (error) {
    // console.error(error);
  }
};

export const initializeFirebase = async () => {
  // console.log('initializing firebase');

  // Wait for serviceworker registration.
  // If this is not done, firebase will try to use it's own default sw and everything blows up

  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready;
    const app = firebase.initializeApp(firebaseConfig);
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging(app);

      // console.log('registering firebase to use the default sw');

      // Let's use the default CRA service worker
      messaging.useServiceWorker(registration);

      messaging.usePublicVapidKey(firebaseConfig.vapid);

      /*
      // TODO: notifications are not shown on desktop if the site is on a focused tab
      // without this, but on android having this leads to duplicate notifications
      // test if this happens now?
      */
    }
  }
};

// eslint-disable-next-line consistent-return
export const initializeNotificationsModule = async dispatch => {
  // console.log('initializing notifications module');

  // Avoid race conditions:
  // do not ever try this until the service worker is ready
  if (!navigator.serviceWorker) {
    return null;
  }
  await navigator.serviceWorker.ready;
  // Check for UC 3:
  if (!('Notification' in window)) {
    // console.log('This browser does not support notifications');
  } else if (Notification.permission === 'granted') {
    // console.log('Notification permissions have been granted, get token');
    await getAndRegisterToken(dispatch);
  } else if (Notification.permission !== 'denied' || Notification.permission === 'default') {
    // console.log('Notification permissions have not been asked yet, propose opt in');
    setTimeout(() => {
      dispatch(
        inboxActions.createLocalNotification({
          messageID: 'REQUEST_PUSH_OPT_IN',
          itemtype: 'pushOptInRequest',
          from: 'tg',
        }),
      );
    }, 5500);
  } else {
    // console.log('Notification permissions have been explicitly denied');
  }
};
