import reducer from './reducers';

import * as integrationsLogic from './logic';
import * as integrationsTypes from './types';
import * as integrationsActions from './actions';
import * as integrationsSelectors from './selectors';

export { integrationsLogic, integrationsTypes, integrationsActions, integrationsSelectors };

export default reducer;
