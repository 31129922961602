import { get, orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import { copyState, enrich } from '../../helpers';

// eslint-disable-next-line import/prefer-default-export
export function selectInterlock(slice, id) {
  return get(slice, `interlocks.${id}`, enrich());
}

export const sortingIteratee = (interlockItem, sortColumn) => {
  if (sortColumn === 'name') {
    return interlockItem.data[sortColumn].toLowerCase();
  }
  return interlockItem.data[sortColumn];
};

export const sortInterlocks = (
  state,
  userID,
  options = {
    sortColumn: 'default',
    sortOrder: SORTING_ORDERS.asc,
    stateInstanceName: 'userInterlocks',
  },
) => {
  const newState = { ...state };
  const { sortColumn, sortOrder, stateInstanceName } = options;

  // if no explicit sorting applied
  if (!sortColumn || sortColumn === 'default') {
    return get(newState, [stateInstanceName, userID], enrich());
  }

  const userInterlocksWithData = Object.values(newState.interlocks).filter(
    interlock =>
      newState[stateInstanceName] &&
      newState[stateInstanceName][userID]?.data &&
      newState[stateInstanceName][userID].data.indexOf(interlock?.data?.id) > -1,
  );
  const sortedUserInterlocksWithData = orderBy(
    userInterlocksWithData,
    [interlock => sortingIteratee(interlock, sortColumn)],
    [sortOrder],
  );

  return {
    ...newState[stateInstanceName][userID],
    data: sortedUserInterlocksWithData.map(interlock => interlock.data.id),
  };
};

export const selectUserInterlocks = createSelector(
  [state => state, (state, userID) => userID, (state, userID, sortOptions) => sortOptions],
  (state, userID, sortOptions) =>
    sortInterlocks(state, userID, { ...sortOptions, stateInstanceName: 'userInterlocks' }),
);

export function selectContributors(slice, interlockID) {
  return get(slice?.contributors, interlockID, enrich());
}

export function selectContributorsWithoutOwner(slice, interlockID) {
  const interlock = copyState(selectInterlock(slice, interlockID));
  const ownerID = get(interlock, 'data.owner');
  const preparedContributors = copyState(get(slice?.contributors, interlockID, enrich()));

  if (preparedContributors.data) {
    preparedContributors.data = preparedContributors.data.filter(
      contributor => contributor.id !== ownerID,
    );
    return preparedContributors;
  }

  return preparedContributors;
}

export function canDeleteInterlock({ slice, interlockID, userId, isChangeManager }) {
  // Only creator, owner and change manager may delete
  const interlock = selectInterlock(slice, interlockID);
  if (interlock.ok) {
    if (userId === interlock.data.owner || userId === interlock.data.creator || isChangeManager) {
      return true;
    }
  }
  return false;
}

export function canEditInterlock({ slice, interlockID, userId, isChangeManager }) {
  return canDeleteInterlock({ slice, interlockID, userId, isChangeManager });
}

export const selectTeamInterlocks = createSelector(
  [state => state, (state, teamID) => teamID, (state, teamID, sortOptions) => sortOptions],
  (state, teamID, sortOptions) =>
    sortInterlocks(state, teamID, { ...sortOptions, stateInstanceName: 'teamInterlocks' }),
);

export const selectResolvedTeamInterlocks = createSelector(
  [state => state, (state, teamID) => teamID, (state, teamID, sortOptions) => sortOptions],
  (state, teamID, sortOptions) =>
    sortInterlocks(state, teamID, { ...sortOptions, stateInstanceName: 'teamResolvedInterlocks' }),
);

export const selectResolvedUserInterlocks = createSelector(
  [state => state, (state, userID) => userID, (state, userID, sortOptions) => sortOptions],
  (state, userID, sortOptions) =>
    sortInterlocks(state, userID, { ...sortOptions, stateInstanceName: 'userResolvedInterlocks' }),
);

export function hasOwnerPermissions(currentUserID, ownerID) {
  return currentUserID === ownerID;
}

export function selectContributorChartInterlocks(slice, interlockID) {
  return get(slice, ['contributorsChart', interlockID], enrich());
}
