import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { APIGW_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

const SLICE_NAME = 'activitylog';

// eslint-disable-next-line import/prefer-default-export
export const getActivitylogLogic = createLogic({
  type: types.FETCH_ACTIVITYLOG,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    const { id, force } = action.payload;
    if (
      !!id &&
      shouldFetch(
        selectors.selectActivitylogForNode(state.main[SLICE_NAME], id),
        state.main.connection,
        force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const { id } = action.payload;
    axios
      .get(`${APIGW_URL}/activitylogs/${getState().auth.tenantID}/activitylogs`, {
        params: { id },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedNodeActivitylog({ ...res.data.result, id }));
      })
      .catch(() => {
        dispatch(actions.failedNodeActivitylog(action.payload));
      })
      .then(() => done());
  },
});

export const getActivitylogDebouncedLogic = createLogic({
  type: types.FETCH_ACTIVITYLOG_DEBOUNCED,
  debounce: 5000,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    const { id, force } = action.payload;
    if (
      !!id &&
      shouldFetch(
        selectors.selectActivitylogForNode(state.main[SLICE_NAME], id),
        state.main.connection,
        force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const { id } = action.payload;
    axios
      .get(`${APIGW_URL}/activitylogs/${getState().auth.tenantID}/activitylogs`, {
        params: { id },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedNodeActivitylog({ ...res.data.result, nodeId: id }));
      })
      .catch(() => {
        dispatch(actions.failedNodeActivitylog(action.payload));
      })
      .then(() => done());
  },
});
