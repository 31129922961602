import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Link from '@mui/material/Link';

import UnfoldIcon from '@mui/icons-material/UnfoldMore';
import SettingsIcon from '@mui/icons-material/Settings';
import WhatsNewIcon from '@mui/icons-material/Notes';
import SupportIcon from 'Components/Common/SupportIcon';
import LogoutIcon from 'Components/Common/LogoutIcon';
import UpdateIcon from '@mui/icons-material/CheckCircle';
import { logout } from 'config/helpers';

import { getRegistration } from 'serviceWorkerRegistration';
import { connectionSelectors } from 'state/ducks/connection';
import { peopleActions } from 'state/ducks/people';
import { channel } from 'config/swbus';
import UserChip from 'Components/Library/UserChip';
import DialogUserSettings from 'Components/Common/DialogUserSettings2';

const styles = theme => ({
  root: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    transition: 'all 0.125s ease',
    alignItems: 'center',
    justifyContent: 'stretch',
    cursor: 'pointer',
    width: 192,
    ...theme.shape,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  menu: {
    width: 192,
    '& .MuiList-root': {
      paddingTop: 0,
    },
    '& li, a': {
      ...theme.shape,
      marginRight: theme.spacing(),
      marginLeft: theme.spacing(),
      paddingLeft: theme.spacing(),
      ...theme.typography.subtitle2,
      color: theme.palette.text.secondary,
      '& :hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& svg': {
        marginRight: theme.spacing(),
      },
    },
  },
  content: {
    ...theme.shape,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 10,
    padding: 0,
    height: 32,
    overflow: 'hidden',
  },
  appVersionContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  version: {
    userSelect: 'text',
  },
});

class Profile extends Component {
  state = {
    anchorEl: null,
    menuOpen: false,
    settingsPanelOpen: false,
    updateCheckEmptyResult: false,
  };

  componentDidMount() {
    this.props.dispatch(peopleActions.getPersons({ subs: [this.props.auth.userID] }));
  }

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget, menuOpen: true });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  openSettings = () => {
    this.setState({ settingsPanelOpen: true, menuOpen: false });
  };

  closeSettings = () => {
    this.setState({ settingsPanelOpen: false, menuOpen: false });
  };

  logout = async () => {
    logout(this.props.auth, { unregisterSw: true });
  };

  checkUpdates = async registration => {
    if (!!registration) {
      // calling registration.update() will check if there's a new version available
      const result = await registration.update();

      // if not waiting or installing, that means our version was already the latest one
      if (!result.waiting && !result.installing) {
        // Show snackbar for "No update found"
        this.setState({ updateCheckEmptyResult: true });
      } else {
        // A new version was found and is installing/waiting
        // This is a bit backwards, but we need to post a message to the
        // waiting service worker for it to post a message back to us to take
        // the new version into use
        // This will trigger the logic in ServiceWorkerUpdateHandler
        channel.postMessage({ title: 'UPDATE_CHECK_RETURNED_WAITING' });
      }
    }
  };

  hideSnackbar = () => {
    this.setState({ updateCheckEmptyResult: null });
  };

  renderButton = actionable => {
    const { classes, auth } = this.props;
    return (
      <div
        data-tg-name="drawer-footer-menu-toggle"
        onClick={actionable ? this.handlePopoverOpen : null}
        className={classes.content}
      >
        <UserChip color="transparent" sub={auth.userID} />
        <Box
          mr={1}
          sx={{ height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <UnfoldIcon fontSize="small" color="action" />
        </Box>
      </div>
    );
  };

  render() {
    const { auth, classes, isOnline, t } = this.props;
    const { anchorEl, menuOpen, settingsPanelOpen, updateCheckEmptyResult } = this.state;

    const registration = getRegistration();

    return (
      <>
        <ButtonBase className={classes.root}>{this.renderButton(true)}</ButtonBase>
        <Menu
          open={menuOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          marginThreshold={4}
          onClose={() => this.handlePopoverClose()}
          classes={{ paper: classes.menu }}
          elevation={8}
          sx={{
            '& .MuiDivider-root': {
              marginTop: '2px !important',
              marginBottom: '2px !important',
            },
          }}
        >
          {this.renderButton(false)}
          <Box sx={{ width: '100%', height: theme => theme.spacing() }} />
          <MenuItem
            dense
            id="drawer-footer-menu-settings-opt"
            disabled={!isOnline}
            onClick={this.openSettings}
          >
            <SettingsIcon color="action" fontSize="small" />
            {t('usersettingsmenu.settingsMenuItem')}
          </MenuItem>
          <Divider />
          <MenuItem
            dense
            component={Link}
            href="https://support.tangible-growth.com"
            target="_blank"
            id="drawer-footer-menu-support-page-opt"
            onClick={this.handlePopoverClose}
          >
            <SupportIcon color="action" fontSize="small" />
            {t('usersettingsmenu.documentationMenuItem')}
          </MenuItem>
          <MenuItem
            dense
            component={Link}
            href="https://www.tangible-growth.com/whats-new"
            target="_blank"
            id="drawer-footer-menu-whats-new-opt"
            onClick={this.handlePopoverClose}
          >
            <WhatsNewIcon color="action" fontSize="small" />
            {t('usersettingsmenu.whatsNewMenuItem')}
          </MenuItem>
          <Divider />
          {!!registration ? (
            <Tooltip title={`Version: ${process.env.REACT_APP_BUILD_VERSION}`} placement="right">
              <MenuItem
                dense
                onClick={() => this.checkUpdates(registration)}
                name="mainmenu-check-updates-btn"
              >
                <UpdateIcon color="action" fontSize="small" />
                {t('usersettingsmenu.updateMenuItem')}
              </MenuItem>
            </Tooltip>
          ) : (
            <Typography variant="caption" className={classes.version}>
              {`Version: ${process.env.REACT_APP_BUILD_VERSION}`}
            </Typography>
          )}
          <Divider />
          <MenuItem dense id="drawer-footer-menu-logout-opt" onClick={() => this.logout()}>
            <LogoutIcon color="action" fontSize="small" id="logoutIcon" />
            {t('general.logout')}
          </MenuItem>
        </Menu>
        {settingsPanelOpen && <DialogUserSettings onClose={this.closeSettings} sub={auth.userID} />}
        <Snackbar
          open={!!updateCheckEmptyResult}
          autoHideDuration={6000}
          message={t('swUpdateHandler.noUpdatesFound')}
          onClose={this.hideSnackbar}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
});

Profile.propTypes = {
  auth: PropTypes.object,
  isOnline: PropTypes.bool,
  dispatch: PropTypes.func,
  classes: PropTypes.exact({
    container: PropTypes.string,
    avatarContainer: PropTypes.string,
    appVersionContainer: PropTypes.string,
    version: PropTypes.string,
    root: PropTypes.string,
    content: PropTypes.string,
    menu: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(Profile)));
