import { styled } from '@mui/material/styles';

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  
  &:not(.Mui-disabled):not(.Mui-error):hover > :is(input,textarea) {
    border-color: ${
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.7)'
    };
  }
  &:not(.Mui-disabled):not(.Mui-error) > :is(input:focus,textarea:focus) {
    border-color: ${theme.palette.action.active};
  }

  &.Mui-disabled > *::placeholder {
    color: ${theme.palette.action.disabled};
  }

  &.Mui-error > *::placeholder {
    color: ${theme.palette.error.main};
  }
  
  &.Mui-disabled > :is(input,textarea) {
    border-color: ${theme.palette.action.selected};
    color: ${theme.palette.action.disabled};
  }

  &.Mui-error > :is(input,textarea) {
    border-color: ${theme.palette.error.main};
    color: ${theme.palette.error.main};
  }
  
  `,
);

const InputRoot = styled(StyledInputRoot, {
  name: 'Input',
  slot: 'Root',
  overridesResolver: (props, styles) => styles.root,
})({});

export default InputRoot;
