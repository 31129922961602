import React from 'react';

function TaskList(props) {
  const { children } = props;
  return (
    <ul
      // the data attributes are required for copy pasting
      // back to the editor to correctly parse the content
      data-task-list
    >
      {children}
    </ul>
  );
}

TaskList.propTypes = {};

export default TaskList;
