import React from 'react';
import { get } from 'lodash';
import FormContext from '../FormContext';

function FormSelectorField(props) {
  const { render, fieldName } = props;
  const { onFieldChange, values, formName, fieldErrors, showErrors } =
    React.useContext(FormContext);

  const fieldProps = {
    onSelect: value => onFieldChange(fieldName, value),
    value: get(values, fieldName),
    name: `${formName}-${fieldName}`,
    error: !!showErrors && fieldName in fieldErrors,
    helperText: !!showErrors && fieldName in fieldErrors ? fieldErrors[fieldName] : ' ',
  };
  return render(fieldProps);
}

export default FormSelectorField;
