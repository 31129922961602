import React from 'react';
import { capitalize, get } from 'lodash';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import { InlineEditInputProps } from './InlineEditInput.types';

const StyledTextField = styled(TextField)(
  ({ theme }) => `
    transition: all 0.25s ease;
    padding: 0;
    & .MuiInputBase-root {
      padding: 0;
    }


    &:hover .MuiInputBase-root:not(.Mui-error) > textarea {
      -webkit-text-fill-color: ${theme.palette.text.primary};
    }

    & .Mui-error {
      color: ${theme.palette.error.main};
      -webkit-text-fill-color: ${theme.palette.error.main};
    }

    & .Mui-disabled {
      color: ${theme.palette.text.primary} !important;
      -webkit-text-fill-color: ${theme.palette.text.primary} !important;
    }

    & .MuiFormHelperText-root {
      align-self: flex-start;
      font-size: 1rem;
      letter-spacing: 0;
      transform: scale(0.75);
      transform-origin: 0px 0px;
    }

    & fieldset {
      transition: border 0.25s ease;
      border: 0;
      & legend {
        visibility: hidden;
        width: 0;
      }
      & span {
        visibility: hidden;
      }
    }
    ${Object.keys(theme.typography).map(
      key => `
        & .TgInlineEditInput-variant${capitalize(key)} {
          font-size: ${theme.typography[key].fontSize};
          font-weight: ${theme.typography[key].fontWeight};
          font-family: ${theme.typography[key].fontFamily};
          line-height: ${theme.typography[key].lineHeight};
          letter-spacing: ${theme.typography[key].letterSpacing} !important;
          & label:not(.Mui-focused):not(.MuiInputLabel-shrink) {
            font-size: ${theme.typography[key].fontSize};
            letter-spacing: ${theme.typography[key].letterSpacing} !important;
            font-weight: ${theme.typography[key].fontWeight};
          }
        }
        `,
    )};
  `,
);

function InlineEditInput(props: InlineEditInputProps) {
  const { classes, variant = 'body1', label, inputProps = {}, ...rest } = props;

  // Fix weird MUI bug where the label is shown on top of the text
  // If the value is updated via props without user interaction
  const inputLabelProps = !!rest.value ? { shrink: true } : {};

  return (
    <StyledTextField
      placeholder={label}
      {...rest}
      inputProps={{
        'aria-label': label,
        ...inputProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...rest.InputProps,
        classes: {
          ...get(rest, 'InputProps.classes', {}),
          root: `TgInlineEditInput-variant${capitalize(variant)}`,
        },
      }}
      InputLabelProps={inputLabelProps}
      variant="outlined"
    />
  );
}

export default InlineEditInput;
