import { get, orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { NODE_TYPE_TEAM, NODE_TYPE_USER } from 'config/constants';

import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import { enrich } from '../../helpers';

export function selectCommitment(slice, commitmentID) {
  return get(slice, `commitments.${commitmentID}`, enrich());
}

export function selectContributors(slice, commitmentID) {
  return get(slice?.contributors, commitmentID, enrich());
}

export const sortingIteratee = (commitmentItem, sortColumn) => {
  if (sortColumn === 'name') {
    return commitmentItem.data[sortColumn].toLowerCase();
  }
  return commitmentItem.data[sortColumn];
};

export const sortCommitments = (
  state,
  userID,
  options = {
    sortColumn: 'default',
    sortOrder: SORTING_ORDERS.asc,
    stateInstanceName: 'userCommitments',
  },
) => {
  const newState = { ...state };
  const { sortColumn, sortOrder, stateInstanceName } = options;

  // if no explicit sorting applied
  if (!sortColumn || sortColumn === 'default') {
    return get(newState, [stateInstanceName, userID], enrich());
  }

  const userCommitmentsWithData = Object.values(newState.commitments).filter(
    commitment =>
      newState[stateInstanceName] &&
      newState[stateInstanceName][userID]?.data &&
      newState[stateInstanceName][userID].data.indexOf(commitment?.data?.id) > -1,
  );
  const sortedUserCommitmentsWithData = orderBy(
    userCommitmentsWithData,
    [commitment => sortingIteratee(commitment, sortColumn)],
    [sortOrder],
  );

  return {
    ...newState[stateInstanceName][userID],
    data: sortedUserCommitmentsWithData.map(commitment => commitment.data.id),
  };
};

export const selectUserCommitments = createSelector(
  [state => state, (state, userID) => userID, (state, userID, sortOptions) => sortOptions],
  (state, userID, sortOptions) =>
    sortCommitments(state, userID, { ...sortOptions, stateInstanceName: 'userCommitments' }),
);

export const selectCompletedUserCommitments = createSelector(
  [state => state, (state, userID) => userID, (state, userID, sortOptions) => sortOptions],
  (state, userID, sortOptions) =>
    sortCommitments(state, userID, {
      ...sortOptions,
      stateInstanceName: 'userCompletedCommitments',
    }),
);

export const selectTeamCommitments = createSelector(
  [state => state, (state, userID) => userID, (state, userID, sortOptions) => sortOptions],
  (state, userID, sortOptions) =>
    sortCommitments(state, userID, { ...sortOptions, stateInstanceName: 'teamCommitments' }),
);

export const selectCompletedTeamCommitments = createSelector(
  [state => state, (state, userID) => userID, (state, userID, sortOptions) => sortOptions],
  (state, userID, sortOptions) =>
    sortCommitments(state, userID, {
      ...sortOptions,
      stateInstanceName: 'teamCompletedCommitments',
    }),
);

export function canDeleteCommitment({ slice, commitmentID, userId, isChangeManager }) {
  // Only creator or owner may delete
  const commitment = selectCommitment(slice, commitmentID);
  if (commitment.ok) {
    if (userId === commitment.data.owner || userId === commitment.data.creator || isChangeManager) {
      return true;
    }
  }
  return false;
}

export function canEditCommitment({
  slice,
  commitmentID,
  userId,
  managedTeamIds,
  isChangeManager,
}) {
  const canDelete = canDeleteCommitment({ slice, commitmentID, userId, isChangeManager });
  if (canDelete) {
    // If the user can delete, they can also edit
    return true;
  }
  const contributors = selectContributors(slice, commitmentID);

  if (contributors.ok) {
    for (const node of contributors.data) {
      if (node.type === NODE_TYPE_USER && node.id === userId) {
        return true;
      }
      if (node.type === NODE_TYPE_TEAM && managedTeamIds.includes(node.id)) {
        return true;
      }
    }
  }

  return false;
}
