import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { SORTING_ORDERS } from 'state/ducks/interlocks/types';

const styles = theme => ({
  cell: {
    paddingLeft: '0',
    ...theme.typography.caption,
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.background.paper,
    borderBottomColor: theme.palette.divider,
  },
  sortableCell: {
    paddingLeft: '0',
    ...theme.typography.caption,
    backgroundColor: theme.palette.background.paper,
    borderBottomColor: theme.palette.divider,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
});

function InterlocksTableHeader(props) {
  const { t } = useTranslation();
  const { classes, sortColumn, sortOrder, setSortBy, setSortOrder } = props;
  const translationNamespace = 'interlocks';

  const onSortClick = name => {
    setSortBy(name);
    setSortOrder(sortOrder === SORTING_ORDERS.asc ? SORTING_ORDERS.desc : SORTING_ORDERS.asc);
  };
  const isSortingByDueDate = sortColumn === 'due_date';
  const isSortingByName = sortColumn === 'name';

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.cell} sx={{ width: 120 }}>
          {t(`${translationNamespace}.propertyLabels.status`)}
        </TableCell>
        <TableCell className={classes.sortableCell} sx={{ width: '65%' }}>
          <IconButton
            size="small"
            onClick={() => onSortClick('name')}
            className={classes.sortableCell}
            disableRipple
            component="div"
            sx={{
              display: 'flex',
              width: 'auto',
              height: 'auto',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: '8px',
              color: 'text.secondary',
            }}
          >
            {t(`${translationNamespace}.propertyLabels.name`)}
            {/* placeholder element to prevent columns width from "jumping" */}
            {!isSortingByName && <ArrowUpward sx={{ opacity: '0' }} />}
            {isSortingByName && sortOrder === SORTING_ORDERS.asc && <ArrowUpward />}
            {isSortingByName && sortOrder === SORTING_ORDERS.desc && <ArrowDownward />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.cell} sx={{ width: '15%' }}>
          {t(`${translationNamespace}.with`)}
        </TableCell>
        <TableCell className={classes.sortableCell} sx={{ color: 'text.secondary' }}>
          <IconButton
            component="div"
            size="small"
            onClick={() => onSortClick('due_date')}
            className={classes.sortableCell}
            disableRipple
            sx={{
              display: 'flex',
              width: 'auto',
              height: 'auto',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: '8px',
              color: 'text.secondary',
            }}
          >
            {t(`${translationNamespace}.propertyLabels.due_date`)}
            {/* placeholder element to prevent columns width from "jumping" */}
            {!isSortingByDueDate && <ArrowUpward sx={{ opacity: '0' }} />}
            {isSortingByDueDate && sortOrder === SORTING_ORDERS.asc && <ArrowUpward />}
            {isSortingByDueDate && sortOrder === SORTING_ORDERS.desc && <ArrowDownward />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.sortableCell} sx={{ color: 'text.secondary' }}>
          <IconButton
            component="div"
            size="small"
            onClick={() => onSortClick('last_modified')}
            className={classes.sortableCell}
            disableRipple
            sx={{
              display: 'flex',
              width: 'auto',
              height: 'auto',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: '8px',
              color: 'text.secondary',
            }}
          >
            {t('general.lastUpdatedTitle')}
            {sortColumn === 'last_modified' && sortOrder === SORTING_ORDERS.asc && <ArrowUpward />}
            {sortColumn === 'last_modified' && sortOrder === SORTING_ORDERS.desc && (
              <ArrowDownward />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

InterlocksTableHeader.propTypes = {
  classes: PropTypes.exact({
    cell: PropTypes.string,
    sortableCell: PropTypes.string,
  }),
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf([SORTING_ORDERS.asc, SORTING_ORDERS.desc]),
  setSortBy: PropTypes.func,
  setSortOrder: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(InterlocksTableHeader);
