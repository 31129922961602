// eslint-disable-next-line import/prefer-default-export
export const styles = theme => ({
  content: {
    padding: 64,
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  paper: {
    overflowX: 'auto',
  },
  row: {
    backgroundColor: theme.palette.background.paper,
    '&:nth-of-type(odd)': {
      backgroundColor: `${theme.palette.background.box}50`,
    },
  },
  icon: {
    padding: '0% calc(50% - 10px)',
  },
  image: {
    margin: '0 auto',
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    margin: '0 auto',
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
});
