import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';

const styles = theme => ({
  xs: {
    /*
      Smallest variant, confirmation screens etc
      Will not go full screen, even on phones
    */
    width: '40%',
    minWidth: 600,
    maxWidth: 800,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
      minWidth: 'calc(100% - 16px)',
      maxWidth: 'calc(100% - 16px)',
    },
  },
  small: {
    /*
      Small dialog, simple creation screens and similar.
      Will go full screen on phones.
    */
    width: '40%',
    minWidth: 600,
    maxWidth: 800,
  },
  medium: {
    /*
      Medium dialog.
      Will go full screen on phones.
    */
    width: '60%',
    minWidth: 700,
    maxWidth: theme.breakpoints.values.md - 16,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 16px)',
      width: 'calc(100% - 16px)',
      minWidth: 'calc(100% - 16px)',
    },
  },
  large: {
    /*
      Large dialog.
      Will go full screen on phones.
    */
    width: 1400,
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'calc(100% - 16px)',
      width: 'calc(100% - 16px)',
      minWidth: 'calc(100% - 16px)',
    },
  },
  xl: {
    /*
      Full screen
      Will go full screen on phones.
    */
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    minWidth: 'calc(100% - 48px)',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'calc(100% - 16px)',
      width: 'calc(100% - 16px)',
      minWidth: 'calc(100% - 16px)',
    },
  },
  modalPaper: {
    borderRadius: 15,
    '&:not($xs)': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100%',
        minWidth: 'auto',
        height: '100%',
        maxHeight: '100%',
        borderRadius: 0,
        margin: 0,
      },
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflow: 'hidden',
  },
  fixedHeight: {
    // If not on mobile, apply fixed height

    [theme.breakpoints.up('sm')]: {
      '&$small': {
        height: '40%',
        maxHeight: '88%',
      },
      '&$medium': {
        height: '60%',
        maxHeight: '88%',
      },
      '&$large': {
        height: '90%',
        maxHeight: '92%',
      },
      '&$xl': {
        height: 'calc(100% - 16px)',
      },
    },
  },
});

class StandardDialog extends Component {
  onBackdropClick = () => {
    if (
      (typeof this.props.preventBackDropCloseFunc === 'undefined' ||
        !this.props.preventBackDropCloseFunc()) &&
      typeof this.props.onBackdropClick === 'function'
    ) {
      return this.props.onBackdropClick();
    }
    return false;
  };

  render() {
    const {
      classes,
      open,
      children,
      enablePortal = true,
      name,
      size,
      fixedHeight,
      id,
      sx,
      zIndexModifier,
    } = this.props;

    return (
      <Dialog
        id={id}
        open={open}
        onClose={this.onBackdropClick}
        classes={{
          paper: clsx(classes.modalPaper, classes[size], fixedHeight && classes.fixedHeight),
        }}
        disablePortal={!enablePortal}
        BackdropProps={{
          className: 'backdrop',
        }}
        PaperProps={{
          name,
        }}
        sx={{ ...sx, zIndex: 1250 + zIndexModifier }}
      >
        {children}
      </Dialog>
    );
  }
}

StandardDialog.propTypes = {
  classes: PropTypes.exact({
    modalPaper: PropTypes.string,
    fixedHeight: PropTypes.string,
    xs: PropTypes.string,
    small: PropTypes.string,
    medium: PropTypes.string,
    large: PropTypes.string,
    xl: PropTypes.string,
  }),
  size: PropTypes.string,
  id: PropTypes.string,
  open: PropTypes.bool,
  enablePortal: PropTypes.bool,
  name: PropTypes.string,
  onBackdropClick: PropTypes.func,
  preventBackDropCloseFunc: PropTypes.func,
  fixedHeight: PropTypes.bool,
  sx: PropTypes.object,
  zIndexModifier: PropTypes.number,
};

StandardDialog.defaultProps = {
  fixedHeight: false,
  zIndexModifier: 50,
};

export default withStyles(styles)(StandardDialog);
