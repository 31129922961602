import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AnimateOnChange from 'react-animate-on-change';

import NotificationIcon from '@mui/icons-material/Notifications';
import { withLocation, withNavigation } from 'withRouter';

import { openNotificationsPanel } from 'config/ModalProvider/helpers';
import LinkItem from './LinkItem';

const styles = theme => ({
  root: {
    ...theme.shape,
    color: theme.palette.text.primary,
    backgroundColor: 'rgba(0,0,0,0)',
    cursor: 'pointer',
    '&:hover': {
      ...theme.shape,
      backgroundColor: theme.palette.action.hover,
    },
    marginBottom: theme.spacing(-1),
  },
  icon: {
    // empty class needs to exist
  },
  effectContainer: {},
  notificationEffect: {
    '& $icon': {
      animation: '$wiggle .75s ease-out',
    },
  },
  '@keyframes wiggle': {
    '0%': { transform: 'rotate(20deg)' },
    '25%': { transform: 'rotate(-20deg)' },
    '50%': { transform: 'rotate(10deg)' },
    '75%': { transform: 'rotate(-5deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
  notificationsBadge: {
    ...theme.shape,
    ...theme.typography.caption,
    height: 16,
    minWidth: 16,
    lineHeight: 16,
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    backgroundColor: theme.palette.error.main,
    color: '#FFF',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
});

class NotificationsIconButton extends Component {
  state = {
    inboxDelta: 0,
    prevInboxLength: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.inbox && nextProps.inbox.inbox) {
      const newLength = nextProps.inbox.inbox.length;
      return {
        inboxDelta: newLength - prevState.prevInboxLength,
        prevInboxLength: newLength,
      };
    }
    return null;
  }

  openNotifications = () => {
    openNotificationsPanel(this.props.navigate, this.props.location);
  };

  render() {
    const { t, inbox, classes } = this.props;
    const { inboxDelta } = this.state;
    if (!inbox || (inbox.inbox && inbox.inbox.length === 0)) {
      return null;
    }
    return (
      <LinkItem
        linkIcon={<NotificationIcon fontSize="small" />}
        linkText={t('notifications.panel.panelTitle')}
        variant="large"
        id="header-notifications-toggle-mainnav"
        onClick={this.openNotifications}
        statusIcon={
          <AnimateOnChange
            baseClassName={classes.effectContainer}
            animationClassName={classes.notificationEffect}
            animate={inboxDelta > 0}
          >
            <div className={classes.notificationsBadge} name="home-screen-notifications-badge">
              {inbox.inbox.length}
            </div>
          </AnimateOnChange>
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  inbox: state.main.inbox,
});

NotificationsIconButton.propTypes = {
  inbox: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    icon: PropTypes.string,
    effectContainer: PropTypes.string,
    notificationEffect: PropTypes.string,
    notificationsBadge: PropTypes.string,
  }),
  location: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func,
};

export default withLocation(
  withNavigation(
    withTranslation()(connect(mapStateToProps)(withStyles(styles)(NotificationsIconButton))),
  ),
);
