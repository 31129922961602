import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NODE_TYPE_OBJECTIVE } from 'config/constants';
import ObjectivesIcon from 'Components/Features/Objectives/ObjectivesIcon';
import AiPopover from './AiPopover';
import { postSuggestRequest } from '../api';

function AiAssistCreateObjective({ onChange, period, domain, value }) {
  const [result, setResult] = useState({});

  if (domain.type !== 'team') {
    // Only supported for team OKRs currently
    return null;
  }

  if (!period.stPeriodId) {
    return null;
  }

  return (
    <AiPopover
      SuggestionIcon={ObjectivesIcon}
      dataGetter={context =>
        postSuggestRequest(NODE_TYPE_OBJECTIVE, {
          input_text: value,
          st_period: period.stPeriodId ? period.stPeriodId.split('-').slice(-1)[0] : '',
          lt_period: period.ltPeriodId,
          domain_id: `TEAM_${domain.id}`,
          ...context,
        })
      }
      onReceive={d => {
        setResult(d);
      }}
      result={result}
      onClose={() => setResult({})}
      onApprove={v => onChange({ target: { value: v } })}
    />
  );
}

AiAssistCreateObjective.propTypes = {
  period: PropTypes.object,
  domain: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AiAssistCreateObjective;
