import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import StandardInput from 'Components/Library/StandardInput';

import { CTX_ELEMENT_MAX_URL_STR_LENGTH } from 'config/constants';
import parseVideoFromUrl from './helpers';

export const validate = item => !!item.src;

class VideoEditor extends Component {
  state = {
    urlParseResult: null,
    error: false,
    currentSrc: '',
  };

  urlChange = e => {
    const urlParseResult = parseVideoFromUrl(e.target.value);
    const state = { urlParseResult, currentSrc: e.target.value };
    if (!!urlParseResult.validates) {
      state.error = null;
    }
    this.setState(state);
  };

  render() {
    const { menuState, onSave, onClose, t } = this.props;
    const { videoEditMenuOpen } = menuState;
    const { urlParseResult, error, currentSrc } = this.state;

    return (
      <Dialog fullWidth maxWidth="sm" open={Boolean(videoEditMenuOpen)}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            {t('contextv2.cards.embedvideotitle')}
          </Typography>
          <br />
          <StandardInput
            value={currentSrc}
            label={t('contextv2.cards.videourlinputlabel')}
            name="ctx-card-editor-video-url-input"
            onChange={this.urlChange}
            variant="filled"
            fullWidth
            FormHelperTextProps={{
              name: 'ctx-card-editor-video-url-input-helper',
            }}
            maxLength={CTX_ELEMENT_MAX_URL_STR_LENGTH}
            error={Boolean(error)}
            helperText={urlParseResult && urlParseResult.message ? urlParseResult.message : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} name="ctx-card-editor-video-url-input-cancel-btn">
            {t('general.cancel')}
          </Button>
          <Button
            name="ctx-card-editor-video-url-input-submit-btn"
            disabled={!urlParseResult || !urlParseResult.validates}
            variant="contained"
            onClick={() => onSave(urlParseResult)}
            color="secondary"
          >
            {t('general.save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

VideoEditor.propTypes = {
  menuState: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(VideoEditor);
