import React from 'react';
import LabelIcon from '@mui/icons-material/Label';
import DataObjectChip from 'Components/Library/DataObjectChip/index';
import IconButton from '@mui/material/IconButton';
import { t } from 'i18next';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { tagsActions } from 'state/ducks/tags/index';

interface ITagProps {
  tagText?: string;
  tagColor: string;
  tagID: string;
  instanceID: string;
  canEdit: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export function Tag({ tagText, canEdit, tagID, instanceID, tagColor }: ITagProps) {
  const dispatch = useDispatch();

  const unlinkTag = () => {
    dispatch(tagsActions.unlinkTag({ id: tagID, target_id: instanceID }));
  };
  return (
    <DataObjectChip
      text={tagText}
      icon={<LabelIcon fontSize="small" sx={{ marginTop: '5px', color: tagColor }} />}
      variant="normal"
      color="white"
      statusComponent={
        canEdit ? (
          <IconButton
            onClick={unlinkTag}
            name="delete-tag"
            aria-label={t('general.delete')}
            sx={{
              borderRadius: '10px',
              p: 0.5,
            }}
          >
            <HighlightOffIcon sx={{ color: theme => theme.palette.primary[500] }} />
          </IconButton>
        ) : null
      }
    />
  );
}

Tag.propTypes = {
  tagText: PropTypes.string,
};
