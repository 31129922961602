import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { pwaoptinActions, pwaoptinSelectors } from 'state/ducks/pwaoptin';
import { connectionSelectors } from 'state/ducks/connection';

const styles = theme => ({
  bannerRoot: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      position: 'relative',
      display: 'block',
    },
    backgroundColor: '#000000',
    // overflow: 'hidden',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    padding: theme.spacing(2),
    overflow: 'hidden',
  },
  bannerTextContainer: {
    flexDirection: 'column',
    color: '#ffffff',
    flexGrow: 2,
    overflow: 'hidden',
  },
  icon: {
    color: '#ffffff',
  },
  iconButton: {
    marginRight: theme.spacing(2),
  },
  textButtonContainer: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1.05,
  },
});

const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

const enoughTimeHasPassed = optInStatus => {
  if (!optInStatus.optOutDate) {
    return true;
  }
  if (Date.now() - optInStatus.optOutDate > 1000 * 3600 * 24 * 30) {
    // 30 days
    return true;
  }
  return false;
};

class AddToHomeScreenHandler extends Component {
  state = {
    dismissed: false,
  };

  install = () => {
    this.props.deferredEvent.prompt();
    this.setState({
      dismissed: true,
    });
  };

  dismiss = () => {
    this.setState({
      dismissed: true,
    });
    this.props.dispatch(pwaoptinActions.optOut());
  };

  render() {
    const { classes, deferredEvent, optInStatus, isOnline, t } = this.props;
    const { dismissed } = this.state;

    // On most platforms we rely on the existence of a deferred 'beforeinstallprompt' event
    // On iOS, we need to handle the flow ourselves
    let showIosBanner = false;
    let showAndroidBanner = false;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipad|ipod/.test(userAgent);
    let isSafari = /safari/.test(userAgent);
    const isChrome = /chrome/.test(userAgent);
    const isChromeOnIos = /crios/.test(userAgent);
    const isFFOnIos = /fxios/.test(userAgent);
    const isFF = /firefox/i.test(userAgent);
    const isAndroid = /android/i.test(userAgent);

    // user-agent of the Chrome browser also includes the Safari browser’s user-agent
    if (isChrome && isSafari) isSafari = false;

    // user-agent of chrome on ios contains CriOS
    if (isChromeOnIos && isSafari) isSafari = false;

    // user-agent of Firefox on ios contains FxiOS
    if (isFFOnIos && isSafari) isSafari = false;

    if (isIos && isSafari && !isInStandaloneMode() && enoughTimeHasPassed(optInStatus)) {
      showIosBanner = true;
    }

    // Chrome supports the beforeinstallprompt allowing a button that installs the pwa straight away, much better UX than guiding the user to go to menus and do things. Firefox does not
    if (isAndroid && isFF && !isInStandaloneMode() && enoughTimeHasPassed(optInStatus)) {
      showAndroidBanner = true;
    }

    if (!isOnline) {
      return null;
    }

    return (
      <>
        {!showAndroidBanner && !showIosBanner && !!deferredEvent && !dismissed && (
          <div className={classes.bannerRoot} data-tg-name="pwa-promotion-banner">
            <div className={classes.bannerContent}>
              <IconButton
                name="dismiss-pwa-install-button"
                className={classes.iconButton}
                onClick={this.dismiss}
                aria-label={t('pwa.dismissPwaInstallButton')}
                size="large"
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
              <div className={classes.bannerTextContainer}>
                <Typography color="primary.contrastText" variant="caption">
                  {t('pwa.installWebAppCta')}
                </Typography>
              </div>
              <div className={classes.textButtonContainer}>
                <Button
                  name="pwa-install-button"
                  variant="contained"
                  color="secondary"
                  onClick={this.install}
                >
                  {t('general.install')}
                </Button>
              </div>
            </div>
          </div>
        )}
        {showIosBanner && (
          <div className={classes.bannerRoot} data-tg-name="pwa-promotion-banner">
            <div className={classes.bannerContent}>
              <IconButton
                name="dismiss-pwa-install-button"
                className={classes.iconButton}
                onClick={this.dismiss}
                aria-label={t('pwa.dismissPwaInstallButton')}
                size="large"
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
              <div className={classes.bannerTextContainer}>
                <Typography color="primary.contrastText" variant="caption">
                  {t('pwa.installWebAppMobileCta1')}
                  &nbsp;
                  <SvgIcon viewBox="0 0 39 52" className={classes.icon} fontSize="small">
                    <line x1="1" y1="13" x2="1" y2="52" stroke="#ffffff" strokeWidth="2" />
                    <line x1="38" y1="13" x2="38" y2="52" stroke="#ffffff" strokeWidth="2" />
                    <line y1="51" x2="39" y2="51" stroke="#ffffff" strokeWidth="2" />
                    <line y1="13" x2="12" y2="13" stroke="#ffffff" strokeWidth="2" />
                    <line x1="27" y1="13" x2="39" y2="13" stroke="#ffffff" strokeWidth="2" />
                    <line x1="20" y1="1" x2="20" y2="30" stroke="#ffffff" strokeWidth="2" />
                    <line
                      x1="20.7071"
                      y1="0.707107"
                      x2="12.7071"
                      y2="8.70711"
                      stroke="#ffffff"
                      strokeWidth="2"
                    />
                    <line
                      x1="27.2929"
                      y1="8.70711"
                      x2="20.2929"
                      y2="1.70711"
                      stroke="#ffffff"
                      strokeWidth="2"
                    />
                  </SvgIcon>
                  &nbsp;
                  {t('pwa.installWebAppMobileCta2')}
                </Typography>
              </div>
            </div>
          </div>
        )}
        {showAndroidBanner && (
          <div className={classes.bannerRoot} data-tg-name="pwa-promotion-banner">
            <div className={classes.bannerContent}>
              <IconButton
                name="dismiss-pwa-install-button"
                className={classes.iconButton}
                onClick={this.dismiss}
                aria-label={t('pwa.dismissPwaInstallButton')}
                size="large"
              >
                <CloseIcon className={classes.icon} />
              </IconButton>
              <div className={classes.bannerTextContainer}>
                <Typography color="primary.contrastText" variant="caption">
                  {t('pwa.installWebAppMobileCta1')}
                  &nbsp;
                  <SvgIcon x="0px" y="0px" viewBox="0 0 192 192">
                    <line
                      x1="13"
                      y1="97.49"
                      x2="95.94"
                      y2="14.37"
                      stroke="#ffffff"
                      strokeWidth={15}
                      strokeLinecap="round"
                      strokeMiterlimit={10}
                    />
                    <line
                      x1="96.85"
                      y1="14.73"
                      x2="179.98"
                      y2="97.67"
                      stroke="#ffffff"
                      strokeWidth={15}
                      strokeLinecap="round"
                      strokeMiterlimit={10}
                    />
                    <line
                      x1="96.5"
                      y1="81"
                      x2="96.5"
                      y2="138.12"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth={11}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                    />
                    <line
                      x1="66.26"
                      y1="109"
                      x2="124.5"
                      y2="109"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth={11}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                    />
                    <path
                      d="M39.44,71.46v80.77c0,12.92,10.47,23.39,23.39,23.39h67.34c12.92,0,23.39-10.47,23.39-23.39V71.46"
                      fill="none"
                      stroke="#ffffff"
                      strokeWidth={15}
                      strokeMiterlimit={10}
                    />
                  </SvgIcon>
                  &nbsp;
                  {t('pwa.installWebAppMobileCta2')}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  optInStatus: pwaoptinSelectors.selectPwaOptInStatus(state.main.pwaoptin),
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
});

AddToHomeScreenHandler.propTypes = {
  classes: PropTypes.exact({
    bannerRoot: PropTypes.string,
    bannerContent: PropTypes.string,
    bannerTextContainer: PropTypes.string,
    icon: PropTypes.string,
    iconButton: PropTypes.string,
    textButtonContainer: PropTypes.string,
  }),
  deferredEvent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dispatch: PropTypes.func,
  optInStatus: PropTypes.object,
  isOnline: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(
  connect(mapStateToProps)(withStyles(styles)(AddToHomeScreenHandler)),
);
