import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { connectionActions } from 'state/ducks/connection';

class ConnectionHandler extends Component {
  componentDidMount() {
    // This component will be mounted by the App
    window.addEventListener('online', this.handleConnection);
    window.addEventListener('offline', this.handleConnection);

    // TODO: send a ping of some kind when mounting to determine whether the PWA
    // was launched in offline state, could we handle this in the axios wrapper config/axios.js and after like 5 failed backend calls notify we should dispatch a redux event to change the redux state to offline
  }

  handleConnection = () => {
    // eslint-disable-next-line no-console
    console.log('handleConnection: ', navigator.onLine);
    if (navigator.onLine) {
      this.props.dispatch(connectionActions.setStatus(true));
    } else {
      this.props.dispatch(connectionActions.setStatus(false));
    }
  };

  render() {
    return null;
  }
}

ConnectionHandler.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(ConnectionHandler);
