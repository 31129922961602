import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import { withNavigation } from 'withRouter';
import { peopleActions, peopleSelectors } from 'state/ducks/people';
import CommonNotification from './CommonNotification';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: 8,
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[300],
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
});

class TeamInviteRequestApprovedNotification extends React.Component {
  componentDidMount() {
    const { dispatch, sub, message } = this.props;
    // Let's make sure the user is added to the team so they can view private content now
    dispatch(peopleActions.editedUserMatrixTeam({ sub, teamID: message.teamID }));
  }

  handleClick = () => {
    const { navigate, message } = this.props;
    navigate(`/home/teams/${message.teamID}`);
  };

  render() {
    const { classes, t, message, key, teamName } = this.props;
    if (!!message) {
      return (
        <CommonNotification
          key={key}
          message={message}
          className={classes.root}
          onClick={this.handleClick}
        >
          <PersonasAvatar sub={message.approver} className={classes.avatar} />
          <div>
            <Typography variant="subtitle2">
              {t('notifications.teams.inviteRequestApproved', {
                from: this.props.getName(message.approver),
                teamName,
              })}
            </Typography>
            <Typography variant="caption">
              {t('general.ndaysago', {
                days: message.age,
              })}
            </Typography>
          </div>
        </CommonNotification>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  inbox: state.main.inbox && state.main.inbox.inbox,
  getName: sub => peopleSelectors.selectFullName(state.main.people, sub),
  teamName: peopleSelectors.selectTeamName(state.main.people, ownProps.message.teamID),
  sub: state.auth.userID,
});

TeamInviteRequestApprovedNotification.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    avatar: PropTypes.string,
  }),
  key: PropTypes.string,
  t: PropTypes.func,
  message: PropTypes.object,
  navigate: PropTypes.func,
  getName: PropTypes.func,
  teamName: PropTypes.string,
  sub: PropTypes.string,
  dispatch: PropTypes.func,
};

export default withNavigation(
  withTranslation()(
    withStyles(styles)(connect(mapStateToProps)(TeamInviteRequestApprovedNotification)),
  ),
);
