import Box from '@mui/material/Box';
import React from 'react';
import PropTypes from 'prop-types';
import { getStatusColor } from 'Components/Library/Contributors/helpers';

function EventChartBar({ chartColumn }) {
  return (
    <Box
      key={chartColumn.id}
      sx={{
        width: `${chartColumn.durationPercentage}%`,
      }}
    >
      <Box sx={{ height: '10px' }}>
        <Box
          sx={{
            backgroundColor: theme => getStatusColor(theme, chartColumn.status),
            height: '10px',
            width: '100%',
          }}
        />
      </Box>
    </Box>
  );
}

EventChartBar.propTypes = {
  chartColumn: PropTypes.object,
};

export default EventChartBar;
