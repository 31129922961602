export enum NodeType {
  Commitment = 'COM',
  Objective = 'OBJ',
  KeyResult = 'KR',
  Interlock = 'INTERLOCK',
  User = 'USER',
  Team = 'TEAM',
}

export interface NodeData {
  id: string;
  name: string;
  owner?: string;
  [x: string]: unknown;
}

export enum Column {
  TypeIcon = 'TYPE_ICON',
  Status = 'STATUS',
  StatusSmall = 'STATUS_SMALL',
  Name = 'NAME',
  Owner = 'OWNER',
  Domains = 'DOMAINS',
  DueDate = 'DUEDATE',
  LastUpdated = 'LASTUPDATED',
}

export interface NodeTableRowProps {
  /**
   * Graph ID of the node to be represented
   */
  graphId: string;
  /**
   * Array of Columns to render
   * @default '[TypeIcon, StatusSmall, Name, Owner]'
   */
  columns?: Array<Column>;
  /**
   * Size
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * allowEdits
   * @default false
   */
  allowEdits?: boolean;
}

export interface NodeTableRowInstanceProps extends NodeTableRowProps {
  nodeData: NodeData;
}
