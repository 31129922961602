import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { peopleActions, peopleSelectors } from 'state/ducks/people';
import KeyresultEventList from './KeyresultEventList';

const fetchUsers = props => {
  // Fetch all the users referenced in the events here to allow batching
  const { selectUser, dispatch, keyresult } = props;
  const { events } = keyresult;
  if (!!events) {
    const shouldFetch = [];
    for (const evt of events) {
      for (const attr of ['krowner', 'owner']) {
        const sub = evt[attr];
        if (!!sub) {
          const userData = selectUser(sub);
          if (!userData.ok && !shouldFetch.includes(sub)) {
            shouldFetch.push(sub);
          }
        }
      }
    }
    if (shouldFetch.length > 0) {
      dispatch(peopleActions.getPersons({ subs: shouldFetch.slice(0, 25) }));
    }
  }
};

class KeyresultEventListContainer extends React.Component {
  componentDidMount() {
    fetchUsers(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    fetchUsers(newProps);
  }

  render() {
    return <KeyresultEventList canEdit={this.props.canEdit} keyresult={this.props.keyresult} />;
  }
}

const mapStateToProps = state => ({
  selectUser: sub => peopleSelectors.selectUser(state.main.people, sub),
});

KeyresultEventListContainer.propTypes = {
  canEdit: PropTypes.bool,
  keyresult: PropTypes.object,
  // these are used in fetchUsers, eslint doing the stupid:
  // eslint-disable-next-line react/no-unused-prop-types
  selectUser: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(KeyresultEventListContainer);
