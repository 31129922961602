import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { API_BASE_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

const SLICE_NAME = 'context';

export const getContextLogic = createLogic({
  type: types.GET_CONTEXT,
  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectContext(
          state.main[SLICE_NAME],
          action.payload.contextID,
          action.payload.contextType,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const { contextID, contextType } = action.payload;
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/getcontextcardsv2`, {
        params: { contextID, contextType },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.contextReceived(res.data.result));
      })
      .catch(e => {
        dispatch(actions.contextFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const editContextCardLogic = createLogic({
  type: types.EDIT_CONTEXT_CARD,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/editcontextcard`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.contextCardEdited(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteContextCardLogic = createLogic({
  type: types.DELETE_CONTEXT_CARD,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    const { contextID, contextType } = action.payload;
    axios
      .post(
        `${API_BASE_URL}/${getState().auth.tenantID}/deletecontextcard`,
        { contextID, contextType, id: action.payload.id, requestID: action.payload.requestID },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        dispatch(actions.contextCardDeleted(res.data.result));
      })
      .catch(e => {
        if (e.response) {
          let handled = false;
          if (e.response.status === 409) {
            // Retryable conflict, someone else was editing the context at the same time
            // retry up to 5 times
            const attemptNumber =
              (action.payload.attemptNumber ? action.payload.attemptNumber : 0) + 1;
            if (attemptNumber < 5) {
              dispatch(actions.deleteContextCard({ ...action.payload, attemptNumber }));
              handled = true;
            }
          }
          if (!handled) {
            const errorPayload = { ...action.payload };
            if (e.response.data && e.response.data.error) {
              errorPayload.errormsg = e.response.data.error;
            }
            dispatch(actions.errorTryAgainLater(errorPayload));
          }
        }
      })
      .then(() => done());
  },
});

export const createContextCardLogic = createLogic({
  type: types.CREATE_CONTEXT_CARD,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/createcontextcard`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.contextCardCreated(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const moveContextCardLogic = createLogic({
  type: types.MOVE_CONTEXT_CARD,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/movecontextcard`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.contextCardMoved(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});
