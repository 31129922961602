import React from 'react';
import { Input as MuiBaseInput, InputInputSlotPropsOverrides } from '@mui/base/Input';

import { FormControl } from '@mui/base';
import { styled } from '@mui/material/styles';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Typography } from '@mui/material';
import useId from '@mui/utils/useId';
import { borderRadius, spacingUnit } from 'config/theme';
import InputRoot from './InputRoot';

import { InputProps } from './Input.types';

const StyledFormControl = styled(FormControl)(
  ({ theme }) => `
    //& *, *::placeholder {
    //  transition: border-color 0.125s ease-in, color 0.125s ease-in;
    //}
  
    @keyframes on-error-shake {
      0%  { transform: translate(0.5px, 0.5px) rotate(0deg);   }
      25%  { transform: translate(-0.5px, -0.5px) rotate(0.2deg);    }
      50%  { transform: translate(0px, 0px) rotate(0deg) scale(1.01);    }
      75%  { transform: translate(0.7px, -0.5px) rotate(0.1deg);    }
      100%  { transform: translate(0px, 0px) rotate(0deg);    }
    }
  
    @keyframes on-error-flash {
      0%  { box-shadow: inset 0px 0px 7px -5px ${theme.palette.error.main};  }
      100%  { box-shadow: unset;  }
    }
  
    &.Mui-error {
      animation: on-error-shake 0.1s ease-out;
    }
  
    &.Mui-error :is(input,textarea) {
      animation: on-error-flash 0.4s;
    }

    & .MuiInput-formControl {
      block-size: auto;
      display: flex;
    }
    `,
);

export const inputStyleTemplate = args => {
  const { theme, noBorder = false } = args;

  return `
    // TODO: make these the default styles for everything
    // that is built from our design system's "chip"
    
    font-size: 0.75rem; // 12px
    font-weight: 400;
    letter-spacing: 0.009375rem; // .15px
    line-height: 1.333334; // 16px
    // box-sizing does not work for the height as the height
    // is computed using the line-height - use 7px/3px for the
    // top and bottom paddings to account for the border:
    
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.action.border};
    box-sizing: border-box;
    resize: none;

    //placeholder
    &::placeholder {
      opacity: 1; /* Firefox */
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }

    // color variants
    &.chip-bg-white {
      background-color: ${theme.palette.background.paper};
    }
    &.chip-bg-grey {
      background-color: ${theme.palette.background.box};
    }
    &.chip-bg-transparent {
      background-color: transparent;
    }
    
    // size variants
    &.chip-size-small {
      padding: ${1 + (noBorder ? 1 : 0)}px ${spacingUnit * 0.75}px;
      min-height: ${spacingUnit * 2.5}px; 
      border-radius: ${borderRadius.sm};
    }
    &.chip-size-medium {
      padding: ${3 + (noBorder ? 1 : 0)}px ${spacingUnit * 0.75}px;
      min-height: ${spacingUnit * 3}px; 
      border-radius: ${borderRadius.md};
    }
    &.chip-size-large {
      padding: ${7 + (noBorder ? 1 : 0)}px ${spacingUnit * 1}px;
      min-height: ${spacingUnit * 4}px; 
      border-radius: ${borderRadius.lg};
    }

    // raised or flat?
    &.chip-raised {
      box-shadow: ${theme.shadows[2]};
    }

`;
};

const StyledInputElement = styled('input')(args => inputStyleTemplate(args));

const StyledTextAreaElement = styled(TextareaAutosize)(args => inputStyleTemplate(args));

const Input = React.forwardRef(function CustomInput(
  props: InputProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    fullWidth,
    label,
    placeholder,
    helperText,
    id: idOverride,
    readOnly,
    size = 'medium',
    bgColor = 'transparent',
    autoFocus,
    multiline,
    rows,
    sx = {},
    onKeyUp,
    onKeyDown,
    ...rest
  } = props;
  const id = useId(idOverride);
  const labelId = `${id}-label`;

  const labelColor: string = props.error
    ? 'error.main'
    : props.disabled
    ? 'action.disabled'
    : 'text.secondary';

  const inputSlotProps: InputInputSlotPropsOverrides = {
    className: `chip-bg-${bgColor} chip-size-${size}`,
    ref,
  };

  // TODO: figure out how to pass a typed object
  // for the props - this is a bit difficult due to
  // how the mui base input interface has been defined
  const subTypeProps: any = {};

  if (multiline) {
    subTypeProps.multiline = true;
    subTypeProps.rows = rows;
  }

  const inputProps = {
    id,
    placeholder,
    autoFocus,
    readOnly,
    slots: { input: StyledInputElement, root: InputRoot, textarea: StyledTextAreaElement },
    onKeyUp,
    onKeyDown,
    slotProps: {
      input: inputSlotProps,
    },
    ...subTypeProps,
  };

  return (
    <StyledFormControl
      {...rest}
      sx={fullWidth ? { ...sx, '& :is(input,textarea)': { width: '100%' } } : sx}
    >
      {label && (
        <Typography
          id={labelId}
          variant="caption"
          sx={{ lineHeight: '16px', display: 'block', fontWeight: 400, mb: 0.5 }}
          component="label"
          htmlFor={id}
          color={labelColor}
        >
          {props.required ? `${label} *` : label}
        </Typography>
      )}
      <MuiBaseInput {...inputProps} />
      {helperText && (
        <Typography
          variant="caption"
          sx={{ lineHeight: '16px', display: 'block', mt: 0.5 }}
          color={labelColor}
        >
          {helperText}
        </Typography>
      )}
    </StyledFormControl>
  );
});

export default Input;
