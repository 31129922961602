import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';

class CadencePeriodLabel extends Component {
  render() {
    const { label } = this.props;
    return !!label ? label : null;
  }
}

const mapStateToProps = (state, ownProps) => {
  let label;
  if (!!ownProps.objectiveData) {
    if (ownProps.objectiveData.stperiod) {
      label = objectivesSelectors.selectStPeriodName(
        state.main.objectives,
        null,
        ownProps.objectiveData.stperiod,
      );
    } else {
      label = objectivesSelectors.selectLtPeriodName(
        state.main.objectives,
        null,
        ownProps.objectiveData.ltperiod,
      );
    }
  }

  return {
    label,
  };
};

CadencePeriodLabel.propTypes = {
  // Used in mapStateToProps:
  // eslint-disable-next-line react/no-unused-prop-types
  objectiveData: PropTypes.object,
  label: PropTypes.string,
};

export default connect(mapStateToProps)(CadencePeriodLabel);
