import * as types from './types';

// Context v2
export const getContext = payload => ({
  type: types.GET_CONTEXT,
  payload,
});

export const contextReceived = payload => ({
  type: types.RECEIVED_CONTEXT,
  payload,
});

export const contextFailed = payload => ({
  type: types.FAILED_CONTEXT,
  payload,
});

export const editContextCard = payload => ({
  type: types.EDIT_CONTEXT_CARD,
  payload,
});

export const contextCardEdited = payload => ({
  type: types.EDITED_CONTEXT_CARD,
  payload,
});

export const createContextCard = payload => ({
  type: types.CREATE_CONTEXT_CARD,
  payload,
});

export const contextCardCreated = payload => ({
  type: types.CREATED_CONTEXT_CARD,
  payload,
});

export const moveContextCard = payload => ({
  type: types.MOVE_CONTEXT_CARD,
  payload,
});

export const contextCardMoved = payload => ({
  type: types.MOVED_CONTEXT_CARD,
  payload,
});

export const deleteContextCard = payload => ({
  type: types.DELETE_CONTEXT_CARD,
  payload,
});

export const contextCardDeleted = payload => ({
  type: types.DELETED_CONTEXT_CARD,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
