import * as types from './types';

export const fetchNodeComments = payload => ({
  type: types.FETCH_COMMENTS,
  payload,
});

export const fetchedNodeComments = payload => ({
  type: types.RECEIVED_COMMENTS,
  payload,
});

export const failedNodeComments = payload => ({
  type: types.FAILED_COMMENTS,
  payload,
});

export const addComment = payload => ({
  type: types.ADD_COMMENT,
  payload,
});

export const addedComment = payload => ({
  type: types.ADDED_COMMENT,
  payload,
});

export const editComment = payload => ({
  type: types.EDIT_COMMENT,
  payload,
});

export const editedComment = payload => ({
  type: types.EDITED_COMMENT,
  payload,
});

export const deleteComment = payload => ({
  type: types.DELETE_COMMENT,
  payload,
});

export const deletedComment = payload => ({
  type: types.DELETED_COMMENT,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
