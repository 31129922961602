import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  EmptyTablePlaceholderContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    width: '100%',
  },
});

function EmptyTablePlaceholder(props) {
  const { t } = useTranslation();
  const { classes, translationgroup } = props;
  return (
    <div
      className={clsx([classes.EmptyTablePlaceholderContainer, 'emptystate-root'])}
      name="empty-table-container"
    >
      <Typography align="center" variant="body2" color="text.disabled">
        {t(`${translationgroup}.emptytableplaceholder`)}
      </Typography>
    </div>
  );
}

EmptyTablePlaceholder.propTypes = {
  classes: PropTypes.exact({
    EmptyTablePlaceholderContainer: PropTypes.string,
  }),
  translationgroup: PropTypes.string,
};

export default withStyles(styles)(EmptyTablePlaceholder);
