import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MoreVert';
import PeopleIcon from '@mui/icons-material/People';
import BackIcon from '@mui/icons-material/ArrowBack';

const styles = theme => ({
  modalPaper: {
    borderRadius: 15,
    width: 1400,
    maxWidth: '80%',
    height: '80%',
    maxHeight: 1200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: 0,
      margin: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflow: 'clip',
  },
  modalActions: {
    height: 44,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 20,
    paddingRight: 12,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalContentRoot: {
    flexGrow: 2,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'row',
  },
  modalContent: {
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(4),
    // Handle scrollbar gutters:
    paddingLeft: '22px',
    paddingRight: '22px',
    flexGrow: 2,
  },
  sideBar: {
    minWidth: 300,
    flexBasis: 300,
    flexGrow: 0,
    flexShrink: 0,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: theme.zIndex.modal,
      transform: 'translateX(100%)',
      transition: 'all 0.125s ease',
    },
  },
  sideBarActions: {
    display: 'none',
    alignSelf: 'stretch',
  },
  sideBarContent: {
    padding: theme.spacing(4),
    // Handle scrollbar gutters:
    paddingLeft: '22px',
    paddingRight: '22px',
    overflowY: 'auto',
  },
  mobileSideBarOpen: {
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(0%)',
      display: 'flex',
    },
  },
  mobileSideBarToggle: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline-flex',
    },
  },
});

class StandardSurveyDialog extends Component {
  state = {
    menuOpen: false,
    anchorEl: null,
    mobileSideBarOpen: false,
  };

  onBackdropClick = () => {
    if (
      (typeof this.props.preventBackDropCloseFunc === 'undefined' ||
        !this.props.preventBackDropCloseFunc()) &&
      typeof this.props.onBackdropClick() === 'function'
    ) {
      return this.props.onBackdropClick();
    }
    return false;
  };

  toggleMenu = anchorEl => {
    this.setState({ menuOpen: !this.state.menuOpen, anchorEl });
  };

  toggleSideBar = () => {
    this.setState({ mobileSideBarOpen: !this.state.mobileSideBarOpen });
  };

  render() {
    const {
      classes,
      title,
      open,
      children,
      enablePortal = true,
      menuItems = null,
      sidebarContent = null,
      actionbarContent = null,
      onCloseClick,
      name,
      closeButtonProps,
      menuButtonProps,
      t,
    } = this.props;

    const { menuOpen, anchorEl, mobileSideBarOpen } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onBackdropClick}
        classes={{
          paper: classes.modalPaper,
        }}
        disablePortal={!enablePortal}
        BackdropProps={{
          className: 'backdrop',
        }}
        PaperProps={{
          name,
        }}
      >
        <div className={classes.modalActions}>
          <div className={classes.title}>{title}</div>
          <div className={classes.actionButtons}>
            {!!menuItems && (
              <>
                <IconButton
                  onClick={evt => this.toggleMenu(evt.currentTarget)}
                  size="medium"
                  {...menuButtonProps}
                  aria-controls={open ? `${name}-header-menu` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  open={menuOpen}
                  id={`${name}-header-menu`}
                  onClose={() => this.toggleMenu()}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {menuItems.map(el =>
                    React.cloneElement(el, {
                      onClick: e => {
                        this.toggleMenu(null);
                        el.props.onClick(e);
                      },
                    }),
                  )}
                </Menu>
              </>
            )}
            <IconButton
              id={`${name}-people-icon`}
              className={classes.mobileSideBarToggle}
              onClick={this.toggleSideBar}
              aria-label={t('general.toggleParticipants')}
            >
              <PeopleIcon />
            </IconButton>
            <IconButton
              onClick={onCloseClick}
              size="medium"
              aria-label={t('general.close')}
              {...closeButtonProps}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.modalContentRoot}>
          <div className={clsx(classes.modalContent, 'scrollable')}>{children}</div>
          <div className={clsx(classes.sideBar, mobileSideBarOpen && classes.mobileSideBarOpen)}>
            <div
              className={clsx(
                classes.modalActions,
                classes.sideBarActions,
                mobileSideBarOpen && classes.mobileSideBarOpen,
              )}
            >
              <IconButton
                className={classes.mobileSideBarToggle}
                onClick={this.toggleSideBar}
                aria-label={t('general.back')}
              >
                <BackIcon />
              </IconButton>
            </div>
            <div className={clsx(classes.sideBarContent, 'scrollable')}>{sidebarContent}</div>
          </div>
        </div>
        {actionbarContent}
      </Dialog>
    );
  }
}

StandardSurveyDialog.propTypes = {
  classes: PropTypes.exact({
    modalPaper: PropTypes.string,
    modalActions: PropTypes.string,
    modalContentRoot: PropTypes.string,
    modalContent: PropTypes.string,
    sideBar: PropTypes.string,
    sideBarActions: PropTypes.string,
    sideBarContent: PropTypes.string,
    mobileSideBarOpen: PropTypes.string,
    mobileSideBarToggle: PropTypes.string,
    actionButtons: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.node,
  open: PropTypes.bool,
  menuItems: PropTypes.node,
  sidebarContent: PropTypes.node,
  actionbarContent: PropTypes.node,
  enablePortal: PropTypes.bool,
  name: PropTypes.string,
  onBackdropClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  preventBackDropCloseFunc: PropTypes.func,
  closeButtonProps: PropTypes.object,
  menuButtonProps: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(StandardSurveyDialog));
