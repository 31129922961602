import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StandardDialog from 'Components/Library/StandardDialog';
import Form from 'Components/Library/Forms/';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';

import { objectivesActions } from 'state/ducks/objectives';

const schema = {
  type: 'object',
  additionalProperties: true,
};

class DialogConfirmDelete extends Component {
  render() {
    const { open, onCancel, keyresultData, t, onConfirm } = this.props;

    if (!keyresultData) {
      return null;
    }

    return (
      <StandardDialog open={open} onBackdropClick={onCancel} size="xs">
        <Form
          name="confirm-delete-kr-form"
          schema={schema}
          initialValues={{}}
          stateSlice="main.objectives"
          submitActionCreator={objectivesActions.deleteKeyresult}
          additionalProperties={{ keyresultID: keyresultData.keyresultID }}
          onSubmitSuccess={onConfirm}
        >
          <DialogTitle id="simple-dialog-title">{t('objectives.deletekrdialogTitle')}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <Trans
                i18nKey="objectives.confirmkrdelete"
                values={{ keyresult: keyresultData.keyresult }}
              />
            </Typography>
            <LinearIndicator positioning="absolute" />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} id="delete-kr-form-cancel-button">
              {t('general.cancel')}
            </Button>
            <FormButton
              render={buttonProps => (
                <Button
                  id="delete-kr-form-confirm-button"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('general.delete')}
                </Button>
              )}
            />
          </DialogActions>
        </Form>
      </StandardDialog>
    );
  }
}

DialogConfirmDelete.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  keyresultData: PropTypes.object,
  open: PropTypes.bool,
  dispatch: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(DialogConfirmDelete);
