import { createLogic } from 'redux-logic';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { instance } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import * as actions from './actions';
import * as types from './types';
import * as constants from '../../constants/api';

import * as programsActions from '../programs/actions';

const processMemberships = (decodedIdToken, dispatch) => {
  if (decodedIdToken) {
    dispatch(
      actions.myTeamsReceived({
        ownTeamId: !!decodedIdToken.lineTeamId ? decodedIdToken.lineTeamId : null,
        managedTeams:
          !!decodedIdToken.managedTeamIds &&
          JSON.parse(decodedIdToken.managedTeamIds).map(teamId => ({
            id: teamId,
          })),
        matrixTeams:
          !!decodedIdToken.matrixTeamIds &&
          JSON.parse(decodedIdToken.matrixTeamIds).map(teamId => ({
            id: teamId,
          })),
      }),
    );
    if (decodedIdToken.programs) {
      dispatch(programsActions.programsReceived(JSON.parse(decodedIdToken.programs)));
    }
  }
};

const exchangeCodeForTokens = createLogic({
  type: types.GET_TOKENS_WITH_CODE,
  latest: true,

  process: async ({ action }, dispatch, done) => {
    // send request to exchange code for tokens
    const details = {
      grant_type: 'authorization_code',
      code: action.payload.code,
      redirect_uri: constants.REDIRECT_URL,
      client_id: action.payload.clientId,
    };
    axios({
      method: 'post',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      url: `https://${action.payload.cognitoSubDomain}${constants.COGNITO_ENDPOINT_URL}/oauth2/token`,
      data: Object.keys(details)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
        .join('&'),
    })
      .then(res => {
        // Process contents of identity token
        // Dind out tenantID
        const decoded = jwtDecode(res.data.id_token);
        const tenantID = decoded['custom:tenantId'];

        // Is the user a line manager?
        const isLineManager =
          'custom:isManager' in decoded && parseInt(decoded['custom:isManager'], 10) > 0;

        // Is the user a change manager?
        const isChangeManager =
          'custom:isChangeManager' in decoded &&
          parseInt(decoded['custom:isChangeManager'], 10) > 0;

        // Is the user a tenant admin?
        const isAdmin = 'custom:isAdmin' in decoded && parseInt(decoded['custom:isAdmin'], 10) > 0;

        // Is the user a provisioned user?
        const isProvisioned = decoded.provisioned === 'true';

        // Construct identity & dispatch
        const tokens = res.data;
        const t = new Date();
        tokens.expires_in = t.getTime() + 3600 * 1000;
        const identity = {
          tokens,
          username: decoded['cognito:username'],
          email: decoded.email,
          firstName: decoded.given_name,
          lastName: decoded.family_name,
          userID: decoded.sub,
          analyticsId: decoded.analyticsId,
          teamID: decoded['custom:teamId'],
          isCEO: decoded['custom:teamId'] === tenantID,
          isProvisioned,
          tenantID,
          avatar: decoded.picture,
          isChangeManager,
          isLineManager,
          isAdmin,
          cognitoSubDomain: action.payload.cognitoSubDomain,
          clientId: action.payload.clientId,
          loginType: action.payload.loginType,
          idpName: action.payload.idpName,
          ssoRef: decoded.sso_ref,
        };
        processMemberships(decoded, dispatch);
        dispatch(actions.tokenFetchSuccess(identity));
      })
      .catch(e => {
        dispatch(actions.tokenFetchFailed({ error: e, requestID: action.payload.requestID }));
      })
      .then(() => done());
  },
});

const refreshTokensTimerLogic = createLogic({
  type: types.START_REFRESH_TIMER,
  warnTimeout: 0,
  // this process never ends until cancelled otherwise we would call done
  process({ action }, dispatch) {
    setInterval(
      () => {
        dispatch(actions.refreshTokens(action.payload));
        // add randomness to the timer to ensure that
        // multiple tabs do not refresh tokens at the same time
        // unneccessarily
      },
      1000 * 300 + Math.random(100),
    );
  },
});

const refreshTokensLogic = createLogic({
  type: types.REFRESH_TOKENS,
  latest: true,
  debounce: 1500,
  process: async ({ getState, action }, dispatch, done) => {
    const now = new Date();
    if (
      // eslint-disable-next-line no-constant-condition
      !getState().auth.tokens.expires_in ||
      now.getTime() + 600 * 1000 >= parseInt(getState().auth.tokens.expires_in, 10) ||
      !!action.payload.force
    ) {
      // send request to refresh tokens
      // eslint-disable-next-line no-console
      console.log('Posting token refresh');
      const details = {
        grant_type: 'refresh_token',
        refresh_token: getState().auth.tokens.refresh_token,
        client_id: getState().auth.clientId,
      };
      axios({
        method: 'post',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        url: `https://${getState().auth.cognitoSubDomain}${
          constants.COGNITO_ENDPOINT_URL
        }/oauth2/token`,
        data: Object.keys(details)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`)
          .join('&'),
      })
        .then(res => {
          const tokens = res.data;
          const t = new Date();
          tokens.expires_in = t.getTime() + 3600 * 1000;
          const decoded = jwtDecode(tokens.id_token);

          dispatch(actions.tokenRefreshSuccess({ tokens, analyticsId: decoded.analyticsId }));
          processMemberships(decoded, dispatch);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Token refresh failed, dispatching TOKEN_REFRESH_FAILURE');
          dispatch(actions.tokenRefreshFailure());
        })
        .then(() => done());
    } else {
      // eslint-disable-next-line no-console
      console.log('Cancelling token refresh');
      done();
    }
  },
});

const revokeRefreshTokenLogic = createLogic({
  type: types.REVOKE_TOKENS,
  process: async ({ getState, action }, dispatch, done) => {
    const logoutAction = { type: 'LOGOUT', payload: { isSwitching: action?.payload?.isSwitching } };
    if (getState().auth.tokens.refresh_token) {
      axios
        .post(
          `https://${getState().auth.cognitoSubDomain}${
            constants.COGNITO_ENDPOINT_URL
          }/oauth2/revoke`,
          { token: getState().auth.tokens.refresh_token, client_id: getState().auth.clientId },
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
            },
          },
        )
        .then(() => {
          dispatch(logoutAction);
          done();
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log('Token revoke failed, continuing logout.');
          dispatch(logoutAction);
          done();
        });
    } else {
      dispatch(logoutAction);
      done();
    }
  },
});

const getMyTeamsLogic = createLogic({
  type: types.GET_MY_TEAMS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (shouldFetch(state.auth)) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState }, dispatch, done) => {
    instance
      .get(`${constants.API_BASE_URL}/${getState().auth.tenantID}/getmyteams`, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.myTeamsReceived(res.data.result));
      })
      .catch(() => {})
      .then(() => done());
  },
});

export {
  refreshTokensLogic,
  refreshTokensTimerLogic,
  exchangeCodeForTokens,
  getMyTeamsLogic,
  revokeRefreshTokenLogic,
};
