import { last, get } from 'lodash';
import { enrich } from 'state/helpers';

export function selectMatrices(slice) {
  if (!!slice.matrices) {
    return slice.matrices;
  }
  return enrich();
}

export function selectUsersLineTeam(slice, sub) {
  if (!!slice.subs[sub]) {
    return slice.subs[sub].teamId;
  }
  return null;
}

export function selectUsersMatrixTeams(slice, sub) {
  if (!!slice.subs[sub]) {
    return slice.subs[sub].matrixTeams || [];
  }
  return [];
}

export function selectTeamsInMatrix(slice, matrixId) {
  const teamsArr = [];
  if (slice.teamMatrices) {
    Object.keys(slice.teamMatrices).forEach(teamId => {
      if (slice.teamMatrices[teamId] === matrixId) {
        teamsArr.push(teamId);
      }
    });
  }
  const teamsObject = {};
  teamsArr.forEach(teamId => {
    teamsObject[teamId] = slice.teamNames[teamId];
  });
  return teamsObject;
}

export function selectTeamsInAnyMatrix(slice) {
  const teamsArr = [];
  if (slice.teamMatrices) {
    Object.keys(slice.teamMatrices).forEach(teamId => {
      if (slice.teamMatrices[teamId]) {
        teamsArr.push(teamId);
      }
    });
  }
  const teamsObject = {};
  teamsArr.forEach(teamId => {
    teamsObject[teamId] = slice.teamNames[teamId];
  });
  return teamsObject;
}

export function selectTeamsNotInAnyMatrix(slice) {
  const teamsArr = [];
  if (slice.teamMatrices) {
    Object.keys(slice.teamMatrices).forEach(teamId => {
      if (!slice.teamMatrices[teamId]) {
        teamsArr.push(teamId);
      }
    });
  }
  const teamsObject = {};
  teamsArr.forEach(teamId => {
    teamsObject[teamId] = slice.teamNames[teamId];
  });
  return teamsObject;
}

export function selectMatrixName(slice, matrixId) {
  if (!!slice.matrices && !!slice.matrices.ok) {
    for (const matrix of slice.matrices.data) {
      if (matrix.id === matrixId) {
        return matrix.name;
      }
    }
  }
  return '';
}

export function selectMatrixType(slice, matrixId) {
  if (!!slice.matrices && !!slice.matrices.ok) {
    for (const matrix of slice.matrices.data) {
      if (matrix.id === matrixId) {
        return matrix.type;
      }
    }
  }
  return '';
}

export function selectMatrixOfTeam(slice, teamId) {
  return slice.teamMatrices[teamId];
}

export function selectList(slice) {
  if (!!slice.list) {
    return slice.list;
  }
  return enrich();
}

export function selectRootTeam(slice) {
  return !!slice.hierarchy && slice.hierarchy.teamId;
}

export function selectRootUser(slice) {
  return slice.hierarchy.managerId;
}

export function selectHierarchy(slice, matrixId = null) {
  if (!matrixId && !!slice.hierarchy.fetchStatus) {
    return slice.hierarchy;
  }
  if (matrixId && !!slice.matrixhierarchies[matrixId] && !!slice.matrixhierarchies[matrixId].ok) {
    return slice.matrixhierarchies[matrixId].hierarchy;
  }
  return enrich();
}

export function selectMatrixHierarchy(slice, matrixId) {
  if (!!slice.matrixhierarchies[matrixId]) {
    return slice.matrixhierarchies[matrixId];
  }
  return enrich();
}

export function selectHierarchyFromTeam(slice, teamId) {
  if (!!teamId && !!slice.hierarchypointers) {
    return slice.hierarchypointers[teamId];
  }
  return null;
}

export function selectChildHierarchyFromTeam(slice, teamId) {
  if (!!teamId && !!slice.hierarchypointers) {
    if (!!slice.hierarchypointers[teamId]) {
      return slice.hierarchypointers[teamId].children;
    }
  }
  return null;
}

export function getTeamsAboveTeam(slice, teamId) {
  let parentTeams = slice.teampaths[teamId] ? slice.teampaths[teamId].split('/') : [];
  if (parentTeams && parentTeams.length > 0) {
    parentTeams.pop();
    parentTeams = parentTeams.filter(team => !!team);
  }
  return parentTeams;
}

export function selectSiblingHierarchyFromTeam(slice, teamId) {
  if (!!teamId && !!slice.hierarchypointers) {
    const parentId = last(getTeamsAboveTeam(slice, teamId));
    if (!!parentId && !!slice.hierarchypointers[parentId]) {
      const siblingTeams = slice.hierarchypointers[parentId].children.filter(
        team => team.id !== teamId,
      );
      return siblingTeams;
    }
  }

  // for the top teams in a matrix org
  const matrixId = selectMatrixOfTeam(slice, teamId);
  if (!!matrixId && !!slice.matrixhierarchies[matrixId] && !!slice.matrixhierarchies[matrixId].ok) {
    const isTeamInTopLevel =
      slice.matrixhierarchies[matrixId].hierarchy.children.filter(team => team.id === teamId)
        .length === 1;
    if (isTeamInTopLevel) {
      return slice.matrixhierarchies[matrixId].hierarchy.children.filter(
        team => team.id !== teamId,
      );
    }
  }

  return null;
}

export function selectParentHierarchyFromTeam(slice, teamId) {
  if (!!teamId && !!slice.hierarchypointers) {
    const parentId = last(getTeamsAboveTeam(slice, teamId));
    if (!!parentId && !!slice.hierarchypointers[parentId]) {
      return slice.hierarchypointers[parentId];
    }
  }
  return null;
}

export function getTeamsAboveUser(slice, userId) {
  const teamId = slice.subs[userId] && slice.subs[userId].teamId;
  const parentTeams = slice.teampaths[teamId]
    ? slice.teampaths[teamId].split('/').filter(team => !!team)
    : [];
  return parentTeams;
}

export function selectManagedTeams(slice, ownTeams, user, maxDepth) {
  /* returns list of all teams under the users management chain */
  if (!slice || !ownTeams) {
    return [];
  }
  const teamSet = new Set();

  function recurse(teamId, curDepth) {
    teamSet.add(teamId);
    if (slice.childTeams[teamId]) {
      for (const childTeam of slice.childTeams[teamId]) {
        if (!maxDepth || curDepth <= maxDepth) {
          recurse(childTeam, curDepth + 1);
        }
      }
    }
  }

  for (const team of ownTeams) {
    recurse(team.id, 0);
  }
  const teamList = [...teamSet];
  return teamList;
}

export function selectTeamsManagedBy(slice, userId) {
  return slice.managedTeams[userId] || [];
}

export function selectUser(slice, sub) {
  return get(slice, `subs.${sub}`, enrich());
}

export function selectUsersTeam(slice, sub) {
  if (slice.subs[sub]) {
    return slice.subs[sub].teamId;
  }
  return null;
}

export function selectTeamName(slice, teamID) {
  if (slice.teamNames[teamID]) {
    return slice.teamNames[teamID];
  }
  return '';
}

export function isValidTeam(slice, teamID) {
  // Assume team is valid until all hierarchies have been fetched
  // To avoid rendering 404 screens while loading data
  if (!slice.hierarchy.ok) {
    return true;
  }
  // eslint-disable-next-line no-unused-vars
  for (const [key, hierarchy] of Object.entries(slice.matrixhierarchies)) {
    if (!hierarchy.ok) {
      return true;
    }
  }

  return Object.keys(slice.teamNames).includes(teamID);
}

export function selectTeamsManagedByWithNames(slice, userId) {
  const teamsWithNames = {};
  for (const team in slice.managedTeams[userId]) {
    teamsWithNames[slice.managedTeams[userId][team]] =
      slice.teamNames[slice.managedTeams[userId][team]];
  }
  return teamsWithNames;
}

export function selectTeamManager(slice, teamID) {
  if (!!slice.teamManagers && slice.teamManagers[teamID]) {
    return slice.teamManagers[teamID];
  }
  return null;
}

export function selectPermissions(slice, sub) {
  if (slice.permissions[sub]) {
    return slice.permissions[sub];
  }
  return enrich();
}

export function selectFollowedTeams(slice) {
  if (slice.followedTeams) {
    return slice.followedTeams;
  }
  return enrich();
}

export function selectMyJoinRequests(slice) {
  if (slice.myJoinRequests) {
    return slice.myJoinRequests;
  }
  return enrich();
}

export function canRequestToJoin(slice, teamID) {
  const matrixId = selectMatrixOfTeam(slice, teamID);
  if (!!matrixId) {
    const matrixType = selectMatrixType(slice, matrixId);
    return !!matrixType && matrixType.startsWith('inv_');
  }
  return false;
}

export function selectTeamJoinRequests(slice, teamID) {
  if (slice.teamJoinRequests[teamID]) {
    return slice.teamJoinRequests[teamID];
  }
  return enrich();
}

export function selectParentTeam(slice, teamID) {
  if (teamID && slice.parentTeams[teamID]) {
    return slice.parentTeams[teamID];
  }
  return null;
}

export function selectPathToRoot(slice, teamID) {
  const pathToRoot = [];
  function recurse(id) {
    pathToRoot.push(id);
    const parentTeam = selectParentTeam(slice, id);
    if (!!parentTeam) {
      recurse(parentTeam);
    }
  }

  recurse(teamID);
  return pathToRoot;
}

export function selectTeamMembers(slice, teamId) {
  if (slice && teamId && slice.teamMembers[teamId]) {
    return slice.teamMembers[teamId];
  }
  return enrich();
}

export function selectFullName(slice, sub) {
  return get(slice, `subs[${sub}].fullName`, '...');
}

export function selectFirstName(slice, sub) {
  if (slice.subs[sub]) {
    return slice.subs[sub].firstName;
  }
  return '??';
}

export function selectTitle(slice, sub) {
  if (slice.subs[sub]) {
    return slice.subs[sub].title;
  }
  return ' ';
}

export function selectMyManagedHierarchy(slice, userId, maxDepth) {
  const teams = [];
  const rootTeam = selectRootTeam(slice);
  if (!userId) {
    userId = selectRootUser(slice);
  }
  function recurse(managerEncountered, teamId, curDepth) {
    if (!managerEncountered && selectTeamManager(slice, teamId) === userId) {
      managerEncountered = true;
    }
    if (managerEncountered) {
      teams.push(teamId);
    }
    if (slice.childTeams[teamId]) {
      for (const childTeam of slice.childTeams[teamId]) {
        if (!maxDepth || curDepth <= maxDepth) {
          recurse(managerEncountered, childTeam, managerEncountered ? curDepth + 1 : curDepth);
        }
      }
    }
  }
  recurse(false, rootTeam, 0);
  return teams;
}

export function flattenTree(tree) {
  const teams = [];

  function recurse(node) {
    teams.push(node.id);
    for (const child of get(node, 'children', [])) {
      recurse(child);
    }
  }
  if (!!tree && !!tree.id) {
    recurse(tree);
  }

  return teams;
}

export function selectFlattenedOrg(slice, matrixId) {
  const h = selectHierarchy(slice, matrixId);
  const teams = [];

  function recurse(teamId) {
    teams.push(teamId);
    if (slice.childTeams[teamId]) {
      for (const childTeam of slice.childTeams[teamId]) {
        recurse(childTeam);
      }
    }
  }

  if (h.ok || !!h.id) {
    if (h.type === 'team') {
      recurse(h.id);
    } else {
      for (const child of h.children) {
        recurse(child.id);
      }
    }
  }
  return teams;
}

export function isManagerOf(slice, managerSub, userSub) {
  if (
    !slice.subs[managerSub] ||
    !slice.subs[managerSub].ok ||
    !slice.subs[userSub] ||
    !slice.subs[userSub].ok
  ) {
    return false;
  }
  const teamsToCheck = [slice.subs[userSub].teamId];
  slice.subs[userSub].matrixTeams.forEach(t => {
    teamsToCheck.push(t.id);
  });
  const managedTeams = selectTeamsManagedBy(slice, managerSub);
  if (!managedTeams) {
    return false;
  }
  for (const t of teamsToCheck) {
    if (managedTeams.includes(t)) {
      return true;
    }
    const parentList = getTeamsAboveTeam(slice, t);
    for (const parentTeam of parentList) {
      if (managedTeams.includes(parentTeam)) {
        return true;
      }
    }
  }
  return false;
}

export function isCeo(slice, sub) {
  if (selectRootTeam(slice) && selectTeamManager(slice, selectRootTeam(slice)) === sub) {
    return true;
  }
  return false;
}

export function isMember(slice, teamID, sub, lineTeamID) {
  if (!teamID) {
    return false;
  }

  // line team
  if (teamID === lineTeamID) {
    return true;
  }

  // team managed by
  if (selectTeamsManagedBy(slice, sub).includes(teamID)) {
    return true;
  }

  // matrix team
  const matrixTeams = selectUsersMatrixTeams(slice, sub);
  for (const team in matrixTeams) {
    if (matrixTeams[team].id === teamID) {
      return true;
    }
  }

  return false;
}

export function selectTeamNames(slice) {
  return slice.teamNames || {};
}

export function selectRecentTeams(slice) {
  return slice.recentTeams || [];
}

export function selectRecentUsers(slice) {
  return slice.recentUsers || [];
}
