import {
  COM_DESCRIPTION_MAX_LENGTH,
  COM_NAME_MAX_LENGTH,
  COM_OWNER_MAX_LENGTH,
} from 'config/constants';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';

const createSchema = {
  type: 'object',
  properties: {
    name: { type: 'string', minLength: 1, maxLength: COM_NAME_MAX_LENGTH },
    owner: { type: 'string', minLength: 1, maxLength: COM_OWNER_MAX_LENGTH },
    description: {
      richtextschema: SCHEMA_FULL,
      minLength: 0,
      maxLength: COM_DESCRIPTION_MAX_LENGTH,
    },
  },
  required: ['name', 'owner'],
  additionalProperties: true,
};

const editSchema = createSchema;
editSchema.required = [];
export { editSchema };

export default createSchema;
