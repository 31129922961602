import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get, orderBy } from 'lodash';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import StandardDialog from 'Components/Library/StandardDialog';
import { withLocation, withNavigation } from 'withRouter';
import Form from 'Components/Library/Forms/';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import FormCheckbox from 'Components/Library/Forms/Elements/FormCheckbox';
import PeriodSelector from 'Components/Features/Objectives/PeriodSelector';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';

import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';

import { openViewObjectivePanel } from 'config/ModalProvider/helpers';

const schema = {
  type: 'object',
  properties: {
    includeKrs: { type: 'boolean' },
    targetPeriod: { type: 'object' },
  },
  required: ['targetPeriod', 'includeKrs'],
  additionalProperties: true,
};

const createReduxAction = params => {
  const { targetPeriod, ...rest } = params;
  rest.ltperiod = targetPeriod.ltPeriodId;
  if (!!targetPeriod.stPeriodId) {
    rest.stperiod = targetPeriod.stPeriodId;
  }
  return objectivesActions.copyObjectiveToPeriod(rest);
};

class DialogConfirmCopy extends Component {
  state = {
    newObjectiveId: null,
    targetPeriod: null,
    initialTargetPeriod: null,
    mounted: false,
  };

  componentDidMount() {
    this.computeInitialState(this.props);
  }

  componentDidUpdate() {
    this.computeInitialState(this.props);
  }

  onObjectiveCreated = (apiResult, submittedParams) => {
    const objectiveID = get(apiResult, 'data.objectiveID');
    this.setState({ newObjectiveId: objectiveID, targetPeriod: submittedParams.targetPeriod });
  };

  computeInitialState = props => {
    const { objectiveData, sortedPeriods, periodCfg } = props;
    const { mounted } = this.state;
    if (sortedPeriods && objectiveData && !mounted) {
      const isShortTermOkr = Boolean(objectiveData.stperiod);
      const initialTargetPeriod = {};
      const ltPeriodIndex = sortedPeriods.map(p => p.id).indexOf(objectiveData.ltperiod);

      const providedPeriodNotFound = ltPeriodIndex === -1;

      if (providedPeriodNotFound) {
        // Cannot figure out the period of the OKR being copied...
        // Propose the currently ongoing period
        initialTargetPeriod.ltPeriodId = periodCfg?.data?.activeperiod?.ltperiod;
        if (isShortTermOkr) {
          initialTargetPeriod.stPeriodId = periodCfg?.data?.activeperiod?.stperiod;
        }
      } else if (!isShortTermOkr) {
        if (sortedPeriods.length > ltPeriodIndex + 1) {
          // Return next long term period
          initialTargetPeriod.ltPeriodId = sortedPeriods[ltPeriodIndex + 1].id;
        }
      } else {
        const currentSortedStPeriods = sortedPeriods[ltPeriodIndex].sortedStPeriods;
        const currentStPeriodIndex = currentSortedStPeriods
          .map(p => p.id)
          .indexOf(objectiveData.stperiod);

        if (currentSortedStPeriods.length > currentStPeriodIndex + 1) {
          // This OKR is not in the last short term period in the lt period,
          // return the next
          initialTargetPeriod.stPeriodId = currentSortedStPeriods[currentStPeriodIndex + 1].id;
          initialTargetPeriod.ltPeriodId = objectiveData.ltperiod;
        } else if (sortedPeriods.length > ltPeriodIndex + 1) {
          // This OKR is in the last st period of the lt period, return the
          // first st period of the next lt period
          initialTargetPeriod.stPeriodId = sortedPeriods[ltPeriodIndex + 1].sortedStPeriods[0].id;
          initialTargetPeriod.ltPeriodId = sortedPeriods[ltPeriodIndex + 1].id;
        }
      }
      this.setState({ mounted: true, initialTargetPeriod });
    }
  };

  viewNew = () => {
    // Figure out in the context of what short term period to view the links
    const { periodCfg } = this.props;
    const { targetPeriod } = this.state;
    let targetStPeriod;
    if (!!targetPeriod.stPeriodId) {
      // eslint-disable-next-line prefer-destructuring
      targetStPeriod = this.state.targetPeriod.stPeriodId;
    } else {
      // It's a long term objective
      // If we're in the same long term period currently, use the current period
      const activeLtPeriodId = get(periodCfg, 'data.activeperiod.ltperiod');
      if (this.state.targetPeriod.ltPeriodId === activeLtPeriodId) {
        targetStPeriod = get(periodCfg, 'data.activeperiod.stperiod');
      } else {
        // Otherwise take the first short term period of that long term period
        targetStPeriod = orderBy(
          Object.keys(periodCfg.data.periods[this.state.targetPeriod.ltPeriodId].stperiods).map(
            stPeriodId => ({
              ...periodCfg.data.periods[this.state.targetPeriod.ltPeriodId].stperiods[stPeriodId],
              id: stPeriodId,
            }),
          ),
          ['periodStart'],
        )[0].id;
      }
    }
    openViewObjectivePanel(
      targetStPeriod,
      this.state.newObjectiveId,
      false,
      this.props.navigate,
      this.props.location,
    );
  };

  filterFunc = p => {
    /* filter out the period where the Objective already exists from the options */
    const { objectiveData } = this.props;
    return !(
      p.ltPeriodId === objectiveData.ltperiod &&
      get(p, 'stPeriodId', '') === get(objectiveData, 'stperiod', '')
    );
  };

  render() {
    const { t, open, onCancel, objectiveID, periodCfg } = this.props;
    const { newObjectiveId, mounted, initialTargetPeriod } = this.state;

    // const futurePeriodsStripped = futurePeriods.filter(value => value !== period);
    if (!periodCfg || !periodCfg.ok) {
      return null;
    }

    return (
      <StandardDialog open={open} onBackdropClick={onCancel} size="xs" id="copy-objective-dialog">
        {!newObjectiveId && mounted ? (
          <Form
            name="confirm-copy-form"
            schema={schema}
            initialValues={{
              targetPeriod: initialTargetPeriod,
              includeKrs: true,
            }}
            stateSlice="main.objectives"
            submitActionCreator={createReduxAction}
            onSubmitSuccess={this.onObjectiveCreated}
            additionalProperties={{ objectiveID }}
            preventSaveIncomplete
          >
            <DialogTitle>{t('objectives.copytoperiodtitle')}</DialogTitle>
            <DialogContent>
              <LinearIndicator positioning="absolute" />
              <div>
                <div>
                  <FormSelectorField
                    fieldName="targetPeriod"
                    render={fieldProps => (
                      <PeriodSelector filterFunc={this.filterFunc} {...fieldProps} />
                    )}
                  />
                  <FormCheckbox
                    fieldName="includeKrs"
                    render={fieldProps => (
                      <FormControlLabel
                        control={<Checkbox {...fieldProps} />}
                        label={t('objectives.copytoperiodincludekrslabel')}
                      />
                    )}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} id="copy-objective-form-cancel-button">
                {t('general.cancel')}
              </Button>
              <FormButton
                render={buttonProps => (
                  <Button
                    id="copy-objective-form-confirm-button"
                    variant="contained"
                    color="secondary"
                    {...buttonProps}
                  >
                    {t('general.copy')}
                  </Button>
                )}
              />
            </DialogActions>
          </Form>
        ) : (
          <React.Fragment>
            <DialogTitle id="copy-objective-dialog-title">
              {t('objectives.copytoperioddonetitle')}
            </DialogTitle>
            <DialogContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('objectives.copytoperiodviewcreatedsubtitle')}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel}>{t('general.close')}</Button>
              <Button
                id="copy-objective-form-view-new-button"
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => this.viewNew()}
              >
                {t('objectives.copytoperiodviewcta')}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </StandardDialog>
    );
  }
}

const mapStateToProps = state => ({
  periodCfg: objectivesSelectors.selectPeriodConfig(state.main.objectives),
  sortedPeriods: objectivesSelectors.selectPeriodsAsSortedLists(state.main.objectives),
});

DialogConfirmCopy.propTypes = {
  open: PropTypes.bool,
  location: PropTypes.object,
  navigate: PropTypes.func,
  dispatch: PropTypes.func,
  objectiveID: PropTypes.string,
  onCancel: PropTypes.func,
  objectiveData: PropTypes.object,
  periodCfg: PropTypes.object,
  t: PropTypes.func,
  sortedPeriods: PropTypes.array,
};

export default withLocation(
  withNavigation(withTranslation()(connect(mapStateToProps)(DialogConfirmCopy))),
);
