import React from 'react';
import { getObjectiveCompletionPct, round } from 'config/helpers';
import { getObjectiveConfidenceString } from 'state/ducks/objectives/helpers';
import { Box, useTheme, SxProps } from '@mui/material';
import { get } from 'lodash';

export interface ObjectiveStatusChipProps {
  objectiveData: any;
  sx?: SxProps;
}

function ObjectiveStatusChip(props: ObjectiveStatusChipProps) {
  const { objectiveData, sx } = props;

  // handle backwards compatibility between the graph API and legacy APIs
  const representationVersion = objectiveData.repr_version || 1;

  const confidence = getObjectiveConfidenceString(objectiveData, representationVersion);
  const theme = useTheme();

  const confidenceColor = get(theme, ['palette', 'confidence', confidence], 'grey');

  return (
    <Box
      sx={{
        height: 24,
        pl: 1,
        pr: 1,
        textAlign: 'center',
        borderRadius: '8px',
        color: 'white',
        fontSize: theme.typography.body2.fontSize,
        letterSpacing: theme.typography.body2.letterSpacing,
        fontWeight: theme.typography.body2.fontWeight,
        fontFamily: theme.typography.body2.fontFamily,
        lineHeight: '24px',
        backgroundColor: confidenceColor,
        width: 'min-content',
        ...sx,
      }}
      data-tg-name={confidence}
    >
      {`${round(getObjectiveCompletionPct(objectiveData, representationVersion))}%`}
    </Box>
  );
}

export default ObjectiveStatusChip;
