import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function VisionAndPurposeIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.24" />
      <path
        d="M12 4.5C7.8675 4.5 4.5 7.8675 4.5 12C4.5 16.1325 7.8675 19.5 12 19.5C16.1325 19.5 19.5 16.1325 19.5 12C19.5 7.8675 16.1325 4.5 12 4.5ZM12 18C8.6925 18 6 15.3075 6 12C6 8.6925 8.6925 6 12 6C15.3075 6 18 8.6925 18 12C18 15.3075 15.3075 18 12 18ZM14.25 12C14.25 13.245 13.245 14.25 12 14.25C10.755 14.25 9.75 13.245 9.75 12C9.75 10.755 10.755 9.75 12 9.75C13.245 9.75 14.25 10.755 14.25 12Z"
        fill="black"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}

export default VisionAndPurposeIcon;
