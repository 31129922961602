import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Slider from './MuiSliderPort';
import DeltaSliderThumb from './DeltaSliderThumb';

const styles = () => ({
  thumbactive: {
    // empty class, needs to exist
  },
  thumb: {
    width: 24,
    height: 24,
    borderRadius: 8,
  },
  thumbLarge: {
    width: 56,
    height: 56,
    borderRadius: 8,
  },
  disabled: {
    '& $thumb': {
      width: 12,
      height: 12,
      // marginTop: -5,
      marginLeft: -6,
    },
  },
  track: {
    opacity: 0,
  },
  rail: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(90deg, rgba(255,255,255,0.5) 25%, rgba(0,0,0,0.5) 25%, rgba(0,0,0,0.5) 50%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.5) 75%, rgba(0,0,0,0.5) 75%, rgba(0,0,0,0.5) 100%)`,
    backgroundSize: '8px 8px',
  },
});

class TgDeltaSlider extends Component {
  render() {
    const { classes, value, disabled, onChange, getAriaLabel, size, t } = this.props;

    return (
      <Slider
        value={value}
        disabled={disabled}
        onChange={onChange}
        getAriaLabel={!getAriaLabel ? () => t('contextv2.sliderLabel') : getAriaLabel}
        unsorted
        size="small"
        components={{ Thumb: DeltaSliderThumb }}
        classes={{
          thumb: size === 'small' ? classes.thumb : classes.thumbLarge,
          track: classes.track,
          rail: classes.rail,
          disabled: classes.disabled,
        }}
      />
    );
  }
}

TgDeltaSlider.propTypes = {
  classes: PropTypes.exact({
    thumbactive: PropTypes.string,
    thumb: PropTypes.string,
    thumbLarge: PropTypes.string,
    savingIndicator: PropTypes.string,
    disabled: PropTypes.string,
    track: PropTypes.string,
    rail: PropTypes.string,
    dark: PropTypes.string,
  }),
  size: PropTypes.oneOf(['small', 'medium']),
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  t: PropTypes.func,
  getAriaLabel: PropTypes.func,
};

TgDeltaSlider.defaultProps = {
  size: 'small',
};
export default withTranslation()(withStyles(styles)(TgDeltaSlider));
