import React from 'react';
import PropTypes from 'prop-types';
import rison from 'rison-node';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withNavigation } from 'withRouter';
import { objectivesSelectors } from 'state/ducks/objectives';
import { peopleSelectors } from 'state/ducks/people';
import DialogObjectives from './DialogObjectives';

class DialogObjectivesContainer extends React.Component {
  onExpand = event => {
    const { domain, period } = this.props;
    event.preventDefault();
    event.stopPropagation();
    const path = `/home/objectives/${period}/dashboard/${rison.encode({
      v: 'widgets',
      d: [{ d: domain.d, t: domain.t }],
    })}`;
    this.props.navigate(path);
  };

  render() {
    return (
      <DialogObjectives
        onExpand={this.onExpand}
        open={this.props.open}
        period={this.props.period}
        domain={this.props.domain}
        onClose={this.props.onClose}
        sub={this.props.sub}
        dispatch={this.props.dispatch}
        objectivePeriodConfig={this.props.objectivePeriodConfig}
        isValidTeam={this.props.isValidTeam}
        zIndexModifier={this.props.zIndexModifier}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sub: state.auth.userID,
  objectivePeriodConfig: objectivesSelectors.selectPeriodConfig(state.main.objectives),
  isValidTeam: peopleSelectors.isValidTeam(state.main.people, get(ownProps, 'domain.d')),
});

DialogObjectivesContainer.propTypes = {
  domain: PropTypes.object,
  period: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  navigate: PropTypes.func,
  sub: PropTypes.string,
  dispatch: PropTypes.func,
  objectivePeriodConfig: PropTypes.object,
  isValidTeam: PropTypes.bool,
  zIndexModifier: PropTypes.number,
};

export default withNavigation(connect(mapStateToProps)(DialogObjectivesContainer));
