import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { API_BASE_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';

const getInboxFromAPI = function getInboxFromAPIFunction(authToken, tenantID, payload) {
  return axios.get(`${API_BASE_URL}/${tenantID}/getinbox`, {
    params: payload,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

// eslint-disable-next-line import/prefer-default-export
export const getInboxLogic = createLogic({
  type: types.GET_INBOX,
  latest: true,
  process: async ({ getState }, dispatch, done) => {
    getInboxFromAPI(getState().auth.tokens.access_token, getState().auth.tenantID)
      .then(res => {
        dispatch(actions.inboxReceived(res.data.result));
      })
      .catch(e => {
        dispatch(actions.errorTryAgainLater(e));
      })
      .then(() => done());
  },
});

export const dismissNotificationLogic = createLogic({
  type: types.DISMISS_INBOX_CARD,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/dismissnotification`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.notificationDismissed(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});
