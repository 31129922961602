import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FormContext from '../FormContext';

const FormStringList = React.memo(props => {
  const {
    render,
    fieldName,
    values,
    formName,
    onFieldChange,
    onEntryDeleted,
    onEntryAdded,
    error,
    entryErrors,
    minLength,
    maxLength,
    isRequired,
    helperText,
    canAdd,
  } = props;

  const inputProps = {};
  if (!!minLength) {
    inputProps.minLength = minLength;
  }
  if (!!maxLength) {
    inputProps.maxLength = maxLength;
  }
  const entryProps = [];

  if (!!values) {
    values.forEach((value, index) => {
      entryProps[index] = {
        value,
        onChange: e => onFieldChange(index, e.target.value),
        onDelete: () => onEntryDeleted(index),
        error: index in entryErrors,
        helperText: !!(index in entryErrors) ? entryErrors[index] : '',
        inputProps,
        name: `${formName}-${fieldName}-opt-${index}`,
      };
    });
  }

  const fieldProps = {
    values,
    name: `${formName}-${fieldName}`,
    error,
    helperText,
    required: isRequired,
    addButtonProps: {
      onClick: onEntryAdded,
      disabled: !canAdd,
    },
    entryProps,
  };
  return render(fieldProps);
});

const getEntryErrors = (fieldName, fieldErrors) => {
  const entryErrors = {};
  Object.keys(fieldErrors).forEach(key => {
    if (key.startsWith(fieldName)) {
      if (key !== fieldName) {
        const fieldIndex = parseInt(key.split('.').pop(), 10);
        if (!(fieldIndex in entryErrors)) {
          entryErrors[fieldIndex] = [];
        }
        entryErrors[fieldIndex].push(fieldErrors[key]);
      }
    }
  });
  return entryErrors;
};

function OuterFormStringList(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormStringList that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render, fieldName } = props;

  const { onFieldChange, values, formName, fieldErrors, showErrors, schema } =
    React.useContext(FormContext);

  const minLength = get(schema, `properties.${fieldName}.items.minLength`);
  const maxLength = get(schema, `properties.${fieldName}.items.maxLength`);
  const maxItems = get(schema, `properties.${fieldName}.maxItems`, 100);
  const isRequired = get(schema, 'required', []).includes(fieldName);

  const onChange = (index, value) => {
    const newValues = [...values[fieldName]];
    newValues[index] = value;
    onFieldChange(fieldName, newValues);
  };

  const onEntryAdded = e => {
    const newValues = !!values[fieldName] ? [...values[fieldName]] : [];
    newValues.push('');
    onFieldChange(fieldName, newValues);
    // We will typically want to autofocus the added input,
    // call blur on the button or the MUI focus effect will get bugged
    e.currentTarget.blur();
    return true;
  };

  const onEntryDeleted = index => {
    const newValues = [...values[fieldName]];
    newValues.splice(index, 1);
    onFieldChange(fieldName, newValues);
  };

  const overallError = showErrors && fieldErrors[fieldName];

  const entryErrors = showErrors ? getEntryErrors(fieldName, fieldErrors) : {};

  return (
    <FormStringList
      fieldName={fieldName}
      render={render}
      onFieldChange={onChange}
      onEntryAdded={onEntryAdded}
      onEntryDeleted={onEntryDeleted}
      values={get(values, fieldName)}
      formName={formName}
      maxLength={maxLength}
      minLength={minLength}
      isRequired={isRequired}
      error={Boolean(overallError)}
      entryErrors={entryErrors}
      helperText={!!overallError ? overallError : ''}
      canAdd={values[fieldName].length < maxItems}
    />
  );
}

OuterFormStringList.propTypes = {
  render: PropTypes.func,
  fieldName: PropTypes.string,
};

export default OuterFormStringList;
