import { get } from 'lodash';
import { enrich } from '../../helpers';

// To be consistent with selectors of other ducks and prepare for
// additional selectors:
// eslint-disable-next-line import/prefer-default-export
export function selectCommentsForNode(slice, nodeId, embeddedNodeId) {
  const commentData = get(slice, ['commentedNodes', nodeId], enrich());
  if (commentData && commentData.ok && embeddedNodeId) {
    return {
      ...commentData,
      data: commentData.data.filter(c => c.sub_reference === embeddedNodeId),
    };
  }
  return commentData;
}
