import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import { withNavigation } from 'withRouter';

import { peopleSelectors } from 'state/ducks/people';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: 8,
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[300],
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
});

class TeamInviteRequestNotification extends React.Component {
  handleClick = () => {
    const { navigate, message } = this.props;
    navigate(`/home/teams/${message.teamID}/managemembers`);
  };

  render() {
    const { classes, t, message, key, teamName } = this.props;
    if (!!message) {
      return (
        <div key={key} className={classes.root} onClick={this.handleClick}>
          <PersonasAvatar sub={message.requestor} className={classes.avatar} />
          <div>
            <Typography variant="subtitle2">
              {t('notifications.teams.newInviteRequest', {
                from: this.props.getName(message.requestor),
                teamName,
              })}
            </Typography>
            <Typography variant="caption">
              {t('general.ndaysago', {
                days: message.age,
              })}
            </Typography>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  inbox: state.main.inbox && state.main.inbox.inbox,
  getName: sub => peopleSelectors.selectFullName(state.main.people, sub),
  teamName: peopleSelectors.selectTeamName(state.main.people, ownProps.message.teamID),
});

TeamInviteRequestNotification.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    avatar: PropTypes.string,
  }),
  key: PropTypes.string,
  t: PropTypes.func,
  message: PropTypes.object,
  navigate: PropTypes.func,
  getName: PropTypes.func,
  teamName: PropTypes.string,
};

export default withNavigation(
  withTranslation()(withStyles(styles)(connect(mapStateToProps)(TeamInviteRequestNotification))),
);
