import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import withStyles from '@mui/styles/withStyles';
import Fab from 'Components/Common/Fab';
import { withLocation, withNavigation } from 'withRouter';
import { openCreateTopicPanel } from 'config/ModalProvider/helpers';
import { connectionSelectors } from 'state/ducks/connection';

const styles = theme => ({
  fabIcon: {
    color: theme.palette.secondary.contrastText,
  },
});

class CreateTopicButton extends PureComponent {
  state = {
    anchorEl: null,
  };

  handleOpen = () => {
    this.setState({ anchorEl: null });
    openCreateTopicPanel(
      this.props.audienceType,
      this.props.audienceID,
      this.props.navigate,
      this.props.location,
    );
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, isOnline, t } = this.props;
    if (!isOnline) {
      return null;
    }
    return (
      <div>
        <Fab
          name="add-insights-fab"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          aria-label={t('insights.AddInsightFabLabel')}
          onClick={this.handleOpen}
        >
          <AddIcon className={classes.fabIcon} />
        </Fab>
      </div>
    );
  }
}

CreateTopicButton.propTypes = {
  classes: PropTypes.exact({
    fabIcon: PropTypes.string,
  }),
  isOnline: PropTypes.bool,
  location: PropTypes.object,
  navigate: PropTypes.func,
  audienceID: PropTypes.string,
  audienceType: PropTypes.string,
  t: PropTypes.func,
};

const mapStateToProps = state => ({
  isChangeManager: state.auth.isChangeManager,
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
});

export default withTranslation()(
  withLocation(withNavigation(withStyles(styles)(connect(mapStateToProps)(CreateTopicButton)))),
);
