/* eslint-disable camelcase */
import { PURGE } from 'redux-persist';
import { get, isEqual } from 'lodash';
import { validatePersistedState, enrich, copyState } from 'state/helpers';
import {
  onFetchStarted,
  onListFetchSuccess,
  onFetchFailed,
  addApiErrorToState,
} from 'state/defaultLogic';
import * as types from './types';

export const initialState = {
  VERSION: 1.04,
  relations: {},
  actionlog: {},
};

const applyRelationEdits = (state, payload) => {
  const newState = copyState(state);
  for (const change of payload.changeset) {
    const { from, to, action, type } = change;
    for (const nodeId of [from, to]) {
      if (get(newState.relations, `${nodeId}.ok`, false)) {
        // shallow copy object to ensure components re-render
        newState.relations[nodeId] = { ...newState.relations[nodeId] };

        const relation = {
          from,
          to,
          type,
        };

        if (action === 'ADD') {
          newState.relations[nodeId].data.push(relation);
        } else if (action === 'DELETE') {
          newState.relations[nodeId].data = newState.relations[nodeId].data.filter(
            r => !isEqual(r, relation),
          );
        }
      }
    }
  }
  return newState;
};

export const queryCompletedTransformFunc = (state, payload) => {
  // function run on the state after a list of relations has been received,
  // adds the received relations to the lists for the opposite vertex if
  // the data exists

  const { id, relations } = payload;
  for (const relation of relations) {
    const otherNodeId = relation.from === id ? relation.to : relation.from;
    const otherNodeRelations = get(state.relations, otherNodeId, {});
    if (otherNodeRelations.ok) {
      const isKnown = otherNodeRelations.data.find(r => isEqual(r, relation));
      if (!isKnown) {
        state.relations[otherNodeId] = enrich(otherNodeRelations);
        // only add if this relation is not already known:
        state.relations[otherNodeId].data.push(relation);
      }
    }
  }
};

// The params need to be in this order, that's what redux gives :)
// eslint-disable-next-line default-param-last
const reducer = (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);

  switch (action.type) {
    case types.FETCH_RELATIONS:
      return onFetchStarted({ state, payload: action.payload, key: 'relations' });

    case types.RECEIVED_RELATIONS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'relations',
        payloadDataKey: 'relations',
        postProcessTransform: queryCompletedTransformFunc,
      });
    case types.FAILED_RELATIONS:
      return onFetchFailed({ state, payload: action.payload, key: 'relations' });
    case types.EDITED_RELATIONS:
      return applyRelationEdits(state, action.payload);
    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action.payload);

    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};

export default reducer;
