import { enrich } from '../../helpers';

export const selectSurveysForAudience = (slice, audienceType, audienceID) => {
  if (slice.surveysPerAudience[audienceType][audienceID]) {
    return slice.surveysPerAudience[audienceType][audienceID];
  }
  return enrich();
};

export const selectTopic = (slice, topicID) => {
  if (!!slice.topicdata[topicID]) return slice.topicdata[topicID];

  return enrich();
};

export const selectInterpretationTopic = (slice, csiID) => {
  if (slice.csidata[csiID]) {
    return slice.csidata[csiID];
  }
  return enrich();
};

export const selectSurvey = (slice, surveyID) => {
  if (surveyID) {
    if (slice.surveydata[surveyID]) {
      return slice.surveydata[surveyID];
    }
    const modernSurveyID = slice.legacySurveyIds[surveyID];
    if (!!modernSurveyID && slice.surveydata[modernSurveyID]) {
      return slice.surveydata[modernSurveyID];
    }
  }
  return enrich();
};

export const selectTopicForSurvey = (slice, surveyID) => {
  if (!!surveyID) {
    const survey = selectSurvey(slice, surveyID);
    if (survey && survey.data) {
      const { topicID } = survey.data;
      if (slice.topicdata[topicID]) {
        return slice.topicdata[topicID];
      }
    }
  }
  return enrich();
};

export const selectSurveysForTopic = (slice, topicID) => {
  if (slice.surveysPerTopic[topicID]) {
    return slice.surveysPerTopic[topicID];
  }
  return enrich();
};

export const selectSurveyStats = (slice, surveyID) => {
  if (surveyID) {
    if (slice.surveyStats[surveyID]) {
      return slice.surveyStats[surveyID];
    }
  }

  return enrich();
};

/* Common understanding */
export const selectInterpretationStats = (slice, csiID) => {
  if (!!slice.understandingstats[csiID]) {
    return slice.understandingstats[csiID];
  }
  return enrich();
};

export const selectInterpretationStatsForTeam = (slice, csiID, teamID) => {
  const stats = selectInterpretationStats(slice, csiID);
  if (stats && stats.ok) {
    return stats.stats[teamID];
  }
  return null;
};

export const selectInterpretationTopicsForProgram = (slice, cpID) => {
  if (slice.csi[cpID]) {
    return slice.csi[cpID];
  }
  return enrich();
};
