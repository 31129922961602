import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';

const styles = theme => ({
  progressRoot: {
    backgroundColor: theme.palette.secondary.dark,
  },
  progressRootLight: {},
  container: {
    height: 5,
    width: '100%',
  },
  errorTextContainer: {
    position: 'relative',
    zIndex: 9999,
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
    textAlign: 'center',
  },
  errorContent: {
    padding: '0px !important',
  },
  errorText: {
    padding: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: theme.palette.error.main,
    color: '#FFFFFF',
    fontSize: 14,
  },
});

class SavingIndicator extends Component {
  state = {
    showDone: false,
    showError: false,
  };

  componentDidUpdate(prevProps) {
    if (!!prevProps.submitted && !this.props.submitted) {
      if (this.props.submitStatus === 1) {
        // It's ok to use setState here, the checks we have ensure
        // this will not lead to an infinite loop...
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ showDone: true });
        this.successtimeout = setTimeout(() => {
          this.setState({ showDone: false });
        }, 750);
      } else if (this.props.submitStatus === -1) {
        // It's ok to use setState here, the checks we have ensure
        // this will not lead to an infinite loop..
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ showError: true });
        this.errortimeout = setTimeout(() => {
          this.setState({ showError: false });
        }, 2500);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.errortimeout);
    clearTimeout(this.successtimeout);
  }

  render() {
    const {
      classes,
      submitStatus,
      submitted,
      className,
      background = 'dark',
      errorText = this.props.t('general.savingError'),
    } = this.props;
    const { showDone, showError } = this.state;
    return (
      <div className={className}>
        <div className={classes.container}>
          {submitted && (
            // saving
            <LinearProgress
              name="saving-indicator-saving"
              color="secondary"
              className={background === 'dark' ? classes.progressRoot : classes.progressRootLight}
            />
          )}
          {submitStatus === 1 && !!showDone && (
            <LinearProgress
              name="saving-indicator-saved"
              color="secondary"
              variant="determinate"
              value={100}
            />
          )}
          {
            // retrying
            submitStatus === -10 && (
              <LinearProgress
                name="saving-indicator-retrying"
                color="secondary"
                className={background === 'dark' ? classes.progressRoot : classes.progressRootLight}
              />
            )
          }
          {
            // max retries, error
            submitStatus === -1 && !!showError && (
              <div className={classes.errorTextContainer}>
                <Slide direction="down" in={submitStatus === -1} mountOnEnter unmountOnExit>
                  <Card name="saving-indicator-error-card">
                    <CardContent className={classes.errorContent}>
                      <Typography className={classes.errorText}>{errorText}</Typography>
                    </CardContent>
                  </Card>
                </Slide>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

SavingIndicator.propTypes = {
  submitStatus: PropTypes.number,
  classes: PropTypes.exact({
    progressRoot: PropTypes.string,
    progressRootLight: PropTypes.string,
    container: PropTypes.string,
    errorTextContainer: PropTypes.string,
    errorContent: PropTypes.string,
    errorText: PropTypes.string,
  }),
  submitted: PropTypes.bool,
  background: PropTypes.string,
  errorText: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(SavingIndicator));
