export const GET_CONTEXT = 'GET_CONTEXT';
export const RECEIVED_CONTEXT = 'CONTEXT_RECEIVED';
export const FAILED_CONTEXT = 'FAILED_CONTEXT';

export const EDIT_CONTEXT_CARD = 'EDIT_CONTEXT_CARD';
export const EDITED_CONTEXT_CARD = 'EDITED_CONTEXT_CARD';

export const MOVE_CONTEXT_CARD = 'MOVE_CONTEXT_CARD';
export const MOVED_CONTEXT_CARD = 'MOVED_CONTEXT_CARD';

export const DELETE_CONTEXT_CARD = 'DELETE_CONTEXT_CARD';
export const DELETED_CONTEXT_CARD = 'DELETED_CONTEXT_CARD';

export const CREATE_CONTEXT_CARD = 'CREATE_CONTEXT_CARD';
export const CREATED_CONTEXT_CARD = 'CREATED_CONTEXT_CARD';

export const ERROR_RECEIVED_FROM_API = 'CTX_API_ERROR_RECEIVED';
