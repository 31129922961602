import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CollapseIcon from '@mui/icons-material/UnfoldLess';
import ExpandIcon from '@mui/icons-material/UnfoldMore';
import CircleIcon from '@mui/icons-material/Circle';
import TeamChip from 'Components/Library/TeamChip';
import UserChip from 'Components/Library/UserChip';
import { peopleSelectors } from 'state/ducks/people';

function MemberStatusList(props) {
  const { t, teamId, members, heartbeat, doneUsers } = props;
  const [collapsed, toggleCollapsed] = useState(false);

  if (!members || members.length === 0 || !heartbeat) {
    return null;
  }
  const allUsers = {};
  const waitingUsers = [];
  heartbeat.users.forEach(user => {
    allUsers[user.user_id] = user;
  });

  members.forEach(sub => {
    if (!doneUsers.includes(sub)) {
      waitingUsers.push(sub);
    }
  });

  const renderTitles = heartbeat.session_state < 4;

  const onKeyDown = e => {
    if (['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
      toggleCollapsed(!collapsed);
      return true;
    }

    return false;
  };

  return (
    <Box name="member-status-list">
      {renderTitles && (
        <Typography
          variant="subtitle2"
          component="span"
          display="block"
          color="text.secondary"
          sx={{ mb: 2 }}
        >
          <Trans
            i18nKey="dfac.memberStatusListHeading"
            values={{
              numDone: doneUsers.length,
              numTotal: members.length,
            }}
          />
        </Typography>
      )}

      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.box,
          borderRadius: 1,
          '&:hover': {
            cursor: 'pointer',
          },
          '&:hover .team-widget-toggle-icon': {
            backgroundColor: 'action.hover',
          },
          '&:focus .team-widget-toggle-icon': {
            backgroundColor: 'action.hover',
          },
        }}
        tabIndex={0}
        onClick={() => toggleCollapsed(!collapsed)}
        onKeyDown={onKeyDown}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TeamChip color="transparent" teamId={teamId} />
          <Box
            sx={{
              mr: 1,
              borderRadius: 0.5,
              width: 24,
              height: 24,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="team-widget-toggle-icon"
          >
            {collapsed ? (
              <ExpandIcon fontSize="small" color="action" />
            ) : (
              <CollapseIcon fontSize="small" color="action" />
            )}
          </Box>
        </Box>
        {!collapsed && (
          <Box
            name="member-status-list-users"
            sx={{
              p: 1,
              pb: 1,
              '& > div:not(:last-child)': {
                mb: 1,
              },
            }}
          >
            {renderTitles && (
              <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
                <Typography variant="body2" color="text.strong">
                  {t('dfac.memberStatusListAnsweredSectionTitle')} {`(${doneUsers.length})`}
                </Typography>
              </Box>
            )}
            {doneUsers.map((sub, index) => {
              const online = allUsers[sub] && allUsers[sub].connection_status;
              return (
                <UserChip
                  key={`member-list-done-key-${index}`}
                  color="white"
                  sub={sub}
                  ContextIcon={CircleIcon}
                  contextIconProps={{
                    sx: {
                      color: theme =>
                        !!online ? theme.palette.secondary.main : theme.palette.text.disabled,
                      fontSize: '10px',
                      pr: '2px',
                    },
                  }}
                />
              );
            })}
            {renderTitles && (
              <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
                <Typography variant="body2" color="text.strong">
                  {t('dfac.memberStatusListWaitingSectionTitle')} {`(${waitingUsers.length})`}
                </Typography>
              </Box>
            )}
            {waitingUsers.map((sub, index) => {
              const online = allUsers[sub] && allUsers[sub].connection_status;
              return (
                <UserChip
                  key={`member-list-waiting-key-${index}`}
                  color="white"
                  variant="small"
                  sub={sub}
                  ContextIcon={CircleIcon}
                  contextIconProps={{
                    sx: {
                      color: theme =>
                        !!online ? theme.palette.secondary.main : theme.palette.text.disabled,
                      fontSize: '10px',
                      pr: '2px',
                    },
                  }}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}

MemberStatusList.propTypes = {
  heartbeat: PropTypes.object,
  doneUsers: PropTypes.array,
  members: PropTypes.array,
  t: PropTypes.func,
  teamId: PropTypes.string,
};

const mapStateToProps = state => ({
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
});

export default connect(mapStateToProps)(withTranslation()(MemberStatusList));
