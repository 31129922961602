import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import BulbIcon from '@mui/icons-material/EmojiObjectsOutlined';
import PollIcon from '@mui/icons-material/PollOutlined';
import WarnIcon from '@mui/icons-material/WarningAmber';
import SvgIcon from '@mui/material/SvgIcon';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
  icon: {
    marginRight: theme.spacing(),
  },
  type: {
    marginTop: 2, // pixels
  },
});

const renderIcon = (theme, variant, className) => {
  switch (variant) {
    case 'bestpractice':
      return (
        <SvgIcon color="secondary" className={className} viewBox="0 0 24 24">
          <path
            d="M 19,3 H 5 C 4,3 3,4 3,5 V 19 C 3,20 4,21 5,21 H 19 C 20,21 21,20 21,19 V 5 C 21,4 20,3 19,3 Z M 9,19 h -2 v -2.5 l 2,2.4e-5 z m 4,4e-6 h -2 v -6 h 2 l 0,6 h 0 z m 4,-4e-6 h -2 V 9 h 2 z"
            stroke="null"
          />
        </SvgIcon>
      );
    case 'recommendation':
      return <PollIcon htmlColor={theme.palette.action.active} className={className} />;
    case 'hint':
      return <BulbIcon htmlColor={theme.palette.action.active} className={className} />;
    case 'warning':
      return <WarnIcon color="warning" className={className} />;
    default:
      return <InfoIcon htmlColor={theme.palette.action.active} className={className} />;
  }
};

class HelperText extends Component {
  render() {
    const { classes, theme, className, children, variant = 'info', ...rest } = this.props;

    return (
      <div className={clsx([classes.root, className])}>
        {renderIcon(theme, variant, classes.icon)}
        <Typography className={classes.type} variant="caption" color="text.secondary" {...rest}>
          {children}
        </Typography>
      </div>
    );
  }
}

HelperText.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
  }),
  className: PropTypes.string,
  theme: PropTypes.object,
  variant: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(HelperText);
