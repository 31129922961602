import React from 'react';
import PropTypes from 'prop-types';
import FormContext from '../FormContext';

const FormButton = React.memo(props => {
  const { render, onSubmit, submitting } = props;

  const fieldProps = {
    onClick: onSubmit,
    disabled: !!submitting,
  };
  return render(fieldProps);
});

function OuterFormButton(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormTextField that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render } = props;

  const { onFieldChange, isValid, formName, onSubmit, submitting } = React.useContext(FormContext);

  return (
    <FormButton
      render={render}
      onFieldChange={onFieldChange}
      formName={formName}
      isValid={isValid}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

OuterFormButton.propTypes = {
  render: PropTypes.func,
};

export default OuterFormButton;
