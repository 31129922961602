import React from 'react';
import { get } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import EmptyTablePlaceholder from './EmptyTablePlaceholder';

const styles = theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    overflowX: 'auto',
    pageBreakInside: 'avoid',
    ...theme.shape,
    backgroundColor: theme.palette.background.paper,
    minHeight: 32,
    padding: theme.spacing(),
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      overflowX: 'scroll',
    },
  },
});

function PaginatedDataGrid(props) {
  const {
    classes,
    translationNameSpace,
    data,
    RowElement,
    TableHeaderElement,
    statusFilterValue,
    columns,
    sortColumn,
    sortOrder,
    setSortBy,
    setSortOrder,
    allowEdits,
  } = props;
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  if (!data.ok && !data.loading && data.error) {
    return null;
  }

  return (
    <div className={classes.root} style={{ paddingTop: 0 }}>
      <Table
        size="small"
        sx={{ mb: 1, 'tbody:before': { height: '8px', content: '" "', display: 'block' } }}
      >
        <TableHeaderElement
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          setSortBy={setSortBy}
          setSortOrder={setSortOrder}
        />
        <TableBody sx={{ '& tr > td': { borderBottom: 0 } }}>
          {get(data, 'data', []).map(graphId => (
            <RowElement
              graphId={graphId}
              key={graphId}
              statusFilter={statusFilterValue}
              columns={columns}
              allowEdits={allowEdits}
            />
          ))}
          {data.loading && (
            <TableRow aria-label={t('general.loading')}>
              <TableCell colSpan={columns ? columns.length : 1}>
                <Skeleton variant="rounded" height={22} aria-disabled />
              </TableCell>
            </TableRow>
          )}
          {
            // The empty state row will be shown only if no other rows are rendered
            // This works because rows render null if they do not match filters,
            data.ok && !data.loading && (
              <TableRow sx={{ '&:not(:only-child)': { display: 'none' } }}>
                <TableCell colSpan={columns ? columns.length : 1}>
                  <EmptyTablePlaceholder translationgroup={translationNameSpace} />
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </div>
  );
}

PaginatedDataGrid.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  data: PropTypes.object,
  statusFilterValue: PropTypes.array,
  translationNameSpace: PropTypes.string,
  RowElement: PropTypes.elementType,
  TableHeaderElement: PropTypes.elementType,
  columns: PropTypes.array,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf([SORTING_ORDERS.asc, SORTING_ORDERS.desc]),
  setSortBy: PropTypes.func,
  setSortOrder: PropTypes.func,
  allowEdits: PropTypes.bool,
};

export default withStyles(styles)(PaginatedDataGrid);
