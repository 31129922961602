import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { insightsSelectors } from 'state/ducks/insights';
import { connectionSelectors } from 'state/ducks/connection';
import { peopleSelectors } from 'state/ducks/people';
import InsightsCard from './InsightsCard';

class InsightsCardContainer extends Component {
  render() {
    const { topic, survey, surveyID, isOnline, canAct, canReply } = this.props;

    return (
      <InsightsCard
        topic={topic}
        survey={survey}
        surveyID={surveyID}
        isOnline={isOnline}
        canAct={canAct}
        canReply={canReply}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const survey = insightsSelectors.selectSurvey(state.main.insights, ownProps.surveyID);
  let canAct = false;
  let canReply = true;
  if (!!survey.ok) {
    if (survey.data.audienceType === 'PRG') {
      canAct = state.auth.isChangeManager;
    }
    if (survey.data.audienceType === 'TEAM') {
      const isTeamLead =
        state.auth.userID ===
        peopleSelectors.selectTeamManager(state.main.people, survey.data.audienceID);
      const isLeadOfLead = peopleSelectors
        .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
        .includes(survey.data.audienceID);
      const isMember = peopleSelectors.isMember(
        state.main.people,
        survey.data.audienceID,
        state.auth.userID,
        state.auth.teamID,
      );
      canAct = isTeamLead || isLeadOfLead || state.auth.isChangeManager;
      canReply = isMember || isTeamLead;
    }
  }

  return {
    isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
    survey,
    canAct,
    canReply,
  };
};

InsightsCardContainer.propTypes = {
  topic: PropTypes.string,
  survey: PropTypes.object,
  isOnline: PropTypes.bool,
  surveyID: PropTypes.string,
  canAct: PropTypes.bool,
  canReply: PropTypes.bool,
};

export default connect(mapStateToProps)(InsightsCardContainer);
