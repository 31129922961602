import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import Form from 'Components/Library/Forms/Form';
import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import { gameplansActions } from 'state/ducks/gameplans';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';

import RichTextRenderer from 'Components/Library/RichTextRenderer';
import WidgetFooter from './Lib/WidgetFooter';

export const ITEMTYPE_RICHTEXT = 'RICHTEXT';

const schema = {
  type: 'object',
  properties: {
    elements: {
      type: 'array',
      minItems: 1,
      maxItems: 1,
      items: {
        type: 'object',
        properties: {
          element_type: { const: ITEMTYPE_RICHTEXT },
          elements: { richtextschema: SCHEMA_FULL, minLength: 0, maxLength: 8000 },
        },
      },
    },
  },
};

const CARD_FONTS = {
  h1: 'h4',
  h2: 'h5',
  h3: 'h6',
};

const DIALOG_FONTS = {
  p: 'bodylarge',
  h1: 'h3',
  h2: 'h4',
  h3: 'h5',
};

function VisionWidget({
  variant,
  canEdit,
  teamId,
  gameplanId,
  data,
  dragHandleProps,
  requestRemove,
  name,
}) {
  const { t } = useTranslation();

  const isInteractive = variant === 'card' && canEdit;

  return (
    <>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '24px',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Typography variant="subtitle2">{t('gameplans.visionWidget.title')}</Typography>
          {isInteractive && (
            <Stack direction="row" spacing={1} className="gp-widget-actions">
              <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                <DeleteIcon />
              </ContainedIconButton>
            </Stack>
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          mt: 0,
          mb: 1.5,
          ml: 2,
          mr: 2,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
        className="widget-content"
        onClick={e => e.stopPropagation()}
      >
        <Form
          name="gameplan-vision"
          schema={schema}
          preventSaveIncomplete
          stateSlice="main.gameplans"
          submitActionCreator={gameplansActions.editGamePlanWidget}
          debouncedAutoSubmit={950}
          additionalProperties={{ domain_id: teamId, widget_id: data.id, gameplan_id: gameplanId }}
          initialValues={{ elements: data.elements }}
        >
          {isInteractive ? (
            <FormRichTextField
              fieldName="elements.0.content"
              render={fieldProps => (
                <RichTextEditor
                  {...fieldProps}
                  label={t('gameplans.visionWidget.visionContentLabel')}
                  placeholder={t('gameplans.visionWidget.visionContentLabel')}
                  toolbarVariant="floating"
                  variant="standard"
                  schema={SCHEMA_FULL}
                  fontVariantOverrides={CARD_FONTS}
                />
              )}
            />
          ) : (
            <>
              <RichTextRenderer
                variant={variant === 'card' ? 'standard' : 'inline'}
                richTextContent={data.elements[0].content}
                schema={SCHEMA_FULL}
                fontVariantOverrides={variant === 'card' ? CARD_FONTS : DIALOG_FONTS}
              />
              {/* The box is to make the pixels match what the editor sees,
                  the rich text editor always has a blank row at the bottom:
              */}
              <Box sx={{ height: '16px' }} />
            </>
          )}
        </Form>
      </Box>
      {variant === 'card' && <WidgetFooter data={data} dragHandleProps={dragHandleProps} />}
    </>
  );
}

VisionWidget.propTypes = {
  teamId: PropTypes.string,
  gameplanId: PropTypes.string,
  data: PropTypes.object,
  dragHandleProps: PropTypes.object,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  name: PropTypes.string,
};

VisionWidget.defaultProps = {
  variant: 'card',
};

export default VisionWidget;
