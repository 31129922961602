import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import ThumbIcon from '@mui/icons-material/ThumbUp';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HelperText from 'Components/Common/HelperText';

const styles = theme => ({
  row: {
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.box,
    ...theme.shape,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
  },
  voteCaptionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: -4,
  },
  voteCaption: {
    color: theme.palette.secondary.main,
  },
  icon: {
    marginBottom: 5,
  },
  voteContainer: {
    flexGrow: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    marginBottom: theme.spacing(2),
  },
  inputItem: {
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflow: 'hidden',
    ...theme.shape,
    backgroundColor: theme.palette.background.paper,
    justifySelf: 'stretch',
    flexGrow: 5,
    lineHeight: '1.25rem',
    padding: 12,
    paddingTop: 8,
    paddingBottom: 8,
  },
});

class DivShowVotes extends Component {
  state = {
    expandedQuestions: [],
  };

  renderVotedInputs = (key, data) => {
    const { t, classes } = this.props;
    const elements = [];
    if (!!data[key]) {
      data[key].forEach((inputItem, index) => {
        if (index < 3 || this.state.expandedQuestions.includes(key)) {
          elements.push(
            <div className={classes.voteContainer} key={`vote-inputs-${index}`}>
              <div className={classes.iconContainer}>
                <ThumbIcon color="secondary" fontSize="small" className={classes.icon} />
                <div className={classes.voteCaptionContainer}>
                  <Typography variant="caption" className={classes.voteCaption}>
                    {inputItem.votes}
                  </Typography>
                </div>
              </div>
              <Typography className={classes.inputItem} variant="body1">
                {`${inputItem.input}`}
              </Typography>
            </div>,
          );
        }
      });
      if (data[key].length > 3) {
        elements.push(
          <Button
            key={`vote-inputs-expand-cta-${key}`}
            variant="text"
            name={
              this.state.expandedQuestions.includes(key)
                ? 'vote-inputs-collapse'
                : 'vote-inputs-expand'
            }
            onClick={() => this.expandQuestion(key)}
          >
            {this.state.expandedQuestions.includes(key)
              ? t('dfac.collapsecta')
              : t('dfac.expandallcta')}
          </Button>,
        );
      }
    } else {
      elements.push(
        <HelperText key={`vote-inputs-${key}-no-answers`} name="no-answers-yet">
          {t('dfac.sessionnoanswerstovoteon')}
        </HelperText>,
      );
    }
    return elements;
  };

  expandQuestion = index => {
    let questionArray = [...this.state.expandedQuestions];
    if (questionArray.includes(index)) {
      questionArray = questionArray.filter(inputId => inputId !== index);
    } else {
      questionArray.push(index);
    }
    this.setState({ expandedQuestions: questionArray });
  };

  render() {
    const { classes, facilitationData, votes } = this.props;
    let questions = [];
    if (facilitationData.ok) {
      // eslint-disable-next-line prefer-destructuring
      questions = facilitationData.questions;
    }
    const votedInputsByQuestion = {};
    if (votes.ok) {
      Object.keys(votes.questions).forEach(questionKey => {
        votedInputsByQuestion[questionKey] = orderBy(
          votes.questions[questionKey],
          ['votes'],
          ['desc'],
        );
      });
    }
    return (
      <div name="live-dfac-panel-votes-list">
        {questions &&
          questions.map((question, i) => (
            <div
              className={clsx(classes.row, 'live-dfac-panel-question-item')}
              key={`live-dfac-panel-question-${i}`}
            >
              <Typography
                variant="h6"
                component="span"
                display="block"
                color="text.strong"
                sx={{ mb: 2 }}
              >
                {`${question}`}
              </Typography>
              {this.renderVotedInputs(i, votedInputsByQuestion)}
            </div>
          ))}
      </div>
    );
  }
}

DivShowVotes.propTypes = {
  facilitationData: PropTypes.object,
  votes: PropTypes.object,
  classes: PropTypes.exact({
    row: PropTypes.string,
    iconContainer: PropTypes.string,
    voteCaptionContainer: PropTypes.string,
    voteCaption: PropTypes.string,
    icon: PropTypes.string,
    voteContainer: PropTypes.string,
    inputItem: PropTypes.string,
    divider: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(DivShowVotes));
