import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import { withNavigation } from 'withRouter';

import CommonNotification from './CommonNotification';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class OutdatedOkrsNotification extends React.Component {
  handleClick = () => {
    this.props.navigate('/home/objectives');
  };

  render() {
    const { classes, message, key, t } = this.props;
    if (!!message) {
      return (
        <CommonNotification
          key={key}
          message={message}
          className={classes.root}
          onClick={this.handleClick}
        >
          <div>
            <Typography variant="subtitle2">{t('notifications.outdatedOkrs.title')}</Typography>
            <Typography variant="caption">{t('notifications.outdatedOkrs.cta')}</Typography>
          </div>
        </CommonNotification>
      );
    }
    return <div />;
  }
}

OutdatedOkrsNotification.propTypes = {
  message: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  key: PropTypes.string,
  navigate: PropTypes.func,
  t: PropTypes.func,
};

export default withNavigation(withTranslation()(withStyles(styles)(OutdatedOkrsNotification)));
