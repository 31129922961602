export const RECEIVED_PROGRAMS = 'PROGRAMS_RECEIVED';
export const FAILED_PROGRAMS = 'FAILED_PROGRAMS';
export const CREATE_PROGRAM = 'CREATE_PROGRAM';
export const PROGRAM_CREATED = 'PROGRAM_CREATED';
export const EDIT_PROGRAM = 'EDIT_PROGRAM';
export const PROGRAM_EDITED = 'PROGRAM_EDITED';
export const DISABLE_PROGRAM = 'DISABLE_PROGRAM';
export const PROGRAM_DISABLED = 'PROGRAM_DISABLED';
export const ENABLE_PROGRAM = 'ENABLE_PROGRAM';
export const PROGRAM_ENABLED = 'PROGRAM_ENABLED';
export const EDIT_PROGRAM_ROOT = 'EDIT_PROGRAM_ROOT';
export const PROGRAM_ROOT_EDITED = 'PROGRAM_ROOT_EDITED';
export const GET_PROGRAM_USERS = 'GET_PROGRAM_USERS';
export const RECEIVED_PROGRAM_USERS = 'RECEIVED_PROGRAM_USERS';
export const FAILED_PROGRAM_USERS = 'FAILED_PROGRAM_USERS';

export const ERROR_RECEIVED_FROM_API = 'PRG_API_ERROR_RECEIVED';
