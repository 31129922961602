import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  doneEffect: {
    animation: '$wiggle .9s ease-out',
  },
  '@keyframes wiggle': {
    '0%': { transform: 'scale(1)' },
    '25%': { transform: 'scale(1.1)' },
    '50%': { transform: 'scale(1)' },
    '75%': { transform: 'scale(1.3)' },
    '100%': { transform: 'scale(1)' },
  },
});

class StatusText extends Component {
  state = {
    animate: false,
    value: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {
      value: nextProps.value,
    };

    if (!!nextProps.value && !!nextProps.goal) {
      if (
        prevState.value !== nextProps.value &&
        nextProps.value >= nextProps.goal &&
        !prevState.animate
      ) {
        state.animate = true;
      } else {
        state.animate = false;
      }
    }
    return state;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.goal !== this.props.goal ||
      this.state.animate !== nextState.animate
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.animate && !!this.state.animate) {
      this.timeout = setTimeout(() => {
        this.setState({ animate: false });
      }, 1200);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { classes, className = null, variant, suffix = '' } = this.props;
    const { animate, value } = this.state;
    return (
      <Typography
        variant={variant}
        className={clsx(className, !!animate && classes.doneEffect)}
        component="span"
      >{`${value}${suffix}`}</Typography>
    );
  }
}

StatusText.propTypes = {
  goal: PropTypes.number,
  value: PropTypes.number,
  variant: PropTypes.string,
  suffix: PropTypes.string,
  classes: PropTypes.exact({
    doneEffect: PropTypes.string,
  }),
};

export default withStyles(styles)(StatusText);
