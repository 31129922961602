import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
/*
    react-beautiful-dnd does not support nested DnD contexts!

    This is a poor design decision, as the only place where
    onDragEnd can be handled is via a callback function passed
    to the DragDropContext - this would mean that whatever parent
    context would need to know how to handle the event for any
    children.

    This is a big issue for things such as game plans, where we
    want to be able to drag the widgets to reorder them, but also
    to drag elements within a widget.

    To keep separation of concerns, and allow the Forms owning the
    child contexts to handle their own ordering, we abstract this
    mess out.
*/

const RootDndContext = React.createContext();

class RootDndContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.childContexts = {};
  }

  registerChild = (id, ref) => {
    this.childContexts[id] = ref;
  };

  unregisterChild = id => {
    delete this.childContexts[id];
  };

  onDragEnd = result => {
    // Defer handling onDragEnd to the child context(s)
    this.childContexts[result.destination?.droppableId]?.onDragEnd({
      ...result,
      isDestination: true,
    });
    if (result.destination?.droppableId !== result.source.droppableId) {
      this.childContexts[result.source.droppableId]?.onDragEnd({ ...result, isDestination: false });
    }
  };

  render() {
    const { children } = this.props;
    return (
      <RootDndContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          registerChild: this.registerChild,
          unregisterChild: this.unregisterChild,
        }}
      >
        <DragDropContext onDragEnd={this.onDragEnd}>{children}</DragDropContext>
      </RootDndContext.Provider>
    );
  }
}
export default RootDndContextProvider;

export { RootDndContext };
