// eslint-disable-next-line import/prefer-default-export
import moment from 'moment';

const NO_STATUS = 'NO-STATUS';

export const prepareEditInterlockPayload = payload => {
  const { name, requestID, description, id, status, contributors, owner } = payload;
  const preparedPayload = {
    name,
    requestID,
    id,
    description: JSON.stringify(description),
    status,
    contributors,
    owner,
  };
  // eslint-disable-next-line camelcase
  let { due_date } = payload;

  // due_date needs to null or 0, not empty
  // eslint-disable-next-line camelcase
  if (!due_date) {
    // eslint-disable-next-line camelcase
    due_date = null;
  }

  // we need to add due_date to payload only if it was edited (may also be unset)
  // eslint-disable-next-line camelcase
  if (due_date || Object.prototype.hasOwnProperty.call(payload, 'due_date')) {
    // eslint-disable-next-line camelcase
    preparedPayload.due_date = due_date;
  }

  return preparedPayload;
};

/*
 * function to calculate actual duratiob (relative width for the timeline chart)
 * of each status change event
 * @param config - all the data neeeded for calcualtions:
 * index - index of status change from the array of status changes per contributor
 * columnTs - current timestamp for which we are calculating realtive width
 * contributorChartData - the whole array of chart status change events
 * startDateTs - date of creation of interlock
 * endDateTs - the end till which the timeline should be displayed
 * @return number from 0 to 100 - for relative width display in chart
 */
export const getColumnDurationPercentage = config => {
  const { index, columnTs, contributorChartData = [], startDateTs, endDateTs } = config;
  const duration = endDateTs - startDateTs;

  if (index === contributorChartData.length - 1) {
    const lastDayDate = moment.unix(endDateTs).isSameOrBefore(moment())
      ? moment.unix(endDateTs)
      : moment().add(1, 'day');
    return (lastDayDate.diff(moment.unix(columnTs), 'seconds') / duration) * 100;
  }

  return (
    (moment
      .unix(contributorChartData[index + 1]?.timestamp)
      .diff(moment.unix(columnTs), 'seconds') /
      duration) *
    100
  );
};

/*
 * function to transform data received from serve to the format
 * which can be used to draw timeline chart
 */
export const getPreparedChartData = (contributors, chartNodes, interlockData) => {
  const endDateTs = !!interlockData?.due_date
    ? interlockData?.due_date
    : moment().add(1, 'week').unix();
  const preparedChartDataWithTS = {};
  // create a proper data structure -> getting all contributor ids as key
  // all timeline events as array of data per contributor id
  chartNodes.forEach(chartNode => {
    chartNode.data.contributors.forEach(contributorsDataItem => {
      if (!preparedChartDataWithTS[contributorsDataItem.id]) {
        preparedChartDataWithTS[contributorsDataItem.id] = [];
      }
      if (moment.unix(chartNode.timestamp).isSameOrBefore(moment.unix(endDateTs))) {
        preparedChartDataWithTS[contributorsDataItem.id].push({
          status: contributorsDataItem.status,
          timestamp: chartNode.timestamp,
          id: chartNode.graph_id,
        });
      }
    });
  });

  // there is nothing between start date and first event on the timeline -
  // so addinf "NO_STATUS" in the end
  // and reversing the array to show the data chronologically
  Object.keys(preparedChartDataWithTS).forEach(contributorId => {
    const chartNodeData = preparedChartDataWithTS[contributorId];
    chartNodeData.push({
      status: NO_STATUS,
      timestamp: interlockData.created_on,
      id: `${contributorId}-blank`,
    });
    preparedChartDataWithTS[contributorId] = chartNodeData.reverse();
  });

  // getting durationPercentage per each chart event -
  // the width it will take on the graph
  const preparedChartDataWithDurations = {};
  Object.keys(preparedChartDataWithTS).forEach(contributorId => {
    let chartNodeData = preparedChartDataWithTS[contributorId];
    chartNodeData = chartNodeData.map((chartNodeDataItem, index) => {
      chartNodeDataItem.durationPercentage = getColumnDurationPercentage({
        index,
        columnTs: chartNodeDataItem.timestamp,
        contributorChartData: chartNodeData,
        startDateTs: interlockData.created_on,
        endDateTs,
      });
      return chartNodeDataItem;
    });
    preparedChartDataWithDurations[contributorId] = chartNodeData;
  });

  return preparedChartDataWithDurations;
};

/*
 * function to get all historical contributors to the status timeline
 * with their id and type - Team or User
 * @param  chartNodes - data as received from the api
 * @returns - array of objects with fields "id" and "type"
 */
export const getChartContributors = chartNodes => {
  const contributors = [];
  chartNodes.forEach(chartNode => {
    chartNode.data.contributors.forEach(contributor => {
      if (!contributors.some(c => c.id === contributor.id)) {
        contributors.push({
          id: contributor.id,
          type: contributor.type.toLowerCase(),
        });
      }
    });
  });
  return contributors;
};
