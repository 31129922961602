/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import HelperText from 'Components/Common/HelperText';
import { appstatusSelectors } from 'state/ducks/appstatus';
import { channel } from 'config/swbus';
import { Alert } from '@mui/material';
import { logout } from 'config/helpers';
import {
  getActiveSwVersion,
  getLastInstalledSwVersion,
  getLastDownloadedSwVersion,
  isActiveSwObsolete,
  compareSwVersion,
  forceSwDownload,
  serviceWorkerIsRunning,
  getRegistration,
  hardRefresh,
} from './helpers';

const versionRegexp = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])-\d+$/i;

export class UnwrappedServiceWorkerUpdateHandler extends Component {
  state = {
    newSwAvailable: false,
    newlyInstalledSwVersion: null,
  };

  componentDidMount() {
    const lastKnownInstalledVersion = getLastInstalledSwVersion();
    if (!lastKnownInstalledVersion) {
      // Handle possible breaking scenarios
      console.log('ServiceWorkerUpdateHandler - Last known installed version is unknown.');
      localStorage.setItem('last_installed_sw_ver', getActiveSwVersion());
    }
    // Initialize communications channel between SW and app
    channel.onmessage = data => this.onSwMessage(data);
    // Check if we have stored information about an update available in localstorage
    this.shouldShowUpdateDialog(getLastDownloadedSwVersion(), true);
    this.shouldShowUpdatedSnackbar(lastKnownInstalledVersion, true);
  }

  componentDidUpdate() {
    console.log('Debug - Updating');
    if (isActiveSwObsolete(this.props)) {
      const registration = getRegistration();
      if (!!registration) {
        console.log(
          'ServiceWorkerUpdateHandler - Forcing SW registration update - current version is outdated.',
        );
        forceSwDownload(registration);
      } else {
        console.log(
          'ServiceWorkerUpdateHandler - API returned higher SW version than the installed app version but no service worker registration was found.',
        );
      }
    }
  }

  shouldShowUpdateDialog = (availableVersion, forceUpdate = false, forceShow = false) => {
    // Determine whether to show the "please update" - dialog or silently force the update
    if (
      'serviceWorker' in navigator &&
      !!availableVersion &&
      (compareSwVersion(availableVersion) || forceShow)
    ) {
      if (!!forceUpdate || isActiveSwObsolete(this.props)) {
        console.log('ServiceWorkerUpdateHandler - Forcing download');
        this.forceSwUpdate();
      } else {
        console.log('ServiceWorkerUpdateHandler - Displaying ServiceWorker update dialog');
        this.setState({ newSwAvailable: true });
      }
    }
  };

  shouldShowUpdatedSnackbar = installedVersion => {
    // Determine whether to show the "version has been updated" -snackbar
    console.log(
      `ServiceWorkerUpdateHandler - shouldShowUpdatedSnackbar, recently installed version is : ${installedVersion}`,
    );
    if (serviceWorkerIsRunning && !!installedVersion && installedVersion !== getActiveSwVersion()) {
      console.log('ServiceWorkerUpdateHandler - Displaying ServiceWorker updated snackbar');
      localStorage.setItem('last_installed_sw_ver', process.env.REACT_APP_BUILD_VERSION);
      this.setState({ newlyInstalledSwVersion: process.env.REACT_APP_BUILD_VERSION });
    }
  };

  forceSwUpdate = () => {
    console.log('ServiceWorkerUpdateHandler - ServiceWorker update forced');
    channel.postMessage({ title: 'SKIP_WAITING' });
    this.setState({ newSwAvailable: false });
  };

  closeSwUpdateDialog = () => {
    this.setState({ newSwAvailable: false });
  };

  hideSnackbar = () => {
    this.setState({ newlyInstalledSwVersion: null });
  };

  onSwMessage = event => {
    console.log('ServiceWorkerUpdateHandler - Receiving event: ', event);
    if (event.title === 'SW_UPDATE_FOUND') {
      if (
        !!event.v &&
        versionRegexp.test(event.v) &&
        event.v > process.env.REACT_APP_BUILD_VERSION
      ) {
        console.log('ServiceWorkerUpdateHandler - ServiceWorker update available: ', event.v);
        const prevNotifiedVersion = getLastDownloadedSwVersion();
        if (!prevNotifiedVersion || compareSwVersion(event.v, prevNotifiedVersion) || event.force) {
          console.log('ServiceWorkerUpdateHandler - Storing available version');
          localStorage.setItem('last_offered_sw_version', event.v);
          this.shouldShowUpdateDialog(event.v, false, event.force);
        }
      }
    }
    if (event.title === 'SW_UPDATE_ACTIVATED') {
      if (
        !!navigator.serviceWorker &&
        !!navigator.serviceWorker.controller &&
        process.env.REACT_APP_BUILD_VERSION !== event.v
      ) {
        // Only reload if this was a SW update, not first time install
        console.log('ServiceWorkerUpdateHandler - ServiceWorker update activated: ', event.v);
        localStorage.setItem('last_installed_sw_ver', process.env.REACT_APP_BUILD_VERSION);
        hardRefresh();
      } else {
        console.log('ServiceWorkerUpdateHandler - Service worker taken into use');
      }
    }
  };

  render() {
    const { t, auth } = this.props;
    const { newSwAvailable, newlyInstalledSwVersion } = this.state;
    const isObsolete = isActiveSwObsolete(this.props);

    return (
      <>
        <Dialog open={newSwAvailable}>
          <DialogContent>
            {t('swUpdateHandler.swUpdateAvailableDialogTitle')}
            <br />
            <br />
            <HelperText>{t('swUpdateHandler.swAutoUpdateHint')}</HelperText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.closeSwUpdateDialog}
              name="sw-update-handler-dialog-later-btn"
            >
              {t('swUpdateHandler.laterCta')}
            </Button>
            <Button
              color="secondary"
              onClick={this.forceSwUpdate}
              name="sw-update-handler-dialog-update-btn"
            >
              {t('swUpdateHandler.updateCta')}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={newlyInstalledSwVersion}
          name="sw-update-handler-snackbar"
          message={t('swUpdateHandler.swUpdateDone', { version: newlyInstalledSwVersion })}
          autoHideDuration={6000}
          onClose={this.hideSnackbar}
        />
        <Snackbar open={isObsolete} name="sw-version-obsolete">
          <Alert
            variant="filled"
            elevation={6}
            severity="error"
            onClick={() =>
              logout(auth, {
                nextTenant: auth.tenantID,
                nextPath: window.location.pathname + window.location.hash,
                unregisterSw: true,
              })
            }
            sx={{
              cursor: 'pointer',
            }}
          >
            {t('swUpdateHandler.versionIsObsolete')}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

UnwrappedServiceWorkerUpdateHandler.propTypes = {
  t: PropTypes.func,
  // eslint is drunk, appstatus is used in isActiveSwObsolete
  // eslint-disable-next-line react/no-unused-prop-types
  appstatus: PropTypes.object,
  auth: PropTypes.object,
};

const mapStateToProps = state => ({
  appstatus: appstatusSelectors.selectStatus(state.main.appstatus),
  auth: state.auth,
});

export default connect(mapStateToProps)(withTranslation()(UnwrappedServiceWorkerUpdateHandler));
