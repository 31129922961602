export const FETCH_INTERLOCK = 'FETCH_INTERLOCK';

export const RECEIVED_INTERLOCK = 'RECEIVED_INTERLOCK';

export const FETCH_USER_INTERLOCKS = 'FETCH_USER_INTERLOCKS';
export const FETCH_RESOLVED_USER_INTERLOCKS = 'FETCH_RESOLVED_USER_INTERLOCKS';
export const RECEIVED_RESOLVED_USER_INTERLOCKS = 'RECEIVED_RESOLVED_USER_INTERLOCKS';
export const FAILED_RESOLVED_USER_INTERLOCKS = 'FAILED_RESOLVED_USER_INTERLOCKS';

export const RECEIVED_USER_INTERLOCKS = 'RECEIVED_USER_INTERLOCKS';
export const FAILED_USER_INTERLOCKS = 'FAILED_USER_INTERLOCKS';

export const FAILED_INTERLOCK = 'FAILED_INTERLOCK';
export const ADD_INTERLOCK = 'ADD_INTERLOCK';
export const ADDED_INTERLOCK = 'ADDED_INTERLOCK';
export const EDIT_INTERLOCK = 'EDIT_INTERLOCK';
export const INTERLOCK_UPDATED = 'INTERLOCK_UPDATED';

export const DELETE_INTELOCK = 'DELETE_INTELOCK';
export const INTERLOCK_DELETED = 'INTELOCK_DELETED';

export const FETCH_TEAM_INTERLOCKS = 'FETCH_TEAM_INTERLOCKS';
export const RECEIVED_TEAM_INTERLOCKS = 'RECEIVED_TEAM_INTERLOCKS';
export const FAILED_TEAM_INTERLOCKS = 'FAILED_TEAM_INTERLOCKS';
export const FETCH_RESOLVED_TEAM_INTERLOCKS = 'FETCH_RESOLVED_TEAM_INTERLOCKS';
export const FAILED_RESOLVED_TEAM_INTERLOCKS = 'FAILED_RESOLVED_TEAM_INTERLOCKS';
export const RECEIVED_RESOLVED_TEAM_INTERLOCKS = 'RECEIVED_RESOLVED_TEAM_INTERLOCKS';

export const FETCH_CONTRIBUTORS = 'FETCH_CONTRIBUTORS_INTERLOCKS';
export const RECEIVED_CONTRIBUTORS = 'RECEIVED_CONTRIBUTORS_INTERLOCKS';
export const FAILED_CONTRIBUTORS = 'FAILED_CONTRIBUTORS_INTERLOCKS';
export const CONTRIBUTORS_UPDATED = 'CONTRIBUTORS_UPDATED_INTERLOCKS';
export const EDIT_CONTRIBUTORS = 'EDIT_CONTRIBUTORS_INTERLOCKS';
export const EDIT_CONTRIBUTORS_STATUS = 'EDIT_CONTRIBUTORS_STATUS_INTERLOCKS';
export const CONTRIBUTORS_STATUS_UPDATED = 'CONTRIBUTORS_STATUS_UPDATED_INTERLOCKS';

export const FETCH_CONTRIBUTOR_CHART_INTERLOCKS = 'FETCH_CONTRIBUTOR_CHART_INTERLOCKS';
export const RECEIVED_CONTRIBUTOR_CHART_INTERLOCKS = 'RECEIVED_CONTRIBUTOR_CHART_INTERLOCKS';
export const FAILED_CONTRIBUTOR_CHART_INTERLOCKS = 'FAILED_CONTRIBUTOR_CHART_INTERLOCKS';

export const REFRESH_CONTRIBUTORS_CACHE = 'REFRESH_INTERLOCKS_CONTRIBUTORS_CACHE';

export const ERROR_RECEIVED_FROM_API = 'ERROR_RECEIVED_FROM_API';

export const SORTING_ORDERS = {
  asc: 'asc',
  desc: 'desc',
};
