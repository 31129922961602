export const GET_TEAM_FILTERS = 'GET_TEAM_FILTERS';
export const FETCHED_TEAM_FILTERS = 'FETCHED_TEAM_FILTERS';
export const FAILED_TEAM_FILTERS = 'FAILED_TEAM_FILTERS';

export const CREATE_TEAM_FILTER = 'CREATE_TEAM_FILTER';
export const CREATED_TEAM_FILTER = 'CREATED_TEAM_FILTER';

export const DELETE_TEAM_FILTER = 'DELETE_TEAM_FILTER';
export const TEAM_FILTER_DELETED = 'TEAM_FILTER_DELETED';

export const EDIT_TEAM_FILTER = 'EDIT_TEAM_FILTER';
export const TEAM_FILTER_EDITED = 'TEAM_FILTER_EDITED';

export const ERROR_RECEIVED_FROM_API = 'TEAM_FILTER_API_ERROR_RECEIVED';
