import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { orderBy, toArray } from 'lodash';
import Box from '@mui/material/Box';
import { programsSelectors } from 'state/ducks/programs';
import { connectionSelectors } from 'state/ducks/connection';
import { withNavigation, withLocation } from 'withRouter';
import LinkItem from './LinkItem';
import ExpandButton from './ExpandButton';
import AddButton from './AddButton';

class ProgramSelector extends Component {
  state = {
    expanded: true,
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  createProgram = () => {
    this.props.navigate('#modal/programs/create');
  };

  render() {
    const { classes, location, programs, strategyProgram, isChangeManager, isOnline, t } =
      this.props;
    const { expanded } = this.state;
    if (!programs || !strategyProgram) {
      return null;
    }

    // convert to array, filter out strategy program and sort by status, then name
    // (Disabled programs end up last)
    const processedPrograms = orderBy(
      toArray(programs).filter(prg => prg.type !== 'strategy'),
      ['status', p => p.name.toLowerCase()],
      ['desc', 'asc'],
    );

    const programLinks = [
      {
        linkText: strategyProgram.name,
        to: 'strategy',
        key: 'program-selector-menuitem-strategy',
        name: 'program-selector-menuitem',
        id: 'program-selector-menuitem-strategy',
      },
    ];

    for (const program of processedPrograms) {
      programLinks.push({
        linkText: program.name,
        to: `programs/${program.cpID}`,
        key: `program-selector-menuitem-${program.cpID}`,
        name: 'program-selector-menuitem',
        id: `program-selector-menuitem-${program.cpID}`,
      });
    }

    return (
      <div className={classes.selectorRoot}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
            {t('mainNavigation.sectionTitlePrograms')}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{
              height: 24,
              overflow: 'visible',
            }}
          >
            {isChangeManager && isOnline && (
              <AddButton
                onClick={this.createProgram}
                name="main-nav-add-program"
                tooltip={t('mainNavigation.addProgramCta')}
              />
            )}
            <ExpandButton
              expanded={expanded}
              onToggle={this.toggleExpanded}
              name="main-nav-toggle-expand-programs"
            />
          </Box>
        </Box>
        <List dense component="div">
          {programLinks.map(programProps => {
            if (expanded || location.pathname.includes(programProps.to)) {
              return <LinkItem {...programProps} />;
            }
            return null;
          })}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isChangeManager: state.auth.isChangeManager,
  programs: programsSelectors.selectProgramsData(state.main.programs),
  strategyProgram: programsSelectors.selectStrategy(state.main.programs),
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
});

ProgramSelector.propTypes = {
  navigate: PropTypes.func,
  location: PropTypes.object,
  programs: PropTypes.object,
  strategyProgram: PropTypes.object,
  isChangeManager: PropTypes.bool,
  isOnline: PropTypes.bool,
  t: PropTypes.func,
  classes: PropTypes.shape({
    selectorRoot: PropTypes.string,
  }),
};

export default withLocation(
  withNavigation(connect(mapStateToProps)(withTranslation()(ProgramSelector))),
);
