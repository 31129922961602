import * as types from './types';
import {
  IAddTagPayload,
  IDeleteTagPayload,
  IEditTagPayload,
  IGetInstanceTagsPayload,
  ILinkedTagNodesData,
  ILinkTagPayload,
  ITag,
} from './types';

export const getInstanceTags = (payload: IGetInstanceTagsPayload) => ({
  type: types.GET_INSTANCE_TAGS,
  payload,
});

export const instanceTagsFetchSuccess = (payload: Array<ITag>) => ({
  type: types.INSTANCE_TAGS_FETCH_SUCCESS,
  payload,
});

export const failedInstanceTags = payload => ({
  type: types.FAILED_INSTANCE_TAGS,
  payload,
});

export const getAllTags = () => ({
  type: types.GET_ALL_TAGS,
});

export const allTagsFetchSuccess = payload => ({
  type: types.ALL_TAGS_FETCH_SUCCESS,
  payload,
});

export const failedAllTags = payload => ({
  type: types.FAILED_ALL_TAGS,
  payload,
});

export const addTag = (payload: IAddTagPayload) => ({
  type: types.ADD_TAG,
  payload,
});

export const addedTag = (payload: ITag) => ({
  type: types.ADDED_TAG,
  payload,
});

export const deleteTag = (payload: IDeleteTagPayload) => ({
  type: types.DELETE_TAG,
  payload,
});

export const deletedTag = payload => ({
  type: types.DELETED_TAG,
  payload,
});

export const editTag = (payload: IEditTagPayload) => ({
  type: types.EDIT_TAG,
  payload,
});

export const editedTag = (payload: ITag) => ({
  type: types.EDITED_TAG,
  payload,
});

export const linkTag = (payload: ILinkTagPayload) => ({
  type: types.LINK_TAG,
  payload,
});

export const linkedTag = (payload: ILinkedTagNodesData) => ({
  type: types.LINKED_TAG,
  payload,
});

export const unlinkTag = (payload: ILinkTagPayload) => ({
  type: types.UNLINK_TAG,
  payload,
});

export const unlinkedTag = (payload: ILinkedTagNodesData) => ({
  type: types.UNLINKED_TAG,
  payload,
});

export const fetchLinkedToTag = (payload: IGetInstanceTagsPayload) => ({
  type: types.GET_LINKED_TAGS,
  payload,
});

export const linkedTagsFetchSuccess = (payload: Array<ITag>) => ({
  type: types.LINKED_TAGS_FETCH_SUCCESS,
  payload,
});

export const failedLinkedTags = payload => ({
  type: types.FAILED_LINKED_TAGS,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API_TAGS,
  payload,
});
