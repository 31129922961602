import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { nanoid } from 'nanoid';
import { insightsSelectors, insightsActions } from 'state/ducks/insights';
import { peopleSelectors } from 'state/ducks/people';
import { programsSelectors } from 'state/ducks/programs';
import { connectionSelectors } from 'state/ducks/connection';

import PanelViewInterpretation from './PanelViewInterpretation';

export class PanelViewInterpretationContainer extends Component {
  state = {
    answers: [],
    answersSubmitted: [],
    trafficLightSelection: null,
    trafficLightSelectionSubmitted: null,
    requestID: null,
    submitStatus: 0,
    submitted: false,
  };

  componentDidMount() {
    this.update();
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.submitted === true) {
      if (state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[state.requestID].result === 'ok') {
          return {
            submitStatus: 1,
            submitted: false,
          };
        }
        return {
          submitStatus: -1,
          submitted: false,
        };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!prevState.submitted && !this.state.submitted) {
      if (this.state.submitStatus === 1) {
        // done
        this.onClose();
      }
    }
    if (this.props.open !== prevProps.open || (this.props.allstats.ok && !prevProps.allstats.ok)) {
      /* derive state if the panel was toggled or we just received the stats for this topic */
      this.update();
    }
  }

  update = () => {
    const { canEdit, stats, topic } = this.props;
    const state = { answers: [], answersSubmitted: [], trafficLightSelection: null };

    if ((stats || canEdit) && topic && topic.ok) {
      for (let i = 0; i < topic.questions.length; i++) {
        state.answers.push({ q: topic.questions[i], a: stats ? stats.answers[i] : '' });
        state.answersSubmitted.push({ q: topic.questions[i], a: stats ? stats.answers[i] : '' });
      }
      if (topic.trafficLightsEnabled && stats && !!stats.trafficLightSelection) {
        state.trafficLightSelection = stats.trafficLightSelection;
        state.trafficLightSelectionSubmitted = stats.trafficLightSelection;
      }
    }

    this.setState(state);
  };

  onChange = (index, e) => {
    const answers = [...this.state.answers];
    answers[index].a = e.target.value;
    this.setState({ answers });
  };

  onTrafficLightChange = event => {
    this.setState({ trafficLightSelection: event.target.value });
  };

  handleSubmit = () => {
    const answers = [];
    for (const qa of this.state.answers) {
      answers.push(qa.a);
    }
    const requestID = nanoid(10);
    const payload = {
      csiID: this.props.csiID,
      teamID: this.props.teamId,
      answers,
      requestID,
    };
    if (this.props.topic.trafficLightsEnabled) {
      payload.trafficLightSelection = this.state.trafficLightSelection;
    }
    this.setState({
      submitted: true,
      requestID,
      answersSubmitted: this.state.answers,
      trafficLightSelectionSubmitted: this.state.trafficLightSelectionSubmitted,
    });
    this.props.dispatch(insightsActions.replyToInterpretation(payload));
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { open, teamId, teamName, canEdit, topic, isOnline, zIndexModifier } = this.props;
    if (!this.state.answers) {
      return null;
    }
    return (
      <PanelViewInterpretation
        open={open}
        teamName={teamName}
        teamId={teamId}
        onClose={this.onClose}
        topic={topic}
        canEdit={canEdit && isOnline}
        canSubmit={canEdit}
        handleSubmit={this.handleSubmit}
        onChange={this.onChange}
        onTrafficLightChange={this.onTrafficLightChange}
        answers={this.state.answers}
        trafficLightSelection={this.state.trafficLightSelection}
        answersSubmitted={this.state.answersSubmitted}
        trafficLightSelectionSubmitted={this.state.trafficLightSelectionSubmitted}
        requestID={this.state.requestID}
        submitStatus={this.state.submitStatus}
        submitted={this.state.submitted}
        zIndexModifier={zIndexModifier}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let rootTeamId = null;

  let stats;
  let parentstats;
  let topic;
  let canEdit = false;

  if (!!ownProps.csiID) {
    const parts = ownProps.csiID.split('_');
    const programID = parts[1];

    const program = programsSelectors.selectProgramById(state.main.programs, programID);
    if (!!program) {
      if (program.type !== 'engagement' && program.type !== 'strategy') {
        ({ rootTeamId } = program);
      }
    }

    let isRoot = false;

    if (
      (!!rootTeamId && rootTeamId === ownProps.teamId) ||
      !peopleSelectors.selectParentTeam(state.main.people, ownProps.teamId)
    ) {
      isRoot = true;
    }

    stats = insightsSelectors.selectInterpretationStatsForTeam(
      state.main.insights,
      ownProps.csiID,
      ownProps.teamId,
    );
    parentstats = insightsSelectors.selectInterpretationStatsForTeam(
      state.main.insights,
      ownProps.csiID,
      peopleSelectors.selectParentTeam(state.main.people, ownProps.teamId),
    );
    topic = insightsSelectors.selectInterpretationTopic(state.main.insights, ownProps.csiID);

    // Can edit if the parent team has answered or this is the root team
    canEdit =
      peopleSelectors.selectTeamManager(state.main.people, ownProps.teamId) === state.auth.userID &&
      (!!parentstats || isRoot);
  }

  return {
    teamName: state.main.people.teamNames[ownProps.teamId],
    canEdit,
    allstats: insightsSelectors.selectInterpretationStats(state.main.insights, ownProps.csiID),
    stats,
    topic,
    actionlog: state.main.insights.actionlog,
    isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
  };
};

PanelViewInterpretationContainer.propTypes = {
  actionlog: PropTypes.object,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  stats: PropTypes.object,
  allstats: PropTypes.object,
  csiID: PropTypes.string,
  teamId: PropTypes.string,
  topic: PropTypes.object,
  teamName: PropTypes.string,
  canEdit: PropTypes.bool,
  isOnline: PropTypes.bool,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(PanelViewInterpretationContainer);
