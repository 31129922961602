import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import StandardDialog from 'Components/Library/StandardDialog';
import HelperText from 'Components/Common/HelperText';
import TeamSelector from 'Components/Library/TeamSelector';

import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';

import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';
import { peopleSelectors } from 'state/ducks/people';

const initialState = {
  selected: '',
  selectedTeam: null,
};

const styles = theme => ({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  box: {
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
    overflowX: 'hidden',
    '& label': {
      display: 'flex',
      marginRight: 0,
    },
  },
  label: {
    flexGrow: 10,
    minWidth: '1px', // fix ellipsis
    '& > *': {
      flexGrow: 10,
    },
  },
  subtitle1: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  helperText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class PanelParentSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    if (props.selected) {
      this.state.selected = props.selected;
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.selectedTeam && nextProps.initialTeamSelection) {
      return { selectedTeam: nextProps.initialTeamSelection };
    }
    return null;
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentDidUpdate() {
    this.fetchData(this.props);
  }

  onSelect(e) {
    this.setState({ selected: e.target.value });
  }

  fetchData = newProps => {
    const { dispatch, selected, open, appliedStPeriod } = newProps;
    if (open !== this.props.open) {
      this.setState({ selected });
    }
    if (open && !!appliedStPeriod) {
      dispatch(objectivesActions.getCompanyObjectives({ stperiod: appliedStPeriod }));

      if (!!this.state.selectedTeam) {
        dispatch(
          objectivesActions.getTeamObjectives({
            teamId: this.state.selectedTeam.id,
            stperiod: appliedStPeriod,
          }),
        );
      }
    }
  };

  renderCompanyObjectives = () => {
    const { classes, objectiveType, companyObjectives, selectObjective, t, stPeriodId } =
      this.props;
    const { selected } = this.state;
    if (objectiveType === 'personal') {
      return null; // Personal objective cannot be linked to company objectives
    }

    let content;

    if (companyObjectives.ok) {
      if (companyObjectives.data.length === 0) {
        content = (
          <Typography variant="caption">
            {t('objectives.parentselectormodalnocompanyobjectiveshint')}
          </Typography>
        );
      } else {
        content = [];
        for (const id of companyObjectives.data) {
          const objData = selectObjective(id);
          const obj = objData.data;
          if (!!stPeriodId || !obj.stperiod) {
            content.push(
              <div key={`panel-parentselector-${obj.objectiveID}`}>
                <FormControlLabel
                  value={obj.objectiveID}
                  control={
                    <Radio
                      color="secondary"
                      checked={selected === obj.objectiveID}
                      onChange={e => this.onSelect(e)}
                      id={`panel-parentselector-company-${obj.objectiveID}`}
                      name="select-company-objective-radio"
                    />
                  }
                  classes={{ label: classes.label }}
                  label={
                    <ObjectiveChip objectiveData={objData} color="grey" allowNavigate={false} />
                  }
                />
              </div>,
            );
          }
        }
      }
    } else {
      // Handle errors
      content = <Typography variant="caption">{t('general.loading')}</Typography>;
    }

    return (
      <>
        <Typography variant="subtitle1" className={classes.subtitle1}>
          {t('objectives.parentselectormodalcompanyobjectivessubtitle')}
        </Typography>
        <div className={classes.box} id="panel-parentselector-company">
          {content}
        </div>
      </>
    );
  };

  renderSelectedTeamObjectives = () => {
    const { classes, selectObjective, selectTeamObjectives, t, stPeriodId } = this.props;
    const { selected, selectedTeam } = this.state;

    let objectives;

    let content;
    if (!!selectedTeam) {
      objectives = selectTeamObjectives(selectedTeam.id);
      if (!!objectives && objectives.ok) {
        const filteredOkrs = [];
        for (const objectiveID of objectives.data) {
          const objData = selectObjective(objectiveID);
          const obj = objData.data;
          if (!!obj && (!!stPeriodId || !obj.stperiod)) {
            filteredOkrs.push(objData);
          }
        }
        if (filteredOkrs.length === 0) {
          content = (
            <Typography variant="caption">
              {t('objectives.parentselectormodalnoteamobjectiveshint', {
                teamname: selectedTeam.teamName,
              })}
            </Typography>
          );
        } else {
          content = [];
          for (const obj of filteredOkrs) {
            content.push(
              <div key={`panel-parentselector-selectteam-${obj.data.objectiveID}`}>
                <FormControlLabel
                  value={obj.data.objectiveID}
                  control={
                    <Radio
                      color="secondary"
                      checked={selected === obj.data.objectiveID}
                      onChange={e => this.onSelect(e)}
                      id={`panel-parentselector-selectteam-${obj.data.objectiveID}`}
                      name="selected-team-objective-radio"
                    />
                  }
                  classes={{ label: classes.label }}
                  label={<ObjectiveChip objectiveData={obj} color="grey" allowNavigate={false} />}
                />
              </div>,
            );
          }
        }
      } else {
        // Handle errors
        content = <Typography variant="caption">{t('general.loading')}</Typography>;
      }
    } else {
      content = <Typography variant="caption" />;
    }

    return (
      <>
        <Typography variant="subtitle1" className={classes.subtitle1}>
          {t('objectives.parentselectormodalanotherteamsubtitle')}
        </Typography>
        <div
          className={clsx([classes.box, 'panelParentSelectorTeamBox'])}
          id="panel-parentselector-select-team"
        >
          <TeamSelector
            label={null}
            placeholder={t('general.teamSearchLabel')}
            onSelect={this.selectTeam}
            value={selectedTeam}
            name="team-search-objectives-parentselector"
          />
          <br />
          {content}
        </div>
      </>
    );
  };

  selectTeam = team => {
    this.setState({ selectedTeam: team });
  };

  handleSubmit = () => {
    this.props.onSelect(this.state.selected);
  };

  render() {
    const { classes, open, t, selected, stPeriodId, appliedStPeriod, onCancel } = this.props;
    if (!appliedStPeriod) {
      return null;
    }
    return (
      <StandardDialog open={open} size="small" onBackdropClick={onCancel}>
        <DialogTitle id="simple-dialog-title">
          {t('objectives.parentSelectorModalOverline')}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {!stPeriodId && (
            <HelperText className={classes.helperText}>
              {t('objectives.parentSelectorLongTermHelperText')}
            </HelperText>
          )}
          <RadioGroup>
            {this.renderCompanyObjectives()}
            {this.renderSelectedTeamObjectives()}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button id="panel-parent-selector-cancel-btn" onClick={onCancel}>
            {t('general.cancel')}
          </Button>
          <Button
            id="panel-parent-selector-submit-btn"
            color="secondary"
            variant="contained"
            disabled={!this.state.selected || selected === this.state.selected}
            onClick={this.handleSubmit}
          >
            {t('objectives.parentSelectorModalSelectCta')}
          </Button>
        </DialogActions>
      </StandardDialog>
    );
  }
}

PanelParentSelector.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    box: PropTypes.string,
    label: PropTypes.string,
    subtitle1: PropTypes.string,
    helperText: PropTypes.string,
  }),
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSelect: PropTypes.func,
  t: PropTypes.func,
  companyObjectives: PropTypes.object,
  // eslint please, dispatch is very much used
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
  initialTeamSelection: PropTypes.object,
  appliedStPeriod: PropTypes.string,
  selectObjective: PropTypes.func,
  selectTeamObjectives: PropTypes.func,
  // The following props are used in mapStateToProps, eslint is stupid:
  // eslint-disable-next-line react/no-unused-prop-types
  ltPeriodId: PropTypes.string,
  stPeriodId: PropTypes.string,
  selected: PropTypes.string,
  objectiveType: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  /* Select the appropriate short term period ID for fetching
  potential linking targets.

  If the objective being parented is a short term objective,
  use the same period.

  If the objective is a long term objective, choose the first st
  period of that long term period, as when querying for okrs of
  a domain all long term okrs are returned for any short term period
  under said long term period.
  */
  let maptype;
  let mapid;
  let appliedStPeriod;
  let initialTeamSelection;

  if (ownProps.stPeriodId) {
    appliedStPeriod = ownProps.stPeriodId;
  } else if (ownProps.ltPeriodId) {
    appliedStPeriod = Object.keys(
      objectivesSelectors.selectPeriodConfig(state.main.objectives).data.periods[
        ownProps.ltPeriodId
      ].stperiods,
    )[0];
  }

  const companyObjectives = objectivesSelectors.selectCompanyObjectives(
    state.main.objectives,
    appliedStPeriod,
  );

  const typeStr = ownProps.objectiveID.substring(0, 2);
  if (typeStr === 'CO') {
    maptype = 'company';
  } else if (typeStr === 'TO') {
    maptype = 'team';
    // the teamId might have underscores
    const parts = ownProps.objectiveID.split('_').slice(1, -3); // cut out the prefix, period ids and id
    mapid = parts.join('_');
  } else if (typeStr === 'PO') {
    maptype = 'personal';
    mapid = ownProps.objectiveID.split('_')[1];
  }

  let teamId;
  if (maptype === 'team') {
    teamId = peopleSelectors.selectParentTeam(state.main.people, mapid);
  } else if (maptype === 'personal') {
    teamId = peopleSelectors.selectUsersTeam(state.main.people, mapid);
  }
  if (teamId) {
    initialTeamSelection = {
      type: 'team',
      id: teamId,
      text: peopleSelectors.selectTeamName(state.main.people, teamId),
    };
  }
  return {
    objectiveType: maptype,
    initialTeamSelection,
    companyObjectives,
    appliedStPeriod,
    selectTeamObjectives: tid =>
      objectivesSelectors.selectTeamObjectives(state.main.objectives, tid, appliedStPeriod),
    selectObjective: id => objectivesSelectors.selectObjective(state.main.objectives, id),
    parentTeams: state.main.people.parentTeams,
    ownTeamId: state.auth.teamID,
  };
};

export default withTranslation()(connect(mapStateToProps)(withStyles(styles)(PanelParentSelector)));
