import reducer from './reducers';

import * as tagsLogic from './logic';
import * as tagsTypes from './types';
import * as tagsActions from './actions';
import * as tagsSelectors from './selectors';

export { tagsLogic, tagsTypes, tagsActions, tagsSelectors };

export default reducer;
