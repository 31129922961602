export const FETCH_COMMITMENT = 'FETCH_COMMITMENT';
export const FAILED_COMMITMENT = 'FAILED_COMMITMENT';
export const RECEIVED_COMMITMENT = 'RECEIVED_COMMITMENT';

export const FETCH_CONTRIBUTORS = 'FETCH_CONTRIBUTORS_COMMITMENTS';
export const RECEIVED_CONTRIBUTORS = 'RECEIVED_CONTRIBUTORS_COMMITMENTS';
export const FAILED_CONTRIBUTORS = 'FAILED_CONTRIBUTORS_COMMITMENTS';
export const CONTRIBUTORS_UPDATED = 'CONTRIBUTORS_UPDATED_COMMITMENTS';
export const EDIT_CONTRIBUTORS = 'EDIT_CONTRIBUTORS_COMMITMENTS';

export const FETCH_USER_COMMITMENTS = 'FETCH_USER_COMMITMENTS';
export const RECEIVED_USER_COMMITMENTS = 'RECEIVED_USER_COMMITMENTS';
export const FAILED_USER_COMMITMENTS = 'FAILED_USER_COMMITMENTS';
export const FETCH_COMPLETED_USER_COMMITMENTS = 'FETCH_COMPLETED_USER_COMMITMENTS';
export const RECEIVED_COMPLETED_USER_COMMITMENTS = 'RECEIVED_COMPLETED_USER_COMMITMENTS';
export const FAILED_COMPLETED_USER_COMMITMENTS = 'FAILED_COMPLETED_USER_COMMITMENTS';

export const ADD_COMMITMENT = 'ADD_COMMITMENT';
export const ADDED_COMMITMENT = 'ADDED_COMMITMENT';

export const EDIT_COMMITMENT = 'EDIT_COMMITMENT';
export const COMMITMENT_UPDATED = 'COMMITMENT_UPDATED';
export const ERROR_RECEIVED_FROM_API = 'ERROR_RECEIVED_FROM_API';

export const DELETE_COMMITMENT = 'DELETE_COMMITMENT';
export const COMMITMENT_DELETED = 'COMMITMENT_DELETED';

export const FETCH_TEAM_COMMITMENTS = 'FETCH_TEAM_COMMITMENTS';
export const FAILED_TEAM_COMMITMENTS = 'FAILED_TEAM_COMMITMENTS';
export const RECEIVED_TEAM_COMMITMENTS = 'RECEIVED_TEAM_COMMITMENTS';
export const FETCH_COMPLETED_TEAM_COMMITMENTS = 'FETCH_COMPLETED_TEAM_COMMITMENTS';
export const FAILED_COMPLETED_TEAM_COMMITMENTS = 'FAILED_COMPLETED_TEAM_COMMITMENTS';
export const RECEIVED_COMPLETED_TEAM_COMMITMENTS = 'RECEIVED_COMPLETED_TEAM_COMMITMENTS';
