import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Box from '@mui/material/Box';

const styles = theme => ({
  section: {
    backgroundColor: theme.palette.background.box,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
  },
});

const EmphasizedSection = React.forwardRef((props, ref) => {
  const { className, classes, children, name, id, sx = {}, ...rest } = props;

  return (
    <Box
      className={clsx(classes.section, className)}
      ref={ref}
      name={name}
      id={id}
      sx={{
        ...{
          p: 3,
          borderRadius: 1,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
});

EmphasizedSection.propTypes = {
  classes: PropTypes.exact({
    section: PropTypes.string,
  }),
  name: PropTypes.string,
  id: PropTypes.string,
  sx: PropTypes.object,
};

export default withStyles(styles)(EmphasizedSection);
