import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotificationsPanel from './NotificationsPanel';

class NotificationsPanelContainer extends Component {
  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <NotificationsPanel
        open={this.props.open}
        onClose={this.onClose}
        inbox={this.props.inbox}
        zIndexModifier={this.props.zIndexModifier}
      />
    );
  }
}

const mapStateToProps = state => ({
  inbox: state.main.inbox && state.main.inbox.inbox,
});

NotificationsPanelContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  inbox: PropTypes.array,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(NotificationsPanelContainer);
