import reducer from './reducers';

import * as teamFilterLogics from './logic';
import * as teamFilterTypes from './types';
import * as teamFilterActions from './actions';
import * as teamFilterSelectors from './selectors';

export { teamFilterLogics, teamFilterTypes, teamFilterActions, teamFilterSelectors };

export default reducer;
