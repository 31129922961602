import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const styles = theme => ({
  navLink: {
    ...theme.shape,
    display: 'block',
    padding: '0px 8px',
    height: 32,
    transition: 'all 0.125s ease',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
    '&:focus': {
      backgroundColor: theme.palette.action.focus,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
        height: 32,
        lineHeight: '32px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    textDecoration: 'none', // storybook needs this to show navlinks similarily
  },
  listItem: {
    padding: 0,
    margin: 0,
    height: 32,
  },
  activeNavLink: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
      },
    },
  },
  iconContainer: {
    marginRight: theme.spacing(),
    minWidth: theme.spacing(2),
    float: 'left',
    '& svg': {
      fontSize: '16px',
    },
  },
  iconPlaceholder: {
    height: 16,
    minWidth: theme.spacing(1),
    float: 'left',
  },
});

class LinkItem extends Component {
  render() {
    const {
      classes,
      id,
      to,
      linkText,
      linkIcon,
      statusIcon,
      onClick,
      name = 'mainmenu-navlink',
      customNavLinkStyles,
    } = this.props;
    let appliedIcon = <div className={classes.iconPlaceholder} />;
    if (!!linkIcon) {
      appliedIcon = <ListItemIcon className={classes.iconContainer}>{linkIcon}</ListItemIcon>;
    }
    const content = (
      <ListItem component="div" className={classes.listItem}>
        {appliedIcon}
        <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }} primary={linkText} />
        {statusIcon}
      </ListItem>
    );
    if (!!to) {
      return (
        <NavLink
          name={name}
          id={id}
          to={to}
          className={({ isActive }) =>
            isActive
              ? clsx(classes.activeNavLink, classes.navLink, customNavLinkStyles)
              : clsx(classes.navLink, customNavLinkStyles)
          }
        >
          {content}
        </NavLink>
      );
    }
    return (
      <div
        onClick={onClick}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={clsx(classes.navLink, customNavLinkStyles)}
        role="button"
        name={name}
        id={id}
      >
        {content}
      </div>
    );
  }
}

LinkItem.propTypes = {
  classes: PropTypes.exact({
    navLink: PropTypes.string,
    activeNavLink: PropTypes.string,
    listItem: PropTypes.string,
    listItemText: PropTypes.string,
    listItemTextSmall: PropTypes.string,
    iconContainer: PropTypes.string,
    iconPlaceholder: PropTypes.string,
  }),
  to: PropTypes.string,
  onClick: PropTypes.func,
  linkText: PropTypes.string,
  linkIcon: PropTypes.object,
  statusIcon: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  customNavLinkStyles: PropTypes.string,
};

export default withStyles(styles)(LinkItem);
