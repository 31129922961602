import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ValuesIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
      <path
        d="M15.75 10.8225V4.5H8.25V10.8225C8.25 11.085 8.385 11.3325 8.6175 11.4675L11.7525 13.35L11.01 15.105L8.4525 15.3225L10.395 17.0025L9.8025 19.5L12 18.1725L14.1975 19.5L13.6125 17.0025L15.555 15.3225L12.9975 15.105L12.255 13.35L15.39 11.4675C15.615 11.3325 15.75 11.0925 15.75 10.8225ZM12.75 12.1725L12 12.6225L11.25 12.1725V5.25H12.75V12.1725Z"
        fill="black"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}

export default ValuesIcon;
