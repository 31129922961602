import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import * as actions from './actions';
import * as types from './types';

import { API_BASE_URL, ORG_API_BASE_URL } from '../../constants/api';

import * as selectors from './selectors';

const getPersonsFromAPI = function getPersonsFromAPIFunction(authToken, tenantID, payload) {
  return axios.get(`${API_BASE_URL}/${tenantID}/getperson`, {
    params: payload,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getPersonsLogic = createLogic({
  type: types.GET_PERSON,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    const subsToFetch = action.payload.subs.filter(
      sub =>
        !!sub &&
        shouldFetch(
          selectors.selectUser(state.main.people, sub),
          state.main.connection,
          !!action.payload && action.payload.force,
        ),
    );
    if (!!subsToFetch && subsToFetch.length > 0) {
      action.payload.subs = subsToFetch;
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    getPersonsFromAPI(getState().auth.tokens.access_token, getState().auth.tenantID, {
      subs: JSON.stringify(action.payload.subs),
    })
      .then(res => {
        dispatch(
          actions.personsReceived({ ...res.data.result, originalrequest: action.payload.subs }),
        );
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
          errorPayload.subs = action.payload.subs;
        }
        dispatch(actions.getPersonsFailed(errorPayload));
      })
      .then(() => done());
  },
});

export const getTeamLogic = createLogic({
  type: types.GET_TEAM,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      action.payload.teamId &&
      shouldFetch(
        selectors.selectTeamMembers(state.main.people, action.payload.teamId),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/getteammembers`, {
        params: { teamId: action.payload.teamId },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.teamReceived(res.data.result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch(actions.getTeamFailed(errorPayload));
      })
      .then(() => done());
  },
});

export const getListLogic = createLogic({
  type: types.GET_USER_LIST,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectList(state.main.people),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/listusers`, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.receivedList(res.data.result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch(actions.getListFailed(errorPayload));
      })
      .then(() => done());
  },
});

const searchPeopleFromAPI = function searchPeopleFromAPIFunction(authToken, tenantID, payload) {
  return axios.get(`${API_BASE_URL}/${tenantID}/searchusers`, {
    params: payload,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const searchPeopleLogic = createLogic({
  type: types.SEARCH_PEOPLE,
  latest: false,
  debounce: 250,
  process: async ({ getState, action }, dispatch, done) => {
    searchPeopleFromAPI(getState().auth.tokens.access_token, getState().auth.tenantID, {
      searchstring: action.payload.searchstring,
    })
      .then(res => {
        dispatch(
          actions.searchedPeopleReceived({
            people: res.data.result,
            requestID: action.payload.requestID,
          }),
        );
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

const getOrgHierarchyFromAPI = function getOrgHierarchyFromAPIFunction(authToken, tenantID) {
  return axios.get(`${ORG_API_BASE_URL}/${tenantID}/`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getOrgHierarchyLogic = createLogic({
  type: types.GET_ORG_HIERARCHY,
  latest: false,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectHierarchy(state.main.people),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState }, dispatch, done) => {
    getOrgHierarchyFromAPI(getState().auth.tokens.access_token, getState().auth.tenantID)
      .then(res => {
        dispatch(actions.hierarchyReceived(res.data));
      })
      .catch(() => {})
      .then(() => done());
  },
});

export const editPersonAttributesLogic = createLogic({
  type: types.EDIT_PERSON_ATTRIBUTES,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/edituser`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.personAttributesEdited(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const createPersonLogic = createLogic({
  type: types.CREATE_PERSON,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/createuser`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.personCreated(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const createTeamLogic = createLogic({
  type: types.CREATE_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/createteam`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.teamCreated(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const getPermissionsLogic = createLogic({
  type: types.GET_USER_PERMISSIONS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectPermissions(state.main.people, action.payload.sub),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/getuserpermissions`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.receivedUserPermissions({ ...res.data.result, sub: action.payload.sub }));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch(actions.getUserPermissionsFailed(errorPayload));
      })
      .then(() => done());
  },
});

export const editPermissionsLogic = createLogic({
  type: types.EDIT_USER_PERMISSIONS,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/edituserpermissions`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.editedUserPermissions(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const setAvatarLogic = createLogic({
  type: types.SET_AVATAR,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/setavatar`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.avatarSet(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const editUsersTeamLogic = createLogic({
  type: types.EDIT_USERS_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/moveuser`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.editedUserTeam(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const detachUsersMatrixTeamLogic = createLogic({
  type: types.DETACH_USER_MATRIX_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/unsetmatrixteam`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.detachedMatrixTeam(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const editUsersMatrixTeamLogic = createLogic({
  type: types.EDIT_USERS_MATRIX_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/setmatrixteam`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.editedUserMatrixTeam(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const editTeamManagerLogic = createLogic({
  type: types.EDIT_TEAM_MANAGER,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/editteammanager`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.editedTeamManager(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const editCeoLogic = createLogic({
  type: types.EDIT_CEO,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/makeceo`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.editedCeo(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const editTeamParentLogic = createLogic({
  type: types.EDIT_TEAM_PARENT,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/editteamparent`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.editedTeamParent(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const editTeamNameLogic = createLogic({
  type: types.EDIT_TEAM_NAME,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/editteamname`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.editedTeamName(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteTeamLogic = createLogic({
  type: types.DELETE_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/deleteteam`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.deletedTeam(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteUserLogic = createLogic({
  type: types.DELETE_USER,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/deleteuser`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.deletedUser(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const getMatricesLogic = createLogic({
  type: types.GET_MATRICES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectMatrices(state.main.people),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/listmatrices`, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.matricesReceived(res.data.result));
        res.data.result.forEach(matrix => {
          dispatch(actions.getMatrixHierarchy({ matrixId: matrix.id }));
        });
      })
      .catch(() => {})
      .then(() => done());
  },
});

export const getMatrixHierarchyLogic = createLogic({
  type: types.GET_MATRIX_HIERARCHY,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectMatrixHierarchy(state.main.people, action.payload.matrixId),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/getmatrixhierarchy`, {
        params: { matrixId: action.payload.matrixId },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.matrixHierarchyReceived(res.data.result));
      })
      .catch(() => {})
      .then(() => done());
  },
});

/* Follows */

export const getFollowedTeamsLogic = createLogic({
  type: types.GET_FOLLOWED_TEAMS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectFollowedTeams(state.main.people),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/listfollowedteams`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch({ type: types.FOLLOWED_TEAMS_RECEIVED, payload: res.data.result });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch({ type: types.FOLLOWED_TEAMS_FAILED, payload: errorPayload });
      })
      .then(() => done());
  },
});

export const followTeamLogic = createLogic({
  type: types.FOLLOW_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/followteam`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch({ type: types.FOLLOWED_TEAM, payload: result });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const unfollowTeamLogic = createLogic({
  type: types.UNFOLLOW_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/unfollowteam`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch({ type: types.UNFOLLOWED_TEAM, payload: result });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

/* Freely joinable/invitable teams */

export const getMyJoinRequestsLogic = createLogic({
  type: types.GET_MY_JOIN_REQUESTS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectMyJoinRequests(state.main.people),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/listmyjoinrequests`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch({ type: types.MY_JOIN_REQUESTS_RECEIVED, payload: res.data.result });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch({ type: types.MY_JOIN_REQUESTS_FAILED, payload: errorPayload });
      })
      .then(() => done());
  },
});

export const getTeamJoinRequestsLogic = createLogic({
  type: types.GET_TEAM_JOIN_REQUESTS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectTeamJoinRequests(state.main.people, action.payload.teamID),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/listteamjoinrequests`, {
        params: { teamID: action.payload.teamID },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch({ type: types.TEAM_JOIN_REQUESTS_RECEIVED, payload: res.data.result });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch({ type: types.TEAM_JOIN_REQUESTS_FAILED, payload: errorPayload });
      })
      .then(() => done());
  },
});

export const leaveTeamLogic = createLogic({
  type: types.LEAVE_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/leaveteam`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch({
          type: types.LEFT_TEAM,
          payload: { ...result, sub: getState().auth.userID },
        });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const requestToJoinTeamLogic = createLogic({
  type: types.REQUEST_TO_JOIN_TEAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/requestteamjoin`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch({
          type: types.REQUESTED_TO_JOIN_TEAM,
          payload: result,
        });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const approveJoinRequestLogic = createLogic({
  type: types.APPROVE_JOIN_REQUEST,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/approvejoinrequest`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch({
          type: types.APPROVED_JOIN_REQUEST,
          payload: result,
        });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const denyJoinRequestLogic = createLogic({
  type: types.DENY_JOIN_REQUEST,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/denyjoinrequest`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch({
          type: types.DENIED_JOIN_REQUEST,
          payload: result,
        });
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});
