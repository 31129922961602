// eslint-disable-next-line import/prefer-default-export
export const sortAndSliceItemsToShow = (items, columnToSortBy, numberOfItems = 5) => {
  if (!items.length) {
    return items;
  }
  if (!items[0][columnToSortBy]) {
    throw new Error(`Invalid columnToSortBy value: ${columnToSortBy}`);
  }
  const sortedItems = items.sort((a, b) => b[columnToSortBy] - a[columnToSortBy]);
  if (numberOfItems === -1) {
    return sortedItems;
  }
  return sortedItems.slice(0, numberOfItems);
};
