import { enrich } from 'state/helpers';

export function selectIntegrations(slice) {
  if (!!slice.integrations && !!slice.integrations.fetchStatus) {
    return slice.integrations;
  }
  return enrich();
}

export default selectIntegrations;
