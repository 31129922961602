import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import Popover from '@mui/material/Popover';

import { objectivesSelectors } from 'state/ducks/objectives';

import DataObjectChip from 'Components/Library/DataObjectChip';
import SearchOverlay from './SearchOverlay';

const styles = () => ({
  searchPopoverPaper: {
    marginTop: -2, // Align the search input text vertically with the chip text
  },
});

function PeriodSelector(props) {
  const {
    classes,
    value,
    onSelect,
    color,
    filterFunc,
    disabled,
    showPast,
    name,
    IconComponent = null,
    variant,
    sx,
  } = props;

  const selectedPeriodName = useSelector(state =>
    objectivesSelectors.selectPeriodName(state.main.objectives, value),
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const openSearch = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const onSelectHandler = selected => {
    closeSearch();
    onSelect(selected);
  };

  return (
    <>
      <DataObjectChip
        color={color}
        text={selectedPeriodName}
        actionable={!disabled}
        onClick={openSearch}
        statusComponent={
          !disabled && IconComponent && <IconComponent fontSize="13px" color="action" />
        }
        actOnDownArrow
        name={name}
        variant={variant}
        id="period-selector"
        sx={sx}
      />
      <Popover
        anchorEl={anchorEl}
        open={open}
        disableAutoFocus
        onClose={closeSearch}
        classes={{
          paper: classes.searchPopoverPaper,
        }}
      >
        <SearchOverlay
          name={name}
          onSelect={onSelectHandler}
          filterFunc={filterFunc}
          showPast={showPast}
        />
      </Popover>
    </>
  );
}

PeriodSelector.propTypes = {
  classes: PropTypes.exact({
    searchPopoverPaper: PropTypes.string,
  }),
  value: PropTypes.object,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  variant: PropTypes.oneOf(['normal', 'small']),
  IconComponent: PropTypes.elementType,
  filterFunc: PropTypes.func,
  showPast: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  sx: PropTypes.object,
};

export default withStyles(styles)(PeriodSelector);
