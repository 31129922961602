import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { objectivesActions } from 'state/ducks/objectives';
import { domainToGraphId } from 'state/ducks/objectives/helpers';

function useObjectiveSorting(objectiveData, domain, period, uniqueContextId) {
  const dispatch = useDispatch();

  const prefix = 'okr-table-draggable';

  const onDragEnd = useCallback(
    result => {
      const { draggableId, destination, source } = result;
      if (!destination || !source) {
        return;
      }
      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;

      const objectiveID: string = draggableId.replace(`${prefix}-${uniqueContextId}-`, '');

      const order: Array<string> = objectiveData.data.map(d => d.data.objectiveID);
      order.splice(sourceIndex, 1);
      order.splice(destinationIndex, 0, objectiveID);

      const pl = {
        updates: [],
      };
      // convert domain ID to graph ID format
      const domainId = domainToGraphId(domain);

      order.forEach((objectiveID, index) => {
        pl.updates.push({
          objectiveID,
          positions: {
            [domainId]: { [period]: index },
          },
        });
      });

      dispatch(objectivesActions.sortDomainOkrs(pl));
    },
    [dispatch, domain, uniqueContextId, objectiveData, period],
  );

  const getDraggableId = useCallback(
    objectiveID => `${prefix}-${uniqueContextId}-${objectiveID}`,
    [prefix, uniqueContextId],
  );

  return { onDragEnd, getDraggableId, contextId: `${prefix}-${uniqueContextId}` };
}

export default useObjectiveSorting;
