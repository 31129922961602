import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import ButtonBase from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ArrowRight';
import Tooltip from '@mui/material/Tooltip';

const styles = theme => ({
  expandButton: {
    ...theme.shape,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0)',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: theme.palette.action.disabled,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.action.active,
      ...theme.shape,
    },
  },
  iconContainer: {
    padding: 4,
    width: 24,
    height: 24,
  },
  icon: {
    transition: 'transform 150ms',
    width: 24,
    height: 24,
  },
  expanded: {
    transform: 'rotate(90deg)',
  },
});

class ExpandButton extends Component {
  render() {
    const { t, name, classes, expanded, onToggle } = this.props;
    return (
      <Tooltip title={!expanded ? t('mainNavigation.viewAllCta') : t('mainNavigation.collapseCta')}>
        <ButtonBase centerRipple name={name} className={classes.expandButton} onClick={onToggle}>
          <div className={classes.iconContainer}>
            <ExpandMoreIcon className={clsx(classes.icon, expanded ? classes.expanded : null)} />
          </div>
        </ButtonBase>
      </Tooltip>
    );
  }
}

ExpandButton.propTypes = {
  classes: PropTypes.exact({
    expandButton: PropTypes.string,
    iconContainer: PropTypes.string,
    icon: PropTypes.string,
    expanded: PropTypes.string,
  }),
  onToggle: PropTypes.func,
  t: PropTypes.func,
  expanded: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default withTranslation()(withStyles(styles)(ExpandButton));
