import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import ProtectedImage from 'Components/Common/ProtectedImage';

function ImageElement({ item, widgetId, theme }) {
  const { src, id, version } = item;

  if (src) {
    // No data from API yet, in mocking mode
    // or not yet saved
    return (
      <img
        src={src}
        width="100%"
        alt="Card media"
        style={{ borderRadius: '10px', color: theme.palette.primary.main }}
      />
    );
  }
  return (
    <ProtectedImage
      filepath={!!item.version ? `${widgetId}_${id}_${version}.jpg` : `${widgetId}_${id}.jpg`}
      width="100%"
      alt="Card media"
      style={{ borderRadius: '10px', color: theme.palette.primary.main }}
    />
  );
}

ImageElement.propTypes = {
  widgetId: PropTypes.string,
  item: PropTypes.object,
  theme: PropTypes.object,
};

export default withStyles({}, { withTheme: true })(ImageElement);
