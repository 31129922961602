import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/AddCircle';
import DragIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DraggableElement({
  disabled,
  index,
  children,
  draggableId,
  isActive,
  onDelete,
  onAddClick,
}) {
  const { t } = useTranslation();

  if (disabled) {
    return <Box sx={{ p: 1, pb: 0 }}>{children}</Box>;
  }

  return (
    <Draggable
      key={`ctx-card-editor-item-${draggableId}`}
      draggableId={`ctx-card-editor-item-${draggableId}`}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          onClick={e => e.stopPropagation()}
        >
          <Box sx={{ p: 1, pb: 0 }}>
            <Box
              sx={{
                backgroundColor: isActive || snapshot.isDragging ? 'background.box' : 'none',
                position: 'relative',
                '& .draggable-element-actions': {
                  opacity: isActive || snapshot.isDragging ? 1 : 0,
                },
                '&:hover .draggable-element-actions': {
                  opacity: 1,
                },
                '&:focus-within .draggable-element-actions': {
                  opacity: 1,
                },
                '&:hover': {
                  backgroundColor: 'background.box',
                },
                '&:focus-within': {
                  backgroundColor: 'background.box',
                },
                borderRadius: '8px',
              }}
            >
              <Box
                className="draggable-element-actions"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: -64,
                  width: 64,
                  height: 32,
                  transform: 'translateY(-50%)',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  sx={{
                    display: 'flex',
                    width: '32px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    backgroundColor: 'background.box',
                  }}
                  aria-label={t('gameplans.addElement')}
                  name="gameplans-add-element"
                  onClick={e => {
                    e.stopPropagation();
                    onAddClick(e);
                  }}
                >
                  <AddIcon />
                </IconButton>
                <div {...provided.dragHandleProps} onClick={e => e.stopPropagation()}>
                  <DragIcon color="primary" />
                </div>
              </Box>
              <Box
                className="draggable-element-actions"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: -64,
                  width: 64,
                  height: 32,
                  transform: 'translateY(-50%)',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {' '}
                <IconButton
                  sx={{
                    display: 'flex',
                    width: '32px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    backgroundColor: 'background.box',
                  }}
                  name="gameplans-delete-element-button"
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(e);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              {children}
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
}

DraggableElement.propTypes = {
  index: PropTypes.number,
  isActive: PropTypes.bool,
  draggableId: PropTypes.string,
  onAddClick: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DraggableElement;
