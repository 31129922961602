import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Skeleton, Box, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { interlocksSelectors, interlocksActions } from 'state/ducks/interlocks';
import FeatureIcon from 'Components/Features/Interlocks/FeatureIcon';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import AddInterlockButton from 'Components/Features/Interlocks/AddInterlockButton';
import InterlockChip from 'Components/Features/Interlocks/InterlockChip';
import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import { sortAndSliceItemsToShow } from 'Components/Features/GamePlans/Widgets/utils';

function InterlocksWidget({ variant, canEdit, teamId, dragHandleProps, requestRemove, name }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(interlocksActions.fetchTeamInterlocks({ id: teamId }));
    dispatch(interlocksActions.fetchResolvedTeamInterlocks({ id: teamId }));
  }, [teamId]);

  const activeInterlocks = useSelector(
    state =>
      interlocksSelectors.selectTeamInterlocks(state.main.interlocks, teamId, {
        sortColumn: 'last_modified',
        sortOrder: SORTING_ORDERS.desc,
      }),
    // { stabilityCheck: 'never' },
  );

  const completedInterlocks = useSelector(
    state =>
      interlocksSelectors.selectResolvedTeamInterlocks(state.main.interlocks, teamId, {
        sortColumn: 'last_modified',
        sortOrder: SORTING_ORDERS.desc,
      }),
    // { stabilityCheck: 'never' },
  );

  const allInterlocksIds = activeInterlocks.data.concat(completedInterlocks.data) || [];

  const allInterlocks = useSelector(
    state =>
      allInterlocksIds.map(
        interlockId =>
          interlocksSelectors.selectInterlock(state.main.interlocks, interlockId)?.data,
      ),
    // { stabilityCheck: 'never' },
  );

  const isLoading =
    (!activeInterlocks.ok && activeInterlocks.loading) ||
    (!completedInterlocks.ok && completedInterlocks.loading);

  const isInteractive = variant === 'card' && canEdit;
  const isPresentationMode = variant === 'dialog';

  const allInterlocksSorted = sortAndSliceItemsToShow(
    allInterlocks,
    'last_modified',
    isPresentationMode ? -1 : 5,
  );

  return (
    <>
      <Box
        sx={{
          mt: 0,
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'stretch',
          height: '24px',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Stack direction="row" alignItems="center">
            <FeatureIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle2">{t('interlocks.pageTitle')}</Typography>
          </Stack>
          {isInteractive && (
            <Stack
              direction="row"
              spacing={1}
              className="gp-widget-actions"
              onClick={e => e.stopPropagation()}
            >
              <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                <DeleteIcon />
              </ContainedIconButton>
              <AddInterlockButton teamId={teamId} variant="contained" />
            </Stack>
          )}
        </Stack>
      </Box>
      <Box
        sx={{ ml: 2, mr: 2, p: 1, borderRadius: 1, backgroundColor: 'background.paper' }}
        className="widget-content"
      >
        <Stack direction="column" alignItems="stretch" spacing={1}>
          {allInterlocksSorted.map(interlock => (
            <InterlockChip
              ContextIcon={null}
              key={`interlocks-widget-${interlock.id}`}
              graphId={interlock.id}
              color="transparent"
              allowNavigate
            />
          ))}
          {isLoading && (
            <Skeleton height={80} variant="rounded" sx={{ mb: 2, bgcolor: 'background.paper' }} />
          )}
        </Stack>
      </Box>
      <Box sx={{ height: 16 }} {...dragHandleProps} />
    </>
  );
}

InterlocksWidget.propTypes = {
  teamId: PropTypes.string,
  dragHandleProps: PropTypes.object,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  name: PropTypes.string,
};

InterlocksWidget.defaultProps = {
  variant: 'card',
};

export default InterlocksWidget;
