import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import StandardConfirmationDialog from 'Components/Library/StandardConfirmationDialog';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';

const styles = () => ({
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
});

function DeleteFacilitationSessionDialog(props) {
  const { deleteSession, submitStatus, onClose, errorText, submitted, classes } = props;

  const { t } = useTranslation();

  return (
    <StandardConfirmationDialog
      title={t('general.confirmDeleteTitle')}
      open
      name="dialog-confirm-delete-session"
      closeOnBackDropClick
      secondaryAction={{
        label: t('general.cancel'),
        onClick: onClose,
      }}
      primaryAction={{
        label: t('general.confirmDelete'),
        onClick: deleteSession,
      }}
    >
      <SavingIndicator
        submitted={submitted}
        submitStatus={submitStatus}
        errorText={errorText}
        className={classes.savingIndicator}
      />
      {t('dfac.confirmDeleteText')}
    </StandardConfirmationDialog>
  );
}

DeleteFacilitationSessionDialog.propTypes = {
  onClose: PropTypes.func,
  deleteSession: PropTypes.func,
  submitStatus: PropTypes.number,
  submitted: PropTypes.bool,
  errorText: PropTypes.string,
  classes: PropTypes.exact({
    savingIndicator: PropTypes.string,
  }),
};

export default withStyles(styles)(DeleteFacilitationSessionDialog);
