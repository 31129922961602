import * as types from './types';

export const getTenant = payload => ({
  type: types.GET_TENANT,
  payload,
});

export const tenantFetchSuccess = payload => ({
  type: types.TENANT_FETCH_SUCCESS,
  payload,
});

export const tenantFetchFailed = payload => ({
  type: types.TENANT_FETCH_FAILURE,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
