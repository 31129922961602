import { orderBy, get, cloneDeep } from 'lodash';
import { createSelector } from 'reselect';
import { enrich } from '../../helpers';
import { domainToGraphId, parseObjectiveIDfromKeyresultID } from './helpers';

export function selectPeriodConfig(slice) {
  return slice.cadenceConfig || enrich();
}

export function selectLtPeriodForStPeriod(slice, stperiod) {
  const config = selectPeriodConfig(slice);
  if (config.ok && config.data.stperiodconfigs[stperiod]) {
    return config.data.stperiodconfigs[stperiod].ltperiod;
  }
  return null;
}

export function selectStPeriodCfg(slice, period) {
  const cadenceConfig = selectPeriodConfig(slice);
  if (!!cadenceConfig && !!cadenceConfig.ok) {
    if (!!cadenceConfig.data.stperiodconfigs[period]) {
      return cadenceConfig.data.stperiodconfigs[period];
    }
  }
  return null;
}

export function selectLtPeriodCfg(slice, period) {
  const cadenceConfig = selectPeriodConfig(slice);
  if (!!cadenceConfig && !!cadenceConfig.ok) {
    if (!!cadenceConfig.data.periods[period]) {
      return cadenceConfig.data.periods[period];
    }
  }
  return null;
}

export function selectStPeriodName(slice, config, stPeriodId) {
  if (!config && !!slice) config = selectPeriodConfig(slice);
  if (!!config && config.ok) {
    return get(config, `data.stperiodconfigs.${stPeriodId}.displayName`);
  }
  return null;
}

export function selectLtPeriodName(slice, config, ltPeriodId) {
  if (!config && !!slice) config = selectPeriodConfig(slice);
  if (!!config && config.ok) {
    return get(config, `data.periods.${ltPeriodId}.displayName`);
  }
  return null;
}

export function selectPeriodName(slice, periodObject) {
  if (!periodObject) {
    return '';
  }
  if (!periodObject.stPeriodId || periodObject.stPeriodId === 'undefined') {
    return selectLtPeriodName(slice, null, periodObject.ltPeriodId);
  }
  return selectStPeriodName(slice, null, periodObject.stPeriodId);
}

export function selectCurrentPeriod(slice) {
  const config = selectPeriodConfig(slice);
  if (config.ok && config.data.activeperiod) {
    return config.data.activeperiod.stperiod;
  }
  return null;
}

export function selectPeriodStage(slice, stperiod) {
  const config = selectPeriodConfig(slice);
  if (!config || !config.ok) {
    return null;
  }
  const currentPeriod = selectCurrentPeriod(slice);
  if (stperiod === currentPeriod) {
    return 'current';
  }
  const currentIndex = config.data.stperiods.indexOf(currentPeriod);
  const compIndex = config.data.stperiods.indexOf(stperiod);
  if (compIndex < currentIndex) {
    return 'past';
  }
  if (compIndex > currentIndex) {
    return 'future';
  }
  return null;
}

export function selectPeriodsAsSortedLists(slice) {
  const config = selectPeriodConfig(slice);
  if (!config || !config.ok) {
    return [];
  }
  const { periods } = config.data;
  const sortedPeriods = orderBy(
    Object.keys(periods).map(ltPeriodId => {
      const sortedStPeriods = orderBy(
        Object.keys(periods[ltPeriodId].stperiods).map(stPeriodId => ({
          ...periods[ltPeriodId].stperiods[stPeriodId],
          id: stPeriodId,
        })),
        ['periodStart'],
      );
      return { ...periods[ltPeriodId], id: ltPeriodId, sortedStPeriods };
    }),
    ['periodStart'],
  );
  return sortedPeriods;
}

export function selectSortedStPeriodIdList(slice) {
  const config = selectPeriodConfig(slice);
  if (config.ok) {
    // const current = config.data.activeperiod.stperiod;
    const sortedPeriods = selectPeriodsAsSortedLists(slice);
    const sortedStPeriods = sortedPeriods.reduce(
      (arr, ltPeriod) => arr.concat(ltPeriod.sortedStPeriods.map(stPeriod => stPeriod.id)),
      [],
    );
    return sortedStPeriods;
  }
  return null;
}

export function selectNextShortTermPeriod(slice, fromPeriod) {
  const compPeriod = !!fromPeriod ? fromPeriod : selectCurrentPeriod(slice);
  if (compPeriod) {
    const stPeriodList = selectSortedStPeriodIdList(slice);
    if (!!stPeriodList) {
      return stPeriodList[stPeriodList.indexOf(compPeriod) + 1];
    }
  }
  return null;
}

export function selectPrevShortTermPeriod(slice, fromPeriod) {
  const compPeriod = !!fromPeriod ? fromPeriod : selectCurrentPeriod(slice);
  if (compPeriod) {
    const stPeriodList = selectSortedStPeriodIdList(slice);
    if (!!stPeriodList) {
      return stPeriodList[stPeriodList.indexOf(compPeriod) - 1];
    }
  }
  return null;
}

export function selectBestStPeriodForObjective(slice, objectiveID) {
  if (!objectiveID) {
    return null;
  }
  const parts = objectiveID.split('_');
  // <TYPE_PREFIX>_<DOMAIN>_<LTPERIODID>_<STPERIODID>_<OBJECTIVEID>

  if (!!parts[3]) {
    // It's a short term objective
    return parts[3];
  }
  const ltperiodId = parts[2];
  const config = selectPeriodConfig(slice);
  if (config.ok) {
    // Find the best matching st period
    const currentPeriod = selectCurrentPeriod(slice);
    const currentLtPeriod = selectLtPeriodForStPeriod(slice, currentPeriod);
    if (currentLtPeriod === ltperiodId) {
      // lt period is ongoing, returning current'
      return currentPeriod;
    }
    const today = new Date().toISOString().substring(0, 10);
    if (ltperiodId in config.data.periods) {
      let stperiods = [];
      Object.keys(config.data.periods[ltperiodId].stperiods).forEach(key => {
        stperiods.push({ ...config.data.periods[ltperiodId].stperiods[key], stperiodid: key });
      });
      stperiods = orderBy(stperiods, ['periodStart'], ['asc']);
      if (today < config.data.periods[ltperiodId].periodStart) {
        // future period, return first stperiod
        return stperiods[0].stperiodid;
      }
      // past period, return last stperiod
      return stperiods[stperiods.length - 1].stperiodid;
    }
  }
  return null;
}

export function selectHierarchy(slice, period) {
  if (!!period && !!slice.cadences[period] && !!slice.cadences[period].hierarchy) {
    return slice.cadences[period].hierarchy;
  }

  return enrich();
}

export function selectObjective(slice, objectiveID) {
  if (!!objectiveID) {
    if (slice.objectiveData[objectiveID]) {
      return slice.objectiveData[objectiveID];
    }
    if (objectiveID in slice.graphIdToLegacyMap) {
      return slice.objectiveData[slice.graphIdToLegacyMap[objectiveID]];
    }
  }
  return enrich();
}

export function selectRelations(slice, rng, period) {
  if (!!rng && !!period && slice.cadences[period] && slice.cadences[period].links[rng]) {
    return slice.cadences[period].links[rng];
  }

  return enrich();
}

export function selectTeamObjectives(slice, team, period) {
  if (!period) {
    period = selectCurrentPeriod(slice);
  }
  if (!!slice.cadences[period] && slice.cadences[period].team[team]) {
    return slice.cadences[period].team[team];
  }
  return enrich();
}

export function selectCompanyObjectives(slice, period) {
  if (!period) {
    period = selectCurrentPeriod(slice);
  }
  if (!!slice.cadences[period] && !!slice.cadences[period].company) {
    return slice.cadences[period].company;
  }
  return enrich();
}

export function selectPersonalObjectives(slice, sub, period) {
  if (!period) {
    period = selectCurrentPeriod(slice);
  }
  if (!!slice.cadences[period] && !!slice.cadences[period].personal[sub]) {
    return slice.cadences[period].personal[sub];
  }
  return enrich();
}

export function selectRelatedObjectives(slice, sub, period) {
  if (!period) {
    period = selectCurrentPeriod(slice);
  }
  if (!!slice.cadences[period] && !!slice.cadences[period].related[sub]) {
    return slice.cadences[period].related[sub];
  }
  return enrich();
}

export function selectKeyresult(slice, keyresultID) {
  const objectiveID = parseObjectiveIDfromKeyresultID(keyresultID);
  const objectiveData = selectObjective(slice, objectiveID);

  const nodeId = keyresultID.split('_').pop();
  if (!!objectiveData && objectiveData.ok) {
    for (const kr of objectiveData.data.keyresults) {
      if (kr.keyresultID.endsWith(nodeId)) {
        return kr;
      }
    }
  }
  return null;
}

const readDomainOkrs = (state, domain, period) => {
  let d = {};
  switch (domain.t) {
    case 'company':
      d = cloneDeep(selectCompanyObjectives(state, period));
      break;
    case 'team':
      d = cloneDeep(selectTeamObjectives(state, domain.d, period));
      break;
    case 'personal':
      d = cloneDeep(selectRelatedObjectives(state, domain.d, period));
      break;
    default:
      break;
  }
  if (d?.ok) {
    d.data = d.data.map(objectiveID => selectObjective(state, objectiveID));
    const domainId = domainToGraphId(domain);
    // sort by defined order, then alphabetically

    const getPosition = okr => get(okr, ['data', 'positions', domainId, period], 999999);

    d.data = orderBy(d.data, [getPosition, 'data.objective'], ['asc', 'asc']);
  }
  return d;
};

export const selectOkrsForDomains = createSelector(
  [state => state, (state, period) => period, (state, period, domains) => domains],
  (state, period, domains) => domains.map(d => readDomainOkrs(state, d, period)),
);
