import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import FormCustomEditable from 'Components/Library/Forms/Elements/FormCustomEditable';
import FormSlider from 'Components/Library/Forms/Elements/FormSlider';
import Form from 'Components/Library/Forms/Form';
import Slider from 'Components/Common/TgDeltaSlider/MuiSliderPort';
import TgDeltaSlider from 'Components/Common/TgDeltaSlider';
import FormFreeText from './FormFreeText';

const styles = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  label: {
    width: `calc(50% - ${theme.spacing()})`,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  /* We need to override the styles of the slider as we're using tg-mui-range-slider
     and to be consistent with a potential delta slider on the same screen
   */
  thumb: {
    backgroundColor: theme.palette.secondary.main,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
  },
  rail: {
    backgroundColor: theme.palette.primary[400],
  },
});

class FormSliderReply extends React.Component {
  state = {
    schema: null,
    initialValues: {},
  };

  static getDerivedStateFromProps(nextProps) {
    const { values } = nextProps;
    if (!!values.sliders) {
      const state = {};
      state.schema = {
        type: 'object',
        properties: Object.keys(values.sliders).reduce((res, key) => {
          res[key] =
            values.sliders[key].type === 'basic'
              ? { type: 'number', minimum: 0, maximum: 100 }
              : { type: 'array', items: { type: 'number' } };
          return res;
        }, {}),
      };
      if (!!values.surveyReply) {
        // The user has already replied, use that for the initialvalues
        state.initialValues = values.surveyReply.score;
      } else {
        // Set the initial value for normal sliders to 50% and delta sliders to [33%,66%]
        state.initialValues = Object.keys(values.sliders).reduce((res, key) => {
          res[key] = values.sliders[key].type === 'basic' ? 50 : [33, 66];
          return res;
        }, {});
      }
      return state;
    }
    return null;
  }

  render() {
    const { classes, t, values } = this.props;
    const { schema, initialValues } = this.state;
    if (!schema) {
      return null;
    }

    return (
      <>
        <FormCustomEditable
          fieldName="sliders"
          render={parentFormConnectorProps => (
            /* The sliders object in the form schema is a bit iffy to work with
               Use a separate Form to flatten the schema, and connect it to the
               PanelReplyTopic form with a FormCustomEditable and some black magic
            */
            <Form
              name="dialog-reply-topic-slider-reply-form"
              schema={schema}
              customDispatchFunc={parentFormConnectorProps.onChange}
              debouncedAutoSubmit={10}
              initialValues={initialValues}
              preventSaveIncomplete
              submitActionCreator={v => {
                delete v.requestID;
                return v;
              }}
            >
              {values.sliders.map((slider, i) => (
                <div
                  className={classes.paper}
                  key={`panel-reply-topic-slider-${i}`}
                  id={`panel-reply-topic-slider-${i}`}
                  name="panel-reply-topic-slider"
                >
                  <Typography
                    variant="subtitle1"
                    paragraph
                    id={`panel-reply-topic-slider-question-${i}`}
                  >
                    {slider.question}
                  </Typography>
                  <div className={classes.row}>
                    <Typography
                      variant="caption"
                      className={classes.label}
                      id={`panel-reply-topic-slider-minlabel-${i}`}
                    >
                      {slider.minLabel}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.label}
                      id={`panel-reply-topic-slider-maxlabel-${i}`}
                    >
                      {slider.maxLabel}
                    </Typography>
                  </div>
                  <FormSlider
                    fieldName={i.toString()}
                    render={fieldProps =>
                      values.sliders[i].type === 'basic' ? (
                        <Slider
                          color="secondary"
                          {...fieldProps}
                          classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                          }}
                        />
                      ) : (
                        <TgDeltaSlider {...fieldProps} color="secondary" />
                      )
                    }
                  />
                </div>
              ))}
            </Form>
          )}
        />
        <FormFreeText
          audienceType={values.audienceType}
          label={
            !!values.opentextfieldlabel
              ? values.opentextfieldlabel
              : t('insights.defaultopencommentlabel')
          }
        />
      </>
    );
  }
}

FormSliderReply.propTypes = {
  values: PropTypes.object,
  classes: PropTypes.exact({
    row: PropTypes.string,
    label: PropTypes.string,
    paper: PropTypes.string,
    thumb: PropTypes.string,
    track: PropTypes.string,
    rail: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withStyles(styles)(withTranslation()(FormSliderReply));
