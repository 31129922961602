import React from 'react';
import { METRIC_IDS } from 'screens/Features/HnR/index';
import axios from 'axios';
// eslint-disable-next-line camelcase
import { API_HnR_URL } from 'state/constants/api';
import { getStore } from 'store';

import { useQueries } from '@tanstack/react-query';
import TeamHnR from 'screens/Features/HnR/TeamHnR';
import PropTypes from 'prop-types';

function TeamHnRHoc({ teamID }) {
  const state = getStore().getState();

  const { tenantID } = state.auth;
  const accessToken = state.auth.tokens.access_token;

  const headers = { headers: { Authorization: `Bearer ${accessToken}` } };

  const hnrQueries = useQueries({
    queries: Object.values(METRIC_IDS).map(metricID => ({
      queryKey: [`hnr-${teamID}`, metricID],
      queryFn: () =>
        // eslint-disable-next-line camelcase
        axios.get(`${API_HnR_URL}/${tenantID}/teammetric`, {
          params: { team_id: teamID, metric_type: metricID },
          ...headers,
        }),
    })),
  });

  return <TeamHnR hnrQueriesData={hnrQueries} />;
}

TeamHnRHoc.propTypes = {
  teamID: PropTypes.string,
};

export default TeamHnRHoc;
