import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TgProgress from 'Components/Common/TgProgress';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  fullHeight: {
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - ${theme.spacing(8)})`,
    },
  },
  desktopActions: {
    margin: theme.spacing(2),
  },
  progress: {
    margin: '0 auto',
  },
});

function mediaQueryWrapper(Component) {
  return function WrappedComponent(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <Component {...props} fullScreen={fullScreen} />;
  };
}

class ResponsiveDialog extends React.Component {
  onBackdropClick = e => {
    const { secondaryAction, closeOnBackDropClick = false } = this.props;
    if (closeOnBackDropClick && secondaryAction) {
      secondaryAction.onClick(e);
    }
  };

  render() {
    /*
      When using a ResponsiveDialog component, it is extremely important
      to pass the actions and title as props, and to not use MUI Dialog
      components such as DialogTitle / DialogContent, as those will break
      margins and paddings.
    */
    const {
      classes,
      maxWidth = 'sm',
      contentClass,
      titleVariant = 'h6',
      name = 'responsive-dialog',
      className,
      fullScreen = false, // for some reason this cannot be passed as true..
      open,
      children,
      title,
      primaryAction,
      secondaryAction,
      tertiaryAction,
      hideActions = false,
      disablePortal = false,
      progress = false,
      fullWidth = false,
      fullHeight = false,
    } = this.props;

    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={this.onBackdropClick}
        disablePortal={disablePortal}
        classes={{
          paper: clsx(
            [classes.root],
            !!className ? [className] : null,
            fullHeight ? [classes.fullHeight] : '',
          ),
        }}
      >
        {!!title && (
          <DialogTitle>
            <Typography component="span" variant={titleVariant}>
              {title}
            </Typography>
          </DialogTitle>
        )}
        {!!progress && <TgProgress className={this.props.classes.progress} />}
        <DialogContent className={clsx([contentClass])}>{children}</DialogContent>

        {!hideActions && (
          <DialogActions className={classes.desktopActions}>
            {!!secondaryAction && (
              <Button
                name={`${name}-secondary-action`}
                color="primary"
                disabled={secondaryAction.disabled}
                onClick={secondaryAction.onClick}
              >
                {secondaryAction.label}
              </Button>
            )}
            {!!tertiaryAction && (
              <Button
                name={`${name}-tertiary-action`}
                color="primary"
                disabled={tertiaryAction.disabled}
                onClick={tertiaryAction.onClick}
                variant="outlined"
              >
                {tertiaryAction.label}
              </Button>
            )}
            {!!primaryAction && (
              <Button
                name={`${name}-primary-action`}
                color="secondary"
                variant="contained"
                disabled={primaryAction.disabled}
                onClick={primaryAction.onClick}
              >
                {primaryAction.label}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

ResponsiveDialog.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    fullHeight: PropTypes.string,
    desktopActions: PropTypes.string,
    progress: PropTypes.string,
  }),
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  contentClass: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string,
  fullScreen: PropTypes.bool,
  title: PropTypes.string,
  primaryAction: PropTypes.object,
  secondaryAction: PropTypes.object,
  tertiaryAction: PropTypes.object,
  progress: PropTypes.bool,
  hideActions: PropTypes.bool,
  disablePortal: PropTypes.bool,
  closeOnBackDropClick: PropTypes.bool,
  titleVariant: PropTypes.string,
};

export default mediaQueryWrapper(withStyles(styles, { withTheme: true })(ResponsiveDialog));
