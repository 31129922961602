import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import NotificationsIcon from '@mui/icons-material/Notifications';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import StandardDialog from 'Components/Library/StandardDialog';

import UserIntegrations from 'screens/Features/Admin/IntegrationsView/UserIntegrations';

import ThemeTab from 'Components/Common/DialogUserSettings2/ThemeTab';
import { useMediaQuery, useTheme } from '@mui/material';
import { isEnabled } from 'config/flags';
import ProfileTab from './ProfileTab';
import LanguageTab from './LanguageTab';
import NotificationsTab from './NotificationsTab';
import { useSelector } from 'react-redux';

const styles = theme => ({
  menu: {
    backgroundColor: theme.palette.primary[200],
    flexBasis: 120,
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(3),
  },
  content: {
    flexBasis: 120,
    flexGrow: 3,
    padding: theme.spacing(3),
    minHeight: 480,
    overflowY: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
});

function DialogUserSettingsContainer({ classes, onClose }) {
  const { t } = useTranslation();

  const isProvisioned = useSelector(state => state.auth.isProvisioned);
  const [currentTab, setCurrentTab] = useState(isProvisioned ? 'profile' : 'language');

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const ListComponent = isXs ? Select : List;
  const listComponentProps = isXs
    ? {
        sx: {
          '& .MuiSelect-select': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& .MuiListItemIcon-root': { justifyContent: 'center' },
          },
          mt: 2,
        },
        fullWidth: true,
        onChange: e => setCurrentTab(e.target.value),
        value: currentTab,
        id: 'settings-dialog-page-select',
      }
    : {};

  const renderMenuItem = ({ value, Icon }) => {
    const content = (
      <>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={t(`usersettings.${value}.tabTitle`)} />
      </>
    );
    if (isXs) {
      return (
        <MenuItem value={value} name={`menu-${value}-opt`}>
          {content}
        </MenuItem>
      );
    }
    return (
      <ListItem
        name={`menu-${value}-opt`}
        key={value}
        onClick={() => setCurrentTab(value)}
        selected={currentTab === value}
        button
      >
        {content}
      </ListItem>
    );
  };

  return (
    <StandardDialog open size="medium" fixedHeight onBackdropClick={onClose}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box
          sx={{
            backgroundColor: 'action.hover',
            flexBasis: { xs: 'auto', sm: 120 },
            flexShrink: 0,
            flexGrow: 0,
            p: 3,
          }}
        >
          <Typography variant="h6">{t('usersettings.dialogHeader')}</Typography>
          <ListComponent {...listComponentProps}>
            {isProvisioned && renderMenuItem({ value: 'profile', Icon: ProfileIcon })}
            {isProvisioned && renderMenuItem({ value: 'notifications', Icon: NotificationsIcon })}
            {renderMenuItem({ value: 'language', Icon: LanguageIcon })}
            {isEnabled('DARKMODE') && renderMenuItem({ value: 'theme', Icon: RemoveRedEyeIcon })}
            {isProvisioned &&
              renderMenuItem({ value: 'integrations', Icon: IntegrationInstructionsIcon })}
          </ListComponent>
        </Box>

        <div className={classes.content}>
          {!isXs && (
            <Typography variant="h6" sx={{ mb: 3 }}>
              {t(`usersettings.${currentTab}.tabTitle`)}
            </Typography>
          )}
          {currentTab === 'profile' && <ProfileTab />}
          {currentTab === 'language' && <LanguageTab />}
          {currentTab === 'notifications' && <NotificationsTab />}
          {currentTab === 'theme' && <ThemeTab />}
          {currentTab === 'integrations' && <UserIntegrations />}
          <div className={classes.closeBtn}>
            <IconButton
              name="settings-dialog-close-btn"
              onClick={onClose}
              size="large"
              aria-label={t('general.close')}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Box>
    </StandardDialog>
  );
}

DialogUserSettingsContainer.propTypes = {
  classes: PropTypes.exact({
    wrapper: PropTypes.string,
    menu: PropTypes.string,
    content: PropTypes.string,
    closeBtn: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

export default withStyles(styles)(DialogUserSettingsContainer);
