import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';
import { withFormStatus } from '../FormContext';

const styles = theme => ({
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 5,
    width: '100%',
  },
  static: {
    height: 5,
    width: '100%',
  },
  errorTextContainer: {
    position: 'relative',
    zIndex: 9999,
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
    textAlign: 'center',
  },
  errorContent: {
    padding: '0px !important',
  },
  errorText: {
    padding: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: theme.palette.error.main,
    color: '#FFFFFF',
    fontSize: 14,
  },
});

class LinearIndicator extends Component {
  state = {
    showDone: false,
    showError: false,
  };

  componentDidUpdate(prevProps) {
    if (!!prevProps.submitting && !this.props.submitting) {
      if (this.props.submitSuccess) {
        // It's ok to use setState here, the checks we have ensure
        // this will not lead to an infinite loop..
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ showDone: true });
        this.successtimeout = setTimeout(() => {
          this.setState({ showDone: false });
        }, 750);
      } else if (this.props.apiError) {
        // It's ok to use setState here, the checks we have ensure
        // this will not lead to an infinite loop..
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ showError: true });
        this.errortimeout = setTimeout(() => {
          this.setState({ showError: false });
        }, 2500);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.errortimeout);
    clearTimeout(this.successtimeout);
  }

  render() {
    const {
      classes,
      apiErrorMessage = this.props.t('general.savingError'),
      apiError,
      submitting,
      submitSuccess,
      positioning = 'static',
    } = this.props;

    const { showDone, showError } = this.state;

    return (
      <div className={classes[positioning]}>
        <div>
          {submitting && (
            // saving
            <LinearProgress name="saving-indicator-saving" color="secondary" />
          )}
          {submitSuccess && !!showDone && (
            <LinearProgress
              name="saving-indicator-saved"
              color="secondary"
              variant="determinate"
              value={100}
            />
          )}
          {
            // max retries, error
            apiError && !!showError && (
              <div className={classes.errorTextContainer}>
                <Slide direction="down" in mountOnEnter unmountOnExit>
                  <Card name="saving-indicator-error-card">
                    <CardContent className={classes.errorContent}>
                      <Typography className={classes.errorText}>{apiErrorMessage}</Typography>
                    </CardContent>
                  </Card>
                </Slide>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

LinearIndicator.propTypes = {
  classes: PropTypes.exact({
    errorTextContainer: PropTypes.string.isRequired,
    errorContent: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
    absolute: PropTypes.string.isRequired,
    static: PropTypes.string.isRequired,
  }),
  submitting: PropTypes.bool,
  submitSuccess: PropTypes.bool,
  apiError: PropTypes.bool,
  apiErrorMessage: PropTypes.string,
  t: PropTypes.func,
  positioning: PropTypes.oneOf(['absolute', 'static']),
};

export default withFormStatus(withTranslation()(withStyles(styles)(LinearIndicator)));
