import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FormContext from '../FormContext';

const FormSlider = React.memo(props => {
  const { render, fieldName, value, formName, onFieldChange } = props;

  const fieldProps = {
    onChange: (e, v) => onFieldChange(fieldName, v),
    value,
    name: `${formName}-${fieldName}`,
  };
  return render(fieldProps);
});

function OuterFormSlider(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormSlider that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render, fieldName } = props;

  const { onFieldChange, values, formName, fieldErrors, showErrors } =
    React.useContext(FormContext);

  return (
    <FormSlider
      fieldName={fieldName}
      render={render}
      onFieldChange={onFieldChange}
      value={get(values, fieldName)}
      formName={formName}
      error={!!showErrors && !!fieldErrors && fieldName in fieldErrors}
      helperText={
        !!showErrors && !!fieldErrors && fieldName in fieldErrors ? fieldErrors[fieldName] : ''
      }
    />
  );
}

OuterFormSlider.propTypes = {
  render: PropTypes.func,
  fieldName: PropTypes.string,
};

export default OuterFormSlider;
