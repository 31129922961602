import React from 'react';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { Divider } from '@mui/material';
import { withLocation, withNavigation } from 'withRouter';

import PersonasAvatar from 'Components/Common/PersonasAvatar';
import DataObjectChip from 'Components/Library/DataObjectChip';

import * as apiconstants from 'state/constants/api';
import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';
import { peopleSelectors } from 'state/ducks/people';
import { openViewObjectivePanel } from 'config/ModalProvider/helpers';
import ObjectivesIcon from '../ObjectivesIcon';
import ObjectiveStatusChip from './ObjectiveStatusChip';
import KeyresultsList from '../KeyresultsList';

class ObjectiveChip extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    this.fetchData();
  }

  fetchData = () => {
    if (!!this.props.objectiveData && !!!this.props.objectiveData.ok && !!this.props.objectiveID) {
      this.props.dispatch(
        objectivesActions.getObjective({ objectiveIDs: [this.props.objectiveID] }),
      );
    }
  };

  onClick = e => {
    const { onClick, stperiod, objectiveID, bestPeriod, navigate, location, allowNavigate } =
      this.props;
    if (allowNavigate || !!onClick) {
      if (allowNavigate) {
        openViewObjectivePanel(
          !!stperiod ? stperiod : bestPeriod,
          objectiveID,
          false,
          navigate,
          location,
        );
      }
      if (!!onClick) {
        onClick(e);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    const {
      objectiveID,
      objectiveData,
      wrapText,
      color,
      statusComponent,
      allowNavigate,
      onClick,
      ContextIcon,
      sx,
      name,
      overline,
      domainName,
      periodName,
      placeholder,
      variant,
      contextIconProps,
      hideOwner,
      hideStatus,
      hideKr,
      iconWithOverline,
      raised,
      actionable,
      secondaryActions,
      canEdit,
    } = this.props;

    const isObjectiveDataMissing = !objectiveData || (!objectiveData.ok && !objectiveData.error);
    // TODO: this could be simplified in the future by refactoring so that the variant xl does not automatically launch the krs to show
    const shouldKeyResultsBeShown =
      variant === 'xl' && !hideKr && get(objectiveData, 'data.keyresults', []).length > 0;

    // increasing UX for search result as we already have text for objectives
    if (placeholder && isObjectiveDataMissing) {
      return (
        <DataObjectChip
          name={`objective-chip-${objectiveID}`}
          overline={null}
          sx={sx}
          onClick={this.onClick}
          actionable={actionable || allowNavigate || Boolean(onClick)}
          text={placeholder}
          color={color}
          wrapText={wrapText}
          dataChipType="okr-objective"
          icon={<PersonasAvatar sub="" size="xtiny" />}
          // It's not duplicate...:
          // eslint-disable-next-line react/jsx-no-duplicate-props
          Icon={ContextIcon}
          contextIconProps={contextIconProps}
          raised={raised}
          secondaryActions={secondaryActions}
        />
      );
    }

    if (isObjectiveDataMissing) {
      // Loading
      return <Skeleton variant="rounded" height={32} sx={sx} />;
    }

    if (objectiveData.fetchStatus === apiconstants.DELETED || objectiveData.error) {
      // The entire OKR has been deleted or something catastrophic is going on with the
      // API connection
      return null;
    }

    return (
      <>
        <DataObjectChip
          name={name ?? `objective-chip-${objectiveID}`}
          icon={
            hideOwner ? null : (
              <PersonasAvatar sub={get(objectiveData, 'data.owner')} size="xtiny" />
            )
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          overline={
            !!overline ? (
              <>
                {overline.domain && domainName}
                {overline.period && overline.domain && ' - '}
                {overline.period && periodName}
              </>
            ) : null
          }
          // It's not duplicate...:
          // eslint-disable-next-line react/jsx-no-duplicate-props
          Icon={ContextIcon}
          sx={sx}
          onClick={this.onClick}
          actionable={actionable || allowNavigate || Boolean(onClick)}
          text={get(objectiveData, 'data.objective')}
          color={color}
          dataChipType="okr-objective"
          statusComponent={
            hideStatus ? null : (
              <>
                <ObjectiveStatusChip objectiveData={objectiveData.data} />
                {!!statusComponent && statusComponent}
              </>
            )
          }
          variant={variant}
          wrapText={wrapText || variant === 'xl'}
          contextIconProps={contextIconProps}
          iconWithOverline={iconWithOverline}
          raised={raised}
          secondaryActions={secondaryActions}
        />
        {shouldKeyResultsBeShown && (
          <>
            <Divider />
            <KeyresultsList
              objectiveData={objectiveData}
              uniqueContextIdentifier={`okr-dialog-${objectiveID}`}
              emptyStateNode={null}
              canEditObjective={canEdit}
              keyresultChipProps={{
                wrapText: true,
                color: 'transparent',
                ContextIcon: null,
                sx: { flexGrow: 10 },
              }}
              stackProps={{
                spacing: 0,
              }}
              enableAddButton={false}
            />
          </>
        )}
      </>
    );
  }
}

ObjectiveChip.propTypes = {
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'normal', 'large', 'xl', 'xxl']),
  location: PropTypes.object,
  navigate: PropTypes.func,
  bestPeriod: PropTypes.string,
  dispatch: PropTypes.func,
  wrapText: PropTypes.bool,
  statusComponent: PropTypes.node,
  overline: PropTypes.object,
  domainName: PropTypes.string,
  periodName: PropTypes.string,
  ContextIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  stperiod: PropTypes.string,
  allowNavigate: PropTypes.bool,
  sx: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  hideOwner: PropTypes.bool,
  hideStatus: PropTypes.bool,
  hideKr: PropTypes.bool,
  contextIconProps: PropTypes.object,
  iconWithOverline: PropTypes.bool,
  raised: PropTypes.bool,
  actionable: PropTypes.bool,
  secondaryActions: PropTypes.array,
  canEdit: PropTypes.bool,
};

ObjectiveChip.defaultProps = {
  ContextIcon: ObjectivesIcon,
  hideOwner: false,
  hideStatus: false,
  hideKr: false,
  iconWithOverline: false,
};

const mapStateToProps = (state, ownProps) => {
  let { objectiveData } = ownProps;
  const { t, objectiveID } = ownProps;
  if (!objectiveData && !!objectiveID) {
    objectiveData = objectivesSelectors.selectObjective(state.main.objectives, objectiveID);
  }
  let domainName;
  let periodName;
  if (!!objectiveData && objectiveData.ok) {
    const { type } = objectiveData.data;
    if (type === 'company') {
      domainName = t('objectives.typescompany');
    } else if (type === 'team') {
      domainName = peopleSelectors.selectTeamName(state.main.people, objectiveData.data.teamID);
    } else if (type === 'personal') {
      domainName = t('objectives.typespersonal');
    }

    if (objectiveData.data.stperiod) {
      periodName = objectivesSelectors.selectStPeriodName(
        state.main.objectives,
        null,
        objectiveData.data.stperiod,
      );
    } else {
      periodName = objectivesSelectors.selectLtPeriodName(
        state.main.objectives,
        null,
        objectiveData.data.ltperiod,
      );
    }
  }
  return {
    objectiveData,
    // Prefer the legacy ID from the objectiveData if present, as the ID will be used
    // To open dialogs
    objectiveID: get(ownProps, 'objectiveData.data.objectiveID', objectiveID),
    bestPeriod: objectivesSelectors.selectBestStPeriodForObjective(
      state.main.objectives,
      get(objectiveData, 'data.objectiveID'),
    ),
    domainName,
    periodName,
  };
};

export default withLocation(
  withNavigation(withTranslation()(connect(mapStateToProps)(ObjectiveChip))),
);
