import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import EmptyStateBg from './empty_state-background.svg';
import EmptyStateBgDark from './empty_state-background_dark.svg';

const styles = theme => ({
  emptyStateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 2,
    whiteSpace: 'pre-line',
  },
  emptybg: {
    backgroundImage:
      theme.palette.mode === 'dark' ? `url(${EmptyStateBgDark})` : `url(${EmptyStateBg})`,
    backgroundSize: '100%',
    width: 360,
    height: 360,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  img: {
    width: '24%',
    height: '24%',
  },
});

function EmptyState(props) {
  const { t } = useTranslation();
  const { classes, icon, translationgroup, noaccess = false } = props;
  return (
    // hardcoded classname for TA:
    <div
      className={clsx([classes.emptyStateContainer, 'emptystate-root'])}
      name="empty-state-container"
    >
      <div className={classes.emptybg}>
        {!!icon && <img src={icon} className={classes.img} alt={t('general.emptystateicon')} />}
      </div>
      {!noaccess && (
        <Typography align="center" variant="subtitle1" color="text.disabled">
          {t(`${translationgroup}.emptystate.title`)}
        </Typography>
      )}
      {!!noaccess && (
        <Typography align="center" variant="subtitle1" color="text.disabled">
          {t(`${translationgroup}.emptystate.noaccesstitle`)}
        </Typography>
      )}
    </div>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.string,
  classes: PropTypes.exact({
    emptyStateContainer: PropTypes.string,
    emptybg: PropTypes.string,
    img: PropTypes.string,
  }),
  translationgroup: PropTypes.string,
  noaccess: PropTypes.bool,
};

export default withStyles(styles)(EmptyState);
