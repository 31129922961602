import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import CanEditObjectiveWrapper from 'Components/Features/Objectives/CanEditObjectiveWrapper';

import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';
import { DELETED } from 'state/constants/api';

import DialogObjectiveReflection from './DialogObjectiveReflection';

class DialogObjectiveReflectionContainer extends React.Component {
  state = {
    submitted: false,
    requestID: null,
    submitStatus: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.submitted && !!prevState.requestID) {
      if (prevState.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[prevState.requestID].result === 'ok') {
          return { submitStatus: 1, submitted: false };
        }
        return {
          submitStatus: -1,
          submitted: false,
          serverError: nextProps.actionlog[prevState.requestID].message,
        };
      }
    }
    return null;
  }

  componentDidUpdate() {
    const { objectiveData, objectiveID, dispatch } = this.props;
    if (objectiveID && (!objectiveData || !objectiveData.ok)) {
      dispatch(objectivesActions.getObjective({ objectiveIDs: [objectiveID] }));
    }
    if (this.state.submitStatus === 1 && this.state.submitted === false) {
      this.timeout = setTimeout(() => {
        if (this.state.submitStatus === 1) {
          this.setState({ submitStatus: 0 });
          this.onClose();
        }
      }, 500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleSubmit = (grade, reflection) => {
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    const payload = {
      objectiveID: this.props.objectiveID,
      grade: grade || 0,
      reflection,
      requestID,
    };
    this.props.dispatch(objectivesActions.gradeObjective(payload));
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <CanEditObjectiveWrapper
        render={canEdit => (
          <DialogObjectiveReflection
            objectiveData={this.props.objectiveData}
            onClose={this.onClose}
            actionlog={this.props.actionlog}
            objectiveID={this.props.objectiveID}
            dispatch={this.props.dispatch}
            isOnline={this.props.isOnline}
            open={this.props.open}
            submitted={this.state.submitted}
            submitStatus={this.state.submitStatus}
            requestID={this.state.requestID}
            canEdit={canEdit}
            handleSubmit={this.handleSubmit}
          />
        )}
        objectiveData={this.props.objectiveData}
        canEdit={this.props.canEdit}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const objectiveData = objectivesSelectors.selectObjective(
    state.main.objectives,
    ownProps.objectiveID,
  );
  return {
    objectiveData,
    deleted: !!objectiveData && objectiveData.fetchStatus === DELETED,
    actionlog: state.main.objectives.actionlog,
  };
};

DialogObjectiveReflectionContainer.propTypes = {
  actionlog: PropTypes.object,
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  canEdit: PropTypes.bool,
  isOnline: PropTypes.bool,
  open: PropTypes.bool,
};

export default connect(mapStateToProps)(DialogObjectiveReflectionContainer);
