import React from 'react';
import PropTypes from 'prop-types';
import { NODE_TYPE_TEAM } from 'config/constants';
import InterlocksGrid from './index';

function TeamInterlocks(props) {
  const { teamId } = props;

  return (
    <InterlocksGrid
      initialDomain={{ type: NODE_TYPE_TEAM, id: teamId }}
      allowDomainChange={false}
    />
  );
}

TeamInterlocks.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default TeamInterlocks;
