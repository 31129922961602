import * as types from './types';

export const getAppStatus = payload => ({
  type: types.GET_APP_STATUS,
  payload,
});

export const fetchedAppStatus = payload => ({
  type: types.FETCHED_APP_STATUS,
  payload,
});

export const failedAppStatus = payload => ({
  type: types.FAILED_APP_STATUS,
  payload,
});
