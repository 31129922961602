/* eslint-disable no-console */
/* while validating subdomain functionality */
import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Polyfill required for running tests on lambda
import 'core-js/proposals/string-match-all';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import queryString from 'query-string';
import { authActions } from 'state/ducks/auth';
import TenantSwitchDialog from 'Components/Common/SubdomainHandler/TenantSwitchDialog';
import IntegrationsAuthorizationView from 'screens/Features/Integrations/IntegrationsAuthorizationView';
import { isEnabled } from 'config/flags';
import { addDictionary } from 'config/i18n';
import { appstatusSelectors } from 'state/ducks/appstatus';
import initPendo, { isPendoReady, getPendoVisitorId, pendoRegistration } from 'config/pendo';
import { debounce } from 'config/helpers';
import Home from './screens/Home';
import Tabs from './screens/Tabs';
import Login from './screens/Login';
import Debug from './screens/Debug';
import BindIdentity from './screens/BindIdentity';
import LoggingOut from './screens/LoggingOut';
import LoggedOut from './screens/LoggedOut';
import Loading from './screens/Loading';
import IntegrationsFinalizeView from './screens/Features/Admin/IntegrationsFinalizeView';
import MissingUserInfo from './screens/MissingUserInfo';
import AuthGate from './config/AuthGate';
import './index.css';

const handlePendoInit = props => {
  const { auth } = props;
  const { analyticsId, isChangeManager, isAdmin, tenantID } = auth;
  if (!!analyticsId && !!tenantID) {
    if (!isPendoReady() || getPendoVisitorId() !== analyticsId) {
      initPendo({ tenantId: tenantID, analyticsId, isChangeManager, isAdmin });
    }
  }
};

const initDictionary = () => {
  addDictionary('default');
};
class App extends Component {
  constructor(props) {
    super(props);
    this.debouncedPendoInit = debounce(handlePendoInit, 500);
    this.state = {
      deferredPwaInstallEvent: null,
      sentryInitialized: false,
      pendoInitialized: false,
    };
  }

  componentDidMount() {
    const context = this;
    initDictionary();
    window.addEventListener('beforeinstallprompt', e => {
      context.setState({
        deferredPwaInstallEvent: e,
      });
      e.preventDefault();
    });
    this.props.dispatch(authActions.startRefreshTimer({}));
    this.initExtLibraries();
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initExtLibraries();
  }

  initExtLibraries = () => {
    if (isEnabled('SENTRY', this.props.appstatus) && !this.state.sentryInitialized) {
      Sentry.init({
        dsn: 'https://4df471959b3840e08eef566a43e5d82f@o489472.ingest.sentry.io/5551748',
        normalizeDepth: 10,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.REACT_APP_STAGE,
        dist: process.env.REACT_APP_BUILD_VERSION,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.00001,

        beforeBreadcrumb(breadcrumb) {
          // removing redux actions coming from breadcrumb and console log
          if (breadcrumb.category === 'redux.action') {
            return null;
          }
          if (breadcrumb.category === 'console' && breadcrumb.data) {
            return null;
          }
          return breadcrumb;
        },
      });
      this.setState({ sentryInitialized: true });
    }
    if (isEnabled('PENDO', this.props.appstatus) && !this.state.pendoInitialized) {
      pendoRegistration('ad361fbc-cfac-4570-6249-4493d03a6f36');
      this.debouncedPendoInit(this.props);
      this.setState({ pendoInitialized: true });
    }
  };

  render() {
    const { auth } = this.props;
    const { hostname, host, search } = window.location;
    Sentry.setTag('tenantID', auth.tenantID);
    const domainparts = hostname.split('.');
    const KNOWN_PARTS = ['my', 'my-alpha', 'my-test', 'my-beta', 'my-staging', 'localhost'];
    // The state is currently stored in the localstorage of the
    // "naked" domain, if we have a subdomain we must first redirect the user,
    // keeping the requested domain in the querystring
    const parsedQs = queryString.parse(search);
    const cfDomainIndex = domainparts.indexOf('cloudfront');
    if (cfDomainIndex > 0) {
      // dev deployment on cloudfront
      // Note: the index would need to be above 0 as there should
      // always be a subdomain (CF distribution ID)
      const cfDistributionId = domainparts[cfDomainIndex - 1];
      KNOWN_PARTS.push(cfDistributionId);
    }
    if (!KNOWN_PARTS.includes(domainparts[0])) {
      // tenant-specific subdomain detected
      const receivedTenantId = domainparts[0];

      parsedQs.tenant = receivedTenantId;
      const pathName = window.location.pathname === '/' ? '/home' : window.location.pathname;
      const redirectUrl = `${window.location.protocol}//${host.replace(
        `${domainparts[0]}.${domainparts[1]}`,
        domainparts[1],
      )}${pathName}?${queryString.stringify(parsedQs)}${window.location.hash}`;

      console.log('Navigating to: ', redirectUrl);
      window.location.assign(redirectUrl);
      return null;
    }
    // the redirect has happened, we're able to check whether the tenantID matches
    if (!!parsedQs.tenant) {
      if (!!auth.tenantID) {
        if (parsedQs.tenant !== auth.tenantID) {
          console.log('Tenant ID does not match, proposing switch');
          return (
            <Router>
              <TenantSwitchDialog
                targetTenant={parsedQs.tenant}
                currentTenant={auth.tenantID}
                auth={auth}
              />
            </Router>
          );
        }
        // Tenant requested matches the current tenant, clean up the query string
        delete parsedQs.tenant;
        const ss = parsedQs ? `?${queryString.stringify(parsedQs)}` : '';
        window.location.assign(`${window.location.pathname}${ss}${window.location.hash}`);
      }
    }

    return (
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="//" element={<Navigate to="/home" />} />
          {/* These paths do not require login */}
          <Route path="/login/*" element={<Login />}>
            <Route path=":tenant" element={<Login />} />
          </Route>
          <Route path="/debug" element={<Debug />} />
          <Route path="/extlogin/*" element={<Login backdoor />} />
          <Route path="/logout/*" element={<LoggingOut />} />
          <Route path="/loggedout/*" element={<LoggedOut />} />
          <Route path="/missinguserinfo/*" element={<MissingUserInfo />} />
          <Route path="/loginCallback/*" element={<Loading />} />

          {/* All paths under /home require login */}
          <Route element={<AuthGate />}>
            <Route
              path="/home/*"
              element={<Home pwaInstallPromptEvent={this.state.deferredPwaInstallEvent} />}
            />
            <Route
              path="/tabs/*"
              element={<Tabs pwaInstallPromptEvent={this.state.deferredPwaInstallEvent} />}
            />
          </Route>
          {/* These views do not use the normal redux-logic flow for handling
            token refresh, and need to be blocked until a valid token exists */}
          <Route element={<AuthGate />}>
            <Route
              path="/bindidentity/*"
              element={<BindIdentity pwaInstallPromptEvent={this.state.deferredPwaInstallEvent} />}
            />
            <Route
              path="/oauth/finalizeintegration/*"
              element={
                <IntegrationsFinalizeView
                  pwaInstallPromptEvent={this.state.deferredPwaInstallEvent}
                />
              }
            />
            <Route
              path="/openid/userauthorization/*"
              element={
                <IntegrationsAuthorizationView
                  pwaInstallPromptEvent={this.state.deferredPwaInstallEvent}
                />
              }
            />
          </Route>
        </Routes>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  appstatus: appstatusSelectors.selectStatus(state.main.appstatus),
});

App.propTypes = {
  auth: PropTypes.object,
  dispatch: PropTypes.func,
  appstatus: PropTypes.object,
};

export default connect(mapStateToProps)(App);
