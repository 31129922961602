import React from 'react';
import useDomainOkrs from 'hooks/useDomainOkrs';
import { Grid, Skeleton } from '@mui/material';
import PeriodNavigator from 'Components/Features/Objectives/PeriodNavigator';
import Panel from 'Components/Library/BaseComponents/Panel';
import ObjectivesTablev2 from 'Components/Features/Objectives/ObjectivesTablev2';
import EmptyState from 'Components/Common/EmptyState';
// @ts-ignore
import ObjectivesEmptyStateIcon from 'Components/Features/Objectives/empty_state-icon-objectives.svg';
import { useSelector } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';

interface ObjectivesTabProps {
  teamId: string;
  handlePeriodChange: Function;
  selectedPeriod: string;
}

function ObjectivesTab(props: ObjectivesTabProps) {
  const { teamId, handlePeriodChange, selectedPeriod } = props;
  const domain = { d: teamId, t: 'team' };
  const { okrs } = useDomainOkrs([domain], selectedPeriod);

  const nextShortTermPeriod = useSelector(state =>
    objectivesSelectors.selectNextShortTermPeriod(
      // @ts-ignore
      state.main.objectives,
      selectedPeriod,
    ),
  );
  const prevShortTermPeriod = useSelector(state =>
    objectivesSelectors.selectPrevShortTermPeriod(
      // @ts-ignore
      state.main.objectives,
      selectedPeriod,
    ),
  );

  let okrContent = <Skeleton variant="rounded" />;
  if (okrs[0].ok) {
    if (okrs[0].data.length > 0) {
      okrContent = (
        <Panel sx={{ mt: 2 }} alignItems="stretch">
          <ObjectivesTablev2
            period={selectedPeriod}
            domain={domain}
            okrData={okrs[0]}
            renderHeader={true}
            showDomainName={false}
            showLastModified={true}
          />
        </Panel>
      );
    } else {
      okrContent = <EmptyState icon={ObjectivesEmptyStateIcon} translationgroup="objectives" />;
    }
  }
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={4}>
          <PeriodNavigator
            selectedPeriod={selectedPeriod}
            handlePeriodChange={handlePeriodChange}
            prevPeriod={prevShortTermPeriod}
            nextPeriod={nextShortTermPeriod}
          />
        </Grid>
      </Grid>
      {okrContent}
    </>
  );
}

export default ObjectivesTab;
