import React from 'react';
import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

const DEFAULT_STYLES = {
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 2,
  color: theme => `${theme.palette?.primary.main}`,
};

function RelationshipIcon(props) {
  return (
    <SvgIcon {...props} sx={{ ...DEFAULT_STYLES, ...props.sx }} viewBox="-1 -3 16 16">
      <path d="M11.8337 5.41667V0.75H7.75033V2.5H4.25033V0.75H0.166992V5.41667H4.25033V3.66667H5.41699V9.5H7.75033V11.25H11.8337V6.58333H7.75033V8.33333H6.58366V3.66667H7.75033V5.41667H11.8337Z" />
    </SvgIcon>
  );
}

RelationshipIcon.propTypes = {
  sx: PropTypes.object,
};

export default RelationshipIcon;
