import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import StatusText from 'Components/Common/StatusText';

import KeyresultChart from 'Components/Features/Objectives/KeyresultChart';
import KeyresultsIcon from 'Components/Features/Objectives/KeyresultsIcon';
import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import DataObjectChip from 'Components/Library/DataObjectChip';
import Form from 'Components/Library/Forms/';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import FormCheckbox from 'Components/Library/Forms/Elements/FormCheckbox';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormNumberField from 'Components/Library/Forms/Elements/FormNumberField';
import StandardInput from 'Components/Library/StandardInput';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import UserChip from 'Components/Library/UserChip';
import UserSelector from 'Components/Library/UserSelector';

import { round, getKeyResultCompletionPct } from 'config/helpers';
import { getKeyresultWeeklyProgress } from 'state/ducks/objectives/helpers';
import { objectivesActions } from 'state/ducks/objectives';

import { KR_UNIT_MAX_LENGTH } from 'config/constants';
import { TagsComponent } from 'Components/Library/Tags/Details/index';
import FeatureFlag from 'Components/Library/FeatureFlag/index';
import KrConfidenceWidget from '../KeyresultUpdateForm/KrConfidenceWidget';
import KrStatusWidget from '../KeyresultUpdateForm/KrStatusWidget';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  content: {
    ...theme.shape,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.box,
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    '& > div:first-of-type': {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  },
  green: {
    color: `${theme.palette.confidence.green} !important`,
  },
  amber: {
    color: `${theme.palette.confidence.amber} !important`,
  },
  red: {
    color: `${theme.palette.confidence.red} !important`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  leftColumn: {
    width: 100,
    flexShrink: 0,
  },
  chipLabel: {
    paddingTop: 3,
  },
  rightColumn: {
    minWidth: 0, // Fix text ellipsis in flexbox
  },
  krDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiSwitch-root': {
      marginTop: 0,
      marginBottom: 4,
    },
    '& .Mui-disabled': {
      color: theme.palette.text.primary,
      '-webkit-text-fill-color': theme.palette.text.primary,
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
      height: 32,
      '& input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input': {
        textAlign: 'center',
      },
    },
  },
  status: {
    display: 'inline-block',
    marginRight: theme.spacing(),
  },
});

const schema = {
  type: 'object',
  properties: {
    type: { type: 'string', default: '%', maxLength: 64, minLength: 1 },
    baseline: { type: 'number' },
    weight: { type: 'integer', minimum: 0, maximum: 100 },
    status: { type: 'number' },
    target: { type: 'number' },
    committed: { type: 'boolean' },
    confidence: {
      type: 'integer',
      minimum: 0,
      maximum: 100,
    },
  },
  additionalProperties: true,
};

class KeyresultDetails extends React.Component {
  state = {
    status: null,
  };

  static getDerivedStateFromProps(nextProps) {
    if (!!nextProps.keyresultData) {
      const status = getKeyResultCompletionPct(nextProps.keyresultData);
      return {
        status,
      };
    }
    return null;
  }

  render() {
    const { classes, canEdit, keyresultData, objectiveData, t } = this.props;

    const { status } = this.state;
    const weeklyDelta = getKeyresultWeeklyProgress(keyresultData);
    const domainType = get(objectiveData, 'data.type');
    const initialValues = !!keyresultData
      ? {
          owner: keyresultData.owner,
          baseline: keyresultData.baseline,
          target: keyresultData.target,
          committed: keyresultData.committed,
          weight: keyresultData.weight,
          type: keyresultData.type,
        }
      : null;

    return (
      <Form
        name="panel-view-kr-details-form"
        debouncedAutoSubmit={1250}
        schema={schema}
        initialValues={initialValues}
        stateSlice="main.objectives"
        submitActionCreator={objectivesActions.editKeyresult}
        submitOnlyDirty
        allowRefreshData
        additionalProperties={{
          keyresultID: keyresultData.keyresultID,
        }}
      >
        <LinearIndicator positioning="absolute" />
        <div id="kr-details-container" className={classes.root}>
          <ObjectiveChip
            objectiveData={objectiveData}
            objectiveID={get(objectiveData, 'data.objectiveID')}
            color="grey"
            allowNavigate
          />
          <div className={classes.content}>
            <DataObjectChip
              Icon={KeyresultsIcon}
              text={t('objectives.genericDataObjectNameKeyresult')}
              color="transparent"
            />

            <KeyresultChart
              eventData={keyresultData.value_history}
              confidence={keyresultData.confidence}
              status={status}
              objectiveData={objectiveData}
              stage={objectiveData.data.eventdata.stage}
            />
            <Grid container spacing={1}>
              <Grid item xs={3} className={classes.krDetailContainer}>
                <Typography id="baselineLabel" variant="caption" color="textSecondary">
                  {t('objectives.baselineheader')}
                </Typography>

                <FormNumberField
                  fieldName="baseline"
                  render={fieldProps => (
                    <StandardInput
                      id="keyresultBaselineInputField"
                      inputProps={{ 'aria-labelledby': 'baselineLabel' }}
                      variant="outlined"
                      {...fieldProps}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} className={classes.krDetailContainer}>
                <Typography variant="caption" color="textSecondary">
                  {t('objectives.statusheader')}
                </Typography>
                <KrStatusWidget keyresultData={keyresultData} canEdit={canEdit} />
              </Grid>
              <Grid item xs={3} className={classes.krDetailContainer}>
                <Typography id="targetLabel" variant="caption" color="textSecondary">
                  {t('objectives.targetheader')}
                </Typography>

                <FormNumberField
                  fieldName="target"
                  render={fieldProps => (
                    <StandardInput
                      id="keyresultTargetInputField"
                      variant="outlined"
                      inputProps={{ 'aria-labelledby': 'targetLabel' }}
                      {...fieldProps}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} className={classes.krDetailContainer}>
                <Typography id="unitLabel" variant="caption" color="textSecondary">
                  {t('objectives.createnewkrunitinputlabel')}
                </Typography>
                <FormTextField
                  fieldName="type"
                  render={fieldProps => (
                    <StandardInput
                      id="keyresultUnitInputField"
                      variant="outlined"
                      inputProps={{
                        maxLength: KR_UNIT_MAX_LENGTH,
                        'aria-labelledby': 'unitLabel',
                      }}
                      fullWidth
                      {...fieldProps}
                      // Force helperText to null, there is not space for a validation
                      // error in the specs, and one could occur here.
                      helperText=""
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <KrConfidenceWidget keyresultData={keyresultData} canEdit={canEdit} />
            <Grid container spacing={1} justifyContent="center" sx={{ mt: 0.5, mb: 1 }}>
              <Grid item xs={3} className={classes.krDetailContainer}>
                <Typography variant="caption" color="textSecondary">
                  {t('objectives.createnewkrweightinputlabel')}
                </Typography>

                <FormNumberField
                  fieldName="weight"
                  render={fieldProps => (
                    <StandardInput
                      variant="outlined"
                      id="keyresultWeightInputField"
                      fullWidth
                      {...fieldProps}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} className={classes.krDetailContainer}>
                <Typography variant="caption" color="textSecondary">
                  {t('objectives.createnewkrcommittedinputlabel')}
                </Typography>
                <FormCheckbox
                  fieldName="committed"
                  render={fieldProps => (
                    <Switch
                      id="keyresultCommittedSwitch"
                      size="small"
                      onKeyDown={event => {
                        // Switch does not seem to play nice with keyboard, let's fix that:
                        if (['Enter', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                          // Emit something that "looks" like a SyntheticEvent:
                          fieldProps.onChange({ target: { checked: !fieldProps.checked } });
                        }
                      }}
                      {...fieldProps}
                      sx={{ mt: '8px' }}
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 1.5, mt: 0.5 }} />
            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('objectives.progress')}
                </Typography>
              </div>
              <div className={classes.rightColumn}>
                <StatusText
                  key={`panel-view-kr-status-text-${keyresultData.keyresultID}`}
                  variant="h6"
                  className={classes.status}
                  value={round(status)}
                  goal={100}
                  suffix="%"
                />
                <span>
                  <Typography
                    variant="caption"
                    component="span"
                    display="inline"
                    className={classes[weeklyDelta >= 0 ? 'green' : 'red']}
                  >
                    {`${weeklyDelta >= 0 ? '+' : ''}${weeklyDelta}%`}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="span"
                    display="inline"
                    sx={{ color: theme => theme.palette.primary[600] }}
                  >
                    {t('objectives.objectivedetailslastweek')}
                  </Typography>
                </span>
              </div>
            </div>

            <div className={classes.row}>
              <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('general.owner')}
                </Typography>
              </div>
              <div name="kr-owner-row" className={classes.rightColumn}>
                {!!canEdit && domainType !== 'personal' ? (
                  <FormSelectorField
                    fieldName="owner"
                    render={fieldProps => <UserSelector color="white" {...fieldProps} />}
                  />
                ) : (
                  <UserChip color="white" sub={get(objectiveData, 'data.owner')} />
                )}
              </div>
            </div>
            <FeatureFlag flag="TAGS">
              <>
                <Divider sx={{ mb: 1.5, mt: 0.5 }} />
                <div className={classes.row}>
                  <div className={clsx(classes.leftColumn, classes.chipLabel)}>
                    <Typography variant="subtitle2" color="text.secondary">
                      {t('tags.label')}
                    </Typography>
                  </div>
                  <div className={classes.rightColumn}>
                    <TagsComponent instanceID={keyresultData?.graph_id} canEdit={canEdit} />
                  </div>
                </div>
              </>
            </FeatureFlag>
          </div>
        </div>
      </Form>
    );
  }
}

KeyresultDetails.propTypes = {
  objectiveData: PropTypes.object,
  keyresultData: PropTypes.object,
  handleEdit: PropTypes.func,
  classes: PropTypes.exact({
    root: PropTypes.string,
    content: PropTypes.string,
    green: PropTypes.string,
    amber: PropTypes.string,
    red: PropTypes.string,
    row: PropTypes.string,
    leftColumn: PropTypes.string,
    chipLabel: PropTypes.string,
    rightColumn: PropTypes.string,
    krDetailContainer: PropTypes.string,
    status: PropTypes.string,
  }),
  canEdit: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(KeyresultDetails));
