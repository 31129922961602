import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { initializeNotificationsModule } from 'firebase-cfg';
import { notificationconfigSelectors } from 'state/ducks/notificationconfig';

class NotificationsHandler extends Component {
  async componentDidMount() {
    initializeNotificationsModule(this.props.dispatch);
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  currentDeviceCfg: notificationconfigSelectors.selectCurrentDeviceCfg(
    state.main.notificationconfig,
  ),
});

NotificationsHandler.propTypes = {
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(NotificationsHandler);
