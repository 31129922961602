import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { peopleActions } from 'state/ducks/people';

import EmployeeSearch from './EmployeeSearch';

export class SearchWrapper extends Component {
  state = {
    searchString: '',
    suggestions: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { people, filterFunc = null } = nextProps;
    const results = [];

    const { searchString } = prevState;
    if (searchString.length > 1) {
      for (const sub in people.subs) {
        if (!filterFunc || filterFunc(sub)) {
          if (Object.hasOwn(people.subs, sub)) {
            if (
              people.subs[sub].fullName &&
              people.subs[sub].fullName.toLowerCase().includes(searchString.toLowerCase())
            ) {
              const person = JSON.parse(JSON.stringify(people.subs[sub])); // Clone object
              results.push(person);
            }
          }
        }
      }
    }
    return { suggestions: results };
  }

  getPeople(searchString) {
    this.setState({ searchString });
    if (searchString.length > 1) {
      this.props.dispatch(peopleActions.searchPeople({ searchstring: searchString }));
    }
  }

  clearSearch() {
    this.setState({ searchString: '' });
  }

  render() {
    return (
      <EmployeeSearch
        selected={this.props.selected}
        error={this.props.error}
        inputClass={this.props.inputClass}
        inputVariant={this.props.inputVariant}
        containerClass={this.props.containerClass}
        className={this.props.className}
        autoFocus={this.props.autoFocus}
        suggestions={this.state.suggestions}
        onSelect={sub => this.props.onSelect(sub)}
        onClearRequested={() => this.clearSearch()}
        onFetchRequested={searchstring => this.getPeople(searchstring)}
        id={this.props.id}
      />
    );
  }
}

const mapStateToProps = state => ({
  people: state.main.people,
});

SearchWrapper.propTypes = {
  selected: PropTypes.object,
  error: PropTypes.bool,
  autoFocus: PropTypes.bool,
  inputClass: PropTypes.string,
  inputVariant: PropTypes.string,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  people: PropTypes.object,
  dispatch: PropTypes.func,
  filterFunc: PropTypes.func,
  id: PropTypes.string,
};

export default connect(mapStateToProps)(SearchWrapper);
