import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import ButtonBase from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

const styles = theme => ({
  addButton: {
    ...theme.shape,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0)',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: theme.palette.action.disabled,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.action.active,
      ...theme.shape,
    },
  },
  iconContainer: {
    padding: 8,
    width: 16,
    height: 16,
  },
  icon: {
    width: 16,
    height: 16,
  },
});

class AddButton extends Component {
  render() {
    const { classes, onClick, tooltip, name } = this.props;
    return (
      <Tooltip title={tooltip}>
        <ButtonBase name={name} className={classes.addButton} onClick={onClick}>
          <div className={classes.iconContainer}>
            <AddIcon className={classes.icon} />
          </div>
        </ButtonBase>
      </Tooltip>
    );
  }
}

AddButton.propTypes = {
  classes: PropTypes.exact({
    addButton: PropTypes.string,
    iconContainer: PropTypes.string,
    icon: PropTypes.string,
  }),
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  name: PropTypes.string,
};

export default withStyles(styles)(AddButton);
