import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { peopleActions, peopleSelectors } from 'state/ducks/people';

import ChipAutocomplete from 'Components/Library/Shared/ChipAutocomplete';
import UserChip from 'Components/Library/UserChip';
import TeamChip from 'Components/Library/TeamChip';
import { NODE_TYPE_USER, NODE_TYPE_TEAM } from 'config/constants';

// TODO: use useSearch hook
import { isMatch } from 'hooks/useSearch/utils';

const renderSuggestion = (suggestion, { isHighlighted }) => {
  if (suggestion.type === NODE_TYPE_USER) {
    return (
      <UserChip
        id={`persona-selector-user-result-${suggestion.id}`}
        name="persona-selector-user-result"
        sub={suggestion.id}
        color={isHighlighted ? 'grey' : 'transparent'}
        actionable
      />
    );
  }
  if (suggestion.type === NODE_TYPE_TEAM) {
    return (
      <TeamChip
        id={`persona-selector-team-result-${suggestion.id}`}
        name="persona-selector-team-result"
        teamId={suggestion.id}
        color={isHighlighted ? 'grey' : 'transparent'}
        actionable
      />
    );
  }
  return null;
};

class SearchOverlay extends Component {
  state = {
    searchString: '',
    suggestions: [],
    multiSection: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      people,
      teamNames,
      showRecent,
      recentTeams,
      recentPeople,
      selectTeamsManagedBy,
      searchFor,
      filterFunc,
    } = nextProps;
    const results = [];
    const { searchString } = prevState;

    // Compute matches
    // This is done in getDerivedStateFromProps, as some of the data being searched
    // is always available (all teams) - while other parts of the data require fetching
    // and thus props to update

    // Search for teams:
    if (searchString.length > 1) {
      const highPrioTeamMatches = [];
      const lowPrioTeamMatches = [];
      const highPrioUserMatches = [];
      const lowPrioUserMatches = [];
      const matchedIds = [];
      if (searchFor.includes('teams')) {
        for (const [searchedId, teamName] of Object.entries(teamNames)) {
          if (!filterFunc || filterFunc({ type: NODE_TYPE_TEAM, id: searchedId })) {
            const matchLevel = isMatch(searchString, teamName);

            if (matchLevel === 2) {
              matchedIds.push(searchedId);
              highPrioTeamMatches.push({ id: searchedId, text: teamName, type: NODE_TYPE_TEAM });
            } else if (matchLevel === 1) {
              lowPrioTeamMatches.push({ id: searchedId, text: teamName, type: NODE_TYPE_TEAM });
              matchedIds.push(searchedId);
            }
          }
        }
      }
      if (searchFor.includes('teamsledby') || searchFor.includes('people')) {
        for (const sub in people.subs) {
          if (Object.hasOwn(people.subs, sub) && people.subs[sub].ok) {
            const { fullName } = people.subs[sub];
            const matchLevel = isMatch(searchString, fullName);
            if (matchLevel > 0) {
              if (searchFor.includes('teamsledby')) {
                const managedTeams = selectTeamsManagedBy(sub);
                for (const teamId of managedTeams) {
                  if (!filterFunc || filterFunc({ type: NODE_TYPE_TEAM, id: teamId })) {
                    if (!matchedIds.includes(teamId) && teamId in teamNames) {
                      lowPrioTeamMatches.push({
                        id: teamId,
                        text: teamNames[teamId],
                        type: NODE_TYPE_TEAM,
                      });
                      matchedIds.push(teamId);
                    }
                  }
                }
              }
              if (searchFor.includes('people')) {
                if (!filterFunc || filterFunc({ type: NODE_TYPE_USER, id: sub })) {
                  matchedIds.push(sub);
                  if (matchLevel === 2) {
                    highPrioUserMatches.push({ id: sub, text: fullName, type: NODE_TYPE_USER });
                  } else {
                    lowPrioUserMatches.push({ id: sub, text: fullName, type: NODE_TYPE_USER });
                  }
                }
              }
            }
          }
        }
      }
      const teamMatches = highPrioTeamMatches.concat(lowPrioTeamMatches);
      const userMatches = highPrioUserMatches.concat(lowPrioUserMatches);
      const isMultiSection = teamMatches.length > 0 && userMatches.length > 0;
      if (isMultiSection) {
        return {
          suggestions: [
            { title: 'people', suggestions: userMatches },
            { title: 'teams', suggestions: teamMatches },
          ],
          multiSection: true,
        };
      }
      return { suggestions: userMatches.concat(teamMatches), multiSection: false };
    }
    if (!!showRecent) {
      const recent = [];
      if (searchFor.includes('people')) {
        for (const sub of recentPeople) {
          if (!filterFunc || filterFunc({ type: NODE_TYPE_USER, id: sub })) {
            const { fullName } = people.subs[sub];
            if (!!fullName) {
              recent.push({
                type: NODE_TYPE_USER,
                id: sub,
                text: fullName,
              });
            }
          }
        }
      }
      if (searchFor.includes('teams')) {
        for (const recentTeamId of recentTeams) {
          if (!filterFunc || filterFunc({ type: NODE_TYPE_TEAM, id: recentTeamId })) {
            if (!!recentTeamId && !!teamNames[recentTeamId]) {
              recent.push({
                type: NODE_TYPE_TEAM,
                id: recentTeamId,
                text: teamNames[recentTeamId],
              });
            }
          }
        }
      }
      return {
        multiSection: true,
        suggestions: [
          {
            title: 'recent',
            suggestions: recent,
          },
        ],
      };
    }
    return { suggestions: results, multiSection: false };
  }

  getPeople = searchString => {
    this.setState({ searchString });
    if (searchString.length > 1) {
      this.props.dispatch(peopleActions.searchPeople({ searchstring: searchString }));
    }
  };

  onSelect = suggestion => {
    if (suggestion.type === NODE_TYPE_USER) {
      this.props.dispatch(peopleActions.addRecentUser({ sub: suggestion.id }));
    } else if (suggestion.type === NODE_TYPE_TEAM) {
      this.props.dispatch(peopleActions.addRecentTeam({ sub: suggestion.id }));
    }
    this.props.onSelect(suggestion);
  };

  clearSearch = () => {
    this.setState({ searchString: '' });
  };

  render() {
    return (
      <ChipAutocomplete
        name={this.props.name}
        selected={this.props.selected}
        suggestions={this.state.suggestions}
        onSelect={this.onSelect}
        multiSection={this.state.multiSection}
        alwaysRenderSuggestions={this.state.multiSection}
        renderSuggestion={(suggestion, { isHighlighted }) =>
          renderSuggestion(suggestion, { isHighlighted })
        }
        onClearRequested={this.clearSearch}
        onFetchRequested={this.getPeople}
        id={this.props.id}
        placeholder={this.props.t('general.orgSearchLabel')}
      />
    );
  }
}

const mapStateToProps = state => ({
  people: state.main.people,
  recentPeople: peopleSelectors.selectRecentUsers(state.main.people),
  recentTeams: peopleSelectors.selectRecentTeams(state.main.people),
  teamNames: peopleSelectors.selectTeamNames(state.main.people),
  selectTeamsManagedBy: userId => peopleSelectors.selectTeamsManagedBy(state.main.people, userId),
});

SearchOverlay.propTypes = {
  selected: PropTypes.object,
  onSelect: PropTypes.func,
  people: PropTypes.object,
  recentPeople: PropTypes.array,
  recentTeams: PropTypes.array,
  teamNames: PropTypes.object,
  selectTeamsManagedBy: PropTypes.func,
  dispatch: PropTypes.func,
  filterFunc: PropTypes.func,
  t: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  showRecent: PropTypes.bool,
  searchFor: PropTypes.array,
};

SearchOverlay.defaultProps = {
  showRecent: true,
  searchFor: ['teams', 'teamsledby', 'people'],
  filterFunc: null,
};

export default withTranslation()(connect(mapStateToProps)(SearchOverlay));
