import React, { PropsWithChildren } from 'react';

import { Box, useTheme, SvgIconProps as MuiSvgIconProps } from '@mui/material';
import { IconProps } from './Icon.types';
import { get } from 'lodash';

/*
  Typical use:
   - Wraps a material ui icon component and ensures it's styling is consistent
  Props:
   - size: sm, md, lg, xl
   - background color:
   - color:
*/

const SIZE_DEF = {
  small: [0.75, 1.5, 2.5],
  medium: [1, 2.25, 3],
  large: [1.25, 2.25, 4],
  xl: [1.5, 2.5, 5],
};
const Icon = React.forwardRef(function Icon(props: PropsWithChildren<IconProps>, ref) {
  const { size = 'medium', className, bgcolor, color, sx, children, ...rest } = props;
  const [borderRadius, iconSizeMultiplier, wrapperSizeMultiplier] = SIZE_DEF[size];

  const theme = useTheme();
  const iconSize = theme.spacing(iconSizeMultiplier);
  const wrapperSize = theme.spacing(wrapperSizeMultiplier);

  const iconEl = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const iconProps: MuiSvgIconProps = {
        sx: { width: iconSize, height: iconSize },
      };
      if (!!color) {
        iconProps.htmlColor = get(theme, ['palette', color, 'main'], color);
      }
      return React.cloneElement(child, iconProps);
    }
    return child;
  });

  return (
    <Box
      className={className}
      sx={{
        width: wrapperSize,
        height: wrapperSize,
        bgcolor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(borderRadius),
        ...sx,
      }}
      {...rest}
    >
      {iconEl}
    </Box>
  );
});

export default Icon;
