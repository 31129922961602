import { get } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const selectFormState = (slice, formName, formIdentifier) => {
  let path = `forms.${formName}`;
  if (!!formIdentifier) {
    path = `${path}.${formIdentifier}`;
  }
  return get(slice, path, null);
};
