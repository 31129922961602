import { idRegex } from 'state/ducks/objectives/helpers';
import { NodeType } from '../Graph/types';

// eslint-disable-next-line import/prefer-default-export
export const getNodeModalPath = graphId => {
  /* Return path to navigate to a node based on it's graph ID */

  switch (getNodeType(graphId)) {
    case NodeType.Objective:
      if (idRegex.test(graphId)) {
        return `#modal/objectives/viewkr/-/${graphId}`;
      }
      return `#modal/objectives/view/-/${graphId}`;
    case NodeType.Interlock:
      return `#modal/interlocks/view/${graphId}`;
    case NodeType.Commitment:
      return `#modal/commitments/view/${graphId}`;
    case NodeType.User:
      return `/home/people/${graphId.split('_')[1]}`;
    default:
      return null;
  }
};

export const getNodeType = function (graphId): NodeType {
  const nodeTypePrefix = graphId.split('_')[0];
  switch (nodeTypePrefix) {
    case 'OBJ':
      return NodeType.Objective;
    case 'INTERLOCK':
      return NodeType.Interlock;
    case 'COM':
      return NodeType.Commitment;
    case 'KR':
      return NodeType.KeyResult;
    case 'USR':
      return NodeType.User;
    case 'TEAM':
      return NodeType.Team;
    default:
      return null;
  }
};
