import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';

import StandardDialog from 'Components/Library/StandardDialog';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import StandardInput from 'Components/Library/StandardInput';
import DataObjectChip from 'Components/Library/DataObjectChip';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import TrafficLightIcon from '../TrafficLightIcon';

const styles = theme => ({
  title: {
    height: 44,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 20,
    paddingRight: 12,
  },
  qaBox: {
    ...theme.shape,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#ffffff',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  headLine: {
    marginTop: 32,
    marginBottom: theme.spacing(4),
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  h6: {
    marginBottom: theme.spacing(2),
    marginTop: 8,
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  input: {
    marginBottom: theme.spacing(4),
  },
  radioOption: {
    padding: 0,
  },
  radioGroup: {
    marginLeft: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

class PanelViewInterpretation extends Component {
  renderQuestionsAndAnswersForm = () => {
    const { classes, teamId, answers, canEdit, onChange, t } = this.props;
    if ((!answers || answers.length === 0) && !canEdit) {
      return (
        <Typography name="panel-view-interpretation-not-answered-yet-h6" variant="h6">
          {t('csi.viewmodalnopermissionsnotansweredtitle')}
        </Typography>
      );
    }
    if (!!answers && answers.length > 0) {
      return answers.map((qa, index) => (
        <div key={`csi-panel-${teamId}-${index}`} className={canEdit ? null : classes.qaBox}>
          <Typography variant="h6" className={classes.h6}>
            {qa.q}
          </Typography>
          {canEdit ? (
            <StandardInput
              multiline
              fullWidth
              className={classes.input}
              variant="standard"
              onChange={e => onChange(index, e)}
              value={answers[index].a}
              name={`panel-view-interpretation-question-${index}`}
            />
          ) : (
            <Typography variant="body2">{qa.a}</Typography>
          )}
        </div>
      ));
    }

    return null;
  };

  renderTrafficLightsForm = () => {
    const { classes, topic, onTrafficLightChange, trafficLightSelection } = this.props;
    if (!!topic && topic.trafficLightsEnabled) {
      return (
        <React.Fragment>
          <Typography variant="h6" className={classes.h6}>
            {topic.trafficLightQuestion}
          </Typography>
          <RadioGroup
            value={trafficLightSelection}
            onChange={onTrafficLightChange}
            className={classes.radioGroup}
          >
            {['green', 'yellow', 'red'].map(color => (
              <FormControlLabel
                label={topic.trafficLightOptions[color]}
                value={color}
                className={classes.radioOption}
                key={`key-${color}`}
                name={`trafficlight-control-${color}`}
                control={
                  <Radio
                    classes={{
                      root: classes.radioOption,
                    }}
                    checked={color === trafficLightSelection}
                    checkedIcon={<TrafficLightIcon color={color} />}
                    icon={<TrafficLightIcon color={color} disabled />}
                  />
                }
              />
            ))}
          </RadioGroup>
        </React.Fragment>
      );
    }
    return null;
  };

  renderTrafficLightsSelection = () => {
    const { classes, topic, trafficLightSelection } = this.props;
    if (!!topic && topic.trafficLightsEnabled && !!trafficLightSelection) {
      return (
        <div className={classes.qaBox}>
          <Typography variant="h6" className={classes.h6}>
            {topic.trafficLightQuestion}
          </Typography>
          <div className={classes.row}>
            <TrafficLightIcon color={trafficLightSelection} />
            <Typography variant="subtitle2">
              {topic.trafficLightOptions[trafficLightSelection]}
            </Typography>
          </div>
        </div>
      );
    }
    return null;
  };

  disableSubmitButton = () => {
    for (const i in this.props.answers) {
      if (this.props.answers[i].a === '') {
        return true;
      }
    }
    if (!!this.props.topic.trafficLightsEnabled && !this.props.trafficLightSelection) {
      return true;
    }
    return false;
  };

  render() {
    const {
      open,
      classes,
      teamName,
      canEdit,
      t,
      submitted,
      submitStatus,
      onClose,
      zIndexModifier,
    } = this.props;

    return (
      <StandardDialog
        size="large"
        open={open}
        onBackdropClick={onClose}
        zIndexModifier={zIndexModifier}
      >
        <SavingIndicator submitted={submitted} submitStatus={submitStatus} />
        <div className={classes.title}>
          <DataObjectChip text={t('csi.viewmodaloverline')} variant="normal" />
          <IconButton onClick={onClose} size="medium" aria-label={t('general.close')}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={clsx(classes.content, 'scrollable')}>
          <Typography variant="h3" className={classes.headLine}>
            {teamName}
          </Typography>
          <EmphasizedSection>
            {!canEdit && this.renderTrafficLightsSelection()}
            {this.renderQuestionsAndAnswersForm()}
            {canEdit && this.renderTrafficLightsForm()}
          </EmphasizedSection>
        </DialogContent>
        <DialogActions>
          <Button id="action-bar-button-close" onClick={onClose}>
            {t('general.close')}
          </Button>
          {canEdit && (
            <Button
              variant="contained"
              color="secondary"
              id="action-bar-button-submit"
              disabled={this.disableSubmitButton()}
              onClick={() => this.props.handleSubmit()}
            >
              {t('general.submit')}
            </Button>
          )}
        </DialogActions>
      </StandardDialog>
    );
  }
}

PanelViewInterpretation.propTypes = {
  classes: PropTypes.exact({
    title: PropTypes.string,
    qaBox: PropTypes.string,
    headLine: PropTypes.string,
    content: PropTypes.string,
    h6: PropTypes.string,
    input: PropTypes.string,
    radioOption: PropTypes.string,
    radioGroup: PropTypes.string,
    row: PropTypes.string,
  }),
  teamId: PropTypes.string,
  answers: PropTypes.array,
  canEdit: PropTypes.bool,
  onChange: PropTypes.func,
  topic: PropTypes.object,
  onTrafficLightChange: PropTypes.func,
  trafficLightOptions: PropTypes.bool,
  trafficLightQuestion: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  trafficLightSelection: PropTypes.string,
  teamName: PropTypes.string,
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  submitted: PropTypes.bool,
  submitStatus: PropTypes.number,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(withStyles(styles)(PanelViewInterpretation));
