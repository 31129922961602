import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { nanoid } from 'nanoid';
import { inboxActions } from 'state/ducks/inbox';

class CommonNotification extends React.Component {
  /* Common notification wrapper, dispatches dismiss when clicked */

  handleClick = () => {
    const { dispatch, message, onClick } = this.props;
    dispatch(
      inboxActions.dismissNotification({ messageId: message.messageID, requestID: nanoid(10) }),
    );
    if (!!onClick) {
      onClick();
    }
  };

  render() {
    const { className, children, key } = this.props;
    return (
      <div key={key} className={className} onClick={this.handleClick}>
        {children}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  actionlog: state.main.inbox.actionlog,
});

CommonNotification.propTypes = {
  message: PropTypes.object,
  onClick: PropTypes.func,
  key: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(CommonNotification);
