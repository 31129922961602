import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import StandardDialog from 'Components/Library/StandardDialog';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import DataObjectChip from 'Components/Library/DataObjectChip';

import { CP_NAME_MAX_LENGTH } from 'config/constants';

const styles = theme => ({
  title: {
    height: 44,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 20,
    paddingRight: 12,
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  h6: {
    marginBottom: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  savingIndicator: {
    marginTop: -1 * 32,
    marginRight: -1 * 32,
    marginLeft: -1 * 32,
    marginBottom: 32 - 6,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20 * -1 - 6,
      marginBottom: 20 + 2,
    },
  },
});

class PanelCreateProgram extends Component {
  render() {
    const {
      classes,
      name,
      onChange,
      submit,
      submitDisabled,
      submitStatus,
      submitted,
      onClose,
      t,
      zIndexModifier,
    } = this.props;
    return (
      <StandardDialog open onBackdropClick={onClose} size="medium" zIndexModifier={zIndexModifier}>
        <div className={classes.title}>
          <DataObjectChip text={t('programs.createprogrampaneloverline')} />
          <IconButton onClick={onClose} size="medium" aria-label={t('general.close')}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={clsx(classes.content, 'scrollable')}>
          <SavingIndicator
            submitted={submitted}
            submitStatus={submitStatus}
            className={classes.savingIndicator}
          />
          <Typography variant="h6" sx={{ mb: 4 }}>
            {t('programs.createprogrampaneltitle')}
          </Typography>
          <TextField
            variant="outlined"
            label={t('programs.createprogramnameinputlabel')}
            fullWidth
            inputProps={{ maxLength: CP_NAME_MAX_LENGTH }}
            value={name}
            onChange={onChange('name')}
            className={classes.input}
            name="program-name-program-creation"
          />
        </DialogContent>
        <DialogActions>
          <Button id="cancel-program-creation" onClick={() => onClose()}>
            {t('general.cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!name || name.length === 0 || submitDisabled}
            onClick={submit}
          >
            {t('general.create')}
          </Button>
        </DialogActions>
      </StandardDialog>
    );
  }
}

PanelCreateProgram.propTypes = {
  classes: PropTypes.exact({
    title: PropTypes.string,
    content: PropTypes.string,
    h6: PropTypes.string,
    input: PropTypes.string,
    savingIndicator: PropTypes.string,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  activePrograms: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  submitDisabled: PropTypes.bool,
  submitStatus: PropTypes.number,
  submitted: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.func,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(withStyles(styles)(PanelCreateProgram));
