import * as types from './types';

export const getInbox = function getInbox() {
  return { type: types.GET_INBOX };
};

export const inboxReceived = payload => ({
  type: types.RECEIVED_INBOX,
  payload,
});

export const dismissNotification = payload => ({
  type: types.DISMISS_INBOX_CARD,
  payload,
});

export const notificationDismissed = payload => ({
  type: types.INBOX_CARD_DISMISSED,
  payload,
});

export const createLocalNotification = payload => ({
  type: types.CREATE_LOCAL_NOTIFICATION,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
