import { createDefaultListQueryLogic, createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { INTERLOCK_TYPES } from 'state/ducks/interlocks/constants';
import { prepareEditInterlockPayload } from 'state/ducks/interlocks/utils';
import {
  onContributorStatusUpdated,
  onInterlockCreated,
  onInterlockUpdated,
} from 'state/ducks/interlocks/logic-handlers';
import { APIGW_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

const SLICE_NAME = 'interlocks';

export const addInterlockLogic = createLogic({
  type: types.ADD_INTERLOCK,
  process: async ({ getState, action }, dispatch, done) => {
    const { name, requestID, description, contributors, owner } = action.payload;
    const payload = {
      name,
      requestID,
      description: JSON.stringify(description),
      type: INTERLOCK_TYPES.obstacle,
      owner,
      contributors,
    };

    axios
      .post(
        `${APIGW_URL}/interlocks/${getState().auth.tenantID}/addinterlock`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        onInterlockCreated(res, requestID, dispatch);
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editInterlockLogic = createLogic({
  type: types.EDIT_INTERLOCK,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { payload } = action;
    const { requestID } = payload;
    const preparedPayload = prepareEditInterlockPayload(payload);

    function refetchContributors() {
      // if owner change
      if (Object.hasOwn(action.payload, 'owner')) {
        dispatch(actions.resetInterlockContributorsCache({ id: payload.id }));
        dispatch(actions.fetchContributorsChart({ id: payload.id, force: true }));
      }
    }

    axios
      .post(
        `${APIGW_URL}/interlocks/${state.auth.tenantID}/updateinterlock`,
        { ...preparedPayload },
        {
          headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
        },
      )
      .then(res => {
        const currentUserId = state.auth.userID;
        const ownerID = res.data.owner;
        onInterlockUpdated(
          res,
          requestID,
          refetchContributors,
          currentUserId === ownerID,
          dispatch,
        );
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const getInterlockLogic = createLogic({
  type: types.FETCH_INTERLOCK,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.id &&
      shouldFetch(
        selectors.selectInterlock(state.main[SLICE_NAME], action.payload.id),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    // eslint-disable-next-line camelcase
    const { id, next_token } = action.payload;

    axios
      .get(`${APIGW_URL}/interlocks/${getState().auth.tenantID}/interlock`, {
        // eslint-disable-next-line camelcase
        params: { id, next_token },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(
          actions.fetchedInterlock({
            ...res.data.result,
            id,
            // eslint-disable-next-line camelcase
            fromToken: next_token,
          }),
        );
      })
      .catch(() => {
        dispatch(actions.failedInterlock(action.payload));
      })
      .then(() => done());
  },
});

export const deleteInterlockLogic = createLogic({
  type: types.DELETE_INTELOCK,
  process: async ({ getState, action }, dispatch, done) => {
    const authToken = getState().auth.tokens.access_token;
    const { tenantID } = getState().auth;
    const { payload } = action;

    return axios
      .post(`${APIGW_URL}/interlocks/${tenantID}/deleteinterlock`, payload, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then(() => {
        dispatch(actions.interlockDeleted(action.payload));
      })
      .catch(() => {
        dispatch(actions.errorTryAgainLater(payload));
      })
      .then(() => done());
  },
});

export const getUserInterlocksLogic = createDefaultListQueryLogic({
  endpoint: { api: `${APIGW_URL}/interlocks`, method: 'userinterlocks' },
  actionTypes: {
    fetch: types.FETCH_USER_INTERLOCKS,
    success: types.RECEIVED_USER_INTERLOCKS,
    fail: types.FAILED_USER_INTERLOCKS,
  },
  selector: selectors.selectUserInterlocks,
  sliceName: SLICE_NAME,
});

export const getTeamInterlocksLogic = createDefaultListQueryLogic({
  endpoint: { api: `${APIGW_URL}/interlocks`, method: 'teaminterlocks' },
  actionTypes: {
    fetch: types.FETCH_TEAM_INTERLOCKS,
    success: types.RECEIVED_TEAM_INTERLOCKS,
    fail: types.FAILED_TEAM_INTERLOCKS,
  },
  selector: selectors.selectTeamInterlocks,
  sliceName: SLICE_NAME,
});

export const getContributorsLogic = createLogic({
  type: types.FETCH_CONTRIBUTORS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.id &&
      shouldFetch(
        selectors.selectContributors(state.main[SLICE_NAME], action.payload.id),
        state.main.connection,
        action.payload?.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    const { id } = action.payload;

    axios
      .get(`${APIGW_URL}/interlocks/${getState().auth.tenantID}/contributors`, {
        params: { id },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(
          actions.fetchedContributors({
            ...res.data.result,
            id,
          }),
        );
      })
      .catch(() => {
        dispatch(actions.failedContributors({ ...action.payload, id }));
      })
      .then(() => done());
  },
});

export const editContributorsLogic = createLogic({
  type: types.EDIT_CONTRIBUTORS,
  process: async ({ getState, action }, dispatch, done) => {
    const { requestID, id, contributors } = action.payload;

    const payload = {
      requestID,
      id,
      contributors,
    };

    axios
      .post(
        `${APIGW_URL}/interlocks/${getState().auth.tenantID}/updatecontributors`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        const { result } = res.data;
        result.requestID = requestID;
        result.id = id;
        dispatch(actions.updatedContributors(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editContributorStatusLogic = createLogic({
  type: types.EDIT_CONTRIBUTORS_STATUS,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { requestID, id, contributors } = action.payload;

    const payload = {
      requestID,
      id,
      contributors,
    };

    axios
      .post(
        `${APIGW_URL}/interlocks/${state.auth.tenantID}/updatecontributors`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
        },
      )
      .then(res => {
        onContributorStatusUpdated(res, requestID, id, dispatch);
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const getResolvedTeamInterlocksLogic = createDefaultListQueryLogic({
  endpoint: {
    api: `${APIGW_URL}/interlocks`,
    method: 'teaminterlocks',
    additionalProperties: { filter: 'RECENTLY_COMPLETED' },
  },
  actionTypes: {
    fetch: types.FETCH_RESOLVED_TEAM_INTERLOCKS,
    success: types.RECEIVED_RESOLVED_TEAM_INTERLOCKS,
    fail: types.FAILED_RESOLVED_TEAM_INTERLOCKS,
  },
  selector: selectors.selectResolvedTeamInterlocks,
  sliceName: SLICE_NAME,
});

export const getResolvedUserInterlocksLogic = createDefaultListQueryLogic({
  endpoint: {
    api: `${APIGW_URL}/interlocks`,
    method: 'userinterlocks',
    additionalProperties: { filter: 'RECENTLY_COMPLETED' },
  },
  actionTypes: {
    fetch: types.FETCH_RESOLVED_USER_INTERLOCKS,
    success: types.RECEIVED_RESOLVED_USER_INTERLOCKS,
    fail: types.FAILED_RESOLVED_USER_INTERLOCKS,
  },
  selector: selectors.selectResolvedUserInterlocks,
  sliceName: SLICE_NAME,
});

export const getContributorChartLogic = createDefaultListQueryLogic({
  endpoint: {
    api: `${APIGW_URL}/interlocks`,
    method: 'contributorchart',
  },
  actionTypes: {
    fetch: types.FETCH_CONTRIBUTOR_CHART_INTERLOCKS,
    success: types.RECEIVED_CONTRIBUTOR_CHART_INTERLOCKS,
    fail: types.FAILED_CONTRIBUTOR_CHART_INTERLOCKS,
  },
  selector: selectors.selectContributorChartInterlocks,
  sliceName: SLICE_NAME,
});
