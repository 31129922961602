import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { peopleSelectors, peopleActions } from 'state/ducks/people';

import ChipAutocomplete from 'Components/Library/Shared/ChipAutocomplete';
import TeamChip from 'Components/Library/TeamChip';

function SearchOverlay(props) {
  const { filterFunc, onSelect, name } = props;
  const [searchString, setSearchString] = React.useState('');
  const { t } = useTranslation();

  const managedTeams = useSelector(
    state =>
      peopleSelectors.selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID),
    // { stabilityCheck: 'never' },
  );
  const teamNames = useSelector(state => peopleSelectors.selectTeamNames(state.main.people));
  const recentTeams = useSelector(state => peopleSelectors.selectRecentTeams(state.main.people));

  const dispatch = useDispatch();

  let suggestions = [];
  let multiSection = true;
  if (searchString !== '') {
    /* User is searching, only show the search results */
    const highPriorityMatches = [];
    const lowPriorityMatches = [];
    for (const [searchedId, teamName] of Object.entries(teamNames)) {
      if (teamName.toLowerCase().startsWith(searchString.toLocaleLowerCase())) {
        highPriorityMatches.push({ id: searchedId, text: teamName, type: 'team' });
      } else if (teamName.toLowerCase().includes(searchString.toLocaleLowerCase())) {
        lowPriorityMatches.push({ id: searchedId, text: teamName, type: 'team' });
      }
    }
    suggestions = highPriorityMatches.concat(lowPriorityMatches);
    multiSection = false;
  } else {
    suggestions.push({
      title: 'recent',
      suggestions: recentTeams
        .map(recentTeamId => ({
          type: 'team',
          id: recentTeamId,
          text: teamNames[recentTeamId],
        }))
        .filter(recentTeam => !!recentTeam.text && !!recentTeam.id),
    });
    suggestions.push({
      title: 'managed',
      suggestions: managedTeams
        .map(managedTeamId => ({
          id: managedTeamId,
          type: 'team',
          text: teamNames[managedTeamId],
        }))
        .filter(managedTeam => !!managedTeam.id && !!managedTeam.text),
    });
  }

  if (!!filterFunc) {
    // Apply filter func
    if (!multiSection) {
      suggestions = suggestions.filter(s => filterFunc(s));
    } else {
      Object.keys(suggestions).forEach(key => {
        suggestions[key].suggestions = suggestions[key].suggestions.filter(s => filterFunc(s));
      });
    }
  }
  const onSelectWrapper = suggestion => {
    dispatch(peopleActions.addRecentTeam({ teamId: suggestion.id }));
    onSelect(suggestion);
  };

  return (
    <ChipAutocomplete
      suggestions={suggestions}
      alwaysRenderSuggestions={multiSection}
      multiSection={multiSection}
      onSelect={onSelectWrapper}
      name={name}
      renderSuggestion={(suggestion, { isHighlighted }) => {
        if (suggestion.type === 'team' && !!suggestion.text) {
          return (
            <TeamChip
              name="team-selector-result"
              id={`team-selector-result-${suggestion.id}`}
              teamId={suggestion.id}
              color={isHighlighted ? 'grey' : 'transparent'}
              actionable
            />
          );
        }
        return null;
      }}
      onClearRequested={() => setSearchString('')}
      onFetchRequested={searchstring => setSearchString(searchstring)}
      id={props.id}
      placeholder={t('general.teamSearchLabel')}
    />
  );
}

SearchOverlay.propTypes = {
  onSelect: PropTypes.func,
  filterFunc: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
};

export default SearchOverlay;
