import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { interlocksActions, interlocksSelectors } from 'state/ducks/interlocks/index';
import DialogViewInterlock from 'Components/Features/Interlocks/DialogViewInterlock/DialogViewInterlock';
import { ERROR } from 'state/constants/api';

const fetchData = props => {
  const { interlockData, interlockID, dispatch } = props;
  if (interlockID && !interlockData?.ok) {
    dispatch(interlocksActions.fetchInterlock({ id: interlockID }));
  }
};

class DialogViewInterlockContainer extends React.Component {
  componentDidMount() {
    fetchData(this.props);
  }

  componentDidUpdate() {
    fetchData(this.props);
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { interlockData, open, interlockID, canDelete, zIndexModifier = 0, canEdit } = this.props;

    return (
      <DialogViewInterlock
        interlockData={interlockData}
        interlockID={interlockID}
        open={open}
        onClose={this.onClose}
        zIndexModifier={zIndexModifier}
        notFound={interlockData?.fetchStatus === ERROR}
        canDelete={canDelete}
        canEdit={canEdit}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const canDelete = interlocksSelectors.canDeleteInterlock({
    slice: state.main.interlocks,
    interlockID: ownProps.interlockID,
    userId: state.auth.userID,
    isChangeManager: state.auth.isChangeManager,
  });
  const canEdit = interlocksSelectors.canEditInterlock({
    slice: state.main.interlocks,
    interlockID: ownProps.interlockID,
    userId: state.auth.userID,
    isChangeManager: state.auth.isChangeManager,
  });
  const interlockData = interlocksSelectors.selectInterlock(
    state.main.interlocks,
    ownProps.interlockID,
  );

  return {
    interlockData,
    canDelete,
    canEdit,
  };
};

DialogViewInterlockContainer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  interlockID: PropTypes.string.isRequired,
  interlockData: PropTypes.object,
  zIndexModifier: PropTypes.number,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default connect(mapStateToProps)(DialogViewInterlockContainer);
