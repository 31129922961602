import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bind from './Bind';

class BindIdentity extends Component {
  // TODO: Fix location, currently not in use for bind

  render() {
    return <Bind name="identity" location={this.props.location} />;
  }
}

BindIdentity.propTypes = {
  location: PropTypes.object,
};

export default BindIdentity;
