import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';
import Form from 'Components/Library/Forms/Form';
import ObjectiveRelations from './ObjectiveRelations';

const schema = {
  type: 'object',
  properties: {
    parent: { type: ['string', 'null'] },
  },
  additionalProperties: true,
};
const handleFetch = props => {
  const { objectiveID, relations, dispatch, period } = props;
  if (!!objectiveID && !!period && period !== 'current') {
    dispatch(objectivesActions.getObjectiveLinks({ objectiveID, stperiod: period }));
  }
  if (!!objectiveID && !!relations && relations.ok) {
    const objectives = [...relations.children];
    if (relations.parent) {
      objectives.push(relations.parent);
    }
    const toFetch = [];
    for (const orng of objectives) {
      if (orng !== 'mission') {
        toFetch.push(orng);
      }
    }
    if (toFetch.length > 0) {
      dispatch(objectivesActions.getObjective({ objectiveIDs: toFetch, stperiod: period }));
    }
  }
};

class ObjectiveRelationsContainer extends React.Component {
  componentDidMount() {
    handleFetch(this.props);
  }

  render() {
    const { relations, objectiveID, period, canEdit, objectiveData } = this.props;
    if (!objectiveID || !objectiveData || !objectiveData.ok) {
      return null;
    }
    return (
      <Form
        schema={schema}
        additionalProperties={{ objectiveID }}
        name="panel-view-objective-parent"
        debouncedAutoSubmit={100}
        initialValues={{ parent: get(relations, 'parent') }}
        submitActionCreator={objectivesActions.updateObjectiveParent}
        stateSlice="main.objectives"
      >
        <ObjectiveRelations
          period={period}
          canEdit={canEdit}
          relations={relations}
          objectiveID={objectiveID}
          objectiveData={objectiveData}
        />
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  relations: objectivesSelectors.selectRelations(
    state.main.objectives,
    ownProps.objectiveID,
    ownProps.period,
  ),
});

ObjectiveRelationsContainer.propTypes = {
  objectiveID: PropTypes.string,
  relations: PropTypes.object,
  period: PropTypes.string,
  canEdit: PropTypes.bool,
  objectiveData: PropTypes.object,
};

export default connect(mapStateToProps)(ObjectiveRelationsContainer);
