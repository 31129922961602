import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function WillNotDoIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
      <path
        d="M12 4.5C7.86 4.5 4.5 7.86 4.5 12C4.5 16.14 7.86 19.5 12 19.5C16.14 19.5 19.5 16.14 19.5 12C19.5 7.86 16.14 4.5 12 4.5ZM12 18C8.685 18 6 15.315 6 12C6 10.6125 6.4725 9.3375 7.2675 8.325L15.675 16.7325C14.6625 17.5275 13.3875 18 12 18ZM16.7325 15.675L8.325 7.2675C9.3375 6.4725 10.6125 6 12 6C15.315 6 18 8.685 18 12C18 13.3875 17.5275 14.6625 16.7325 15.675Z"
        fill="black"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}

export default WillNotDoIcon;
