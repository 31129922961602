import * as types from './types';

export const getPersons = payload => ({
  type: types.GET_PERSON,
  payload,
});

export const getPersonsFailed = payload => ({
  type: types.FAILED_PERSON,
  payload,
});

export const personsReceived = payload => ({
  type: types.RECEIVED_PERSON,
  payload,
});

export const getList = payload => ({
  type: types.GET_USER_LIST,
  payload,
});

export const getListFailed = payload => ({
  type: types.FAILED_USER_LIST,
  payload,
});

export const receivedList = payload => ({
  type: types.RECEIVED_USER_LIST,
  payload,
});

export const searchPeople = payload => ({
  type: types.SEARCH_PEOPLE,
  payload,
});

export const searchedPeopleReceived = payload => ({
  type: types.RECEIVED_SEARCHED_PEOPLE,
  payload,
});

export const getHierarchy = payload => ({
  type: types.GET_ORG_HIERARCHY,
  payload,
});

export const hierarchyReceived = payload => ({
  type: types.RECEIVED_ORG_HIERARCHY,
  payload,
});

export const getTeam = payload => ({
  type: types.GET_TEAM,
  payload,
});

export const getTeamFailed = payload => ({
  type: types.FAILED_TEAM,
  payload,
});

export const teamReceived = payload => ({
  type: types.RECEIVED_TEAM,
  payload,
});

export const editPersonAttributes = payload => ({
  type: types.EDIT_PERSON_ATTRIBUTES,
  payload,
});

export const personAttributesEdited = payload => ({
  type: types.EDITED_PERSON_ATTRIBUTES,
  payload,
});

export const createPerson = payload => ({
  type: types.CREATE_PERSON,
  payload,
});

export const personCreated = payload => ({
  type: types.CREATED_PERSON,
  payload,
});

export const createTeam = payload => ({
  type: types.CREATE_TEAM,
  payload,
});

export const teamCreated = payload => ({
  type: types.CREATED_TEAM,
  payload,
});

export const apiError = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});

export const getUserPermissions = payload => ({
  type: types.GET_USER_PERMISSIONS,
  payload,
});

export const getUserPermissionsFailed = payload => ({
  type: types.FAILED_USER_PERMISSIONS,
  payload,
});

export const receivedUserPermissions = payload => ({
  type: types.RECEIVED_USER_PERMISSIONS,
  payload,
});

export const editUserPermissions = payload => ({
  type: types.EDIT_USER_PERMISSIONS,
  payload,
});

export const editedUserPermissions = payload => ({
  type: types.EDITED_USER_PERMISSIONS,
  payload,
});

export const editUserTeam = payload => ({
  type: types.EDIT_USERS_TEAM,
  payload,
});

export const editedUserTeam = payload => ({
  type: types.USERS_TEAM_EDITED,
  payload,
});

export const editUserMatrixTeam = payload => ({
  type: types.EDIT_USERS_MATRIX_TEAM,
  payload,
});

export const editedUserMatrixTeam = payload => ({
  type: types.USERS_MATRIX_TEAM_EDITED,
  payload,
});

export const detachUserMatrixTeam = payload => ({
  type: types.DETACH_USER_MATRIX_TEAM,
  payload,
});

export const detachedMatrixTeam = payload => ({
  type: types.DETACHED_USER_MATRIX_TEAM,
  payload,
});

export const editCeo = payload => ({
  type: types.EDIT_CEO,
  payload,
});

export const editedCeo = payload => ({
  type: types.EDITED_CEO,
  payload,
});

export const setAvatar = payload => ({
  type: types.SET_AVATAR,
  payload,
});

export const avatarSet = payload => ({
  type: types.AVATAR_SET,
  payload,
});

export const editTeamManager = payload => ({
  type: types.EDIT_TEAM_MANAGER,
  payload,
});

export const editedTeamManager = payload => ({
  type: types.EDITED_TEAM_MANAGER,
  payload,
});

export const editTeamParent = payload => ({
  type: types.EDIT_TEAM_PARENT,
  payload,
});

export const editedTeamParent = payload => ({
  type: types.EDITED_TEAM_PARENT,
  payload,
});

export const editTeamName = payload => ({
  type: types.EDIT_TEAM_NAME,
  payload,
});

export const editedTeamName = payload => ({
  type: types.EDITED_TEAM_NAME,
  payload,
});

export const deleteTeam = payload => ({
  type: types.DELETE_TEAM,
  payload,
});

export const deletedTeam = payload => ({
  type: types.DELETED_TEAM,
  payload,
});

export const deleteUser = payload => ({
  type: types.DELETE_USER,
  payload,
});

export const deletedUser = payload => ({
  type: types.DELETED_USER,
  payload,
});

export const getMatrices = () => ({
  type: types.GET_MATRICES,
});

export const matricesReceived = payload => ({
  type: types.MATRICES_RECEIVED,
  payload,
});

export const getMatrixHierarchy = payload => ({
  type: types.GET_MATRIX_HIERARCHY,
  payload,
});

export const matrixHierarchyReceived = payload => ({
  type: types.MATRIX_HIERARCHY_RECEIVED,
  payload,
});

export const getFollowedTeams = () => ({
  type: types.GET_FOLLOWED_TEAMS,
});

export const followTeam = payload => ({
  type: types.FOLLOW_TEAM,
  payload,
});

export const unFollowTeam = payload => ({
  type: types.UNFOLLOW_TEAM,
  payload,
});

export const requestToJoinTeam = payload => ({
  type: types.REQUEST_TO_JOIN_TEAM,
  payload,
});

export const approveJoinRequest = payload => ({
  type: types.APPROVE_JOIN_REQUEST,
  payload,
});

export const denyJoinRequest = payload => ({
  type: types.DENY_JOIN_REQUEST,
  payload,
});

export const leaveTeam = payload => ({
  type: types.LEAVE_TEAM,
  payload,
});

export const getMyJoinRequests = payload => ({
  type: types.GET_MY_JOIN_REQUESTS,
  payload,
});

export const getTeamJoinRequests = payload => ({
  type: types.GET_TEAM_JOIN_REQUESTS,
  payload,
});

export const addRecentTeam = payload => ({
  type: types.ADD_RECENT_TEAM,
  payload,
});

export const addRecentUser = payload => ({
  type: types.ADD_RECENT_USER,
  payload,
});
