import React from 'react';
import PropTypes from 'prop-types';
import { idRegex } from 'state/ducks/objectives/helpers';

import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import KeyresultChip from 'Components/Features/Objectives/KeyresultChip';
import CommitmentChip from 'Components/Features/Commitments/CommitmentChip';
import InterlockChip from 'Components/Features/Interlocks/InterlockChip';
import LinkChip from '../NodeRelations/LinkChip';

function GraphElementChip({ graphId, ...other }) {
  const nodeTypePrefix = graphId.split('_')[0];

  switch (nodeTypePrefix) {
    case 'OBJ':
      if (idRegex.test(graphId)) {
        return <KeyresultChip keyresultID={graphId} {...other} />;
      }
      return <ObjectiveChip objectiveID={graphId} {...other} />;
    case 'COM':
      return <CommitmentChip graphId={graphId} {...other} />;
    case 'INTERLOCK':
      return <InterlockChip graphId={graphId} {...other} />;
    case 'RELEXTLINK':
      return <LinkChip graphId={graphId} {...other} />;
    default:
      return null;
  }
}

GraphElementChip.propTypes = {
  graphId: PropTypes.string,
};

export default GraphElementChip;
