/* eslint-disable func-names */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const stringsEn = require('./strings-en.json');
const stringsFi = require('./strings-fi.json');
const stringsFr = require('./strings-fr.json');

export const STRINGS = {
  ...stringsEn,
  ...stringsFi,
  ...stringsFr,
};

const I18N_DETECTOR_OPTS = {
  // order and from where user language should be detected
  order: ['localStorage', 'cookie', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
};

export const LANGS = [
  { code: 'en', localname: 'English' },
  { code: 'fi', localname: 'Suomi' },
  { code: 'fr', localname: 'Français' },
];

const i18nparams = {
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'en',
  resources: STRINGS,
  react: {
    useSuspense: true,
    transSupportBasicHtmlNodes: true,
    bindI18n: 'loaded languageChanged',
    bindI18nStore: 'onLoaded onAdded',
  },
};

if (
  process.env.REACT_APP_STAGE === 'alpha' ||
  process.env.REACT_APP_STAGE === 'test' ||
  process.env.NODE_ENV === 'development'
) {
  i18nparams.missingKeyHandler = (lng, ns, key, fallbackValue, updateMissing, options) => {
    // testing especially one key
    // eslint-disable-next-line no-console
    console.error(
      'Missing localization key: ',
      lng,
      ns,
      key,
      fallbackValue,
      updateMissing,
      options,
    );
    return key;
  };
  i18nparams.saveMissing = true;
}

export const initDictionaryOverride = function (dictionaryOverrides) {
  // eslint-disable-next-line no-console
  console.log(`Adding custom dictionary: ${dictionaryOverrides}`);
  Object.keys(dictionaryOverrides).forEach(lng =>
    i18n.addResourceBundle(
      lng,
      'translation',
      { dictionary: dictionaryOverrides[lng] },
      true,
      true,
    ),
  );
};

export const addDictionary = function (dictName) {
  // eslint-disable-next-line no-console
  console.log(`Adding dictionary: ${dictName}`);
  if (i18n.hasResourceBundle('en', 'translation')) {
    i18n.addResourceBundle(
      'en',
      'translation',
      { dictionary: stringsEn.en.dictionary[dictName] },
      true,
      true,
    );
    i18n.addResourceBundle(
      'fr',
      'translation',
      { dictionary: stringsFr.fr.dictionary[dictName] },
      true,
      true,
    );
    i18n.addResourceBundle(
      'fi',
      'translation',
      { dictionary: stringsFi.fi.dictionary[dictName] },
      true,
      true,
    );
  }
};
const languageDetector = new LanguageDetector(null, I18N_DETECTOR_OPTS);
i18n.on('initialized', function () {
  addDictionary('default');
});
i18n.use(languageDetector).use(initReactI18next).init(i18nparams);

export default i18n;
