export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const RECEIVED_COMMENTS = 'RECEIVED_COMMENTS';
export const FAILED_COMMENTS = 'FAILED_COMMENTS';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const EDITED_COMMENT = 'EDITED_COMMENT';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADDED_COMMENT = 'ADDED_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETED_COMMENT = 'DELETED_COMMENT';
export const ERROR_RECEIVED_FROM_API = 'INBOX_API_ERROR_RECEIVED';
