import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import withStyles from '@mui/styles/withStyles';
import { withLocation, withNavigation } from 'withRouter';
import Fab from 'Components/Common/Fab';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import { openAddCommitmentPanel } from 'config/ModalProvider/helpers';
import { NODE_TYPE_TEAM } from 'config/constants';

const styles = theme => ({
  fabIcon: {
    color: theme.palette.secondary.contrastText,
  },
});

function AddCommitmentButton(props) {
  const { t } = useTranslation();
  const { classes, variant, navigate, location, teamId } = props;

  const ButtonElement = variant === 'fab' ? Fab : ContainedIconButton;

  const buttonProps = variant === 'contained' ? { size: 'small' } : {};
  return (
    <ButtonElement
      {...buttonProps}
      name="add-commitment-fab"
      color="secondary"
      onClick={() =>
        openAddCommitmentPanel(navigate, location, [{ type: NODE_TYPE_TEAM, id: teamId }])
      }
      aria-label={t('general.add')}
    >
      <AddIcon className={classes.fabIcon} />
    </ButtonElement>
  );
}

AddCommitmentButton.propTypes = {
  classes: PropTypes.exact({
    fabIcon: PropTypes.string,
  }),
  location: PropTypes.object,
  navigate: PropTypes.func,
  teamId: PropTypes.string,
  variant: PropTypes.string,
};

AddCommitmentButton.defaultProps = {
  variant: 'fab',
};

export default withLocation(
  withNavigation(withStyles(styles, { withTheme: true })(AddCommitmentButton)),
);
