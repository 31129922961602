import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DELETED } from 'state/constants/api';
import CanEditObjectiveWrapper from 'Components/Features/Objectives/CanEditObjectiveWrapper';
import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';
import PanelViewObjective from './PanelViewObjective';

const fetchData = props => {
  const { objectiveData, objectiveID, dispatch } = props;
  if (objectiveID && (!objectiveData || !objectiveData.ok)) {
    dispatch(objectivesActions.getObjective({ objectiveIDs: [objectiveID] }));
  }
};

class PanelViewObjectiveContainer extends React.Component {
  state = {
    deleted: false,
  };

  static getDerivedStateFromProps(props) {
    const { deleted } = props;
    if (!!deleted) {
      return { deleted };
    }
    return null;
  }

  componentDidMount() {
    fetchData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!this.state.deleted && !prevState.deleted) {
      this.onClose();
    } else {
      fetchData(this.props);
    }
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <CanEditObjectiveWrapper
        render={canEdit => (
          <PanelViewObjective
            objectiveData={this.props.objectiveData}
            objectiveID={this.props.objectiveID}
            canEdit={canEdit}
            open={this.props.open}
            onClose={this.onClose}
            period={this.props.period}
            zIndexModifier={this.props.zIndexModifier}
          />
        )}
        objectiveData={this.props.objectiveData}
        objectiveID={this.props.objectiveID}
        canEdit={this.props.canEdit}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const objectiveData = objectivesSelectors.selectObjective(
    state.main.objectives,
    ownProps.objectiveID,
  );

  return {
    objectiveData,
    deleted: !!objectiveData && objectiveData.fetchStatus === DELETED,
    period:
      ownProps.period === '-'
        ? objectivesSelectors.selectBestStPeriodForObjective(
            state.main.objectives,
            ownProps.objectiveID,
          )
        : ownProps.period,
  };
};

PanelViewObjectiveContainer.propTypes = {
  onClose: PropTypes.func,
  canEdit: PropTypes.bool,
  open: PropTypes.bool,
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  period: PropTypes.string,
  deleted: PropTypes.bool,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(PanelViewObjectiveContainer);
