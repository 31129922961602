import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { nanoid } from 'nanoid';
import { programsSelectors } from 'state/ducks/programs';
import { insightsActions } from 'state/ducks/insights';

import { validateText } from 'config/helpers';
import PanelCreateInterpretation from './PanelCreateInterpretation';

class PanelCreateInterpretationContainer extends Component {
  state = {
    submitStatus: 0,
    submitted: false,
    canSubmit: false,
    trafficLights: false,
    questions: [],
    trafficLightOptions: {
      green: this.props.t('csi.trafficLightOptions.green'),
      yellow: this.props.t('csi.trafficLightOptions.yellow'),
      red: this.props.t('csi.trafficLightOptions.red'),
    },
    trafficLightQuestion: this.props.t('csi.trafficLightQuestion'),
    name: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (!!prevState.submitted && !this.state.submitted) {
      if (this.state.submitStatus === 1) {
        // done
        this.onClose();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.submitted === true) {
      if (state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[state.requestID].result === 'ok') {
          return {
            submitStatus: 1,
            submitted: false,
          };
        }
        return {
          submitStatus: -1,
          submitted: false,
        };
      }
    }
    return null;
  }

  onSubmit = () => {
    if (this.state.canSubmit) {
      const requestID = nanoid(10);
      this.setState({ submitted: true, submitStatus: 0, requestID });
      const payload = {
        cpID: this.props.program.cpID,
        requestID,
        name: this.state.name,
        questions: this.state.questions,
      };
      if (!!this.state.trafficLights) {
        payload.trafficLights = true;
        payload.green = this.state.trafficLightOptions.green;
        payload.yellow = this.state.trafficLightOptions.yellow;
        payload.red = this.state.trafficLightOptions.red;
        payload.trafficLightQuestion = this.state.trafficLightQuestion;
      } else {
        payload.trafficLights = false;
      }
      this.props.dispatch(insightsActions.createInterpretationTopic(payload));
    }
  };

  validate = () => {
    let canSubmit = true;
    if (this.state.questions.length > 0 && validateText(this.state.name, 4)) {
      for (const question of this.state.questions) {
        if (!validateText(question, 4)) {
          canSubmit = false;
        }
      }
    } else {
      canSubmit = false;
    }
    this.setState({ canSubmit });
  };

  deleteQuestion = i => {
    const qs = [...this.state.questions];
    qs.splice(i, 1);
    this.setState({ questions: qs });
    this.validate();
  };

  onQuestionTextChange = (i, event) => {
    const qs = [...this.state.questions];
    qs[i] = event.target.value;
    this.setState({ questions: qs });
    this.validate();
  };

  onTrafficLightOptionChange = (label, event) => {
    const qs = { ...this.state.trafficLightOptions };
    qs[label] = event.target.value;
    this.setState({ trafficLightOptions: qs });
    this.validate();
  };

  onTrafficLightQuestionChange = event => {
    this.setState({ trafficLightQuestion: event.target.value });
  };

  addQuestion = () => {
    const newState = { questions: [...this.state.questions] };
    newState.questions.push('');
    this.setState(newState);
    this.validate();
  };

  onNameChange = event => {
    this.setState({ name: event.target.value });
    this.validate();
  };

  toggleTrafficLights = event => {
    this.setState({ trafficLights: event.target.checked });
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    if (!this.props.program) {
      return null;
    }
    return (
      <PanelCreateInterpretation
        submitted={this.state.submitted}
        submitStatus={this.state.submitStatus}
        submitDisabled={!this.state.canSubmit}
        questions={this.state.questions}
        trafficLightOptions={this.state.trafficLightOptions}
        trafficLights={this.state.trafficLights}
        trafficLightQuestion={this.state.trafficLightQuestion}
        name={this.state.name}
        deleteQuestion={this.deleteQuestion}
        onQuestionTextChange={this.onQuestionTextChange}
        onNameChange={this.onNameChange}
        addQuestion={this.addQuestion}
        toggleTrafficLights={this.toggleTrafficLights}
        onTrafficLightOptionChange={this.onTrafficLightOptionChange}
        onTrafficLightQuestionChange={this.onTrafficLightQuestionChange}
        open={this.props.open}
        onClose={this.onClose}
        program={this.props.program}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        zIndexModifier={this.props.zIndexModifier}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isChangeManager: state.auth.isChangeManager,
  program: programsSelectors.selectProgramById(state.main.programs, ownProps.cpID),
  actionlog: state.main.insights.actionlog,
});

PanelCreateInterpretationContainer.propTypes = {
  open: PropTypes.bool,
  actionlog: PropTypes.object,
  program: PropTypes.object,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  t: PropTypes.func,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(connect(mapStateToProps)(PanelCreateInterpretationContainer));
