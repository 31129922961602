import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

function SaveCommentButton({ onClick, disabled }) {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      variant="contained"
      color="secondary"
      disabled={disabled}
      sx={{
        borderRadius: 1,
        minWidth: 10,
        paddingLeft: { xs: '8px', sm: '14px' },
        paddingRight: { xs: '8px', sm: '14px' },
      }}
      onClick={onClick}
      id="save-comment-button"
    >
      {isMobile ? (
        <SendIcon color="primary.contrastText" fontSize="small" sx={{ fontSize: '18px' }} />
      ) : (
        t('general.comments.commentButton')
      )}
    </Button>
  );
}

SaveCommentButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SaveCommentButton;
