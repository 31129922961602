import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrevIcon from '@mui/icons-material/KeyboardArrowLeft';
import NextIcon from '@mui/icons-material/KeyboardArrowRight';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import PeriodSelector from 'Components/Features/Objectives/PeriodSelector';

const periodFilterFunc = p => {
  // Filter out long term periods
  if (!p.stPeriodId) {
    return false;
  }
  return true;
};

function PeriodNavigator(props) {
  const {
    selectedPeriod,
    nextPeriod,
    prevPeriod,
    handlePeriodChange,
    t,
    color,
    floating,
    fullWidth,
  } = props;

  const buttonBg = color === 'white' ? 'background.paper' : 'background.box';

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', height: 24 }}
      onClick={e => e.preventDefault()}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '8px',
          height: 24,
          flexGrow: 0,
          flexBasis: 'fit-content',
          boxShadow: floating ? 1 : 0,
          mr: 1,
        }}
      >
        <ButtonBase
          disabled={!prevPeriod}
          sx={{
            height: 24,
            border: 0,
            borderRadius: '8px',
            pl: 1,
            pr: 1,
            minWidth: 26,
            backgroundColor: buttonBg,
            cursor: 'pointer',
            color: 'action.active',
            fontSize: '16px',
            '&:hover': {
              boxShadow: theme =>
                `inset 0px 0px 0px 100px ${theme.palette.action.hover} !important`,
            },
            '&:focus': {
              boxShadow: theme =>
                `inset 0px 0px 0px 100px ${theme.palette.action.focus} !important`,
            },
            '&.Mui-disabled': {
              color: 'action.disabled',
            },
          }}
          onClick={() => handlePeriodChange({ stPeriodId: prevPeriod })}
          aria-label={t('general.previous')}
        >
          <PrevIcon fontSize="inherit" color="action" />
        </ButtonBase>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '8px',
          height: 24,
          flexGrow: 0,
          flexBasis: fullWidth ? '100%' : 'fit-content',
          boxShadow: floating ? 1 : 0,
          display: fullWidth ? 'flex' : 'block',
          minWidth: '1px', // Needed for ellipsis to work
        }}
      >
        <PeriodSelector
          onSelect={handlePeriodChange}
          value={{ stPeriodId: selectedPeriod }}
          filterFunc={periodFilterFunc}
          IconComponent={ExpandMoreIcon}
          variant="normal"
          color={color}
          sx={fullWidth ? { flexGrow: 10 } : {}}
          showPast
        />
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '8px',
          height: 24,
          flexGrow: 0,
          flexBasis: 'fit-content',
          boxShadow: floating ? 1 : 0,
          ml: 1,
        }}
      >
        <ButtonBase
          disabled={!nextPeriod}
          sx={{
            height: 24,
            border: 0,
            borderRadius: '8px',
            pl: 1,
            pr: 1,
            minWidth: 26,
            backgroundColor: buttonBg,
            cursor: 'pointer',
            color: 'action.active',
            fontSize: '16px',
            '&:hover': {
              boxShadow: theme =>
                `inset 0px 0px 0px 100px ${theme.palette.action.hover} !important`,
            },
            '&:focus': {
              boxShadow: theme =>
                `inset 0px 0px 0px 100px ${theme.palette.action.focus} !important`,
            },
            '&.Mui-disabled': {
              color: 'action.disabled',
            },
          }}
          onClick={() => handlePeriodChange({ stPeriodId: nextPeriod })}
          aria-label={t('general.next')}
        >
          <NextIcon fontSize="inherit" color="action" />
        </ButtonBase>
      </Box>
    </Box>
  );
}

PeriodNavigator.propTypes = {
  selectedPeriod: PropTypes.string,
  nextPeriod: PropTypes.string,
  prevPeriod: PropTypes.string,
  handlePeriodChange: PropTypes.func,
  t: PropTypes.func,
  color: PropTypes.string,
  floating: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

PeriodNavigator.defaultProps = {
  color: 'grey',
  floating: false,
  fullWidth: false,
};

export default withTranslation()(PeriodNavigator);
