import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { idRegex } from 'state/ducks/objectives/helpers';
import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';
import { interlocksSelectors, interlocksActions } from 'state/ducks/interlocks';
import { commitmentsSelectors, commitmentsActions } from 'state/ducks/commitments';

function GraphElementName({ graphId }) {
  const nodeTypePrefix = graphId.split('_')[0];
  const dispatch = useDispatch();
  let selector = () => {};
  let slice = '';
  let nameValuePath = [];

  switch (nodeTypePrefix) {
    case 'OBJ':
      // eslint-disable-next-line no-case-declarations
      let objectiveID;
      if (idRegex.test(graphId)) {
        objectiveID = graphId.split('-')[0];
        selector = objectivesSelectors.selectKeyresult;
        nameValuePath = ['keyresult'];
      } else {
        selector = objectivesSelectors.selectObjective;
        nameValuePath = ['data', 'objective'];
        objectiveID = graphId;
      }

      dispatch(objectivesActions.getObjective({ objectiveIDs: [objectiveID] }));
      slice = 'objectives';
      break;
    case 'COM':
      dispatch(commitmentsActions.fetchCommitment({ id: graphId }));
      selector = commitmentsSelectors.selectCommitment;
      nameValuePath = ['data', 'name'];
      slice = 'commitments';
      break;
    case 'INTERLOCK':
      dispatch(interlocksActions.fetchInterlock({ id: graphId }));
      selector = interlocksSelectors.selectInterlock;
      nameValuePath = ['data', 'name'];
      slice = 'interlocks';
      break;
    default:
      break;
  }
  const data = useSelector(state => selector(state.main[slice], graphId));
  return get(data, nameValuePath, '');
}

GraphElementName.propTypes = {
  graphId: PropTypes.string,
};

export default GraphElementName;
