import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { peopleActions, peopleSelectors } from 'state/ducks/people';
import { handleMentions } from '../helpers';
import FormContext from '../FormContext';

const EMPTY_DOC = { type: 'doc', content: [{ type: 'paragraph' }] };

const FormRichTextField = React.memo(props => {
  const {
    render,
    fieldName,
    // The rich text editor would dispatch events on load
    // if provided undefined as value:
    value = EMPTY_DOC,
    formName,
    onFieldChange,
    error,
    helperText,
    isRequired,
    registerRef,
  } = props;

  // Form expects all it's fields to be controlled, but the remirror
  // editor is unusably slow in controlled mode as it's not a native
  // React library.

  // We will handle emulating "controlled" functionality in this connector

  // Create a ref to store the latest known value coming from the editor
  const _v = useRef(value);

  // Create a ref used to communicate with the remirror context
  const handleRef = useRef();
  registerRef(fieldName, handleRef);

  if (!isEqual(_v.current, value) && handleRef.current) {
    // The incoming value has deviated from the last known value.
    // This means that the form received new data, and we should update
    // the content of the rich text editor to match.

    // The Form has the necessary logic to not have this happen unless
    // it is desired.

    handleRef.current.setContent(value);
  }

  const fieldProps = {
    onChange(parameter) {
      const { state } = parameter;
      const document = state.doc;
      // remirror dispatches onChange events for a lot of things, the content
      // has not necessarily changed.
      const jsonDoc = document.toJSON();
      if (!isEqual(jsonDoc, value)) {
        _v.current = jsonDoc;
        onFieldChange(fieldName, jsonDoc);
      }
    },
    value,
    name: `${formName}-${fieldName}`,
    error,
    required: isRequired,
    helperText,
    handleRef,
  };
  return render(fieldProps);
});

function OuterFormRichTextField(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormRichTextField that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render, fieldName } = props;

  const { onFieldChange, values, formName, fieldErrors, showErrors, schema, registerRef } =
    React.useContext(FormContext);

  const value = get(values, fieldName);

  /* Fetch names for mentioned users and defer rendering until the data
     is available
  */
  const dispatch = useDispatch();

  const peopleSlice = useSelector(state => state.main.people);
  const selectUser = sub => peopleSelectors.selectUser(peopleSlice, sub);

  const [parsedValue, missingUsers] = handleMentions(value, selectUser);

  if (missingUsers.length) {
    dispatch(peopleActions.getPersons({ subs: missingUsers }));
    return null;
  }
  const isRequired = get(schema, 'required', []).includes(fieldName);

  return (
    <FormRichTextField
      fieldName={fieldName}
      render={render}
      onFieldChange={onFieldChange}
      value={parsedValue}
      formName={formName}
      isRequired={isRequired}
      error={!!showErrors && !!fieldErrors && fieldName in fieldErrors}
      helperText={
        !!showErrors && !!fieldErrors && fieldName in fieldErrors ? fieldErrors[fieldName] : ''
      }
      registerRef={registerRef}
    />
  );
}

OuterFormRichTextField.propTypes = {
  render: PropTypes.func,
  fieldName: PropTypes.string,
};

export default OuterFormRichTextField;
