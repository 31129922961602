import React from 'react';
import PropTypes from 'prop-types';
import StandardDialog from 'Components/Library/StandardDialog';
import NotFoundPanelContent from 'Components/Common/NotFoundPanelContent';
import ObjectivesTablev2 from 'Components/Features/Objectives/ObjectivesTablev2';
import useDomainOkrs from 'hooks/useDomainOkrs';

function DialogObjectives(props) {
  const {
    open,
    onExpand,
    period,
    domain,
    objectivePeriodConfig,
    isValidTeam,
    zIndexModifier = 0,
    onClose,
  } = props;

  const { okrs } = useDomainOkrs([domain], period);

  if (!domain) {
    return null;
  }

  let content;
  if (domain.t === 'team' && !isValidTeam) {
    content = <NotFoundPanelContent />;
  } else {
    content = objectivePeriodConfig.ok && (
      <ObjectivesTablev2
        period={period}
        domain={domain}
        okrData={okrs[0]}
        open
        onClose={onClose}
        onExpand={onExpand}
        renderHeader
        showControls
        alwaysOpen
        showLastModified={true}
      />
    );
  }

  return (
    <StandardDialog
      open={open}
      name="dialog-team-objectives"
      onBackdropClick={onClose}
      disablePortal
      zIndexModifier={zIndexModifier}
      size="large"
    >
      {content}
    </StandardDialog>
  );
}

DialogObjectives.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
  period: PropTypes.string,
  domain: PropTypes.object,
  objectivePeriodConfig: PropTypes.object,
  isValidTeam: PropTypes.bool,
  zIndexModifier: PropTypes.number,
};

export default DialogObjectives;
