import React, { MouseEventHandler, useMemo, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import Panel from 'Components/Library/BaseComponents/Panel';
import RootDndContextProvider from 'Components/Library/DragAndDrop/RootDndContext';
import NestedDndContext from 'Components/Library/DragAndDrop/NestedDndContext';
import useCanCreateForDomain from 'hooks/useCanCreateForDomain';
import HelperText from 'Components/Common/HelperText';
import ObjectiveChip from '../ObjectiveChip';
import CanEditObjectiveWrapper from '../CanEditObjectiveWrapper';
import KeyresultsList from '../KeyresultsList';
import TableHeader from './TableHeader';
import TableColumnNames from './TableColumnNames';

import { isObjectiveOwned } from 'state/ducks/objectives/helpers';
import { computeMetadata } from './helpers';
import { IDomainData, NodeDomain } from 'state/ducks/objectives/types';
import useObjectiveSorting from 'hooks/useObjectiveSorting';
interface ObjectivesTablev2Props {
  domain: NodeDomain;
  okrData: IDomainData;
  period: string;
  renderHeader?: boolean;
  canToggle?: boolean;
  open?: boolean;
  showControls?: boolean;
  showDomainName?: boolean;
  showLastModified?: boolean;
  onExpand?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLButtonElement>;
}

const staticRowStyle = {
  '& > *': {
    borderTop: '1px solid',
    borderColor: theme => theme.palette.divider,
    pt: 0.5,
    pb: 0.5,
    boxSizing: 'border-box',
  },
  display: 'table-row',
};

const draggedRowStyle = {
  display: 'table',
  backgroundColor: 'background.paper',
  boxShadow: 5,
  borderRadius: 1,
  pt: 0.5,
  pb: 0.5,
  borderSpacing: 0,
};

function ObjectivesTablev2(props: ObjectivesTablev2Props) {
  const {
    domain,
    period,
    renderHeader,
    okrData,
    canToggle = false,
    open,
    showControls = false,
    showLastModified = false,
    showDomainName,
    onExpand,
    onClose,
  } = props;

  const [isMinimized, setIsMinimized] = useState(canToggle ? !Boolean(open) : false);

  const { t } = useTranslation();

  const canCreateForDomain = useCanCreateForDomain(domain);
  const canOrder = ['team', 'company'].includes(domain.t) && canCreateForDomain;

  const metadata = renderHeader ? computeMetadata(okrData?.data, domain) : null;
  const domainStr = useMemo(() => `${domain.t}-${domain.d}`, [domain]);
  const { onDragEnd, getDraggableId } = useObjectiveSorting(okrData, domain, period, domainStr);

  return (
    <RootDndContextProvider>
      <NestedDndContext onDragEnd={onDragEnd} droppableId={`okr-table-droppable-${domainStr}`}>
        <Panel sx={{ backgroundColor: 'background.paper' }} alignItems="stretch" m={1}>
          {renderHeader && (
            <TableHeader
              canToggle={canToggle}
              open={open}
              showControls={showControls}
              metadata={metadata}
              domain={domain}
              period={period}
              showDomainName={showDomainName}
              onExpand={onExpand}
              onClose={onClose}
              isMinimized={isMinimized}
              setIsMinimized={setIsMinimized}
            />
          )}
          {!isMinimized && (
            <Box
              component="table"
              sx={{
                tableLayout: 'fixed',
                width: '100%',
              }}
            >
              <TableColumnNames showLastModified={showLastModified} />
              {!!okrData && okrData.data && okrData.data.length > 0 && (
                <Droppable droppableId={`okr-table-droppable-${domainStr}`} type="objective">
                  {(provided, snapshot) => (
                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                      {okrData.data.map((data, ind) => {
                        if (data.ok) {
                          return (
                            <Draggable
                              draggableId={getDraggableId(data.data.objectiveID)}
                              index={ind}
                              key={getDraggableId(data.data.objectiveID)}
                              isDragDisabled={!canOrder}
                              disableInteractiveElementBlocking
                            >
                              {(prov, snap) => (
                                <Box
                                  component="tr"
                                  ref={prov.innerRef}
                                  {...prov.draggableProps}
                                  {...prov.dragHandleProps}
                                  data-tg-name={`okr-table-item-${domainStr}-${data.data.objectiveID}`}
                                  style={prov.draggableProps.style}
                                  sx={snap.isDragging ? draggedRowStyle : staticRowStyle}
                                >
                                  <CanEditObjectiveWrapper
                                    objectiveData={data}
                                    render={canEdit => (
                                      <>
                                        <Box
                                          component="td"
                                          sx={{
                                            display: 'table-cell',
                                            verticalAlign: 'top',
                                            width: { xs: '100%', sm: '33%' },
                                          }}
                                        >
                                          <ObjectiveChip
                                            objectiveData={data}
                                            overline={{
                                              period: true,
                                              domain: domain.t === 'personal',
                                            }}
                                            stperiod={period}
                                            allowNavigate
                                            ContextIcon={null}
                                            wrapText
                                            sx={{
                                              opacity: isObjectiveOwned(domain, data.data)
                                                ? 1
                                                : 0.5,
                                              width: '100%',
                                            }}
                                            color="transparent"
                                          />
                                        </Box>
                                        <KeyresultsList
                                          canEditObjective={canEdit}
                                          objectiveData={data}
                                          uniqueContextIdentifier={domainStr}
                                          enableAddButton
                                          keyresultChipProps={{
                                            sx: { flexGrow: 100 },
                                            ContextIcon: null,
                                          }}
                                          stackProps={{ spacing: 0.25, alignItems: 'stretch' }}
                                          sx={{
                                            display: { xs: 'none', sm: 'table-cell' },
                                            verticalAlign: { xs: 'inherit', sm: 'middle' },
                                            justifyContent: 'center',
                                            pl: 1,
                                            width: { xs: '0%', sm: '67%' },
                                          }}
                                          showLastModified={showLastModified}
                                          component="td"
                                        />
                                      </>
                                    )}
                                  />
                                </Box>
                              )}
                            </Draggable>
                          );
                        }
                        return null;
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              )}

              {!!okrData && okrData.data && okrData.data.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    <Panel sx={{ mt: 2 }}>
                      <HelperText>{t('objectives.noobjectivesyetinfotext')}</HelperText>
                    </Panel>
                  </td>
                </tr>
              )}
            </Box>
          )}
        </Panel>
      </NestedDndContext>
    </RootDndContextProvider>
  );
}

export default ObjectivesTablev2;
