import React, { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import { ContainerProps } from './Container.types';
/*
  Typical use:
   - Low level construct, wrapper around flexbox
   - Used to position things, provides padding and spacing between children
*/

function Container(props: PropsWithChildren<ContainerProps>) {
  const { children, spacing = 1, m = 2, ...rest } = props;
  const appliedProps = {
    spacing,
    m,
    alignItems: 'flex-start',
    ...rest,
  };
  return <Stack {...appliedProps}>{children}</Stack>;
}
export default Container;
