import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import MediaQueryWrapper from 'Components/Common/MediaQueryWrapper';
import DetailsSkeleton from 'Components/Library/Skeletons/DetailsSkeleton';
import HeadlineSkeleton from 'Components/Library/Skeletons/HeadlineSkeleton';
import RelationsSkeleton from 'Components/Library/Skeletons/RelationsSkeleton';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      overflowX: 'hidden',
      overflowY: 'auto',
      display: 'block',
    },
    position: 'relative', // to allow absolute positioning of the saving indicator
    justifyContent: 'stretch',
    flexBasis: '100%',
  },
  left: {
    width: '60%',
    flexBasis: '60%',
    height: '100%',
    flexShrink: 2,
    flexGrow: 10,
    padding: theme.spacing(3),
    paddingRight: 0,
    paddingLeft: 0,
    boxSizing: 'border-box',
    borderRight: `1px solid ${theme.palette.divider}`,
    overflowY: 'auto',
    overflowX: 'clip',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
      flexBasis: 'auto',
      flexGrow: 0,
      padding: 8,
      borderRight: 'none',
    },
  },
  right: {
    width: '40%',
    flexBasis: '40%',
    minWidth: 400,
    height: '100%',
    padding: theme.spacing(3),
    paddingRight: 14,
    paddingLeft: 14,
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflowX: 'clip',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexBasis: 'auto',
      flexGrow: 0,
      height: 'auto',
      padding: 8,
      minWidth: 0,
    },
  },
  headlineWrapper: {
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  logWrapper: {
    marginLeft: 16,
    marginRight: 16,
  },
});

function TaskItemDialogContent(props) {
  const {
    classes,
    sm,
    headlineComponent,
    logComponent = null,
    detailsComponent = null,
    relationsComponent = null,
    loading,
  } = props;
  return (
    <div className={clsx(classes.root, sm && 'scrollable')}>
      <div className={clsx(classes.left, 'scrollable')}>
        {loading ? (
          <HeadlineSkeleton />
        ) : (
          <div className={classes.headlineWrapper}>{headlineComponent}</div>
        )}
        {!sm && <div className={classes.logWrapper}>{logComponent}</div>}
      </div>
      <div className={clsx(classes.right, 'scrollable')}>
        {loading ? <DetailsSkeleton /> : detailsComponent}
        {loading ? <RelationsSkeleton /> : relationsComponent}
        {sm && logComponent}
      </div>
    </div>
  );
}

TaskItemDialogContent.propTypes = {
  classes: PropTypes.exact({
    right: PropTypes.string,
    left: PropTypes.string,
    root: PropTypes.string,
    headlineWrapper: PropTypes.string,
    logWrapper: PropTypes.string,
  }),
  sm: PropTypes.bool,
  headlineComponent: PropTypes.element,
  logComponent: PropTypes.element,
  detailsComponent: PropTypes.element,
  relationsComponent: PropTypes.element,
  loading: PropTypes.bool,
};

TaskItemDialogContent.defaultProps = {
  loading: false,
};

export default MediaQueryWrapper()(withStyles(styles)(TaskItemDialogContent));
