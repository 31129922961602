import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { programsSelectors, programsActions } from 'state/ducks/programs';
import { peopleSelectors, peopleActions } from 'state/ducks/people';
import PanelCreateTopic from './PanelCreateTopic';

class PanelCreateTopicContainer extends Component {
  componentDidMount() {
    const { audienceType, audienceID, dispatch } = this.props;
    if (audienceType === 'PRG') {
      dispatch(programsActions.getProgramUsers({ cpID: audienceID }));
    } else {
      dispatch(peopleActions.getTeam({ teamId: audienceID }));
    }
  }

  render() {
    const {
      open,
      onClose,
      audienceID,
      audienceType,
      audienceName,
      program,
      numPeople,
      sub,
      teamList,
      zIndexModifier,
    } = this.props;
    if (audienceType === 'PRG' && !program) {
      return null;
    }
    return (
      <PanelCreateTopic
        open={open}
        onClose={onClose}
        audienceID={audienceID}
        audienceType={audienceType}
        audienceName={audienceName}
        numPeople={numPeople}
        sub={sub}
        teamList={teamList}
        zIndexModifier={zIndexModifier}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let people;
  let numPeople;
  let audienceName;
  let program;
  let teamList;
  if (ownProps.audienceType === 'PRG') {
    people = programsSelectors.selectProgramUsers(state.main.programs, ownProps.audienceID);
    numPeople = people.ok ? people.data.length : undefined;
    program = programsSelectors.selectProgramById(state.main.programs, ownProps.audienceID);
    audienceName = !!program && program.name;
    // TODO: this should be memoized, it's pretty expensive to compute!
    const rootTeamId =
      program.type !== 'strategy'
        ? program.rootTeamId
        : peopleSelectors.selectRootTeam(state.main.people);
    teamList = peopleSelectors.flattenTree(
      peopleSelectors.selectHierarchyFromTeam(state.main.people, rootTeamId),
    );
  } else {
    people = peopleSelectors.selectTeamMembers(state.main.people, ownProps.audienceID);
    numPeople = people.ok ? people.members.length : undefined;
    audienceName = peopleSelectors.selectTeamName(state.main.people, ownProps.audienceID);
  }

  return {
    audienceName,
    program,
    numPeople,
    sub: state.auth.userID,
    teamList,
  };
};

PanelCreateTopicContainer.propTypes = {
  dispatch: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  audienceName: PropTypes.string,
  numPeople: PropTypes.number,
  program: PropTypes.object,
  audienceID: PropTypes.string,
  audienceType: PropTypes.string,
  sub: PropTypes.string,
  teamList: PropTypes.array,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(PanelCreateTopicContainer);
