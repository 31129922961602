import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { nanoid } from 'nanoid';
import { insightsActions, insightsSelectors } from 'state/ducks/insights';
import SurveyDeleteDialog from './SurveyDeleteDialog';

class SurveyDeleteDialogContainer extends Component {
  state = {
    submitted: false,
    submitStatus: 0,
    requestID: '',
    deletedOk: false,
  };

  componentDidMount() {
    if (this.props.survey && this.props.survey.data) {
      this.props.dispatch(
        insightsActions.getSingleTopic({ topicID: this.props.survey.data.topicID }),
      );
    }
  }

  componentDidUpdate() {
    if (this.state.submitted === true) {
      this.interval = setInterval(() => {
        this.checkUpdate();
      }, 1000);
    } else {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  }

  checkUpdate = () => {
    if (this.state.submitted === true) {
      if (this.state.requestID in this.props.actionlog) {
        if (this.props.actionlog[this.state.requestID].result === 'ok') {
          this.setState({
            requestID: null,
            submitted: false,
            submitStatus: 0,
            deletedOk: true,
          });
        } else {
          const errorMessage =
            this.props.actionlog[this.state.requestID].message ||
            this.props.t('general.errorOccured');
          this.setState({ submitStatus: -1, submitted: false, errorText: errorMessage });
          this.timeout = setTimeout(() => {
            if (this.state.submitStatus === -1) {
              this.setState({ submitStatus: 0, submitted: false, errorText: null });
            }
          }, 3000);
        }
      }
    }
  };

  deleteInsight = topicID => {
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    const { dispatch } = this.props;
    dispatch(insightsActions.deleteInsight({ topicID, requestID }));
  };

  deleteSurvey = surveyID => {
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    const { dispatch } = this.props;
    dispatch(insightsActions.deleteSurvey({ surveyID, requestID }));
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { submitStatus, submitted, errorText, deletedOk } = this.state;
    const { open, topic, survey } = this.props;

    // we need to have the topic to know which version of dialog to display, if topic is late the wrong one can be displayed for some time
    let manyPulses = 'WAITING';
    if (topic && topic.ok) {
      if (topic.data.surveyCount > 1) {
        manyPulses = 'MANY';
      } else {
        manyPulses = 'SINGLE';
      }
    }

    return (
      <SurveyDeleteDialog
        errorText={errorText}
        submitted={submitted}
        submitStatus={submitStatus}
        deleteInsight={this.deleteInsight}
        deleteSurvey={this.deleteSurvey}
        open={open}
        survey={survey}
        topic={topic}
        onClose={this.onClose}
        manyPulses={manyPulses}
        deletedOk={deletedOk}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  actionlog: state.main.insights.actionlog,
  topic: insightsSelectors.selectTopicForSurvey(state.main.insights, ownProps.surveyID),
  survey:
    ownProps.surveyID && insightsSelectors.selectSurvey(state.main.insights, ownProps.surveyID),
});

SurveyDeleteDialogContainer.propTypes = {
  dispatch: PropTypes.func,
  actionlog: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  topic: PropTypes.object,
  survey: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(connect(mapStateToProps)(SurveyDeleteDialogContainer));
