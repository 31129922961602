import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Droppable } from 'react-beautiful-dnd';
import NestedDndContext from 'Components/Library/DragAndDrop/NestedDndContext';

function DragContainer({ disabled, type, widgetId, elements, onFieldChange, fieldName, children }) {
  const reorder = (sourceIndex, destIndex) => {
    const result = Array.from(elements);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destIndex, 0, removed);
    onFieldChange(fieldName, result);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    reorder(result.source.index, result.destination.index);
  };

  if (disabled) {
    return <Box>{children}</Box>;
  }

  return (
    <NestedDndContext onDragEnd={onDragEnd} droppableId={`ctx-card-editor-dropzone-${widgetId}`}>
      <Droppable droppableId={`ctx-card-editor-dropzone-${widgetId}`} type={type}>
        {(provided, snapshot) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={snapshot.isDraggingOver ? null : null}
          >
            {children}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </NestedDndContext>
  );
}

DragContainer.propTypes = {
  elements: PropTypes.array,
  onFieldChange: PropTypes.func,
  fieldName: PropTypes.string,
  widgetId: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DragContainer;
