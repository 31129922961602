import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { settingsSelectors, settingsActions } from 'state/ducks/usersettings';
import { useMediaQuery, useTheme } from '@mui/material';
import ToggleNavIcon from './ToggleNavIcon';

export const shouldRender = (menuState, isMobile, context) => {
  let result = false;
  if (isMobile) {
    // on mobile screens never show the icon in the menu, the user
    // can tap outside the menu to hide it if it's open
    // render the icon if menuState is true for page headers
    result = context !== 'menu';
  } else {
    // on desktop we show the icon in the menu or page header purely depending
    // on the value of menuState
    result = menuState ? context === 'menu' : context === 'pageheader';
  }
  return result;
};

function ToggleNavButton({ context, sx }) {
  const dispatch = useDispatch();

  // menuState is always true by default when loading the app
  // for desktop, menuState === true means show the menu
  // for mobile, menuState === true means hide the menu
  const menuState = useSelector(state =>
    settingsSelectors.selectAttribute(state.usersettings, 'menustate'),
  );
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('md'));

  if (!shouldRender(menuState, sm, context)) {
    return null;
  }

  return (
    <IconButton
      size="small"
      id="main-menu-toggle"
      sx={sx}
      onClick={() =>
        dispatch(settingsActions.setAttribute({ name: 'menustate', value: !menuState }))
      }
    >
      <ToggleNavIcon />
    </IconButton>
  );
}

ToggleNavButton.propTypes = {
  context: PropTypes.oneOf(['menu', 'pageheader']),
  sx: PropTypes.object,
};

ToggleNavButton.defaultProps = {
  sx: {},
};

export default ToggleNavButton;
