import React from 'react';
import PropTypes from 'prop-types';
import { NODE_TYPE_TEAM } from 'config/constants';
import CommitmentsGrid from './index';

function TeamCommitments(props) {
  const { teamId } = props;

  return (
    <CommitmentsGrid
      initialDomain={{ type: NODE_TYPE_TEAM, id: teamId }}
      allowDomainChange={false}
    />
  );
}

TeamCommitments.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default TeamCommitments;
