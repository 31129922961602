import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Skeleton, Box, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { commitmentsSelectors, commitmentsActions } from 'state/ducks/commitments';
import FeatureIcon from 'Components/Features/Commitments/FeatureIcon';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import AddCommitmentButton from 'Components/Features/Commitments/AddCommitmentButton';
import CommitmentChip from 'Components/Features/Commitments/CommitmentChip';
import { SORTING_ORDERS } from 'state/ducks/interlocks/types';
import { sortAndSliceItemsToShow } from 'Components/Features/GamePlans/Widgets/utils';

function CommitmentsWidget({ variant, canEdit, teamId, dragHandleProps, requestRemove, name }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commitmentsActions.fetchTeamCommitments({ id: teamId }));
    dispatch(commitmentsActions.fetchCompletedTeamCommitments({ id: teamId }));
  }, [teamId]);

  const activeCommitments = useSelector(
    state =>
      commitmentsSelectors.selectTeamCommitments(state.main.commitments, teamId, {
        sortColumn: 'last_modified',
        sortOrder: SORTING_ORDERS.desc,
      }),
    // { stabilityCheck: 'never' },
  );

  const completedCommitments = useSelector(
    state =>
      commitmentsSelectors.selectCompletedTeamCommitments(state.main.commitments, teamId, {
        sortColumn: 'last_modified',
        sortOrder: SORTING_ORDERS.desc,
      }),
    // { stabilityCheck: 'never' },
  );

  const allCommitmentsIds = activeCommitments.data.concat(completedCommitments.data) || [];

  const allCommitments = useSelector(
    state =>
      allCommitmentsIds.map(commitmentId => state.main.commitments.commitments[commitmentId].data),
    // { stabilityCheck: 'never' },
  );

  const isLoading =
    (!activeCommitments.ok && activeCommitments.loading) ||
    (!completedCommitments.ok && completedCommitments.loading);

  const isInteractive = variant === 'card' && canEdit;
  const isPresentationMode = variant === 'dialog';

  const allCommitmentsSorted = sortAndSliceItemsToShow(
    allCommitments,
    'last_modified',
    isPresentationMode ? -1 : 5,
  );

  return (
    <>
      <Box
        sx={{
          mt: 0,
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'stretch',
          height: '24px',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Stack direction="row" alignItems="center">
            <FeatureIcon sx={{ mr: 1 }} />
            <Typography variant="subtitle2">{t('commitments.pageTitle')}</Typography>
          </Stack>
          {isInteractive && (
            <Stack
              direction="row"
              spacing={1}
              className="gp-widget-actions"
              onClick={e => e.stopPropagation()}
            >
              <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                <DeleteIcon />
              </ContainedIconButton>
              <AddCommitmentButton teamId={teamId} variant="contained" />
            </Stack>
          )}
        </Stack>
      </Box>
      <Box
        sx={{ ml: 2, mr: 2, p: 1, borderRadius: 1, backgroundColor: 'background.paper' }}
        className="widget-content"
      >
        <Stack direction="column" alignItems="stretch" spacing={1}>
          {allCommitmentsSorted.map(commitment => (
            <CommitmentChip
              ContextIcon={null}
              key={`commitments-widget-${commitment.id}`}
              graphId={commitment.id}
              color="transparent"
              allowNavigate
            />
          ))}
          {isLoading && (
            <Skeleton height={80} variant="rounded" sx={{ mb: 2, bgcolor: 'background.paper' }} />
          )}
        </Stack>
      </Box>
      <Box sx={{ height: 16 }} {...dragHandleProps} />
    </>
  );
}

CommitmentsWidget.propTypes = {
  teamId: PropTypes.string,
  dragHandleProps: PropTypes.object,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  name: PropTypes.string,
};

CommitmentsWidget.defaultProps = {
  variant: 'card',
};

export default CommitmentsWidget;
