import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ContributorStatus from 'Components/Library/Contributors/ContributorStatus';
import { interlocksActions, interlocksSelectors } from 'state/ducks/interlocks/index';
import { CONTRIBUTOR_TYPES } from 'config/constants';

function OwnerStatus({ interlockID, ownerID, contributorsData }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(interlocksActions.fetchContributors({ id: interlockID, force: false }));
  }, []);
  let ownerData = null;

  if (contributorsData?.data) {
    ownerData = contributorsData?.data.find(contributor => contributor.id === ownerID);
  }
  const editOwnerStatus = status => {
    const payload = {
      id: interlockID,
      contributors: [
        { data: { id: ownerData.id, type: CONTRIBUTOR_TYPES.USER, status }, action: 'UPDATE' },
      ],
    };
    dispatch(interlocksActions.editContributorsStatus(payload));
  };

  if (!ownerData || !ownerID) {
    return null;
  }

  return (
    <ContributorStatus
      value={ownerData.status}
      onSelect={option => editOwnerStatus(option)}
      contributorID={ownerData.id}
      contributorType={ownerData.type}
    />
  );
}

const mapStateToProps = (state, ownProps) => ({
  contributorsData: interlocksSelectors.selectContributors(
    state.main.interlocks,
    ownProps.interlockID,
  ),
});

OwnerStatus.propTypes = {
  interlockID: PropTypes.string.isRequired,
  ownerID: PropTypes.string,
  contributorsData: PropTypes.object,
};
export default connect(mapStateToProps)(OwnerStatus);
