export const REPLY_TO_SURVEY = 'REPLY_TO_SURVEY';
export const FAILED_SURVEY_REPLY = 'FAILED_SURVEY_REPLY';
export const REPLIED_TO_SURVEY = 'REPLIED_TO_SURVEY';

export const GET_TOPIC = 'GET_TOPIC';
export const FAILED_TO_GET_TOPIC = 'FAILED_TO_GET_TOPIC';
export const TOPIC_NOT_FOUND = 'TOPIC_NOT_FOUND';
export const FETCHED_TOPIC = 'FETCHED_TOPIC';

export const GET_INTERPRETATION_TOPICS = 'GET_INTERPRETATION_TOPICS';
export const FAILED_TO_GET_INTERPRETATION_TOPICS = 'FAILED_TO_GET_INTERPRETATION_TOPICS';
export const FETCHED_INTERPRETATION_TOPICS = 'FETCHED_INTERPRETATION_TOPICS';

export const REPLY_TO_INTERPRETATION = 'REPLY_TO_INTERPRETATION';
export const REPLIED_TO_INTERPRETATION = 'REPLIED_TO_INTERPRETATION';

export const GET_INTERPRETATION_STATS = 'GET_INTERPRETATION_STATS';
export const FAILED_TO_GET_INTERPRETATION_STATS = 'FAILED_TO_GET_INTERPRETATION_STATS';
export const FETCHED_INTERPRETATION_STATS = 'FETCHED_INTERPRETATION_STATS';

export const GET_SURVEYS = 'GET_SURVEYS';
export const FAILED_TO_GET_SURVEYS = 'FAILED_TO_GET_SURVEYS';
export const FETCHED_SURVEYS = 'FETCHED_SURVEYS';

export const GET_TOPIC_SURVEYS = 'GET_TOPIC_SURVEYS';
export const FAILED_TO_GET_TOPIC_SURVEYS = 'FAILED_TO_GET_TOPIC_SURVEYS';
export const FETCHED_TOPIC_SURVEYS = 'FETCHED_TOPIC_SURVEYS';

export const GET_SINGLE_SURVEY = 'GET_SINGLE_SURVEY';
export const FAILED_TO_GET_SINGLE_SURVEY = 'FAILED_TO_GET_SINGLE_SURVEY';
export const FETCHED_SINGLE_SURVEY = 'FETCHED_SINGLE_SURVEY';

export const CREATE_TOPIC = 'CREATE_TOPIC';
export const INSIGHTS_API_ERROR = 'INSIGHTS_API_ERROR';
export const CREATED_TOPIC = 'CREATED_TOPIC';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const CREATED_SURVEY = 'CREATED_SURVEY';

export const CREATE_INTERPRETATION_TOPIC = 'CREATE_INTERPRETATION_TOPIC';
export const CREATED_INTERPRETATION_TOPIC = 'CREATED_INTERPRETATION_TOPIC';

export const GET_SURVEY_STATS = 'GET_SURVEY_STATS';
export const FAILED_TO_GET_SURVEY_STATS = 'FAILED_TO_GET_SURVEY_STATS';
export const FETCHED_SURVEY_STATS = 'FETCHED_SURVEY_STATS';

export const UNDERSTANDING_DELETED = 'UNDERSTANDING_DELETED';
export const DELETE_UNDERSTANDING = 'DELETE_UNDERSTANDING';

export const INSIGHT_DELETED = 'INSIGHT_DELETED';
export const DELETE_INSIGHT = 'DELETE_INSIGHT';

export const SURVEY_DELETED = 'SURVEY_DELETED';
export const DELETE_SURVEY = 'DELETE_SURVEY';
