import reducer from './reducers';

import * as objectivesLogic from './logic';
import * as objectivesTypes from './types';
import * as objectivesActions from './actions';
import * as objectivesSelectors from './selectors';
import * as objectivesHelpers from './helpers';

export {
  objectivesLogic,
  objectivesTypes,
  objectivesActions,
  objectivesSelectors,
  objectivesHelpers,
};

export default reducer;
