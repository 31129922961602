import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Fab from 'Components/Common/Fab';
import { withLocation, withNavigation } from 'withRouter';
import { openStartFacilitationPanel } from 'config/ModalProvider/helpers';
import { connectionSelectors } from 'state/ducks/connection';

const styles = theme => ({
  fabIcon: {
    color: theme.palette.secondary.contrastText,
  },
});

class StartFacilitationButton extends PureComponent {
  handleOpen = () => {
    openStartFacilitationPanel(this.props.teamID, this.props.navigate, this.props.location);
  };

  render() {
    const { classes, isOnline, t } = this.props;
    if (!isOnline) {
      return null;
    }
    return (
      <div>
        <Fab
          variant="circular"
          name="start-facilitation-fab"
          onClick={this.handleOpen}
          aria-label={t('dfac.createheadline')}
        >
          <AddIcon className={classes.fabIcon} />
        </Fab>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
});

StartFacilitationButton.propTypes = {
  teamID: PropTypes.string,
  classes: PropTypes.exact({
    fabIcon: PropTypes.string,
  }),
  isOnline: PropTypes.bool,
  location: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func,
};

export default withLocation(
  withNavigation(
    withTranslation()(withStyles(styles)(connect(mapStateToProps)(StartFacilitationButton))),
  ),
);
