import {
  getKeyResultConfidenceString,
  getKeyresultWeeklyProgress,
  isObjectiveOwned,
  isKrOwned,
} from 'state/ducks/objectives/helpers';
import { IObjectiveData, NodeDomain } from 'state/ducks/objectives/types';
import { getKeyResultCompletionPct, round } from 'config/helpers';
import { KR_OUTDATED_TRESHOLD } from 'config/constants';
import { get } from 'lodash';

export type DomainOkrMetadata = {
  numOwnedObjectives: number;
  numOwnedKrs: number;
  numOutdatedKrs: number;
  numRedConfidenceKrs: number;
  numAmberConfidenceKrs: number;
  numGoodKrs: number;
  statusPercent: number;
  weeklyDelta: number;
  events: any;
  alldone: boolean;
  loading: boolean;
  refreshing: boolean;
};

export function computeMetadata(
  objectives: Array<IObjectiveData>,
  domain: NodeDomain,
): DomainOkrMetadata {
  let numOwnedObjectives: number = 0;
  let numOwnedKrs: number = 0;
  let numOutdatedKrs = 0;
  let numRedConfidenceKrs = 0;
  let numAmberConfidenceKrs = 0;
  let numGoodKrs = 0;
  const events = { keyresults: [] };
  let alldone = true;
  let refreshing = false;
  let sumStatus = 0;
  let sumDelta = 0;
  let sumWeight = 0;

  if (objectives && objectives.length > 0) {
    objectives.forEach(objective => {
      if (isObjectiveOwned(domain, objective.data)) {
        numOwnedObjectives++;
      }
      get(objective, ['data', 'keyresults'], []).forEach(keyresult => {
        if (isKrOwned(domain, objective.data, keyresult)) {
          numOwnedKrs += 1;
          const krConfidence = getKeyResultConfidenceString(keyresult);
          let krIsGood = true;
          if (krConfidence === 'amber') {
            numAmberConfidenceKrs += 1;
            krIsGood = false;
          } else if (krConfidence === 'red') {
            numRedConfidenceKrs += 1;
            krIsGood = false;
          }

          const krStatus = getKeyResultCompletionPct(keyresult);
          if (
            keyresult.lastModifiedDays >= KR_OUTDATED_TRESHOLD &&
            objective.data.stage === 'LIVE' &&
            krStatus < 100
          ) {
            numOutdatedKrs += 1;
            krIsGood = false;
          }

          if (!!krIsGood) {
            numGoodKrs += 1;
          }

          events.keyresults.push(keyresult);

          /* Calculate statuses here to work for all domain types */
          const weeklyDelta = getKeyresultWeeklyProgress(keyresult);

          sumStatus += krStatus * keyresult.weight;
          sumWeight += keyresult.weight;

          sumDelta += weeklyDelta * keyresult.weight;
        }
      });
    });
  }
  return {
    numOwnedObjectives,
    numOwnedKrs,
    numOutdatedKrs,
    numAmberConfidenceKrs,
    numRedConfidenceKrs,
    numGoodKrs,
    statusPercent: sumWeight !== 0 ? round(sumStatus / sumWeight) : 0,
    weeklyDelta: sumWeight !== 0 ? round(sumDelta / sumWeight) : 0,
    events,
    // TODO: these should be cleaned up but were needed for backwards
    // compatibility. The loading status is now provided by the useDomainOkrs
    // hook
    alldone,
    refreshing,
    loading: false,
  };
}
