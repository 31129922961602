import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useRemirrorContext } from '@remirror/react';
import { containsNodesOfType } from '@remirror/core-utils';
import Box from '@mui/material/Box';

const getTextLength = remirrorState => {
  let textContentLength = remirrorState.doc.textContent.trim().length;
  // Mention Atom Nodes do not contribute to the text length as computed by remirror,
  // add one if there are mentions in the document to allow eg. comments with just a @ping
  if (
    containsNodesOfType({
      node: remirrorState.doc,
      type: remirrorState.config.schema.nodes.mentionAtom,
    })
  ) {
    textContentLength += 1;
  }
  return textContentLength;
};

const ValidatorHandle = forwardRef(({ showError }, ref) => {
  const { setContent, getState } = useRemirrorContext({
    autoUpdate: true,
  });

  const [error, setError] = useState({ exceeded: false, message: '' });

  const validate = ({ minLength, maxLength }) => {
    const remirrorState = getState();
    const textContentLength = getTextLength(remirrorState);

    const msg = `${textContentLength}/${maxLength}`;
    const underMin = textContentLength < minLength;
    const overMax = textContentLength > maxLength;
    if (underMin || overMax) {
      setError({ message: msg, exceeded: overMax });
      return [msg];
    }
    setError({ message: '', exceeded: false });
    return [];
  };

  // Expose setContent to outside of the remirror context
  useImperativeHandle(ref, () => ({ setContent, validate }));

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '1px',
        marginBottom: '-1px',
        overflow: 'visible',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 2,
          right: 6,
          color: 'error.main',
          fontSize: '0.75rem',
          // Other input fields will prevent the user from typing over the maxLength,
          // This is not possible with the rich text editor - deviate from usual
          // UX where validation errors are only shown after trying to submit, and
          // always show the error if the length is exceeded
          display: showError || error.exceeded ? 'block' : 'none',
        }}
      >
        {error.message}
      </Box>
    </Box>
  );
});

ValidatorHandle.propTypes = {
  showError: PropTypes.bool,
};

export default ValidatorHandle;
