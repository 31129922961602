import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ContentCardIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C2.89543 3 2 3.89543 2 5V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V5C22 3.89543 21.1046 3 20 3H4ZM6 5C4.89543 5 4 5.89543 4 7V15.5C4 16.6046 4.89543 17.5 6 17.5H18C19.1046 17.5 20 16.6046 20 15.5V7C20 5.89543 19.1046 5 18 5H6Z"
        fill="black"
        fillOpacity="0.26"
      />
      <path
        d="M16 11.5714H12.5714V15H11.4286V11.5714H8V10.4286H11.4286V7H12.5714V10.4286H16V11.5714Z"
        fill="black"
        fillOpacity="0.26"
      />
    </SvgIcon>
  );
}

export default ContentCardIcon;
