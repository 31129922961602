import { PURGE } from 'redux-persist';
import { validatePersistedState, copyState, apiError, enrich, fetching } from 'state/helpers';
import * as types from './types';

import * as constants from '../../constants/api';

export const initialState = {
  VERSION: 1.09,
  teamManagers: {},
  managedTeams: {},
  subs: {},
  hierarchy: {},
  hierarchypointers: {},
  childTeams: {}, // Map of parentTeam: [children ]
  teamMembers: {},
  teamNames: {},
  parentTeams: {},
  teamMatrices: {},
  teampaths: {},
  permissions: {},
  list: enrich(),
  actionlog: {},
  matrices: {},
  matrixhierarchies: {},
  followedTeams: {},
  myJoinRequests: {},
  teamJoinRequests: {},
  recentTeams: [],
  recentUsers: [],
};

function addApiErrorToState(state, action) {
  const newState = copyState(state);
  newState.actionlog[action.payload.requestID] = {
    result: 'error',
    message: action.payload.errormsg,
  };
  return newState;
}

function _augmentHierarchy(newstate) {
  // Calculate the maximum depth & populate teamManagers
  let maxDepth = 0;
  newstate.teamNames = {};
  newstate.hierarchypointers = {};
  newstate.teamManagers = {};
  newstate.managedTeams = {};
  newstate.teampaths = {};
  newstate.childTeams = {};
  newstate.parentTeams = {};
  newstate.teamMatrices = {};

  function recurse(node, curDepth, parentId, parentPath, matrixId = null, skip = false) {
    if (!skip) {
      if (curDepth > maxDepth) {
        maxDepth = curDepth;
      }
      node.teamId = node.id;
      node.teamName = node.name;
      node.type = skip ? 'root' : 'team';
      newstate.hierarchypointers[node.id] = node;
      node.path = `${parentPath}/${node.id}`;
      newstate.teampaths[node.id] = node.path;

      newstate.teamMatrices[node.id] = matrixId;

      if (node.id && node.managerId) {
        if (curDepth === 0 && !matrixId) {
          // Ensure CEO does't have a team ID (ENG-284)
          // Handle incoming CEO
          if (newstate.subs[node.managerId] && newstate.subs[node.managerId].ok) {
            newstate.subs[node.managerId].teamId = null;
          }
        }
        newstate.teamManagers[node.id] = node.managerId;
      }

      if (!newstate.managedTeams[node.managerId]) {
        newstate.managedTeams[node.managerId] = [];
      }
      newstate.managedTeams[node.managerId].push(node.id);

      if (node.id && !newstate.teamNames[node.id] && node.name) {
        newstate.teamNames[node.id] = node.name;
      }

      if (!newstate.childTeams[parentId]) {
        newstate.childTeams[parentId] = [];
      }
      newstate.childTeams[parentId].push(node.id);

      newstate.parentTeams[node.id] = parentId;
    }
    if (node.children) {
      Object.keys(node.children).forEach(child => {
        recurse(
          node.children[child],
          curDepth + 1,
          skip ? null : node.id,
          node.path || parentPath,
          matrixId,
        );
      });
    }
  }

  recurse(newstate.hierarchy, 0, null, '');
  if (newstate.matrixhierarchies) {
    Object.keys(newstate.matrixhierarchies).forEach(matrixId => {
      if (newstate.matrixhierarchies[matrixId].ok) {
        recurse(newstate.matrixhierarchies[matrixId].hierarchy, 0, null, '', matrixId, true);
      }
    });
  }
  newstate.hierarchy.maxDepth = maxDepth;
}

function addFetchingMatricesToState(state) {
  const newState = copyState(state);
  newState.matrices = fetching(newState.matrices);
  return newState;
}

function addMatricesToState(state, payload) {
  const matrices = payload;
  const newState = copyState(state);
  newState.matrices = enrich({
    data: matrices,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 60 * 20,
  });
  return newState;
}

function addHierarchyToState(state, payload) {
  const hierarchy = payload.children[0];
  hierarchy.fetchStatus = constants.OK;
  hierarchy.lastFetched = Date.now();
  hierarchy.maxAge = 1000 * 120;
  const newState = copyState(state);
  newState.hierarchy = enrich(hierarchy);
  _augmentHierarchy(newState);
  return newState;
}

function addMatrixHierarchyToState(state, payload) {
  const hierarchy = payload;
  const newState = copyState(state);
  newState.matrixhierarchies = { ...newState.matrixhierarchies };
  newState.matrixhierarchies[payload.id] = enrich({
    hierarchy,
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 120,
  });
  _augmentHierarchy(newState);
  return newState;
}

function addModifiedHierarchyToState(state, payload) {
  let newState;
  if (payload.matrixId) {
    newState = addMatrixHierarchyToState(state, payload.hierarchy);
  } else {
    newState = addHierarchyToState(state, payload.hierarchy);
  }
  newState.actionlog[payload.requestID] = { result: 'ok' };
  return newState;
}

function createdUser(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  delete payload.requestID;
  payload.matrixTeams = [];

  newState.subs[payload.sub] = enrich({ ...payload, fetchStatus: constants.OK });
  newState.subs[payload.sub].fullName = `${payload.firstName.replace(/(^|\s)\S/g, l =>
    l.toUpperCase(),
  )} ${payload.lastName.replace(/(^|\s)\S/g, l => l.toUpperCase())}`;
  if (newState.teamMembers[payload.teamId] && newState.teamMembers[payload.teamId].ok) {
    newState.teamMembers[payload.teamId].members.push(payload.sub);
  }
  if (newState.list.ok) {
    newState.list.data.push(payload.sub);
  }
  return newState;
}

function updateAvatarInState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  if (!!newState.subs[payload.sub] && !!newState.subs[payload.sub].ok) {
    newState.subs[payload.sub] = { ...newState.subs[payload.sub], avatar: payload.b64str };
  }
  return newState;
}

/* EDITING THE ORG (admin) */

function addEditedTeamManagerToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  const { hierarchy, matrixId } = payload;
  if (!matrixId) {
    newState.hierarchy = hierarchy;
  } else {
    newState.matrixhierarchies[matrixId].hierarchy = hierarchy;
  }

  _augmentHierarchy(newState);
  return newState;
}

function addEditedTeamNameToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  const { hierarchy, matrixId } = payload;
  if (!matrixId) {
    newState.hierarchy = hierarchy;
  } else {
    newState.matrixhierarchies[matrixId].hierarchy = hierarchy;
  }

  _augmentHierarchy(newState);
  return newState;
}

/* EDITING USERS (admin) */

function removeDeletedUserFromState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  const { sub } = payload;

  newState.subs[sub] = enrich({
    fetchStatus: constants.DOES_NOT_EXIST,
    sub,
    fullName: 'User does not exist',
  });

  if (newState.list && newState.list.ok) {
    newState.list.data = newState.list.data.filter(listsub => listsub !== sub);
  }

  for (const tid in newState.teamMembers) {
    const team = newState.teamMembers[tid];
    if (team && team.ok) {
      team.members = team.members.filter(memberId => memberId !== sub);
    }
  }

  return newState;
}

function updateUserAttributes(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  delete payload.requestID;

  newState.subs[payload.sub] = enrich({ ...newState.subs[payload.sub], ...payload });
  newState.subs[payload.sub].fullName = `${newState.subs[payload.sub].firstName.replace(
    /(^|\s)\S/g,
    l => l.toUpperCase(),
  )} ${newState.subs[payload.sub].lastName.replace(/(^|\s)\S/g, l => l.toUpperCase())}`;
  return newState;
}

function addEditedUsersTeam(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  const { sub, teamId } = payload;

  if (newState.subs[sub] && newState.subs[sub].ok) {
    newState.subs[sub].teamId = teamId;
  }

  for (const tid in newState.teamMembers) {
    const team = newState.teamMembers[tid];
    if (team && team.ok) {
      team.members = team.members.filter(memberId => memberId !== sub);
    }
  }

  if (newState.teamMembers[teamId] && newState.teamMembers[teamId].ok) {
    newState.teamMembers[teamId].members.push(sub);
  }
  return newState;
}

function addEditedUsersMatrixTeam(state, payload) {
  const newState = copyState(state);
  if (!!payload.requestID) {
    newState.actionlog[payload.requestID] = { result: 'ok' };
  }

  // Same reducer used for a couple of API methods with varying signature:
  const { sub, teamId, teamID } = payload;
  const appliedTeamId = teamId || teamID;

  if (newState.subs[sub] && newState.subs[sub].ok) {
    const userData = newState.subs[sub];
    const isInTeam = userData.matrixTeams.find(t => t.id === appliedTeamId);
    if (!isInTeam) {
      const newMatrixTeams = [...userData.matrixTeams];
      newMatrixTeams.push({ id: appliedTeamId });
      userData.matrixTeams = newMatrixTeams;
    }
  }

  if (
    newState.teamMembers[appliedTeamId] &&
    newState.teamMembers[appliedTeamId].ok &&
    !newState.teamMembers[appliedTeamId].members.includes(sub)
  ) {
    newState.teamMembers[appliedTeamId].members.push(sub);
  }
  return newState;
}

function removeUserFromMatrixTeam(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  // Same reducer used for a couple of API methods with varying signature:
  const { sub, teamId, teamID } = payload;
  const appliedTeamId = teamId || teamID;

  if (newState.subs[sub] && newState.subs[sub].ok) {
    const userData = newState.subs[sub];
    const newMatrixTeams = [];

    if (userData.matrixTeams) {
      userData.matrixTeams.forEach(team => {
        if (team.id !== appliedTeamId) {
          newMatrixTeams.push(team);
        } else {
          const oldTeam = newState.teamMembers[team.id];
          if (oldTeam && oldTeam.ok) {
            oldTeam.members = oldTeam.members.filter(memberId => memberId !== sub);
          }
        }
      });
    }
    userData.matrixTeams = newMatrixTeams;
  }
  return newState;
}

/* SEARCHING USERS */

function addSearchedPeopleToState(state, payload) {
  const newState = copyState(state);
  for (let i = 0; i < payload.people.length; i++) {
    const person = {
      sub: payload.people[i].sub,
      firstName: payload.people[i].firstName.replace(/(^|\s)\S/g, l => l.toUpperCase()),
      lastName: payload.people[i].lastName.replace(/(^|\s)\S/g, l => l.toUpperCase()),
      email: payload.people[i].email,
      avatar: payload.people[i].avatar
        ? `data:image/jpeg;base64,${payload.people[i].avatar}`
        : null,
      fullName: `${payload.people[i].firstName.replace(/(^|\s)\S/g, l =>
        l.toUpperCase(),
      )} ${payload.people[i].lastName.replace(/(^|\s)\S/g, l => l.toUpperCase())}`,
      title: payload.people[i].title,
      teamId: payload.people[i].teamID,
      matrixTeams: payload.people[i].matrixTeams,
      fetchStatus: constants.OK,
      lastFetched: Date.now(),
      maxAge: 1000 * 600,
    };
    newState.subs[payload.people[i].sub] = enrich(person);
  }
  newState.actionlog[payload.requestID] = { result: 'ok' };
  return newState;
}

/* FETCHING USERS BY SUB */

function addFetchedPeopleToState(state, payload) {
  const newState = copyState(state);
  const seenSubs = [];
  payload.users.forEach(person => {
    seenSubs.push(person.sub);
    const result = {
      sub: person.sub,
      firstName: person.firstName.replace(/(^|\s)\S/g, l => l.toUpperCase()),
      lastName: person.lastName.replace(/(^|\s)\S/g, l => l.toUpperCase()),
      email: person.email,
      avatar: person.avatar ? `data:image/jpeg;base64,${person.avatar}` : null,
      fullName: `${person.firstName.replace(/(^|\s)\S/g, l =>
        l.toUpperCase(),
      )} ${person.lastName.replace(/(^|\s)\S/g, l => l.toUpperCase())}`,
      title: person.title,
      teamId: person.teamID,
      fetchStatus: constants.OK,
      lastFetched: Date.now(),
      matrixTeams: person.matrixTeams,
      maxAge: 1000 * 600,
    };
    newState.subs[person.sub] = enrich(result);
  });
  payload.notfound.forEach(sub => {
    seenSubs.push(sub);
    newState.subs[sub] = enrich({
      fetchStatus: constants.DOES_NOT_EXIST,
      sub,
      fullName: 'User does not exist',
    });
  });
  payload.originalrequest.forEach(sub => {
    if (!seenSubs.includes(sub)) {
      newState.subs[sub] = apiError(newState.subs[sub]);
    }
  });
  return newState;
}

function addFetchingPersonToState(state, payload) {
  const newState = copyState(state);
  payload.subs.forEach(sub => {
    newState.subs[sub] = fetching(newState.subs[sub]);
  });
  return newState;
}

function addFailedFetchPersonToState(state, payload) {
  const newState = copyState(state);
  payload.subs.forEach(sub => {
    newState.subs[sub] = apiError(newState.subs[sub]);
  });
  return newState;
}

/* TEAM MEMBERS: used extensively in OKR views */

function addFetchedTeamToState(state, payload) {
  const newState = copyState(state);
  const subs = Object.keys(payload.members);
  newState.teamMembers[payload.teamId] = enrich({
    fetchStatus: constants.OK,
    lastFetched: Date.now(),
    maxAge: 1000 * 600,
    members: subs,
  });
  subs.forEach(sub => {
    const person = payload.members[sub];
    const result = {
      sub,
      avatar: person.avatar ? `data:image/jpeg;base64,${person.avatar}` : null,
      email: person.email,
      firstName: person.firstName.replace(/(^|\s)\S/g, l => l.toUpperCase()),
      lastName: person.lastName.replace(/(^|\s)\S/g, l => l.toUpperCase()),
      fullName: `${person.firstName.replace(/(^|\s)\S/g, l =>
        l.toUpperCase(),
      )} ${person.lastName.replace(/(^|\s)\S/g, l => l.toUpperCase())}`,
      title: person.title,
      teamId: person.teamID,
      matrixTeams: person.matrixTeams,
      fetchStatus: constants.OK,
      lastFetched: Date.now(),
      maxAge: 1000 * 600,
    };
    newState.subs[person.sub] = enrich({ ...newState.subs[person.sub], ...result });
  });
  return newState;
}

function addFetchingTeamToState(state, payload) {
  const newState = copyState(state);
  newState.teamMembers[payload.teamId] = fetching(newState.teamMembers[payload.teamId]);
  return newState;
}

function addFailedFetchTeamToState(state, payload) {
  const newState = copyState(state);
  newState.teamMembers[payload.teamId] = apiError(newState.teamMembers[payload.teamId]);
  return newState;
}

/* USER LIST: used in admin */

function addFetchedListToState(state, payload) {
  const newState = copyState(state);
  newState.list = enrich({ data: payload, fetchStatus: constants.OK, lastFetched: Date.now() });
  return newState;
}

function addFetchingListToState(state) {
  const newState = copyState(state);

  newState.list = fetching(newState.list);
  return newState;
}

function addFailedListToState(state) {
  const newState = copyState(state);
  newState.list = apiError(newState.list);
  return newState;
}

/* USER PERMISSIONS: used in admin */

function addFetchedPermsToState(state, payload) {
  const newState = copyState(state);
  newState.permissions[payload.sub] = enrich({
    fetchStatus: constants.OK,
    permissions: payload,
    lastFetched: Date.now(),
  });
  return newState;
}

function addEditedPermsToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  newState.permissions[payload.sub] = enrich({ fetchStatus: constants.OK, permissions: payload });
  return newState;
}

function addFetchingPermsToState(state, payload) {
  const newState = copyState(state);
  newState.permissions[payload.sub] = fetching(newState.permissions[payload.sub]);
  return newState;
}

function addFailedFetchPermsToState(state, payload) {
  const newState = copyState(state);
  newState.permissions[payload.sub] = apiError(newState.permissions[payload.sub]);
  return newState;
}

/* Followed teams */

function addFetchedFollowedTeamsToState(state, payload) {
  const newState = copyState(state);
  newState.followedTeams = enrich({
    fetchStatus: constants.OK,
    data: payload.teams,
    lastFetched: Date.now(),
  });
  return newState;
}

function addFetchingFollowedTeamsToState(state) {
  const newState = copyState(state);
  newState.followedTeams = fetching(newState.followedTeams);
  return newState;
}

function addFailedFollowedTeamsToState(state) {
  const newState = copyState(state);
  newState.followedTeams = apiError(newState.followedTeams);
  return newState;
}

function followTeamInState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  if (!newState.followedTeams) {
    newState.followedTeams = enrich({
      fetchStatus: constants.PARTIAL,
      data: [payload.teamID],
    });
  } else if (!newState.followedTeams.data.includes(payload.teamID)) {
    newState.followedTeams.data.push(payload.teamID);
  }
  return newState;
}

function unfollowTeamInState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  if (!!newState.followedTeams) {
    newState.followedTeams.data = newState.followedTeams.data.filter(tid => tid !== payload.teamID);
  }
  return newState;
}

/* Join requests and invites */

function addFetchedMyJoinRequestsToState(state, payload) {
  const newState = copyState(state);
  newState.myJoinRequests = enrich({
    fetchStatus: constants.OK,
    data: payload.teamIDs || [],
    lastFetched: Date.now(),
  });
  return newState;
}

function addFetchingMyJoinRequestsToState(state) {
  const newState = copyState(state);
  newState.myJoinRequests = fetching(newState.myJoinRequests);
  return newState;
}

function addFailedMyJoinRequestsToState(state) {
  const newState = copyState(state);
  newState.myJoinRequests = apiError(newState.myJoinRequests);
  return newState;
}

function requestToJoinTeamInState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  if (!newState.myJoinRequests) {
    newState.myJoinRequests = enrich({
      fetchStatus: constants.PARTIAL,
      data: [payload.teamID],
    });
  } else if (!newState.myJoinRequests.data.includes(payload.teamID)) {
    newState.myJoinRequests.data.push(payload.teamID);
  }
  return newState;
}

function addFetchedTeamJoinRequestsToState(state, payload) {
  const newState = copyState(state);
  newState.teamJoinRequests[payload.teamID] = enrich({
    fetchStatus: constants.OK,
    data: payload.requestors || [],
    lastFetched: Date.now(),
  });
  return newState;
}

function addFetchingTeamJoinRequestsToState(state, payload) {
  const newState = copyState(state);
  newState.teamJoinRequests[payload.teamID] = fetching(newState.teamJoinRequests[payload.teamID]);
  return newState;
}

function addFailedTeamJoinRequestsToState(state, payload) {
  const newState = copyState(state);
  newState.teamJoinRequests[payload.teamID] = apiError(newState.teamJoinRequests[payload.teamID]);
  return newState;
}

function addApprovedJoinRequestToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  newState.teamJoinRequests[payload.teamID].data = newState.teamJoinRequests[
    payload.teamID
  ].data.filter(s => s !== payload.sub);
  // DRY: use existing func to make sure the user<->team relationship is updated
  return addEditedUsersMatrixTeam(newState, payload);
}

function addDeniedJoinRequestToState(state, payload) {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };

  newState.teamJoinRequests[payload.teamID].data = newState.teamJoinRequests[
    payload.teamID
  ].data.filter(s => s !== payload.sub);

  return newState;
}

function addRecentTeam(state, payload) {
  const newState = copyState(state);
  const { teamId } = payload;
  newState.recentTeams = [teamId, ...newState.recentTeams.filter(tId => tId !== teamId)];
  return newState;
}

function addRecentUser(state, payload) {
  const newState = copyState(state);
  const { sub } = payload;
  newState.recentUsers = [sub, ...newState.recentUsers.filter(userId => userId !== sub)];
  return newState;
}

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.GET_PERSON:
      return addFetchingPersonToState(state, action.payload);
    case types.FAILED_PERSON:
      return addFailedFetchPersonToState(state, action.payload);
    case types.RECEIVED_PERSON:
      return addFetchedPeopleToState(state, action.payload);
    case types.RECEIVED_SEARCHED_PEOPLE:
      return addSearchedPeopleToState(state, action.payload);
    case types.RECEIVED_ORG_HIERARCHY:
      return addHierarchyToState(state, action.payload);
    case types.GET_TEAM:
      return addFetchingTeamToState(state, action.payload);
    case types.FAILED_TEAM:
      return addFailedFetchTeamToState(state, action.payload);
    case types.RECEIVED_TEAM:
      return addFetchedTeamToState(state, action.payload);
    case types.GET_USER_LIST:
      return addFetchingListToState(state, action.payload);
    case types.FAILED_USER_LIST:
      return addFailedListToState(state, action.payload);
    case types.RECEIVED_USER_LIST:
      return addFetchedListToState(state, action.payload);
    case types.EDITED_PERSON_ATTRIBUTES:
      return updateUserAttributes(state, action.payload);
    case types.CREATED_PERSON:
      return createdUser(state, action.payload);
    case types.CREATED_TEAM:
      return addModifiedHierarchyToState(state, action.payload);
    case types.GET_USER_PERMISSIONS:
      return addFetchingPermsToState(state, action.payload);
    case types.FAILED_USER_PERMISSIONS:
      return addFailedFetchPermsToState(state, action.payload);
    case types.RECEIVED_USER_PERMISSIONS:
      return addFetchedPermsToState(state, action.payload);
    case types.EDITED_USER_PERMISSIONS:
      return addEditedPermsToState(state, action.payload);
    case types.USERS_TEAM_EDITED:
      return addEditedUsersTeam(state, action.payload);
    case types.USERS_MATRIX_TEAM_EDITED:
      return addEditedUsersMatrixTeam(state, action.payload);
    case types.DETACHED_USER_MATRIX_TEAM:
      return removeUserFromMatrixTeam(state, action.payload);
    case types.EDITED_TEAM_PARENT:
      return addModifiedHierarchyToState(state, action.payload);
    case types.DELETED_TEAM:
      return addModifiedHierarchyToState(state, action.payload);
    case types.EDITED_TEAM_MANAGER:
      return addEditedTeamManagerToState(state, action.payload);
    case types.EDITED_CEO:
      return addModifiedHierarchyToState(state, action.payload);
    case types.EDITED_TEAM_NAME:
      return addEditedTeamNameToState(state, action.payload);
    case types.DELETED_USER:
      return removeDeletedUserFromState(state, action.payload);
    case types.AVATAR_SET:
      return updateAvatarInState(state, action.payload);
    /* Follows */
    case types.GET_FOLLOWED_TEAMS:
      return addFetchingFollowedTeamsToState(state, action.payload);
    case types.FOLLOWED_TEAMS_FAILED:
      return addFailedFollowedTeamsToState(state, action.payload);
    case types.FOLLOWED_TEAMS_RECEIVED:
      return addFetchedFollowedTeamsToState(state, action.payload);
    case types.FOLLOWED_TEAM:
      return followTeamInState(state, action.payload);
    case types.UNFOLLOWED_TEAM:
      return unfollowTeamInState(state, action.payload);
    /* Join requests and team invites */
    case types.GET_MY_JOIN_REQUESTS:
      return addFetchingMyJoinRequestsToState(state, action.payload);
    case types.MY_JOIN_REQUESTS_FAILED:
      return addFailedMyJoinRequestsToState(state, action.payload);
    case types.MY_JOIN_REQUESTS_RECEIVED:
      return addFetchedMyJoinRequestsToState(state, action.payload);

    case types.REQUESTED_TO_JOIN_TEAM:
      return requestToJoinTeamInState(state, action.payload);

    case types.GET_TEAM_JOIN_REQUESTS:
      return addFetchingTeamJoinRequestsToState(state, action.payload);
    case types.TEAM_JOIN_REQUESTS_FAILED:
      return addFailedTeamJoinRequestsToState(state, action.payload);
    case types.TEAM_JOIN_REQUESTS_RECEIVED:
      return addFetchedTeamJoinRequestsToState(state, action.payload);

    case types.APPROVED_JOIN_REQUEST:
      return addApprovedJoinRequestToState(state, action.payload);

    case types.DENIED_JOIN_REQUEST:
      return addDeniedJoinRequestToState(state, action.payload);

    case types.LEFT_TEAM:
      return removeUserFromMatrixTeam(state, action.payload);

    case types.ERROR_RECEIVED_FROM_API:
      return addApiErrorToState(state, action);
    case types.GET_MATRICES:
      return addFetchingMatricesToState(state, action.payload);
    case types.MATRICES_RECEIVED:
      return addMatricesToState(state, action.payload);
    case types.MATRIX_HIERARCHY_RECEIVED:
      return addMatrixHierarchyToState(state, action.payload);
    case types.ADD_RECENT_TEAM:
      return addRecentTeam(state, action.payload);
    case types.ADD_RECENT_USER:
      return addRecentUser(state, action.payload);

    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
