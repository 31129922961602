import 'regenerator-runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { I18nextProvider } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import i18n from 'config/i18n';

import { initializeFirebase } from 'firebase-cfg';
import { setupAxios } from 'config/axios';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'config/reactquery';

import store from 'store';
import persistor from 'persistor';
import MuiThemeProvider from 'config/MuiThemeProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <App />
            </QueryClientProvider>
          </LocalizationProvider>
        </MuiThemeProvider>
      </PersistGate>
    </I18nextProvider>
  </Provider>,
);
setupAxios(store);
initializeFirebase();
serviceWorkerRegistration.register();
