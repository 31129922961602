import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import UserChip from 'Components/Library/UserChip';
import { getLastModifiedStringFromTimestamp } from 'config/helpers';

function WidgetFooter({ data, dragHandleProps }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 24,
        mb: 1.5,
        pl: 2,
        pr: 2,
      }}
      {...dragHandleProps}
    >
      <UserChip sub={data.updated_by} variant="normal" color="transparent" />
      <Typography variant="body2" color="text.secondary" name="widget-footer-last-modified">
        {getLastModifiedStringFromTimestamp(data.last_modified)}
      </Typography>
    </Box>
  );
}

WidgetFooter.propTypes = {
  data: PropTypes.object,
  dragHandleProps: PropTypes.object,
};

export default WidgetFooter;
