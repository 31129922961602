import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { connect, useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { interlocksActions, interlocksSelectors } from 'state/ducks/interlocks/index';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import EventsChartBar from 'Components/Features/Interlocks/DialogViewInterlock/InterlockDetails/InterlockTimeline/EventsChartBar';

const styles = theme => ({
  chartWrapper: {
    width: '100%',
  },
  chartLine: {
    display: 'flex',
    backgroundColor: theme.palette.background.box,
    borderRadius: '6px',
    height: '22px',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  chartBarsWrapper: {
    width: '100%',
    display: 'flex',
    '& > div:first-of-type > div > div': {
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
    },
    '& > div:last-of-type > div > div': {
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
    },
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginLeft: '3px',
  },
});

function InterlockTimeline({ interlockData, contributorChartData, classes }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(interlocksActions.fetchContributorsChart({ id: interlockData?.id, force: true }));
  }, []);

  let chartData = [];

  if (!!contributorChartData?.data) {
    chartData = contributorChartData.data;
  }

  const startDate = moment.unix(interlockData?.created_on);
  const endDate = !!interlockData?.due_date
    ? moment.unix(interlockData?.due_date)
    : moment().add(1, 'week');

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="top"
      alignContent="flex-start"
      justifyContent="space-between"
      flexWrap="wrap"
      mb={2}
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: 1,
        p: 1,
        width: '95%',
      }}
    >
      <Typography variant="caption" color="text.disabled">
        {startDate.format('MMM')}
      </Typography>
      <Typography variant="caption" color="text.disabled">
        {endDate.format('MMM')}
      </Typography>
      {!!chartData?.contributorChartData && (
        <div className={classes.chartWrapper}>
          {chartData.contributors.map(contributor => (
            <div className={classes.chartLine} key={`${contributor.id}-row`}>
              <PersonasAvatar
                sub={contributor.id}
                size="xtiny"
                type={contributor.type}
                className={classes.avatar}
              />
              <div className={classes.chartBarsWrapper}>
                {!!chartData.contributorChartData[contributor.id] &&
                  chartData.contributorChartData[contributor.id].map(
                    (chartColumn, chartColumnIndex) => (
                      <EventsChartBar
                        key={`${chartColumn.id}-${chartColumn.timestamp}-column-chart`}
                        chartColumn={chartColumn}
                        isLast={
                          chartColumnIndex ===
                          chartData.contributorChartData[contributor.id].length - 1
                        }
                        isFirst={chartColumnIndex === 0}
                      />
                    ),
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
    </Box>
  );
}

const mapStateToProps = (state, ownProps) => ({
  contributorChartData: interlocksSelectors.selectContributorChartInterlocks(
    state.main.interlocks,
    ownProps.interlockData?.id,
  ),
});

InterlockTimeline.propTypes = {
  interlockData: PropTypes.object,
  contributorChartData: PropTypes.object,
  classes: PropTypes.exact({
    chartWrapper: PropTypes.string,
    chartLine: PropTypes.string,
    avatar: PropTypes.string,
    chartBarsWrapper: PropTypes.string,
  }),
};

export default connect(mapStateToProps)(withStyles(styles)(InterlockTimeline));
