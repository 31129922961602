import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import SvgIcon from '@mui/material/SvgIcon';

import TrafficGreen from '@mui/icons-material/ThumbUp';
import TrafficRed from '@mui/icons-material/ThumbDown';

// secret comment

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    borderRadius: 20,
    textAlign: 'center',
    // lineHeight: '40px',
    display: 'inline-block',
    flexShrink: 0,
    margin: 8,
    boxShadow: theme.shadows[2],
    fontSize: '1.7142857142857142rem',
  },
  green: {
    color: theme.palette.secondary.main,
    verticalAlign: 'middle',
  },
  red: {
    color: theme.palette.error.main,
    verticalAlign: 'middle',
    marginTop: 2,
  },
  yellow: {
    color: '#F2C94C',
    width: 24,
    height: 24,
    verticalAlign: 'middle',
    marginTop: 2,
  },
  grey: {
    color: theme.palette.primary[400],
  },
  disabled: {
    backgroundColor: theme.palette.primary[700],
  },
});

class TrafficLightIcon extends React.Component {
  render() {
    const { classes, color, disabled } = this.props;
    return (
      <div className={clsx([classes.root, disabled ? classes.disabled : null])}>
        {color === 'red' && (
          <TrafficRed className={clsx([classes.red, disabled ? classes.grey : null])} />
        )}
        {color === 'green' && (
          <TrafficGreen className={clsx([classes.green, disabled ? classes.grey : null])} />
        )}
        {color === 'yellow' && (
          <SvgIcon
            viewBox="0 0 18 18"
            className={clsx([classes.yellow, disabled ? classes.grey : null])}
          >
            <path d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.022 16.2 1.8 12.978 1.8 9C1.8 5.022 5.022 1.8 9 1.8C12.978 1.8 16.2 5.022 16.2 9C16.2 12.978 12.978 16.2 9 16.2Z" />
          </SvgIcon>
        )}
      </div>
    );
  }
}

TrafficLightIcon.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.exact({
    root: PropTypes.string,
    green: PropTypes.string,
    red: PropTypes.string,
    yellow: PropTypes.string,
    grey: PropTypes.string,
    disabled: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default withStyles(styles)(TrafficLightIcon);
