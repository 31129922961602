import * as actions from 'state/ducks/insights/actions';
import { sendAnalyticsData } from 'config/analytics';

// eslint-disable-next-line import/prefer-default-export
export const onTopicCreated = (res, dispatch) => {
  const { result } = res.data;
  sendAnalyticsData('created insight');
  dispatch(actions.createdTopic(result));
};

export const onSurveyCreated = (result, dispatch) => {
  sendAnalyticsData('created survey');
  dispatch(actions.createdSurvey(result));
};
