import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormSlider from 'Components/Library/Forms/Elements/FormSlider';
import FormFreeText from './FormFreeText';

const styles = theme => ({
  row: {
    marginTop: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    width: '50%',
    color: theme.palette.primary[600],
    zIndex: 2,
  },
  labelRight: {
    width: '50%',
    color: theme.palette.primary[600],
    zIndex: 2,
    textAlign: 'end',
  },
  sliderContainer: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  valueLabel: {
    // Fix off-center value label
    left: 'calc(-50% - 8px)',
  },
});

class FormQuadReply extends Component {
  render() {
    const { t, classes } = this.props;
    let { values } = this.props;

    let { scorex } = this.props.values;
    let { scorey } = this.props.values;

    // when creating a topic these are missing and populated to default here
    if (!scorex && !scorey) {
      scorex = 50;
      scorey = 50;
    }

    if (values.quadOptions) {
      values = { ...values, ...values.quadOptions };
    }
    return (
      <div>
        <Typography name="reply-xquestion" variant="subtitle1">
          {values.xquestion}
        </Typography>
        <div className={classes.row}>
          <Typography name="reply-xmin" variant="overline" className={classes.label}>
            {values.xmin}
          </Typography>
          <Typography name="reply-xmax" variant="overline" className={classes.labelRight}>
            {values.xmax}
          </Typography>
        </div>
        <div id="panel-reply-survey-quad-x-slider" className={classes.sliderContainer}>
          <FormSlider
            fieldName="scorex"
            render={fieldProps => (
              <Slider color="secondary" {...fieldProps} valueLabelDisplay="off" />
            )}
          />
        </div>

        <Typography name="reply-yquestion" variant="subtitle1">
          {values.yquestion}
        </Typography>
        <div className={classes.row}>
          <Typography name="reply-ymin" variant="overline" className={classes.label}>
            {values.ymin}
          </Typography>
          <Typography name="reply-ymax" variant="overline" className={classes.labelRight}>
            {values.ymax}
          </Typography>
        </div>
        <div id="panel-reply-survey-quad-y-slider" className={classes.sliderContainer}>
          <FormSlider
            fieldName="scorey"
            render={fieldProps => (
              <Slider color="secondary" {...fieldProps} valueLabelDisplay="off" />
            )}
          />
        </div>
        <FormFreeText
          audienceType={values.audienceType}
          label={
            !!values.opentextfieldlabel
              ? values.opentextfieldlabel
              : t('insights.defaultopencommentlabel')
          }
        />
      </div>
    );
  }
}

FormQuadReply.propTypes = {
  classes: PropTypes.exact({
    row: PropTypes.string,
    label: PropTypes.string,
    labelRight: PropTypes.string,
    sliderContainer: PropTypes.string,
    valueLabel: PropTypes.string,
  }),
  values: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(FormQuadReply));
