import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/MoreVert';

const styles = theme => ({
  modalPaper: {
    borderRadius: 15,
    width: 1400,
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: 0,
      margin: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflow: 'clip',
  },
  modalActions: {
    height: 44,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 20,
    paddingRight: 12,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalContent: {
    flexGrow: 2,
    display: 'flex',
    overflow: 'hidden',
  },
});

class StandardContentDialog extends Component {
  state = {
    menuOpen: false,
    anchorEl: null,
  };

  onBackdropClick = () => {
    if (
      (typeof this.props.preventBackDropCloseFunc === 'undefined' ||
        !this.props.preventBackDropCloseFunc()) &&
      typeof this.props.onBackdropClick() === 'function'
    ) {
      return this.props.onBackdropClick();
    }
    return false;
  };

  toggleMenu = anchorEl => {
    this.setState({ menuOpen: !this.state.menuOpen, anchorEl });
  };

  render() {
    const {
      classes,
      title,
      extraButtons,
      open,
      children,
      enablePortal = true,
      menuItems = null,
      onCloseClick,
      name,
      closeButtonProps,
      menuButtonProps,
      zIndexModifier,
      t,
    } = this.props;

    const { menuOpen, anchorEl } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onBackdropClick}
        classes={{
          paper: classes.modalPaper,
        }}
        disablePortal={!enablePortal}
        BackdropProps={{
          className: 'backdrop',
        }}
        PaperProps={{
          name,
        }}
        sx={{ zIndex: 1250 + zIndexModifier }}
      >
        <div className={classes.modalActions}>
          <div className={classes.title}>{title}</div>
          <div className={classes.actionButtons}>
            {!!menuItems && (
              <>
                <IconButton
                  onClick={evt => this.toggleMenu(evt.currentTarget)}
                  size="medium"
                  {...menuButtonProps}
                  aria-controls={open ? `${name}-header-menu` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  open={menuOpen}
                  id={`${name}-header-menu`}
                  onClose={() => this.toggleMenu()}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {menuItems.map((el, i) =>
                    React.cloneElement(el, {
                      onClick: e => {
                        this.toggleMenu(null);
                        el.props.onClick(e);
                      },
                      key: `cloned-menuitem-${i}`,
                    }),
                  )}
                </Menu>
              </>
            )}
            {extraButtons}
            <IconButton
              onClick={onCloseClick}
              size="medium"
              aria-label={t('general.close')}
              {...closeButtonProps}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={classes.modalContent}>{children}</div>
      </Dialog>
    );
  }
}

StandardContentDialog.propTypes = {
  classes: PropTypes.exact({
    modalPaper: PropTypes.string,
    modalActions: PropTypes.string,
    modalContent: PropTypes.string,
    actionButtons: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.node,
  extraButtons: PropTypes.node,
  open: PropTypes.bool,
  menuItems: PropTypes.node,
  enablePortal: PropTypes.bool,
  name: PropTypes.string,
  onBackdropClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  preventBackDropCloseFunc: PropTypes.func,
  closeButtonProps: PropTypes.object,
  menuButtonProps: PropTypes.object,
  // zIndexModifier is used to ensure that modals are rendered in a predictable order, with the latest opened on top
  zIndexModifier: PropTypes.number,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(StandardContentDialog));
