import axios from 'axios';
import { APIGW_URL } from 'state/constants/api';
import { getStore } from 'store';
import { NODE_TYPE_OBJECTIVE, NODE_TYPE_KR } from 'config/constants';

/* Handle this flow out of redux - there's no point in persisting this data,
And the data is contextually bound to a single interaction.
*/

export const postChatMessage = async message => {
  const state = getStore().getState();

  const { tenantID } = state.auth;
  const accessToken = state.auth.tokens.access_token;

  const payload = {
    question: message,
  };

  const headers = { headers: { Authorization: `Bearer ${accessToken}` } };

  return axios.post(`${APIGW_URL}/assistant/${tenantID}/kbquestion`, payload, headers);
};

const nodeTypeMap = {
  [NODE_TYPE_KR]: 'kr',
  [NODE_TYPE_OBJECTIVE]: 'obj',
};

export const postSuggestRequest = async (nodeType, context) => {
  const state = getStore().getState();

  const { tenantID } = state.auth;
  const accessToken = state.auth.tokens.access_token;

  const endpoint = nodeType === NODE_TYPE_KR && context.input_text ? 'rate' : 'suggest';

  const payload = {
    source: nodeTypeMap[nodeType],
    context,
  };

  const headers = { headers: { Authorization: `Bearer ${accessToken}` } };

  return axios.post(`${APIGW_URL}/assistant/${tenantID}/${endpoint}`, payload, headers);
};
