import * as types from './types';

export const getIntegrations = payload => ({
  type: types.GET_INTEGRATIONS,
  payload,
});

export const integrationFetchSuccess = payload => ({
  type: types.INTEGRATION_FETCH_SUCCESS,
  payload,
});

export const failedIntegrations = payload => ({
  type: types.FAILED_INTEGRATIONS,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API_INTEGRATIONS,
  payload,
});
