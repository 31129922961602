import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import { withNavigation } from 'withRouter';
import { programsSelectors, programsActions } from 'state/ducks/programs';
import PanelCreateProgram from './PanelCreateProgram';

class PanelCreateProgramWrapper extends Component {
  state = {
    name: '',
    submitted: false,
    submitStatus: 0,
    canSubmit: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitted === true) {
      if (this.state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[this.state.requestID].result === 'ok') {
          this.setState({ submitted: false, requestID: null });
          this.props.navigate(
            `/home/programs/${nextProps.actionlog[this.state.requestID].createdId}/settings`,
          );
        } else {
          this.setState({ submitStatus: -1, submitted: false });
        }
      }
    }
    if (this.props.open !== nextProps.open && nextProps.open) {
      // reset to initial state when opening
      this.setState({
        canSubmit: false,
        submitted: false,
        submitStatus: 0,
      });
    }
  }

  onChange = name => evt => {
    this.setState({ [name]: evt.target.value });
  };

  submit = () => {
    const requestID = nanoid(10);
    this.setState({ submitted: true, requestID });

    this.props.dispatch(
      programsActions.createProgram({
        requestID,
        name: this.state.name,
        type: 'program',
      }),
    );
  };

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { name, submitted, submitStatus, canSubmit } = this.state;
    const { activePrograms, zIndexModifier } = this.props;
    return (
      <PanelCreateProgram
        onChange={this.onChange}
        name={name}
        submitted={submitted}
        submitStatus={submitStatus}
        canSubmit={canSubmit}
        submit={this.submit}
        onClose={this.onClose}
        activePrograms={activePrograms}
        zIndexModifier={zIndexModifier}
      />
    );
  }
}

const mapStateToProps = state => ({
  actionlog: state.main.programs.actionlog,
  activePrograms: programsSelectors.selectActivePrograms(state.main.programs),
});

PanelCreateProgramWrapper.propTypes = {
  open: PropTypes.bool,
  actionlog: PropTypes.object,
  navigate: PropTypes.func,
  activePrograms: PropTypes.array,
  dispatch: PropTypes.func,
  onClose: PropTypes.func,
  zIndexModifier: PropTypes.number,
};

export default withNavigation(connect(mapStateToProps)(PanelCreateProgramWrapper));
