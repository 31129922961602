import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';

import RelatedTeamsPopover from './RelatedTeamsPopover';

function RelatedTeamsButton(props) {
  const { classes, teamId, onTeamSelected } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Tooltip title={t('teamHeaderInformation.relatedTeams')}>
        <ButtonBase
          onClick={e => setAnchorEl(e.currentTarget)}
          name="teamInformation-open-teams-list"
          className={clsx([classes.rowItem, classes.hoverItem, classes.button])}
        >
          <AccountTreeIcon className={classes.icon} />
        </ButtonBase>
      </Tooltip>
      <RelatedTeamsPopover
        teamId={teamId}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        onTeamSelected={onTeamSelected}
      />
    </>
  );
}

RelatedTeamsButton.propTypes = {
  classes: PropTypes.shape({
    rowItem: PropTypes.string,
    hoverItem: PropTypes.string,
    button: PropTypes.string,
    icon: PropTypes.string,
  }),
  teamId: PropTypes.string,
  onTeamSelected: PropTypes.func,
};
export default RelatedTeamsButton;
