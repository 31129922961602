import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SettingsIcon from '@mui/icons-material/Settings';
import Slider from 'Components/Common/TgDeltaSlider/MuiSliderPort';
import StandardInput from 'Components/Library/StandardInput';
import TgDeltaSlider from 'Components/Common/TgDeltaSlider';
import FormSliderEditorConnector from './FormSliderEditorConnector';

const styles = theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  spreadRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    '& > div': {
      flexBasis: 1,
      flexGrow: 1,
    },
    '& > div:first-child': {
      marginRight: theme.spacing(),
    },
  },
  icon: {
    fontSize: 16,
  },
  iconButton: {
    width: 36,
    height: 36,
  },
  secondaryButton: {
    ...theme.typography.caption,
    paddingTop: theme.spacing(),
    paddinBottom: theme.spacing(),
    cursor: 'pointer',
    color: theme.palette.primary[600],
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    '& > svg': {
      marginLeft: 4,
    },
  },
  lefticon: {
    marginRight: 8,
  },
  sliderContainer: {
    marginTop: theme.spacing(4),
    '&:first-of-type': {
      marginTop: 0,
    },
  },
  settingsIcon: {
    marginTop: -12,
  },
  settingsMenu: {
    margin: theme.spacing(2),
  },
  /* We need to override the styles of the slider as we're using tg-mui-range-slider
     and to be consistent with a potential delta slider on the same screen
   */
  thumb: {
    backgroundColor: theme.palette.secondary.main,
  },
  track: {
    backgroundColor: theme.palette.secondary.main,
  },
  rail: {
    backgroundColor: theme.palette.primary[400],
  },
});

class SliderEditor extends React.Component {
  state = {
    popOverAnchor: undefined,
    popOverIndex: undefined,
    validationError: false,
  };

  openSettings = (index, event) => {
    this.setState({ popOverAnchor: event.currentTarget, popOverIndex: index });
  };

  closeSettings = () => {
    this.setState({ popOverAnchor: undefined, popOverIndex: undefined });
  };

  render() {
    const { t, classes } = this.props;
    const { popOverAnchor, popOverIndex } = this.state;

    return (
      <div className={this.props.className}>
        <FormSliderEditorConnector
          fieldName="sliders"
          render={fieldProps => (
            <>
              {fieldProps.error && !!fieldProps.helperText && (
                <FormHelperText error>{fieldProps.helperText}</FormHelperText>
              )}
              {fieldProps.entryProps.map(({ onDelete, ...entryProps }, i) => (
                <div
                  key={`option-${i + 1}`}
                  className={classes.sliderContainer}
                  name={entryProps.name}
                >
                  <div className={classes.row}>
                    <StandardInput
                      multiline
                      fullWidth
                      name={`form-slider-editor-connector-question-${i}`}
                      label={t('insights.sliderQuestionPlaceholder')}
                      placeholder={t('insights.sliderQuestionPlaceholder')}
                      margin="dense"
                      {...entryProps.question}
                    />
                  </div>
                  <div className={classes.spreadRow}>
                    <StandardInput
                      multiline
                      name={`form-slider-editor-connector-minlabel-${i}`}
                      label={t('insights.sliderMinLabelPlaceholder')}
                      placeholder={t('insights.sliderMinLabelPlaceholder')}
                      margin="dense"
                      {...entryProps.minLabel}
                    />
                    <StandardInput
                      multiline
                      name={`form-slider-editor-connector-maxlabel-${i}`}
                      label={t('insights.sliderMaxLabelPlaceholder')}
                      placeholder={t('insights.sliderMaxLabelPlaceholder')}
                      margin="dense"
                      {...entryProps.maxLabel}
                    />
                  </div>
                  <div className={classes.spreadRow}>
                    {fieldProps.values[i].type === 'basic' ? (
                      <Slider
                        color="secondary"
                        size="small"
                        value={50}
                        classes={{
                          thumb: classes.thumb,
                          track: classes.track,
                          rail: classes.rail,
                        }}
                      />
                    ) : (
                      <TgDeltaSlider color="secondary" value={[33, 66]} />
                    )}
                    <IconButton
                      name={`slider-settings-btn-${i}`}
                      className={classes.settingsIcon}
                      onClick={e => this.openSettings(i, e)}
                      size="large"
                      aria-label={t('general.edit')}
                    >
                      <SettingsIcon size="small" />
                    </IconButton>
                  </div>
                  <div className={classes.spreadRow}>
                    <Button
                      variant="text"
                      color="secondary"
                      name={`form-slider-editor-connector-add-slider-${i}`}
                      onClick={() => fieldProps.onAdd(i)}
                      disabled={!fieldProps.canAdd}
                    >
                      {t('insights.addAnotherSliderCTA')}
                    </Button>
                    <Button
                      variant="text"
                      onClick={onDelete}
                      name={`form-slider-editor-connector-delete-slider-${i}`}
                    >
                      {t('insights.removeSliderCTA')}
                      <DeleteIcon className={classes.icon} />
                    </Button>
                  </div>
                </div>
              ))}
              {
                // show add button if no sliders
                fieldProps.entryProps.length === 0 && (
                  <Button
                    variant="text"
                    color="secondary"
                    name="form-slider-editor-connector-add-slider-0"
                    onClick={() => fieldProps.onAdd(0)}
                    disabled={!fieldProps.canAdd}
                  >
                    {t('insights.addAnotherSliderCTA')}
                  </Button>
                )
              }
              <Popover
                open={Boolean(popOverAnchor)}
                anchorEl={popOverAnchor}
                onClose={this.closeSettings}
              >
                <FormControl component="fieldset" className={classes.settingsMenu}>
                  <RadioGroup // TODO: Joona to create connector between components
                    name="slider-type-select"
                    value={popOverIndex !== undefined && fieldProps.values[popOverIndex].type}
                    onChange={e => {
                      fieldProps.onTypeChange(popOverIndex, e.target.value);
                      this.closeSettings();
                    }}
                  >
                    <FormControlLabel
                      value="basic"
                      control={<Radio name="slider-settings-type-opt-basic" />}
                      label={t('insights.sliderTypeBasic')}
                    />
                    <FormControlLabel
                      value="delta"
                      control={<Radio name="slider-settings-type-opt-delta" />}
                      label={t('insights.sliderTypeDelta')}
                    />
                  </RadioGroup>
                </FormControl>
              </Popover>
            </>
          )}
        />

        <FormHelperText id="sliders-error-text" error>
          {this.state.validationError && t('insights.emptySlidersErrorText')}
        </FormHelperText>
      </div>
    );
  }
}

SliderEditor.propTypes = {
  classes: PropTypes.exact({
    row: PropTypes.string,
    spreadRow: PropTypes.string,
    icon: PropTypes.string,
    iconButton: PropTypes.string,
    secondaryButton: PropTypes.string,
    lefticon: PropTypes.string,
    sliderContainer: PropTypes.string,
    settingsIcon: PropTypes.string,
    settingsMenu: PropTypes.string,
    thumb: PropTypes.string,
    track: PropTypes.string,
    rail: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(SliderEditor));
