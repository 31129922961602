import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'config/helpers';
import { isEmpty } from 'lodash';
import { peopleSelectors, peopleActions } from 'state/ducks/people';
import { fetchActualSearchResults, getPeopleSuggestions, getTeamSuggestions } from './utils';

const debouncedSearch = debounce(fetchActualSearchResults, 200);

function useSearch(searchString, searchFor, multiSection = true) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const receiveResults = results => {
    setSuggestions(results);
    setIsLoading(false);
  };

  const auth = useSelector(state => state.auth);
  const people = useSelector(state => state.main.people);
  const teamNames = useSelector(state => peopleSelectors.selectTeamNames(state.main.people));
  const selectTeamsManagedBy = userId => peopleSelectors.selectTeamsManagedBy(people, userId);

  const dispatchSearch = () => {
    if (searchString.length > 1) {
      // if not already fetching and input field is filled in
      if (!!searchString.length) {
        setIsLoading(true);

        if (searchFor.includes('ALL') || searchFor.includes('people')) {
          // The organization is searched with a legacy API:
          // TODO: debounce
          dispatch(peopleActions.searchPeople({ searchstring: searchString }));
        }
        if (searchFor.includes('ALL') || searchFor.includes('nodes')) {
          debouncedSearch({
            searchString,
            auth,
            setSuggestions: searchResults => {
              receiveResults(searchResults);
            },
            multiSection,
          });
        }
      }
    }
  };

  // Handle clearing of the search bar
  // TODO: results should probably be cleared
  // every time the search string changes and the new search string does
  // not begin with the old search string
  if (suggestions.length > 0 && searchString === '') {
    setSuggestions([]);
  }

  // Compute matches:
  let results = multiSection ? suggestions?.filter(s => !isEmpty(s)) : suggestions;
  let teamSuggestions = [];
  let peopleSuggestions = [];

  if (!searchString || searchString.length === 0) {
    results = [];
  }

  if (!!searchString && searchString.length > 1 && multiSection) {
    // TODO: org search cannot be used when multiSection is false
    // they are not searched using the cloudsearch API and thus we do
    // not have a compatible match score to sort the results!
    teamSuggestions = getTeamSuggestions(searchString, {
      people,
      teamNames,
      selectTeamsManagedBy,
      searchFor,
    });

    const hasTeams = results.some(result => result.title === 'teams');
    if (hasTeams) {
      results = results.filter(result => result.title !== 'teams');
    }
    results.push({
      title: 'teams',
      suggestions: teamSuggestions,
    });

    peopleSuggestions = getPeopleSuggestions(searchString, {
      people,
      searchFor,
    });
    const hasPeople = results.some(result => result.title === 'people');
    if (hasPeople) {
      results = results.filter(result => result.title !== 'people');
    }
    results.push({
      title: 'people',
      suggestions: peopleSuggestions,
    });
  }

  useEffect(() => {
    dispatchSearch();
  }, [searchString]);

  return [results, isLoading];
}

export default useSearch;
