import React, { useCallback } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import { useActive, useAttrs, useChainedCommands } from '@remirror/react';
import { AddLink, LinkOff } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import { Divider } from '@mui/material';

export const getUrlValue = url => (url.indexOf('://') === -1 ? `//${url}` : url);

export default function LinkButton({ showEditLinkDialog }) {
  const active = useActive();
  const isLinkActive = active.link();
  const url = useAttrs().link()?.href ?? '';
  const chain = useChainedCommands();
  const onRemove = useCallback(() => chain.removeLink().focus().run(), [chain]);

  return (
    <>
      {isLinkActive && (
        <Divider
          variant="middle"
          orientation="vertical"
          sx={{ m: 0.5, ml: 0.25, height: '24px' }}
        />
      )}
      <Button
        className={clsx(['TG-texteditor-button', isLinkActive && 'TG-texteditor-button-active'])}
        onClick={showEditLinkDialog}
        sx={{ width: '32px' }}
        aria-label="toggle link"
      >
        {isLinkActive ? <LinkIcon fontSize="small" /> : <AddLink fontSize="small" />}
      </Button>
      {isLinkActive && (
        <>
          <Button
            className="TG-texteditor-button"
            onClick={() => {
              window.open(getUrlValue(url), '_blank');
            }}
            sx={{ width: '32px' }}
            aria-label="open link"
          >
            <OpenInNewIcon fontSize="small" />
          </Button>
          <Button
            className="TG-texteditor-button"
            onClick={onRemove}
            sx={{ width: '32px' }}
            aria-label="open link"
          >
            <LinkOff fontSize="small" />
          </Button>
        </>
      )}
    </>
  );
}

LinkButton.propTypes = {
  showEditLinkDialog: PropTypes.func,
};
