import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import StandardInput from 'Components/Library/StandardInput';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import HelperText from 'Components/Common/HelperText';
import EmphasizedSection from 'Components/Library/EmphasizedSection';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  caption: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
});

class FormFreeText extends Component {
  render() {
    const { t, classes, audienceType = 'PRG', label } = this.props;
    return (
      <div className={classes.root}>
        <EmphasizedSection>
          <FormTextField
            fieldName="text"
            render={fieldProps => (
              <StandardInput fullWidth multiline {...fieldProps} label={label} />
            )}
          />
        </EmphasizedSection>
        <HelperText variant="info" className={classes.caption}>
          {audienceType === 'TEAM'
            ? t('insights.commentswillbevisiblehintTeam')
            : t('insights.commentswillbevisiblehint')}
        </HelperText>
      </div>
    );
  }
}

FormFreeText.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    caption: PropTypes.string,
  }),
  audienceType: PropTypes.string,
  t: PropTypes.func,
  label: PropTypes.string,
};
export default withTranslation()(withStyles(styles)(FormFreeText));
