import reducer from './reducers';

import * as commitmentsLogic from './logic';
import * as commitmentsTypes from './types';
import * as commitmentsActions from './actions';
import * as commitmentsSelectors from './selectors';

export { commitmentsLogic, commitmentsTypes, commitmentsActions, commitmentsSelectors };

export default reducer;
