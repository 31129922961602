import React from 'react';
import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { commentsActions, commentsSelectors } from 'state/ducks/comments';
import { activitylogActions, activitylogSelectors } from 'state/ducks/activitylog';
import {
  EVENT_TYPE_COMMENT,
  EVENT_TYPE_STATE_SNAPSHOT,
  EVENT_TYPE_RELATIONS_CHANGESET,
} from 'config/constants';

import CommentEditor from 'Components/Library/CommentEditor';
import RichTextComment from './RichTextComment';
import StandardEditEvent from './StandardEditEvent';
import RelationEditEvent from './RelationEditEvent';

const EVENT_ELEMENT_TYPES = {
  [EVENT_TYPE_COMMENT]: RichTextComment,
  [EVENT_TYPE_STATE_SNAPSHOT]: StandardEditEvent,
  [EVENT_TYPE_RELATIONS_CHANGESET]: RelationEditEvent,
};

class ActivityLog extends React.Component {
  componentDidMount() {
    this.fetchData(true);
  }

  componentDidUpdate() {
    this.fetchData();
  }

  fetchData = (force = false) => {
    const { dispatch, nodeId, legacyMode } = this.props;
    dispatch(commentsActions.fetchNodeComments({ nodeId, force }));
    if (!legacyMode) {
      dispatch(activitylogActions.fetchNodeActivitylog({ id: nodeId, force }));
    }
  };

  render() {
    const {
      t,
      eventsObject,
      nodeId,
      comments,
      name,
      embeddedNodeId,
      elementTypeOverrides,
      nodeType,
    } = this.props;
    const eventArray = get(eventsObject, 'data', []);
    const commentArray = get(comments, 'data', []);
    const sortedEvents = orderBy(eventArray.concat(commentArray), ['ts'], 'desc');

    const ELEMENT_TYPES = { ...EVENT_ELEMENT_TYPES, ...elementTypeOverrides };

    return (
      <Box name={name} sx={{ mt: { xs: 2, md: 0 } }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('general.activity')}
        </Typography>
        <CommentEditor nodeId={nodeId} embeddedNodeId={embeddedNodeId} />
        {sortedEvents.map(evt => {
          const ElementType = ELEMENT_TYPES[evt.type];
          if (!ElementType) {
            return null;
          }
          return (
            <Box
              key={`${nodeId}${!!embeddedNodeId ? `-${embeddedNodeId}` : ''}-eventlist-${
                evt.ts
              }-${get(evt, 'id', '')}`}
              sx={{ mb: 2 }}
            >
              <ElementType
                event={evt}
                viewingNodeId={nodeId}
                viewingEmbeddedNodeId={embeddedNodeId}
                nodeType={nodeType}
                triggerUpdate={() => this.fetchData(true)}
              />
            </Box>
          );
        })}
      </Box>
    );
  }
}

ActivityLog.propTypes = {
  // used in mapStateToProps:
  // eslint-disable-next-line react/no-unused-prop-types
  events: PropTypes.array,
  eventsObject: PropTypes.object,
  nodeId: PropTypes.string,
  embeddedNodeId: PropTypes.string,
  name: PropTypes.string,
  t: PropTypes.func,
  dispatch: PropTypes.func,
  comments: PropTypes.object,
  elementTypeOverrides: PropTypes.object,
  nodeType: PropTypes.string,
  legacyMode: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  let legacyMode = false;
  if (ownProps.events) {
    legacyMode = true;
  }
  return {
    comments: commentsSelectors.selectCommentsForNode(
      state.main.comments,
      ownProps.nodeId,
      ownProps.embeddedNodeId,
    ),
    eventsObject: legacyMode
      ? { data: ownProps.events }
      : activitylogSelectors.selectActivitylogForNode(state.main.activitylog, ownProps.nodeId),
    legacyMode,
  };
};

export default withTranslation()(connect(mapStateToProps)(ActivityLog));
