import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Container from '../Container';
import { PanelProps } from './Panel.types';

/*
  Typical use:
   - Container that has a background color that is different from it's parent
  Props: color, defaults to background.box
*/

function Panel(props: PropsWithChildren<PanelProps>) {
  const {
    sx,
    children,
    direction = 'column',
    alignItems = 'flex-start',
    justifyContent = 'flex-start',
    spacing = 1,
    m = 2,
    ...rest
  } = props;
  const appliedSx = {
    backgroundColor: theme => theme.palette.background.box,
    p: m,
    borderRadius: 1,
    ...sx,
  };
  return (
    <Box sx={appliedSx} {...rest}>
      <Container
        m={0}
        spacing={spacing}
        alignItems={alignItems}
        justifyContent={justifyContent}
        direction={direction}
      >
        {children}
      </Container>
    </Box>
  );
}
export default Panel;
