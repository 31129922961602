import { PURGE } from 'redux-persist';
import { validatePersistedState, copyState } from 'state/helpers';
import * as types from './types';

export const initialState = {
  VERSION: 1.02,
  currentDevice: {
    token: null,
  },
  actionlog: {},
};

const addPushCfgToState = (state, payload) => {
  const newState = copyState(state);
  newState.actionlog[payload.requestID] = { result: 'ok' };
  newState.currentDevice.token = payload.token;
  newState.currentDevice.hashed_arn = payload.endpoint_hash;
  return newState;
};

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.REGISTERED_PUSH_ENDPOINT:
      return addPushCfgToState(state, action.payload);

    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
