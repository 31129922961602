import React from 'react';
import PropTypes from 'prop-types';

import UserChip from 'Components/Library/UserChip';

function UserSearchResultWrapper(props) {
  const { item, isHovered, isFocused } = props;

  const sub = !!item ? item.sub : null;
  return <UserChip sub={sub} actionable color={isFocused || isHovered ? 'grey' : 'white'} />;
}

UserSearchResultWrapper.propTypes = {
  item: PropTypes.object,
  isHovered: PropTypes.bool,
  isFocused: PropTypes.bool,
};

export default UserSearchResultWrapper;
