import { enrich } from 'state/helpers';

export function selectBindings(slice) {
  if (!!slice.bindings && !!slice.bindings.fetchStatus) {
    return slice.bindings;
  }
  return enrich();
}

export default selectBindings;
