import React from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useActive, useChainedCommands, useCommands, useHelpers } from '@remirror/react';

const CMD_NAME = 'toggleBulletList';

export default function UnorderedListButton() {
  const { getCommandOptions } = useHelpers();
  const commands = useCommands();
  const chain = useChainedCommands();
  const active = useActive();

  const options = getCommandOptions(CMD_NAME);

  const isActive = active[options.name]() ?? false;

  const toggleStyle = () => {
    if (options.disableChaining) {
      commands[CMD_NAME]();
      return;
    }

    const command = chain[CMD_NAME]();

    command.focus();

    command.run();
  };

  return (
    <Button
      className={clsx(['TG-texteditor-button', isActive && 'TG-texteditor-button-active'])}
      onClick={toggleStyle}
      sx={{ width: '32px' }}
      aria-label="toggle unordered list"
    >
      <FormatListBulletedIcon fontSize="small" />
    </Button>
  );
}
