import * as types from './types';

export const clearForm = payload => ({
  type: types.CLEAR_FORM,
  payload,
});

export const saveForm = payload => ({
  type: types.SAVE_FORM,
  payload,
});

export const loadForm = payload => ({
  type: types.LOAD_FORM,
  payload,
});
