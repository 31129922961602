import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { objectivesSelectors, objectivesHelpers } from 'state/ducks/objectives';
import { peopleSelectors } from 'state/ducks/people';
import { connectionSelectors } from 'state/ducks/connection';

class canEditKeyResultWrapper extends React.Component {
  render() {
    const { render, canEdit, isOnline } = this.props;
    return render(canEdit && isOnline);
  }
}

const mapStateToProps = (state, ownProps) => {
  let objectiveData;
  let keyresultData;
  if (!!ownProps.objectiveData) {
    ({ objectiveData } = ownProps);
  } else if (!!ownProps.objectiveID) {
    objectiveData = objectivesSelectors.selectObjective(
      state.main.objectives,
      ownProps.objectiveID,
    );
  }
  if (!!ownProps.keyresult) {
    objectiveData = objectivesSelectors.selectObjective(
      state.main.objectives,
      ownProps.keyresult.objectiveID,
    );
    keyresultData = ownProps.keyresult;
  } else if (!!ownProps.keyresultID) {
    const objectiveID = objectivesHelpers.parseObjectiveIDfromKeyresultID(ownProps.keyresultID);
    objectiveData = objectivesSelectors.selectObjective(state.main.objectives, objectiveID);

    if (!!objectiveData && objectiveData.ok) {
      for (const kr of objectiveData.data.keyresults) {
        if (kr.keyresultID === ownProps.keyresultID) {
          keyresultData = kr;
          break;
        }
      }
    }
  }
  return {
    isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
    objectiveData,
    canEdit:
      objectiveData &&
      objectiveData.ok &&
      (state.auth.isChangeManager || // change managers currently have wide rights
        objectiveData.data.owner === state.auth.userID || // Can always edit objectives that I own
        (!!keyresultData && keyresultData.owner === state.auth.userID) || // Can always edit krs that I own
        // can edit team objectives of the teams I lead or to which I belong to
        (objectiveData.data.type === 'team' &&
          (peopleSelectors.isMember(
            state.main.people,
            objectiveData.data.teamID,
            state.auth.userID,
            state.auth.teamID,
          ) ||
            peopleSelectors
              .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
              .includes(objectiveData.data.teamID))) ||
        // Edit stuff owned by my subordinates
        peopleSelectors.isManagerOf(
          state.main.people,
          state.auth.userID,
          objectiveData.data.owner,
        ) ||
        (!!keyresultData &&
          peopleSelectors.isManagerOf(state.main.people, state.auth.userID, keyresultData.owner))),
  };
};

canEditKeyResultWrapper.propTypes = {
  render: PropTypes.func,
  canEdit: PropTypes.bool,
  isOnline: PropTypes.bool,
  // The following props are very much used in mapStateToProps:
  // eslint-disable-next-line react/no-unused-prop-types
  objectiveID: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  objectiveData: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  keyresult: PropTypes.object,
};

export default connect(mapStateToProps)(canEditKeyResultWrapper);
