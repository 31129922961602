import React from 'react';
import { SvgIcon } from '@mui/material';

function InterlockIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
      <svg fill="black" fillOpacity="0.6" x={3} y={6} xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 0a1 1 0 0 0 0 2c1.993 0 2.98.9 4.302 2.103l.15.137c.272.247.56.505.873.76H1.5a1 1 0 0 0 0 2h6.325c-.313.255-.601.513-.873.76l-.15.137C5.48 9.1 4.493 10 2.5 10a1 1 0 1 0 0 2c2.78 0 4.286-1.378 5.587-2.568l.21-.192c.683-.62 1.317-1.164 2.11-1.567C11.18 7.279 12.15 7 13.5 7h.586l-1.293 1.293a1 1 0 0 0 1.414 1.414L17.914 6l-3.707-3.707a1 1 0 1 0-1.414 1.414L14.086 5H13.5c-1.35 0-2.319-.279-3.094-.673-.792-.403-1.426-.946-2.108-1.567l-.21-.192C6.785 1.378 5.28 0 2.5 0Z" />
      </svg>
      <defs>
        <clipPath id="clip0_985_6588">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default InterlockIcon;
