export const imgFileToDataUrl = file => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.')); // TODO: solve how this can be translated, using other functions than withTranslation, or get t as parameter
    };

    reader.onload = () => {
      resolve(reader.result);
    };
    if (file instanceof Blob) {
      reader.readAsDataURL(file);
    }
  });
};

export const getCanvasImageCoordinates = (image, crop) => {
  let sourceX = 0;
  let sourceY = 0;
  let sourceWidth = 0;
  let sourceHeight = 0;
  const destX = 0;
  const destY = 0;
  let destWidth = 0;
  let destHeight = 0;

  if (image && crop) {
    const { naturalWidth = 0, naturalHeight = 0 } = image;

    // Determine the sx and sy arguments for drawImage
    // for an image that has dimensions of 2000px * 500px
    // with crop.x = 10% and crop.y = 5%, we should compute
    // sx = 2000 * 10% = 200px
    // sy = 500 * 5% = 25px
    sourceX = naturalWidth * (crop.x / 100);
    sourceY = naturalHeight * (crop.y / 100);

    // Similarly compute the sWidth and sHeight arguments
    sourceWidth = naturalWidth * (crop.width / 100);
    sourceHeight = naturalHeight * (crop.height / 100);

    // the destination arguments:
    // dx, dy are 0 (we draw to the top left of the canvas)
    // dWidth, dHeight are going to be equal to the sWidth and sHeight
    destWidth = sourceWidth;
    destHeight = sourceHeight;
  }

  return {
    sourceX,
    sourceY,
    sourceWidth,
    sourceHeight,
    destX,
    destY,
    destWidth,
    destHeight,
  };
};
