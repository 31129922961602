import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import UserChip from 'Components/Library/UserChip';
import PopoverHeader from 'Components/Library/PopoverHeader';
import JoinTeamButton from './JoinTeamButton';

function TeamMembersPopover(props) {
  const { teamId, anchorEl, onClose, sortedMembers } = props;
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      name="team-members-popover"
    >
      <PopoverHeader>{t('general.teamMembers')}</PopoverHeader>
      <Box m={1}>
        <JoinTeamButton teamId={teamId} />
        {sortedMembers.map(sub => (
          <UserChip color="white" sub={sub} key={`team-info-expanded-memberslist-u-${sub}`} />
        ))}
      </Box>
    </Popover>
  );
}

TeamMembersPopover.propTypes = {
  teamId: PropTypes.string,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  sortedMembers: PropTypes.array,
};
export default TeamMembersPopover;
