import React from 'react';
import PropTypes from 'prop-types';
import ActivityLog from 'Components/Library/ActivityLog';
import { EVENT_TYPE_KREVENT } from 'config/constants';
import KeyresultEvent from './KeyresultEvent';

class KeyresultEventList extends React.Component {
  render() {
    const { keyresult } = this.props;

    return (
      <ActivityLog
        name="panel-view-kr-events-list"
        events={keyresult.events}
        elementTypeOverrides={{ [EVENT_TYPE_KREVENT]: KeyresultEvent }}
        nodeId={keyresult.graph_id}
      />
    );
  }
}

KeyresultEventList.propTypes = {
  keyresult: PropTypes.object,
};

export default KeyresultEventList;
