import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Slider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StandardInput from 'Components/Library/StandardInput';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import HelperText from 'Components/Common/HelperText';

import { OBJ_REFLECTION_MAX_LENGTH } from 'config/constants';

const styles = theme => ({
  root: {
    padding: theme.spacing(),
  },
  track: {
    height: 3,
    border: 0,
  },
  mark: {
    height: 11,
    width: 1,
  },
  red: {
    backgroundColor: theme.palette.confidence.red,
  },
  amber: {
    backgroundColor: theme.palette.confidence.amber,
  },
  green: {
    backgroundColor: theme.palette.confidence.green,
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  sliderCaptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    '& > span': {
      flexGrow: 0,
      flexShrink: 0,
      maxWidth: '28%',
      color: theme.palette.primary[500],
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.625rem',
      },
      '&:first-of-type': {
        textAlign: 'left',
      },
      '&:last-child': {
        textAlign: 'right',
        color: theme.palette.primary.main,
      },
    },
  },
});

class DialogObjectiveReflection extends Component {
  state = {
    reflectionText: '',
    objectiveScore: 0,
  };

  componentDidMount() {
    this.setState({
      reflectionText: this.props.objectiveData.data.reflection,
      objectiveScore: this.props.objectiveData.data.grade,
    });
  }

  handleCancel = () => {
    this.props.onClose();
  };

  setGrade = (event, value) => {
    this.setState({
      objectiveScore: value,
    });
  };

  setText = event => {
    this.setState({
      reflectionText: event.target.value,
    });
  };

  render() {
    const { classes, handleSubmit, submitted, submitStatus, t } = this.props;
    let { reflectionText, objectiveScore } = this.state;

    let barColor = 'green';
    if (objectiveScore <= 60) {
      barColor = 'amber';
    }
    if (objectiveScore <= 40) {
      barColor = 'red';
    }

    if (!objectiveScore) {
      objectiveScore = 0;
    }

    if (!reflectionText) {
      reflectionText = '';
    }

    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="objective-reflection-dialog-title"
        classes={{ paper: classes.root }}
        onClose={this.handleCancel}
      >
        <SavingIndicator
          className={classes.savingIndicator}
          submitted={submitted}
          submitStatus={submitStatus}
          background="light"
        />
        <DialogTitle id="objective-reflection-dialog-title">
          {t('objectives.reflectobjectivetitle')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {t('objectives.reflectobjectivesubtitle')}
          </Typography>
          <HelperText>{t('objectives.reflectobjectivebody')}</HelperText>
          <form>
            <Slider
              name="objective-reflection-dialog-slider"
              value={objectiveScore}
              onChange={this.setGrade}
              marks
              step={10}
              classes={{
                mark: classes.mark,
                markActive: classes[barColor],
                track: clsx([classes.track, classes[barColor]]),
                rail: classes.track,
                thumb: classes[barColor],
              }}
            />
            <div className={classes.sliderCaptions}>
              <Typography variant="caption">{t('objectives.reflectstatusred')}</Typography>
              <Typography variant="caption">{t('objectives.reflectstatusamber')}</Typography>
              <Typography variant="caption">{t('objectives.reflectstatusgreen')}</Typography>
            </div>
            <br />
            <br />
            <StandardInput
              value={reflectionText}
              onChange={this.setText}
              inputProps={{ maxLength: OBJ_REFLECTION_MAX_LENGTH }}
              multiline
              name="objective-reflection-dialog-textfield"
              label={t('objectives.reflectdescribelabel')}
              variant="filled"
              fullWidth
            />
          </form>
          {submitStatus !== -1 ? null : (
            <Typography color="error">{t('general.somethingWentWrongPleaseRetry')}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            name="objective-reflection-dialog-cancel-button"
            disabled={submitted}
            onClick={() => this.handleCancel()}
          >
            {t('general.cancel')}
          </Button>
          <Button
            name="objective-reflection-dialog-confirm-button"
            color="secondary"
            variant="contained"
            onClick={() => handleSubmit(objectiveScore, reflectionText)}
          >
            {t('general.done')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogObjectiveReflection.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    track: PropTypes.string,
    mark: PropTypes.string,
    red: PropTypes.string,
    amber: PropTypes.string,
    green: PropTypes.string,
    savingIndicator: PropTypes.string,
    sliderCaptions: PropTypes.string,
  }),
  objectiveData: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitted: PropTypes.bool,
  submitStatus: PropTypes.number,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(DialogObjectiveReflection));
