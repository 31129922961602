import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import UserChip from 'Components/Library/UserChip';

const styles = () => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    height: 24,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 8,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
    margin: 8,
    marginTop: 4,
    marginBottom: 4,
    '&:last-child': {
      marginBottom: 8,
    },
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  return (
    <div
      style={containerProps.style}
      role={containerProps.role}
      key={containerProps.key}
      className={containerProps.className}
      id={containerProps.id}
      ref={containerProps.ref}
    >
      {children}
    </div>
  );
}

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <UserChip
      name="employeesearch-menuitem"
      id={`employeesearch-result-${suggestion.sub}`}
      sub={suggestion.sub}
      color={isHighlighted ? 'grey' : 'transparent'}
      actionable
    />
  );
}

function getSuggestionValue(suggestion) {
  return `${suggestion.firstName} ${suggestion.lastName}`;
}

class EmployeeSearch extends Component {
  state = {
    value: '',
  };

  componentDidMount() {
    if (this.props.selected && this.props.selected.ok) {
      this.setState({ value: this.props.selected.fullName });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.selected && !this.props.selected) {
      this.setState({ value: newProps.selected.fullName });
    }
  }

  handleSuggestionSelected = (event, { suggestion }) => {
    this.props.onSelect(suggestion.sub);
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.props.onFetchRequested(value);
  };

  handleSuggestionsClearRequested = () => {
    this.props.onClearRequested();
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  renderInput = inputProps => {
    const { classes, ref, variant, ...other } = inputProps;
    const inputClasses = { input: classes.input };
    if (classes.underline) {
      inputClasses.underline = classes.underline;
    }
    return (
      <TextField
        fullWidth
        name="employeesearch-input"
        id={this.props.id}
        variant={variant}
        InputProps={{
          inputRef: ref,
          classes: inputClasses,
          ...other,
        }}
      />
    );
  };

  render() {
    const {
      classes,
      autoFocus,
      suggestions,
      containerClass,
      className,
      inputClass,
      inputVariant = 'standard',
      error,
      t,
    } = this.props;
    return (
      <Autosuggest
        theme={{
          container: clsx([classes.container, className]),
          input: inputClass,
          suggestionsContainerOpen: clsx([classes.suggestionsContainerOpen, containerClass]),
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onSuggestionSelected={this.handleSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          classes,
          error,
          placeholder: t('general.peopleSearchLabel'),
          value: this.state.value,
          onChange: this.handleChange,
          autoFocus,
          variant: inputVariant,
        }}
      />
    );
  }
}

EmployeeSearch.propTypes = {
  selected: PropTypes.object,
  classes: PropTypes.exact({
    container: PropTypes.string,
    suggestionsContainerOpen: PropTypes.string,
    suggestion: PropTypes.string,
    suggestionsList: PropTypes.string,
  }),
  onFetchRequested: PropTypes.func,
  onClearRequested: PropTypes.func,
  onSelect: PropTypes.func,
  ok: PropTypes.bool,
  autoFocus: PropTypes.bool,
  suggestions: PropTypes.array,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  inputClass: PropTypes.string,
  inputVariant: PropTypes.string,
  error: PropTypes.bool,
  t: PropTypes.func,
  id: PropTypes.string,
};

export default withTranslation()(withStyles(styles)(EmployeeSearch));
