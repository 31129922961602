import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { API_BASE_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

const SLICE_NAME = 'programs';

const submitProgramToAPI = function submitProgramToAPIFunction(authToken, tenantID, payload) {
  return axios.post(`${API_BASE_URL}/${tenantID}/createprogram`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getProgramUsersLogic = createLogic({
  type: types.GET_PROGRAM_USERS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectProgramUsers(state.main[SLICE_NAME], action.payload.cpID),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/getprogramusers`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.programUsersReceived(res.data.result));
      })
      .catch(e => {
        dispatch(actions.programUsersFailed({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});

export const createProgramLogic = createLogic({
  type: types.CREATE_PROGRAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    submitProgramToAPI(
      getState().auth.tokens.access_token,
      getState().auth.tenantID,
      action.payload,
    )
      .then(res => {
        dispatch(actions.programCreated(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editProgramLogic = createLogic({
  type: types.EDIT_PROGRAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/editprogram`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.programEdited(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editProgramRootLogic = createLogic({
  type: types.EDIT_PROGRAM_ROOT,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/setprogramroot`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.programRootEdited(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const disableProgramLogic = createLogic({
  type: types.DISABLE_PROGRAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/disableprogram`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.programDisabled(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const enableProgramLogic = createLogic({
  type: types.ENABLE_PROGRAM,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/enableprogram`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.programEnabled(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});
