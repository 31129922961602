import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import MediaQueryWrapper from 'Components/Common/MediaQueryWrapper';

const styles = theme => ({
  root: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: theme.spacing(-0.5),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    flexWrap: 'noWrap',
    alignItems: 'center',
    '& > *': {
      maxWidth: '31%',
      margin: theme.spacing(0.5),
    },
    '& > button': {
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1,
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > *': {
        flexBasis: 'content',
        maxWidth: 'max-content',
      },
    },
  },
});

function CreateDialogActions(props) {
  const { classes, children } = props;
  return <div className={classes.root}>{children}</div>;
}

CreateDialogActions.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
};

export default MediaQueryWrapper()(withStyles(styles)(CreateDialogActions));
