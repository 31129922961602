import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import { AutoSizer } from 'react-virtualized';

import ReactPlayer from 'react-player';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PropTypes from 'prop-types';
import { instance as axios } from 'config/axios';

export const validate = item => !!item.src;
const styles = () => ({
  root: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
  youtube: {
    'object-fit': 'cover',
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

class VideoElement extends Component {
  state = {
    currentSrc: '',
    image: null,
  };

  componentDidMount() {
    if (!!this.props.item.src && !!this.props.item.videotype) {
      this.newUrl(this.props.item.videotype, this.props.item.src);
      this.fetchImage();
    }
    this.controller = new AbortController();
  }

  componentDidUpdate() {
    if (!this.state.currentSrc.includes(this.props.item.src)) {
      if (!!this.props.item.src && !!this.props.item.videotype) {
        this.newUrl(this.props.item.videotype, this.props.item.src);
      }
    }
    if (!this.state.image) {
      this.fetchImage();
    }
  }

  componentWillUnmount() {
    this.controller.abort();
    clearTimeout(this.timeout);
    if (!!this.img) {
      this.img.onload = function empty() {};
      delete this.img;
    }
  }

  newUrl = (videotype, src) => {
    let newurl = '';
    if (videotype === 'youtube') {
      newurl = `https://youtu.be/${src}`;
    } else if (videotype === 'vimeo') {
      newurl = `https://www.vimeo.com/${src}`;
    }
    if (newurl) {
      this.setState({
        currentSrc: newurl,
      });
      this.fetchImage(src);
    }
  };

  // Sets a low quality image at start and when/if the higher quality is loaded changes to that
  // TODO: this could further be improved with a list of urls that would be checked in order
  // as the highest quality is not always there but a medium quality would be
  useProgressiveImg = (lowQualitySrc, highQualitySrc) => {
    this.setState({ image: lowQualitySrc });

    this.img = new Image();
    this.img.src = highQualitySrc;
    this.img.onload = () => {
      if (!this.img) {
        return;
      }
      if ('naturalHeight' in this.img) {
        if (this.img.naturalHeight + this.img.naturalWidth === 0) {
          return;
        }
      } else if (this.img.width + this.img.height === 0) {
        return;
      }
      // this is the youtube default empty thumbnail size
      if (this.img.naturalWidth === 120 && this.img.width === 120 && this.img.height === 90) {
        return;
      }
      this.setState({ image: highQualitySrc });
    };
  };

  fetchImage = (src = this.props.item.src) => {
    if (this.props.item.videotype === 'youtube' && src) {
      this.useProgressiveImg(
        `https://img.youtube.com/vi/${src}/default.jpg`,
        `https://img.youtube.com/vi/${src}/hqdefault.jpg`,
      );
    } else if (this.props.item.videotype === 'vimeo' && src && this.controller) {
      axios
        .get(`https://vimeo.com/api/v2/video/${src}.json`, {
          signal: this.controller.signal,
        })
        .then(response => {
          this.useProgressiveImg(
            response.data[0].thumbnail_small,
            response.data[0].thumbnail_large,
          );
        });
    }
  };

  render() {
    const { classes, item, key, id, mode } = this.props;
    const { src } = item;
    const { currentSrc } = this.state;

    return (
      <div className={classes.root} data-tg-name="ctx-card-el-video" key={key} id={id}>
        {!!src && (
          <div className={classes.content}>
            <AutoSizer disableHeight>
              {({ width }) => {
                const height = (width / 16) * 9;
                if (mode === 'expanded') {
                  return (
                    <ReactPlayer
                      id="ctx-card-yt-iframe"
                      width={width}
                      height={height}
                      url={currentSrc}
                      playing
                    />
                  );
                }
                let classname = '';
                if (this.props.item.videotype === 'youtube') {
                  classname = classes.youtube;
                }
                return (
                  <div style={{ width: `${width}px`, height: `${height}px` }}>
                    <img
                      className={classname}
                      src={this.state.image}
                      width={`${width}px`}
                      height={`${height}px`}
                      alt="Video thumbnail"
                    />
                    <PlayIcon className={classes.playIcon} fontSize="large" htmlColor="white" />
                  </div>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </div>
    );
  }
}

VideoElement.propTypes = {
  item: PropTypes.shape({
    src: PropTypes.string,
    id: PropTypes.string,
    videotype: PropTypes.string,
    __cfg: PropTypes.object,
  }),
  classes: PropTypes.exact({
    dialogpaper: PropTypes.string,
    content: PropTypes.string,
    playIcon: PropTypes.string,
    root: PropTypes.string,
    youtube: PropTypes.string,
  }),
  mode: PropTypes.string,
  key: PropTypes.bool,
  id: PropTypes.string,
};

export default withStyles(styles)(VideoElement);
