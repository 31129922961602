import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Skeleton from '@mui/material/Skeleton';
import { withLocation, withNavigation } from 'withRouter';

import PersonasAvatar from 'Components/Common/PersonasAvatar';
import CommittedIcon from 'Components/Features/Objectives/CommittedIcon';
import DataObjectChip from 'Components/Library/DataObjectChip';

import { openViewKeyresultPanel } from 'config/ModalProvider/helpers';
import KeyresultsIcon from '../KeyresultsIcon';
import KeyresultStatusChip from './KeyresultStatusChip';

const styles = theme => ({
  green: {
    backgroundColor: `${theme.palette.confidence.green} !important`,
  },
  amber: {
    backgroundColor: `${theme.palette.confidence.amber} !important`,
  },
  red: {
    backgroundColor: `${theme.palette.confidence.red} !important`,
  },
  status: {
    height: 24,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    textAlign: 'center',
    borderRadius: 8,
    color: 'white',
    ...theme.typography.body2,
    lineHeight: '24px',
  },
});

class KeyresultChip extends React.Component {
  onClick = e => {
    const { allowNavigate, onClick } = this.props;
    if (allowNavigate || !!onClick) {
      if (allowNavigate) {
        openViewKeyresultPanel(
          null,
          this.props.keyresult.keyresultID,
          null,
          this.props.navigate,
          this.props.location,
        );
      }
      if (!!onClick) {
        onClick(e);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    const {
      keyresult,
      wrapText,
      color,
      actionable,
      hideStatus,
      hideOwner,
      ContextIcon,
      size,
      sx,
      objectiveData,
      placeholder,
      contextIconProps,
      raised,
      secondaryActions,
    } = this.props;

    if (!keyresult) {
      const isObjectiveDataMissing = !!objectiveData && !objectiveData.ok && !objectiveData.error;
      if (placeholder && isObjectiveDataMissing) {
        return (
          <DataObjectChip
            text={placeholder}
            color={color}
            wrapText={wrapText}
            actionable={actionable}
            variant={size}
            dataChipType="okr-keyresult"
            sx={sx}
            icon={<PersonasAvatar sub="" size="xtiny" />}
            // It's not a duplicate
            // eslint-disable-next-line react/jsx-no-duplicate-props
            Icon={ContextIcon}
            contextIconProps={contextIconProps}
            raised={raised}
            secondaryActions={secondaryActions}
          />
        );
      }
      if (isObjectiveDataMissing) {
        // Loading ...
        return <Skeleton variant="rounded" height={32} sx={sx} />;
      }
      return null;
    }

    return (
      <DataObjectChip
        name={`kr-chip-${keyresult.keyresultID}`}
        id={`kr-chip-${actionable}-${keyresult.keyresultID}`}
        icon={!hideOwner ? <PersonasAvatar sub={keyresult.owner} size="xtiny" /> : null}
        // It's not a duplicate
        // eslint-disable-next-line react/jsx-no-duplicate-props
        Icon={ContextIcon}
        onClick={this.onClick}
        text={keyresult.keyresult}
        color={color}
        wrapText={wrapText}
        actionable={actionable}
        variant={size}
        dataChipType="okr-keyresult"
        sx={sx}
        statusComponent={
          !hideStatus ? (
            <>
              {keyresult.committed && <CommittedIcon fontSize="18px" color="action" />}
              <KeyresultStatusChip keyresultData={keyresult} />
            </>
          ) : null
        }
        contextIconProps={contextIconProps}
        raised={raised}
        secondaryActions={secondaryActions}
      />
    );
  }
}

KeyresultChip.propTypes = {
  keyresult: PropTypes.object,
  objectiveData: PropTypes.object,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  location: PropTypes.object,
  navigate: PropTypes.func,
  wrapText: PropTypes.bool,
  actionable: PropTypes.bool,
  allowNavigate: PropTypes.bool,
  hideStatus: PropTypes.bool,
  hideOwner: PropTypes.bool,
  ContextIcon: PropTypes.elementType,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  sx: PropTypes.object,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  contextIconProps: PropTypes.object,
  raised: PropTypes.bool,
  secondaryActions: PropTypes.array,
};

KeyresultChip.defaultProps = {
  actionable: true,
  hideStatus: false,
  hideOwner: false,
  ContextIcon: KeyresultsIcon,
  size: 'large',
};

export default withLocation(withNavigation(withStyles(styles)(KeyresultChip)));
