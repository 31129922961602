import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import { peopleSelectors } from 'state/ducks/people';

import TeamMembersPopover from './TeamMembersPopover';

class TeamMembersButton extends React.Component {
  // If you ever try to convert this to a functional component,
  // do note that you need to make sure that the amount of hook calls does
  // not change between renders or the app will crash.

  // The amount of members may change, between renders and their names being read in a loop
  // could cause fiery death
  state = {
    anchorEl: null,
  };

  setAnchorEl = anchorEl => {
    this.setState({ anchorEl });
  };

  render() {
    const { t, classes, teamId, members, managerSub, selectFullName } = this.props;
    const { anchorEl } = this.state;
    const skeletonSize = 24;

    let sortedMembers = [];
    if (members.ok && !!managerSub) {
      const fullMemberList = members.members.filter(sub => sub !== managerSub);
      sortedMembers = orderBy(fullMemberList, [sub => selectFullName(sub)], ['asc']);
      sortedMembers.unshift(managerSub);
    }

    const membersList = (
      <>
        {!!members.ok &&
          !!sortedMembers.length > 0 &&
          sortedMembers.map((sub, index) => {
            if (index < sortedMembers.length && index < 3) {
              return (
                <PersonasAvatar
                  size="xtiny"
                  shape="square"
                  sub={sub}
                  key={`team-info-personas-avatar-${sub}`}
                />
              );
            }
            return null;
          })}
        {!!members.ok && (
          <Typography
            sx={{
              marginLeft: 1,
              marginTop: '2px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              height: '24px',
              lineHeight: '24px',
            }}
            variant="caption"
            name="teamInformation-members-list-typography"
          >
            {t('teamHeaderInformation.numMembersCaption', { count: sortedMembers.length })}
          </Typography>
        )}
        {!!members.loading && !members.ok && (
          <>
            <Skeleton variant="rect" width={skeletonSize} height={skeletonSize} />
            <Skeleton variant="rect" width={skeletonSize} height={skeletonSize} />
            <Skeleton variant="rect" width={skeletonSize} height={skeletonSize} />
            <Skeleton variant="rect" width={66} height={12} sx={{ ml: 1 }} id="progress-area" />
          </>
        )}
      </>
    );
    return (
      <>
        <Tooltip
          title={
            members.ok
              ? t('teamHeaderInformation.viewAllTooltip', { count: sortedMembers.length })
              : ''
          }
        >
          <Box
            onClick={e => this.setAnchorEl(e.currentTarget)}
            name="teamInformation-open-members-list"
            className={clsx(classes.rowItem, classes.hoverItem)}
            sx={{
              cursor: 'pointer',
              alignItems: 'center !important',
              order: { xs: 20, sm: 0 },
              flexBasis: { xs: 'calc(100% - 16px)', sm: 'auto' },
              p: { xs: 0, sm: 0.5 },
              ml: { xs: -1, sm: 0 },
              mr: { sm: 1, md: 2, lg: 3 },
              mb: { xs: 1, sm: 0 },
            }}
          >
            {membersList}
          </Box>
        </Tooltip>
        <TeamMembersPopover
          teamId={teamId}
          onClose={() => this.setAnchorEl(null)}
          anchorEl={anchorEl}
          sortedMembers={sortedMembers}
        />
      </>
    );
  }
}

TeamMembersButton.propTypes = {
  classes: PropTypes.shape({
    rowItem: PropTypes.string,
    hoverItem: PropTypes.string,
  }),
  teamId: PropTypes.string,
  t: PropTypes.func,
  members: PropTypes.object,
  managerSub: PropTypes.string,
  selectFullName: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  members: peopleSelectors.selectTeamMembers(state.main.people, ownProps.teamId),
  managerSub: peopleSelectors.selectTeamManager(state.main.people, ownProps.teamId),
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
});
export default connect(mapStateToProps)(withTranslation()(TeamMembersButton));
