import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OptionsIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import moment from 'moment';
import UserChip from 'Components/Library/UserChip';
import InteractableCard from 'Components/Library/InteractableCard';
import {
  openTopicReplyPanel,
  openTopicViewSurveyPanel,
  openSurveyDeleteDialog,
} from 'config/ModalProvider/helpers';
import { withLocation, withNavigation } from 'withRouter';
import SurveyTypeChip from '../SurveyTypeChip';

const styles = theme => ({
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // min-width needed for the ellipsis to work inside
    minWidth: 1,
    flexShrink: 100,
    flexBasis: 30,
    flexGrow: 25,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  insightText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(),
    wordBreak: 'break-word',
    display: 'box',
    lineClamp: 4,
    boxOrient: 'vertical',
  },
  secondaryText: {
    color: theme.palette.secondary.main,
    flexShrink: 50,
    flexBasis: 'fit-content',
    flexGrow: 1,
  },
  disabledText: {
    color: theme.palette.action.disabled,
    flexShrink: 50,
    flexBasis: 'fit-content',
    flexGrow: 1,
  },
  spaceBetween: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionsIcon: {
    flexShrink: 0,
    flexGrow: 0,
  },
});

class InsightsCard extends Component {
  state = {
    optionsOpen: false,
  };

  openReplyPanel = () => {
    openTopicReplyPanel(this.props.surveyID, false, this.props.navigate, this.props.location);
  };

  openViewPanel = () => {
    openTopicViewSurveyPanel(
      this.props.surveyID,
      false,
      undefined,
      false,
      this.props.navigate,
      this.props.location,
    );
  };

  getAction = surveyData => {
    const { t, classes, isOnline, canReply } = this.props;
    if (surveyData) {
      if (surveyData.status === 'started') {
        if (surveyData.surveyReply) {
          return [
            true,
            this.openReplyPanel,
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.disabledText}
              name="insights-card-answered-cta"
              noWrap
            >
              {t('insights.insightscardstatusanswered')}
            </Typography>,
          ];
        }
        if (!isOnline) {
          return [
            false,
            null,
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.disabledText}
              noWrap
            >
              {t('insights.insightscardstatusdisconnected')}
            </Typography>,
          ];
        }
        return [
          true,
          canReply ? this.openReplyPanel : () => {},
          <Typography
            variant="subtitle2"
            component="span"
            className={classes.secondaryText}
            name="insights-card-answer-cta"
            noWrap
          >
            {canReply
              ? t('insights.insightscardanswercta')
              : t('insights.insightscardteamisanswering')}
          </Typography>,
        ];
      }
      if (surveyData.status === 'parsed') {
        return [
          true,
          this.openViewPanel,
          <Typography
            variant="subtitle2"
            component="span"
            className={isOnline ? classes.secondaryText : classes.disabledText}
            name="insights-card-results-cta"
            noWrap
          >
            {t('insights.insightscardviewcta')}
          </Typography>,
        ];
      }
    }
    return [
      false,
      null,
      <Typography variant="subtitle2" component="span" className={classes.disabledText} noWrap>
        {t('insights.insightscardstatusstartingsoon')}
      </Typography>,
    ];
  };

  openOptions = e => {
    this.setState({ optionsOpen: e.currentTarget });
    e.preventDefault();
    e.stopPropagation();
  };

  closeOptions = e => {
    this.setState({ optionsOpen: null });
    e.preventDefault();
    e.stopPropagation();
  };

  requestDelete = e => {
    this.closeOptions(e);
    openSurveyDeleteDialog(this.props.surveyID, this.props.navigate, this.props.location);
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { t, classes, survey, canAct, canReply } = this.props;
    const { optionsOpen } = this.state;

    const surveyData = survey.data;

    let timeLeftStr = '';
    let secondsLeft;
    if (surveyData) {
      // eslint-disable-next-line prefer-destructuring
      secondsLeft = surveyData.secondsLeft;
      const daysLeft = secondsLeft / 3600 / 24;
      const hoursLeft = secondsLeft / 3600;
      if (hoursLeft < 25 && secondsLeft > 0) {
        timeLeftStr = t('general.nhoursleft', { hours: Math.floor(hoursLeft) });
      } else if (hoursLeft >= 25) {
        timeLeftStr = t('general.ndaysleft', { days: Math.floor(daysLeft) });
      } else if (hoursLeft > -24 && secondsLeft < 0) {
        const startTimeStamp = moment(surveyData.timestamp);

        timeLeftStr = t('general.nhoursago', { hours: moment().diff(startTimeStamp, 'hours') });
      } else {
        const startTimeStamp = moment(surveyData.timestamp);

        timeLeftStr = t('general.ndaysago', { days: moment().diff(startTimeStamp, 'days') });
      }
    }
    const [actionable, action, cta] = this.getAction(surveyData, secondsLeft);
    return (
      <InteractableCard
        fixedHeight
        id={`survey-card-${surveyData.surveyID}`}
        name="survey-card"
        disabled={!actionable || !(canReply || canAct)}
        onClick={action}
        footer={
          <>
            <UserChip variant="normal" color="grey" sub={surveyData.owner} />
            <Typography
              name="survey-card-timeLeftStr"
              variant="body2"
              color="text.secondary"
              display="block"
            >
              {timeLeftStr}
            </Typography>
          </>
        }
      >
        <CardContent>
          <div className={classes.topRow}>
            {cta}
            <div className={classes.actions}>
              <SurveyTypeChip
                survey={surveyData}
                size="small"
                sx={{ flexShrink: 10000, minWidth: '1px', ml: 1, overflow: 'hidden' }}
              />
              {canAct && (
                <IconButton
                  className={classes.optionsIcon}
                  onClick={this.openOptions}
                  aria-label={t('insights.toggleOptionsLabel')}
                  name="survey-card-card-toggle-options-button"
                  id={`survey-card-card-toggle-options-button-${surveyData.surveyID}`}
                  size="small"
                >
                  <OptionsIcon />
                </IconButton>
              )}
            </div>
          </div>

          <Typography variant="h4" className={classes.insightText} name="insights-card-topic-text">
            {!!surveyData && surveyData.topicText}
          </Typography>
        </CardContent>
        {Boolean(optionsOpen) && (
          <Menu anchorEl={optionsOpen} open={Boolean(optionsOpen)} onClose={this.closeOptions}>
            <MenuItem name="insights-card-options-remove" onClick={this.requestDelete}>
              {t('general.remove')}
            </MenuItem>
          </Menu>
        )}
      </InteractableCard>
    );
  }
}

InsightsCard.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    topRow: PropTypes.string,
    actions: PropTypes.string,
    avatar: PropTypes.string,
    insightText: PropTypes.string,
    secondaryText: PropTypes.string,
    disabledText: PropTypes.string,
    spaceBetween: PropTypes.string,
    optionsIcon: PropTypes.string,
  }),
  survey: PropTypes.object,
  surveyID: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func,
  isOnline: PropTypes.bool,
  t: PropTypes.func,
  canAct: PropTypes.bool,
  canReply: PropTypes.bool,
};

export default withLocation(withNavigation(withTranslation()(withStyles(styles)(InsightsCard))));
