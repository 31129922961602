import HnRContent from 'screens/Features/HnR/HnRContent';
import Stack from '@mui/material/Stack';
import React from 'react';
import { METRIC_IDS } from 'screens/Features/HnR/index';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function TeamHnR({ hnrQueriesData }) {
  if (!hnrQueriesData) {
    return null;
  }
  return (
    <Stack useFlexGap direction="row" spacing={1} sx={{ flexWrap: 'wrap' }}>
      {Object.values(METRIC_IDS).map((metricID, index) => {
        if (hnrQueriesData[index]?.isLoading) {
          return (
            <Box mb={1} key={`loading-${metricID}`}>
              <Skeleton variant="rounded" height={200} width="200px" />
            </Box>
          );
        }
        return (
          <HnRContent
            key={metricID}
            metricID={metricID}
            metricNodes={hnrQueriesData[index].data.data.result.nodes}
          />
        );
      })}
    </Stack>
  );
}

TeamHnR.propTypes = {
  hnrQueriesData: PropTypes.array,
};

export default TeamHnR;
