import reducer from './reducers';

import * as gameplansLogic from './logic';
import * as gameplansTypes from './types';
import * as gameplansActions from './actions';
import * as gameplansSelectors from './selectors';

export { gameplansLogic, gameplansTypes, gameplansActions, gameplansSelectors };

export default reducer;
