/* eslint-disable camelcase */
// the attributes of events as used by the API are not in camelCase
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ActivityEntry from 'Components/Library/ActivityLog/ActivityEntry';
import GraphElementChip from 'Components/Library/GraphElementResolver/GraphElementChip';
import RichTextRenderer from 'Components/Library/RichTextRenderer';

import { SCHEMA_COMMENT } from 'Components/Library/RichTextEditor/schemas';

export class UnwrappedRichTextComment extends React.Component {
  render() {
    const { t, event, viewingEmbeddedNodeId, triggerUpdate } = this.props;
    const { richTextContent, ts, owner, target_node_id, sub_reference, id, deleted, edited } =
      event;
    let title = (
      <Typography
        variant="body2"
        component="span"
        sx={{ flexShrink: 0, whiteSpace: 'pre-wrap' }}
        color="text.secondary"
      >
        {`${t('general.comments.commented')} ${deleted ? `(${t('general.deleted')})` : ''}${
          edited ? `(${t('general.edited')})` : ''
        }`}
      </Typography>
    );
    if (sub_reference && !viewingEmbeddedNodeId) {
      // we're looking at a top level node, such as an Objective
      // but this comment is about an embedded node, such as a Key Result.
      // Display this in the title
      title = (
        <>
          <Typography
            variant="body2"
            component="span"
            sx={{ flexShrink: 0, whiteSpace: 'pre-wrap' }}
            color="text.secondary"
          >
            {`${t('general.comments.commentedOn')} ${deleted ? `(${t('general.deleted')})` : ''}`}
          </Typography>
          <GraphElementChip
            graphId={`${target_node_id}-${sub_reference}`}
            color="transparent"
            actionable={false}
            size="small"
            hideOwner
            hideStatus
          />
        </>
      );
    }
    return (
      <ActivityEntry
        data-tg-name="COMMENT"
        sub={owner}
        targetNode={target_node_id}
        timestamp={ts}
        title={title}
        id={id}
        content={deleted ? '' : richTextContent}
        message={
          deleted ? (
            ''
          ) : (
            <RichTextRenderer schema={SCHEMA_COMMENT} richTextContent={richTextContent} />
          )
        }
        triggerUpdate={triggerUpdate}
        isDeleted={deleted}
      />
    );
  }
}

UnwrappedRichTextComment.propTypes = {
  t: PropTypes.func,
  /* 
      Optional: the ID of the embeddedNode in the context of which we're looking at comments
    */
  viewingEmbeddedNodeId: PropTypes.string,
  event: PropTypes.shape({
    deleted: PropTypes.bool,
    edited: PropTypes.bool,
    id: PropTypes.string,
    owner: PropTypes.string,
    richTextContent: PropTypes.object,
    sub_reference: PropTypes.string,
    target_node_id: PropTypes.string,
    ts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  triggerUpdate: PropTypes.func,
};

export default withTranslation()(UnwrappedRichTextComment);
