import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { insightsSelectors, insightsActions } from 'state/ducks/insights';
import InsightsOverviewWidget from './InsightsOverviewWidget';

const fetchData = props => {
  if (props.audienceType === 'TEAM') {
    if (
      !props.isMember &&
      !props.isManager &&
      !props.isManagerOfManager &&
      !props.isChangeManager
    ) {
      // Do not attempt to fetch, it will fail
      return;
    }
  }
  props.dispatch(
    insightsActions.getSurveys({ audienceID: props.audienceID, audienceType: props.audienceType }),
  );
};

class InsightsOverviewWidgetContainer extends Component {
  componentDidMount() {
    fetchData(this.props);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    fetchData(nextProps);
  }

  render() {
    const {
      isChangeManager,
      isManager,
      isManagerOfManager,
      isMember,
      audienceType,
      surveys,
      surveyIDs,
      maxItems = 5,
      minimized,
    } = this.props;
    return (
      <InsightsOverviewWidget
        surveys={surveys}
        isChangeManager={isChangeManager}
        surveyIDs={surveyIDs}
        minimized={minimized}
        maxItems={maxItems}
        isMember={isMember}
        isManager={isManager}
        isManagerOfManager={isManagerOfManager}
        audienceType={audienceType}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const surveys = [];
  const surveyIDs = insightsSelectors.selectSurveysForAudience(
    state.main.insights,
    ownProps.audienceType,
    ownProps.audienceID,
  );

  if (surveyIDs.ok) {
    for (const surveyID of surveyIDs.data) {
      const survey = insightsSelectors.selectSurvey(state.main.insights, surveyID);
      if (survey.ok) surveys.push(survey.data);
    }
  }
  return {
    isChangeManager: state.auth.isChangeManager,
    surveyIDs,
    surveys,
  };
};

InsightsOverviewWidgetContainer.propTypes = {
  isChangeManager: PropTypes.bool,
  isManagerOfManager: PropTypes.bool,
  isManager: PropTypes.bool,
  // eslint doing it's thing - this props is very much used
  // eslint-disable-next-line react/no-unused-prop-types
  audienceID: PropTypes.string,
  audienceType: PropTypes.string,
  surveys: PropTypes.array,
  surveyIDs: PropTypes.object,
  maxItems: PropTypes.number,
  minimized: PropTypes.bool,
  isMember: PropTypes.bool,
};

export default connect(mapStateToProps)(InsightsOverviewWidgetContainer);
