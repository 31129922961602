import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { get } from 'lodash';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import EmptyState from 'Components/Common/EmptyState';
import TgProgress from 'Components/Common/TgProgress';
import FacilitationEmptyStateIcon from 'Components/Features/DigitalFacilitation/empty_state-icon-facilitation.svg';
import FacilitationSessionCard from '../FacilitationSessionCard';

const styles = theme => ({
  progress: {
    margin: theme.spacing(6),
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 10,
  },
  skeleton: { height: '100%', transform: 'none' },
});

class FacilitationSessionGrid extends Component {
  componentWillUnmount() {
    clearTimeout(this.loadMoreTimeout);
  }

  handleWaypointEnter = () => {
    const isLoading = get(this.props, 'sessions.loading', true);
    const lastEvaluated = get(this.props, 'sessions.lastEvaluated', null);
    if (!!lastEvaluated) {
      if (!isLoading) {
        this.props.fetchSessions(lastEvaluated);
      } else if (!this.loadMoreTimeout) {
        this.loadMoreTimeout = setTimeout(() => {
          this.props.fetchSessions(lastEvaluated);
        }, 3000);
      }
    }
  };

  render() {
    const { sessions, classes, isManager, isMember } = this.props;
    if (!isMember && !isManager) {
      return (
        <div className={classes.root}>
          <EmptyState icon={FacilitationEmptyStateIcon} translationgroup="dfac" noaccess />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        {sessions.ok && sessions.data && sessions.data.length > 0 && (
          <Grid container spacing={3} alignItems="stretch">
            {sessions.data &&
              sessions.data.map(sessionID => {
                if (!!sessionID) {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      xl={3}
                      key={sessionID}
                      id={`facilitation-grid-${sessionID}`}
                    >
                      <FacilitationSessionCard
                        sessionID={sessionID}
                        id={`facilitation-grid-card-${sessionID}`}
                        isManager={isManager}
                        onDelete={this.onDelete}
                      />
                    </Grid>
                  );
                }
                return null;
              })}
            {!!sessions.lastEvaluated && (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                key="facilitation-grid-load-more-item"
                id="facilitation-grid-load-more-item"
              >
                <Waypoint
                  key="facilitation-grid-load-more-skeleton"
                  onEnter={() => this.handleWaypointEnter()}
                >
                  <Skeleton shape="rect" className={classes.skeleton} />
                </Waypoint>
              </Grid>
            )}
          </Grid>
        )}
        {sessions.ok && sessions.data && sessions.data.length === 0 && (
          <EmptyState icon={FacilitationEmptyStateIcon} translationgroup="dfac" />
        )}
        {!sessions.ok && (sessions.loading || !sessions.error) && (
          <TgProgress className={classes.progress} />
        )}
      </div>
    );
  }
}

FacilitationSessionGrid.propTypes = {
  sessions: PropTypes.object,
  classes: PropTypes.exact({
    progress: PropTypes.string,
    root: PropTypes.string,
    skeleton: PropTypes.string,
  }),
  isManager: PropTypes.bool,
  isMember: PropTypes.bool,
  fetchSessions: PropTypes.func,
};

export default withStyles(styles)(FacilitationSessionGrid);
