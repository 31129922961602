import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import * as constants from 'state/constants/api';

import StandardContentDialog from 'Components/Library/StandardContentDialog';
import TaskItemDialogContent from 'Components/Library/TaskItemDialogContent';
import DataObjectChip from 'Components/Library/DataObjectChip';
import ActivityLog from 'Components/Library/ActivityLog';
import DialogHeadline from 'Components/Library/DialogHeadline';
import ConfirmDeleteDialog from 'Components/Library/ConfirmDeleteDialog';
import NodeRelations from 'Components/Library/NodeRelations';

import NotFoundPanelContent from 'Components/Common/NotFoundPanelContent';

import { editSchema } from 'Components/Features/Commitments/schema';
import CommitmentsIcon from 'Components/Features/Commitments/CommitmentsIcon';

import { NODE_TYPE_COMMITMENT } from 'config/constants';
import { activitylogActions } from 'state/ducks/activitylog';
import { commitmentsActions } from 'state/ducks/commitments/index';
import { graphActions } from 'state/ducks/graph/index';
import CommitmentsDetails from './CommitmentsDetails';

function DialogViewCommitment(props) {
  const {
    onClose,
    open,
    t,
    zIndexModifier = 0,
    notFound,
    commitmentData,
    commitmentID,
    canEdit,
    canDelete,
  } = props;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const menuItems = [
    <MenuItem
      id="commitment-actions-menu-delete-option"
      onClick={() => setIsDeleteDialogOpen(true)}
    >
      {t('general.delete')}
    </MenuItem>,
  ];

  const isCommitmentDeleted = commitmentData.fetchStatus === constants.DELETED;

  useEffect(() => {
    if (isCommitmentDeleted) {
      onClose();
    }
  }, [isCommitmentDeleted]);

  const dispatch = useDispatch();

  const changeSubmitted = () => {
    dispatch(activitylogActions.fetchNodeActivitylogDebounced({ id: commitmentID, force: true }));
  };
  return (
    <>
      <StandardContentDialog
        size="large"
        open={open}
        onBackdropClick={onClose}
        onCloseClick={onClose}
        menuButtonProps={{
          id: 'commitment-actions-menu-toggle',
        }}
        closeButtonProps={{
          id: 'panel-view-commitment-close-btn',
        }}
        menuItems={canDelete ? menuItems : null}
        title={
          <DataObjectChip
            Icon={CommitmentsIcon}
            text={t('commitments.pageTitleSingle')}
            variant="normal"
            contextIconProps={{
              sx: {
                color: '#F6C944',
              },
            }}
          />
        }
        zIndexModifier={zIndexModifier}
      >
        {!!notFound ? (
          <NotFoundPanelContent />
        ) : (
          <TaskItemDialogContent
            loading={!commitmentData.ok}
            headlineComponent={
              <DialogHeadline
                dataObject={commitmentData}
                name="commitments"
                editSchema={editSchema}
                additionalProperties={{ id: commitmentID }}
                submitActionCreator={commitmentsActions.editCommitment}
                canEdit={canEdit}
                onSubmitSuccess={changeSubmitted}
              />
            }
            detailsComponent={
              <CommitmentsDetails
                commitmentData={commitmentData}
                commitmentID={commitmentID}
                submitActionCreator={commitmentsActions.editCommitment}
                canEdit={canEdit}
                canDelete={canDelete}
                onSubmitSuccess={changeSubmitted}
              />
            }
            logComponent={
              <ActivityLog nodeId={commitmentData.data?.id} nodeType={NODE_TYPE_COMMITMENT} />
            }
            relationsComponent={
              <NodeRelations
                graphId={commitmentID}
                onSelect={data => {
                  dispatch(
                    graphActions.editRelations({
                      changeset: [
                        {
                          action: 'ADD',
                          from: commitmentID,
                          to: data.node_id,
                          type: 'GENREL',
                        },
                      ],
                    }),
                  );
                }}
                canEdit={canEdit}
              />
            }
          />
        )}
      </StandardContentDialog>
      {!!isDeleteDialogOpen && !!commitmentData.data && (
        <ConfirmDeleteDialog
          onCancel={() => setIsDeleteDialogOpen(false)}
          name={commitmentData.data.name}
          actionProperties={{ id: commitmentData.data.id }}
          instanceName="commitments"
          submitActionCreator={commitmentsActions.deleteCommitment}
        />
      )}
    </>
  );
}

DialogViewCommitment.propTypes = {
  onClose: PropTypes.func,
  commitmentData: PropTypes.object,
  commitmentID: PropTypes.string,
  open: PropTypes.bool,
  notFound: PropTypes.bool,
  t: PropTypes.func,
  zIndexModifier: PropTypes.number,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default withTranslation()(DialogViewCommitment);
