import * as types from './types';

export const fetchNodeActivitylog = payload => ({
  type: types.FETCH_ACTIVITYLOG,
  payload,
});

export const fetchNodeActivitylogDebounced = payload => ({
  type: types.FETCH_ACTIVITYLOG_DEBOUNCED,
  payload,
});

export const fetchedNodeActivitylog = payload => ({
  type: types.RECEIVED_ACTIVITYLOG,
  payload,
});

export const failedNodeActivitylog = payload => ({
  type: types.FAILED_ACTIVITYLOG,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
