// TODO: consider refactoring and using common functions with AdminIntegrations
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { instance as axios } from 'config/axios';

import EmphasizedSection from 'Components/Library/EmphasizedSection';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import ResponsiveDialog from 'Components/Common/ResponsiveDialog';
import { INTEGRATIONS_API_BASE_URL } from 'state/constants/api';
import { SLACK_SERVICE_NAME, USER_TYPE } from 'screens/Features/Admin/IntegrationsView/constants';

import { bindingsActions } from 'state/ducks/bindings/index';
import { styles } from 'screens/Features/Admin/IntegrationsView/IntegrationView.styles';
import signinslackimage from './sign_in_with_slack.png';

class UserIntegrationsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      submitStatus: 0,
      serverErrorText: '',
      openDelete: false,
      selectedServiceName: '',
      openConfirmDelete: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(bindingsActions.getBindings({ force: true }));
  }

  integrate = serviceName => {
    const { accessToken, tenantID } = this.props;
    this.setState({ submitted: true, submitStatus: 1 });
    axios
      .get(`${INTEGRATIONS_API_BASE_URL}/${tenantID}/geturl`, {
        params: { serviceName, type: USER_TYPE },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(response => {
        this.setState({
          submitted: false,
          submitStatus: 0,
        });

        window.open(response.data.result, '_blank');
      })
      .catch(e => {
        this.setState({
          submitted: false,
          submitStatus: -1,
          serverErrorText: e.response.data.message,
        });
      });
  };

  delete = () => {
    const { accessToken, tenantID } = this.props;
    // in future when there will be more integrations -
    // we'll have a separate component for it
    // now there is a need for this check
    this.setState({ submitted: true, submitStatus: 1 });
    let requestData = { serviceName: this.state.selectedServiceName };

    if (this.state.openConfirmDelete) {
      requestData = { ...requestData, force: true };
    }
    // @TODO: wrap into duck
    axios
      .post(`${INTEGRATIONS_API_BASE_URL}/${tenantID}/unbindidentity`, requestData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(response => {
        if (!!response) {
          if (response.data?.result) {
            this.fetch();
            this.setState({
              submitted: false,
              submitStatus: 0,
              openDelete: false,
              openConfirmDelete: false,
            });
          } else {
            this.setState({
              openConfirmDelete: true,
              submitted: false,
            });
          }
        } else {
          this.setState({ submitted: false, submitStatus: 0 });
        }
      })
      .catch(e => {
        let serverErrorText = '';
        if (!!e?.response?.data) {
          if (!!e.response.data.message) {
            serverErrorText = e.response.data.message;
          }
          if (!!e.response.data.error) {
            serverErrorText = e.response.data.error;
          }
        }

        this.setState({
          submitted: false,
          submitStatus: -1,
          serverErrorText,
        });
      });
  };

  getIntegrationStatus = binding => {
    const { t } = this.props;
    if (!binding.status) {
      return (
        <section>
          {t('admin.integrations.noInstall', { selectedServiceName: binding.serviceName })}
        </section>
      );
    }

    if (binding?.connected) {
      return (
        <Button
          onClick={() => this.openDelete(binding.serviceName)}
          id={`delete-button-${binding.serviceName}`}
        >
          {t('admin.integrations.disconnect', { selectedServiceName: binding.serviceName })}
        </Button>
      );
    }

    const loadedImg = this.loadImage(binding.serviceName, t);
    if (loadedImg) {
      return (
        <IconButton
          onClick={() => this.integrate(binding.serviceName)}
          className={this.props.classes.button}
          id={`integrate-button-${binding.serviceName}`}
        >
          {loadedImg}
        </IconButton>
      );
    }

    return null;
  };

  openDelete = serviceName => {
    this.setState({
      openDelete: true,
      selectedServiceName: serviceName,
    });
  };

  closeDelete = () => {
    this.setState({
      openDelete: false,
      submitted: false,
      submitStatus: 0,
    });
  };

  loadImage = (serviceName, t) => {
    switch (serviceName) {
      case SLACK_SERVICE_NAME:
        return (
          <img
            className={this.props.classes.image}
            src={signinslackimage}
            alt={t('admin.integrations.alt', { serviceName })}
            id={serviceName}
            width={172}
            height={40}
          />
        );
      default:
        return null;
    }
  };

  getIntegrationRows = (t, bindings, classes) => {
    const rows = [];
    if (!!bindings && Array.isArray(bindings)) {
      for (const binding of bindings) {
        rows.push(
          <TableRow
            key={binding.serviceName}
            name="integration-row"
            id={`integration-row-${binding.serviceName}`}
            className={classes.row}
          >
            <TableCell>{binding.serviceName}</TableCell>
            <TableCell>{this.getIntegrationStatus(binding)}</TableCell>
          </TableRow>,
        );
      }
    }
    return rows;
  };

  fetch() {
    this.props.dispatch(bindingsActions.getBindings({ force: true }));
  }

  render() {
    const { t, classes, bindingsObject } = this.props;
    const bindings = bindingsObject?.data;

    if (!bindingsObject?.ok || !bindings) {
      return (
        <Typography
          variant="caption"
          color="text.secondary"
          paragraph
          gutterBottom
          align="center"
          name="search-no-results"
        >
          {t('binding.identity.errorCouldNotBind')}
        </Typography>
      );
    }

    const { serverErrorText, submitted, submitStatus, openDelete, selectedServiceName } =
      this.state;

    return (
      <div className={classes.main}>
        <SavingIndicator
          className={classes.savingIndicator}
          submitted={submitted}
          submitStatus={submitStatus}
          errorText={serverErrorText}
        />
        <div className={classes.content}>
          <EmphasizedSection>
            <Paper className={classes.paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('admin.integrations.name')}</TableCell>
                    <TableCell>{t('admin.integrations.status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.getIntegrationRows(t, bindings, classes)}</TableBody>
              </Table>
            </Paper>
          </EmphasizedSection>
        </div>
        {openDelete && (
          <ResponsiveDialog
            title={t('binding.confirmRemoveBindingTitle', { selectedServiceName })}
            open={openDelete}
            name="dialog-confirm-delete-integration"
            closeOnBackDropClick
            secondaryAction={{
              label: t('general.cancel'),
              onClick: this.closeDelete,
              disabled: submitted,
            }}
            primaryAction={{
              label: t('binding.disconnect', { selectedServiceName }),
              onClick: this.delete,
              disabled: submitted,
            }}
          >
            <SavingIndicator
              submitted={submitted}
              submitStatus={submitStatus}
              className={classes.savingIndicator}
            />
            <br />
            <br />
            {t('binding.confirmRemoveBindingBody', { selectedServiceName })}
          </ResponsiveDialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tenantID: state.auth.tenantID,
  accessToken: state.auth.tokens.access_token,
  bindingsObject: state.main.bindings.bindings,
});

UserIntegrationsView.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    main: PropTypes.string,
    paper: PropTypes.string,
    row: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
    button: PropTypes.string,
    savingIndicator: PropTypes.string,
  }),
  t: PropTypes.func,
  dispatch: PropTypes.func,
  accessToken: PropTypes.string,
  bindingsObject: PropTypes.object,
  tenantID: PropTypes.string,
};

export default connect(mapStateToProps)(
  withTranslation()(withStyles(styles)(UserIntegrationsView)),
);
