import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import withStyles from '@mui/styles/withStyles';
import { withLocation, withNavigation } from 'withRouter';
import Fab from 'Components/Common/Fab';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import { openAddObjectivePanel } from 'config/ModalProvider/helpers';
import { connectionSelectors } from 'state/ducks/connection';

const styles = theme => ({
  fabIcon: {
    color: theme.palette.secondary.contrastText,
  },
});

class AddObjectiveButton extends PureComponent {
  handleClick = () => {
    if (!!this.props.teamId) {
      this.handleOpen({ type: 'team', teamId: this.props.teamId });
    } else {
      this.handleOpen({ type: 'personal' });
    }
  };

  handleOpen = params => {
    openAddObjectivePanel(
      { stperiod: this.props.stperiod, ...params },
      this.props.navigate,
      this.props.location,
    );
  };

  render() {
    const { classes, stperiod, isOnline, t, variant, isProvisioned } = this.props;

    if (!stperiod || stperiod === 'current' || !isOnline || !isProvisioned) {
      return null;
    }

    const ButtonElement = variant === 'fab' ? Fab : ContainedIconButton;

    const buttonProps = variant === 'contained' ? { size: 'small' } : {};

    return (
      <ButtonElement
        {...buttonProps}
        name="add-objective-fab"
        color="secondary"
        onClick={this.handleClick}
        aria-label={t('objectives.createNewObjectiveOverline')}
      >
        <AddIcon className={classes.fabIcon} />
      </ButtonElement>
    );
  }
}

const mapStateToProps = state => ({
  sub: state.auth.userID,
  isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
  isProvisioned: state.auth.isProvisioned,
});

AddObjectiveButton.propTypes = {
  classes: PropTypes.exact({
    fabIcon: PropTypes.string,
  }),
  isOnline: PropTypes.bool,
  isProvisioned: PropTypes.bool,
  location: PropTypes.object,
  navigate: PropTypes.func,
  stperiod: PropTypes.string,
  t: PropTypes.func,
  // eslint-disable-next-line
  teamId: PropTypes.string,
  variant: PropTypes.string,
};

AddObjectiveButton.defaultProps = {
  variant: 'fab',
};

export default withLocation(
  withNavigation(
    withTranslation()(
      withStyles(styles, { withTheme: true })(connect(mapStateToProps)(AddObjectiveButton)),
    ),
  ),
);
