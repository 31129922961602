import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { nanoid } from 'nanoid';
import { facilitationActions } from 'state/ducks/facilitation';
import DeleteFacilitationSessionDialog from './DeleteFacilitationSessionDialog';

class DeleteFacilitationSessionDialogContainer extends Component {
  state = {
    submitted: false,
    submitStatus: 0,
    requestID: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.submitted && !!prevState.requestID) {
      if (prevState.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[prevState.requestID].result === 'ok') {
          return { submitStatus: 1, submitted: false };
        }
        return {
          submitStatus: -1,
          submitted: false,
          serverError: nextProps.actionlog[prevState.requestID].message,
        };
      }
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.submitStatus === 1 && this.state.submitted === false) {
      this.timeout = setTimeout(() => {
        this.props.onClose();
      }, 500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  deleteSession = () => {
    const { sessionID, teamID, dispatch } = this.props;
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    dispatch(facilitationActions.deleteSession({ sessionID, teamID, requestID }));
  };

  render() {
    const { submitStatus, submitted, serverError } = this.state;
    const { onClose } = this.props;

    return (
      <DeleteFacilitationSessionDialog
        errorText={serverError}
        submitted={submitted}
        submitStatus={submitStatus}
        onClose={onClose}
        deleteSession={this.deleteSession}
      />
    );
  }
}

const mapStateToProps = state => ({
  actionlog: state.main.facilitation.actionlog,
});

DeleteFacilitationSessionDialogContainer.propTypes = {
  sessionID: PropTypes.string,
  teamID: PropTypes.string,
  onClose: PropTypes.func,
  dispatch: PropTypes.func,
  actionlog: PropTypes.object,
};

export default connect(mapStateToProps)(DeleteFacilitationSessionDialogContainer);
