import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';

import PageTitleBar from 'Components/Library/PageTitleBar';
import ContentPage from 'Components/Library/ContentPage';

const styles = () => ({
  pageNotFoundBody: {
    width: '100%',
  },
});

class NotFound extends Component {
  render() {
    const { t, classes } = this.props;
    return (
      <div>
        <PageTitleBar titleStr="404" />
        <ContentPage>
          <Typography variant="h1" gutterBottom name="content-page-headline">
            {t('general.pageNotFoundTitle')}
          </Typography>
          <Typography
            variant="h3"
            component="span"
            name="page-not-found-body"
            className={classes.pageNotFoundBody}
          >
            {t('general.pageNotFoundBody')}
          </Typography>
        </ContentPage>
      </div>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.exact({
    pageNotFoundBody: PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withStyles(styles)(withTranslation()(NotFound));
