export const GET_TOKENS_WITH_CODE = 'GET_TOKENS_WITH_CODE';
export const REFRESH_TOKENS = 'REFRESH_TOKENS';
export const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
export const TOKEN_REFRESH_FAILURE = 'TOKEN_REFRESH_FAILURE';
export const START_REFRESH_TIMER = 'START_REFRESH_TIMER';
export const VALIDATE_TOKENS = 'VALIDATE_TOKENS';
export const TOKEN_FETCH_SUCCESS = 'TOKEN_FETCH_SUCCESS';
export const TOKEN_FETCH_FAILURE = 'TOKEN_FETCH_FAILURE';

export const GET_MY_TEAMS = 'GET_MY_TEAMS';
export const MY_TEAMS_RECEIVED = 'MY_TEAMS_RECEIVED';
export const MY_TEAMS_FAILED = 'MY_TEAMS_FAILED';

export const SW_TOKEN_CONFIRMED = 'SW_TOKEN_CONFIRMED';

export const REVOKE_TOKENS = 'REVOKE_TOKENS';
export const TOKEN_REVOKE_SUCCESS = 'TOKEN_REVOKE_SUCCESS';
export const TOKEN_REVOKE_FAILURE = 'TOKEN_REVOKE_FAILURE';
