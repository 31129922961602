import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Popover from '@mui/material/Popover';

import TeamChip from 'Components/Library/TeamChip';
import DataObjectChip from 'Components/Library/DataObjectChip';
import SearchOverlay from './SearchOverlay';

const styles = () => ({
  searchPopoverPaper: {
    marginTop: -2, // Align the search input text vertically with the chip text
  },
});

function TeamSelector(props) {
  const {
    classes,
    value,
    onSelect,
    color,
    filterFunc,
    disabled,
    showManaged,
    showRecent,
    name,
    placeholder = '',
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openSearch = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const onSelectHandler = selectedSub => {
    closeSearch();
    onSelect(selectedSub);
  };

  const chip = !!value ? (
    <TeamChip
      color={color}
      teamId={value.id}
      actionable={!disabled}
      onClick={openSearch}
      actOnDownArrow
      name={name}
    />
  ) : (
    <DataObjectChip
      color={color}
      text={placeholder}
      actionable={!disabled}
      onClick={openSearch}
      actOnDownArrow
      name={name}
    />
  );

  return (
    <>
      {chip}
      <Popover
        anchorEl={anchorEl}
        open={open}
        disableAutoFocus
        onClose={closeSearch}
        classes={{
          paper: classes.searchPopoverPaper,
        }}
      >
        <SearchOverlay
          onSelect={onSelectHandler}
          filterFunc={filterFunc}
          showManaged={showManaged}
          showRecent={showRecent}
          name={name}
        />
      </Popover>
    </>
  );
}

TeamSelector.propTypes = {
  classes: PropTypes.exact({
    searchPopoverPaper: PropTypes.string,
  }),
  value: PropTypes.object,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  filterFunc: PropTypes.func,
  showManaged: PropTypes.bool,
  showRecent: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

export default withStyles(styles)(TeamSelector);
