import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { withLocation, withNavigation } from 'withRouter';
import { commitmentsActions } from 'state/ducks/commitments';
import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import StandardDialog from 'Components/Library/StandardDialog';
import UserSelector from 'Components/Library/UserSelector';
import TeamChip from 'Components/Library/TeamChip';
import KeyresultChip from 'Components/Features/Objectives/KeyresultChip';
import { NODE_TYPE_TEAM, NODE_TYPE_KR, RELATION_DOMAIN_TYPE } from 'config/constants';
import { graphActions } from 'state/ducks/graph';
import schema from '../schema';

const styles = theme => ({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(3),
    flex: 10,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    paddingBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    margin: theme.spacing(),
    marginBottom: theme.spacing(3),
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
});

class DialogAddCommitment extends Component {
  onCommitmentCreated = result => {
    const { dispatch, onCommitmentCreated, onClose, navigate, location, domains = [] } = this.props;
    const commitmentID = get(result, 'data.id');
    if (!!onCommitmentCreated && commitmentID) {
      onCommitmentCreated(commitmentID, true, navigate, location);
      for (const domain of domains) {
        if (domain.type === RELATION_DOMAIN_TYPE) {
          dispatch(
            graphActions.editRelations({
              changeset: [
                {
                  action: 'ADD',
                  from: domain.id,
                  to: commitmentID,
                  type: 'GENREL',
                },
              ],
            }),
          );
        }
      }
    } else {
      onClose();
    }
  };

  render() {
    const { domains = [], classes, onClose, t, sub } = this.props;
    const additionalProperties = {
      contributors: [],
    };

    for (const domain of domains) {
      if (domain.type === NODE_TYPE_TEAM) {
        additionalProperties.contributors.push({ type: domain.type, id: domain.id });
      }
    }

    return (
      <Form
        name="add-commitment-form"
        schema={schema}
        initialValues={{
          owner: sub,
          name: '',
          description: null,
        }}
        stateSlice="main.commitments"
        submitActionCreator={commitmentsActions.addCommitment}
        onValueChange={this.onValueChange}
        onSubmitSuccess={this.onCommitmentCreated}
        additionalProperties={additionalProperties}
      >
        <StandardDialog
          open
          onBackdropClick={onClose}
          preventBackDropCloseFunc={this.preventBackDropCloseFunc}
          size="small"
        >
          <LinearIndicator positioning="absolute" />
          <div className={classes.content} id="panel-add-commitment-content">
            <div className={classes.formContainer}>
              <FormTextField
                fieldName="name"
                render={fieldProps => (
                  <InlineEditInput
                    autoFocus
                    label={t('commitments.nameInputLabel')}
                    fullWidth
                    multiline
                    variant="h4"
                    {...fieldProps}
                    sx={{ mb: 2 }}
                  />
                )}
              />
              <FormRichTextField
                fieldName="description"
                render={fieldProps => (
                  <RichTextEditor
                    {...fieldProps}
                    label={t('commitments.descriptionInputLabel')}
                    placeholder={t('commitments.descriptionInputLabel')}
                    toolbarVariant="floating"
                    variant="inline"
                    schema="all"
                  />
                )}
              />
            </div>
            <Stack direction="row" spacing={1}>
              {domains.map(domain => {
                switch (domain.type) {
                  case NODE_TYPE_TEAM:
                    return <TeamChip teamId={domain.id} key={`team-chip-stack-${domain.id}`} />;
                  case NODE_TYPE_KR:
                    return (
                      <KeyresultChip
                        keyresultID={domain.id}
                        allowNavigate={false}
                        actionable={false}
                        key={`keyresult-chip-stack-${domain.id}`}
                      />
                    );
                  default:
                    return null;
                }
              })}
              <FormSelectorField
                fieldName="owner"
                render={fieldProps => <UserSelector showRecent {...fieldProps} />}
              />
            </Stack>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onClose} id="add-commitment-form-cancel">
              {t('general.cancel')}
            </Button>
            <FormButton
              render={buttonProps => (
                <Button
                  id="add-commitment-form-submit"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('commitments.createNewCommitmentButtonLabel')}
                </Button>
              )}
            />
          </div>
        </StandardDialog>
      </Form>
    );
  }
}

DialogAddCommitment.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    formContainer: PropTypes.string,
    actionsContainer: PropTypes.string,
  }),
  t: PropTypes.func,
  sub: PropTypes.string,
  onCommitmentCreated: PropTypes.func,
  onClose: PropTypes.func,
  navigate: PropTypes.func,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  domains: PropTypes.array,
};

const mapStateToProps = state => ({
  sub: state.auth.userID,
});

export default withNavigation(
  withLocation(
    connect(mapStateToProps)(withTranslation()(withStyles(styles)(DialogAddCommitment))),
  ),
);
