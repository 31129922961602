import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useSelector } from 'react-redux';
import TeamChip from 'Components/Library/TeamChip';
import PopoverHeader from 'Components/Library/PopoverHeader';
import { peopleSelectors } from 'state/ducks/people';

function RelatedTeamsPopover(props) {
  const { teamId, anchorEl, onClose, onTeamSelected } = props;
  const { t } = useTranslation();

  const childTeams = useSelector(state =>
    peopleSelectors.selectChildHierarchyFromTeam(state.main.people, teamId),
  );
  const siblingTeams = useSelector(
    state => peopleSelectors.selectSiblingHierarchyFromTeam(state.main.people, teamId),
    // { stabilityCheck: 'never' },
  );
  const parentTeam = useSelector(
    state => peopleSelectors.selectParentHierarchyFromTeam(state.main.people, teamId),
    // { stabilityCheck: 'never' },
  );
  const open = Boolean(anchorEl);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      name="related-teams-popover"
    >
      <PopoverHeader>{t('teamHeaderInformation.relatedTeams')}</PopoverHeader>
      <Box m={1}>
        {!!parentTeam && (
          <TeamChip
            teamId={parentTeam.id}
            actionable
            onClick={() => onTeamSelected(parentTeam.id)}
            Icon={ArrowUpwardIcon}
            color="white"
          />
        )}
        {siblingTeams &&
          siblingTeams.map(team => (
            <TeamChip
              key={`team-info-expanded-teamlist-u-${team.id}`}
              actionable
              onClick={() => onTeamSelected(team.id)}
              teamId={team.id}
              Icon={SwapHorizIcon}
              color="white"
            />
          ))}
        {childTeams &&
          childTeams.map(team => (
            <TeamChip
              key={`team-info-expanded-teamlist-u-${team.id}`}
              actionable
              onClick={() => onTeamSelected(team.id)}
              teamId={team.id}
              Icon={ArrowDownwardIcon}
              color="white"
            />
          ))}
      </Box>
    </Popover>
  );
}

RelatedTeamsPopover.propTypes = {
  teamId: PropTypes.string,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  onTeamSelected: PropTypes.func,
};
export default RelatedTeamsPopover;
