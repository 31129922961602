import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocation, withNavigation } from 'withRouter';
import { facilitationSelectors } from 'state/ducks/facilitation';

import {
  openViewLiveFacilitationPanel,
  openDeleteFacilitationDialog,
} from 'config/ModalProvider/helpers';

import FacilitationSessionCard from './FacilitationSessionCard';

class FacilitationSessionCardContainer extends Component {
  openPanel = () => {
    openViewLiveFacilitationPanel(
      this.props.session.teamID,
      this.props.sessionID,
      false,
      this.props.navigate,
      this.props.location,
    );
  };

  openDelete = () => {
    openDeleteFacilitationDialog(
      this.props.session.teamID,
      this.props.sessionID,
      this.props.navigate,
      this.props.location,
    );
  };

  render() {
    const { session, userSub, isManager } = this.props;
    return (
      <FacilitationSessionCard
        session={session}
        userSub={userSub}
        onClick={this.openPanel}
        openDelete={this.openDelete}
        isManager={isManager}
      />
    );
  }
}

FacilitationSessionCardContainer.propTypes = {
  sessionID: PropTypes.string,
  session: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  userSub: PropTypes.string,
  isManager: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const facilitationSession = facilitationSelectors.selectFacilitationSession(
    state.main.facilitation,
    ownProps.sessionID,
  );
  return {
    session: facilitationSession,
    userSub: state.auth.userID,
  };
};

export default withLocation(
  withNavigation(connect(mapStateToProps)(FacilitationSessionCardContainer)),
);
