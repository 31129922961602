import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FormContext from '../FormContext';

const FormCustomEditable = React.memo(props => {
  const {
    render,
    fieldName,
    value,
    formName,
    onFieldChange,
    error,
    schema,
    isRequired,
    helperText,
  } = props;

  const fieldProps = {
    onChange: e => onFieldChange(fieldName, e),
    value,
    name: `${formName}-${fieldName}`,
    error,
    schema,
    required: isRequired,
    helperText,
  };
  return render(fieldProps);
});

function OuterFormCustomEditable(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormCustomEditable that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render, fieldName } = props;

  const { onFieldChange, values, formName, fieldErrors, showErrors, schema } =
    React.useContext(FormContext);

  const isRequired = get(schema, 'required', []).includes(fieldName);
  const fieldSchema = schema.properties[fieldName];

  return (
    <FormCustomEditable
      fieldName={fieldName}
      render={render}
      onFieldChange={onFieldChange}
      value={get(values, fieldName)}
      formName={formName}
      schema={fieldSchema}
      isRequired={isRequired}
      error={
        !!showErrors && !!fieldErrors && fieldName in fieldErrors ? fieldErrors[fieldName] : ''
      }
      helperText={
        !!showErrors && !!fieldErrors && fieldName in fieldErrors
          ? fieldErrors[fieldName].join(',')
          : ''
      }
    />
  );
}

OuterFormCustomEditable.propTypes = {
  render: PropTypes.func,
  fieldName: PropTypes.string,
};

export default OuterFormCustomEditable;
