import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

const MediaQueryWrapper = () => WrappedComponent =>
  function WidthFunctions(props) {
    const theme = useTheme();
    const wideScreen = useMediaQuery(theme.breakpoints.up('md'));
    const sm = useMediaQuery(theme.breakpoints.down('md'));
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    return <WrappedComponent {...props} wideScreen={wideScreen} xs={xs} sm={sm} />;
  };

export default MediaQueryWrapper;
