import { createLogicMiddleware } from 'redux-logic';
import { insightsLogic } from 'state/ducks/insights';
import { notificationconfigLogic } from 'state/ducks/notificationconfig';

import { authLogic } from './ducks/auth';
import { programsLogic } from './ducks/programs';
import { inboxLogic } from './ducks/inbox';
import { peopleLogic } from './ducks/people';
import { objectivesLogic } from './ducks/objectives';
import { tenantLogic } from './ducks/tenant';
import { facilitationLogic } from './ducks/facilitation';
import { integrationsLogic } from './ducks/integrations';
import { bindingsLogic } from './ducks/bindings';
import { contextLogic } from './ducks/context';
import { commentsLogic } from './ducks/comments';
import { appstatusLogic } from './ducks/appstatus';
import { teamFilterLogics } from './ducks/teamfilter';
import { commitmentsLogic } from './ducks/commitments';
import { gameplansLogic } from './ducks/gameplans';
import { activitylogLogic } from './ducks/activitylog';
import { interlocksLogic } from './ducks/interlocks';
import { graphLogic } from './ducks/graph';
import { tagsLogic } from './ducks/tags';

const logicMiddleware = createLogicMiddleware([
  ...Object.values(tenantLogic),
  ...Object.values(authLogic),
  ...Object.values(programsLogic),
  ...Object.values(objectivesLogic),
  ...Object.values(peopleLogic),
  ...Object.values(inboxLogic),
  ...Object.values(insightsLogic),
  ...Object.values(notificationconfigLogic),
  ...Object.values(facilitationLogic),
  ...Object.values(integrationsLogic),
  ...Object.values(bindingsLogic),
  ...Object.values(contextLogic),
  ...Object.values(commentsLogic),
  ...Object.values(appstatusLogic),
  ...Object.values(teamFilterLogics),
  ...Object.values(commitmentsLogic),
  ...Object.values(gameplansLogic),
  ...Object.values(activitylogLogic),
  ...Object.values(interlocksLogic),
  ...Object.values(graphLogic),
  ...Object.values(tagsLogic),
]);

export default logicMiddleware;
