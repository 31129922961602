import reducer from './reducers';

import * as programsLogic from './logic';
import * as programsTypes from './types';
import * as programsActions from './actions';
import * as programsSelectors from './selectors';

export { programsLogic, programsTypes, programsActions, programsSelectors };

export default reducer;
