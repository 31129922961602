import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function HeadlineSkeleton() {
  return (
    <Box>
      <Skeleton height={72} variant="rounded" sx={{ mb: 2 }} />
      <Skeleton height={72} variant="rounded" sx={{ mb: 1 }} />
    </Box>
  );
}

export default HeadlineSkeleton;
