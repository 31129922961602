import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { get } from 'lodash';
import Chip from '@mui/material/Chip';
import { openViewInterlockPanel } from 'config/ModalProvider/helpers';
import * as apiconstants from 'state/constants/api';
import DataObjectChip from 'Components/Library/DataObjectChip';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import { INTERLOCK_STATUS_COLORS } from 'config/constants';
import { interlocksActions, interlocksSelectors } from 'state/ducks/interlocks/index';

function InterlockChip(props) {
  const {
    graphId,
    wrapText,
    color,
    allowNavigate,
    onClick,
    ContextIcon,
    sx,
    name,
    placeholder,
    variant,
    statusComponent,
    raised,
    actionable,
    secondaryActions,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = e => {
    if (allowNavigate || !!onClick) {
      if (allowNavigate) {
        openViewInterlockPanel(graphId, false, navigate, location);
      }
      if (!!onClick) {
        onClick();
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    dispatch(interlocksActions.fetchInterlock({ id: graphId, force: false }));
  });

  const interlockData = useSelector(
    state => interlocksSelectors.selectInterlock(state.main.interlocks, graphId),
    // { stabilityCheck: 'never' },
  );

  const isDataMissing = !interlockData || (!interlockData.ok && !interlockData.error);

  if (isDataMissing && !placeholder) {
    // Loading
    return <Skeleton variant="rounded" height={32} sx={sx} />;
  }

  if (interlockData.fetchStatus === apiconstants.DELETED || interlockData.error) {
    // The entire interlock has been deleted or something catastrophic is going on with the
    // API connection
    return null;
  }

  return (
    <DataObjectChip
      name={name ?? `interlock-chip-${graphId}`}
      icon={<PersonasAvatar sub={get(interlockData, 'data.owner')} size="xtiny" />}
      // It's not duplicate...:
      // eslint-disable-next-line react/jsx-no-duplicate-props
      Icon={ContextIcon}
      sx={sx}
      onClick={handleClick}
      actionable={actionable || allowNavigate || Boolean(onClick)}
      text={placeholder && isDataMissing ? placeholder : get(interlockData, 'data.name')}
      color={color}
      dataChipType="interlock"
      statusComponent={
        <>
          <Chip
            label={t(`interlocks.statusValueText.${get(interlockData, 'data.status')}`)}
            color={INTERLOCK_STATUS_COLORS[get(interlockData, 'data.status')]}
            variant="outlined"
          />
          {statusComponent}
        </>
      }
      variant={variant}
      wrapText={wrapText || variant === 'xl'}
      raised={raised}
      secondaryActions={secondaryActions}
    />
  );
}

InterlockChip.propTypes = {
  graphId: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'normal', 'large', 'xl']),
  classes: PropTypes.exact({
    root: PropTypes.string,
    red: PropTypes.string,
    amber: PropTypes.string,
    green: PropTypes.string,
    status: PropTypes.string,
  }),
  wrapText: PropTypes.bool,
  ContextIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  allowNavigate: PropTypes.bool,
  sx: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  statusComponent: PropTypes.node,
  raised: PropTypes.bool,
  actionable: PropTypes.bool,
  secondaryActions: PropTypes.array,
};

InterlockChip.defaultProps = {
  ContextIcon: InterlockIcon,
};

export default InterlockChip;
