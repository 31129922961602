import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { orderBy } from 'lodash';

import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import DataObjectChip from 'Components/Library/DataObjectChip';
import StandardDialog from 'Components/Library/StandardDialog';
import TopicNotification from './TopicNotification';
import UnderstandingNotification from './UnderstandingNotification';
import OutdatedOkrsNotification from './OutdatedOkrsNotification';
import PushOptInNotification from './PushOptInNotification';
import TeamInviteRequestNotification from './TeamInviteRequestNotification';
import TeamInviteRequestDeclinedNotification from './TeamInviteRequestDeclinedNotification';
import TeamInviteRequestApprovedNotification from './TeamInviteRequestApprovedNotification';
import NewCommentOnOwnedNodeNotification from './NewCommentOnOwnedNodeNotification';
import MentionedInCommentNotification from './MentionedInCommentNotification';

const styles = theme => ({
  title: {
    height: 44,
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 20,
    paddingRight: 12,
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
});

const TYPE_MAP = {
  survey: TopicNotification,
  understanding: UnderstandingNotification,
  commentsCommentOnOwnedNode: NewCommentOnOwnedNodeNotification,
  commentsUserWasMentioned: MentionedInCommentNotification,
  outdatedOkrReminder: OutdatedOkrsNotification,
  pushOptInRequest: PushOptInNotification,
  newTeamJoinRequest: TeamInviteRequestNotification,
  teamJoinRequestApproved: TeamInviteRequestApprovedNotification,
  teamJoinRequestDeclined: TeamInviteRequestDeclinedNotification,
};

class NotificationsPanel extends Component {
  render() {
    const { classes, open, t, onClose, zIndexModifier } = this.props;
    const inbox = orderBy(this.props.inbox, ['timestamp'], ['desc']);
    return (
      <StandardDialog open={open} onBackdropClick={onClose} zIndexModifier={zIndexModifier}>
        <div className={classes.title}>
          <DataObjectChip text={t('notifications.panel.panelTitle')} variant="normal" />
          <IconButton onClick={onClose} size="medium" aria-label={t('general.close')}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={clsx(classes.content, 'scrollable')}>
          {inbox.map((msg, i) => {
            if (msg.itemtype in TYPE_MAP) {
              const ComponentType = TYPE_MAP[msg.itemtype];
              return (
                <ComponentType key={`notification-panel-msg-${i}-${msg.messageID}`} message={msg} />
              );
            }
            return null;
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} id="notifications-close-button">
            {t('general.close')}
          </Button>
        </DialogActions>
      </StandardDialog>
    );
  }
}

NotificationsPanel.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.exact({
    root: PropTypes.string,
    modal: PropTypes.string,
    content: PropTypes.string,
    title: PropTypes.string,
  }),
  t: PropTypes.func,
  inbox: PropTypes.array,
  onClose: PropTypes.func,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(withStyles(styles)(NotificationsPanel));
