import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { withParams } from 'withRouter';

import { objectivesSelectors } from 'state/ducks/objectives';
import useDomainOkrs from 'hooks/useDomainOkrs';
import ObjectivesTablev2 from 'Components/Features/Objectives/ObjectivesTablev2';

const styles = theme => ({
  domainwidget: {
    backgroundColor: theme.palette.background.box,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    height: '100%',
    overflowY: 'auto',
  },
});

function TabObjectivesFetcher(props) {
  const { classes, params } = props;
  const currentPeriod = useSelector(state =>
    objectivesSelectors.selectCurrentPeriod(state.main.objectives),
  );

  const { teamID, sub } = params;
  let domain = {};

  if (!!teamID) {
    domain = { t: 'team', d: teamID };
  } else if (!!sub) {
    domain = { t: 'personal', d: sub };
  } else {
    domain = null;
  }

  const { okrs } = useDomainOkrs([domain], currentPeriod);

  if (!okrs[0].ok) {
    return null;
  }

  return (
    <Paper className={classes.domainwidget}>
      <ObjectivesTablev2
        domain={domain}
        okrData={okrs[0]}
        period={currentPeriod}
        open
        showControls={false}
        alwaysOpen
        showLastModified={true}
      />
    </Paper>
  );
}

TabObjectivesFetcher.propTypes = {
  params: PropTypes.object,
  classes: PropTypes.exact({
    domainwidget: PropTypes.string,
  }),
};

export default withParams(withStyles(styles)(TabObjectivesFetcher));
