import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataObjectChip from 'Components/Library/DataObjectChip';

function StatusSelector(props) {
  const { onSelect, name, variant, color, value, options, disabled } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const select = opt => {
    onSelect(opt);
    setAnchorEl(null);
  };

  const selectedOption = options?.find(opt => opt.option === value);

  const openMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const selectOption = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    select(option);
  };

  return (
    <>
      <DataObjectChip
        icon={
          <Box
            sx={{
              bgcolor: `${selectedOption?.color}.main`,
              height: 14,
              width: 14,
              borderRadius: '4px',
            }}
          />
        }
        text={
          variant === 'icon' ? (
            ''
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {selectedOption?.text}
            </Box>
          )
        }
        color={color}
        variant={variant === 'select' ? 'normal' : 'large'}
        sx={
          variant === 'select'
            ? {
                height: '24px',
                minHeight: '24px',
                borderRadius: '8px !important',
                width: 'fit-content',
              }
            : { width: 'fit-content' }
        }
        actionable={!disabled}
        onClick={disabled ? undefined : openMenu}
        name={name}
        statusComponent={
          variant === 'select' ? (
            <ExpandMoreIcon fontSize="13.333px" color="action" sx={{ ml: 1 }} />
          ) : null
        }
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        name={`${name}-popover`}
      >
        {
          // eslint-disable-next-line no-shadow
          options?.map(({ option, text, color }) => (
            <DataObjectChip
              text={text}
              color="white"
              actionable
              icon={
                <Box
                  sx={{
                    bgcolor: `${color}.main`,
                    height: 14,
                    width: 14,
                    borderRadius: '4px',
                    marginLeft: '5px',
                  }}
                />
              }
              sx={{
                backgroundColor: 'background.paper',
                margin: '8px',
              }}
              onClick={e => selectOption(e, option)}
              key={`statusselector-opt-${option}`}
              name={`${name}-opt-${option}`}
            />
          ))
        }
      </Popover>
    </>
  );
}

StatusSelector.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'select', 'icon']),
  color: PropTypes.oneOf(['white', 'transparent']),
  disabled: PropTypes.bool,
};

StatusSelector.defaultProps = {
  variant: 'outlined',
  color: 'white',
  disabled: false,
};
export default StatusSelector;
