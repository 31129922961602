import * as types from './types';

export const fetchInterlock = payload => ({
  type: types.FETCH_INTERLOCK,
  payload,
});

export const fetchedInterlock = payload => ({
  type: types.RECEIVED_INTERLOCK,
  payload,
});

export const failedInterlock = payload => ({
  type: types.FAILED_INTERLOCK,
  payload,
});

export const addInterlock = payload => ({
  type: types.ADD_INTERLOCK,
  payload,
});

export const addedInterlock = payload => ({
  type: types.ADDED_INTERLOCK,
  payload,
});

export const editInterlock = payload => ({
  type: types.EDIT_INTERLOCK,
  payload,
});

export const updatedInterlock = payload => ({
  type: types.INTERLOCK_UPDATED,
  payload,
});

export const deleteInterlock = payload => ({
  type: types.DELETE_INTELOCK,
  payload,
});

export const interlockDeleted = payload => ({
  type: types.INTERLOCK_DELETED,
  payload,
});

export const fetchUserInterlocks = payload => ({
  type: types.FETCH_USER_INTERLOCKS,
  payload,
});

export const fetchResolvedUserInterlocks = payload => ({
  type: types.FETCH_RESOLVED_USER_INTERLOCKS,
  payload,
});

export const fetchedResolvedUserInterlocks = payload => ({
  type: types.RECEIVED_RESOLVED_USER_INTERLOCKS,
  payload,
});

export const failedResolvedUserInterlocks = payload => ({
  type: types.FAILED_RESOLVED_USER_INTERLOCKS,
  payload,
});

export const failedUserInterlocks = payload => ({
  type: types.FAILED_USER_INTERLOCKS,
  payload,
});

export const fetchedUserInterlocks = payload => ({
  type: types.RECEIVED_USER_INTERLOCKS,
  payload,
});

export const fetchTeamInterlocks = payload => ({
  type: types.FETCH_TEAM_INTERLOCKS,
  payload,
});

export const fetchResolvedTeamInterlocks = payload => ({
  type: types.FETCH_RESOLVED_TEAM_INTERLOCKS,
  payload,
});

export const fetchedResolvedTeamInterlocks = payload => ({
  type: types.RECEIVED_RESOLVED_TEAM_INTERLOCKS,
  payload,
});

export const failedResolvedTeamInterlocks = payload => ({
  type: types.FAILED_RESOLVED_TEAM_INTERLOCKS,
  payload,
});

export const fetchContributors = payload => ({
  type: types.FETCH_CONTRIBUTORS,
  payload,
});

export const fetchedContributors = payload => ({
  type: types.RECEIVED_CONTRIBUTORS,
  payload,
});

export const failedContributors = payload => ({
  type: types.FAILED_CONTRIBUTORS,
  payload,
});

export const editContributors = payload => ({
  type: types.EDIT_CONTRIBUTORS,
  payload,
});

export const updatedContributors = payload => ({
  type: types.CONTRIBUTORS_UPDATED,
  payload,
});

export const editContributorsStatus = payload => ({
  type: types.EDIT_CONTRIBUTORS_STATUS,
  payload,
});

export const updatedContributorsStatus = payload => ({
  type: types.CONTRIBUTORS_STATUS_UPDATED,
  payload,
});

export const fetchContributorsChart = payload => ({
  type: types.FETCH_CONTRIBUTOR_CHART_INTERLOCKS,
  payload,
});

export const fetchedContributorsChart = payload => ({
  type: types.RECEIVED_CONTRIBUTOR_CHART_INTERLOCKS,
  payload,
});

export const failedContributorsChart = payload => ({
  type: types.FAILED_CONTRIBUTOR_CHART_INTERLOCKS,
  payload,
});

export const resetInterlockContributorsCache = payload => ({
  type: types.REFRESH_CONTRIBUTORS_CACHE,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
