import { instance as axios } from 'config/axios/index';
import { API_OBJECTIVES_URL } from 'state/constants/api';
import { sendAnalyticsData } from 'config/analytics';
import * as actions from 'state/ducks/objectives/actions';

export const submitCompanyObjectiveToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createcompanyobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const onCompanyObjectiveCreated = (res, state, dispatch) => {
  const { result } = res.data;
  result.ownerdetails = {
    firstname: state.auth.firstName,
    lastname: state.auth.lastName,
  };
  sendAnalyticsData('created company objective');
  dispatch(actions.objectiveCreated(result));
};

export const submitTeamObjectiveToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createteamobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const onTeamObjectiveCreated = (res, state, action, dispatch) => {
  const { result } = res.data;
  result.ownerdetails = {
    firstname: state.auth.firstName,
    lastname: state.auth.lastName,
  };
  result.parent = action.payload.parent;
  sendAnalyticsData('created team objective');
  dispatch(actions.objectiveCreated(result));
};

export const submitPersonObjectiveToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createpersonalobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const onPersonObjectiveCreated = (res, state, action, dispatch) => {
  const { result } = res.data;
  result.ownerdetails = {
    firstname: state.auth.firstName,
    lastname: state.auth.lastName,
  };
  result.parent = action.payload.parent;
  sendAnalyticsData('created personal objective');
  dispatch(actions.objectiveCreated(result));
};

export const submitNewKeyResultToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/createkeyresult`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const onKeyresultCreated = (res, state, dispatch) => {
  const result = { ...res.data.result, sub: state.auth.userID };
  sendAnalyticsData('created keyresult');
  dispatch(actions.keyresultCreated(result));
};

export const getTeamObjectivesFromAPI = (authToken, tenantID, payload) => {
  const { force, ...params } = payload;
  return axios.get(`${API_OBJECTIVES_URL}/${tenantID}/getteamobjectives`, {
    params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const submitDeleteObjectiveToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/deleteobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const getObjectiveLinksFromAPI = (authToken, tenantID, payload) => {
  const { force, ...params } = payload;
  return axios.get(`${API_OBJECTIVES_URL}/${tenantID}/getobjectivelinks`, {
    params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const getObjectiveWithIdFromAPI = (authToken, tenantID, payload) => {
  const { force, ...params } = payload;
  return axios.get(`${API_OBJECTIVES_URL}/${tenantID}/getobjective`, {
    params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const submitUpdatedKeyResultToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/updatekeyresult`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const submitEditedKeyResultToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/editkeyresult`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const submitUpdatedObjectiveToAPI = (authToken, tenantID, payload) =>
  axios.post(`${API_OBJECTIVES_URL}/${tenantID}/editobjective`, payload, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

export const onKeyResultUpdated = (res, state, payload, dispatch) => {
  const result = {
    ...res.data.result,
    sub: state.auth.userID,
    comment: payload.comment,
  };
  sendAnalyticsData('updated keyresult');
  dispatch(actions.keyresultUpdated(result));
};
