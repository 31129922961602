import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Autorenew, ReportProblemOutlined } from '@mui/icons-material';

function AiError({ onRetry }) {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        p: 2,
        alignItems: 'center',
        direction: 'column',
      }}
    >
      <ReportProblemOutlined sx={{ fontSize: 32, color: 'text.disabled', mb: 1 }} />
      <Typography variant="subtitle2" color="text.disabled">
        {t('ai.errorMessage')}
      </Typography>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        sx={{ mt: 3 }}
        onClick={() => onRetry()}
        data-testid="ai-error-try-again"
      >
        <Autorenew sx={{ fontSize: '16px', mr: 1 }} />
        {t('general.tryAgain')}
      </Button>
    </Stack>
  );
}

AiError.propTypes = {
  onRetry: PropTypes.func,
};

export default AiError;
