import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import StandardConfirmationDialog from 'Components/Library/StandardConfirmationDialog';
import HelperText from 'Components/Common/HelperText';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
  },
  indicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
});

class SurveyDeleteDialog extends Component {
  render() {
    const {
      errorText,
      submitted,
      submitStatus,
      t,
      deleteSurvey,
      deleteInsight,
      onClose,
      open,
      manyPulses,
      deletedOk,
      survey,
      topic,
      classes,
    } = this.props;

    if (
      survey.error ||
      survey.fetchStatus === '-404' ||
      topic.error ||
      topic.fetchStatus === '-404'
    ) {
      let errorMessage = t('general.oops');
      if (topic.fetchStatus === '-404') {
        errorMessage = t('insights.notfound');
      }
      return (
        <StandardConfirmationDialog
          title={t('general.confirmDeleteTitle')}
          open={open}
          name="dialog-confirm-delete-insight"
          closeOnBackDropClick
          primaryAction={{
            label: t('general.close'),
            onClick: onClose,
          }}
        >
          <span className={classes.error}>{errorMessage}</span>
        </StandardConfirmationDialog>
      );
    }
    if (deletedOk) {
      return (
        <StandardConfirmationDialog
          title={t('general.confirmDeleteTitle')}
          open={open}
          name="dialog-confirm-delete-insight"
          closeOnBackDropClick
          primaryAction={{
            label: t('general.close'),
            onClick: onClose,
          }}
        >
          {t('insights.deletedInfoText')}
        </StandardConfirmationDialog>
      );
    }
    if (!survey || !survey.ok || manyPulses === 'WAITING') {
      return (
        <StandardConfirmationDialog
          title={t('general.confirmDeleteTitle')}
          open={open}
          name="dialog-confirm-delete-insight"
          closeOnBackDropClick
          progress
        />
      );
    }
    if (manyPulses === 'MANY') {
      return (
        <StandardConfirmationDialog
          title={t('general.confirmDeleteTitle')}
          open={open}
          name="dialog-confirm-delete-insight"
          closeOnBackDropClick
          tertiaryAction={{
            label: t('insights.deleteSurvey'),
            onClick: () => deleteSurvey(survey.data.surveyID),
          }}
          secondaryAction={{
            label: t('general.cancel'),
            onClick: onClose,
          }}
          primaryAction={{
            label: t('insights.deleteInsight'),
            onClick: () => deleteInsight(survey.data.topicID),
          }}
        >
          <SavingIndicator
            submitStatus={submitStatus}
            errorText={errorText}
            submitted={submitted}
            className={classes.indicator}
          />
          <Typography variant="body1" gutterBottom>
            {t('insights.confirmDeleteTextMany')}
          </Typography>
          <HelperText>{t('insights.deleteIsPermanentHelperText')}</HelperText>
        </StandardConfirmationDialog>
      );
    }
    return (
      <StandardConfirmationDialog
        title={t('general.confirmDeleteTitle')}
        open={open}
        name="dialog-confirm-delete-insight"
        closeOnBackDropClick
        secondaryAction={{
          label: t('general.cancel'),
          onClick: onClose,
        }}
        primaryAction={{
          label: t('general.delete'),
          onClick: () => deleteInsight(survey.data.topicID),
        }}
      >
        <SavingIndicator
          submitStatus={submitStatus}
          errorText={errorText}
          submitted={submitted}
          className={classes.indicator}
        />
        <Typography variant="body1" gutterBottom>
          {t('insights.confirmDeleteText')}
        </Typography>
        <HelperText>{t('insights.deleteIsPermanentHelperText')}</HelperText>
      </StandardConfirmationDialog>
    );
  }
}

SurveyDeleteDialog.propTypes = {
  classes: PropTypes.exact({
    indicator: PropTypes.string,
    error: PropTypes.string,
  }),
  deleteInsight: PropTypes.func,
  deleteSurvey: PropTypes.func,
  submitStatus: PropTypes.number,
  submitted: PropTypes.bool,
  errorText: PropTypes.string,
  t: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  manyPulses: PropTypes.string,
  deletedOk: PropTypes.bool,
  survey: PropTypes.object,
  topic: PropTypes.object,
};

export default withStyles(styles)(withTranslation()(SurveyDeleteDialog));
