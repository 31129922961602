import * as types from './types';

export const replyToSurvey = payload => ({
  type: types.REPLY_TO_SURVEY,
  payload,
});

export const failedSurveyReply = payload => ({
  type: types.FAILED_SURVEY_REPLY,
  payload,
});

export const repliedToSurvey = payload => ({
  type: types.REPLIED_TO_SURVEY,
  payload,
});

export const replyToInterpretation = payload => ({
  type: types.REPLY_TO_INTERPRETATION,
  payload,
});

export const repliedToInterpretation = payload => ({
  type: types.REPLIED_TO_INTERPRETATION,
  payload,
});

export const getSingleTopic = payload => ({
  type: types.GET_TOPIC,
  payload,
});

export const deleteUnderstanding = payload => ({
  type: types.DELETE_UNDERSTANDING,
  payload,
});

export const understandingDeleted = payload => ({
  type: types.UNDERSTANDING_DELETED,
  payload,
});

export const deleteInsight = payload => ({
  type: types.DELETE_INSIGHT,
  payload,
});

export const insightDeleted = payload => ({
  type: types.INSIGHT_DELETED,
  payload,
});

export const deleteSurvey = payload => ({
  type: types.DELETE_SURVEY,
  payload,
});

export const surveyDeleted = payload => ({
  type: types.SURVEY_DELETED,
  payload,
});

export const topicNotFound = payload => ({
  type: types.TOPIC_NOT_FOUND,
  payload,
});

export const failedToFetchTopic = payload => ({
  type: types.FAILED_TO_GET_TOPIC,
  payload,
});

export const fetchedTopic = payload => ({
  type: types.FETCHED_TOPIC,
  payload,
});

export const getTopicSurveys = payload => ({
  type: types.GET_TOPIC_SURVEYS,
  payload,
});

export const failedToFetchTopicSurveys = payload => ({
  type: types.FAILED_TO_GET_TOPIC_SURVEYS,
  payload,
});

export const fetchedTopicSurveys = payload => ({
  type: types.FETCHED_TOPIC_SURVEYS,
  payload,
});

export const getInterpretationTopics = payload => ({
  type: types.GET_INTERPRETATION_TOPICS,
  payload,
});

export const failedToFetchInterpretationTopics = payload => ({
  type: types.FAILED_TO_GET_INTERPRETATION_TOPICS,
  payload,
});

export const fetchedInterpretationTopics = payload => ({
  type: types.FETCHED_INTERPRETATION_TOPICS,
  payload,
});

export const getInterpretationStats = payload => ({
  type: types.GET_INTERPRETATION_STATS,
  payload,
});

export const failedToFetchInterpretationStats = payload => ({
  type: types.FAILED_TO_GET_INTERPRETATION_STATS,
  payload,
});

export const fetchedInterpretationStats = payload => ({
  type: types.FETCHED_INTERPRETATION_STATS,
  payload,
});

export const getSurveys = payload => ({
  type: types.GET_SURVEYS,
  payload,
});

export const failedToFetchSurveys = payload => ({
  type: types.FAILED_TO_GET_SURVEYS,
  payload,
});

export const fetchedSurveys = payload => ({
  type: types.FETCHED_SURVEYS,
  payload,
});

export const getSingleSurvey = payload => ({
  type: types.GET_SINGLE_SURVEY,
  payload,
});

export const failedToFetchSingleSurvey = payload => ({
  type: types.FAILED_TO_GET_SINGLE_SURVEY,
  payload,
});

export const fetchedSingleSurvey = payload => ({
  type: types.FETCHED_SINGLE_SURVEY,
  payload,
});

export const createTopic = payload => ({
  type: types.CREATE_TOPIC,
  payload,
});

export const createdTopic = payload => ({
  type: types.CREATED_TOPIC,
  payload,
});

export const createSurvey = payload => ({
  type: types.CREATE_SURVEY,
  payload,
});

export const createdSurvey = payload => ({
  type: types.CREATED_SURVEY,
  payload,
});

export const createInterpretationTopic = payload => ({
  type: types.CREATE_INTERPRETATION_TOPIC,
  payload,
});

export const createdInterpretationTopic = payload => ({
  type: types.CREATED_INTERPRETATION_TOPIC,
  payload,
});

export const getSurveyStats = payload => ({
  type: types.GET_SURVEY_STATS,
  payload,
});

export const failedToFetchSurveyStats = payload => ({
  type: types.FAILED_TO_GET_SURVEY_STATS,
  payload,
});

export const fetchedSurveyStats = payload => ({
  type: types.FETCHED_SURVEY_STATS,
  payload,
});

export const insightsApiError = payload => ({
  type: types.INSIGHTS_API_ERROR,
  payload,
});
