import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { useTranslation, withTranslation } from 'react-i18next';
import PageTitleBar from 'Components/Library/PageTitleBar/index';
import { StackedLineChart } from '@mui/icons-material';
import { isEnabled } from 'config/flags';
import NotFound from 'screens/NotFound';
import PersonaSelector from 'Components/Library/PersonaSelector/index';
import { peopleSelectors } from 'state/ducks/people/index';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import EmphasizedSection from 'Components/Library/EmphasizedSection/index';
import Paper from '@mui/material/Paper';
import TeamHnRHoc from 'screens/Features/HnR/TeamHnrHoc';
import { NODE_TYPE_TEAM } from 'config/constants';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    width: '100%',
    padding: 0,
    margin: 0,
    justifyContent: 'flex-start',
    display: 'flex',
    flexGrow: 20,
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: 32,
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
    position: 'relative',
    flexGrow: 10,
    height: '100%',
  },
  centeredWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    maxWidth: '40vw',
    backgroundColor: theme.palette.primary['100'],
  },
});

export const METRIC_IDS = Object.freeze({
  STATUS_OF_GOALS_OKR: 'STATUS_OF_GOALS_OKR',
  STATUS_OF_CONFIDENCE: 'STATUS_OF_CONFIDENCE',
  STATUS_OF_INTERLOCKS: 'STATUS_OF_INTERLOCKS',
  STATUS_OF_COMMITMENTS: 'STATUS_OF_COMMITMENTS',
  OKR_TRACKING: 'OKR_TRACKING',
  COMMITMENT_TRACKING: 'COMMITMENT_TRACKING',
  OKRS_CREATED: 'OKRS_CREATED',
  TIMELY_COMMITMENTS: 'TIMELY_COMMITMENTS',
  OBJECTIVE_COUNT: 'OBJECTIVE_COUNT',
  KEYRESULT_COUNT: 'KEYRESULT_COUNT',
  COMMITMENT_COUNT: 'COMMITMENT_COUNT',
  INTERLOCK_COUNT: 'INTERLOCK_COUNT',
  VISION_AND_PURPOSE_FILLED: 'VISION_AND_PURPOSE_FILLED',
  WILL_NOT_DO_FILLED: 'WILL_NOT_DO_FILLED',
  VALUES_DRIVERS_PRINCIPLES: 'VALUES_DRIVERS_PRINCIPLES',
});

function HealthAndRecommendations({ classes, myTeams }) {
  const { t } = useTranslation();

  const [selectedTeam, setSelectedTeam] = useState(myTeams[0]);

  if (!isEnabled('H&R')) {
    return <NotFound />;
  }

  const onTeamSelect = value => {
    setSelectedTeam(value);
  };

  return (
    <div className={classes.root}>
      <PageTitleBar
        titleIcon={<StackedLineChart color="secondary" />}
        titleStr={t('hnr.pageTitle')}
      />
      <section>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
          sx={{
            flexShrink: 0,
            flexGrow: 0,
            flexBasis: '100%',
          }}
        >
          <EmphasizedSection>
            <Paper sx={{ maxWidth: '200px' }}>
              <PersonaSelector
                showRecent={false}
                color="white"
                onSelect={onTeamSelect}
                filterFunc={filterObject => filterObject.type === NODE_TYPE_TEAM}
                ctaNode={
                  <Button
                    startIcon={<SearchIcon />}
                    sx={{ width: '100%', justifyContent: 'start' }}
                  >
                    {!!selectedTeam?.text ? selectedTeam.text : t('hnr.filterTeam')}
                  </Button>
                }
              />
            </Paper>
          </EmphasizedSection>
        </Box>
        <TeamHnRHoc teamID={selectedTeam?.id || selectedTeam} />
      </section>
    </div>
  );
}

const mapStateToProps = state => ({
  myTeams: peopleSelectors.selectManagedTeams(
    state.main.people,
    state.auth.myTeams,
    state.auth.userID,
  ),
});

HealthAndRecommendations.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    main: PropTypes.string,
    root: PropTypes.string,
    centeredWrapper: PropTypes.string,
    section: PropTypes.string,
  }),
  myTeams: PropTypes.array,
};

// @ts-ignore
export default withTranslation()(
  // @ts-ignore
  withStyles(styles)(connect(mapStateToProps)(HealthAndRecommendations)),
);
