import { PURGE } from 'redux-persist';
import { validatePersistedState, enrich, copyState, fetching, apiError } from 'state/helpers';
import { onFetchStarted, onListFetchSuccess, onFetchFailed } from 'state/defaultLogic';
import { AnyAction } from 'redux';
import * as constants from '../../constants/api';
import * as types from './types';
import {
  IAddTagPayload,
  IDeleteTagPayload,
  IEditTagPayload,
  ILinkedTagNodesData,
  ITag,
  ITagNodesData,
  ITagsError,
} from './types';

export interface IStateTags {
  VERSION: number;
  tags: {
    all: {
      canFetch: boolean;
      data?: ITagNodesData;
      error: boolean;
      fetchStatus: string;
      lastFetched: number;
      loading: boolean;
      ok: boolean;
    };
    linked: {
      canFetch: boolean;
      data?: ITagNodesData;
      error: boolean;
      fetchStatus: string;
      lastFetched: number;
      loading: boolean;
      ok: boolean;
    };
  };
  linkedtagsv2: any;
  actionlog: any;
}

export const initialState: IStateTags = {
  VERSION: 1.06,
  tags: {
    all: {
      canFetch: false,
      error: false,
      fetchStatus: '0',
      lastFetched: 0,
      loading: false,
      ok: false,
    },
    linked: {
      canFetch: false,
      error: false,
      fetchStatus: '0',
      lastFetched: 0,
      loading: false,
      ok: false,
    },
  },
  linkedtagsv2: {},
  actionlog: {},
};

function addFetchingAllTagsToState(state: IStateTags) {
  const newState = copyState(state);
  newState.tags.all = fetching(newState.tags.all);

  return newState;
}

function addFetchedAllTagsToState(state: IStateTags, payload) {
  const newState = copyState(state);
  newState.tags.all = enrich({
    data: payload,
    lastFetched: Date.now(),
    fetchStatus: constants.OK,
    ok: true,
  });
  return newState;
}

function addFailedAllTagsToState(state: IStateTags) {
  const newState = copyState(state);
  newState.tags.all = apiError(newState.tags);
  return newState;
}

function addFetchingInstanceTagsToState(state: IStateTags, payload: ITag) {
  const newState = copyState(state);
  newState.tags[payload.id] = fetching(newState.tags[payload.id]);

  return newState;
}

function addFetchedInstanceTagsToState(state: IStateTags, payload: ILinkedTagNodesData) {
  const newState = copyState(state);
  newState.tags[payload.id] = enrich({
    data: { nodes: payload.nodes },
    lastFetched: Date.now(),
    fetchStatus: constants.OK,
    ok: true,
  });
  return newState;
}

function addFailedInstanceTagsToState(state: IStateTags) {
  const newState = copyState(state);
  newState.tags.all = apiError(newState.tags.all);
  return newState;
}

function addLinkedTagToState(state: IStateTags, payload: ILinkedTagNodesData) {
  const newState = copyState(state);
  newState.tags.all = enrich({
    data: { nodes: payload.nodes },
    lastFetched: Date.now(),
    fetchStatus: constants.OK,
    ok: true,
  });
  return newState;
}

function removeDeletedTagFromState(state: IStateTags, payload: IDeleteTagPayload) {
  const newState = JSON.parse(JSON.stringify(state));
  if (payload.id) {
    newState.tags.all.data.nodes = newState.tags.all.data.nodes.filter(
      tag => tag.id !== payload.id,
    );
  }
  return newState;
}

function addEditedTagToState(state: IStateTags, payload: IEditTagPayload) {
  const newState = copyState(state);
  Object.assign(
    newState.tags.all.data.nodes.find(tag => tag.id === payload.id),
    payload,
  );
  newState.tags.all = enrich({
    data: newState.tags.all.data,
    lastFetched: Date.now(),
    fetchStatus: constants.OK,
    ok: true,
  });
  return newState;
}

function addTagToState(state: IStateTags, payload: IAddTagPayload) {
  const newState = copyState(state);
  newState.tags.all.data.nodes.push(payload);
  newState.tags.all = { ...newState.tags.all };

  return newState;
}

function removeLinkedTagFromState(state: IStateTags, payload: ILinkedTagNodesData) {
  const newState = copyState(state);
  newState.tags[payload.id].data.nodes = newState.tags[payload.id].data.nodes.filter(
    tag => tag.id !== payload.nodes[0].id,
  );
  return newState;
}

function addApiErrorToStateTags(state: IStateTags, payload: ITagsError) {
  const newState = copyState(state);
  newState.tags.all = { result: 'error' };
  if (!!payload.error) {
    newState.actionlog[payload.requestID].message = payload.error;
  }
  return newState;
}

const reducer = (
  // eslint-disable-next-line default-param-last
  state = JSON.parse(JSON.stringify(initialState)) as IStateTags,
  action: AnyAction,
): IStateTags => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case types.GET_INSTANCE_TAGS:
      return addFetchingInstanceTagsToState(state, action.payload);
    case types.INSTANCE_TAGS_FETCH_SUCCESS:
      return addFetchedInstanceTagsToState(state, action.payload);
    case types.FAILED_INSTANCE_TAGS:
      return addFailedInstanceTagsToState(state);
    case types.GET_ALL_TAGS:
      return addFetchingAllTagsToState(state);
    case types.ALL_TAGS_FETCH_SUCCESS:
      return addFetchedAllTagsToState(state, action.payload);
    case types.FAILED_ALL_TAGS:
      return addFailedAllTagsToState(state);
    case types.LINKED_TAG:
      return addLinkedTagToState(state, action.payload);
    case types.UNLINKED_TAG:
      return removeLinkedTagFromState(state, action.payload);
    case types.DELETED_TAG:
      return removeDeletedTagFromState(state, action.payload);
    case types.ADDED_TAG:
      return addTagToState(state, action.payload);
    case types.EDITED_TAG:
      return addEditedTagToState(state, action.payload);
    case types.GET_LINKED_TAGS:
      return onFetchStarted({ state, payload: action.payload, key: 'linkedtagsv2' });
    case types.LINKED_TAGS_FETCH_SUCCESS:
      return onListFetchSuccess({
        state,
        payload: action.payload,
        key: 'linkedtagsv2',
        mapFn: data => data.id,
      });
    case types.FAILED_LINKED_TAGS:
      return onFetchFailed({ state, payload: action.payload, key: 'linkedtagsv2' });
    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    case types.ERROR_RECEIVED_FROM_API_TAGS:
      return addApiErrorToStateTags(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
