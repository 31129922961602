import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import {
  onFacilitationConcluded,
  onFacilitationStarted,
} from 'state/ducks/facilitation/logic-handlers';
import { API_FACILITATION_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';

import * as selectors from './selectors';

const SLICE_NAME = 'facilitation';

function helperErrorHandler(payload, e, dispatch, selectedAction = actions.errorTryAgainLater) {
  const errorPayload = { ...payload };
  if (e.response && e.response.data && e.response.data.error) {
    errorPayload.errormsg = e.response.data.error;
  }
  dispatch(selectedAction(errorPayload));
}

export const startSessionLogic = createLogic({
  type: types.START_FACILITATION_SESSION,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_FACILITATION_URL}/${getState().auth.tenantID}/startsession`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(res => {
        onFacilitationStarted(res, dispatch);
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const getSessionLogic = createLogic({
  type: types.FETCH_FACILITATION_SESSION,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectFacilitationSession(state.main[SLICE_NAME], action.payload.sessionID),
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_FACILITATION_URL}/${getState().auth.tenantID}/getsession`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.fetchedSession(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch, actions.errorFailedToFetchSession);
      })
      .then(() => done());
  },
});

export const getInputsLogic = createLogic({
  type: types.FETCH_FACILITATION_INPUTS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectFacilitationInputs(state.main[SLICE_NAME], action.payload.sessionID),
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_FACILITATION_URL}/${getState().auth.tenantID}/getinputs`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        result.sessionID = action.payload.sessionID;
        dispatch(actions.fetchedInputs(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const getVotesLogic = createLogic({
  type: types.FETCH_FACILITATION_VOTES,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectFacilitationVotes(state.main[SLICE_NAME], action.payload.sessionID),
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_FACILITATION_URL}/${getState().auth.tenantID}/getvotes`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        result.sessionID = action.payload.sessionID;
        dispatch(actions.fetchedVotes(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const getSummaryLogic = createLogic({
  type: types.FETCH_FACILITATION_SUMMARY,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectFacilitationSummary(state.main[SLICE_NAME], action.payload.sessionID),
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_FACILITATION_URL}/${getState().auth.tenantID}/getsummary`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        result.sessionID = action.payload.sessionID;
        dispatch(actions.fetchedSummary(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const listSessionsLogic = createLogic({
  type: types.LIST_FACILITATION_SESSIONS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(selectors.selectTeamSessionList(state.main[SLICE_NAME], action.payload.teamID)) ||
      !!action.payload.startkey ||
      action.payload.force
    ) {
      allow(action);
    } else {
      reject();
    }
  },
  process: async ({ getState, action }, dispatch, done) => {
    const payload = { ...action.payload };
    delete payload.force;
    axios
      .get(`${API_FACILITATION_URL}/${getState().auth.tenantID}/listfacilitationsessions`, {
        params: payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        result.teamID = action.payload.teamID;
        result.startkey = action.payload.startkey;
        dispatch(actions.fetchedSessionList(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const submitInputLogic = createLogic({
  type: types.SUBMIT_INPUT,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_FACILITATION_URL}/${getState().auth.tenantID}/submitinput`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(res => {
        const { result } = res.data;
        result.sub = getState().auth.userID;
        result.sessionID = action.payload.sessionID;
        dispatch(actions.inputSubmitted(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const submitSummaryLogic = createLogic({
  type: types.SUBMIT_SUMMARY,
  process: async ({ getState, action }, dispatch, done) => {
    const state = getState();
    axios
      .post(`${API_FACILITATION_URL}/${state.auth.tenantID}/setsummary`, action.payload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })

      .then(res => {
        onFacilitationConcluded(res, state, action, dispatch);
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const submitVotesLogic = createLogic({
  type: types.SUBMIT_VOTES,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_FACILITATION_URL}/${getState().auth.tenantID}/submitvotes`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(res => {
        const { result } = res.data;
        result.sub = getState().auth.userID;
        result.sessionID = action.payload.sessionID;
        dispatch(actions.votesSubmitted(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const sendProgressStateLogic = createLogic({
  type: types.SEND_PROGRESS_STATE,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_FACILITATION_URL}/${getState().auth.tenantID}/statechange`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(res => {
        const { result } = res.data;
        result.sub = getState().auth.userID;
        result.sessionID = action.payload.sessionID;
        dispatch(actions.stateProgressed(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const submitHeartbeatLogic = createLogic({
  type: types.SEND_HEARTBEAT,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_FACILITATION_URL}/${getState().auth.tenantID}/heartbeat`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(res => {
        const { result } = res.data;
        result.sessionID = action.payload.sessionID;
        result.requestID = action.payload.requestID;
        dispatch(actions.receivedHeartbeat(result));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});

export const deleteSessionLogic = createLogic({
  type: types.DELETE_FACILITATION_SESSION,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_FACILITATION_URL}/${getState().auth.tenantID}/delete`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(() => {
        dispatch(actions.sessionDeleted(action.payload));
      })
      .catch(e => {
        helperErrorHandler(action.payload, e, dispatch);
      })
      .then(() => done());
  },
});
