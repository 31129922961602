import axios from 'axios';
import { refreshTokens } from 'state/ducks/auth/actions';

const instance = axios.create();

const setupAxios = store => {
  instance.interceptors.request.use(config => {
    const authToken = store.getState().auth?.tokens?.access_token;
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (!!error.response && error.response.status === 401) {
        // eslint-disable-next-line no-console
        console.log('Forcing token refresh');
        store.dispatch(refreshTokens({ force: true }));
      }
      return Promise.reject(error);
    },
  );
};

export { instance, setupAxios };
