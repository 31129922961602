import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormRadioGroup from 'Components/Library/Forms/Elements/FormRadioGroup';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import FormFreeText from './FormFreeText';

const answerOpts = [1, 2, 3, 4, 5];

class FormCsatReply extends Component {
  render() {
    const { t, values } = this.props;
    return (
      <div>
        <EmphasizedSection sx={{ mb: 3, mt: 3 }}>
          <FormRadioGroup
            fieldName="score"
            valueCastFunc={v => parseInt(v, 10)}
            render={fieldProps => (
              <RadioGroup {...fieldProps} row>
                {answerOpts.map(key => (
                  <Radio
                    sx={{
                      p: 0,
                      mr: 1,
                    }}
                    key={key}
                    checkedIcon={
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: 1,
                          borderRadius: '4px',
                          borderColor: 'secondary.main',
                          color: 'secondary.main',
                          backgroundColor: theme => `${theme.palette.secondary.main}14`,
                          overflow: 'hidden',
                        }}
                      >
                        {key}
                      </Box>
                    }
                    icon={
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: 1,
                          borderRadius: '4px',
                          borderColor: !!fieldProps.error ? 'error.main' : 'action.disabled',
                          backgroundColor: 'background.paper',
                        }}
                      >
                        {key}
                      </Box>
                    }
                    value={key}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </EmphasizedSection>
        <FormFreeText
          audienceType={values.audienceType}
          label={
            !!values.opentextfieldlabel
              ? values.opentextfieldlabel
              : t('insights.defaultopencommentlabel')
          }
        />
      </div>
    );
  }
}

FormCsatReply.propTypes = {
  values: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(FormCsatReply);
