import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import { withLocation, withNavigation } from 'withRouter';

import { peopleSelectors } from 'state/ducks/people';
import { openTopicReplyPanel } from 'config/ModalProvider/helpers';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: 8,
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[300],
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
});

class TopicNotification extends React.Component {
  handleClick = () => {
    openTopicReplyPanel(
      this.props.message.messageID,
      false,
      this.props.navigate,
      this.props.location,
    );
  };

  render() {
    const { classes, t, message, key } = this.props;
    if (!!message) {
      return (
        <div key={key} className={classes.root} onClick={this.handleClick}>
          <PersonasAvatar sub={message.from} className={classes.avatar} />
          <div>
            <Typography variant="subtitle2">
              {t('notifications.insights.text', {
                from: this.props.getName(message.from),
                text: message.text,
              })}
            </Typography>
            <Typography variant="caption">
              {t('general.ndaysago', {
                days: message.age,
              })}
            </Typography>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  inbox: state.main.inbox && state.main.inbox.inbox,
  getName: sub => peopleSelectors.selectFullName(state.main.people, sub),
});

TopicNotification.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    avatar: PropTypes.string,
  }),
  key: PropTypes.string,
  t: PropTypes.func,
  message: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  getName: PropTypes.func,
};

export default withLocation(
  withNavigation(
    withTranslation()(withStyles(styles)(connect(mapStateToProps)(TopicNotification))),
  ),
);
