import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TargetIcon from './flag.svg';

function DeltaSliderThumb(props) {
  const { ownerState, style, children } = props;
  const index = props['data-index'];

  const styles = {
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: 'action.hover',
    },
    '&:focus': {
      backgroundColor: 'action.selected',
    },
  };
  if (index === 1) {
    styles.backgroundImage = `url(${TargetIcon})`;
    styles.backgroundSize = '90%';
    styles.backgroundPosition = 'center';
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'background.box',
        boxShadow: theme => `inset 0px 0px 0px 1000px ${theme.palette.action.selected}`,
        overflow: 'hidden',
      }}
      className={ownerState.classes.thumb}
      style={style}
    >
      <Box sx={styles}>
        {children}
        {index === 0 && (
          <Box
            sx={{
              width: '33.33%',
              height: '33.33%',
              borderRadius: '50%',
              backgroundColor: 'text.secondary',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </Box>
    </Box>
  );
}
DeltaSliderThumb.propTypes = {
  style: PropTypes.object,
  ownerState: PropTypes.object,
  'data-index': PropTypes.number,
};

export default DeltaSliderThumb;
