import * as types from 'state/ducks/gameplans/types';
import { sendAnalyticsData } from 'config/analytics';
import { GP_WIDGET_TYPE_CUSTOM_CARD } from 'config/constants';

export const onGameplanCreated = (res, requestID, domainID, dispatch) => {
  const { result } = res.data;
  sendAnalyticsData('created gameplan');
  dispatch({
    type: types.CREATED_GAMEPLAN,
    payload: { requestID, domain_id: domainID, gameplan: result },
  });
};

export const onWidgetVisibilityChange = (res, requestID, dispatch) => {
  const { result } = res.data;
  result.requestID = requestID;
  sendAnalyticsData('changed widgets visibility');

  dispatch({ type: types.EDITED_GAMEPLAN_WIDGETS_VISIBILITY, payload: result });
};

export const onCardAdded = (requestID, gameplanID, domainID, res, dispatch) => {
  const result = {
    requestID,
    gameplan_id: gameplanID,
    domain_id: domainID,
    gameplan: res.data.result,
  };
  sendAnalyticsData('created content card');

  dispatch({ type: types.CREATED_BLANK_CARD, payload: result });
};

export const onWidgetEdited = (res, requestID, gameplanID, domainID, dispatch) => {
  const { result } = res.data;
  result.requestID = requestID;
  result.gameplan_id = gameplanID;
  result.domain_id = domainID;
  if (result.widget_data?.some(widget => widget.widget_type === GP_WIDGET_TYPE_CUSTOM_CARD)) {
    sendAnalyticsData('edited custom card');
  } else {
    sendAnalyticsData('edited widget card');
  }
  dispatch({ type: types.EDITED_GAMEPLAN_WIDGET, payload: result });
};
