import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { objectivesActions } from 'state/ducks/objectives';
import { getKeyResultConfidenceString, ragConfidenceToInt } from 'state/ducks/objectives/helpers';

import Form from 'Components/Library/Forms';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';

const schema = {
  type: 'object',
  properties: {
    confidence: { type: 'string' },
  },
  required: ['confidence'],
  additionalProperties: true,
};

const createReduxAction = params => {
  const { confidence, ...rest } = params;
  const actionCreator = objectivesActions.updateKeyresult;

  rest.confidence = ragConfidenceToInt(confidence).toString();
  return actionCreator(rest);
};

function KrConfidenceForm(props) {
  const { children, keyresultData } = props;

  const ref = useRef(null);

  return (
    <Form
      name="kr-confidence-update"
      schema={schema}
      initialValues={{
        confidence: getKeyResultConfidenceString(keyresultData),
      }}
      forwardRef={ref}
      debouncedAutoSubmit={950}
      submitActionCreator={createReduxAction}
      stateSlice="main.objectives"
      additionalProperties={{ keyresultID: keyresultData.keyresultID }}
      preventSaveIncomplete
    >
      <LinearIndicator positioning="absolute" />
      {children}
    </Form>
  );
}

KrConfidenceForm.propTypes = {
  keyresultData: PropTypes.object,
};

export default KrConfidenceForm;
