import React from 'react';
import PropTypes from 'prop-types';
import withTheme from '@mui/styles/withTheme';
import './styles.css';

class TgProgress extends React.Component {
  render() {
    const {
      className,
      theme,
      size = 44,
      color = 'primary',
      backgroundColor,
      center = false,
    } = this.props;
    const appliedColor = ['primary', 'secondary'].includes(color)
      ? theme.palette[color].main
      : color;

    const appliedBgColor = ['primary', 'secondary'].includes(backgroundColor)
      ? theme.palette[backgroundColor].main
      : backgroundColor;

    const bgOpacity = !!backgroundColor ? 1.0 : 0.0;

    return (
      <div
        className={className}
        style={center ? { margin: 'auto' } : {}}
        name="tg-progress"
        role="progressbar"
      >
        <svg width={size} height={size}>
          <defs>
            <clipPath id="progress-area">
              <rect
                x={size * 0.1}
                y={size * 0.1}
                width={size * 0.8}
                height={size * 0.8}
                rx={size / 11}
                ry={size / 11}
              />
            </clipPath>
          </defs>
          <rect
            x="2"
            y="2"
            width={size - 4}
            height={size - 4}
            rx={size / 11}
            ry={size / 11}
            fill={appliedBgColor}
            fillOpacity={bgOpacity}
          />
          <g clipPath="url(#progress-area)">
            <g className="bar1" clipPath="url(#progress-area)">
              <rect
                x={size / 4 - size / 14}
                y={size * 0.7}
                width={size / 7}
                height={size * 0.3}
                rx={size / 30}
                ry={size / 30}
                fill={appliedColor}
              />
            </g>
            <g className="bar2" clipPath="url(#progress-area)">
              <rect
                x={2 * (size / 4) - size / 14}
                y={size * 0.5}
                width={size / 7}
                height={size * 0.5}
                rx={size / 30}
                ry={size / 30}
                fill={appliedColor}
              />
            </g>
            <g className="bar3" clipPath="url(#progress-area)">
              <rect
                x={3 * (size / 4) - size / 14}
                y={size * 0.3}
                width={size / 7}
                height={size * 0.7}
                rx={size / 30}
                ry={size / 30}
                fill={appliedColor}
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

TgProgress.propTypes = {
  theme: PropTypes.object,
  size: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  center: PropTypes.bool,
};

export default withTheme(TgProgress);
