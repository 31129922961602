import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

function OutlinedIconButton({ size, color, children, ...rest }) {
  const styles = {
    p: 0,
    width: 24,
    height: 24,
    minWidth: 0,
    '& .MuiSvgIcon-root': { width: 20, height: 20 },
  };

  if (color === 'inherit') {
    styles['&:hover'] = {
      color: theme => theme.palette.text.primary,
      backgroundColor: theme => theme.palette.action.selected,
    };
    // styles.color = 'action.active';
  }

  return (
    <Button size={size} color={color} variant="outlined" sx={styles} {...rest}>
      {children}
    </Button>
  );
}

OutlinedIconButton.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

OutlinedIconButton.defaultProps = {
  color: 'inherit',
  size: 'small',
};
export default OutlinedIconButton;
