import Box from '@mui/material/Box';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataObjectChip from 'Components/Library/DataObjectChip';
import { COM_CONTRIBUTOR_STATUS_COLORS_THEME } from 'config/constants';
import { getStatusColor } from 'Components/Library/Contributors/helpers';

function ContributorStatusOptions({ select, classes }) {
  const { t } = useTranslation();
  const options = Object.keys(COM_CONTRIBUTOR_STATUS_COLORS_THEME).map(status => ({
    text: t(`interlocks.contributorStatusValueText.${status}`),
    option: status,
  }));
  const preparedOptions = [];

  options?.forEach(option => {
    const optionEl = (
      <DataObjectChip
        actionable
        sx={{ marginBottom: 1 }}
        icon={
          <Box
            className={classes.statusIcon}
            sx={{
              backgroundColor: theme => getStatusColor(theme, option.option),
            }}
          />
        }
        color="transparent"
        text={option.text}
        onClick={() => select(option.option)}
        key={`interlock-contributor-statechip-${option.option}`}
        name={`interlock-contributor-status-opt-${option.option}`}
      />
    );

    preparedOptions.push(optionEl);
  });
  return preparedOptions;
}

ContributorStatusOptions.propTypes = {
  select: PropTypes.func,
  classes: PropTypes.exact({
    statusIcon: PropTypes.string,
  }),
};

export default ContributorStatusOptions;
