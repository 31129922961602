import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { withLocation, withNavigation } from 'withRouter';

import KeyresultsIcon from 'Components/Features/Objectives/KeyresultsIcon';
import RootDndContextProvider from 'Components/Library/DragAndDrop/RootDndContext';
import CollapsibleDialogSection from 'Components/Library/CollapsibleDialogSection';
import KeyresultsList from '../KeyresultsList';

import { openAddKeyResultPanel } from 'config/ModalProvider/helpers';

class KeyresultsListContainer extends React.Component {
  openAddKrPanel = () => {
    const { objectiveID, navigate, location } = this.props;
    openAddKeyResultPanel(objectiveID, navigate, location);
  };

  render() {
    const { t, objectiveID, objectiveData, canEdit } = this.props;
    return (
      <RootDndContextProvider>
        <CollapsibleDialogSection
          iconNode={<KeyresultsIcon fontSize="small" sx={{ color: 'text.disabled' }} />}
          title={t('objectives.genericDataObjectNameKeyresults')}
        >
          <KeyresultsList
            objectiveData={objectiveData}
            uniqueContextIdentifier={`okr-dialog-${objectiveID}`}
            canEditObjective={canEdit}
            emptyStateNode={
              <Box
                sx={{
                  backgroundColor: 'action.hover',
                  p: 2,
                  borderRadius: 1,
                  m: 0,
                }}
              >
                <Typography variant="body2" color="text.disabled" sx={{ p: 1 }}>
                  {t('objectives.nokeyresultsinfotext')}
                </Typography>
              </Box>
            }
            keyresultChipProps={{
              wrapText: true,
              color: 'white',
              canEditObjective: canEdit,
              ContextIcon: null,
              raised: true,
              sx: { flexGrow: 10 },
            }}
            stackProps={{
              spacing: 1,
            }}
            enableAddButton
          />
        </CollapsibleDialogSection>
      </RootDndContextProvider>
    );
  }
}

KeyresultsListContainer.propTypes = {
  objectiveData: PropTypes.object,
  canEdit: PropTypes.bool,
  objectiveID: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func,
};

export default withLocation(withNavigation(withTranslation()(KeyresultsListContainer)));
