import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation, Trans } from 'react-i18next';
import { nanoid } from 'nanoid';
import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { peopleActions, peopleSelectors } from 'state/ducks/people';
import { inboxActions } from 'state/ducks/inbox';

import UserChip from 'Components/Library/UserChip';
import EmployeeSearch from 'Components/Common/EmployeeSearch';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';

const styles = theme => ({
  surface: {
    ...theme.shape,
    backgroundColor: theme.palette.primary[200],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  info: {
    backgroundColor: theme.palette.background.box,
    '& span': {
      color: theme.palette.primary[500],
    },
  },
  user: {
    backgroundColor: theme.palette.background.box,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  buttonRed: {
    color: theme.palette.confidence.red,
  },
  buttonGreen: {
    color: theme.palette.confidence.green,
  },
  buttonSmall: {
    fontSize: theme.typography.overline.fontSize,
    fontWeight: theme.typography.overline.fontWeight,
    letterSpacing: theme.typography.overline.letterSpacing,
    marginLeft: theme.spacing(),
    padding: '3px 10px',
  },
  paper: {
    ...theme.shape,
    boxShadow: theme.shadows[4],
    backgroundColor: '#FFFFFF',
    marginTop: 0,
  },
  addUserSearch: {
    maxWidth: 500,
  },
  attachButton: {
    marginLeft: theme.spacing(),
  },
  searchFieldInput: {
    border: '0px !important',
    ...theme.shape,
    backgroundColor: theme.palette.background.box,
    transition: 'all 0.125s ease',
    '& input': {
      ...theme.typography.subtitle2,
      fontWeight: 200,
      padding: `${theme.spacing()} !important`,
      height: 21,
    },
    '& fieldset': {
      /* border: '0px !important', */
    },
    '&:hover:not(:focus):not(.Mui-focused)': {
      backgroundColor: theme.palette.background.box,
    },
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

export class UnWrappedMembersTab extends Component {
  state = {
    requestID: '',
    submitStatus: 0,
    submitted: false,
    errorText: '',
    addingUserSub: null,
    confirmRemove: null,
  };

  componentDidMount() {
    // Dispatch fetches with force on mount to ensure the latest data
    // can always be fetched by just refreshing the page.
    const { dispatch, teamId } = this.props;
    dispatch(peopleActions.getTeam({ teamId, force: true }));
    dispatch(peopleActions.getTeamJoinRequests({ teamID: teamId, force: true }));
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (state.submitted === true) {
      if (state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[state.requestID].result === 'ok') {
          return {
            submitStatus: 1,
            submitted: false,
            errorText: '',
          };
        }
        return {
          submitStatus: -1,
          submitted: false,
          errorText: nextProps.actionlog[state.requestID].message,
        };
      }
    }
    return null;
  }

  onAddUserSelected = sub => {
    this.setState({ addingUserSub: sub });
  };

  submitApprove = sub => {
    const { dispatch, teamId } = this.props;
    const { submitted } = this.state;
    if (submitted) {
      return;
    }
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    dispatch(
      inboxActions.dismissNotification({
        messageId: `NEW-TEAM-JOIN-REQ_${teamId}_${sub}`,
        requestID: nanoid(10),
      }),
    );
    dispatch(peopleActions.approveJoinRequest({ teamID: teamId, sub, requestID }));
  };

  submitDeny = sub => {
    const { dispatch, teamId } = this.props;
    const { submitted } = this.state;
    if (submitted) {
      return;
    }
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    dispatch(
      inboxActions.dismissNotification({
        messageId: `NEW-TEAM-JOIN-REQ_${teamId}_${sub}`,
        requestID: nanoid(10),
      }),
    );
    dispatch(peopleActions.denyJoinRequest({ teamID: teamId, sub, requestID }));
  };

  requestConfirmRemove = sub => {
    this.setState({ confirmRemove: sub });
  };

  cancelRemove = () => {
    this.setState({ confirmRemove: null });
  };

  submitRemove = () => {
    const { dispatch, teamId, matrixId } = this.props;
    const { submitted, confirmRemove } = this.state;
    if (submitted) {
      return;
    }
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID, confirmRemove: null });
    dispatch(
      peopleActions.detachUserMatrixTeam({ teamId, sub: confirmRemove, matrixId, requestID }),
    );
  };

  submitInvite = () => {
    const { dispatch, teamId, matrixId } = this.props;
    const { submitted, addingUserSub } = this.state;
    if (submitted) {
      return;
    }
    if (get(this.props, 'teamJoinRequests.data', []).includes(addingUserSub)) {
      this.submitApprove(addingUserSub);
    } else {
      const requestID = nanoid(10);
      this.setState({ submitted: true, submitStatus: 0, requestID });
      dispatch(
        peopleActions.editUserMatrixTeam({ teamId, sub: addingUserSub, matrixId, requestID }),
      );
    }
  };

  filter = sub => !this.props.members.members.includes(sub);

  render() {
    const { t, classes, teamJoinRequests, members, isManager, selectFullName, teamIsInvitable } =
      this.props;
    const { submitStatus, submitted, confirmRemove, addingUserSub, errorText } = this.state;

    if (!teamIsInvitable || !isManager) {
      return null;
    }
    let sortedMembers = [];
    if (members.ok) {
      sortedMembers = orderBy(members.members, [sub => selectFullName(sub)], ['asc']);
    }

    return (
      <div>
        <SavingIndicator
          submitStatus={submitStatus}
          submitted={submitted}
          errorText={errorText}
          className={classes.savingIndicator}
        />
        <Box p={2} className={classes.surface} name="manage-members-tab-add-new-form">
          <Typography variant="subtitle1" paragraph>
            {t('teams.manageMembers.addMemberTitle')}
          </Typography>
          <Box
            /* Key changes when the amount of user changes, re-rendering and clearing the input */
            key={`manage-members-tab-add-user-input-${!members.ok ? 0 : members.members.length}`}
            display="flex"
            flexDirection="row"
          >
            <div
              className={classes.addUserSearch}
              name="manage-members-tab-add-user-input-container"
            >
              {!!members && !!members.ok && (
                <EmployeeSearch
                  containerClass={classes.paper}
                  onSelect={this.onAddUserSelected}
                  inputClass={classes.searchFieldInput}
                  inputVariant="outlined"
                  disabled={submitted}
                  filterFunc={this.filter}
                  id="manage-members-employeesearch-input-user"
                />
              )}
            </div>
            <Button
              disabled={submitted || !addingUserSub}
              color="secondary"
              variant="contained"
              name="manage-members-tab-attach-member-button"
              onClick={this.submitInvite}
              className={classes.attachButton}
            >
              {t('general.add')}
            </Button>
          </Box>
        </Box>

        <Box p={2} mt={3} className={classes.surface} name="manage-members-tab-requests-form">
          <Typography variant="subtitle1" paragraph>
            {t('teams.manageMembers.membersRequestingToJoinTitle')}
          </Typography>
          {!!teamJoinRequests.ok && teamJoinRequests.data.length === 0 && (
            <Box
              className={classes.info}
              p={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="caption">{t('teams.manageMembers.noMemberRequests')}</Typography>
            </Box>
          )}
          {!!teamJoinRequests.ok && teamJoinRequests.data.length > 0 && (
            <>
              {teamJoinRequests.data.map(sub => (
                <Box key={`manage-members-tab-requests-${sub}`} className={classes.user} p={1}>
                  <UserChip color="transparent" sub={sub} />
                  <Button
                    color="inherit"
                    variant="outlined"
                    onClick={() => this.submitApprove(sub)}
                    name="manage-members-tab-requests-approve-btn"
                    id={`manage-members-tab-requests-${sub}-approve-btn`}
                    className={clsx([classes.buttonSmall, classes.buttonGreen])}
                  >
                    {t('general.approve')}
                  </Button>
                  <Button
                    color="inherit"
                    variant="outlined"
                    onClick={() => this.submitDeny(sub)}
                    name="manage-members-tab-requests-deny-btn"
                    id={`manage-members-tab-requests-${sub}-deny-btn`}
                    className={clsx([classes.buttonSmall, classes.buttonRed])}
                  >
                    {t('general.decline')}
                  </Button>
                </Box>
              ))}
            </>
          )}
        </Box>

        <Box p={2} mt={3} className={classes.surface} name="manage-members-tab-remove-form">
          <Typography variant="subtitle1" paragraph>
            {t('teams.manageMembers.removeMembersTitle')}
          </Typography>
          {sortedMembers.length === 0 && (
            <Box
              className={classes.info}
              p={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="caption">{t('teams.manageMembers.noMembersYet')}</Typography>
            </Box>
          )}
          {sortedMembers.map(sub => (
            <Box key={`manage-members-tab-current-${sub}`} className={classes.user} p={1}>
              <UserChip color="transparent" sub={sub} />
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => this.requestConfirmRemove(sub)}
                name="manage-members-tab-current-members-remove-btn"
                id={`manage-members-tab-current-members-${sub}-remove-btn`}
                className={clsx([classes.buttonSmall, classes.buttonRed])}
              >
                {t('general.remove')}
              </Button>
            </Box>
          ))}
        </Box>
        <Dialog open={!!confirmRemove} onClose={this.cancelRemove}>
          <DialogContent>
            <Typography variant="h6">
              {t('teams.manageMembers.confirmRemoveDialogTitle')}
            </Typography>
            <br />
            <Typography variant="body1">
              <Trans
                i18nKey="teams.manageMembers.confirmRemoveDialogText"
                values={{
                  fullName: selectFullName(confirmRemove),
                }}
                components={[<b>placeholder</b>]}
              />
            </Typography>
            <br />
          </DialogContent>
          <DialogActions>
            <Button
              name="manage-members-cancel-remove-btn"
              color="primary"
              onClick={this.cancelRemove}
            >
              {t('general.cancel')}
            </Button>
            <Button
              name="manage-members-confirm-remove-btn"
              variant="contained"
              color="secondary"
              onClick={this.submitRemove}
            >
              {t('general.remove')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UnWrappedMembersTab.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    surface: PropTypes.string,
    info: PropTypes.string,
    user: PropTypes.string,
    buttonGreen: PropTypes.string,
    buttonRed: PropTypes.string,
    buttonSmall: PropTypes.string,
    savingIndicator: PropTypes.string,
    addUserSearch: PropTypes.string,
    paper: PropTypes.string,
    searchFieldInput: PropTypes.string,
    attachButton: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  teamId: PropTypes.string,
  members: PropTypes.object,
  teamJoinRequests: PropTypes.object,
  selectFullName: PropTypes.func,
  t: PropTypes.func,
  actionlog: PropTypes.object,
  teamIsInvitable: PropTypes.bool,
  isManager: PropTypes.bool,
  matrixId: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const managerSub = peopleSelectors.selectTeamManager(state.main.people, ownProps.teamId);
  return {
    managerSub,
    teamName: peopleSelectors.selectTeamName(state.main.people, ownProps.teamId),
    members: peopleSelectors.selectTeamMembers(state.main.people, ownProps.teamId),
    selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
    actionlog: state.main.people.actionlog,
    teamIsInvitable: peopleSelectors.canRequestToJoin(state.main.people, ownProps.teamId),
    matrixId: peopleSelectors.selectMatrixOfTeam(state.main.people, ownProps.teamId),
    isManager:
      peopleSelectors.selectTeamManager(state.main.people, ownProps.teamId) === state.auth.userID,
    teamJoinRequests: peopleSelectors.selectTeamJoinRequests(state.main.people, ownProps.teamId),
  };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(UnWrappedMembersTab)));
