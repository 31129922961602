import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import WillNotIcon from '@mui/icons-material/DoNotDisturb';
import TitleIcon from '@mui/icons-material/Title';

import { useTranslation } from 'react-i18next';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import Form from 'Components/Library/Forms/Form';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormContext from 'Components/Library/Forms/FormContext';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import { gameplansActions } from 'state/ducks/gameplans';
import DraggableElement from './Lib/DraggableElement';
import DragContainer from './Lib/DragContainer';
import WidgetFooter from './Lib/WidgetFooter';
import { addWidgetElement } from './Lib/api';

export const ITEMTYPE_WILLNOTDO = 'NOTDOITEM';
export const ITEMTYPE_TITLE = 'NOTDOTITLE';

const schema = {
  type: 'object',
  properties: {
    elements: {
      type: 'array',
      items: {
        anyOf: [
          {
            type: 'object',
            properties: {
              element_type: { const: ITEMTYPE_WILLNOTDO },
              title: { type: 'string' },
            },
          },
          {
            type: 'object',
            properties: {
              element_type: { const: ITEMTYPE_TITLE },
              title: { type: 'string' },
            },
          },
        ],
      },
    },
  },
};

const elementStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  alignItems: 'center',
  p: 0.5,
  pl: 1,
};

const addItemAt = async (widgetId, formContext, index, itemType) => {
  const elements = [...formContext.values.elements];
  const item = await addWidgetElement(widgetId, index, itemType);
  elements.splice(index + 1, 0, item);
  formContext.onFieldChange('elements', elements);
};

const removeItemAt = (formContext, index) => {
  const elements = [...formContext.values.elements];
  elements.splice(index, 1);
  formContext.onFieldChange('elements', elements);
};

function WillNotDoWidget({
  canEdit,
  variant,
  teamId,
  gameplanId,
  data,
  dragHandleProps,
  requestRemove,
  name,
}) {
  const [addMenuState, setAddMenuState] = React.useState({});
  const { t } = useTranslation();

  const isInteractive = variant === 'card' && canEdit;

  return (
    <>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '24px',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Typography variant="subtitle2">{t('gameplans.willNotDoWidget.title')}</Typography>
          {isInteractive && (
            <Stack direction="row" spacing={1} className="gp-widget-actions">
              <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                <DeleteIcon />
              </ContainedIconButton>
            </Stack>
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          mb: 1.5,
          ml: variant === 'card' ? 2 : 0,
          mr: variant === 'card' ? 2 : 0,
          pb: 1,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
        className="widget-content"
      >
        <Form
          name="gameplan-willnotdo"
          schema={schema}
          preventSaveIncomplete
          stateSlice="main.gameplans"
          submitActionCreator={gameplansActions.editGamePlanWidget}
          debouncedAutoSubmit={950}
          additionalProperties={{ domain_id: teamId, widget_id: data.id, gameplan_id: gameplanId }}
          initialValues={{
            elements: data.elements || [],
          }}
        >
          <FormContext.Consumer>
            {formContext => (
              <>
                <DragContainer
                  fieldName="elements"
                  type="GAMEPLAN_WIDGET_WILLNOTDO"
                  widgetId={data.id}
                  elements={formContext.values.elements}
                  onFieldChange={formContext.onFieldChange}
                  disabled={!isInteractive}
                >
                  <div>
                    {(!get(formContext, 'values.elements') ||
                      get(formContext, 'values.elements', []).length === 0) && (
                      <ButtonBase
                        sx={{
                          width: '100%',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          pt: 1,
                          pl: 2,
                          pr: 2,
                        }}
                        onClick={event => {
                          event.stopPropagation();
                          setAddMenuState({
                            anchorEl: event.currentTarget,
                            index: 0,
                          });
                        }}
                      >
                        <Box
                          sx={{
                            height: '28px',
                            boxSizing: 'border-box',
                            display: 'inline-flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <WillNotIcon
                            sx={{ marginRight: '11px' }}
                            fontSize="small"
                            color="action"
                          />
                          <Typography
                            variant="body2"
                            color="action.disabled"
                            sx={{ height: '17px', mb: '3px' }}
                          >
                            {t('gameplans.willNotDoWidget.emptyStateCta')}
                          </Typography>
                        </Box>
                      </ButtonBase>
                    )}
                    {formContext.values?.elements?.map((contentItem, index) => (
                      <DraggableElement
                        key={`willnotdo-widget-element-${contentItem.id}`}
                        index={index}
                        draggableId={contentItem.id}
                        onAddClick={event =>
                          setAddMenuState({ anchorEl: event.currentTarget, index })
                        }
                        onDelete={() => removeItemAt(formContext, index)}
                        isActive={addMenuState.index === index}
                        disabled={!isInteractive}
                      >
                        {contentItem.element_type === ITEMTYPE_WILLNOTDO && (
                          <Box sx={elementStyle}>
                            <WillNotIcon
                              sx={{ mr: 1 }}
                              fontSize={variant === 'card' ? 'small' : 'medium'}
                              color="action"
                            />
                            <Box sx={{ flexGrow: 10 }}>
                              <Box sx={{ paddingLeft: '3px', pr: 1, paddingTop: '2px' }}>
                                <FormTextField
                                  fieldName={`elements.${index}.title`}
                                  render={fieldProps => (
                                    <InlineEditInput
                                      {...fieldProps}
                                      variant={variant === 'card' ? 'body2' : 'bodylarge'}
                                      disabled={!isInteractive}
                                      fullWidth
                                      multiline
                                      label={t(
                                        'gameplans.willNotDoWidget.willNotDoElementTitleLabel',
                                      )}
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                        {contentItem.element_type === ITEMTYPE_TITLE && (
                          <Box sx={{ flexGrow: 10 }}>
                            <Box
                              sx={{
                                pl: 1,
                                pr: 1,
                                pt: 0.5,
                                pb: 0.5,
                                mt: 0.5,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <FormTextField
                                fieldName={`elements.${index}.title`}
                                render={fieldProps => (
                                  <InlineEditInput
                                    {...fieldProps}
                                    variant={variant === 'card' ? 'h6' : 'h4'}
                                    disabled={!isInteractive}
                                    fullWidth
                                    multiline
                                    label={t('gameplans.willNotDoWidget.titleElementLabel')}
                                  />
                                )}
                              />
                            </Box>
                          </Box>
                        )}
                      </DraggableElement>
                    ))}
                  </div>
                </DragContainer>

                <Menu
                  id="gameplan-add-element-menu"
                  anchorEl={addMenuState.anchorEl}
                  open={Boolean(addMenuState.anchorEl)}
                  onClose={e => {
                    e.stopPropagation();
                    setAddMenuState({});
                  }}
                  variant="menu"
                  keepMounted
                  onClick={e => e.stopPropagation()}
                >
                  <MenuItem
                    onClick={() => {
                      addItemAt(data.id, formContext, addMenuState.index, ITEMTYPE_TITLE);
                      setAddMenuState({});
                    }}
                  >
                    <ListItemIcon name="willnotdo-title">
                      <TitleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('gameplans.willNotDoWidget.titleElementName')}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      addItemAt(data.id, formContext, addMenuState.index, ITEMTYPE_WILLNOTDO);
                      setAddMenuState({});
                    }}
                  >
                    <ListItemIcon name="willnotdo-body">
                      <WillNotIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t('gameplans.willNotDoWidget.willNotDoElementName')}
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </FormContext.Consumer>
        </Form>
      </Box>
      {variant === 'card' && <WidgetFooter data={data} dragHandleProps={dragHandleProps} />}
    </>
  );
}

WillNotDoWidget.propTypes = {
  teamId: PropTypes.string,
  gameplanId: PropTypes.string,
  data: PropTypes.object,
  dragHandleProps: PropTypes.object,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  name: PropTypes.string,
};

WillNotDoWidget.defaultProps = {
  variant: 'card',
};

export default WillNotDoWidget;
