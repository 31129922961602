import React, { useEffect } from 'react';
import LabelIcon from '@mui/icons-material/Label';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tagsActions, tagsSelectors } from '../../../state/ducks/tags';

import LinkItem from './LinkItem';

function TagsMenuItem({ isChangeManager, tags }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shouldShowTags = tags?.data?.nodes?.length > 0 || isChangeManager;

  useEffect(() => {
    dispatch(tagsActions.getAllTags());
  }, []);

  if (!shouldShowTags) {
    return null;
  }

  return (
    <LinkItem
      to="tags"
      linkIcon={<LabelIcon fontSize="small" />}
      linkText={t('tags.pageTitle')}
      variant="large"
    />
  );
}

const mapStateToProps = state => ({
  isChangeManager: state.auth.isChangeManager,
  tags: tagsSelectors.selectAllTags(state.main.tags),
});

TagsMenuItem.propTypes = {
  isChangeManager: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.any,
};

export default connect(mapStateToProps)(TagsMenuItem);
