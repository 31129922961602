import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import UserChip from 'Components/Library/UserChip';
import { getLastModifiedStringFromTimestamp } from 'config/helpers';

const styles = theme => ({
  ownerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(),
    },
  },
});

class StyledPanelReplyTopicHeader extends Component {
  render() {
    const { t, classes, topicText, owner, description, preview = false, timestamp } = this.props;

    // do not display loading when it is not actually loading in preview
    let displayMessage = t('general.loading');
    if (preview) {
      displayMessage = '';
    }
    if (!!topicText) {
      displayMessage = topicText;
    }

    return (
      <React.Fragment>
        <Typography name="panel-reply-topic-question" variant="h4" sx={{ mb: 3 }}>
          {displayMessage}
        </Typography>
        <Typography
          name="panel-reply-topic-description"
          variant="subtitle1"
          color="text.secondary"
          paragraph
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          {description}
        </Typography>
        <div className={classes.ownerRow}>
          <UserChip sub={owner} color="grey" />
          <Typography variant="caption">{getLastModifiedStringFromTimestamp(timestamp)}</Typography>
        </div>
        <div />
      </React.Fragment>
    );
  }
}

StyledPanelReplyTopicHeader.propTypes = {
  classes: PropTypes.exact({
    ownerRow: PropTypes.string,
  }),
  topicText: PropTypes.string,
  owner: PropTypes.string,
  description: PropTypes.string,
  timestamp: PropTypes.string,
  t: PropTypes.func,
  preview: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(StyledPanelReplyTopicHeader));
