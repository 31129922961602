import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { NUMBER_REASONABLE_MAX, NUMBER_REASONABLE_MIN } from 'config/constants';
import FormContext from '../FormContext';

const FormNumberField = React.memo(props => {
  const { render, fieldName, value, formName, onFieldChange, error, minValue, maxValue } = props;

  // Store the focus outside of the state to avoid causing unneccessary re-renders
  const hasFocus = React.useRef(false);
  const [stateValue, setStateValue] = React.useState(null);

  const onChange = e => {
    /* Ensure whatever is returned is a number */
    if ((!!!e.target.value || Number.isNaN(e.target.value)) && hasFocus.current) {
      // if we have focus and the value is empty, skip dispatching
      // instead we'll wait until the user blurs the input or types
      // something
      setStateValue(e.target.value);
    } else {
      const v = Number(e.target.value);
      if (v >= minValue && v <= maxValue) {
        onFieldChange(fieldName, v);
        setStateValue(null);
      } else if (v < minValue) {
        onFieldChange(fieldName, minValue);
        setStateValue(null);
      } else if (v > maxValue) {
        onFieldChange(fieldName, maxValue);
        setStateValue(null);
      }
    }
  };

  const onFocus = () => {
    hasFocus.current = true;
  };

  const onBlur = () => {
    // The field is now unfocused, if we had a value in state, dispatch it now to the form
    hasFocus.current = false;
    if (stateValue !== null) {
      onFieldChange(fieldName, Number(stateValue));
      setStateValue(null);
    }
  };

  const fieldProps = {
    onChange,
    // Use the value from state if it's not null or undefined (=> user just cleared the input),
    // otherwise use the value from props
    value: stateValue ?? value,
    name: `${formName}-${fieldName}`,
    error,
    type: 'number',
    inputProps: { min: minValue, max: maxValue },
    onFocus,
    onBlur,
  };
  return render(fieldProps);
});

function OuterFormNumberField(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormNumberField that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render, fieldName } = props;

  const { onFieldChange, values, formName, fieldErrors, showErrors, schema } =
    React.useContext(FormContext);

  const minValue = get(schema, `properties.${fieldName}.minimum`, NUMBER_REASONABLE_MIN);
  const maxValue = get(schema, `properties.${fieldName}.maximum`, NUMBER_REASONABLE_MAX);

  return (
    <FormNumberField
      fieldName={fieldName}
      render={render}
      onFieldChange={onFieldChange}
      value={get(values, fieldName)}
      formName={formName}
      minValue={minValue}
      maxValue={maxValue}
      error={!!showErrors && !!fieldErrors && fieldName in fieldErrors}
      helperText={
        !!showErrors && !!fieldErrors && fieldName in fieldErrors ? fieldErrors[fieldName] : ''
      }
    />
  );
}

OuterFormNumberField.propTypes = {
  render: PropTypes.func,
  fieldName: PropTypes.string,
};

export default OuterFormNumberField;
