import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmphasizedSection from 'Components/Library/EmphasizedSection/index';
import React from 'react';
import PropTypes from 'prop-types';

function HnRContent({ metricID, metricNodes }) {
  return (
    <EmphasizedSection
      sx={{ maxWidth: '200px', maxHeight: '500px', overflow: 'auto' }}
      key={metricID}
    >
      <Typography align="center" variant="subtitle1" color="text.disabled">
        {metricID}
      </Typography>
      <Box>
        {metricNodes.map((nodeData: any) => (
          <p key={nodeData?.graph_id}>{JSON.stringify(nodeData?.data)}</p>
        ))}
      </Box>
    </EmphasizedSection>
  );
}

HnRContent.propTypes = {
  metricID: PropTypes.string.isRequired,
  metricNodes: PropTypes.array,
};

export default HnRContent;
