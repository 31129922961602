import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { initDictionaryOverride } from 'config/i18n';
import * as actions from './actions';
import * as types from './types';

import { APIGW_URL } from '../../constants/api';

import * as selectors from './selectors';

const SLICE_NAME = 'appstatus';

// eslint-disable-next-line import/prefer-default-export
export const getAppStatusLogic = createLogic({
  type: types.GET_APP_STATUS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectStatus(state.main[SLICE_NAME]),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/mystrategy/${getState().auth.tenantID}/hello`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedAppStatus(res.data.result));
        if (res.data.result?.dictionary_overrides) {
          initDictionaryOverride(res.data.result.dictionary_overrides);
        }
      })
      .catch(e => {
        dispatch(actions.failedAppStatus({ request: action.payload, response: e }));
      })
      .then(() => done());
  },
});
