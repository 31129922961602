import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import MuiFab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';

const styles = () => ({
  root: {
    zIndex: 10,
  },
});

class Fab extends PureComponent {
  render() {
    const {
      classes,
      className,
      onClick,
      children,
      color = 'secondary',
      name,
      id,
      theme,
      ...rest
    } = this.props;

    return (
      <Zoom in>
        <MuiFab
          name={name}
          id={id}
          theme={theme}
          className={clsx([classes.root, className])}
          color={color}
          onClick={onClick}
          {...rest}
        >
          {children}
        </MuiFab>
      </Zoom>
    );
  }
}

Fab.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  color: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.object,
  theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Fab);
