import reducer from './reducers';

import * as notificationconfigLogic from './logic';
import * as notificationconfigTypes from './types';
import * as notificationconfigActions from './actions';
import * as notificationconfigSelectors from './selectors';

export {
  notificationconfigLogic,
  notificationconfigTypes,
  notificationconfigActions,
  notificationconfigSelectors,
};

export default reducer;
