// @ts-nocheck
/* eslint-disable */
import { ComponentType, FC, useEffect } from 'react';
import { cx, isEmptyArray } from 'remirror';
import { useCommands } from '@remirror/react';
/* @ts-ignore */
import {
  TgMentionAtomNodeAttributes,
  TgMentionAtomState,
  useTgMentionAtom,
  UseTgMentionAtomProps,
  UseTgMentionAtomReturn,
} from './useTgMentionAtom.ts';
import UserSearchResultWrapper from './UserSearchResultWrapper';
//import { ExtensionTgMentionAtomTheme as Theme } from '@remirror/theme';

import { FloatingWrapper } from '@remirror/react';

interface TgMentionAtomPopupComponentProps<
  Data extends TgMentionAtomNodeAttributes = TgMentionAtomNodeAttributes,
> extends UseTgMentionAtomProps<Data> {
  /**
   * Called whenever the query state changes.
   */
  onChange: (mentionAtomState: TgMentionAtomState<Data> | null) => void;

  /**
   * The component to be used for rendering each item.
   */
  ItemComponent?: ComponentType<TgMentionAtomPopupItemComponentProps<Data>>;

  /**
   * The message that is displayed when there are no items to display.
   */
  ZeroItemsComponent?: ComponentType<object>;

  /**
   * CSS classes
   */
  classes: Object
}

interface UseTgMentionAtomChangeHandlerProps<
  Data extends TgMentionAtomNodeAttributes = TgMentionAtomNodeAttributes,
> {
  state: UseTgMentionAtomReturn<Data>['state'];
  onChange: TgMentionAtomPopupComponentProps<Data>['onChange'];
}

function useTgMentionAtomChangeHandler<
  Data extends TgMentionAtomNodeAttributes = TgMentionAtomNodeAttributes,
>(props: UseTgMentionAtomChangeHandlerProps<Data>) {
  const { onChange, state } = props;

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);
}

/**
 * This component renders the mention suggestion dropdown for the user.
 */
export function TgMentionAtomPopupComponent<
  Data extends TgMentionAtomNodeAttributes = TgMentionAtomNodeAttributes,
>(props: TgMentionAtomPopupComponentProps<Data>): JSX.Element {
  const { focus } = useCommands();
  const {
    onChange,
    ItemComponent = DefaultItemComponent,
    ZeroItemsComponent = DefaultZeroItemsComponent,
    classes,
    ...hookProps
  } = props;
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } =
    useTgMentionAtom(hookProps);
  useTgMentionAtomChangeHandler({ state, onChange });
  return (
    <FloatingWrapper positioner='cursor' enabled={!!state} placement='bottom-start' renderOutsideEditor>
      <div {...getMenuProps()} className={cx(classes.popupWrapper)}>
        {!!state && isEmptyArray(hookProps.items) ? (
          <ZeroItemsComponent className={classes.mentionPopupItemComponent} />
        ) : (
          hookProps.items.map((item, index) => {
            const isHighlighted = indexIsSelected(index);
            const isHovered = indexIsHovered(index);

            return (
              <div
                key={item.id}
                className={cx(
                  classes.mentionPopupItem,
                )}
                {...getItemProps({
                  onClick: () => {
                    state?.command(item);
                    focus();
                  },
                  item,
                  index,
                })}
              >
                <UserSearchResultWrapper item={item} state={state} className={classes.mentionPopupItemComponent} isHovered={isHovered} isFocused={isHighlighted}/>
              </div>
            );
          })
        )}
      </div>
    </FloatingWrapper>
  );
}

interface TgMentionAtomPopupItemComponentProps<
  Data extends TgMentionAtomNodeAttributes = TgMentionAtomNodeAttributes,
> {
  item: Data;
  state: UseTgMentionAtomReturn<Data>['state'];
}

function DefaultItemComponent<Data extends TgMentionAtomNodeAttributes = TgMentionAtomNodeAttributes>(
  props: TgMentionAtomPopupItemComponentProps<Data>,
) {
  return <span className={'MENTION_ATOM_POPUP_NAME'/*Theme.MENTION_ATOM_POPUP_NAME*/}>{props.item.name}</span>;
}

const DefaultZeroItemsComponent: FC = props => {
  return <span className={'MENTION_ATOM_ZERO_ITEMS'/*Theme.MENTION_ATOM_ZERO_ITEMS*/}>...</span>;
};