import * as types from './types';

export const getTeamFilters = payload => ({
  type: types.GET_TEAM_FILTERS,
  payload,
});

export const getTeamsFiltersFailed = payload => ({
  type: types.FAILED_TEAM_FILTERS,
  payload,
});

export const fetchedTeamFilters = payload => ({
  type: types.FETCHED_TEAM_FILTERS,
  payload,
});

export const createTeamFilter = payload => ({
  type: types.CREATE_TEAM_FILTER,
  payload,
});

export const teamFilterCreated = payload => ({
  type: types.CREATED_TEAM_FILTER,
  payload,
});

export const apiError = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});

export const deleteTeamFilter = payload => ({
  type: types.DELETE_TEAM_FILTER,
  payload,
});

export const teamFilterDeleted = payload => ({
  type: types.TEAM_FILTER_DELETED,
  payload,
});

export const editTeamFilter = payload => ({
  type: types.EDIT_TEAM_FILTER,
  payload,
});

export const teamFilterEdited = payload => ({
  type: types.TEAM_FILTER_EDITED,
  payload,
});
