import { enrich } from '../../helpers';

export function selectContext(slice, contextID, contextType) {
  if (!!contextID && !!contextType && !!slice.context[contextType][contextID]) {
    return slice.context[contextType][contextID];
  }
  return enrich();
}

export function selectContextCard(slice, contextID, contextType, cardID) {
  const context = selectContext(slice, contextID, contextType);
  let card = null;
  if (!!context.ok) {
    context.data.forEach(column => {
      for (const colCard of column) {
        if (colCard.id === cardID) {
          card = colCard;
        }
      }
    });
  }
  return card;
}
