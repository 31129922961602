import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { get } from 'lodash';
import { TgMentionAtomPopupComponent } from './TgMentionAtomPopupComponent';
import useSearch from 'hooks/useSearch';

const styles = theme => ({
  popupWrapper: {
    ...theme.shape,
    backgroundColor: 'white',
    boxShadow: theme.shadows[4],
    padding: theme.spacing(),
  },
});

function TgMentionComponent(props) {
  const { classes } = props;
  const [query, setQuery] = useState('');
  const [results, isLoading] = useSearch(query, ['people']);

  const peopleResults = results.filter(o => o.title === 'people');
  const peopleSuggestions =
    peopleResults.length > 0 ? get(peopleResults[0], 'suggestions', []) : [];

  const items = peopleSuggestions.map(sug => ({
    sub: sug.id,
    name: sug.text,
    id: sug.id,
    label: sug.text,
  }));

  return (
    <TgMentionAtomPopupComponent
      onChange={evt => {
        const searchStr = get(evt, 'query.full', '');
        if (!!searchStr && searchStr !== query) {
          setQuery(searchStr);
        }
      }}
      items={items.splice(0, 5)}
      classes={classes}
    />
  );
}

TgMentionComponent.propTypes = {
  classes: PropTypes.exact({
    popupWrapper: PropTypes.string,
  }),
};

export default withStyles(styles)(TgMentionComponent);
