import * as types from './types';

export const registerPushEndpoint = payload => ({
  type: types.REGISTER_PUSH_ENDPOINT,
  payload,
});

export const registeredPushEndpoint = payload => ({
  type: types.REGISTERED_PUSH_ENDPOINT,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.REGISTER_API_ERROR_RECEIVED,
  payload,
});
