import { orderBy, get } from 'lodash';
import moment from 'moment';
import { getKeyResultCompletionPct, round } from 'config/helpers';
import { getConfidenceString, lerp, linearRegression, dateToSeconds } from './helpers/common';

export { getConfidenceString, lerp };

function mergeEventsPerDate(events) {
  const eventsPerDate = {};

  // avoid zigzag by only using one value per day - use the latest

  for (const evt of events) {
    const dateStr = evt.timestamp.substring(0, 10);
    if (!eventsPerDate[dateStr]) {
      eventsPerDate[dateStr] = [];
    }
    eventsPerDate[dateStr].push({ ...evt, timestamp: evt.timestamp.substring(0, 19) });
  }
  const filteredEvents = [];
  for (const date in eventsPerDate) {
    const sortedEventsOnDate = orderBy(eventsPerDate[date], ['timestamp'], ['desc']);
    filteredEvents.push({ ...sortedEventsOnDate[0], timestamp: date });
  }
  return filteredEvents;
}

export function calculateObjectiveProgressChartValues(objective) {
  const krRngs = [];
  let allEvents = [];
  const krEvents = {};
  const krWeights = {};
  for (const kr of objective.keyresults) {
    krRngs.push(kr.keyresultID);
    const filteredEvents = kr.value_history || [];
    for (const event of filteredEvents) {
      event.krRng = kr.keyresultID;
    }
    // filteredEvents = filteredEvents.map((evt) => ({ ...evt, krRng: kr.keyresultID }));
    // add the filtered events to the array of all object events
    allEvents = [...allEvents, ...filteredEvents];
    // sort the kr events in ascending order
    krEvents[kr.keyresultID] = orderBy(filteredEvents, ['timestamp'], ['asc']);
    krWeights[kr.keyresultID] = kr.weight;
  }
  allEvents = orderBy(allEvents, ['timestamp'], ['asc']);

  function getKrValuesAtTime(rng, timestamp) {
    let lastValue = 0;
    let lastConfidence = 100;
    let lastWeight = 0;

    let i = 0;
    while (i < krEvents[rng].length) {
      const krEvent = krEvents[rng][i];
      if (krEvent.timestamp <= timestamp) {
        lastValue = parseFloat(krEvent.status);
        lastConfidence = parseFloat(krEvent.confidence);
        lastWeight = parseFloat(krWeights[rng]); /* keeps weight as 0 unless we get an event */
        i += 1;
      } else {
        break;
      }
    }
    return [lastValue, lastWeight, lastConfidence];
  }

  let data = [];

  for (const event of allEvents) {
    // we'll go through all the events in order
    // at each event (timestamp), we'll check the value of the other KRs at the time
    // and compute the average
    const curValue = event.status;
    const curConfidence = parseFloat(event.confidence);
    const curWeight = parseFloat(krWeights[event.krRng]);
    let totalValue = curValue * curWeight;
    let totalWeight = curWeight;

    let lowestConfidence = curConfidence;
    for (const krRng of krRngs) {
      if (krRng !== event.krRng) {
        const vals = getKrValuesAtTime(krRng, event.timestamp);
        totalValue += vals[0] * vals[1];
        totalWeight += vals[1];
        if (vals[2] < lowestConfidence) {
          lowestConfidence = vals[2];
        }
      }
    }
    data.push({
      timestamp: event.timestamp,
      y: totalValue / totalWeight || 0,
      confidence: lowestConfidence,
      confidenceStr: getConfidenceString(lowestConfidence),
    });
  }

  const retVal = {
    historical: [],
    extrapolation: [],
    today: null,
    statusDelta: 0,
    confidenceDelta: 0,
    stage: objective.stage,
  };

  const today = new Date().toISOString().slice(0, 10);
  if (data.length > 0) {
    let todayEvt = {
      timestamp: today,
      y: data[data.length - 1].y,
      confidence: data[data.length - 1].confidence,
    };
    data.push(todayEvt);
    data = mergeEventsPerDate(data);
    todayEvt = data[data.length - 1];
    retVal.historical = data;
    const regr = linearRegression(data);
    let first;
    let last;
    if (data[0].timestamp < objective.periodStart) {
      // we have datapoints from before the period started
      const old = data.filter(dp => dp.timestamp < objective.periodStart);
      const lastOld = old[old.length - 1];
      if (data.length > old.length) {
        // We also have data points that happen during the selected period
        first = {
          timestamp: objective.periodStart,
          y: lerp(lastOld, data[old.length], objective.periodStart),
          confidence: data[old.length].confidence,
          confidenceStr: data[old.length].confidenceStr,
        };
      } else {
        first = lastOld;
      }
    } else {
      first = {
        timestamp: objective.periodStart,
        y: regr.intercept + regr.slope * dateToSeconds(objective.periodStart),
      };
    }
    if (data[data.length - 1].timestamp > objective.periodEnd) {
      const future = data.filter(dp => dp.timestamp > objective.periodEnd);
      // we have datapoints from after the period ended
      // and there also exist datapoints thta are not in the future
      const firstFuture = future[0];
      const lastVisible = data[data.length - future.length - 1] || firstFuture;
      last = {
        timestamp: objective.periodEnd,
        y: lerp(lastVisible, firstFuture, objective.periodEnd),
        confidence: lastVisible.confidence,
        confidenceStr: lastVisible.confidenceStr,
      };
    } else {
      last = {
        timestamp: objective.periodEnd,
        y: regr.intercept + regr.slope * dateToSeconds(objective.periodEnd),
      };
    }

    retVal.extrapolation = [first, last];
    retVal.today = todayEvt;
    for (let i = data.length - 2; i >= 0; i--) {
      const evt = data[i];
      if (retVal.confidenceDelta === 0 && evt.confidence !== todayEvt.confidence) {
        retVal.confidenceDelta = Math.round(todayEvt.confidence - evt.confidence);
      }
      if (retVal.statusDelta === 0 && evt.y !== todayEvt.y) {
        retVal.statusDelta = round(todayEvt.y - evt.y);
      }
    }
  } else {
    retVal.today = { timestamp: today, y: 0, confidence: 100 };
    retVal.extrapolation = [
      { timestamp: objective.periodStart, y: 0 },
      { timestamp: objective.periodEnd, y: 0 },
    ];
  }
  return retVal;
}

// memoize results
const memoizeObjectiveProgressChartValues = () => {
  const cache = {};
  return (objective, forceReload) => {
    if (objective.objectiveID in cache && !forceReload) {
      return cache[objective.objectiveID];
    }
    const result = calculateObjectiveProgressChartValues(objective);
    cache[objective.objectiveID] = result;
    return result;
  };
};

export const getObjectiveProgressChartValues = memoizeObjectiveProgressChartValues();

export const getObjectiveWeeklyProgress = objective => {
  const today = moment();
  const lastWeek = moment(today).subtract(7, 'days');
  const todayStr = today.toISOString().substring(0, 10);
  const lastWeekStr = lastWeek.toISOString().substring(0, 10);
  let lastStatus = 0;
  let hasBeenUpdatedLast7Days = false;
  if (objective?.eventdata?.historical && objective?.eventdata?.today?.y) {
    for (const event of objective.eventdata.historical) {
      if (event.timestamp < lastWeekStr) {
        lastStatus = event.y;
      } else if (event.timestamp >= lastWeekStr) {
        hasBeenUpdatedLast7Days = true;
      }
      if (event.timestamp >= todayStr) {
        break;
      }
    }
    if (objective.eventdata.today.y !== lastStatus) {
      hasBeenUpdatedLast7Days = true;
    }
    return !hasBeenUpdatedLast7Days ? 0 : round(objective.eventdata.today.y - lastStatus);
  }
  return 0;
};

export const getKeyresultLastDelta = keyresult => {
  if (!keyresult.events || keyresult.events.length === 0) {
    return 0;
  }
  // TODO: fix this, find the last event where the status actually changed
  const lastStatus = getKeyResultCompletionPct(keyresult.events[keyresult.events.length - 2]);
  return Math.round((getKeyResultCompletionPct(keyresult) - lastStatus) * 10) / 10;
};

export const sortKeyresults = keyresults =>
  orderBy(keyresults, ['position', 'weight', 'keyresult'], ['asc', 'desc', 'asc']);

export const getKeyresultWeeklyProgress = keyresult => {
  const today = moment();
  const lastWeek = moment(today).subtract(7, 'days');
  const todayStr = today.toISOString().substring(0, 10);
  const lastWeekStr = lastWeek.toISOString().substring(0, 10);
  let lastStatus = 0;
  let hasBeenUpdatedLast7Days = false;
  for (const event of orderBy(keyresult.events, ['timestamp'], ['asc'])) {
    if (event.timestamp.substring(0, 10) < lastWeekStr) {
      lastStatus = getKeyResultCompletionPct(event);
    } else if (
      event.timestamp.substring(0, 10) >= lastWeekStr &&
      event.timestamp.substring(0, 10) <= todayStr
    ) {
      hasBeenUpdatedLast7Days = true;
    }
    if (event.timestamp >= todayStr) {
      break;
    }
  }
  if (!hasBeenUpdatedLast7Days) {
    return 0;
  }
  return round(getKeyResultCompletionPct(keyresult) - lastStatus);
};

export const getKeyResultConfidenceString = keyresult => getConfidenceString(keyresult.confidence);

export const ragConfidenceToInt = confidenceStr => {
  if (confidenceStr === 'green') {
    return 100;
  }
  if (confidenceStr === 'amber') {
    return 60;
  }
  return 1;
};

export const getEffectiveConfidence = statusMap => {
  if (statusMap.red > 0) {
    return 'red';
  }
  if (statusMap.amber > 0) {
    return 'amber';
  }
  return 'green';
};

export const getObjectiveConfidenceString = (objective, reprVersion = 1) => {
  const krStatusMap = {
    red: 0,
    amber: 0,
    green: 0,
  };
  if (reprVersion === 1) {
    for (const keyresult of get(objective, 'keyresults', [])) {
      const statusString = getKeyResultConfidenceString(keyresult);
      krStatusMap[statusString] += 1;
    }
  } else {
    const krDict = get(objective, 'keyresults', {});
    Object.keys(krDict).forEach(krId => {
      const statusString = getKeyResultConfidenceString(krDict[krId]);
      krStatusMap[statusString] += 1;
    });
  }
  return getEffectiveConfidence(krStatusMap);
};

export const isObjectiveOwned = (domain, objectiveData) => {
  if (!domain || !objectiveData) {
    return false;
  }
  if (domain.t === 'team' && objectiveData.type === 'team' && objectiveData.teamID === domain.d) {
    return true;
  }
  if (domain.t === 'personal' && objectiveData.owner === domain.d) {
    return true;
  }
  if (domain.t === 'company' && objectiveData.type === 'company') {
    return true;
  }
  return false;
};

export const isKrOwned = (domain, objectiveData, keyresultData) => {
  if (domain.t === 'team' && objectiveData.type === 'team' && objectiveData.teamID === domain.d) {
    return true;
  }
  if (domain.t === 'personal' && keyresultData.owner === domain.d) {
    /* return true if the user owns the actual key result, regardless of what domain the objective belongs to */
    return true;
  }
  if (domain.t === 'company' && objectiveData.type === 'company') {
    return true;
  }
  return false;
};

export const idRegex = /OBJ_[A-Za-z0-9]{7}-KR_[A-Za-z0-9]{7}/;

export const parseObjectiveIDfromKeyresultID = keyresultID => {
  const isGraphId = idRegex.test(keyresultID);
  if (isGraphId) {
    return keyresultID.split('-')[0];
  }
  const parts = keyresultID.split('_');
  const objectiveID = parts.slice(1, parts.length - 1).join('_');
  return objectiveID;
};

export const domainToGraphId = domain => {
  // convert domain ID to graph ID format
  let domainId = 'company';
  if (domain.t === 'team') {
    domainId = `TEAM_${domain.d}`;
  }
  if (domain.t === 'personal') {
    domainId = `USR_${domain.d}`;
  }

  return domainId;
};
