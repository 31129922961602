import React from 'react';
import PropTypes from 'prop-types';

import StandardInput from 'Components/Library/StandardInput';
import FormNumberField from 'Components/Library/Forms/Elements/FormNumberField';
import KrStatusForm from './KrStatusForm';

function KrStatusWidget(props) {
  const { keyresultData, canEdit } = props;

  return (
    <KrStatusForm keyresultData={keyresultData}>
      <FormNumberField
        fieldName="status"
        render={fieldProps => (
          <StandardInput
            id="dialog-update-kr-status-field"
            variant="outlined"
            autoFocus
            {...fieldProps}
            disabled={!canEdit}
          />
        )}
      />
    </KrStatusForm>
  );
}

KrStatusWidget.propTypes = {
  canEdit: PropTypes.bool,
  keyresultData: PropTypes.object,
  t: PropTypes.func,
};

export default KrStatusWidget;
