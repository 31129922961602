import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import { withNavigation } from 'withRouter';

import { peopleSelectors } from 'state/ducks/people';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: 8,
    borderRadius: '10px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[300],
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
});

class UnderstandingNotification extends React.Component {
  handleClick = () => {
    const msg = this.props.message;
    this.props.navigate(
      `/home/programs/${msg.cpID}/interpretation/${msg.csiID}#modal/csi/viewteam/${msg.csiID}/${msg.teamID}`,
    );
  };

  render() {
    const { classes, t, message, key } = this.props;
    if (!!message) {
      return (
        <div key={key} className={classes.root} onClick={this.handleClick}>
          <PersonasAvatar sub={message.from} className={classes.avatar} />
          <div>
            <Typography variant="subtitle2">
              {t('notifications.understanding.text', {
                from: this.props.getName(message.from),
                team_name: this.props.selectTeamName(message.teamID),
                text: message.text,
              })}
            </Typography>
            <Typography variant="caption">
              {t('general.ndaysago', { days: message.age })}
            </Typography>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  inbox: state.main.inbox && state.main.inbox.inbox,
  getName: sub => peopleSelectors.selectFullName(state.main.people, sub),
  selectTeamName: teamID => peopleSelectors.selectTeamName(state.main.people, teamID),
});

UnderstandingNotification.propTypes = {
  message: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    avatar: PropTypes.string,
  }),
  key: PropTypes.string,
  t: PropTypes.func,
  getName: PropTypes.func,
  navigate: PropTypes.func,
  selectTeamName: PropTypes.func,
};

export default withTranslation()(
  withNavigation(withStyles(styles)(connect(mapStateToProps)(UnderstandingNotification))),
);
