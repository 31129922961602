import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withNavigation } from 'withRouter';

import { getNodeModalPath } from 'Components/Library/GraphElementResolver/helpers';
import { peopleSelectors, peopleActions } from 'state/ducks/people';
import useSearch from 'hooks/useSearch';
import { useNavigate } from 'react-router';
import GlobalSearch from './GlobalSearch';
import { generateTeamNavLink, getRecentTeams } from './utils';

function GlobalSearchOverlay(props) {
  const { onSelect, name, id, label, renderButton, searchDialogOpened, searchFor } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');
  const [results, isLoading] = useSearch(searchString, searchFor);

  const recentTeams = useSelector(state => peopleSelectors.selectRecentTeams(state.main.people));
  const teamNames = useSelector(state => peopleSelectors.selectTeamNames(state.main.people));

  const onSelectSuggestion = suggestion => {
    if (onSelect) {
      onSelect(suggestion);
    }
    if (suggestion?.type === 'team') {
      dispatch(peopleActions.addRecentTeam({ teamId: suggestion.id }));
      navigate(generateTeamNavLink(window.location, suggestion.id), { replace: true });
    } else if (suggestion?.type === 'USER') {
      dispatch(peopleActions.addRecentUser({ sub: suggestion.id }));
      navigate(getNodeModalPath(`USER_${suggestion.id}`));
    } else if (suggestion?.node_id) {
      navigate(getNodeModalPath(suggestion?.node_id));
    }
  };

  const recent = [
    {
      title: 'recent',
      suggestions: getRecentTeams(searchFor, recentTeams, teamNames),
    },
  ];

  return (
    <GlobalSearch
      suggestions={results.length > 0 ? results : recent}
      onSelect={onSelectSuggestion}
      name={name}
      onFetchRequested={setSearchString}
      id={id}
      label={label}
      renderButton={renderButton}
      fetching={isLoading}
      searchDialogOpened={searchDialogOpened}
    />
  );
}

GlobalSearchOverlay.propTypes = {
  // leaving .shape here instead of .exact as the number of properties
  // in auth is enormous
  auth: PropTypes.shape({
    cognitoSubDomain: PropTypes.string,
    idpName: PropTypes.string,
    loginType: PropTypes.string,
    clientId: PropTypes.string,
    tenantID: PropTypes.string,
    tokens: PropTypes.object,
    VERSION: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  renderButton: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  searchFor: PropTypes.array,
  searchDialogOpened: PropTypes.bool,
  onSelect: PropTypes.func,
};

GlobalSearchOverlay.defaultProps = {
  searchFor: ['teams', 'teamsledby', 'people', 'nodes'],
};

const mapStateToProps = state => ({
  people: state.main.people,
  recentTeams: peopleSelectors.selectRecentTeams(state.main.people),
  teamNames: peopleSelectors.selectTeamNames(state.main.people),
  managedTeams: peopleSelectors.selectManagedTeams(
    state.main.people,
    state.auth.myTeams,
    state.auth.userID,
  ),
  selectTeamsManagedBy: userId => peopleSelectors.selectTeamsManagedBy(state.main.people, userId),
  selectFullName: sub => peopleSelectors.selectFullName(state.main.people, sub),
  auth: state.auth,
});

export default withNavigation(connect(mapStateToProps)(GlobalSearchOverlay));
