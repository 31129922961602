import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import { Radio, RadioGroup } from '@mui/material';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormRadioGroup from 'Components/Library/Forms/Elements/FormRadioGroup';
import FormContext from 'Components/Library/Forms/FormContext';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import DateTimePicker from 'Components/Library/BaseComponents/DateTimePicker/index';
import DateHint from 'Components/Features/Topics/PanelCreateTopic/DateHint';
import moment from 'moment';
import QuadEditor from './QuadEditor';
import MultipleChoiceEditor from './MultipleChoiceEditor';
import SliderEditor from './SliderEditor';
import Panel from 'Components/Library/BaseComponents/Panel';
import Container from 'Components/Library/BaseComponents/Container';
import StandardInput from 'Components/Library/StandardInput';

const styles = theme => ({
  button: {
    marginRight: 8,
  },
  alignRight: {
    textAlign: 'right',
    width: '100%',
  },
  actionsContainer: {
    marginTop: 8,
    marginBottom: 8,
  },
  resetContainer: {
    marginTop: 0,
    padding: theme.spacing(3),
  },
  textField: {
    marginLeft: 0,
    marginRight: 8,
    marginBottom: 16,
  },
  formControl: {
    marginLeft: 0,
    marginRight: 8,
  },
  radioOpt: {
    height: 32,
    borderRadius: 24,
    backgroundColor: theme.palette.action.selected,
    width: 'auto !Important',
    paddingRight: 16,
    paddingLeft: 16,
    border: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '6px',
    marginBottom: '6px',
    '&:last-of-type': {
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.focus,
    },
  },
  checked: {
    backgroundColor: theme.palette.secondary.main,
    '& p': {
      color: theme.palette.secondary.contrastText,
    },
  },
  ttlField: {
    width: 'auto',
    minWidth: '60%',
  },
  ttlLabel: {
    position: 'relative',
    top: '16px',
  },
});

const createLabel = (typeOps, key, classes) => (
  <Typography variant="body2" className={classes.radioLabel}>
    {typeOps[key].name}
  </Typography>
);

class NewTopicDialog extends React.Component {
  constructor(props) {
    super(props);

    this.TYPE_OPTS = {
      opentext: { name: this.props.t('insights.typeOptOpenText') },
      nps: { name: props.t('insights.typeOptNps') },
      csat: { name: props.t('insights.typeOptCsat') },
      multiplechoice: { name: this.props.t('insights.typeOptMultipleChoice') },
      quad: { name: this.props.t('insights.typeOptQuad') },
      sliders: { name: this.props.t('insights.typeOptSliders') },
    };
  }

  render() {
    const { t, classes } = this.props;

    return (
      <FormContext.Consumer>
        {formContext => (
          <Container alignItems="stretch" spacing={3} m={0}>
            {/* topic question */}
            <Panel alignItems="stretch" spacing={2}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('insights.createtopicsubtitle')}
              </Typography>
              <FormTextField
                fieldName="topicText"
                render={fieldProps => (
                  <StandardInput
                    label={t('insights.questionInputLabel')}
                    placeholder={t('insights.questionInputPlaceholder')}
                    multiline
                    fullWidth
                    margin="normal"
                    {...fieldProps}
                  />
                )}
              />
              <FormTextField
                fieldName="description"
                render={fieldProps => (
                  <StandardInput
                    label={t('insights.descriptionInputLabel')}
                    placeholder={t('insights.descriptionInputPlaceholder')}
                    multiline
                    fullWidth
                    margin="normal"
                    {...fieldProps}
                  />
                )}
              />

              <br />
              {/* topic type */}
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
                {t('insights.chooseTypeSubtitle')}
              </Typography>
              <FormRadioGroup
                fieldName="topicType"
                render={fieldProps => (
                  <RadioGroup {...fieldProps} row>
                    {Object.keys(this.TYPE_OPTS).map(key => (
                      <Radio
                        key={key}
                        value={key}
                        className={clsx(
                          classes.radioOpt,
                          formContext.values.topicType.toLowerCase() === key.toLowerCase() &&
                            classes.checked,
                        )}
                        checkedIcon={<div>{createLabel(this.TYPE_OPTS, key, classes)}</div>}
                        icon={<div>{createLabel(this.TYPE_OPTS, key, classes)}</div>}
                        checked={formContext.values.topicType.toLowerCase() === key.toLowerCase()}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Panel>
            {
              // If type was multi-choice, display the answer option editor
              formContext.values.topicType === 'multiplechoice' && (
                <Panel alignItems="stretch" spacing={2}>
                  <MultipleChoiceEditor
                    className={classes.formControl}
                    onChange={this.handleOptionsChange}
                  />
                </Panel>
              )
            }
            {
              // If type was sliders, display the answer option editor
              formContext.values.topicType === 'sliders' && (
                <Panel alignItems="stretch" spacing={2}>
                  <SliderEditor
                    className={classes.formControl}
                    onChange={this.handleSliderChange}
                  />
                </Panel>
              )
            }
            {formContext.values.topicType === 'quad' && (
              <Panel alignItems="stretch" spacing={2}>
                <QuadEditor className={classes.formControl} />
              </Panel>
            )}
            <Panel alignItems="stretch" spacing={2}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('insights.topicSettingsSubtitle')}
              </Typography>

              <FormSelectorField
                fieldName="end_datetime"
                render={fieldProps => (
                  <DateTimePicker
                    label={t('insights.dueDate')}
                    format="DD.MM.YYYY HH:mm"
                    // note that here we need to use the endOf('day') to make more certain that
                    // the ts is still in the future as expected by the backend in UTC
                    onAccept={selectedMoment => fieldProps.onSelect(selectedMoment.toISOString())}
                    closeOnSelect={false}
                    disablePast
                    slotProps={{
                      textField: { variant: 'outlined' },
                      actionBar: { actions: ['cancel', 'accept'] },
                    }}
                    {...fieldProps}
                    value={moment(fieldProps.value)}
                  />
                )}
              />
              <br />
              <DateHint value={formContext.values?.end_datetime || new Date().toISOString()} />
            </Panel>
            {formContext.values.topicType !== 'opentext' && (
              <Panel alignItems="stretch" spacing={2}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {t('insights.customFreeTextCommentQuestionInputLabel')}
                </Typography>
                <FormTextField
                  fieldName="opentextfieldlabel"
                  render={fieldProps => (
                    <StandardInput
                      aria-label={t('insights.customFreeTextCommentQuestionInputLabel')}
                      multiline
                      fullWidth
                      variant="outlined"
                      sx={{ backgroundColor: 'background.paper', borderRadius: 1 }}
                      {...fieldProps}
                    />
                  )}
                />
              </Panel>
            )}
          </Container>
        )}
      </FormContext.Consumer>
    );
  }
}

NewTopicDialog.propTypes = {
  classes: PropTypes.exact({
    actionsContainer: PropTypes.string,
    alignRight: PropTypes.string,
    button: PropTypes.string,
    checked: PropTypes.string,
    formControl: PropTypes.string,
    helperText: PropTypes.string,
    radioOpt: PropTypes.string,
    resetContainer: PropTypes.string,
    selectedRadioOpt: PropTypes.string,
    textField: PropTypes.string,
    ttlField: PropTypes.string,
    ttlLabel: PropTypes.string,
  }),
  onChange: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = state => ({
  programs: state.main.programs,
});

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(NewTopicDialog)));
