import { PURGE } from 'redux-persist';
import { validatePersistedState } from 'state/helpers';
import { TENANT_FETCH_FAILURE, TENANT_FETCH_SUCCESS } from './types';

export const initialState = {
  VERSION: 1.02,
  tenant: null,
};

// eslint-disable-next-line default-param-last
export default (state = JSON.parse(JSON.stringify(initialState)), action) => {
  state = validatePersistedState(state, initialState);
  switch (action.type) {
    case TENANT_FETCH_SUCCESS:
      return {
        ...state,
        tenant: {
          tenantId: action.payload.tenantID,
          idpName: action.payload.idpName,
          cognitoSubDomain: action.payload.cognitoSubDomain,
          loginType: action.payload.loginType,
          clientId: action.payload.cognitoClientId,
        },
      };
    case TENANT_FETCH_FAILURE:
      return {
        ...state,
        tenant: { error: action.payload },
      };
    case 'LOGOUT':
    case PURGE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return state;
  }
};
