import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { peopleSelectors } from 'state/ducks/people/index';
import ContributorStatusOptions from 'Components/Library/Contributors/ContributorStatusOptions';
import { getStatusColor } from 'Components/Library/Contributors/helpers';
import { NODE_TYPE_TEAM } from 'config/constants';
import DataObjectChip from 'Components/Library/DataObjectChip';

const styles = () => ({
  statusIcon: {
    height: 14,
    width: 14,
    borderRadius: '4px',
    marginLeft: '5px',
  },
});

function ContributorStatus(props) {
  const { onSelect, value, classes, contributorType, contributorID } = props;
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const teamLead = useSelector(state =>
    peopleSelectors.selectTeamManager(state.main.people, contributorID),
  );

  const currentUserID = useSelector(state => state.auth.userID);
  let disabled = contributorID !== currentUserID;

  if (contributorType === NODE_TYPE_TEAM) {
    disabled = teamLead !== currentUserID;
  }

  const select = opt => {
    onSelect(opt);
    setAnchorEl(null);
  };

  return (
    <>
      <DataObjectChip
        actionable={!disabled}
        title={t(`interlocks.contributorStatusValueText.${value}`)}
        sx={{
          width: 32,
          height: 32,
          mr: 1,
          flexShrink: 0,
          // needed to override "small" variant styles
          paddingRight: '0 !important',
        }}
        icon={
          <Box
            className={classes.statusIcon}
            sx={{
              backgroundColor: theme => getStatusColor(theme, value),
            }}
          />
        }
        color="white"
        variant="small"
        text=""
        onClick={disabled ? undefined : e => setAnchorEl(e.currentTarget)}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        name="contributor-status-popover"
      >
        <Box m={1}>
          <ContributorStatusOptions select={select} classes={classes} />
        </Box>
      </Popover>
    </>
  );
}

ContributorStatus.propTypes = {
  classes: PropTypes.exact({
    statusIcon: PropTypes.string,
  }),
  value: PropTypes.oneOf(['NO-STATUS', 'SOME-HICKUPS', 'HELP-NEEDED', 'ALL-GOOD']).isRequired,
  onSelect: PropTypes.func.isRequired,
  contributorID: PropTypes.string.isRequired,
  contributorType: PropTypes.string.isRequired,
};

export default withStyles(styles)(ContributorStatus);
