import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function DetailsSkeleton() {
  return (
    <Box>
      <Skeleton height={32} width="30%" variant="rounded" sx={{ mb: 2 }} />
      <Skeleton height={240} variant="rounded" sx={{ mb: 2 }} />
    </Box>
  );
}

export default DetailsSkeleton;
