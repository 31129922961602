import * as types from './types';

export const getBindings = payload => ({
  type: types.GET_BINDINGS,
  payload,
});

export const bindingFetchSuccess = payload => ({
  type: types.BINDING_FETCH_SUCCESS,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API_BINDINGS,
  payload,
});
