import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import FantasticBg from './fantastic.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 5,
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 5,
    maxWidth: '60%',
    paddingTop: theme.spacing(15),
  },
  fantastic: {
    backgroundImage: `url(${FantasticBg})`,
    backgroundSize: '100%',
    width: 174,
    height: 174,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
});

class DivInputSubmitted extends Component {
  render() {
    const { classes, t, bodyStringName } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.fantastic} />
          <Typography variant="h3" gutterBottom align="center" id="input-submitted-header">
            {t('dfac.sessionWaitingOthersTitle')}
          </Typography>

          <Typography
            variant="h6"
            align="center"
            id="input-submitted-paragraph"
            color="text.secondary"
          >
            {t(`dfac.${bodyStringName}`)}
          </Typography>
        </div>
      </div>
    );
  }
}

DivInputSubmitted.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    content: PropTypes.string,
    fantastic: PropTypes.string,
  }),
  t: PropTypes.func,
  bodyStringName: PropTypes.string,
};

export default withTranslation()(withStyles(styles)(DivInputSubmitted));
