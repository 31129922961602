import {
  GP_WIDGET_TYPE_OKRS,
  GP_WIDGET_TYPE_COMMITMENTS,
  GP_WIDGET_TYPE_VISION_AND_PURPOSE,
  GP_WIDGET_TYPE_VALUES_AND_DRIVERS,
  GP_WIDGET_TYPE_WILL_NOT_DO,
  GP_WIDGET_TYPE_CUSTOM_CARD,
  GP_WIDGET_TYPE_INTERLOCKS,
  GP_WIDGET_TYPE_METHODS,
} from 'config/constants';
import ObjectivesWidget from '../ObjectivesWidget';
import VisionWidget from '../VisionWidget';
import ValuesWidget from '../ValuesWidget';
import WillNotDoWidget from '../WillNotDoWidget';
import CustomWidget from '../CustomWidget';
import CommitmentsWidget from '../CommitmentsWidget';
import InterlocksWidget from '../InterlocksWidget';
import MethodsWidget from '../MethodsWidget';

// eslint-disable-next-line import/prefer-default-export
export const WIDGET_TYPE_MAPPING = {
  [GP_WIDGET_TYPE_OKRS]: ObjectivesWidget,
  [GP_WIDGET_TYPE_COMMITMENTS]: CommitmentsWidget,
  [GP_WIDGET_TYPE_VISION_AND_PURPOSE]: VisionWidget,
  [GP_WIDGET_TYPE_VALUES_AND_DRIVERS]: ValuesWidget,
  [GP_WIDGET_TYPE_WILL_NOT_DO]: WillNotDoWidget,
  [GP_WIDGET_TYPE_CUSTOM_CARD]: CustomWidget,
  [GP_WIDGET_TYPE_INTERLOCKS]: InterlocksWidget,
  [GP_WIDGET_TYPE_METHODS]: MethodsWidget,
};
