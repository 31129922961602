import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import TgLogo from 'Components/Library/TgLogo';
import persistor from 'persistor';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: 0,
    backgroundColor: theme.palette.background.app,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loginBox: {
    margin: 'auto',
    width: '400px',
  },
});

class Debug extends Component {
  componentDidMount() {
    persistor.flush();
    persistor.pause();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.loginBox}>
            <Card elevation={24}>
              <Box sx={{ p: 4, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <TgLogo />
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 4,
                    mb: 6,
                    pb: 0,
                    alignSelf: 'stretch',
                  }}
                />
              </Box>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

Debug.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    wrapper: PropTypes.string,
    loginBox: PropTypes.string,
  }),
};

export default withStyles(styles)(Debug);
