import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import DataObjectChip from 'Components/Library/DataObjectChip';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import StandardSurveyDialog from 'Components/Library/StandardSurveyDialog';
import FormContext from 'Components/Library/Forms/FormContext';
import InsightsIcon from 'Components/Features/Topics/InsightsIcon';
import TeamWidget from 'Components/Library/TeamWidget';
import TeamChip from 'Components/Library/TeamChip';
import MenuCustomTopic from './MenuCustomTopic';

import SurveyPreviewScreen from './SurveyPreviewScreen';

import TopicForm from '../TopicForm';

class PanelCreateTopic extends Component {
  state = {
    step: 0,
  };

  nextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  prevStep = () => {
    this.setState({ step: Math.max(this.state.step - 1, 0) });
  };

  render() {
    const {
      audienceType,
      audienceID,
      audienceName,
      onClose,
      numPeople,
      sub,
      t,
      teamList,
      zIndexModifier,
    } = this.props;

    const { step } = this.state;

    let sideBarTitleString;
    if (audienceType === 'TEAM') {
      sideBarTitleString = t('insights.createTopicDialog.teamInsightsMemberListTitle', {
        teamName: audienceName,
      });
    } else {
      sideBarTitleString = t('insights.createTopicDialog.programInsightsMemberListTitle', {
        numPeople,
      });
    }

    return (
      <TopicForm audienceType={audienceType} audienceID={audienceID} onClose={onClose}>
        <FormContext.Consumer>
          {formContext => (
            <StandardSurveyDialog
              open
              name="create-topic"
              onBackdropClick={onClose}
              onCloseClick={onClose}
              size="small"
              zIndexModifier={zIndexModifier}
              title={
                <DataObjectChip
                  Icon={InsightsIcon}
                  text={t('insights.featureName')}
                  variant="normal"
                />
              }
              sidebarContent={
                <Box>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    display="block"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {sideBarTitleString}
                  </Typography>

                  {audienceType === 'TEAM' && <TeamWidget teamId={audienceID} />}
                  {audienceType === 'PRG' &&
                    teamList.map(teamId => (
                      <Box sx={{ mb: 2 }} key={teamId}>
                        <TeamChip teamId={teamId} />
                      </Box>
                    ))}
                </Box>
              }
              actionbarContent={
                <DialogActions sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }}>
                  {step === 0 && (
                    <>
                      <Button onClick={this.props.onClose} name="cancel-survey">
                        {t('general.cancel')}
                      </Button>
                      <Button
                        onClick={() => {
                          // ENG-1421: change flow so that the preview can only be accessible if the
                          // validation succeeds
                          if (!formContext.isValid) {
                            formContext.enableShowErrors();
                          } else {
                            this.nextStep();
                          }
                        }}
                        color="secondary"
                        variant="contained"
                        name="preview-survey"
                      >
                        {t('insights.previewcta')}
                      </Button>
                    </>
                  )}
                  {step === 1 && (
                    <>
                      <Button onClick={this.prevStep} name="back-survey">
                        {t('general.back')}
                      </Button>
                      <FormButton
                        render={buttonProps => (
                          <Button
                            variant="contained"
                            color="secondary"
                            name="send-survey"
                            {...buttonProps}
                          >
                            {t('general.send')}
                          </Button>
                        )}
                      />
                    </>
                  )}
                </DialogActions>
              }
            >
              <LinearIndicator positioning="absolute" />
              <Collapse in={step === 0} timeout={50}>
                <MenuCustomTopic />
              </Collapse>

              <Collapse in={step === 1} timeout={50}>
                <SurveyPreviewScreen
                  audienceType={audienceType}
                  sub={sub}
                  ttl={formContext.ttl}
                  values={formContext.values}
                />
              </Collapse>
            </StandardSurveyDialog>
          )}
        </FormContext.Consumer>
      </TopicForm>
    );
  }
}

PanelCreateTopic.propTypes = {
  audienceName: PropTypes.string,
  audienceType: PropTypes.string,
  audienceID: PropTypes.string,
  classes: PropTypes.exact({
    content: PropTypes.string,
    overline: PropTypes.string,
    previewContainer: PropTypes.string,
    bold: PropTypes.string,
  }),
  topic: PropTypes.object,
  numPeople: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.func,
  sub: PropTypes.string,
  teamList: PropTypes.array,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(PanelCreateTopic);
