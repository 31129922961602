import { sendAnalyticsData } from 'config/analytics';
import * as actions from 'state/ducks/commitments/actions';

// eslint-disable-next-line import/prefer-default-export
export const onCommitmentCreated = (res, requestID, dispatch) => {
  const { result } = res.data;
  result.requestID = requestID;
  sendAnalyticsData('created commitment');
  dispatch(actions.addedCommitment(result));
};

export const onCommitmentUpdated = (res, requestID, isOwner, dispatch) => {
  const { result } = res.data;
  result.requestID = requestID;
  if (isOwner) {
    sendAnalyticsData('updated commitment by owner');
  } else {
    sendAnalyticsData('updated commitment by participant');
  }
  if (result.status === 'COMPLETED') {
    sendAnalyticsData('completed commitment');
  }
  dispatch(actions.updatedCommitment(result));
};
