import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withLocation, withNavigation } from 'withRouter';

import { askForPermissioToReceiveNotifications } from 'firebase-cfg';
import { inboxActions } from 'state/ducks/inbox';
import { closeModal } from 'config/ModalProvider/helpers';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  caption: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
});

class PushOptInNotification extends React.Component {
  handleClick = () => {
    askForPermissioToReceiveNotifications(this.props.dispatch);
    this.props.dispatch(inboxActions.dismissNotification({ messageId: 'REQUEST_PUSH_OPT_IN' }));
    closeModal(this.props.navigate, this.props.location);
  };

  render() {
    const { classes, message, key, t } = this.props;
    if (!!message) {
      return (
        <div key={key} className={classes.root}>
          <div>
            <Typography variant="subtitle2">{t('notifications.push.title')}</Typography>
            <Typography variant="caption" className={classes.caption}>
              {t('notifications.push.text')}
            </Typography>
            <Button
              id="notifications-turn-on-button"
              variant="contained"
              onClick={this.handleClick}
              color="secondary"
            >
              {t('notifications.push.cta')}
            </Button>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

PushOptInNotification.propTypes = {
  message: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    caption: PropTypes.string,
  }),
  key: PropTypes.string,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  navigate: PropTypes.func,
  t: PropTypes.func,
};

export default withLocation(
  withNavigation(withTranslation()(withStyles(styles)(connect()(PushOptInNotification)))),
);
