import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { commitmentsSelectors, commitmentsActions } from 'state/ducks/commitments';
import { ERROR } from 'state/constants/api';
import { peopleSelectors } from 'state/ducks/people';
import DialogViewCommitment from './DialogViewCommitment';

const fetchData = props => {
  const { commitmentData, commitmentID, dispatch } = props;
  if (commitmentID && !commitmentData?.ok) {
    dispatch(commitmentsActions.fetchCommitment({ id: commitmentID }));
  }
};

class DialogViewCommitmentContainer extends React.Component {
  componentDidMount() {
    fetchData(this.props);
  }

  componentDidUpdate() {
    fetchData(this.props);
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      commitmentID,
      commitmentData,
      open,
      canEdit,
      canDelete,
      zIndexModifier = 0,
    } = this.props;

    return (
      <DialogViewCommitment
        commitmentData={commitmentData}
        commitmentID={commitmentID}
        open={open}
        onClose={this.onClose}
        zIndexModifier={zIndexModifier}
        notFound={commitmentData.fetchStatus === ERROR}
        canEdit={canEdit}
        canDelete={canDelete}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const commitmentData = commitmentsSelectors.selectCommitment(
    state.main.commitments,
    ownProps.commitmentID,
  );
  const managedTeamList = peopleSelectors.selectTeamsManagedBy(
    state.main.people,
    state.auth.userID,
  );

  const canDelete = commitmentsSelectors.canDeleteCommitment({
    slice: state.main.commitments,
    commitmentID: ownProps.commitmentID,
    userId: state.auth.userID,
    isChangeManager: state.auth.isChangeManager,
  });
  const canEdit = commitmentsSelectors.canEditCommitment({
    slice: state.main.commitments,
    commitmentID: ownProps.commitmentID,
    userId: state.auth.userID,
    managedTeamIds: managedTeamList,
    isChangeManager: state.auth.isChangeManager,
  });

  return {
    commitmentData,
    canDelete,
    canEdit,
  };
};

DialogViewCommitmentContainer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  // used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  commitmentID: PropTypes.string,
  commitmentData: PropTypes.object,
  zIndexModifier: PropTypes.number,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default connect(mapStateToProps)(DialogViewCommitmentContainer);
