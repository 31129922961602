import reducer from './reducers';

import * as bindingsLogic from './logic';
import * as bindingsTypes from './types';
import * as bindingsActions from './actions';
import * as bindingsSelectors from './selectors';

export { bindingsLogic, bindingsTypes, bindingsActions, bindingsSelectors };

export default reducer;
