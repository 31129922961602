import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { withLocation } from 'withRouter';
import { peopleSelectors } from 'state/ducks/people';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import LinkItem from './LinkItem';
import ExpandButton from './ExpandButton';

const generateNavLink = (location, teamID) => {
  if (location.pathname.includes('/home/teams/')) {
    // replace the teamID
    const pathParts = location.pathname.split('/');
    pathParts[pathParts.indexOf('teams') + 1] = teamID;
    return pathParts.join('/');
  }
  return `/home/teams/${teamID}`;
};

class TeamsLedSelector extends Component {
  state = {
    expanded: true,
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { t, classes, location, selectTeamName, managedTeams } = this.props;

    const { expanded } = this.state;

    const teams = [];
    managedTeams.forEach(teamId => {
      teams.push({
        to: generateNavLink(location, teamId),
        linkIcon: <PersonasAvatar size="xtiny" sub={teamId} type="team" />,
        linkText: selectTeamName(teamId),
        id: `mainnav-teamselector-${teamId}`,
        key: `mainnav-teamselector-${teamId}`,
      });
    });

    if (teams.length === 0) {
      return null;
    }
    return (
      <div className={classes.selectorRoot}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: 24,
            overflow: 'visible',
          }}
        >
          <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
            {t('mainNavigation.sectionTitleLedTeams')}
          </Typography>
          <ExpandButton
            expanded={expanded}
            onToggle={this.toggleExpanded}
            name="main-nav-toggle-expand-managed-teams"
          />
        </Box>
        <List dense component="div">
          {teams.map(teamProps => {
            if (expanded || location.pathname.includes(teamProps.to)) {
              return <LinkItem {...teamProps} name="linkitem-my-teams" />;
            }
            return null;
          })}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  managedTeams: peopleSelectors.selectTeamsManagedBy(state.main.people, state.auth.userID),
  selectTeamName: id => peopleSelectors.selectTeamName(state.main.people, id),
});

TeamsLedSelector.propTypes = {
  managedTeams: PropTypes.array,
  selectTeamName: PropTypes.func,
  classes: PropTypes.shape({
    selectorRoot: PropTypes.string,
  }),
  location: PropTypes.object,
  t: PropTypes.func,
};

export default withLocation(connect(mapStateToProps)(withTranslation()(TeamsLedSelector)));
