import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import FormContext from '../FormContext';

const FormRadioGroup = React.memo(props => {
  const { render, fieldName, value, formName, onFieldChange, error } = props;

  const fieldProps = {
    onChange: e => onFieldChange(fieldName, e.target.value),
    value,
    name: `${formName}-${fieldName}`,
    error,
  };
  return render(fieldProps);
});

function OuterFormRadioGroup(props) {
  /* Outer context -connector, passes the context as destructured props
     To the inner connector FormRadioGroup that uses React.memo()

     This way, the input component will not re-render unless the props
     relevant to it change.
  */
  const { render, fieldName, valueCastFunc } = props;

  const { onFieldChange, values, formName, fieldErrors, showErrors } =
    React.useContext(FormContext);

  const onChangeHandler = (fname, value) => {
    if (!!valueCastFunc) {
      onFieldChange(fname, valueCastFunc(value));
    } else {
      onFieldChange(fname, value);
    }
  };

  return (
    <FormRadioGroup
      fieldName={fieldName}
      render={render}
      onFieldChange={onChangeHandler}
      value={get(values, fieldName)}
      formName={formName}
      error={
        !!showErrors && !!fieldErrors && fieldName in fieldErrors ? fieldErrors[fieldName] : ''
      }
      helperText={
        !!showErrors && !!fieldErrors && fieldName in fieldErrors ? fieldErrors[fieldName] : ''
      }
    />
  );
}

OuterFormRadioGroup.propTypes = {
  render: PropTypes.func,
  fieldName: PropTypes.string,
  valueCastFunc: PropTypes.func,
};

export default OuterFormRadioGroup;
