import React from 'react';
import { Soap } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

function MethodsIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect opacity="0.2" width="24" height="24" rx="8" fill="black" fillOpacity="0.14" />
      <Soap height={15} width={15} fill="black" fillOpacity="0.6" x={5} y={4} />
    </SvgIcon>
  );
}

export default MethodsIcon;
