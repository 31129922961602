import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

const TYPE_TO_STR_MAP = {
  quad: 'typeOptQuad',
  nps: 'typeOptNps',
  csat: 'typeOptCsat',
  multiplechoice: 'typeOptMultipleChoice',
  opentext: 'typeOptOpenText',
  sliders: 'typeOptSliders',
};

function SurveyTypeChip(props) {
  const { survey, size, sx } = props;
  const { t } = useTranslation();
  return <Chip size={size} sx={sx} label={t(`insights.${TYPE_TO_STR_MAP[survey.topicType]}`)} />;
}

SurveyTypeChip.propTypes = {
  survey: PropTypes.object,
  sx: PropTypes.object,
  size: PropTypes.oneOf(['normal', 'small']),
};

SurveyTypeChip.defaultProps = {
  size: 'normal',
};
export default SurveyTypeChip;
