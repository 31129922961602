import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { NODE_TYPE_KR } from 'config/constants';
import KeyresultsIcon from 'Components/Features/Objectives/KeyresultsIcon';
import AiPopover from './AiPopover';
import { postSuggestRequest } from '../api';

function AiAssistCreateKr({ onChange, objectiveData, value }) {
  const [result, setResult] = useState({});
  if (get(objectiveData, ['data', 'type']) !== 'team') {
    // Only supported for team OKRs currently
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return null;
  }
  return (
    <AiPopover
      SuggestionIcon={KeyresultsIcon}
      dataGetter={context =>
        postSuggestRequest(NODE_TYPE_KR, {
          input_text: value,
          st_period: get(objectiveData, ['data', 'stperiod'], '').split('-').slice(-1)[0],
          lt_period: get(objectiveData, ['data', 'ltperiod']),
          domain_id: `TEAM_${get(objectiveData, ['data', 'teamID'])}`,
          node_id: get(objectiveData, ['data', 'graph_id']),
          ...context,
        })
      }
      onReceive={d => {
        setResult(d);
      }}
      result={result}
      onClose={() => setResult({})}
      onApprove={v => onChange({ target: { value: v } })}
    />
  );
}

AiAssistCreateKr.propTypes = {
  objectiveData: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AiAssistCreateKr;
