import { get } from 'lodash';
import { createLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import { shouldFetch } from 'state/helpers';
import { onSurveyCreated, onTopicCreated } from 'state/ducks/insights/logic-handlers';
import { APIGW_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';

import * as selectors from './selectors';

const SLICE_NAME = 'insights';

export const replyToSurveyLogic = createLogic({
  type: types.REPLY_TO_SURVEY,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${APIGW_URL}/insights/${getState().auth.tenantID}/replytosurvey`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.repliedToSurvey(result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const replyToInterpretationLogic = createLogic({
  type: types.REPLY_TO_INTERPRETATION,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(
        `${APIGW_URL}/insights/${getState().auth.tenantID}/replytointerpretation`,
        action.payload,
        { headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` } },
      )
      .then(res => {
        const { result } = res.data;
        dispatch(actions.repliedToInterpretation(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const createTopicLogic = createLogic({
  type: types.CREATE_TOPIC,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${APIGW_URL}/insights/${getState().auth.tenantID}/createtopic`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        onTopicCreated(res, dispatch);
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const createSurveyLogic = createLogic({
  type: types.CREATE_SURVEY,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${APIGW_URL}/insights/${getState().auth.tenantID}/createsurvey`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        onSurveyCreated(result, dispatch);
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const createInterpretationTopicLogic = createLogic({
  type: types.CREATE_INTERPRETATION_TOPIC,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(
        `${APIGW_URL}/insights/${getState().auth.tenantID}/createinterpretationtopic`,
        action.payload,
        { headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` } },
      )
      .then(res => {
        const { result } = res.data;
        dispatch(actions.createdInterpretationTopic(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const getTopicLogic = createLogic({
  type: types.GET_TOPIC,
  debounce: 150,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.topicID &&
      shouldFetch(
        selectors.selectTopic(state.main[SLICE_NAME], action.payload.topicID),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/insights/${getState().auth.tenantID}/gettopic`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedTopic(res.data.result));
      })
      .catch(e => {
        if (get(e, 'response.status') === 404) {
          dispatch(actions.topicNotFound(action.payload));
        } else {
          dispatch(actions.failedToFetchTopic(action.payload));
        }
      })
      .then(() => done());
  },
});

export const deleteUnderstandingLogic = createLogic({
  type: types.DELETE_UNDERSTANDING,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(
        `${APIGW_URL}/insights/${getState().auth.tenantID}/deleteinterpretationtopic`,
        action.payload,
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )

      .then(() => {
        dispatch(actions.understandingDeleted(action.payload));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteInsightLogic = createLogic({
  type: types.DELETE_INSIGHT,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${APIGW_URL}/insights/${getState().auth.tenantID}/deletetopic`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(() => {
        dispatch(actions.insightDeleted(action.payload));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteSurveyLogic = createLogic({
  type: types.DELETE_SURVEY,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${APIGW_URL}/insights/${getState().auth.tenantID}/deletesurvey`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(() => {
        dispatch(actions.surveyDeleted(action.payload));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.insightsApiError(errorPayload));
      })
      .then(() => done());
  },
});

export const getTopicSurveysLogic = createLogic({
  type: types.GET_TOPIC_SURVEYS,
  debounce: 150,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.topicID &&
      shouldFetch(
        selectors.selectSurveysForTopic(state.main[SLICE_NAME], action.payload.topicID),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/insights/${getState().auth.tenantID}/gettopicsurveys`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedTopicSurveys(res.data.result));
      })
      .catch(() => {
        dispatch(actions.failedToFetchTopicSurveys(action));
      })
      .then(() => done());
  },
});

export const getLatestSurveysLogic = createLogic({
  type: types.GET_SURVEYS,
  debounce: 150,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.audienceID &&
      !!action.payload.audienceType &&
      shouldFetch(
        selectors.selectSurveysForAudience(
          state.main[SLICE_NAME],
          action.payload.audienceType,
          action.payload.audienceID,
        ),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/insights/${getState().auth.tenantID}/getlatestsurveys`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedSurveys(res.data.result));
      })
      .catch(() => {
        dispatch(actions.failedToFetchSurveys(action.payload));
      })
      .then(() => done());
  },
});

export const getSingleSurveyLogic = createLogic({
  type: types.GET_SINGLE_SURVEY,
  debounce: 150,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.surveyID &&
      shouldFetch(
        selectors.selectSurvey(state.main[SLICE_NAME], action.payload.surveyID),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/insights/${getState().auth.tenantID}/getsurvey`, {
        params: action.payload,
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedSingleSurvey(res.data.result));
      })
      .catch(() => {
        dispatch(actions.failedToFetchSingleSurvey(action.payload));
      })
      .then(() => done());
  },
});

export const getInterpretationTopicsLogic = createLogic({
  type: types.GET_INTERPRETATION_TOPICS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.cpID &&
      shouldFetch(
        selectors.selectInterpretationTopicsForProgram(state.main[SLICE_NAME], action.payload.cpID),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/insights/${getState().auth.tenantID}/getinterpretationtopics`, {
        params: { cpID: action.payload.cpID },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedInterpretationTopics(res.data.result));
      })
      .catch(() => {
        dispatch(actions.failedToFetchInterpretationTopics(action.payload));
      })
      .then(() => done());
  },
});

export const getInterpretationStatsLogic = createLogic({
  type: types.GET_INTERPRETATION_STATS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      !!action.payload.csiID &&
      selectors.selectInterpretationTopic(state.main[SLICE_NAME], action.payload.csiID).ok &&
      shouldFetch(
        selectors.selectInterpretationStats(state.main[SLICE_NAME], action.payload.csiID),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(`${APIGW_URL}/insights/${getState().auth.tenantID}/getinterpretationstats`, {
        params: { csiID: action.payload.csiID },
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedInterpretationStats(res.data.result));
      })
      .catch(() => {
        dispatch(actions.failedToFetchInterpretationStats(action.payload));
      })
      .then(() => done());
  },
});

export const getSurveyStatsLogic = createLogic({
  type: types.GET_SURVEY_STATS,
  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectSurveyStats(state.main[SLICE_NAME], action.payload.surveyID),
        state.main.connection,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .get(
        `${APIGW_URL}/insightstats/${getState().auth.tenantID}/${
          action.payload.surveyID
        }_parsed.json`,
        {
          params: action.payload,
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        dispatch(actions.fetchedSurveyStats({ ...res.data, surveyID: action.payload.surveyID }));
      })
      .catch(() => {
        dispatch(actions.failedToFetchSurveyStats(action.payload));
      })
      .then(() => done());
  },
});
