import { createLogic } from 'state/defaultLogic';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import { instance as axios } from '../../../config/axios';
import { API_BASE_URL } from '../../constants/api';
import { shouldFetch } from '../../helpers';

const SLICE_NAME = 'teamfilters';

// eslint-disable-next-line import/prefer-default-export
export const getTeamFilters = createLogic({
  type: types.GET_TEAM_FILTERS,

  validate({ getState, action }, allow, reject) {
    const state = getState();
    if (
      shouldFetch(
        selectors.selectTeamFilters(state.main[SLICE_NAME]),
        state.main.connection,
        !!action.payload && action.payload.force,
      )
    ) {
      allow(action);
    } else {
      reject();
    }
  },

  process: ({ getState, action }, dispatch, done) => {
    axios
      .get(`${API_BASE_URL}/${getState().auth.tenantID}/getteamfilters`, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.fetchedTeamFilters({ ...res.data.result }));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data) {
          errorPayload.error = e.response.data;
        }
        dispatch(actions.getTeamsFiltersFailed(errorPayload));
      })
      .then(() => done());
  },
});

export const createTeamFilterLogic = createLogic({
  type: types.CREATE_TEAM_FILTER,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/createteamfilter`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const { result } = res.data;
        dispatch(actions.teamFilterCreated(result));
      })
      .catch(e => {
        const { payload } = action;
        const errorPayload = { ...payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteTeamFilter = createLogic({
  type: types.DELETE_TEAM_FILTER,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/deleteteamfilter`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })

      .then(() => {
        dispatch(actions.teamFilterDeleted(action.payload));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});

export const editTeamFilter = createLogic({
  type: types.EDIT_TEAM_FILTER,
  latest: true,
  process: async ({ getState, action }, dispatch, done) => {
    axios
      .post(`${API_BASE_URL}/${getState().auth.tenantID}/editteamfilter`, action.payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.teamFilterEdited(res.data.result));
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.errormsg = e.response.data.error;
        }
        dispatch(actions.apiError(errorPayload));
      })
      .then(() => done());
  },
});
