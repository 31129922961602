import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

function NoResults() {
  const { t } = useTranslation();
  return (
    <Typography
      variant="caption"
      color="text.secondary"
      paragraph
      gutterBottom
      align="center"
      name="search-no-results"
    >
      {t('general.search.searchNoResults')}
    </Typography>
  );
}

export default NoResults;
